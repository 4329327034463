import React, { useEffect, useRef, useState } from "react";
import { TreeTable } from "primereact/treetable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { format } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import { Tooltip } from "primereact/tooltip";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import GV from "../../services/globalVar";
import {
  Card,
  Divider,
  Dropdown,
  Sidebar,
  Button,
  BlockUI,
} from "../PrimeReact.js";
import { Toast } from "primereact/toast";
import BudgetService from "../../services/budget-service";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmPopup } from "primereact/confirmpopup";
import { SpeedDial } from "primereact/speeddial";

export default function ExpenseTracking() {
  const bud = new BudgetService();
  const [isLoading, setIsLoading] = useState(false);
  const [subCategory, setSubCategory] = useState([]);
  const [isProcess, setIsProcess] = useState(false);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [financialYearCatagery, setFinancialYearCatagery] = useState([]);
  let isFinancialYear = GV.userDet.tenantConfig.isFinancialYear;
  const [monthYears, setmonthYears] = useState({});
  const [getMonthyear, setGetMonthyear] = useState({});
  const [defaultValues, setdefaultValues] = useState([]);
  const [dynamicMonth, setDynamicMonth] = useState([]);
  const [dynamicMonthSubHeader, setDynamicMonthSubHeader] = useState([]);
  const [expenses, setExpenses] = useState(null);
  const [quaterDropDown, setQuaterDropDown] = useState([]);
  const [foundedQuater, setFoundedQuater] = useState(null);
  const [dfaultFinancialCurreentYr, setDfaultFinancialCurreentYr] = useState(
    {}
  );
  const [getFieldExpense, setGetFieldExpense] = useState("");
  const [selectDropDown, setSelectDropDown] = useState("");
  const [fiNdQuaterThreeMonths, setFiNdQuaterThreeMonths] = useState([]);
  const [findMonthStatus, setFindMonthStatus] = useState([]);
  const [disableMonth, setDisableMonth] = useState([]);
  const [visible, setVisible] = useState(false);
  const [closeMonthBefore, setCloseMonthBefore] = useState(false);
  const buttonEl = useRef(null);
  const [chooseDrpDwn, setChooseDrpDwn] = useState([]);
  const [nodata, setNodata] = useState(false);

  const toast = useRef(null);

  const hideConfirm = () => {
    setVisible(false);
  };

  function popConfirm(item) {
    setVisible(true);
    setDisableMonth(item);
  }
  const quarterDetails = [
    { id: 1, lable: "First Quarter" },
    { id: 2, lable: "Second Quarter" },
    { id: 3, lable: "Third Quarter" },
    { id: 4, lable: "Fourth Quarter" },
  ];
  const [quarter, setQuarter] = useState(quarterDetails[0]);

  const subMonths = [
    {
      header: "January",
      subHeadr1: "Forecast Amount",
      subHeadr2: "Expenses",
      subHeadr3: "Balance",
    },
    {
      header: "February",
      subHeadr1: "Forecast Amount",
      subHeadr2: "Expenses",
      subHeadr3: "Balance",
    },
    {
      header: "March",
      subHeadr1: "Forecast Amount",
      subHeadr2: "Expenses",
      subHeadr3: "Balance",
    },
    {
      header: "April",
      subHeadr1: "Forecast Amount",
      subHeadr2: "Expenses",
      subHeadr3: "Balance",
    },
    {
      header: "May",
      subHeadr1: "Forecast Amount",
      subHeadr2: "Expenses",
      subHeadr3: "Balance",
    },
    {
      header: "June",
      subHeadr1: "Forecast Amount",
      subHeadr2: "Expenses",
      subHeadr3: "Balance",
    },
    {
      header: "July",
      subHeadr1: "Forecast Amount",
      subHeadr2: "Expenses",
      subHeadr3: "Balance",
    },
    {
      header: "August",
      subHeadr1: "Forecast Amount",
      subHeadr2: "Expenses",
      subHeadr3: "Balance",
    },
    {
      header: "September",
      subHeadr1: "Forecast Amount",
      subHeadr2: "Expenses",
      subHeadr3: "Balance",
    },
    {
      header: "October",
      subHeadr1: "Forecast Amount",
      subHeadr2: "Expenses",
      subHeadr3: "Balance",
    },
    {
      header: "November",
      subHeadr1: "Forecast Amount",
      subHeadr2: "Expenses",
      subHeadr3: "Balance",
    },
    {
      header: "December",
      subHeadr1: "Forecast Amount",
      subHeadr2: "Expenses",
      subHeadr3: "Balance",
    },
  ];

  // function getSubMonths() {
  //   const mappedSubMonths = subMonths.map((a) => {
  //     return a;
  //   });
  //   setDynamicMonthSubHeader(mappedSubMonths);
  // }

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues });

  function generateYearsDropdown() {
    let currentYear = new Date().getFullYear();
    let lastTenYears = currentYear - 10;
    let yearsArray = [];
    let financialYearStartingMonth =
      GV.userDet?.tenantConfig?.startMonthName.slice(0, 3);
    let financialYearStartingMonthID = GV.userDet?.tenantConfig?.startMonth;
    let financialYearEndingMonth = GV.userDet?.tenantConfig?.endMonthName.slice(
      0,
      3
    );
    let financialYearEndingMonthID = GV.userDet?.tenantConfig?.endMonth;
    for (let i = currentYear; i > lastTenYears; i--) {
      let yearObj = {
        id: i,
        years: `${financialYearStartingMonth} ${i} - ${financialYearEndingMonth} ${
          i + 1
        }`,
        selectYear: i,
        startMnth: financialYearStartingMonthID,
        startYr: i,
        endMnth: financialYearEndingMonthID,
        endYr: i + 1,
      };
      yearsArray = [...yearsArray, yearObj];
    }
    console.log(yearsArray, "yearsArray");
    return yearsArray;
  }

  const saveYears = generateYearsDropdown();

  function findYearRange(x) {
    let today = new Date();
    let financialYearStartingMonth =
      GV.userDet?.tenantConfig?.startMonthName.slice(0, 3);
    let financialYearEndingMonth = GV.userDet?.tenantConfig?.endMonthName.slice(
      0,
      3
    );
    let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    let monthStart = new Date(financialYearStartingMonth + " " + x.selectYear);
    let monthEnd = new Date(
      financialYearEndingMonth +
        " " +
        (x.selectYear + 1) +
        " " +
        lastDayOfMonth.getDate()
    );
    return new Date() >= monthStart && new Date() <= monthEnd;
  }

  // function getBudgetForecasting() {
  //   setIsProcess(true);
  //   let tenid = GV.userDet.tenantDetail.tenantID;
  //   let year = selectedYear;
  //   bud
  //     .GetBudgetForecasting(tenid, year)
  //     .then((response) => {
  //       setIsProcess(false);
  //       if (response.data) {
  //         if (response.data.statusCode === 200) {
  //           setIsLoading(false);
  //           setSubCategory(response.data.data);
  //           console.log(
  //             response.data.data,
  //             "%c Oh my heavens! ",
  //             "background: #222; color: #bada55"
  //           );
  //         }
  //       }
  //     })
  //     .catch(function (error) {});
  // }

  // function getBudgetForecastingFinancialYear(year) {
  //   let tenid = GV.userDet.tenantDetail.tenantID;
  //   bud
  //     .GetBudgetForecasting(tenid, year)
  //     .then((response) => {
  //       if (response.data) {
  //         if (response.data.statusCode === 200) {
  //           setIsLoading(false);
  //           setSubCategory(response.data.data);
  //           console.log(response.data.data,'veeran');
  //           setFinancialYearCatagery(response.data.data);
  //         }
  //       }
  //     })
  //     .catch(function (error) {});
  // }

  function getQuaterDropDownList(year) {
    setIsProcess(true);
    let tenid = GV.userDet.tenantDetail.tenantID;
    bud
      .GenerateQuaterDropDown(tenid, year)
      .then((response) => {
        if (response.data) {
          if (response.data.statusCode === 200) {
            setIsProcess(false);
            // console.log(response.data.data,'quaterDropDown');
            setQuaterDropDown(response.data.data);
            console.log(
              quaterDropDown,
              dfaultFinancialCurreentYr,
              "quaterDropDown"
            );
            const date = new Date();
            const result = response.data.data.find(
              (x) =>
                date.getMonth() >= new Date(x.fromDate).getMonth() &&
                date.getMonth() <= new Date(x.toDate).getMonth()
            );
            budgetQtrList(year, result.quarterID);
            setFoundedQuater(result);
            setSelectDropDown(result);
            let startMonthNumber = new Date(result.fromDate).getMonth() + 1;
            let endMonthNumber = new Date(result.toDate).getMonth() + 1;
            let dynamicMonthNumbers = Array.from(
              { length: endMonthNumber - startMonthNumber + 1 },
              (_, a) => a + startMonthNumber
            );
            let findCurrentThreeMonths = generateMonths().filter((x) =>
              dynamicMonthNumbers.includes(x.monthNumber)
            );
            setFiNdQuaterThreeMonths(findCurrentThreeMonths);
            // console.log(findCurrentThreeMonths, 'findmOnths');
          }
        }
      })
      .catch(function (error) {});
  }

  function budgetQtrList(year, quarterID) {
    let tenid = GV.userDet.tenantDetail.tenantID;
    setIsProcess(true);
    setSubCategory([]);
    bud.GetBudgetQuarterList(tenid, year, quarterID).then((res) => {
      setIsProcess(false);
      setSubCategory(res.data.data);
      if (res.data.data.categories.length < 1) {
        setNodata(true)
      } else {
        setNodata(false)
      }
      let getStatus = res.data.data.monthlyStatus;
      console.log(getStatus);
      setFindMonthStatus(getStatus);
      // UpdateBudgetStatus()
    });
  }

  const GetFinYear = () => {
    bud.GetFiscalYears(GV.userDet.tenantDetail.tenantID).then((res) => {
      const sortedYears = res.data.data.sort((a, b) => a.startYr - b.startYr);
      setChooseDrpDwn(sortedYears);
      const findYr = res.data.data.find(
        (a) => a.selectYear === new Date().getFullYear()
      );
      setmonthYears(findYr);
    });
  };

  const handleSelectQuaterDropDown = (e) => {
    budgetQtrList(monthYears.selectYear, e.value.quarterID);
    let startMonthNumber = new Date(e.value.fromDate).getMonth() + 1;
    let endMonthNumber = new Date(e.value.toDate).getMonth() + 1;
    let dynamicMonthNumbers = Array.from(
      { length: endMonthNumber - startMonthNumber + 1 },
      (_, a) => a + startMonthNumber
    );
    let findCurrentThreeMonths = generateMonths().filter((x) =>
      dynamicMonthNumbers.includes(x.monthNumber)
    );
    setFiNdQuaterThreeMonths(findCurrentThreeMonths);
    setFoundedQuater(e.value);
    setSelectDropDown(e.value);
  };

  const handleYearChange1 = (e) => {
    // getBudgetForecastingFinancialYear(e.selectYear);
    // getQuaterDropDownList(e.selectYear)
    // budgetQtrList(e.selectYear)
    getQuaterDropDownList(e.selectYear);
    setmonthYears(e);
    setGetMonthyear(e);
  };

  useEffect(() => {
    GetFinYear();
    if (isFinancialYear === false) {
      // getBudgetForecasting();
    } else {
      let dfaultFinancialCurreent = saveYears.find((x) => findYearRange(x));
      setDfaultFinancialCurreentYr(dfaultFinancialCurreent);
      // getBudgetForecastingFinancialYear(dfaultFinancialCurreent.selectYear);
      console.log(
        dfaultFinancialCurreent,
        "dfaultFinancialCurreent.selectYear"
      );
      // budgetQtrList(dfaultFinancialCurreent?.selectYear)
      getQuaterDropDownList(dfaultFinancialCurreent?.selectYear);
      setmonthYears(dfaultFinancialCurreent);
      // getSubMonths();
    }
  }, []);

  function generateMonths() {
    if(GV.userDet?.tenantConfig?.startMonth === 1) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let startMonth = GV.userDet?.tenantConfig?.startMonth;
      let financialMonths = [];
      for (let i = startMonth - 1; i < months.length; i++) {
        let monthObj = {
          header: months[i],
          subHeadr1: "Forecast Amount",
          subHeadr2: "Expenses",
          subHeadr3: "Balance",
          monthNumber: i + 1,
        };
        financialMonths = [...financialMonths, monthObj];
      }
     
      return financialMonths;
    } else {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let startMonth = GV.userDet?.tenantConfig?.startMonth;
      let endMonth = GV.userDet?.tenantConfig?.endMonth;
      let financialMonths = [];
      for (let i = startMonth - 1; i < months.length; i++) {
        let monthObj = {
          header: months[i],
          subHeadr1: "Forecast Amount",
          subHeadr2: "Expenses",
          subHeadr3: "Balance",
          monthNumber: i + 1,
        };
        financialMonths = [...financialMonths, monthObj];
      }
      for (let j = 0; j < endMonth; j++) {
        let monthObj = {
          header: months[j],
          subHeadr1: "Forecast Amount",
          subHeadr2: "Expenses",
          subHeadr3: "Balance",
          monthNumber: j + 1,
        };
        financialMonths = [...financialMonths, monthObj];
      }
      return financialMonths;
    }
   
  }

  const handleExpenseChange = (value) => {
    console.log(value, "expvalue");
    setGetFieldExpense(value);
  };

  const expenseCancle = (month) => {
    console.log(
      monthYears.selectYear,
      foundedQuater?.quarterID,
      "clicked cancle"
    );
    budgetQtrList(monthYears.selectYear, foundedQuater?.quarterID);
  };

  const expenseSave = (mon, sub, item, j) => {
    const data = [
      {
        BudgetID: mon.budgetInfo?.budgetID || 0,
        BudgetCatID: item?.budgetCatID || "",
        BudgetCategory: item?.budgetCategory || "",
        BudgetSubCatID: sub?.budgetSubCatID || "",
        BudgetSubCategory: sub?.budgetSubCategory || "",
        FinancialYear: mon?.year,
        FinancialMonth: mon?.month,
        ForecastingAmount: mon.budgetInfo?.forecastingAmount,
        Expenses: +getFieldExpense || mon.budgetInfo?.expenses,
        Description: item?.description || "",
        TenantID: GV.userDet.tenantDetail.tenantID,
        CreatedBy: GV.userDet.appUser.userName,
        status: subCategory.monthlyStatus[j].status,
      },
    ];
    console.log(data, "datadatadata");
    bud.SaveBudgetExpense(data).then((res) => {
      if (res.data.statusCode === 200) {
        setGetFieldExpense("");
        budgetQtrList(monthYears.selectYear, foundedQuater?.quarterID);
        toast.current.show({
          severity: "success",
          summary: res.data.message,
        });
      }
    });
  };

  const confirm = async (item, e) => {
    // setVisible(true);
    let isCurrentMonth = item?.monthID === (new Date().getMonth() + 1);
    setDisableMonth(item);
    console.log(item, "chat");
    let dialogObj = {
      message: isCurrentMonth
        ? "Proceeding with month closure before its end date are you sure?"
        : "Are you sure you want to close the month?" ,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle negative-value",
      defaultfocus: "accept",
      accept: () => accept(item),
      reject,
    };
    confirmDialog(dialogObj);
  };
  // const confirm = async (item, e) => {
  //   console.log(e, "monthsDetail");
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = today.getMonth();
  //   const dateDay = today.getDate();
  //   const gettingDate = new Date(year, month, dateDay + 1);
  //   const currentDate = gettingDate.toISOString().split("T")[0];
  //   const endDate = new Date(year, month + 1, 0);
  //   const formattedEndDate = endDate.toISOString().split("T")[0];
  //   let getDate = currentDate < formattedEndDate;
  //   setCloseMonthBefore(getDate);
  //   console.log(closeMonthBefore, "closeMonthBefore");
  //   console.log(currentDate, "currentDate");
  //   console.log(formattedEndDate, "formattedEndDate");
  //   console.log(getDate, "getDate");
  //   // setVisible(true);
  //   setDisableMonth(item);
  //   console.log(item, "chat");
  //   let dialogObj = {
  //     message: currentDate < formattedEndDate
  //       ? "Are you sure you want to close the month before the end date?"
  //       : "Are you sure you want to close the month?",
  //     header: "Confirmation",
  //     icon: "pi pi-exclamation-triangle negative-value",
  //     defaultfocus: "accept",
  //     accept: () => accept(item),
  //     reject,
  //   };
  //   confirmDialog(dialogObj);
  // };

  const accept = (datas) => {
    // const datas = disableMonth
    const data = {
      TenantID: datas.tenantID,
      YearID: datas?.yearID,
      MonthID: datas?.monthID,
      Status: "Closed",
    };
    bud.UpdateBudgetStatus(data).then((res) => {
      if (res.status === 200) {
        budgetQtrList(monthYears.selectYear, foundedQuater?.quarterID);
        toast.current.show({
          severity: "success",
          summary: "Confirmed",
          detail: "Month is closed",
          life: 3000,
        });
      }
    });
  };

  const reject = () => {};

  //   const customTooltipOptions = {
  //     style: {
  //         borderRadius: '8px'
  //     },
  //     position: 'bottom',
  //     event: 'hover'
  // };

  return (
    <div>
      <BlockUI blocked={isProcess}>
        <div>
          <Toast ref={toast}></Toast>
          <div className="card cc-greyCard-custm card-borderless mb-3" >
            <div className="card-body p-2" >
              <div className="d-flex justify-content-between align-items-center mb-2 flex-wrap">
                <h4 className="m-0">Budget-Tracking</h4>
                <div></div>
              </div>
            </div>
          </div>

          <div className="card mt-2">
            <div className="mt-2 row mb-2 ">
              <div>
                <div className=" row mx-auto">
                  <div className="col-md-2  ps-0 me-1">
                    <div className="ps-3 mt-0 ">
                      <Controller
                        render={({ field }) => (
                          <Dropdown
                              className="yr-down budget-drop"
                              placeholder="Select an option"
                              // options={saveYears.map((year) => ({
                              //   value: year,
                              //   label: year.years,
                              // }))}
                              options={chooseDrpDwn}
                              optionLabel="years"
                              {...field}
                              value={monthYears}
                              onChange={(e) => handleYearChange1(e.value)}
                            />
                        )}
                        control={control}
                        name="monthWithYears"
                      />
                    </div>
                  </div>

                  {subCategory?.monthlyStatus?.map((item, index) => (
                    <div
                      className={`ps-1 col-md mt-1 pb-1 pt-1 text-center ${
                        index % 2 === 0 ? "odd" : "even"
                      }`}
                      key={index}
                    >
                      <div className="row month-expense">
                        <div className="col-md"></div>
                        <div className="col-md">{item.monthName}</div>
                        <div className="col-md text-end ">
                          {["Active"].includes(
                            subCategory?.monthlyStatus[index]?.status
                          ) ? (
                            <>
                              <Tooltip target=".tooltip-icon" position="left" />
                              <a href="#" onClick={(e) => confirm(item, e)}>
                                <i
                                  className="pi pi-calendar-times tooltip-icon month-cancel"
                                  style={{ fontSize: "1rem", color: "red" }}
                                  data-pr-tooltip="Close a month"
                                ></i>
                              </a>
                            </>
                          ) : (
                            // <Button
                            //     className="ticksuccess month-cancel"
                            //     icon="pi pi-times"
                            //     rounded
                            //     text
                            //     severity="danger"
                            //     aria-label="Cancel"
                            //     onClick={(e) => popConfirm(item)}
                            //   />
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  <ConfirmDialog />
                </div>

                <div className="row d-flex mx-auto">
                  <div className="col-md-2  ps-0 me-1 mt-2">
                    <div className="ps-3 mt-0 ">
                      <Dropdown
                        value={selectDropDown}
                        onChange={(e) => handleSelectQuaterDropDown(e)}
                        options={quaterDropDown}
                        optionLabel="quarterName"
                        placeholder="Select quarter"
                        className="yr-down budget-drop"
                      />
                    </div>
                  </div>

                  {fiNdQuaterThreeMonths?.map((item, index) => (
                    <div
                      className={`ps-1 col-md mt-1 pb-1 pt-1 text-center ${
                        index % 2 === 0 ? "odd" : "even"
                      }`}
                      key={index}
                    >
                      <div className="row mx-auto align-items-center">
                        <div className="col-md">
                          <p className="mb-0">{item.subHeadr1}</p>
                        </div>
                        <div className="col-md">
                          <p className="mb-0">{item.subHeadr2}</p>
                        </div>
                        <div className="col-md">
                          <p className="mb-0">{item.subHeadr3}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {isLoading && (
            <ProgressSpinner
              style={{
                width: "50px",
                height: "50px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              strokeWidth="4"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          )}

          <div className="mt-2 abc">
            {subCategory?.categories?.map((item, i) => {
              const totalForecastingAmountByMonth = {};
              const totalForecastingAmountByMonth1 = {};
              const totalForecastingAmountByMonth2 = {};
              let getIndex = null;
              // const testing = {forecastingAmount:0, expense:0, balance:0}
              return (
                <Card className=" mt-2" key={i}>
                  <div className="row">
                    <div className="col-md-12 d-flex">
                      <h6>
                        {" "}
                        <b>{item.budgetCategory}</b>
                      </h6>
                      <div className="col-md"></div>
                      <div className="col-md text-center"></div>
                      <div className="col-md"></div>
                    </div>
                  </div>
                  <Divider />
                  <>
                    {item?.subCategory?.map((sub, i) => {
                      getIndex = i;
                      return (
                        <div
                          key={i}
                          className="row ps-1 mt-1"
                          style={{ flexWrap: "nowrap" }}
                        >
                          <div className="col-md-2 ps-0">
                            <div
                              className="ps-1 mt-1 pb-1 pt-1  pointer"
                              style={{
                                borderBottom: "1px solid #d3d3d3",
                                background: "#FFE6B9",
                              }}
                            >
                              <p className="mb-0 cc-font-size-14 p-2">
                                {" "}
                                {sub.budgetSubCategory}
                              </p>
                            </div>
                          </div>
                          {sub?.month?.map((mon, j) => {
                            const expenses = mon?.budgetInfo
                              ? mon.budgetInfo.expenses
                              : null;
                            const forecastingAmount = mon?.budgetInfo
                              ? mon.budgetInfo.forecastingAmount
                              : null;
                            const balance = mon?.budgetInfo
                              ? mon.budgetInfo.balanceAmount
                              : null;
                            const formattedBalance =
                              balance === null ? "0.00" : balance.toFixed(2);
                            const overExpense = forecastingAmount < expenses;
                            const textStyle = {
                              color: overExpense ? "red !important" : "inherit",
                            };
                            totalForecastingAmountByMonth[j] =
                              (totalForecastingAmountByMonth[j] || null) +
                              forecastingAmount;
                            totalForecastingAmountByMonth1[j] =
                              (totalForecastingAmountByMonth1[j] || null) +
                              expenses;
                            totalForecastingAmountByMonth2[j] =
                              (totalForecastingAmountByMonth2[j] || null) +
                              balance;

                            return (
                              <div
                                key={j}
                                className={`ps-1 col-md mt-1 pb-1 pt-1 pe-0 text-center month-expense ${
                                  j % 2 === 0 ? "odd" : "even"
                                }`}
                              >
                                <div className="row mx-auto align-items-center">
                                  <div className="col-md">
                                    <p className=" mb-0 cc-font-size-14">
                                      {" "}
                                      {forecastingAmount === null
                                        ? "0.00"
                                        : forecastingAmount?.toFixed(2)}{" "}
                                    </p>
                                  </div>
                                  <div className="col-md d-flex align-items-center">
                                    {!["Active", "Opened"].includes(
                                      subCategory?.monthlyStatus[j].status
                                    ) ? (
                                      ""
                                    ) : (
                                      <>
                                        <Tooltip
                                          target=".tooltip-button-cancel"
                                          position="top"
                                        />
                                        <Button
                                          className="ticksuccess month-cancel tooltip-button-cancel"
                                          icon="pi pi-times"
                                          // rounded
                                          text
                                          severity="danger"
                                          aria-label="Cancel"
                                          onClick={() => expenseCancle(mon)}
                                          data-pr-tooltip="Cancel"
                                        />
                                      </>
                                    )}
                                    <input
                                      type="number"
                                      className="form-control cc-font-size-14"
                                      style={{ width: "6rem", textAlign:"center" }}
                                      // onKeyDown={handleKeyPress}
                                      disabled={
                                        !["Active", "Opened"].includes(
                                          subCategory?.monthlyStatus[j].status
                                        )
                                      }
                                      defaultValue={
                                        mon?.budgetInfo
                                          ? mon.budgetInfo.expenses.toFixed(2)
                                          : "0.00"
                                      }
                                      onBlur={(e) =>
                                        handleExpenseChange(e.target.value)
                                      }
                                    />
                                    {!["Active", "Opened"].includes(
                                      subCategory?.monthlyStatus[j].status
                                    ) ? (
                                      ""
                                    ) : (
                                      <>
                                        <Tooltip
                                          target=".tooltip-button-save"
                                          position="top"
                                        />

                                        <Button
                                          className="ticksuccess month-success tooltip-button-save "
                                          icon="pi pi-save"
                                          // rounded
                                          text
                                          aria-label="Filter"
                                          onClick={(e) =>
                                            expenseSave(mon, sub, item, j)
                                          }
                                          data-pr-tooltip="Save"
                                        />
                                      </>
                                    )}
                                  </div>
                                  <div className="col-md">
                                    <p
                                      style={{ ...textStyle }}
                                      className={`mb-0 cc-font-size-14 ${
                                        overExpense ? "over-expense" : ""
                                      }`}
                                    >
                                      {" "}
                                      {formattedBalance}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                    <div className="row ps-1 mt-1 total-forecasting-amount">
                      <div className="col-md-2 ps-0">
                        <div
                          className="ps-1 mt-1 pt-1  pointer text-bold"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          Total ({GV.userDet.tenantConfig.currency})
                        </div>
                      </div>
                      <div className={` col-md mt-1 sub-cat  `}>
                        <div className="row">
                          <div className="col-md text-center">
                            <p
                              className=" mb-0 cc-font-size-14 pe-3"
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              {GV.userDet.tenantConfig.currency+ " " + totalForecastingAmountByMonth[0]?.toFixed(
                                2
                              )}{" "}
                            </p>
                          </div>
                          <div className="col-md text-center">
                            <p
                              className=" mb-0 cc-font-size-14"
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              {GV.userDet.tenantConfig.currency+ " " + totalForecastingAmountByMonth1[0]?.toFixed(
                                2
                              )}{" "}
                            </p>
                          </div>
                          <div className="col-md text-center">
                            <p
                              className=" mb-0 cc-font-size-14 ps-4"
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              {GV.userDet.tenantConfig.currency+ " " + totalForecastingAmountByMonth2[0]?.toFixed(
                                2
                              )}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={` col-md mt-1  sub-cat  `}>
                        <div className="row">
                          <div className="col-md text-center">
                            <p
                              className=" mb-0 cc-font-size-14"
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              {GV.userDet.tenantConfig.currency+ " " + totalForecastingAmountByMonth[1]?.toFixed(
                                2
                              )}{" "}
                            </p>
                          </div>
                          <div className="col-md text-center">
                            <p
                              className=" mb-0 cc-font-size-14"
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              {GV.userDet.tenantConfig.currency+ " " + totalForecastingAmountByMonth1[1]?.toFixed(
                                2
                              )}{" "}
                            </p>
                          </div>
                          <div className="col-md text-center">
                            <p
                              className=" mb-0 cc-font-size-14 "
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              {GV.userDet.tenantConfig.currency+ " " + totalForecastingAmountByMonth2[1]?.toFixed(
                                2
                              )}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={` col-md mt-1  sub-cat  `}>
                        <div className="row">
                          <div className="col-md text-center">
                            <p
                              className=" mb-0 cc-font-size-14 "
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              {GV.userDet.tenantConfig.currency+ " " + totalForecastingAmountByMonth[2]?.toFixed(
                                2
                              )}{" "}
                            </p>
                          </div>
                          <div className="col-md text-center">
                            <p
                              className=" mb-0 cc-font-size-14"
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              {GV.userDet.tenantConfig.currency+ " " + totalForecastingAmountByMonth1[2]?.toFixed(
                                2
                              )}{" "}
                            </p>
                          </div>
                          <div className="col-md text-center">
                            <p
                              className={` mb-0 cc-font-size-14  ${
                                GV.userDet.tenantConfig.currency+ " " + totalForecastingAmountByMonth2[2]?.toFixed(2) <
                                0
                                  ? "over-expense"
                                  : ""
                              }`}
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              {GV.userDet.tenantConfig.currency+ " " + totalForecastingAmountByMonth2[2]?.toFixed(
                                2
                              )}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </Card>
              );
            })}
        {nodata && <Card
                  style={{
                    height: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h5>No data available for this year</h5>
                  </div>
                </Card>}
          </div>
        </div>
      </BlockUI>
    </div>
  );
}
