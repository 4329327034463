import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../assets/styles/vendor/vendor.scss";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { format } from "date-fns";
import { Toast } from "primereact/toast";
import GV from "../../services/globalVar";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";

//API call
import ContactsService from "../../services/contactsService";

const NewCustomer = forwardRef((props, ref) => {
  const cos = new ContactsService();
  const [customerID, setCustomerID] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [date, setDate] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [gstTreatmentDropDown, setGstTreatmentDropDown] = useState();
  const [selectedGstTreatment, setSelectedGstTreatment] = useState();
  const [isProcess, setIsProcess] = useState(false);
  const [getAutoPass, setGetAutoPass] = useState(false);
  const [isGstin, setIsGstin] = useState(false);

  const defaultValues = {
    //1
    cType: "",
    salType: "",
    fname: "",
    lname: "",
    cname: "",
    dname: "",
    email: "",
    con: "",
    website: "",
    pan: "",
    openingBalance: "",
    curType: "",
    bAddress1: "",
    bAddress2: "",
    bCity: "",
    bState: "",
    bCountry: "",
    bZipCode: "",
    sAddress1: "",
    sAddress2: "",
    sCity: "",
    sState: "",
    sCountry: "",
    sZipCode: "",
    gsttreatment: "",
    gstin: "",
  };
  let cId = props.data.customerId;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = useForm({ mode: "all", defaultValues });
  const resetAsyncForm = async () => {
    reset();
  };

  watch(["bAddress1", "bAddress2", "bCity", "bState", "bCountry", "bZipCode"]);

  useImperativeHandle(ref, () => ({
    alertToggle() {
      onClick("displaynewCust");
    },
    alertToggledit(val) {
      onClickEdit("displaynewCust", val);
    },
  }));

  const businessList = [
    {
      id: 1,
      name: "Registered business - regular",
      value: "Registered business - regular",
    },
    {
      id: 2,
      name: "Registered business - composition",
      value: "Registered business - composition",
    },
    { id: 3, name: "Unregistered business", value: "Unregistered business" },
    { id: 4, name: "Consumer", value: "Consumer" },
    { id: 5, name: "Overseas", value: "Overseas" },
    { id: 6, name: "Special ecomoninc zone", value: "Special ecomoninc zone" },
    { id: 7, name: "Deemed export", value: "Deemed export" },
    { id: 8, name: "Tax deductor", value: "Tax deductor" },
    { id: 9, name: "SEZ developer", value: "SEZ developer" },
  ];
  // const [displaynewCust, setDisplaynewCust] = useState(false);
  const [displaynewCust, setDisplaynewCust] = useState(false);
  const [position, setPosition] = useState("center");

  const handleDateChange = (date) => {
    setDate(format(new Date(date), "MM/dd/yyyy"));
  };

  const handleGSTtreatment = (e) => {
    setIsGstin(false);
    const selectedValue = e.target.value;
    const selectedValue1 = e.target;
    console.log(selectedValue1, "gh");
    switch (selectedValue) {
      case "Registered business - regular":
        setIsGstin(true);
        break;

      case "Registered business - composition":
        setIsGstin(true);
        break;

      case "Unregistered business":
        break;

      case "Consumer":
        break;

      case "Overseas":
        break;

      case "Special ecomoninc zone":
        break;

      case "Deemed export":
        setIsGstin(true);
        break;

      case "Tax deductor":
        setIsGstin(true);
        break;

      case "SEZ developer":
        setIsGstin(true);
        break;

      default:
        break;
    }
  };

  const salutation = [
    { label: "Mr.", value: "Mr." },
    { label: "Ms.", value: "Ms." },
    { label: "Mrs.", value: "Mrs." },
    { label: "Miss.", value: "Miss." },
    { label: "Dr.", value: "Dr." },
  ];

  const currency = [
    { label: "INR-Indian Rupee", value: "INR" },
    { label: "Dollar", value: "Dlr" },
    { label: "Euro", value: "Ero" },
    { label: "Soil", value: "Sol" },
  ];
  const toast = useRef(null); //2

  const dialogFuncMap = {
    displaynewCust: setDisplaynewCust,
  };

  const onClickEdit = (name, value) => {
    dialogFuncMap[`${name}`](true);
    resetAsyncForm();
    console.log(value, "opBal");
    setCustomerID(value.ContactID);
    setValue("cType", value.customerType);
    setValue("salType", value.salutation);
    setValue("fname", value.firstName);
    setValue("lname", value.lastName);
    setValue("cname", value.companyName);
    setValue("dname", value.displayName);
    setValue("email", value.email);
    setValue("con", value.phone);
    setValue("website", value.website);
    setValue("pan", value.pan);
    setValue("openingBalance", value.openingBalance);
    setValue("curType", value.currency_Code);
    setValue("bAddress1", value.baAddress1);
    setValue("bAddress2", value.baAddress2);
    setValue("bCity", value.baCity);
    setValue("bState", value.baState);
    setValue("bCountry", value.baCountry);
    setValue("bZipCode", value.baZipCode);
    setValue("sAddress1", value.saAddress1);
    setValue("sAddress2", value.saAddress2);
    setValue("sCity", value.saCity);
    setValue("sState", value.saState);
    setValue("sCountry", value.saCountry);
    setValue("sZipCode", value.saZipCode);
    setValue("gsttreatment", value.gstTreatment);
    setValue("gstin", value.gstNumber);
    // setValue("date",format(new Date(value.createdOn), "MM-dd-yyyy"))

    if (
      value.baAddress1 === value.saAddress1 &&
      value.baAddress2 === value.saAddress2 &&
      value.baCity === value.saCity &&
      value.baState === value.saState &&
      value.baCountry === value.saCountry &&
      value.baZipCode === value.saZipCode
    ) {
      setIsSameAddress(true);
    } else {
      setIsSameAddress(false);
    }

    let findCurrency = currency.find((a) => a.value === value.currencySymbol);
    setSelectedCurrency(findCurrency?.value);
    setValue("curType", findCurrency?.value);
    // if (value.status === 'Active') {
    //     setValue('accntActive', true)
    // } else {
    //     setValue('accntActive', false)
    // }
    if (position) {
      setPosition(position);
    }
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
    // setCType("");
    resetAsyncForm();
    setCustomerID(0);

    setValue("salType", salutation[0].value);
    // setValue('accntActive', true)
    // setValue('accntCode',accntTypes[0].value)
    //  setValue('accntName',accntTypes[0].value)
    if (position) {
      setPosition(position);
    }
  };

  const handleDropdown = (e) => {
    console.log(e.target, "dropdoll");
    setSelectedCurrency(e.target.value);
  };

  const onHide = (name) => {
    setSelectedCurrency("");
    setIsSameAddress(false);
    dialogFuncMap[`${name}`](false);
  };
  const onSubmit = (data) => {
    setIsProcess(true);
    const opBal = Number(+data.openingBalance);
    // const formattedOpBal = parseFloat(opBal.toFixed(2));
    // const a = opBal.toFixed(2);
    // console.log(Number(a), "formattedOpBal");

    const datas = {
      ContactID: cId || 0,
      CustomerType: data.cType,
      Salutation: data.salType,
      FirstName: data.fname,
      LastName: data.lname,
      CompanyName: data.cname,
      DisplayName: data.dname,
      Email: data.email,
      Phone: data.con,
      SkypeNameNumber: "",
      Designation: "",
      Department: "",
      Website: data.website,
      PAN: data.pan,
      GSTTreatment: data.gsttreatment,
      GSTNumber: data.gstin,
      Supply: "",
      CurrencyName: "",
      CurrencyCode: "",
      CurrencySymbol: selectedCurrency,
      ExchangeRate: 90.45,
      PaymentTerms: "",
      TDS: "",
      Facebook: "",
      Twitter: "",
      Remarks: "",
      TenantID: GV.userDet.tenantDetail.tenantID,
      BAAttention: "",
      BACountry: data.bCountry.trim(),
      BAAddress1: data.bAddress1.trim(),
      BAAddress2: data.bAddress2.trim(),
      BACity: data.bCity.trim(),
      BAState: data.bState.trim(),
      BAZipCode: data.bZipCode.trim(),
      BAPhone: "",
      BAFax: "",
      SAAttention: "",
      SACountry: data.sCountry.trim(),
      SAAddress1: data.sAddress1.trim(),
      SAAddress2: data.sAddress2.trim(),
      SACity: data.sCity.trim(),
      SAState: data.sState.trim(),
      SAZipCode: data.sZipCode.trim(),
      SAPhone: "",
      SAFax: "",
      Status: "Active",
      OpeningBalance: Number(opBal),
      ClosingBalance: 5000.75,
      CreatedBy: "",
      Date: date,
    };
    console.log("df", datas);
    cos
      .SaveCustomer(datas)
      .then((res) => {
        // if (res.data.message === 'Successfully added Customer') {
        //     props.parentFunction()
        //     toast.current.show({ severity: 'success', summary: 'Successfully Created', detail: 'Successfully Created Customer' });
        // }
        // if (res.data.message === 'Successfully modified Customer') {
        //     props.parentFunction()
        //     toast.current.show({ severity: 'success', summary: 'Successfully Updated Customer' });
        // }
        if (res.data.statusCode === 200) {
          setIsProcess(false);
          console.log("success");
          props.parentFunction();
          toast.current.show({
            severity: "success",
            summary: res.data.message,
          });
          dialogFuncMap["displaynewCust"](false);
          setIsSameAddress(false);
          // props.data.fetchVendorList(1,10);
        } else {
          setIsProcess(false);
        }
      })
      .catch(function (error) {
        if (error.response.data.message === "Customer Already Exists") {
          setIsProcess(false);
          toast.current.show({
            severity: "error",
            summary: error.response.data.message,
            detail: "Customer Already Exists",
          });
        }
      });
  };

  //fields
  // const categories = [{ name: 'Business', key: 'A' }, { name: 'Individual', key: 'M' }];
  // const [ctype, setCType] = useState(categories[0]);
  // const custType = (e) => {
  //     setCType(e.value);
  //     setCustomerData(e.target.value);

  // }
  //fields

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide("displaynewCust")}
          className="p-button-text btn-cancel"
        />

        <Button
          type="submit"
          label={cId != "" || cId != 0 ? "Update" : "Save"}
          icon="pi pi-check"
          onClick={handleSubmit(onSubmit)}
          autoFocus
          className="btn btn-primary"
        />
        {/* <Button type="submit" label="Update" icon="pi pi-check" onClick={handleSubmit((onSubmit))} autoFocus className='btn btn-primary' /> */}
      </div>
    );
  };

  const checkAutoPass = (e) => {
    setGetAutoPass(e.target.checked);
  };

  const [customerDataBillingAdd, setCustomerDataBillingAdd] = useState({
    bAddress1: "",
    bAddress2: "",
    bCity: "",
    bState: "",
    bCountry: "",
    bZipCode: "",
  });

  const [customerDataShippingAdd, setCustomerDataShippingAdd] = useState({
    sAddress1: "",
    sAddress2: "",
    sCity: "",
    sState: "",
    sCountry: "",
    sZipCode: "",
  });

  const [isSameAddress, setIsSameAddress] = useState(false);

  // const handleCheckboxChange = (e) => {
  //   setIsSameAddress(e.target.checked);
  //   console.log(customerDataBillingAdd,'customerDataBillingAdd');
  //   if (e.target.checked) {
  //     setValue('sAddress1',customerDataBillingAdd.bAddress1 );
  //     setValue('sAddress2',customerDataBillingAdd.bAddress2 );
  //     setValue('sCity',customerDataBillingAdd.bCity );
  //     setValue('sState',customerDataBillingAdd.bState );
  //     setValue('sCountry',customerDataBillingAdd.bCountry );
  //     setValue('sZipCode',customerDataBillingAdd.bZipCode );
  //   } else {
  //     setValue('sAddress1','' );
  //     setValue('sAddress2','' );
  //     setValue('sCity','');
  //     setValue('sState','' );
  //     setValue('sCountry','' );
  //     setValue('sZipCode','' );
  //   }
  // };

  const handleCheckboxChange = (e) => {
    setIsSameAddress(e.target.checked);

    if (e.target.checked) {
      setValue("sAddress1", getValues("bAddress1"));
      setValue("sAddress2", getValues("bAddress2"));
      setValue("sCity", getValues("bCity"));
      setValue("sState", getValues("bState"));
      setValue("sCountry", getValues("bCountry"));
      setValue("sZipCode", getValues("bZipCode"));
    } else {
      setValue("sAddress1", "");
      setValue("sAddress2", "");
      setValue("sCity", "");
      setValue("sState", "");
      setValue("sCountry", "");
      setValue("sZipCode", "");
    }
  };

  useEffect(() => {
    setGstTreatmentDropDown(businessList);
  }, []);

  useEffect(() => {
    register("salType");
    // register("cType");
  }, [register]);

  return (
    <div>
      <Toast ref={toast}></Toast>
      <BlockUI blocked={isProcess}>
        <Dialog
          header={cId != "" || cId != 0 ? "Update Customer" : "New Customer"}
          visible={displaynewCust}
          onHide={() => onHide("displaynewCust")}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "50vw" }}
          footer={renderFooter("displayNewCustomer")}
        >
          <form>
            <div className="p-3 form">
              <div className="row">
                <label className="ps-0 required">Customer Type</label>
                <div className="col-12 ps-0">
                  <div className="p-0">
                    <label className="md-3 g-0 me-4">
                      <input
                        type="radio"
                        {...register("cType", { required: true })}
                        value="Business"
                        defaultValue={customerData?.cType}
                        onChange={(e) => setCustomerData(...e.target.value)}
                      />{" "}
                      Business
                    </label>
                    <label className="form-label">
                      <input
                        type="radio"
                        {...register("cType", { required: true })}
                        value="Individual"
                        defaultValue={customerData?.cType}
                        onChange={(e) => setCustomerData(...e.target.value)}
                      />{" "}
                      Individual
                    </label>
                  </div>
                  {errors.cType && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>

                  <div className="col-4 p-0">
                    <label className="ps-0 ">Salutation</label>
                    <Controller
                      render={({ field }) => (
                        <Dropdown
                          className="form-control"
                          options={salutation}
                          {...field}
                          appendTo="self"
                          optionLabel="label"
                          optionValue="value"
                        />
                      )}
                      control={control}
                      name="salType"
                      defaultValue={customerData?.salType}
                      // rules={{ required: "Salutation Type Required" }}
                    />
                    {/* <p className='text-danger'> {errors.salType?.message} </p> */}
                  </div>
                  <div className="col-4 px-1 ">
                      <label className="ps-0 required">First Name</label>
                      <input
                        type="text"
                        className="form-control "
                        defaultValue={customerData?.fname}
                        onChange={(e) => setCustomerData(...e.target.value)}
                        {...register("fname", { required: true })}
                      />
                      {errors.fname && (
                        <p className="text-danger">This field is required</p>
                      )}
                  </div>
                  <div className="col-4 p-0">
                    <label className="ps-0 required">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={customerData?.lname}
                      onChange={(e) => setCustomerData(...e.target.value)}
                      {...register("lname", { required: true })}
                    />
                    {errors.lname && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>

                <div className="col-6 p-0">
                    <label className="ps-0 required">Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={customerData?.cname}
                      onChange={(e) => setCustomerData(...e.target.value)}
                      {...register("cname", { required: true })}
                    />

                    {errors.cname && (
                      <p className="text-danger">This field is required</p>
                    )}
                </div>
                <div className="col-6 pe-0">
                    <label className="ps-0 required">Display Name</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={customerData?.dname}
                      onChange={(e) => setCustomerData(...e.target.value)}
                      {...register("dname", { required: true })}
                    />
                    {errors.dname && (
                      <p className="text-danger">This field is required</p>
                    )}
                </div>

                <div className="col-6 p-0">
                    <label className="ps-0 required">Email</label>
                    <input
                      type="text"
                      className=" form-control"
                      defaultValue={customerData?.email}
                      onChange={(e) => setCustomerData(...e.target.value)}
                      {...register("email", {
                        required: "This field is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <p className="text-danger">{errors.email.message}</p>
                    )}
                </div>
                <div className="col-6 pe-0">
                    <label className="ps-0 required">Contact</label>
                    <input
                      type="text"
                      className=" form-control"
                      defaultValue={customerData?.con}
                      onChange={(e) => setCustomerData(...e.target.value)}
                      {...register("con", { required: true })}
                    />
                    {errors.con && (
                      <p className="text-danger">This field is required</p>
                    )}
                </div>
                <div className="col-6 p-0">
                    <label className="ps-0 required">GST Treatment:</label>
                    <Controller
                      name="gsttreatment"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                            handleGSTtreatment(e);
                          }}
                          options={gstTreatmentDropDown}
                          optionLabel="name"
                          placeholder="Select your business type"
                          className="form-control"
                        />
                      )}
                    />
                    {errors.gsttreatment && (
                      <p className="text-danger">This field is required</p>
                    )}
                </div>
                <div className="col-6 pe-0">
                    <label className={`ps-0 ${isGstin ? "required" : ""} `}>
                      GSTIN
                    </label>{" "}
                    <div className="col-sm px-0">
                      <Controller
                        name="gstin"
                        control={control}
                        rules={{
                          required: isGstin
                            ? "GSTIN number is required"
                            : false,
                        }}
                        //   rules={{
                        //     required: isGstin
                        //       ? "GSTIN number is required"
                        //       : false,
                        //   }}
                        render={({ field }) => (
                          <input
                            type="text"
                            className="form-control"
                            name="gstin"
                            {...field}
                          />
                        )}
                      />
                    </div>
                    {errors.gstin && (
                      <p className="text-danger">This field is required</p>
                    )}
                </div>

                <div className="col-6 p-0">
                    <label className="ps-0">PAN</label>
                    <input
                      type="text"
                      className="col-md-8 form-control"
                      defaultValue={customerData?.pan}
                      onChange={(e) => setCustomerData(...e.target.value)}
                      {...register("pan", { required: false })}
                    />
                    <span className="col-md-3 ms-2"></span>
                    {errors.pan && (
                      <p className="text-danger">This field is required</p>
                    )}
                </div>
              </div>
              {isProcess && (
                <ProgressSpinner
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  strokeWidth="2"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              )}

              <div className="mt-3 ">
                <TabView
                  activeIndex={activeIndex}
                  onTabChange={(e) => setActiveIndex(e.index)}
                  className="customer-tab"
                >
                  <TabPanel header="Billing Address">
                    <div className="row ">
                      <div className="col-6 pe-0">
                        <label className="ps-0 required">Address 1</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={customerDataBillingAdd?.bAddress1}
                          // onChange={(e) => setCustomerData(...e.target.value)}
                          onInput={(e) => {
                            setCustomerDataBillingAdd((prevData) => ({
                              ...prevData,
                              bAddress1: e.target.value,
                            }));
                            setIsSameAddress(false);
                          }}
                          {...register("bAddress1", { required: true })}
                        />
                        {errors.bAddress1 && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                      <div className="col-6 pe-0">
                        <label className="ps-0">Address 2</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={customerDataBillingAdd?.bAddress2}
                          // onChange={(e) => setCustomerData(...e.target.value)}
                          onInput={(e) => {
                            setCustomerDataBillingAdd((prevData) => ({
                              ...prevData,
                              bAddress2: e.target.value,
                            }));
                            setIsSameAddress(false);
                          }}
                          {...register("bAddress2", { required: false })}
                        />
                        {errors.bAddress2 && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                      <div className="col-6 pe-0">
                        <label className="ps-0 required">City</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={customerDataBillingAdd?.bCity}
                          // onChange={(e) => setCustomerData(...e.target.value)}
                          onInput={(e) => {
                            setCustomerDataBillingAdd((prevData) => ({
                              ...prevData,
                              bCity: e.target.value,
                            }));
                            setIsSameAddress(false);
                          }}
                          {...register("bCity", { required: true })}
                        />
                        {errors.bCity && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>

                      <div className="col-6 pe-0">
                        <label className="ps-0 required">State</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={customerDataBillingAdd?.bState}
                          // onChange={(e) => setCustomerData(...e.target.value)}
                          onInput={(e) => {
                            setCustomerDataBillingAdd((prevData) => ({
                              ...prevData,
                              bState: e.target.value,
                            }));
                            setIsSameAddress(false);
                          }}
                          {...register("bState", { required: true })}
                        />
                        {errors.bState && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>

                      <div className="col-6 pe-0">
                        <label className="ps-0 required">Country</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={customerDataBillingAdd?.bCountry}
                          // onChange={(e) => setCustomerData(...e.target.value)}
                          onInput={(e) => {
                            setCustomerDataBillingAdd((prevData) => ({
                              ...prevData,
                              bCountry: e.target.value,
                            }));
                            setIsSameAddress(false);
                          }}
                          {...register("bCountry", { required: true })}
                        />
                        {errors.bCountry && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                      <div className="col-6 pe-0">
                        <label className="ps-0 required">Zip/Postal Code</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={customerDataBillingAdd?.bZipCode}
                          // onChange={(e) => setCustomerData(...e.target.value)}
                          onInput={(e) => {
                            setCustomerDataBillingAdd((prevData) => ({
                              ...prevData,
                              bZipCode: e.target.value,
                            }));
                            setIsSameAddress(false);
                          }}
                          {...register("bZipCode", { required: true })}
                        />
                        {errors.bZipCode && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                      <div className="row mx-0 mt-3 p-0">
                        <div className="form-group col d-flex align-items-baseline">
                          <input
                            type="checkbox"
                            name="checkbox"
                            checked={isSameAddress}
                            onChange={(e) => handleCheckboxChange(e)}
                          />
                          <label className="ps-0 ms-2 mt-0">
                            Shipping address as same as billing address
                          </label>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Shipping Address">
                    <div className="row ">
                      <div className="col-6 pe-0">
                        <label className="ps-0 ">Address 1</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={customerDataShippingAdd?.sAddress1}
                          {...register("sAddress1", { required: false })}
                          onInput={(e) => {
                            setCustomerDataShippingAdd((prevData) => ({
                              ...prevData,
                              sAddress1: e.target.value,
                            }));
                            setIsSameAddress(false);
                          }}
                        />
                      </div>
                      <div className="col-6 pe-0">
                        <label className="ps-0 ">Address 2</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={customerDataShippingAdd?.sAddress2}
                          {...register("sAddress2", { required: false })}
                          onInput={(e) => {
                            setCustomerDataBillingAdd((prevData) => ({
                              ...prevData,
                              sAddress2: e.target.value,
                            }));
                            setIsSameAddress(false);
                          }}
                        />
                      </div>
                      <div className="col-6 pe-0">
                        <label className="ps-0 ">City</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={customerDataShippingAdd?.sCity}
                          {...register("sCity", { required: false })}
                          onInput={(e) => {
                            setCustomerDataBillingAdd((prevData) => ({
                              ...prevData,
                              sCity: e.target.value,
                            }));
                            setIsSameAddress(false);
                          }}
                        />
                      </div>
                      <div className="col-6 pe-0">
                        <label className="ps-0 ">State</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={customerDataShippingAdd?.sState}
                          {...register("sState", { required: false })}
                          onInput={(e) => {
                            setCustomerDataBillingAdd((prevData) => ({
                              ...prevData,
                              sState: e.target.value,
                            }));
                            setIsSameAddress(false);
                          }}
                        />
                      </div>
                      <div className="col-6 pe-0">
                        <label className="ps-0 ">Country</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={customerDataShippingAdd?.sCountry}
                          {...register("sCountry", { required: false })}
                          onInput={(e) => {
                            setCustomerDataBillingAdd((prevData) => ({
                              ...prevData,
                              sCountry: e.target.value,
                            }));
                            setIsSameAddress(false);
                          }}
                        />
                      </div>
                      <div className="col-6 pe-0">
                        <label className="ps-0 ">Zip/Postal Code</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={customerDataShippingAdd?.sZipCode}
                          {...register("sZipCode", { required: false })}
                          onInput={(e) => {
                            setCustomerDataBillingAdd((prevData) => ({
                              ...prevData,
                              sZipCode: e.target.value,
                            }));
                            setIsSameAddress(false);
                          }}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Add more Details">
                    <div className="row">
                      <div className="col-6 pe-0">
                        <label className="ps-0 ">Website</label>
                        <input
                          type="text"
                          className=" form-control"
                          defaultValue={customerData.website}
                          onChange={(e) => setCustomerData(...e.target.value)}
                          {...register("website", { required: false })}
                        />
                      </div>
                      <div className="col-6 pe-0">
                        <label className="ps-0">Date</label>
                        <Controller
                          name="date"
                          control={control}
                          render={({ date }) => (
                            <Calendar
                              className="form-control p-0"
                              value={customerData.Date || date}
                              appendTo="self"
                              onChange={(e) => handleDateChange(e.value)}
                              showIcon
                            />
                          )}
                        />
                        {/* <Calendar className='form-control p-0' value={date} onChange={(e) => setDate(e.value)}></Calendar> */}
                      </div>
                      <div className="col-6 pe-0">
                        <label className="ps-0 ">Opening Balance</label>
                        <div className="input-group ps-0 pe-0">
                          <span className="input-group-text" id="basic-addon1">
                            {GV.userDet.tenantConfig.currency}
                          </span>
                          <input
                            type="text"
                            className=" form-control "
                            defaultValue={customerData.openingBalance}
                            onChange={(e) => setCustomerData(...e.target.value)}
                            {...register("openingBalance", {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-6 pe-0">
                        <label className="ps-0">Currency</label>
                        <Controller
                          render={({ field }) => (
                            <Dropdown
                              options={currency}
                              className="form-control"
                              {...field}
                              value={selectedCurrency}
                              onChange={(e) => {
                                console.log("e", e.optionLabel);
                                handleDropdown(e);
                              }}
                              appendTo="self"
                              optionLabel="label"
                              optionValue="value"
                            />
                          )}
                          control={control}
                          name="curType"
                          defaultValue={customerData.curType}
                        />
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>
          </form>
        </Dialog>
      </BlockUI>
    </div>
  );
});

export default NewCustomer;
