import React, { useEffect, useState, useRef } from "react";
import { IcomoonReact, iconSet, Sidebar, Button } from "../../../PrimeReact";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { VscDash } from "react-icons/vsc";
import { TiDelete } from "react-icons/ti";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import SecurityService from "../../../../services/security-service";
import { Column } from "primereact/column";
import { TreeTable } from 'primereact/treetable';
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { data } from "jquery";
import { ProgressSpinner } from "primereact/progressspinner";



export default function GroupDetail(props) {
  const ss = new SecurityService();
  const data = props.data.rowData;
  console.log(data,'us');
  const toast = useRef(null);
  const [serviceList,setServiceList]=useState([])

  
  console.log(data,'fromparent');
  const associateMem = [{ fullName: "Nithya" }];
  const [memberDrawer, setMemberDrawer] = useState(false);
  const [featureDrawer, setFeatureDrawr] = useState(false);
  const [addMemberDialog, setAddMemberDialog] = useState(false);
  const [addMemberDialogDropDown, setAddMemberDialogDropDown] = useState([]);
  const [selectedAddMemberDialogDropDown, setSelectedAddMemberDialogDropDown] =
    useState([]);
  const [associateService, setAssociateService] = useState([]);
  const [readChecked, setReadChecked] = useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const [serviceStates, setServiceStates] = useState({});
  const [selectedNodeKeysSide, setSelectedNodeKeysSide] = useState(null);
  const [selectedNodeKeysSide1, setSelectedNodeKeysSide1] = useState(null);
  const [sidebarDatas, setSidebarDatas] = useState([]);
  const [expandedKeys3, setExpandedKeys3] = useState([]);
  const [expandedKeys2, setExpandedKeys2] = useState([]);
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [userGroupMemberList, setUserGroupMemberList] = useState([]);
  const [renderComp,setRenderComp] = useState('Init')


  const backFunc = () => {
    props.data.handleBackGroup(false);
  };

  const handleCloseMemb = () => {
    setMemberDrawer(false);
  };
  const handleCloseFeature = () => {
    setFeatureDrawr(false);
    setAddMemberDialog(false);
  };
  const handleOpenMemb = () => {
    GetEntUserGroupMembers1(false);
    setAddMemberDialog(true);
  };
  const handleOpenFeature = () => {
    setFeatureDrawr(true);
  };

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Add User Member</span>
    </div>
  );

  const AddUserGroupMember = () => {
    const datas = {
      "UserGroupID":data.userGroupID,
      "AppUserID":selectedAddMemberDialogDropDown.appUserID
    }
    console.log(datas,'check');
    ss.AddUserGroupMember(datas).then((res) => {
      toast.current.show({ severity: 'success', summary: res.data.errors });
      GetEntUserGroupMembers(true);
    })
    setAddMemberDialog(false);

    
  }

  const footerContent = (
    <div className="text-end mt-3">
      <Button
        type="button"
        label="Cancel"
        icon="pi pi-times"
        onClick={handleCloseFeature}
        className="p-button-text btn-cancel ms-auto btn btn-danger me-1 pe-2"
      />
      <Button
        type="submit"
        label="Update"
        icon="pi pi-check"
        autoFocus
        onClick={AddUserGroupMember}
        className="btn btn-primary"
      />
    </div>
  );

  const handleCheckboxChange = (event, serviceName) => {
    const { checked } = event.target;
    setServiceStates((prevState) => ({
      ...prevState,
      [serviceName]: checked,
    }));
  };

  const datas = [
    {
      entServiceId: 434,
      serviceName: "Dashboard",
      serviceOrder: 1,
      globalOrderBy: 1,
      resourceAction: "/administrative/dashboard",
      resourceActionV2: null,
      menuIcon: "icon-dashboardnew",
      menuIconV2: null,
      resourceType: "menu_service",
      parentServiceId: 0,
      serviceDesc: "q",
      serviceGroup: null,
      read_Attri: true,
      write_Attri: false,
      delete_Attri: true,
      approve_Attri: false,
      export_Attri: true,
      isEntitlable: false,
      displayIndicator: null,
      isChild: false,
      isRoleRestricted: false,
      allowedRoles: null,
      kproductId: 1080,
      serviceCategory: null,
      srvCode: null,
      isAssociate: false,
      subMenu: [],
    },
    {
      entServiceId: 435,
      serviceName: "Project & Workspace",
      serviceOrder: 2,
      globalOrderBy: 7,
      resourceAction: "#",
      resourceActionV2: null,
      menuIcon: "icon-projectandworkspace",
      menuIconV2: null,
      resourceType: "menu_service",
      parentServiceId: 0,
      serviceDesc: "q",
      serviceGroup: null,
      read_Attri: false,
      write_Attri: true,
      delete_Attri: false,
      approve_Attri: true,
      export_Attri: false,
      isEntitlable: false,
      displayIndicator: null,
      isChild: true,
      isRoleRestricted: false,
      allowedRoles: null,
      kproductId: 1080,
      serviceCategory: null,
      srvCode: null,
      isAssociate: false,
      subMenu: [
        {
          entServiceId: 436,
          serviceName: "Projects",
          serviceOrder: 1,
          globalOrderBy: 65,
          resourceAction: "/administrative/project",
          resourceActionV2: null,
          menuIcon: null,
          menuIconV2: null,
          resourceType: "menu_service",
          parentServiceId: 435,
          serviceDesc: null,
          serviceGroup: null,
          read_Attri: true,
          write_Attri: true,
          delete_Attri: true,
          approve_Attri: true,
          export_Attri: true,
          isEntitlable: false,
          displayIndicator: null,
          isChild: false,
          isRoleRestricted: false,
          allowedRoles: null,
          kproductId: 1080,
          serviceCategory: null,
          srvCode: null,
          isAssociate: false,
          subMenu: null,
        },
      ],
    },
  ];

 

  const GetAssocateUserGroupServices = () => {
    setIsProcess(true);
    console.log(data, "dd");
    const datas = {
      UserGroupID: data.userGroupID,
    };
    console.log(datas, "datas");
    ss.GetAssocUserGroupServices(datas).then((res) => {
    setIsProcess(false);
      function recursiveCollect(urlArray, collectionArray) {
        urlArray.forEach((item) => {
          if (item.isAssociate) {
            collectionArray.push(item.entServiceId);
          }
          if (Array.isArray(item.subMenu)) {
            recursiveCollect(item.subMenu, collectionArray);
          }
        });
      }
      const urlCollectionArray = [];
      recursiveCollect(res.data?.data, urlCollectionArray);

      const filteredData = urlCollectionArray.reduce((acc, b) => {
        acc[b] = {
          checked: true,
          partialChecked: false,
        };
        return acc;
      }, {});
      const treeTableData = res.data?.data?.map((a, i) => ({
        key: a.entServiceId,
        index: i,
        data: {
          serviceName: a.serviceName,
        },
        children: a.subMenu.map((b, j) => ({
          key: b.entServiceId,
          index: j,
          data: {
            serviceName: b.serviceName,
          },
        })),
      }));
      setSidebarDatas(treeTableData);
      setSelectedNodeKeysSide(filteredData);
    });
  };

  
  const assoFeatureService = (e) => {
    console.log("assoservice", e.value);
  }
  
const [serviceChanges,setServieChanges] = useState([])

   const handleCheckboxChanges = (i, key, type, e) => {

    const { checked } = e.target;
      const updatedCheckboxes = checkBoxes;
      if (!updatedCheckboxes[i]) {
        updatedCheckboxes[i] = {}; 
      }

      if (type === 'Read') {
        updatedCheckboxes[i].readAccess = checked;
      } else if (type === 'Write') {
        updatedCheckboxes[i].writeAccess = checked;
      } else if (type === 'Delete') {
        updatedCheckboxes[i].deleteAccess = checked;
      } else if (type === 'Approve') {
        updatedCheckboxes[i].approveAccess = checked;
      } else if (type === 'Export') {
        updatedCheckboxes[i].exportAccess = checked;
      }  
      let val = serviceChanges
     const queue = val.findIndex(x=> x.srvUserGroupID === updatedCheckboxes[i].srvUserGroupID)

      if(queue < 0){
        val.push(updatedCheckboxes[i])
      }else{
        val.splice(queue,1);
        val.push(updatedCheckboxes[i])
      }
      setServieChanges(val)
      setServiceList(updatedCheckboxes)
      setRenderComp('Done')
      checkboxIn();
  
  };

  const handleCheckboxChangesSub = (i,j, key, type, e) => {

    const { checked } = e.target;
      const updatedCheckboxes = checkBoxes;
      if (!updatedCheckboxes[i]) {
        updatedCheckboxes[i] = {}; 
      }

      if (type === 'Read') {
        updatedCheckboxes[i].objuser[j].readAccess = checked;
      } else if (type === 'Write') {
        updatedCheckboxes[i].objuser[j].writeAccess = checked;
      } else if (type === 'Delete') {
        updatedCheckboxes[i].objuser[j].deleteAccess = checked;
      } else if (type === 'Approve') {
        updatedCheckboxes[i].objuser[j].approveAccess = checked;
      } else if (type === 'Export') {
        updatedCheckboxes[i].objuser[j].exportAccess = checked;
      }  
      let val1 = serviceChanges
      const queue1 = val1.findIndex(x=> x.srvUserGroupID === updatedCheckboxes[i].srvUserGroupID)
       if(queue1 < 0){
         val1.push(updatedCheckboxes[i])
       }else{
         val1.splice(queue1,1);
         val1.push(updatedCheckboxes[i])
       }
      setServieChanges(val1)
      setServiceList(updatedCheckboxes)
      setRenderComp('Done')
      checkboxIn();
  
  };
  const GetServiceUserGroupData = () => {
    ss.GetServiceUserGroup(data.userGroupID).then((res) => {
    
    let check = res.data.data
       console.log(check,'checkval')
      setCheckBoxes(check)
      setServiceList(res.data.data);
      setRenderComp('Done')
    console.log(serviceList,'ssssone',res.data.data) ; 
    
    checkboxIn();

    })
  }

  function checkboxIn(){
    const associatedDetails = serviceList.map((a,i) => ({
      key:a.srvUserGroupID,
      data: {
        serviceName: a.serviceName,
        Read: a?.readAccess !== null ? <Checkbox checked={a.readAccess}  onChange={(e) => handleCheckboxChanges(i, a.srvUserGroupID, 'Read', e)}></Checkbox> : <VscDash /> ,
        Write: a.writeAccess !== null ? <Checkbox checked={a.writeAccess} onChange={(e) => handleCheckboxChanges(i, a.srvUserGroupID, 'Write', e)}></Checkbox> : <VscDash />,
        Delete: a.deleteAccess !== null ? <Checkbox checked={a.deleteAccess} onChange={(e) => handleCheckboxChanges(i, a.srvUserGroupID, 'Delete', e)}></Checkbox> : <VscDash />,
        Approve: a.approveAccess !== null ? <Checkbox checked={a.approveAccess} onChange={(e) => handleCheckboxChanges(i, a.srvUserGroupID, 'Approve', e)}></Checkbox> : <VscDash />,
        Export: a.exportAccess !== null ? <Checkbox checked={a.exportAccess} onChange={(e) => handleCheckboxChanges(i, a.srvUserGroupID, 'Export', e)}></Checkbox> : <VscDash />,
        icon: (<i className="pi pi-times userrole-remove me-2" onClick={() => saveServicesDele(a)}></i>),
      },
      children: a.objuser.map((b,j) => ({
        key:b.entServiceID,
        data: {
        serviceName: b.serviceName,
        Read: b.readAccess !== null ? <Checkbox checked={b.readAccess} onChange={(e) => handleCheckboxChangesSub(i,j, b.entServiceID, 'Read', e)}></Checkbox> : <VscDash />,
        Write: b.readAccess !== null ? <Checkbox checked={b.writeAccess} onChange={(e) => handleCheckboxChangesSub(i,j, b.srvUserGroupID, 'Write', e)}></Checkbox> : <VscDash />,
        Delete: b.deleteAccess !== null ? <Checkbox checked={b.deleteAccess} onChange={(e) => handleCheckboxChangesSub(i,j, b.srvUserGroupID, 'Delete', e)}></Checkbox> : <VscDash />,
        Approve: b.approveAccess !== null ? <Checkbox checked={b.approveAccess} onChange={(e) => handleCheckboxChangesSub(i,j, b.srvUserGroupID, 'Approve', e)}></Checkbox> : <VscDash />,
        Export: b.exportAccess !== null ? <Checkbox checked={b.exportAccess} onChange={(e) => handleCheckboxChangesSub(i,j, b.srvUserGroupID, 'Export', e)}></Checkbox> : <VscDash />,
        icon: (<i className="pi pi-times userrole-remove me-2" onClick={() => saveServicesDele(b)}></i>),
        }
      }))
    }))
    setAssociateService(associatedDetails);
  }

  function saveServices(){
    setIsProcess(true);
    const payload = []
    serviceChanges.forEach(x=>{
      const data ={
        "EntServiceID": x.entServiceID,
        "UserGroupID": x.userGroupID,
        "ReadAccess": x.readAccess,
        "WriteAccess": x.writeAccess,
        "DeleteAccess": x.deleteAccess,
        "ApproveAccess": x.approveAccess,
        "ExportAccess": x.exportAccess,
        "IsType": "update"
      }
      payload.push(data)
    })
    ss.UpdateServiceUserGroup(payload).then(res=>{
    setIsProcess(false);
      if(res.data.data === 'Updated Successfully'){
        toast.current.show({ severity: 'success', summary: 'Successfully Updated' });
        setServieChanges([])
        GetServiceUserGroupData()
      }else{
        toast.current.show({ severity: 'error', summary: 'Add or remove the service'});

      }
    })
  }


  function saveServicesDele(item){
    setIsProcess(true);
      const data =[{
        "EntServiceID": item.entServiceID,
        "UserGroupID": item.userGroupID,
        "ReadAccess": item.readAccess,
        "WriteAccess": item.writeAccess,
        "DeleteAccess": item.deleteAccess,
        "ApproveAccess": item.approveAccess,
        "ExportAccess": item.exportAccess,
        "IsType": "Delete"
      }]
    ss.UpdateServiceUserGroup(data).then(res=>{
      setIsProcess(false);
      if(res.data.data === 'Updated Successfully'){
        toast.current.show({ severity: 'success', summary: 'Successfully Updated' });
        setServieChanges([])
        GetServiceUserGroupData()

      }else{
        toast.current.show({ severity: 'error', summary: 'Failed to Update' });

      }
    })
  }

  const renderHeader = () => {
    return (
      <div className="custom-sidebar-header d-flex py-2">
        <h4>Add/Update User</h4>
        <button
          onClick={handleCloseMemb}
          className="p-sidebar-close p-link ms-auto"
        >
          <span className="pi pi-times" />
        </button>
      </div>
    );
  };

  const renderHeader1 = () => {
    return (
      <div className="custom-sidebar-header d-flex py-2">
        <h4>Add/Update Feature/Services</h4>
        <button
          onClick={handleCloseFeature}
          className="p-sidebar-close p-link ms-auto"
        >
          <span className="pi pi-times" />
        </button>
      </div>
    );
  };

  const handleCheckIsCheckBox = (e) => {
    console.log("sk", e.value);
    // setSelectedNodeKeys(e.value);
    setSelectedNodeKeysSide(e.value);
  };


  const GetEntUserGroupMembers = (status) => {
    setIsProcess(true);
    const datas = 
    {
        "TenantID" : data.tenantID,
        "UserGroupID" : data.userGroupID,
        "IsMembersList" : status,
        "RoleName":data.roleName,
        "EntityID":data.entityID,
        "EntityType":data.entityType
    }
    ss.GetEntUserGroupMembers(datas).then((res) => {
      setIsProcess(false)
      console.log(res.data.data,'getEntUser');
      setUserGroupMemberList(res.data.data)
      setAddMemberDialogDropDown(res.data.data)
    }).catch((err) => {
      console.log(err,'err');
    })
  }

  const GetEntUserGroupMembers1 = (status) => {
    setIsProcess(true)
    const datas = 
    {
        "TenantID" : data.tenantID,
        "UserGroupID" : data.userGroupID,
        "IsMembersList" : status,
        "RoleName":data.roleName,
        "EntityID":data.entityID,
        "EntityType":data.entityType
    }
    ss.GetEntUserGroupMembers(datas).then((res) => {
    setIsProcess(false)
      setAddMemberDialogDropDown(res.data.data)
    }).catch((err) => {
      console.log(err,'err');
    })
  }

  const celectMemberDropDown =(e) => {
    setSelectedAddMemberDialogDropDown(e.value)
  }

  const AddServiceUserGroup = () => {
    setIsProcess(true)
    const datas = {
      "UserGroupID":data.userGroupID,
      "RoleID":data.roleID,
      "EntServices":Object.keys(selectedNodeKeysSide).join(",")
  }
  console.log(datas,'datas');
  ss.AddServiceUserGroup(datas).then((res) => {
    setIsProcess(false)
    toast.current.show({
      severity: "success",
      detail: res.data.errors,
    });
    setFeatureDrawr(false);
    GetServiceUserGroupData(true);
  })
  }

  const DeleteUserGroupMember = (e) => {
    ss.DeleteUserGroupMember(data.userGroupID, e.appUserID).then((res) => {
      console.log(res.data,'res');
      toast.current.show({
        severity: "success",
        detail: res.data.errors,
      });
      GetEntUserGroupMembers(true);
    })
     }


   

  useEffect(() => {
    if(renderComp === 'Init'){
      GetAssocateUserGroupServices();
      GetServiceUserGroupData();
      GetEntUserGroupMembers(true);
    }
    if(renderComp === 'Done'){
      setTimeout(() => { 
          checkboxIn();

      }, 1000);
    }
  }, [renderComp,serviceList]);

  return (
    <div>
      <Toast ref={toast} />
      <div className="row">
        <h5 className="col my-auto">User Group Detail View</h5>
        <button
          className="bg-danger text-white col-auto ms-auto p-2 rounded"
          onClick={backFunc}
        >
          Back to User List
        </button>
      </div>
      <hr></hr>
      <div className="bg-green p-3 my-2">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md">
              <span className="fw-bold">Group Name:</span>{" "}
              <span>{data.userGroup}</span>
            </div>
            <div className="my-1 col-md">
              <span className="fw-bold">Group Description:</span>{" "}
              <span>{data.userGroupDesc}</span>
            </div>
            <div className="col-md">
                <span className="fw-bold col-md">User Type:</span>{" "}
                <span>{data.userType}</span>
            </div>
            <div className="ms-5 col-md">
                <span className="fw-bold ms-auto">User Role:</span>{" "}
                <span>{data.roleName}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0">
        <div className="col bg-light px-2 py-3 me-2">
          <div className="bg-white p-3 h-100">
            <div className="d-flex mb-2">
              <h6 className="fw-bold">Group Members </h6>
              <h6 className="ms-auto">
                <Button
                  label="Add Member"
                  icon="pi pi-plus"
                  className="btn btn-primary"
                  onClick={handleOpenMemb}
                />
              </h6>
            </div>

            {userGroupMemberList.map((item,i) => {
              return (
                <div key={i} className="card border p-2 rounded-0">
                  <div className="d-flex justify-content-between">
                    <div>
                      {" "}
                      <label>{item.fullName} </label>
                      
                    </div>
                    <div>
                    <i
                        className="pi pi-times userrole-remove me-2"
                        onClick={(e) => DeleteUserGroupMember(item)}
                      ></i>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col bg-light px-2 py-3" style={{height:"500px"}}>
          <div className="bg-white green-tree p-3 h-100" style={{overflow:"auto"}}>
            <div className="d-flex mb-2">
              <h6 className="fw-bold">Associated Features / Services</h6>
              <h6 className="ms-auto">
                <Button
                  label="Associated Features / Services"
                  icon="pi pi-plus"
                  className="btn btn-primary"
                  onClick={handleOpenFeature}
                />
                {/* <IcomoonReact iconSet={iconSet} color="#444" size={16} icon="edit" className='pointer' onClick={handleOpenFeature}/> */}
              </h6>
            </div>

            {/* <Checkbox onChange={(e) => setReadChecked(e.checked)} checked={readChecked}></Checkbox> */}

            <TreeTable
              stripedRows
              lazy
              value={associateService}
              selectionKeys={selectedNodeKeysSide1}
              onSelectionChange={(e) => assoFeatureService(e.value)}
              expandedKeys={expandedKeys2}
              onToggle={(e) => setExpandedKeys2(e.value)}
              // expanded={true}
              // tableStyle={{ minWidth: "100%" }}
              className="tableheader"
              style={{overflow:"auto"}}
            >
              <Column
                field="serviceName"
                header="Service Name"
                expander
                style={{ width: "20%" }}
              ></Column>
              <Column field="Read" header="Read"></Column>
              <Column field="Write" header="Write"></Column>
              <Column field="Delete" header="Delete"></Column>
              <Column field="Approve" header="Approve"></Column>
              <Column field="Export" header="Export"></Column>
              <Column field="icon" header="" className="text-center"></Column>
            </TreeTable>
            {isProcess && (
                <ProgressSpinner
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  strokeWidth="2"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              )}
            <div className="text-end">
             {associateService.length !== 0 && <Button label="Save" className="btn btn-primary mt-2" onClick={saveServices} />} 
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        visible={memberDrawer}
        position="right"
        className="security-drawer"
        onHide={handleCloseMemb}
      >
        <div slot="header">{renderHeader()}</div>
        <div className="py-3 rounded mt-4">
          <div className="bg-green p-3 my-2">
            <div>
              <span className="fw-bold">Group Name:</span>{" "}
              <span>{data.userGroup}</span>
            </div>
            <div className="my-1">
              <span className="fw-bold">Group Description:</span>{" "}
              <span>{data.userGroupDesc}</span>
            </div>
            <div className="d-flex w-75">
              <div>
                <span className="fw-bold">User Type:</span>{" "}
                <span>{data.userType}</span>
              </div>
              <div className="ms-5">
                <span className="fw-bold ms-auto">User Role:</span>{" "}
                <span>{data.roleName}</span>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-2 d-flex">
            <h6 className="text-dark">Group Members</h6>
            <h6 className="ms-auto">+ Add Member</h6>
          </div>
          <div className="row mx-0 mb-3 p-0 ">
            <div className="card p-2 ">
              <div className="d-flex">
                <span>Nithya</span>
                <span className="ms-auto bg-danger text-white px-1 py-0">
                  x
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="text-end mt-3">
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={handleCloseMemb}
            className="p-button-text btn-cancel ms-auto btn btn-danger me-1"
          />
          <Button
            type="submit"
            label="Update"
            icon="pi pi-check"
            autoFocus
            className="btn btn-primary"
          />
        </div>
      </Sidebar>
      <Sidebar
        visible={featureDrawer}
        position="right"
        className="security-drawer pSideBarWidth"
        onHide={handleCloseFeature}
      >
        <div slot="header">{renderHeader1()}</div>
        <div className="py-3 rounded">
          <div className="bg-green p-3 my-2">
            <div>
              <span className="fw-bold">Group Name:</span>{" "}
              <span>{data.userGroup}</span>
            </div>
            <div className="my-1">
              <span className="fw-bold">Group Description:</span>{" "}
              <span>{data.userGroupDesc}</span>
            </div>
            <div className="d-flex w-100">
              <div>
                <span className="fw-bold">User Type:</span>{" "}
                <span>{data.userType}</span>
              </div>
              <div className="ms-5">
                <span className="fw-bold ms-auto">User Role:</span>{" "}
                <span>{data.roleName}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="my-3">
        <h6 className="my-3">Check all the features / Services to associate to this user group</h6>

            <TreeTable
              className="border-0 service-tbl mb-3"
              value={sidebarDatas}
              resizableColumns
              selectionMode="checkbox"
              expandedKeys={expandedKeys3}
              onToggle={(e) => setExpandedKeys3(e.value)}
              selectionKeys={selectedNodeKeysSide}
              onSelectionChange={(e) => {
                handleCheckIsCheckBox(e);
              }}
              tableStyle={{ width: "100%", height: "100%" }}
            >
              <Column
                field="serviceName"
                expander
                className="service-tbl-name userrole sidetree-header"
              ></Column>
            </TreeTable>
          </div>
        <div className="text-end mt-3">
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={handleCloseFeature}
            className="p-button-text btn-cancel ms-auto btn btn-danger me-1"
          />
          <Button
            label="Update"
            icon="pi pi-check"
            autoFocus
            className="btn btn-primary"
            onClick={AddServiceUserGroup}
          />
        </div>
      </Sidebar>

      <Dialog
        visible={addMemberDialog}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "25%" }}
        onHide={() => setAddMemberDialog(false)}
      >
        <div className="card flex justify-content-center mt-3">
          <Dropdown
            value={selectedAddMemberDialogDropDown}
            onChange={(e) => celectMemberDropDown(e)}
            options={addMemberDialogDropDown}
            optionLabel="fullName"
            placeholder="Choose a Member"
            
          />
        </div>
      </Dialog>
    </div>
  );
}
