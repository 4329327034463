import { format } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import React from "react";
import { useEffect } from 'react';
import { useState } from 'react';

function LSCOA({obj,setSearchInit}) {
    console.log(obj)
    const [ledger,setLedger] = useState(obj)
    const [data,setData] = useState(ledger.chartofAccountDetails.accountDetails.ledgerTransaction);
    const [totalRecordsIn,settotalRecordsIn]=useState(ledger.chartofAccountDetails.accountDetails.ledgerTransaction.length)
   
   
    const [first1, setFirst1] = useState(0);
    const [tdataLoad, setTdataLoad] = useState(true);
    const [requestData, setRequestData] = useState([]);
    // const [data, setData] = useState([]);
    // const [totalRecordsIn, setTotalRecordsIn] = useState(0);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [sortName, setSortName] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [searchVal, setSearchVal] = useState('');
    const [isMounted, setIsMounted] = useState(true);

    const onCustomPage1 = (event) => {
        setFirst1(event.first);
        const val = event.page + 1;
        const size = event.rows;
        setSizePerPage(size);
        setPage(val);
        // fetchCoaList(val, size, searchVal, '', '', statusSelected, accountSelected);
    }

    const basiccolumns = [
        { field: 'accountName', header: 'Account Name',disabled:true,body:"" },
            { field: 'contactName', header: 'Contact',disabled:true,body:"" },
            { field: 'debits', header: 'Debit',disabled:true,body:""  },
            { field: 'credits', header: 'Credit',disabled:true ,body:"" },
            { field: 'availableBalance', header: 'Amount',disabled:true,body:""  },
            
    
        ]
        const columns = [
        { field: 'accountName', header: 'Account Name',disabled:true,body:"" },
            { field: 'transactionNumber', header: 'Transaction No.',disabled:false,body:""  },
            { field: 'contactName', header: 'Contact',disabled:true,body:""  },
            { field: 'voucherNo', header: 'Voucher No.' ,disabled:false,body:"" },
            { field: 'description', header: 'Description' ,disabled:false,body:"" },
            { field: 'openingBalance', header: 'Opening Balance',disabled:false ,body:"" },
            { field: 'debits', header: 'Debit',disabled:true ,body:"" },
            { field: 'credits', header: 'Credit',disabled:true ,body:"" },
            { field: 'availableBalance', header: 'Amount',disabled:true,body:""  },
            
    
        ];
        
        const [selectedColumns, setSelectedColumns] = useState(basiccolumns);
        const onColumnToggle = (event) => {
            let selectedColumns = event.value;
            let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
            setSelectedColumns(orderedSelectedColumns);
        }
    
        const dynamicColumns = selectedColumns.map((col, i) => {
            return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={col.body } ></Column>
        });
    useEffect(()=>{
        console.log(ledger.chartofAccountDetails.accountDetails.coaName)

    },[])
  return <div>
      <div className="d-flex justify-content-between">
            <div>
            <h5>Detail Ledger</h5>

            </div>
            <div className="d-flex justify-content-end mb-1">
                    <button  className='btn btn-danger' onClick={e=>{setSearchInit(false)}}>Back</button>
            </div>
      </div>
        <div className="card">
            <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-2" style={{borderBottom:'2px solid #cbcbcb'}}>
                                <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6"  style={{borderRight:'2px solid #cbcbcb'}}>
                                                    <b>Account Name :  </b> 
                                                    {ledger.chartofAccountDetails.accountDetails.coaName}  <br/>
                                                    <b>Ledger Duration : </b> {ledger.inputParams.period}

                                                </div>
                                                <div className="col-md-6">
                                                    <b> Account Code : </b>   {ledger.chartofAccountDetails.accountDetails.code}  <br/>
                                                    <b> Opening Balance : </b>   {ledger.chartofAccountDetails.accountDetails.openingBalance}
                                               
                                                </div>

                                                
                                            </div>
                                </div>
                            </div>

                            <div className='account-table mt-4'>
                            <div className="d-flex justify-content-end mm mb-1">
                                <MultiSelect  value={selectedColumns} options={columns} optionLabel="header" optionDisabled="disabled" onChange={onColumnToggle} style={{ width: '3em' }} scrollHeight="600px" dropdownIcon="pi pi-table"
                                    tooltip="Custom Columns" tooltipOptions={{ position: 'top' }} showSelectAll={false} />
                                </div>
                                <DataTable className=' chofacc' stripedRows value={data} 
                                // responsiveLayout="scroll"
                                 size="small" 
                                    paginator lazy totalRecords={totalRecordsIn}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rowsPerPageOptions={[10, 20, 50, totalRecordsIn]}
                                    first={first1} rows={sizePerPage} onPage={onCustomPage1}
                                    // onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                                >
                                      <Column field="transactionDate" header="Date"  body={(row)=><div> {format(new Date(row.transactionDate),'MM/dd/yyyy')}</div>} ></Column>
                                    {/* <Column field="accountName" header="Account" />
                                    <Column field="description" header="Description" />
                                    <Column field="voucherNo" header="VoucherNo." />

                                    <Column field="contactName" header="Contact Name" />
                                    <Column field="transactionType" header="Transaction Type" />
                                    <Column field="credits" header="Credit" />
                                    <Column field="debits" header="Debit" />
                                    <Column field="openingBalance" header="Opening Balance"></Column>
                                    <Column field="availableBalance" header="Amount"></Column> */}

                                    {dynamicColumns}
                                    {/* <Column field="actionBodyTemplate" header="Action" body={actionBodyTemplate} /> */}
                                </DataTable>
                            </div>

                        </div>
                    </div>
            </div>
        </div>
  </div>;
}

export default LSCOA;
