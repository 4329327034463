import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";

const PurchaseExpense = () => {
  const [date, setDate] = useState(null);
  const [vendorcategory, setVendorcategory] = useState(null);
  const [expensetype, setExpensetype] = useState(null);
  const [ingredient, setIngredient] = useState(""); //GST radio button

  const [formData, setFormData] = useState([
    {
      id: 1,
      hsn: "",
      productName: "",
      productQuantity: "",
      gst: "Yes", // Default value
      sgst1: "",
      sgst2: "",
      amount: "",
    },
  ]);

  const handleChange = (index, e, fieldName) => {
    console.log(e, "cloneCopy1");
    console.log(index, "cloneCopy2");
    console.log(fieldName, "cloneCopy3");
    const value = e.target;
    const newFormFields = [...formData];
    newFormFields[index] = {
      ...newFormFields[index],
      [fieldName]: value,
    };
    // newFormFields[index] = value;
    setFormData(newFormFields);
  };
  // const handleChange = (e) => {
  //     console.log(e.target.name);

  // };

  const addProduct = (e) => {
    e.preventDefault();
    const newFormFields = [...formData];
    const newId = formData[formData.length - 1].id + 1;
    newFormFields.push({
      id: newId,
      hsn: "",
      productName: "",
      quantity: "",
      gst: "Yes",
      sgst1: "",
      sgst2: "",
      amount: "",
    });
    setFormData(newFormFields);
    console.log(newId, "newId");
  };
  const toast = useRef(null);
  const onUpload = () => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  };

  return (
    <div>
      <div className="card mb-2">
        <div className="card-body">
          <div className="d-flex justify-content-between flex-wrap">
            <h4>New Purchase</h4>
          </div>
        </div>
      </div>
      <div>
        {/* <div className="d-flex justify-content-between np">
          <h4 className="kk-bold m-0">New Purchase</h4>
          <Button
            type="submit"
            label="Save"
            autoFocus
            className="btn btn-primary"
          />
        </div> */}
      </div>
      <form>
        <div className="bg-white shadow-sm chart-of-account h-100 p-3">
          <div className="row mb-4">
            <div className="col-8 ">
              <div className="  bag shadow-sm journal-of-account p-4">
                <div className="row gap-3">
                  <div className="col-sm-3  mx-4">
                    <div className="row">
                      <label className="ps-0 required">Purchase No:</label>
                      <input
                        type="text"
                        className="form-control sm-2"
                        //  defaultValue={journalData.JournalNumber}  onChange={(e) => setJournalData(e.target.value)}
                      />
                      {/* {errors.jno && <p className="text-danger">Journal is required.</p>} */}
                    </div>
                  </div>
                  <div className="col-sm-3  mx-4">
                    <div className="row">
                      <label className="ps-0 required">Invoice No:</label>
                      <input
                        type="text"
                        className="form-control sm-2"
                        //  defaultValue={journalData.JournalNumber}  onChange={(e) => setJournalData(e.target.value)}
                      />
                      {/* {errors.jno && <p className="text-danger">Journal is required.</p>} */}
                    </div>
                  </div>

                  <div className="col-sm-3  mx-4">
                    <div className="row">
                      <label htmlFor="buttondisplay"> Date Of Create </label>
                      <Calendar
                        id="buttondisplay"
                        value={date}
                        onChange={(e) => setDate(e.value)}
                        showIcon
                        className="p-0"
                      />
                    </div>
                  </div>

                  <div className="col-sm-3  mx-4">
                    <div className="row">
                      <label className="ps-0 required">Vendor Category:</label>
                      <Dropdown
                        value={vendorcategory}
                        onChange={(e) => setVendorcategory(e.value)}
                        optionLabel="name"
                        placeholder="Select a City"
                        className="w-full md:w-14rem"
                      />
                    </div>
                  </div>
                  <div className="col-sm-3  mx-4">
                    <div className="row">
                      <label className="ps-0 required">Expense Type:</label>
                      <Dropdown
                        value={expensetype}
                        onChange={(e) => setExpensetype(e.value)}
                        optionLabel="name"
                        placeholder="Select a City"
                        className="w-full md:w-14rem"
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="row ">
                      <label className="ps-0 required mx-4">Description</label>
                      <textarea type="text" className="form-control mx-4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="bag shadow-sm journal-of-account p-4">
                <div className="col ">
                  <div className=" row mt-1 align-items-center justify-content-end">
                    <label className="col-sm-3 col-form-label text-end">
                      Amount
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="text-end form-control sm-2"
                      />
                    </div>
                  </div>
                  <div className=" row mt-1 align-items-center justify-content-end">
                    <label className="col-sm-3 col-form-label text-end ">
                      CGST
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="text-end form-control sm-2"
                      />
                    </div>
                  </div>
                  <div className=" row mt-1 align-items-center justify-content-end">
                    <label className="col-sm-3 col-form-label text-end">
                      SGST
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="text-end form-control sm-2"
                      />
                    </div>
                  </div>
                  <div className=" row mt-1 align-items-center justify-content-end">
                    <label className="col-sm-3 col-form-label text-end">
                      Total Amount
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="text-end form-control sm-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4 mx-auto">
            <h6 className="bg-success text-white m-2 p-2">VENDOR DETAIL:</h6>
            <div class="col-sm-12 p-0 m-2 gap-5">
              <div className="row d-flex mx-auto" style={{ flexFlow: "row" }}>
                <div className="col-sm-6 ps-0">
                  <label className="ps-0 required">Vendor Name</label>
                  <input
                    type="text"
                    className="form-control sm-2"
                    //  defaultValue={journalData.JournalNumber}  onChange={(e) => setJournalData(e.target.value)}
                  />
                  {/* {errors.jno && <p className="text-danger">Journal is required.</p>} */}
                </div>
                <div className="col-sm-6">
                  <label className="ps-0 ">Contact Number:</label>
                  <input
                    type="text"
                    className="form-control sm-2"
                    disabled
                    //  defaultValue={journalData.JournalNumber}  onChange={(e) => setJournalData(e.target.value)}
                  />
                  {/* {errors.jno && <p className="text-danger">Journal is required.</p>} */}
                </div>
              </div>
            </div>

            <div class="col-sm-12  p-0 m-2 gap-5 mb-3">
              <div className="row d-flex mx-auto" style={{ flexFlow: "row" }}>
                <div className="col-sm-6 ps-0">
                  <label htmlFor="buttondisplay"> Email </label>
                  <input
                    type="text"
                    className="form-control sm-2"
                    disabled
                    //  defaultValue={journalData.JournalNumber}  onChange={(e) => setJournalData(e.target.value)}
                  />
                </div>
                <div className="col-sm-6">
                  <label className="ps-0 ">Address</label>
                  <input
                    type="text"
                    className="form-control sm-2"
                    disabled
                    //  defaultValue={journalData.JournalNumber}  onChange={(e) => setJournalData(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4 p-0 mx-auto">
            <h6 className="bg-success text-white m-2 p-2">
              DESCRIPTION OF GOODS
            </h6>
            {formData.map((data, index) => (
              <div key={index} className="row mb-4 mx-auto p-0">
                <div className="col-12 p-0">
                  <div className="bgp shadow-sm journal-of-account m-2 pl-0 pr-4 pt-4 pb-4 me-0">
                    <div className="row mx-auto">
                      <div className="col-sm-12 ">
                        <div className="row mx-auto">
                          <div className="col-sm">
                            <label className="ps-0 ">HSN / SAC:</label>
                            <input
                              type="text"
                              className="form-control sm-2"
                              name="hsn"
                              value={data.hsn}
                              onChange={(e) => handleChange(e, index, "hsn")}
                              //  defaultValue={journalData.JournalNumber}  onChange={(e) => setJournalData(e.target.value)}
                            />
                            {/* {errors.jno && <p className="text-danger">Journal is required.</p>} */}
                          </div>
                          <div className="col-sm">
                            <label className="ps-0 ">Product Name:</label>
                            <input
                              type="text"
                              className="form-control sm-2"
                              name="productName"
                              value={data.productName}
                              onChange={(e) =>
                                handleChange(e, index, "productName")
                              }
                              //  defaultValue={journalData.JournalNumber}  onChange={(e) => setJournalData(e.target.value)}
                            />
                            {/* {errors.jno && <p className="text-danger">Journal is required.</p>} */}
                          </div>
                          <div className="col-sm  ">
                            <label htmlFor="buttondisplay">
                              {" "}
                              Product Quantity{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control sm-2"
                              name="productQuantity"
                              value={data.productQuantity}
                              onChange={(e) =>
                                handleChange(e, index, "productQuantity")
                              }
                            />
                          </div>
                        </div>
                        <div className="row mx-auto">
                          <div className="col-sm  mt-3">
                            {/* <div className='row flex'> */}
                            <div className="row  mt-4 mx-auto">
                              <label className="ps-0 col-sm-2">GST:</label>
                              <div className="col-sm-3">
                                <RadioButton
                                  inputId="ingredient1"
                                  name="gstRadio1"
                                  value="Yes"
                                  onChange={(e) =>
                                    handleChange(e, index, "gstRadio1")
                                  }
                                  checked={ingredient === "Yes"}
                                />
                                <label htmlFor="ingredient1" className="ml-2">
                                  Yes
                                </label>
                              </div>
                              <div className="col-sm">
                                <RadioButton
                                  inputId="ingredient2"
                                  name="gstRadio2"
                                  value="No"
                                  onChange={(e) =>
                                    handleChange(e, index, "gstRadio2")
                                  }
                                  checked={ingredient === "No"}
                                />
                                <label htmlFor="ingredient2" className="ml-2">
                                  No
                                </label>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-sm mt-3">
                            <div className="row mt-2 mx-auto">
                              <div className="col-sm-4 p-0">
                                <label className="col-sm-1 ">
                                  SGST
                                </label>
                                <div className="col-sm">
                                  {/* <div className='row '> */}
                                  <input
                                    type="text"
                                    className="form-control sm-2"
                                    name="sgst1"
                                    value={data.sgst1}
                                    onChange={(e) =>
                                      handleChange(e, index, "sgst1")
                                    }
                                  />
                                  {/* </div> */}
                                </div>
                              </div>
                              <div className="col-sm"></div>
                              <div className="col-sm-4 pe-0">
                                <label className="col-sm-1 ">
                                  SGST
                                </label>
                                <div className="col-sm">
                                  {/* <div className='row '> */}
                                  <input
                                    type="text"
                                    className="form-control sm-2"
                                    name="sgst2"
                                    value={data.sgst2}
                                    onChange={(e) =>
                                      handleChange(e, index, "sgst2")
                                    }
                                  />
                                  {/* </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm mt-4">
                                    <label htmlFor="buttondisplay"> Amount </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="amount"
                                          value={data.amount}
                                          onChange={(e) => handleChange(e, index, "amount")}
                                        />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="p-0">
              <div className="d-flex justify-content-end border border-dashed bgp1 p-1 m-2">
                <Button
                  type="submit"
                  onClick={addProduct}
                  label="Add product"
                  autoFocus
                  className="btn btn-primary "
                />
              </div>
            </div>
          </div>

          <div className="row mx-auto">
            <h6 className="bg-success text-white p-2 m-2">INVOICE UPLOAD</h6>
            <div>
              <p>Invoice Upload:</p>
              <div className="d-flex justify-content-center ">
                <Toast ref={toast}></Toast>
                <FileUpload
                  mode="basic"
                  name="demo[]"
                  url="/api/upload"
                  accept="image/*"
                  maxFileSize={1000000}
                  onUpload={onUpload}
                  auto
                  chooseLabel="Browse"
                />
              </div>
            </div>
          </div>

          <div className="" style={{display:"flex", justifyContent:"end"}}>
                <Button label="Cancel" icon="pi pi-times" className="btn btn-primary me-2" style={{color:"white", backgroundColor:"#F74343"}}/>

                <Button type="submit" label= "Save" icon="pi pi-check" autoFocus className='btn btn-primary' />
 
            </div>
        </div>
      </form>
    </div>
  );
};

export default PurchaseExpense;
