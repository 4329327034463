import React, { useState, useEffect, useRef } from "react";
import {
  TabView,
  TabPanel,
  InputSwitch,
  Sidebar,
  DataTable,
  Column,
  ToggleButton,
  iconSet,
  IcomoonReact,
  Button,
} from "../../PrimeReact";
import { AiOutlineSearch, AiOutlineUserAdd, AiFillLock } from "react-icons/ai";
import UserDetail from "./sub-modules/UserDetails";
import UserGroup from "./UserGroup";
import UserRole from "./UserRole";
import ServiceReference from "./ServiceReference";
import { useForm, Controller } from "react-hook-form";
import { Tooltip } from "primereact/tooltip";
import SecurityService from "../../../services/security-service";
import GlobalVariable from "../../../services/globalVar";
import { ProgressSpinner } from "primereact/progressspinner";
import { BlockUI } from "primereact/blockui";
import { Divider } from "primereact/divider";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Result } from "antd";
import { Toast } from "primereact/toast";
import { debounce } from "lodash";
import { format } from "date-fns";
import { SearchOutlined } from "@ant-design/icons";
import { DatePicker, Space, Input } from "antd";

// import SecurityService from "../../../services/security-service";

export default function UserAccountManagement() {
  const toast = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [showUser, setShowUser] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [userDrawer, setUserDrawer] = useState(false);
  const [passDrawer, setPassDrawer] = useState(false);
  const [checked, setChecked] = useState(true);
  const [first1, setFirst1] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [appUserList, setAppUserList] = useState([]);
  const [isProcess, setIsProcess] = useState(false);
  const [isAddNewUser, setIsAddNewUser] = useState(false);
  const [userTypeDropDown, setUserTypeDropDown] = useState([]);
  const [selectUserTypeDropDown, setSelectUserTypeDropDown] = useState([]);
  const [selectSecondUserTypeDropDown, setSelectSecondUserTypeDropDown] =
    useState([]);
  const [primaryRoleDropdown, setPrimaryRoleDropdown] = useState([]);
  const [roleDropdown, setRoleDropdown] = useState([]);
  const [editSecondaryRoleDropdown, setEditSecondaryRoleDropdown] = useState(
    []
  );
  const [secondaryRoleDropdown, setSecondaryRoleDropdown] = useState([]);
  const [selectedSecondaryRoleDropdown, setSelectedSecondaryRoleDropdown] =
    useState([]);
  const [selectedPrimaryRoleDropdown, setSelectedPrimaryRoleDropdown] =
    useState([]);
  const [primaryDialogVisible, setPrimaryDialogVisible] = useState(false);
  const [primaryDialogVisible1, setPrimaryDialogVisible1] = useState(false);
  const [isUserGroupchecked, setisUserGroupchecked] = useState(false);
  const [isUserGroupchecked1, setisUserGroupchecked1] = useState(false);
  const [getAutoPass, setGetAutoPass] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState("");
  const [associatedMember, setAssociatedMember] = useState([]);
  const [getUserGroupByRoleDetails, setGetUserGroupByRoleDetails] = useState(
    []
  );
  const [
    getUserGroupByRoleDetailsSecondary,
    setGetUserGroupByRoleDetailsSecondary,
  ] = useState([]);
  const [isPrimary, setIsPrimary] = useState(false);
  const [isSecondary, setIsSecondary] = useState(false);
  const [secondaryAssociate, setSecondaryAssociate] = useState([]);
  const [getRoleId, setGetRoleId] = useState();
  const [getOtherRoleId, setGetOtherRoleId] = useState();
  const [changePass, setChangePass] = useState(false);
  const [changeCurrentPass, setChangeCurrentPass] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isPrimaryChecked, setIsPrimaryChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isShowPassStrength, setIsShowPassStrength] = useState(false);
  const [isPassValid, setIsPassValid] = useState("");
  const [isPassValidMsg, setIsPassValidMsg] = useState("");
  const [isGridShow, setIsGridShow] = useState(true);
  const [isShowUserDetails, setIsShowUserDetails] = useState(false);
  const [isMainUser, setIsMainUser] = useState(false);
  const [validationPass, setValidationPass] = useState([
    { id: 1, title: "Atleast 1 upper case letters (A - Z)", isValid: false },
    { id: 2, title: "Atleast 1 lower case letters (a-z)", isValid: false },
    { id: 3, title: "Atleast 1 number (0-9)", isValid: false },
    {
      id: 4,
      title: "Atleast 1 non-alphanumeric symbol (e.g. '@$%^!%*+=?|.&')",
      isValid: false,
    },
    {
      id: 5,
      title: "Minimum 8 characters to Maximum 16 characters",
      isValid: false,
    },
    // { id: 6, title: "No white space", isValid: false },
  ]);

  // const [isHashSymbol, isHashSymbol] = useState(false);

  const ss = new SecurityService();

  const defaultValues = {
    firstname: "",
    lastname: "",
    usernamefield: "",
    usertype: "",
    displayname: "",
    passwordd: "",
    primaryrole: "",
    usergroupname: "",
    checkbox: "",
  };
  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({ mode: "onChange", defaultValues });

  const fName = watch("firstname") || "";
  const lName = watch("lastname") || "";

  useEffect(() => {
    const flName = `${fName} ${lName}`;
    setValue("displayname", flName);
  }, [fName, lName, setValue]);

  const handleDisplayNameChange = (e) => {
    setValue("displayname", e.target.value);
  };
  const defaultValues1 = {
    primaryrole: "",
    secondaryrole: "",
    grouprole: "",
  };
  const {
    formState: { errors: errors1 },
    register: register1,
    control: control1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    setValue1,
  } = useForm({ mode: "onChange", defaultValues1 });

  const defaultValues2 = {
    password: "",
    confirmpass: "",
  };

  const {
    formState: { errors: errors2 },
    register: register2,
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    setValue2,
  } = useForm({ mode: "onChange", defaultValues2 });

  const onValid = (errors) => {
    if (Object.keys(errors).length > 0) {
      setIsPrimaryChecked(true);
    } else {
      setIsPrimaryChecked(false);
    }
  };

  const onSubmit = (data) => {
    if (getUserGroupByRoleDetails.some((a) => a.isSelected)) {
      setIsRoleSelected("");
      setPrimaryDialogVisible1(false);
      setIsPrimaryChecked(false);
      setIsPrimary(true);
    } else {
      setIsRoleSelected("Please select at least one user group");
      setIsPrimary(false);
    }
    let datas = getUserGroupByRoleDetails.filter((a) => a.isSelected);
    setAssociatedMember(datas);
  };

  const secondaryClick = () => {
    setPrimaryDialogVisible(false);
    setIsSecondary(true);
    let datas = getUserGroupByRoleDetailsSecondary.filter((a) => a.isSelected);
    setSecondaryAssociate(datas);
  };

  const removeAssociatedGroup = (a, i) => {
    const lines = [...associatedMember];
    if (lines.length === 1) {
      toast.current.show({
        severity: "error",
        summary: "Atleast one group should be associated with the primary role",
      });
    } else {
      lines.splice(i, 1);
      toast.current.show({
        severity: "success",
        summary: "Removed successfully",
      });
    }

    setAssociatedMember(lines);
  };
  const removeSecAssociatedGroup = (a, i) => {
    const lines = [...secondaryAssociate];
    lines.splice(i, 1);
    toast.current.show({
      severity: "success",
      summary: "Removed successfully",
    });
    setSecondaryAssociate(lines);
  };

  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });

  const GetAppUserList = (
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder
  ) => {
    setIsProcess(true);
    const data = {
      AppUserParams: {
        TenantId: GlobalVariable.userDet.tenantDetail.tenantID,
        Status: checked ? "Active" : "inActive",
        UserTypeID: GlobalVariable.userDet.appUser.userTypeID,
        EntityID: GlobalVariable.userDet.tenantDetail.tenantID,
        EntityType: "Tenant",
      },
      ServerSearchables: {
        draw: 1,
        searchData: searchVal || "",
        orderByColumn: "AppUserId",
        orderBy: "Desc",
        currentPage: 0,
        pageSize: sizePerPage,
        pageNumber: page,
      },
      VwEntAppUser: [],
    };

    ss.GetAppUserList(data)
      .then((res) => {
        setIsProcess(false);
        res.data.data.appUserLists.forEach((x) => {
          x.appUserStatus === "Active"
            ? (x.isStatus = true)
            : (x.isStatus = false);
          x.isCheckboxDisabled =
            x.appUserId === GlobalVariable.userDet.appUser.appuserID
              ? true
              : false;
        });
        setAppUserList(res.data.data.appUserLists);
        setTotalRecords(res.data.data.serverSearchables.recordsTotal);
      })
      .catch((err) => {
        setIsProcess(false);
      });
  };

  const keySearch = (e) => {
    const searched = e.target.value;
    setSearchVal(searched);
    if (e.key === "Enter") {
      setPage(1);
      GetAppUserList(1, sizePerPage, searched, "", "");
    }

    if (e.type === "keyup" && searched === "") {
      GetAppUserList(1, sizePerPage, "", "", "");
    }
  };

  const emptySearch = () => {
    setSearchVal("");
    GetAppUserList(1, sizePerPage, "", "", "");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const onCustomPage1 = (event) => {
    setFirst1(event.first);
    const val = event.page + 1;
    const size = event.rows;
    setSizePerPage(size);
    setPage(val);
    GetAppUserList(val, size, searchVal, "", "");
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center">
        <Tooltip target=".pi-eye" content="View" position="right" />
        <Tooltip target=".icon-lock" content="Change password" position="top" />
        <Tooltip target=".custom-edit-icon" />

        <IcomoonReact
          iconSet={iconSet}
          color="#444"
          size={20}
          icon="edit"
          className="pointer custom-edit-icon"
          style={{ fill: "#027D84" }}
          onClick={(e) => {
            editData(e, rowData);
          }}
          data-pr-tooltip="Edit"
          data-pr-position="left"
        />
        <AiFillLock
          className="pointer icon-lock ms-1"
          onClick={(e) => handleOpenPassDrawer(rowData, e)}
        />
        <i
          className="pi pi-eye ms-1"
          style={{ color: "#027D84", fontSize: "1.3rem" }}
          onClick={(e) => handleRowClick1(e)}
        ></i>
      </div>
    );
  };

  const toggleStatus = (e, rowData) => {
    e.originalEvent.stopPropagation();
    const row = [...appUserList];
    row.forEach((x) => {
      if (x.appUserId === rowData.appUserId) x.isStatus = !x.isStatus;
    });
    setAppUserList(row);
    SwitchUserStatus(rowData);
  };

  const SwitchUserStatus = (data) => {
    setIsProcess(true);
    const datas = {
      AppUserID: data.appUserId,
      Status: data.isStatus ? "Active" : "inActive",
    };
    ss.SwitchUserStatus(datas).then((res) => {
      setIsProcess(false);
      toast.current.show({
        severity: "success",
        summary: data.isStatus
          ? "Active successfully"
          : "InActive successfully",
      });
      GetAppUserList(page, sizePerPage, searchVal, "", "");
    });
  };

  const renderStatusColumn = (e) => {
    return statusBodyTemplate(e, toggleStatus);
  };

  const statusBodyTemplate = (rowData, toggleStatus) => {
    return (
      <>
        <Tooltip target=".kk" content="Active / Inactive user" position="top" />
        <InputSwitch
          className="toggleSwitch kk"
          checked={rowData.isStatus}
          onChange={(eve) => toggleStatus(eve, rowData)}
          disabled={rowData.isCheckboxDisabled}
        />
      </>
    );
  };

  const userGroupCheckBox = (item, i) => {
    let groupRole = [...getUserGroupByRoleDetails];
    groupRole[i].isSelected = !item.isSelected;
    let data = groupRole.filter((a) => a.isSelected);
    setAssociatedMember(data);
    // let ids = associatedMember.map((a) => a.userGroupID)
    // console.log(ids,'ids');
    setisUserGroupchecked(groupRole);
    if (getUserGroupByRoleDetails.some((a) => a.isSelected)) {
      setIsRoleSelected("");
    } else {
      setIsRoleSelected("Please select at least one user group");
    }
  };

  const userGroupCheckBox1 = (item, i) => {
    let groupRole = [...getUserGroupByRoleDetailsSecondary];
    groupRole[i].isSelected = !item.isSelected;
    let data = groupRole.filter((a) => a.isSelected);
    setSecondaryAssociate(data);

    setisUserGroupchecked1(groupRole);
  };

  const checkAutoPass = (e) => {
    setGetAutoPass(e.target.checked);
  };

  const checkChangePass = (e) => {
    setChangePass(e.target.checked);
  };

  const checkPassStrength = (e) => {
    setIsShowPassStrength(true);

    const password = e.target.value;
    validationPass[0].isValid = /[A-Z]/.test(password);
    validationPass[1].isValid = /[a-z]/.test(password);
    validationPass[2].isValid = /[0-9]/.test(password);
    validationPass[3].isValid = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
      password
    );
    validationPass[4].isValid = password.length >= 8 && password.length <= 16;
    // validationPass[5].isValid = !/\s/.test(password);
    setIsPassValid(password);
    setValidationPass([...validationPass]);
  };

  const checkBothPass = (e) => {
    let pass = e.target.value;
    if (isPassValid === pass) {
      setIsPassValidMsg("");
    } else {
      setIsPassValidMsg("Password missmatch");
    }
  };

  const handleRowClick = (event) => {
    event.originalEvent.stopPropagation();
    setRowData(event.data);
    // setShowUser(false);
    setIsShowUserDetails(true);
    setIsAddNewUser(false);
    setIsGridShow(false);
  };

  const handleRowClick1 = (event) => {
    setRowData(event.data);
    const clickedElement = event.originalEvent?.target;
    const isLastColumn = clickedElement?.closest(".actionn");

    if (isLastColumn) {
      event.originalEvent.stopPropagation();
      setShowUser(false);
    } else {
      setShowUser(true);
    }
  };

  const handleOpenPassDrawer = (selectedDatas, e) => {
    e.stopPropagation();
    setChangeCurrentPass(selectedDatas);
    setPassDrawer(true);
  };
  const handleBackUser = (item) => {
    setShowUser(item);
    setIsGridShow(true);
    setIsAddNewUser(false);
    setIsShowUserDetails(false);
  };
  const handleClose = () => {
    reset();
    setIsAddNewUser(!isAddNewUser);
    setIsPrimary(false);
    setIsSecondary(false);
    setIsUpdate(false);
    // setUserTypeDropDown("");
    setSelectUserTypeDropDown("");
    setPrimaryRoleDropdown("");
    setSecondaryRoleDropdown("");
    setSelectedPrimaryRoleDropdown("");
    setSelectedSecondaryRoleDropdown("");
    setIsGridShow(true);
    setGetAutoPass(false);
    setChangePass(false);
    setIsPrimary(false);
    setIsSecondary(false);
    setIsShowPassStrength(false);
    setGetUserGroupByRoleDetails("");
    setGetUserGroupByRoleDetailsSecondary("");
    // setGetUserGroupByRoleDetails("");
  };
  const handleClosePass = () => {
    setPassDrawer(false);
    setIsPassValidMsg("");
    setIsShowPassStrength(false);

    reset2();
  };
  const renderHeader = () => {
    return (
      <div className="custom-sidebar-header d-flex py-2">
        <h4>Add/Update User</h4>
        <button
          onClick={handleClose}
          className="p-sidebar-close p-link ms-auto"
        >
          <span className="pi pi-times" />
        </button>
      </div>
    );
  };
  const renderHeader1 = () => {
    return (
      <div className="custom-sidebar-header d-flex py-2">
        <h4>Update Password</h4>
        <button
          onClick={handleClosePass}
          className="p-sidebar-close p-link ms-auto"
        >
          <span className="pi pi-times" />
        </button>
      </div>
    );
  };

  const userRoloe = () => {
    const data = {
      EntityID: GlobalVariable.userDet.tenantDetail.tenantID,
      EntityType: "Tenant",
      ProductID: 6810,
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      UserTypeID: GlobalVariable.userDet.appUser.UserTypeID,
    };
    ss.GetUserTypeRoles(data).then((res) => {});
  };

  const changeStatus = (e) => {
    setChecked(e.value);
  };

  const selectPrimaryRole = () => {
    setPrimaryDialogVisible1(true);
  };
  const selectPrimaryRole1 = () => {
    setPrimaryDialogVisible(true);
  };
  const selectPrimaryRoleCancel = () => {
    setPrimaryDialogVisible(false);
  };
  const selectPrimaryRoleCancel1 = () => {
    setPrimaryDialogVisible1(false);
    setSelectedPrimaryRoleDropdown("");
    setGetUserGroupByRoleDetails("");
  };

  function formatDate(date) {
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }

  const activeFormBodyTemplate = (appUserList) => {
    return (
      <div>
        {format(
          new Date(appUserList.associatedOn),
          GlobalVariable.userDet.tenantConfig.dateFormat
        )}
      </div>
    );
  };

  const lastSignBodyTemplate = (appUserList) => {
    let dates = "-";
    if (appUserList.lastSigninAt) {
      dates = format(
        new Date(appUserList.lastSigninAt),
        GlobalVariable.userDet.tenantConfig.dateFormat
      );
    }
    return <div>{dates}</div>;
  };

  const GetUserTypeList = () => {
    const data = {
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      UserTypeID: GlobalVariable.userDet.appUser.userTypeID,
      EntityID: GlobalVariable.userDet.tenantDetail.tenantID,
      EntityType: "Tenant",
    };
    ss.GetUserTypeList(data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setUserTypeDropDown(res.data.data);
        }
      })
      .catch((err) => console.log(err, "usertypelistErr"));
  };

  const handleDropDownSelectUserType = (e) => {
    setSelectUserTypeDropDown(e.value);
    setSelectSecondUserTypeDropDown(e.value);
    let roles = e.value.roles.map((a) => a);
    let isAsso = roles.filter((a) => a.isAssociate);
    console.log(isAsso, "roles");
    setRoleDropdown(isAsso);
    setEditSecondaryRoleDropdown(isAsso);
    setPrimaryRoleDropdown(isAsso);
    setSecondaryRoleDropdown(isAsso);
    setIsPrimary(false);
    setIsSecondary(false);
    setGetUserGroupByRoleDetails("");
    setGetUserGroupByRoleDetailsSecondary("");
  };

  const handleDropDownSelectUserTypeRole = (e) => {
    setSelectedPrimaryRoleDropdown(e.value);
    GetUserGroupByRole(selectUserTypeDropDown, e.value);
    let fltr = editSecondaryRoleDropdown.filter(
      (a) => a.roleId !== e.value.roleId
    );
    setSecondaryRoleDropdown([...fltr]);
  };

  const handleDropDownSelectUserTypeRole1 = (e) => {
    setSelectedSecondaryRoleDropdown(e.value);
    setGetOtherRoleId(e.value);
    GetUserGroupByRoleForSecondary(selectUserTypeDropDown, e.value);
    let fltr = roleDropdown.filter((a) => a.roleId !== e.value.roleId);
    setPrimaryRoleDropdown([...fltr]);
  };

  const GetUserGroupByRole = (a, b) => {
    setIsProcess(true);
    const datas = {
      UserTypeID: a.userTypeID,
      RoleID: b.roleId,
      TenantID: a.tenantID,
      EntityID: b.entityID,
      EntityType: a.entityLevel,
    };

    return new Promise((resolve, reject) => {
      setGetRoleId(datas);
      ss.GetUserGroupByRole(datas)
        .then((res) => {
          setIsProcess(false);
          res.data.data.forEach((a) => (a.isSelected = false));
          setGetUserGroupByRoleDetails(res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => {
          setIsProcess(false);
          reject(error);
        });
    });
  };

  const GetUserGroupByRoleForSecondary = (a, b) => {
    setIsProcess(true);
    const datas = {
      UserTypeID: a?.userTypeID,
      RoleID: b?.roleId,
      TenantID: a?.tenantID,
      EntityID: b?.entityID,
      EntityType: a?.entityLevel,
    };

    return new Promise((resolve, reject) => {
      setGetRoleId(datas);
      ss.GetUserGroupByRole(datas)
        .then((res) => {
          setIsProcess(false);
          res.data.data.forEach((a) => (a.isSelected = false));
          // setGetUserGroupByRoleDetails(res.data.data);
          setGetUserGroupByRoleDetailsSecondary(res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => {
          setIsProcess(false);
          reject(error);
        });
    });
  };

  const handleSaveCreateUser = (data) => {
    if (!isUpdate) {
      if (!getAutoPass) {
        let isPass = validationPass.every((x) => x.isValid);
        if (!isPass) {
          setIsPrimaryChecked(true);
          return;
        }
      }
    }

    // if (!isEdit) {
    //   let isPrimaryChecked = getUserGroupByRoleDetails?.filter(
    //     (a) => a.isSelected
    //   );
    //   setIsPrimaryChecked(isPrimaryChecked);
    //   return;
    // } else {
    //   setIsPrimaryChecked(false);
    // }

    if (associatedMember.length === 0) {
      setIsPrimaryChecked(true);
      return;
    } else {
      setIsPrimaryChecked(false);
    }
    setIsProcess(true);

    const datas = [
      {
        AppUserID: rowData.appUserId || 0,
        UserName: data.usernamefield,
        Password: getAutoPass ? "pass" : data.passwordd,
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        FirstName: data.firstname,
        MiddleName: null,
        LastName: data.lastname,
        DisplayName: data.displayname,
        CreatedBy: GlobalVariable.userDet.appUser.appuserID,
        RoleID: selectedPrimaryRoleDropdown?.roleId,
        OtherRoleID: selectedSecondaryRoleDropdown?.roleId || 0,
        UserTypeID: selectUserTypeDropDown.userTypeID,
        ProductID: GlobalVariable.userDet.appUser.productID,
        DefaultUserGroupID:
          [...associatedMember, ...secondaryAssociate]
            .map((a) => a.userGroupID.toString())
            .join(",") || "",
        IsDefaultPassword: getAutoPass,
        ChangePassword: changePass,
        IsNotify: false,
        EntityID: GlobalVariable.userDet.appUser.entityID,
        EntityType: GlobalVariable.userDet.appUser.entityType,
      },
    ];
    setIsProcess(false);
    console.log(datas, "chekpas");
    ss.CreateUser(datas)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setIsProcess(false);
          if (res.data.message === "User Already Existed") {
            toast.current.show({
              severity: "error",
              summary: res.data.message,
            });
          } else {
            setIsProcess(false);
            toast.current.show({
              severity: "success",
              summary: res.data.message,
            });
          }
          handleClose();
          reset1();
          GetAppUserList(page, sizePerPage, searchVal, "", "");
        } else {
          setIsProcess(false);
        }
      })
      .catch((err) => {
        setIsPrimaryChecked(true);
      });
    setIsProcess(false);
  };

  const editData = (e, rowData) => {
    e.stopPropagation();
    setIsPrimaryChecked(false);
    setRowData(rowData);
    setIsEdit(false);
    setIsUpdate(true);
    setIsAddNewUser(true);
    setIsGridShow(false);
    setIsShowUserDetails(false);
    setValue("firstname", rowData.firstName);
    setValue("lastname", rowData.lastName);
    setValue("displayname", rowData.fullName);
    setValue("usernamefield", rowData.appUserName);
    const uType = userTypeDropDown.find((a) => a.userType === rowData.userType);
    setSelectUserTypeDropDown(uType);
    setSelectSecondUserTypeDropDown(uType);
    setValue("usertype", uType);
    setRoleDropdown(uType.roles);
    setEditSecondaryRoleDropdown(uType.roles);
    setPrimaryRoleDropdown(uType.roles);
    // setSecondaryRoleDropdown(uType.roles);

    let primary = rowData.objrole.find((a) => a.isPrimaryRole);
    const isPrimaryValue = uType.roles.find((a) => a.roleId === primary.roleID);
    setSelectedPrimaryRoleDropdown(isPrimaryValue);
    let fltr = editSecondaryRoleDropdown.filter(
      (a) => a.roleId !== isPrimaryValue.roleId
    );
    setSecondaryRoleDropdown([...fltr]);
    GetUserGroupByRole(uType, isPrimaryValue).then((res) => {
      let storeValue = res;
      let primaryValue = primary.objgroup;
      let findedValue = storeValue.filter((x) =>
        primaryValue.map((d) => d?.userGroupID).includes(x.userGroupID)
      );
      findedValue.forEach((a) => (a.isSelected = true));
      setIsPrimary(true);
      setAssociatedMember(findedValue);
      setisUserGroupchecked(findedValue);
    });

    let second = rowData.objrole.find((a) => !a.isPrimaryRole);
    const issecondValue = uType.roles.find((a) => a?.roleId === second?.roleID);
    setSelectedSecondaryRoleDropdown(issecondValue);
    GetUserGroupByRoleForSecondary(uType, issecondValue).then((res) => {
      let storeValue = res;
      let secondryValue = second?.objgroup;
      let findedValue = storeValue.filter((x) =>
        secondryValue.map((d) => d?.userGroupID).includes(x.userGroupID)
      );
      findedValue.forEach((a) => (a.isSelected = true));
      setIsSecondary(true);
      setSecondaryAssociate(findedValue);
      setisUserGroupchecked1(findedValue);
    });
  };

  const UpdateUserPassword = (data) => {
    let isPass = validationPass.every((x) => x.isValid);
    if (!isPass) return;
    if (isPassValidMsg === "Password missmatch") return;
    setIsProcess(true);
    const datas = {
      AppUserID: changeCurrentPass.appUserId,
      TenantID: changeCurrentPass.tenantId,
      Password: data.confirmpass,
    };
    console.log(datas);
    ss.UpdateUserPassword(datas).then((res) => {
      setIsProcess(false);
      setPassDrawer(false);
      setIsShowPassStrength(false);
      toast.current.show({
        severity: "success",
        summary: res.data.errors,
      });
    });
  };

  useEffect(() => {
    userRoloe();
    GetUserTypeList();
    GetAppUserList(page, sizePerPage, searchVal, "", "");
  }, []);

  useEffect(() => {
    GetAppUserList(page, sizePerPage, searchVal, "", "");
  }, [checked]);

  return (
    <div>
      <BlockUI blocked={isProcess}>
        <Toast ref={toast} />
        <div>
          <div
            className="d-flex justify-content-between cc-greyCard-custm p-3 mb-2 text-t-primary  kk-table-header kk-header-sec mb-0"
            style={{ backgroundColor: "white" }}
          >
            <h4 className="kk-bold m-0">User Account Management</h4>
          </div>
        </div>

        <div className="bg-white shadow-sm chart-of-account h-100 p-4">
          <div className="row coa-header kk-header-sec">
            <div className="col-auto header-right ms-auto"></div>
          </div>
          <div className="account-table">
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => {
                setActiveIndex(e.index);
                if (e.index === 0) GetUserTypeList();
                setShowUser(false);
                setIsGridShow(true);
                setIsAddNewUser(false);
                setIsShowUserDetails(false);
                setSearchVal("");
                GetAppUserList(page, sizePerPage, searchVal, "", "");
              }}
            >
              <TabPanel header="User Account Management">
                {isGridShow && (
                  <div>
                    <div className="row coa-header kk-header-sec">
                      <h5 className="col"> List of Users</h5>
                      <div className="col-auto header-right align-items-center ms-auto d-flex mb-2">
                        <div className="col  border rounded my-auto ">
                          <div>
                            <Tooltip
                              target=".ch"
                              content="Create new user"
                              position="top"
                            />

                            <Space.Compact className="my-auto">
                              <Input
                                addonBefore={<SearchOutlined />}
                                allowClear={emptySearch}
                                placeholder="Search"
                                value={searchVal}
                                onChange={(e) => keySearch(e)}
                                onKeyUp={(e) => keySearch(e)}
                              />
                            </Space.Compact>
                          </div>
                          <div></div>
                        </div>
                        <Tooltip
                          target=".tt"
                          content="View active / Inactive user"
                          position="top"
                        />

                        <InputSwitch
                          className="mx-2 toggleSwitch tt"
                          checked={checked}
                          disabled={isMainUser}
                          onChange={(e) => changeStatus(e)}
                        />

                        <AiOutlineUserAdd
                          className="col-auto my-auto user-icon ch"
                          onClick={() => {
                            reset();
                            setIsAddNewUser(!isAddNewUser);
                            setRowData("");
                            setIsGridShow(false);
                            setIsEdit(true);
                            setIsPrimaryChecked(false);
                            setSelectSecondUserTypeDropDown("");
                            setSelectUserTypeDropDown("");
                            setPrimaryRoleDropdown("");
                            setSecondaryRoleDropdown("");
                            setSelectedPrimaryRoleDropdown("");
                            setGetAutoPass(false);
                            setChangePass(false);
                            setIsPrimary(false);
                            setIsSecondary(false);
                            setGetUserGroupByRoleDetails("");
                          }}
                        />
                      </div>
                    </div>
                    <DataTable
                      totalRecords={totalRecords}
                      tableStyle={{ minWidth: "100%" }}
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"
                      lazy
                      paginator
                      first={first1}
                      rows={sizePerPage}
                      onPage={onCustomPage1}
                      onSort={onSort}
                      sortField={lazyParams.sortField}
                      sortOrder={lazyParams.sortOrder}
                      rowsPerPageOptions={[10, 20, 50]}
                      onRowClick={handleRowClick}
                      value={appUserList}
                      emptyMessage="No user available"
                    >
                      {/* <Column field="firstName" header="First Name" ></Column> */}
                      <Column
                        field="fullName"
                        header="User Name"
                        style={{ width: "15%" }}
                      ></Column>
                      <Column field="appUserName" header="Login ID"></Column>
                      <Column field="userType" header="User Type"></Column>
                      <Column field="roleName" header="User Role"></Column>
                      <Column
                        field="activeFormBodyTemplate"
                        header="Active From"
                        body={activeFormBodyTemplate}
                      ></Column>
                      <Column
                        field="lastSignBodyTemplate"
                        header="Last Login"
                        body={lastSignBodyTemplate}
                      ></Column>
                      <Column
                        field="statusBodyTemplate"
                        header="Status"
                        className="action"
                        body={(e) => renderStatusColumn(e)}
                      ></Column>
                      <Column
                        field="actionBodyTemplate"
                        header="Action"
                        className="actionn"
                        body={actionBodyTemplate}
                      />
                    </DataTable>
                  </div>
                )}
                {isProcess && (
                  <ProgressSpinner
                    style={{
                      width: "50px",
                      height: "50px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    strokeWidth="2"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                )}
                {isAddNewUser && (
                  <form
                      onSubmit={handleSubmit(handleSaveCreateUser, onValid)}
                    >
                      <div className="col-12 d-flex gap-5">
                  <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <h4>User Information</h4>
                          <Divider className="mb-3" />
                          <div className="row px-2 py-3 mt-4">
                            <div className=" col-md-6 col-sm-12 mb-2">
                              <label className="ps-0 required ">
                                First Name
                              </label>
                              <input
                                type="text"
                                name="firstname"
                                className="form-control"
                                {...register("firstname", { required: true })}
                              />
                              {errors.firstname && (
                                <p className="text-danger">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className=" col-md-6 col-sm-12 mb-2">
                              <label className="ps-0 required">Last Name</label>
                              <input
                                type="text"
                                name="lastname"
                                className="form-control"
                                {...register("lastname", { required: true })}
                              />
                              {errors.lastname && (
                                <p className="text-danger">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className=" col-12 mb-2">
                              <label className="ps-0 ">Display Name</label>
                              <input
                                type="text"
                                name="displayname"
                                className="form-control"
                                value={watch("displayname") || ""}
                                onChange={handleDisplayNameChange}
                                {...register("displayname", {
                                  required: false,
                                })}
                              />
                              {errors.displayname && (
                                <p className="text-danger">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className=" col-md-6 col-sm-12 mb-2">
                              <label className="ps-0 required ">User Name</label>
                              <input
                                type="text"
                                email="true"
                                name="usernamefield"
                                className="form-control"
                                autoComplete="username"
                                {...register("usernamefield", {
                                  required: "This field is required",
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message:
                                      "Username should be like this abc@gmail.com",
                                  },
                                })}
                              />
                              {errors.usernamefield && (
                                <p className="text-danger">
                                  {errors.usernamefield.message}
                                </p>
                              )}
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                              <label className="ps-0 required">User Type</label>
                              <Controller
                                name="usertype"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <Dropdown
                                    {...field}
                                    style={{ width: "100%" }}
                                    placeholder="Select an userType"
                                    options={userTypeDropDown}
                                    optionLabel="userType"
                                    value={selectUserTypeDropDown}
                                    onChange={(e) => {
                                      field.onChange(e.value);
                                      handleDropDownSelectUserType(e);
                                    }}
                                  />
                                )}
                              />
                              {errors.usertype && (
                                <p className="text-danger">
                                  This field is required
                                </p>
                              )}
                            </div>

                            {isEdit && (
                              <>
                                <div className=" col-12 d-flex align-items-baseline my-2 mb-2">
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    defaultChecked={getAutoPass}
                                    onClick={(e) => checkAutoPass(e)}
                                  />
                                  <label className="ps-0 ms-2">
                                    Automatically create a password
                                  </label>
                                </div>

                                {getAutoPass === false && (
                                  <div className=" col-12 mb-2">
                                    <label className="ps-0 required">
                                      Password
                                    </label>
                                    <div className="d-flex input-group">
                                      <input
                                        type={
                                          showPassword2 ? "text" : "password"
                                        }
                                        name="passwordd"
                                        className="form-control"
                                        autoComplete="new-password"
                                        onInput={(e) => checkPassStrength(e)}
                                        {...register("passwordd", {
                                          required: true,
                                        })}
                                      />
                                      <button
                                        type="button"
                                        style={{
                                          width: "2rem",
                                          border: "1px solid #d4d4d4",
                                        }}
                                        onClick={togglePasswordVisibility2}
                                      >
                                        <i
                                          className={`pi ${
                                            showPassword2
                                              ? "pi-eye"
                                              : "pi-eye-slash"
                                          }`}
                                        ></i>
                                      </button>
                                    </div>
                                    {errors.passwordd && (
                                      <p className="text-danger">
                                        This field is required
                                      </p>
                                    )}
                                    {isShowPassStrength && (
                                      <>
                                        <p className="m-1">Password Hint </p>
                                        {validationPass.map((a, i) => {
                                          return (
                                            <p
                                              key={i}
                                              className={`m-1 d-flex align-items-center ${
                                                a.isValid
                                                  ? "custom-p2 text-success"
                                                  : "text-danger"
                                              }`}
                                            >
                                              <i
                                                className={`pi ${
                                                  a.isValid
                                                    ? "pi pi-check text-success"
                                                    : "pi-times-circle text-danger"
                                                } `}
                                                style={{ width: "5%" }}
                                              ></i>
                                              {a.title}
                                            </p>
                                          );
                                        })}
                                      </>
                                    )}
                                  </div>
                                )}

                                <div className=" col-12 d-flex align-items-baseline my-2 ">
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    defaultChecked={changePass}
                                    onClick={(e) => checkChangePass(e)}
                                  />
                                  <label className="ps-0 ms-2">
                                    Require this user to change their password
                                    when they first sign in
                                  </label>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <h4>User Role & Permission</h4>
                          <Divider className="mb-3" />
                          <div className="row mx-0 mb-3 mt-4">
                            <div className="form-group d-flex col-12">
                              <label className="ps-0 required">
                                Primary Role
                              </label>

                              <button
                                type="button"
                                style={{ background: "transparent" }}
                                onClick={selectPrimaryRole}
                              >
                                <p className="m-0 ms-3 PrimaryRole">
                                  click here to assign role
                                </p>
                              </button>
                            </div>
                            {isPrimaryChecked && (
                              <p className="m-0 mx-2 text-danger">
                                This field is required
                              </p>
                            )}
                            {isPrimary && (
                              <div className="col-12 mx-0 d-flex mb-3 mt-4">
                                <div className="form-group d-flex col">
                                  <strong>
                                    Member of associated user group
                                  </strong>
                                </div>
                                <div className="">
                                  {associatedMember.map((a, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="p-2 my-1 m-0 d-flex align-items-center justify-content-between"
                                        style={{
                                          height: "3rem",
                                          background: "#e0e0e0",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {a?.userGroup}
                                        <div>
                                          <i
                                            className="pi pi-trash text-danger"
                                            onClick={() =>
                                              removeAssociatedGroup(a, i)
                                            }
                                          ></i>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                            <div className="col-12 mx-0 mb-3 mt-4">
                              <div className="form-group d-flex col">
                                <label className="ps-0">Secondary Role</label>

                                <button
                                  type="button"
                                  style={{ background: "transparent" }}
                                  onClick={selectPrimaryRole1}
                                >
                                  <p className="m-0 ms-3 PrimaryRole">
                                    click here to assign role
                                  </p>
                                </button>
                              </div>
                            </div>
                            {isSecondary && (
                              <div className="col-12 mx-0 d-flex mb-3 mt-4">
                                <div className="form-group d-flex col">
                                  <strong>
                                    Member of associated user group
                                  </strong>
                                </div>
                                <div className="">
                                  {secondaryAssociate.map((a, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="p-2 my-1 m-0 d-flex align-items-center justify-content-between"
                                        style={{
                                          height: "3rem",
                                          background: "#e0e0e0",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        {a?.userGroup}
                                        <div>
                                          <i
                                            className="pi pi-trash text-danger"
                                            onClick={() =>
                                              removeSecAssociatedGroup(a, i)
                                            }
                                          ></i>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                        </div>
                      <div className="text-end mt-3">
                        <Button
                          label="Cancel"
                          icon="pi pi-times"
                          onClick={handleClose}
                          className="p-button-text btn-cancel ms-auto btn btn-danger me-1"
                        />

                        {isUpdate ? (
                          <Button
                            type="submit"
                            label="Update"
                            icon="pi pi-check"
                            className="btn btn-primary"
                          />
                        ) : (
                          <Button
                            type="submit"
                            label="Save"
                            icon="pi pi-check"
                            className="btn btn-primary"
                          />
                        )}
                      </div>
                    </form>
                )}
                {isShowUserDetails && (
                  <UserDetail
                    data={{ handleBackUser: handleBackUser, rowData: rowData }}
                  />
                )}
                {/* <SwitchCase value={isAddNewUser} /> */}
              </TabPanel>
              <TabPanel header="Permission Management">
                <UserGroup />
              </TabPanel>
              <TabPanel header="Service Reference">
                <ServiceReference />
              </TabPanel>
              <TabPanel header="User Role">
                <UserRole />
              </TabPanel>
            </TabView>
          </div>
        </div>
      </BlockUI>

      <Dialog
        header="Secondary role"
        visible={primaryDialogVisible}
        style={{ width: "30%" }}
        onHide={() => {
          selectPrimaryRoleCancel();
        }}
      >
        {selectSecondUserTypeDropDown.length !== 0 ? (
          <div>
            <div className="form-group col">
              <label className="ps-0 required my-2">Secondary Role Type</label>
              <Controller
                name="secondaryrole"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    style={{ width: "100%" }}
                    placeholder="Select an userType role"
                    options={secondaryRoleDropdown}
                    optionLabel="roleName"
                    value={selectedSecondaryRoleDropdown}
                    onChange={(e) => {
                      field.onChange(e.value);
                      handleDropDownSelectUserTypeRole1(e);
                    }}
                  />
                )}
              />
            </div>
            {getUserGroupByRoleDetailsSecondary.length !== 0 && (
              <div>
                <div className="form-group d-flex col mt-3">
                  <label className="ps-0 required">User group Name</label>
                </div>
                {getUserGroupByRoleDetailsSecondary.map((item, i) => {
                  return (
                    <div key={i}>
                      <div className="d-flex align-items-center">
                        <Checkbox
                          className="ms-3 my-2"
                          onChange={(e) => userGroupCheckBox1(item, i)}
                          checked={item.isSelected}
                        ></Checkbox>
                        <label htmlFor="" className="ms-1">
                          {item.userGroup}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <div>
            <Result
              status="warning"
              title="Select the user type before assigning a role"
            />
          </div>
        )}
        <div className="my-2 text-end gap-2">
          <Button
            type="button"
            className="btn btn-danger me-2"
            onClick={() => selectPrimaryRoleCancel()}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={() => secondaryClick()}
            className="btn btn-primary"
          >
            {" "}
            ok{" "}
          </Button>
        </div>
      </Dialog>

      <Dialog
        header="Primary role"
        visible={primaryDialogVisible1}
        style={{ width: "30%" }}
        onHide={() => {
          selectPrimaryRoleCancel1();
        }}
      >
        <form onSubmit={handleSubmit1(onSubmit)}>
          {selectUserTypeDropDown.length !== 0 ? (
            <div>
              <div className="form-group col">
                <label className="ps-0 required my-2">Primary Role Type</label>
                <Controller
                  name="primaryrole"
                  control={control1}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      style={{ width: "100%" }}
                      placeholder="Select an userType role"
                      options={primaryRoleDropdown}
                      optionLabel="roleName"
                      value={selectedPrimaryRoleDropdown}
                      onChange={(e) => {
                        field.onChange(e.value);
                        handleDropDownSelectUserTypeRole(e);
                      }}
                    />
                  )}
                />
                {errors1.primaryrole && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              {getUserGroupByRoleDetails.length !== 0 && (
                <div>
                  <div className="form-group d-flex col mt-3">
                    <label className="ps-0 required">User group Name</label>
                  </div>
                  {getUserGroupByRoleDetails.map((item, i) => {
                    return (
                      <div key={i}>
                        <div className="d-flex align-items-center">
                          <Checkbox
                            className="ms-3 my-2"
                            name="grouprole"
                            onChange={(e) => userGroupCheckBox(item, i)}
                            checked={item.isSelected}
                            // {...register1("grouprole", { required: true })}
                          ></Checkbox>
                          <label htmlFor="" className="ms-1">
                            {item.userGroup}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                  <p className="text-danger">{isRoleSelected}</p>
                </div>
              )}
            </div>
          ) : (
            <div>
              <Result
                status="warning"
                title="Select the user type before assigning a role"
              />
            </div>
          )}
          <div className="my-2 text-end">
            <Button
              type="button"
              className="btn btn-danger me-2"
              onClick={() => selectPrimaryRoleCancel1()}
            >
              Cancel
            </Button>
            <Button type="submit" className="btn btn-primary">
              {" "}
              ok{" "}
            </Button>
          </div>
        </form>
      </Dialog>

      <Sidebar
        visible={passDrawer}
        position="right"
        className="security-drawer"
        onHide={handleClosePass}
      >
        <div slot="header">{renderHeader1()}</div>
        <form onSubmit={handleSubmit2(UpdateUserPassword)}>
          <div className="py-3 rounded mt-4">
            <div className="row mx-0 mb-3">
              <div className="form-group col">
                <label className="ps-0 required">Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="form-control"
                    onInput={(e) => checkPassStrength(e)}
                    {...register2("password", { required: true })}
                  />
                  <button
                    type="button"
                    style={{ width: "2rem", border: "1px solid #d4d4d4" }}
                    onClick={togglePasswordVisibility}
                  >
                    <i
                      className={`pi ${
                        showPassword ? "pi-eye" : "pi-eye-slash"
                      }`}
                    ></i>
                  </button>
                </div>
                {errors2.password && (
                  <p className="text-danger">This field is required</p>
                )}
                {isShowPassStrength && (
                  <>
                    <p className="m-1">Password Hint </p>
                    {validationPass.map((a, i) => {
                      return (
                        <p
                          key={i}
                          className={`m-1 d-flex align-items-center ${
                            a.isValid ? "custom-p2 text-success" : "text-danger"
                          }`}
                        >
                          <i
                            className={`pi ${
                              a.isValid
                                ? "pi pi-check text-success"
                                : "pi-times-circle text-danger"
                            } `}
                            style={{ width: "5%" }}
                          ></i>
                          {a.title}
                        </p>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <div className="row mx-0 mb-3">
              <div className="form-group col">
                <label className="ps-0 required">Confirm Password</label>
                <div className="input-group">
                  <input
                    type={showPassword1 ? "text" : "password"}
                    name="confirmpass"
                    className="form-control"
                    {...register2("confirmpass", { required: true })}
                    onInput={(e) => checkBothPass(e)}
                  />
                  <button
                    type="button"
                    style={{ width: "2rem", border: "1px solid #d4d4d4" }}
                    onClick={togglePasswordVisibility1}
                  >
                    <i
                      className={`pi ${
                        showPassword1 ? "pi-eye" : "pi-eye-slash"
                      }`}
                    ></i>
                  </button>
                </div>
                {errors2.confirmpass && (
                  <p className="text-danger">This field is required</p>
                )}
                <p className="text-danger">{isPassValidMsg}</p>
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={handleClosePass}
              className="p-button-text btn-cancel ms-auto btn btn-danger me-1"
            />
            <Button
              type="submit"
              label="Update"
              icon="pi pi-check"
              className="btn btn-primary"
            />
          </div>
        </form>
      </Sidebar>
    </div>
  );
}
