import React, { useEffect, useState } from "react";
import LedgerService from "../../services/ledger";
import {
  AccordionTab,
  Accordion,
  DataTable,
  Column,
  Dropdown,
  MultiSelect,
  Calendar,
} from "../PrimeReact.js";
import { AiOutlineSearch } from "react-icons/ai";
import { format } from "date-fns";
import GV from "../../services/globalVar";
import { Tooltip, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

function GeneralLedger() {
  const ldgs = new LedgerService();
  const [totalRecordsIn, setTotalRecordsIn] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [ledgerList, setLedgerList] = useState([]);
  const [dateRange, setDateRange] = useState("");

  const coaStatus = [
    { label: "All", value: "All", status: true },
    { label: "Today", value: "Today", status: true },
    { label: "This Month", value: "This Month", status: true },
    { label: "This Year", value: "This Year", status: false },
    { label: "Custom", value: "Custom", status: false },
  ];
  const [statusSelected, setStatusSelected] = useState(coaStatus[0].value);
  const [filterSelected, setFilterSelected] = useState(coaStatus[0].value);

  function fetchLedgerList(
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder,
    filter,
    fdate,
    tdate
  ) {
    // console.log()
    let datas = {
      InputParams: {
        TenantID: GV.userDet.tenantDetail.tenantID,
        TransactionType: "All",
        Period: filter,
        FromDate: fdate,
        ToDate: tdate,
      },
    };

    ldgs
      .GetDetailedLedgerEntry(datas)
      .then((response) => {
        // if (isMounted) {
        if (response.data) {
          console.log("xyz", response.data);

          const grid = response.data.data.accountDetails;
          console.log(response.data.data.accountDetails, "POOL");
          if (response.data.statusCode == 200) {
            grid.forEach((x, i) => {
              x.key = i;
              x.data = {
                closingBalance: x.closingBalance,
                coaName: x.coaName,
                coaType: x.coaType,
                transactionNumber: "Opening Balance",
                debits: x.openingBalance,
                credits: "Closing Balance",
                availableBalance: x.closingBalance,
              };
              x.children = [];
              if (x.ledgerTransaction.length != 0) {
                x.ledgerTransaction.forEach((y, j) => {
                  y.key = i + "-" + j;
                  y.data = {
                    coaName: y.transactionDate,
                    accountName: y.accountName,
                    availableBalance: y.availableBalance,
                    currencyCode: y.currencyCode,
                    description: y.description,
                    transactionType: y.transactionType,
                    transactionNumber: y.transactionNumber,
                    debits: y.debits,
                    credits: y.credits,
                    availableBalance: y.availableBalance,
                  };
                });
              }
              x.children = x.ledgerTransaction;
            });
            setLedgerList(grid);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(() => {
    fetchLedgerList(
      page,
      sizePerPage,
      searchVal,
      sortName,
      sortOrder,
      filterSelected,
      "",
      ""
    );
  }, []);

  const accordData = "";

  const AccordionOne = ledgerList.map((col, i) => {
    return (
      <AccordionTab key={col.coaMasID || i}
        header={
          <React.Fragment>
            {col.closingBalance == 0 ||
            col.openingBalance === col.closingBalance ? (
              <div>
                <span>{col.coaName}</span>
                <span className="float-end">
                  {" "}
                  Opening Balance :{" "}
                  <b>
                    {GV.userDet.tenantConfig.currency +
                      " " +
                      col.openingBalance.toFixed(2)}{" "}
                  </b>{" "}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  Closing Balance :{" "}
                  <b>
                    {GV.userDet.tenantConfig.currency +
                      " " +
                      col.closingBalance.toFixed(2)}
                  </b>
                </span>
              </div>
            ) : (
              <div>
                <span style={{ color: "blue" }}>{col.coaName}</span>
                <span className="float-end" style={{ color: "blue" }}>
                  {" "}
                  Opening Balance :{" "}
                  <b>
                    {GV.userDet.tenantConfig.currency +
                      " " +
                      col.openingBalance}{" "}
                  </b>{" "}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  Closing Balance :{" "}
                  <b>
                    {GV.userDet.tenantConfig.currency +
                      " " +
                      col.closingBalance}
                  </b>
                </span>
              </div>
            )}
          </React.Fragment>
        }
      >
        <DataTable
          value={col.ledgerTransaction}
          // responsiveLayout="scroll"
        >
          <Column
            field="transactionDate"
            header="Date"
            body={(row) => (
              <div>
                {" "}
                {format(
                  new Date(row.transactionDate),
                  GV.userDet.tenantConfig.dateFormat
                )}
              </div>
            )}
          ></Column>
          <Column field="transactionNumber" header="Transaction No."></Column>
          <Column field="description" header="Description"></Column>
          <Column
            field="debits"
            header="Debit"
            className="text-end amount-align"
            body={(row) => GV.userDet.tenantConfig.currency + " " + row.debits.toFixed(2)}
          ></Column>
          <Column
            field="credits"
            header="Credit"
            className="text-end amount-align"
            body={(row) => GV.userDet.tenantConfig.currency + " " + row.credits.toFixed(2)}
          ></Column>
          <Column
            field="availableBalance"
            header="Amount"
            className="text-end amount-align"
            body={(row) =>
              GV.userDet.tenantConfig.currency + " " + row.availableBalance.toFixed(2)
            }
          ></Column>
        </DataTable>
        {/* {col.ledgerTransaction.map((col2,j) => {
              return <div>
                {col2.accountName}

               
              </div>
          })
        } */}
      </AccordionTab>
    );
  });

  const keySearch = (e) => {
    setSearchVal(e.target.value);
    if (e.key === "Enter") {
      const searched = e.target.value;

      fetchLedgerList(
        page,
        sizePerPage,
        searchVal,
        sortName,
        sortOrder,
        filterSelected,
        "",
        ""
      );
    }
    if (e.type === "keyup") {
      if (e.target.value === "") {
        fetchLedgerList(
          page,
          sizePerPage,
          searchVal,
          sortName,
          sortOrder,
          filterSelected,
          "",
          ""
        );
      }
    }
  };
  const emptySearch = () => {
    setSearchVal("");
    fetchLedgerList(
      page,
      sizePerPage,
      searchVal,
      sortName,
      sortOrder,
      filterSelected,
      "",
      ""
    );
  };

  const accountChange = (e) => {
    setFilterSelected(e.value);
    if (e.value != "Custom") {
      setDateRange("");
    }
    fetchLedgerList(
      page,
      sizePerPage,
      searchVal,
      sortName,
      sortOrder,
      e.value,
      "",
      ""
    );
  };

  function handleRangeDate(e) {
    console.log(e.target.value);
    setDateRange(e.target.value);
    if (e.target.value[1] !== null) {
      let sdate = format(new Date(e.target.value[0]), "yyy-MM-dd");
      let edate = format(new Date(e.target.value[1]), "yyy-MM-dd");
      // setStartDate(format(new Date(sdate),"yyy-MM-dd"));
      // setEndDate(format(new Date(edate),"yyy-MM-dd"));
      fetchLedgerList(
        page,
        sizePerPage,
        searchVal,
        sortName,
        sortOrder,
        filterSelected,
        sdate,
        edate
      );
    }
  }

  const header = (
    <div className="d-flex justify-content-between flex-wrap mb-1">
      <div className="text-t-primary  kk-table-header kk-header-sec row">
        <h5 className="heading">General Ledger</h5>
      </div>
      <div className="d-flex justify-content-end kk-table-header kk-header-sec">
        <Dropdown
          className="col-auto text-start me-3"
          value={filterSelected}
          options={coaStatus}
          onChange={(e) => accountChange(e)}
          scrollHeight="400px"
          placeholder="Select account"
        />
        {filterSelected === "Custom" ? (
          <Calendar
            className=" me-2 "
            id="range"
            value={dateRange}
            maxDate={new Date()}
            onChange={(e) => handleRangeDate(e)}
            selectionMode="range"
            showIcon
          />
        ) : (
          ""
        )}

        {/* <div className='col  border rounded my-auto' >
                <AiOutlineSearch />
                <input
                    className=' py-1 ps-3 search' value={searchVal} onChange={(e) => keySearch(e)}
                    onKeyUp={(e) => keySearch(e)} placeholder="Search" />
                {
                    searchVal !== '' ? <i className='pi pi-times' onClick={emptySearch} /> : ''
                }
            </div> */}
        <Space.Compact className="my-auto">
          <Input
            addonBefore={<SearchOutlined />}
            allowClear
            placeholder="Search"
            value={searchVal}
            onChange={(e) => keySearch(e)}
            onKeyUp={(e) => keySearch(e)}
          />
        </Space.Compact>
        {/* <FiPlus className='me-1' /> */}
        {/* <button className='btn btn-primary py-1 me-2 ' onClick={() => { modalref.current.alertToggle(); }} > <i className='pi pi-plus'></i>  New Account</button>

            <MultiSelect value={selectedColumns} options={columns} optionLabel="header"   optionDisabled="disabled" onChange={onColumnToggle} style={{ width: '3em' }} scrollHeight="400px" dropdownIcon="pi pi-table"
                tooltip="Custom Columns" tooltipOptions={{ position: 'top' }} panelHeaderTemplate={panelHeaderTemplate} /> */}
      </div>
    </div>
  );

  console.log(ledgerList, "DATA");
  return (
    <div>
      <div className="card">
        <div className="card-body  accordLeft">
          {header}
          <Accordion>{AccordionOne}</Accordion>
        </div>
      </div>
    </div>
  );
}

export default GeneralLedger;
