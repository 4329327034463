import config from '../assets/config';
import { ax } from './base';

export default class LedgerService {
    GetLedgerList(payload){
        return ax.post(`${config.apiUrl}LedgerEntry/GetLedgerSearchData`,payload)
    }
    GetDetailedLedgerEntry(payload){
        return ax.post(`${config.apiUrl}LedgerEntry/GetDetailedLedgerEntry`,payload)
    }
    GetLedgerSearchData(payload){
        return ax.post(`${config.apiUrl}LedgerEntry/GetLedgerSearchData`,payload)
    }
}


