import { format } from 'date-fns';
import React, { useLayoutEffect, useState } from 'react'
import {  Dropdown } from '../PrimeReact';
import BudgetService from '../../services/budget-service';
import GV from '../../services/globalVar';
import { Chart } from 'primereact/chart';

export default function ExpenseAnalysis() {
    const currentYear = new Date().getFullYear(); 
    const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const bts = new BudgetService();
    const options = years.map((cont,i) => {
        return (
            {
                label: cont,
                value: cont,
                id: i
            }
        )
    });
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [piechartData, setPieChartData] = useState({});
    const [piechartOptions, setPieChartOptions] = useState({});

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
   

    const onPageLoad = () =>{
            bts.GetBudgetBarcart(GV.userDet.tenantDetail.tenantID,selectedYear).then(res=>{
                const value = res.data.data
                const labelVal = []
                const forcast = []
                const expense = []
                value.forEach(x=>{
                    labelVal.push(x.budgetCategory)
                    forcast.push(x.budgetType.forecasting)
                    expense.push(x.budgetType.expenses)
                })
            

            const data = {
                labels:labelVal,
                datasets: [
                    {
                        label: 'Budget Forecast',
                        backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                        borderColor: documentStyle.getPropertyValue('--blue-500'),
                        data: forcast
                    },
                    {
                        label: 'Expenses',
                        backgroundColor: documentStyle.getPropertyValue('--pink-500'),
                        borderColor: documentStyle.getPropertyValue('--pink-500'),
                        data:expense
                    }
                ]
            };

            const options = {
                indexAxis: 'y',
                maintainAspectRatio: false,
                aspectRatio: 0.7,
                barPercentage:1.0,
                plugins: {
                    legend: {
                        labels: {
                            fontColor: textColor
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary,
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: false,
                            drawBorder: false
                        }
                    },
                    y: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder,
                            drawBorder: false
                        }
                    }
                }
            };
    
            setChartData(data)
            setChartOptions(options);
        })
    }

    const pieChart = () =>{
        bts.GetBudgetChart(GV.userDet.tenantDetail.tenantID,selectedYear).then(res=>{
                const value = res.data.data
                                                                                                       
                const documentStyle = getComputedStyle(document.documentElement);
                const data = {
                    labels: ['Budget Forecast','Expenses'],
                    datasets: [
                        {
                            data: [value.forecasting, value.expenses],
                            backgroundColor: [
                                documentStyle.getPropertyValue('--blue-500'), 
                                documentStyle.getPropertyValue('--green-500')
                            ],
                            hoverBackgroundColor: [
                                documentStyle.getPropertyValue('--blue-400'), 
                                documentStyle.getPropertyValue('--green-400')
                            ]
                        }
                    ]
                }
                const options = {
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true
                            }
                        }
                    }
                };

                setPieChartData(data);
                setPieChartOptions(options);
        });
    }

   useLayoutEffect(()=>{
    onPageLoad();
    pieChart()
    // register("accntType");
   },[selectedYear])


  return (
    <div>
        <div className='card'>
                <div className='card-body'>
                    <div className='d-flex justify-content-between flex-wrap'>
                        <h4>Expense Analysis</h4>
                        <div>
                
                        <Dropdown value={selectedYear} onChange={(e) =>{ setSelectedYear(e.value)}} options={options} optionlabel="label" optionvalue="value" 
                        placeholder="Select a Year" className="w-full md:w-14rem form-control" />
                        </div>
                    </div>
                </div>
        </div>
        <div className='card mt-3'>
            <div className='row mt-4 mb-4'>
                <div className='col-md-7' style={{borderRight:'1px solid #ededed'}}>
                        <Chart type="bar" data={chartData} options={chartOptions} />
                </div>
                <div className="col-md-5 d-flex justify-content-center">
                    <div style={{width:'400px',height:'400px'}}>
                        <Chart type="pie" data={piechartData} options={piechartOptions} className="w-full md:w-20rem" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
