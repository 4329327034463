import React, { useEffect, useState } from 'react';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import { Routes, Route } from 'react-router-dom';

import './App.scss';

import LoginForm from './components/login/LoginForm';
import Layout from './components/layout/Layout.js';
import ChartOfAccount from './components/Accountant/ChartOfAccount.js';
import OrganizationProfile from './components/settings/OrganizationProfile';
// import Customers from './components/Sales/Customers';
import Customers from './components/Sales/Customers.js';
import ListofItems from './components/Items/ListofItems';
import COATable from './components/Accountant/COATable';
import Vendor from './components/Purchases/Vendor';
import Ledger from './components/reports/Ledger';
// import NewJournal from './components/Accountant/NewJournal';
import NewJournal from './components/journal/NewJournal';
import JournalList from './components/journal/JournalList';
// import TestJournal from './components/Accountant/TestJournal';
import LedgerList from './components/reports/LedgerList';
import GeneralLedger from './components/reports/GeneralLedger';
import UpdateJournal from './components/journal/UpdateJournal';
import LocalStorageService from './services/localstorage';
import GV from './services/globalVar'
import LoginService from './services/login';
import { useNavigate } from "react-router-dom";
import { Suspense} from 'react';
import { ImSpinner10 } from 'react-icons/im';
// import { ProgressSpinner } from 'primereact/progressspinner';

import Dashboard from './components/Dashboard/Dashboard';
import { BlockUI } from 'primereact/blockui';
import LedgerSearch from './components/reports/LedgerSearch';
import ProfitLoss from './components/reports/Profit Loss/ProfitLoss';
import UserAccountManagement from './components/Administration/Security/UserAccountManagement';
import PettyCash from './components/PettyCash/PettyCash';
import PettyCashMonth from './components/PettyCash/PettyCashMonth';
import BudgetForecasting from './components/Budget/BudgetForecasting';
import Expenses from './components/Budget/Expenses';
import FundRequest from './components/Budget/FundRequest';
import FundApproval from './components/Budget/FundApproval';
import ExpenseAnalysis from './components/Budget/ExpenseAnalysis';
import ExpenseSummary from './components/Budget/ExpenseSummary';
import ExpenseSummaryView from './components/Budget/ExpenseSummaryView';
import Layouttwo from './components/layout/Layouttwo';
import PettyCashReport from './components/PettyCash/PettyCashReport';
import PettyCashWeek from './components/PettyCash/PettyCashWeek';
// import PurchaseExpense from './components/Budget/PurchaseExpense.js';
// import NewExpense from './components/Budget/NewPurchase.js';
import NewPurchase from './components/Purchases/NewPurchase.js';
// import NewPurchase from './components/Budget/NewPurchase.js';
import PurchaseExpense from './components/Purchases/PurchaseExpense.js';
import ExpenseTracking from './components/Budget/ExpenseTracking.js';
// import PurchaseList from './components/Budget/PurchaseList.js';
import PurchaseList from './components/Purchases/PurchaseList.js';
import NewDashboard from './components/Dashboard/NewDashboard.js';
// import Invoice from './components/Sales/Invoice.js';
// import Invoice from './components/Sales/Invoice.js';
// import Incoice from './components/Sales/Invoice.js'
// import PrivateRoute from './components/PrivateRoute';
import 'antd/dist/reset.css';
// import { Estimate } from './components/sales/Estimate.js';
// import EstimateGrid  from './components/Sales/EstimateGrid.js';
import CreditNoteGrid from './components/Sales/CreditNoteGrid.js';
import Invoice from './components/Sales/Invoice.js';
import { EstimateGrid } from './components/Sales/EstimateGrid.js';
import NewPurchaseOrderForm from './components/Purchases/NewPurchaseOrderForm.js';
import NewPurchaseOrderGrid from './components/Purchases/NewPurchaseOrderGrid.js';
import DebitNoteGrid from './components/Purchases/DebitNoteGrid.js';
import EmployeeLoanGrid from './components/Loan/EmployeeLoanGrid.js'
function App() {
  const navigate = useNavigate();
  const [blockedDocument, setBlockedDocument] = useState(false);
const lss = new LocalStorageService();
GV.userDet = lss.getItem('userDet')

useEffect(() => {
  if(blockedDocument) {
      setTimeout(() => {
          setBlockedDocument(false);
      }, 3000);
  }
  if(GV.userDet === undefined){
    navigate('/')
   }
}, [blockedDocument])

const blockDocument = () => {
  setBlockedDocument(true);
}

  return (
   
    <div className="App">
      <Suspense fallback={<div className='mt-5 text-center'>
        {/* <img src={ImSpinner10} alt="loading" width="200" height="150" /> */}
       <ImSpinner10 color='#000' size={100}/>
        {/* <ProgressSpinner/> */}
      </div>}>
      <BlockUI blocked={blockedDocument} fullScreen />
        {/* <Button type="button" label="Block" onClick={blockDocument} /> */}
      <Routes>
        <Route exact path="/" element={<LoginForm />} />
        {/* <Route exact path="/coa" element={<Layout><ChartOfAccount /></Layout>} /> */}
      
                <Route exact path="/organization-profile" element={<Layout><OrganizationProfile /></Layout>} />
                <Route exact path="/customers" element={<Layout><Customers /></Layout>} />
                <Route exact path="/items" element={<Layout><ListofItems /></Layout>} />
                <Route exact path="/coa" element={<Layout><COATable /></Layout>} />
                <Route exact path="/journal" element={<Layout><JournalList /></Layout>}  onClick={blockDocument}/>
                <Route exact path="/new-journal" element={<Layout><NewJournal /></Layout>} />
                <Route exact path="/update-journal" element={<Layout><UpdateJournal /></Layout>} />
                <Route exact path="/ledger" element={<Layout><Ledger /></Layout>} />
                <Route exact path="/pages/vendors" element={<Layout><Vendor /></Layout>} />
                <Route exact path="/pages/estimate" element={<Layout><EstimateGrid /></Layout>} />
                {/* <Route exact path="/general-ledger" element={<Layout><LedgerList /></Layout>} /> */}
                <Route exact path="general-ledger" element={<Layout><GeneralLedger /></Layout>} />
                <Route exact path="/pages/dashboard" element={<Layout><Dashboard /></Layout>} />
                <Route exact path="/pages/budget-dashboard" element={<Layout>< NewDashboard/></Layout>} />
                <Route exact path='/detail-ledger' element={<Layout><LedgerSearch /></Layout>} />
                <Route exact path='/profit-loss' element={<Layout><ProfitLoss /></Layout>} />
                <Route exact path="/administration/security" element={<Layout><UserAccountManagement/></Layout>}/>
                <Route exact path="/pettycash" element={<Layout><PettyCash/></Layout>}/>
                <Route exact path="/pettycash-month" element={<Layout><PettyCashMonth/></Layout>}/>
                <Route exact path="/budget-forecasting" element={<Layout><BudgetForecasting/></Layout>}/>
                <Route exact path="/expenses" element={<Layout><Expenses/></Layout>}/>
                <Route exact path="/fund-request" element={<Layout><FundRequest/></Layout>}/>
                <Route exact path="/fund-approval" element={<Layout><FundApproval/></Layout>}/>
                <Route exact path="/expense-analysis" element={<Layout><ExpenseAnalysis/></Layout>}/>
                <Route exact path="/expenses-analysis" element={<Layout><ExpenseSummary/></Layout>}/>
                <Route exact path="/expense-summaryview" element={<Layout><ExpenseSummaryView/></Layout>}/>  
                <Route exact path="/pettycash-report" element={<Layout><PettyCashReport/></Layout>}/>  
                <Route exact path="/pettycash-weekview" element={<Layout><PettyCashWeek/></Layout>}/>  
                <Route exact path="/purchase-expense" element={<Layout><PurchaseExpense/></Layout>}/>  
                <Route exact path="/new-purchase" element={<Layout><NewPurchase/></Layout>}/>  
                <Route exact path="/expense-tracking" element={<Layout><ExpenseTracking/></Layout>}/>  
                <Route exact path="/purchase-list" element={<Layout><PurchaseList/></Layout>}/>  
                <Route exact path="/invoice" element={<Layout><Invoice/></Layout>}/>  
                <Route exact path="/pages/creditnote" element={<Layout><CreditNoteGrid/></Layout>}/>  
                <Route exact path="/purchase/newpurchaseorder" element={<Layout><NewPurchaseOrderGrid/></Layout>}/>  
                <Route exact path="/purchase/debitnote" element={<Layout><DebitNoteGrid/></Layout>}/>  
                <Route exact path="/loan" element={<Layout><EmployeeLoanGrid/></Layout>}/>  
                   
                
      </Routes>
      </Suspense>
     </div>
  );
}

export default App;
