import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { Space, Input, Dropdown, Table, Tag, Menu, Flex } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ProgressSpinner } from "primereact/progressspinner";
import { DownOutlined } from "@ant-design/icons";
import { render } from "@testing-library/react";
import { Button, Modal } from "antd";
import GlobalVariable from "../../../../client/src/services/globalVar";
import salesService from "../../services/sales-service";
import { DataTable } from "primereact/datatable";
import { format } from "date-fns";
import { Dropdown as Primedropdown } from "primereact/dropdown";
import { Tooltip } from "antd";
import { IcomoonReact, iconSet } from "../PrimeReact";
import { Dialog } from "primereact/dialog";
import DebitNoteForm from "./DebitNoteForm";
import purchaseService from "../../services/purchase-sevice";
import InvoicePayment from "../Sales/InvoicePayment";

const DebitNoteGrid = () => {
  const ss = new salesService();
  const ps = new purchaseService();
  
  const paymodal = useRef(null);
  const [isProcess, setIsProcess] = useState(false);
  const [invoiceModelPop, setInvoiceModelPop] = useState(false);
  const [isDebitGrid, setisDebitGrid] = useState(true);
  const [isDebitGridModelPop, setIsDebitGridModelPop] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [first1, setFirst1] = useState(0);
  const [creditGrid, setCreditGrid] = useState([]);
  const [debitNumberErr, setDebitNumberErr] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [payment, setPayment] = useState("");
  const [docUrl, setDocUrl] = useState("");
  const [showDocument, setShowDocument] = useState(false);
  const [documentURL, setDocumentURL] = useState("");
  const [documentURLType, setDocumentURLType] = useState("");
  const { Search } = Input;
  const items = [
    { id: 1, label: "All", value: "ALL" },
    { id: 2, label: "Pending", value: "PENDING" },
    { id: 3, label: "Paid", value: "PAID" },
  ];
  const [filterGrid, setFilterGrid] = useState(items[0].value);

  const { Column, ColumnGroup } = Table;

  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });

  const GetPurchaseReturnListSearch = (
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder,
    filterGrid
  ) => {
    setIsProcess(true);
    const data = {
      InputParams: {
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        ContactID: 0,
        Status: filterGrid,
      },
      ServerSearchables: {
        draw: 1,
        searchData: searchVal || "",
        orderByColumn: sortName,
        orderBy: sortOrder,
        currentPage: 1,
        pageSize: sizePerPage,
        pageNumber: page,
      },
    };
    ps.GetPurchaseReturnListSearch(data)
      .then((res) => {
        setIsProcess(false);
        setCreditGrid(res.data.data?.purchaseReturnList);
        setTotalRecords(res.data.data.serverSearchables.recordsTotal);
      })
      .catch((err) => setIsProcess(false));
  };

  const onCustomPage1 = (event) => {
    setFirst1(event.first);
    const val = event.page + 1;
    const size = event.rows;
    setSizePerPage(size);
    setPage(val);
    GetPurchaseReturnListSearch(val, size, searchVal, "", "", "");
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const handleRowClick = (event) => {
    setRowData(event.data);
    setisDebitGrid(false);
  };

  const totalAmountBodyTemplate = (rowData) => {
    return (
      GlobalVariable.userDet.tenantConfig.currency +
      " " +
      rowData.totalAmount.toFixed(2)
    );
  };

  const balanceAmountBodyTemplate = (rowData) => {
    return (
      GlobalVariable.userDet.tenantConfig.currency +
      " " +
      rowData.balancePayment.toFixed(2)
    );
  };
  

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="d-flex">
        <div className="row">
          <div className="col-md d-flex p-0">
          {rowData.status === "OPEN" ? (
              <Tooltip title="Record Payment">
                <IcomoonReact
                  iconSet={iconSet}
                  color="#3e9d71"
                  size={20}
                  icon="payment"
                  className="pointer mx-2"
                  onClick={(e) => proceedPay(e, rowData)}
                  data-pr-tooltip="Edit"
                  data-pr-position="left"
                />
              </Tooltip>
            ) : (
              <Tooltip title="Record Payment">
                <IcomoonReact
                  iconSet={iconSet}
                  color="#3e9d71"
                  size={20}
                  style={{visibility:'hidden'}}
                  icon="payment"
                  className="pointer mx-2"
                  data-pr-tooltip="Edit"
                  data-pr-position="left"
                />
              </Tooltip>
            )}
            <Tooltip title="View Invoice">
              <i
                className="pi pi-eye icon-tooltip"
                icon="view"
                style={{ color: "slateblue", fontSize: "1.3rem" }}
                onClick={(e) => fetchDocuments(e, rowData)}
              ></i>
            </Tooltip>

            <Dialog
          visible={showDocument}
          onHide={onHide}
          header="Document"
          modal
          style={{ width: "50vw" }}
        >
          {showDocument ? (
            documentURL ? (
              documentURL.endsWith(".pdf") ? (
                <embed
                  src={documentURL}
                  type="application/pdf"
                  style={{ width: "100%", height: "400px" }}
                />
              ) : documentURLType.endsWith(".png") ||
                documentURLType.endsWith(".jpg") ||
                documentURLType.endsWith(".jpeg") ? (
                <img
                  src={documentURL}
                  alt="Document"
                  style={{ width: "100%", height: "400px" }}
                />
              ) : (
                <iframe
                  src={documentURL}
                  style={{ width: "100%", height: "400px" }}
                />
              )
            ) : (
              <div>No document found</div>
            )
          ) : null}
        </Dialog>
          </div>
        </div>
      </div>
    );
  };

  const onHide = () => {
    setShowDocument(false);
  };

  const proceedPay = (e, data) => {
    e.stopPropagation();
    setPayment(data);
    paymodal.current.alertToggle(data);
  };

  const dateFormBodyTemplate = (rowData) => {
    return (
      <div>
        {format(
          new Date(rowData.returnDate),
          GlobalVariable.userDet.tenantConfig.dateFormat
        )}
      </div>
    );
  };


  const renderStatusColumn = (rowData) => {
    let status = rowData.status?.toUpperCase();
    let statusClass;
    switch (status) {
      case "OPEN":
        statusClass = "orange";
        break;
      case "PARTIALLY PAID":
        statusClass = "processing";
        break;
      case "PAID":
        statusClass = "green";
        break;
      case "DRAFT":
        statusClass = "purple";
        break;
      default:
        statusClass = "cyan";
    }
    return (
      <Tag bordered={false} color={statusClass}>
        {status}
      </Tag>
    );
  };


  const onSearch = (value, _e, info) => {
    console.log(value,'value');
    
    if (value === "") return setDebitNumberErr("Enter Expenses Number");
    const data = {
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      PurchaseCode: value,
    };
    ps.GetPurchaseByCode(data).then((res) => {
      console.log(res.data,'che')
      if (res.data.message === "Already Exists") {
        setDebitNumberErr("This Invoice Already Returned");
        return
      } if (res.data.message === "Unpaid Purchase") {
        setDebitNumberErr("Unpaid Purchase");
        return
      } if (res.data.message === "Purchase Not Found") {
        setDebitNumberErr("Purchase Not Found");
        return
      }
      else {
        setDebitNumberErr("");
        setisDebitGrid(false);
        setInvoiceModelPop(false);
        setRowData(res.data.data);
      }
    });
  };

  const keySearch = (e) => {
    const searched = e.target.value;
    setSearchVal(searched);
    if (e.key === "Enter") {
      setPage(1);
      GetPurchaseReturnListSearch(1, sizePerPage, searched, "", "", "");
    }

    if (e.type === "keyup" && searched === "") {
      GetPurchaseReturnListSearch(1, sizePerPage, "", "", "", "");
    }
  };

  const emptySearch = () => {
    setSearchVal("");
    GetPurchaseReturnListSearch(1, sizePerPage, "", "", "", "");
  };

  const rowClick = (record) => {
    console.log(record, "row");
    setRowData(record);
  };

  const handleModelOkBtn = () => {
    setInvoiceModelPop(false);
    setisDebitGrid(false);
  };
  const handleMenuClick = (e) => {
    setSelectedItem(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {items.map((item) => (
        <Menu.Item key={item.label}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  const backToDebitNote = (item) => {
    setisDebitGrid(item);
    GetPurchaseReturnListSearch(page, sizePerPage, searchVal, "", "", "");
  };

  const fetchDocuments = (e, datas) => {
    e.stopPropagation();
    setIsProcess(true);
    const data = {
      DocID: datas.docID,
    };
    
    ps.GetPurchaseDocs(data)
    .then((res) => {
      if (res.data.data.length > 0) {
          console.log(res.data,'datas')
          setIsProcess(false);
          setDocumentURL(res.data.data[0].documentURL);
          setDocumentURLType(res.data.data[0].fileName);
          setShowDocument(true);
        } else {
          setShowDocument(true);
          setIsProcess(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
          setIsProcess(false);

      });
  };
  

  const parentFunction = () => {
    GetPurchaseReturnListSearch(page, sizePerPage, searchVal, "", "", "");
  };

  useEffect(() => {
    GetPurchaseReturnListSearch(page, sizePerPage, searchVal, "", "", "");
  }, []);

  useEffect(() => {
    GetPurchaseReturnListSearch(
      page,
      sizePerPage,
      searchVal,
      "",
      "",
      filterGrid
    );
  }, [filterGrid]);

  return (
    <div className="row">
       <InvoicePayment
        ref={paymodal}
        data={{ payment }}
        parentFunction={parentFunction}
      />
      {isDebitGrid ? (
        <div className="col-md-12">
          <div className="card card-borderless cc-greyCard-custm mb-3">
            <div className="ms-2 my-2 me-2">
              <div className="d-flex justify-content-between flex-wrap align-items-center my-2">
                <h4 className="m-0">Debit Note</h4>
                <div className="col-md-3 d-flex justify-content-between kk-table-header kk-header-sec cc-font-size-14">
                  <div className="col  border rounded my-auto aa-search">
                    <Space.Compact className="my-auto">
                      <Input
                        addonBefore={<SearchOutlined />}
                        allowClear={emptySearch}
                        placeholder="Search"
                        value={searchVal}
                        onChange={(e) => keySearch(e)}
                        onKeyUp={(e) => keySearch(e)}
                      />
                    </Space.Compact>
                  </div>

                  <button
                    className="btn btn-primary py-1 me-2 mx-2 kk-btnThin my-auto"
                    onClick={() => {
                      setInvoiceModelPop(true);
                      setInvoiceNumber("");
                    }}
                  >
                    {" "}
                    <i className="pi pi-plus"></i> Create New
                  </button>
                  
                </div>
              </div>
            </div>
          </div>
         
          <div className="account-table">
           
            <DataTable
              className=" chofacc"
              value={creditGrid}
              // responsiveLayout="scroll"
              size="small"
              loading={isProcess}
              paginator
              lazy
              totalRecords={totalRecords}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rowsPerPageOptions={[10, 20, 50, totalRecords]}
              first={first1}
              rows={sizePerPage}
              onPage={onCustomPage1}
              onSort={onSort}
              sortField={lazyParams.sortField}
              sortOrder={lazyParams.sortOrder}
              onRowClick={handleRowClick}
            >
              
              <Column
                field="dateFormBodyTemplate"
                header="Date"
                body={dateFormBodyTemplate}
              />
              <Column field="companyName" header="Company Name" />
              <Column field="purchaseCode" header="Purchase Number" />
              <Column field="debitNoteNumber" header="Debit Note Number" />
              <Column field="invoiceNo" header="Invoice Number" />
              {/* {dynamicColumns} */}
              <Column
                field="statusBodyTemplate"
                header="Status"
                className="action"
                body={(e) => renderStatusColumn(e)}
              ></Column>
              <Column
                body={totalAmountBodyTemplate}
                header="Amount"
                className="text-end amount-align"
                field="totalAmountBodyTemplate"
              />
              <Column
                body={balanceAmountBodyTemplate}
                header="Balance"
                className="text-end amount-align"
                field="totalAmountBodyTemplate"
              />
              <Column
                body={actionBodyTemplate}
                header="Action"
                field="actionBodyTemplate"
              />
            </DataTable>
            
          </div>
          {/*</div> */}
        </div>
      ) : (
        <DebitNoteForm
        data={{ rowData: rowData, backToDebitNote: backToDebitNote }}
        documentURLs={documentURL}
        showDocuments={showDocument}
      />
      
      )}
      <Modal
        title="Enter Expenses Number"
        centered
        footer={null}
        open={invoiceModelPop}
        onCancel={() => {
          setInvoiceModelPop(false);
          setDebitNumberErr("");
          setInvoiceNumber("");
        }}
        className="antMdl"
      >
        <div>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Search
              placeholder="PCB013"
              className="antBTn-clr"
              onSearch={onSearch}
              enterButton="GO"
              value={invoiceNumber}
              onChange={(e) =>{ setInvoiceNumber(e.target.value); if(e.target.value.trim() === "") setDebitNumberErr("")}}
            />
          </Space>

          <p className="text-danger my-2">{debitNumberErr}</p>
        </div>
      </Modal>
    </div>
  );
};

export default DebitNoteGrid;
