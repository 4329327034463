// import config from '../config';
import config from '../assets/config';
import { ax } from './base';

export default class AccountantService {
   
    GetCOASearchData(payload){
        return ax.post(`${config.apiUrl}COA/GetCOASearchData`,payload)
    }
    SaveMasCOA(payload){
        return ax.post(`${config.apiUrl}COA/SaveMasCOA`,payload)
    }
    GetCOATypes(){
        return ax.get(`${config.apiUrl}Common/GetCOATypes`)
    }
    COATypes(tenantId){
        return ax.get(`${config.apiUrl}Common/GetCOATypes/${tenantId}`)
    }
    
}