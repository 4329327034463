import {
  DataTable,
  Column,
  Dropdown,
  InputText,
  MultiSelect,
  iconSet,
  IcomoonReact,
} from "../PrimeReact.js";
// import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";
import { Button, Row } from "react-bootstrap";
import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AccountantService from "../../services/accountant";
import JournalService from "../../services/journal.js";
import { format } from "date-fns";
import "../../assets/styles/accountant/journal.scss";
import { AiOutlineSearch } from "react-icons/ai";
import NewJournal from "../journal/NewJournal.js";
import GV from "../../services/globalVar";
import { SearchOutlined } from "@ant-design/icons";
import { Tooltip, Input, Space } from "antd";

const JournalList = () => {
  const history = useNavigate();

  const modalref = useRef(null);
  const acs = new JournalService();

  const [first1, setFirst1] = useState(0);
  const [tdataLoad, setTdataLoad] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [data, setData] = useState([]);
  const [totalRecordsIn, setTotalRecordsIn] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [isMounted, setIsMounted] = useState(true);
  const [journalSelected, setJournalSelected] = useState("All");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dates2, setDates2] = useState("");

  const journalStatus = [
    { label: "All", value: "All", status: true },
    { label: "Published", value: "Published", status: true },
    { label: "Draft", value: "Draft", status: true },
  ];
  const periodStatus = [
    { label: "All", value: "All", status: false },
    { label: "Today", value: "Today", status: false },
    { label: "This Week", value: "This Week", status: false },
    { label: "This Month", value: "This Month", status: false },
    { label: "This Quarter", value: "This Quarter", status: false },
    { label: "This Year", value: "This Year", status: false },
    { label: "Custom", value: "Custom", status: true },

    // { label: 'Today', start: new Date(), end: new Date() },
    // { label: 'This Week', start: new Date(new Date().setDate(new Date().getDate() + 7)), end: new Date() },
    // { label: 'This Month', start: new Date(new Date().setDate(new Date().getDate() + 12)), end: new Date() },
    // { label: 'This Quarter', start: new Date(new Date().setDate(new Date().getDate())), end: new Date() },
    // { label: 'This Year', start: new Date(new Date().setDate(new Date().getDate())), end: new Date() },
    // { label: 'Yesterday', start: new Date(new Date().setDate(new Date().getDate() - 1)), end: new Date() },
  ];
  //journal header status
  const [statusSelected, setStatusSelected] = useState(journalStatus[0].value);
  const [filterSelected, setFilterSelected] = useState(journalStatus[0].value);
  const [periodStatusSelected, setPeriodStatusSelected] = useState(
    periodStatus[0].value
  );
  const [periodFilterSelected, setPeriodFilterSelected] = useState(
    periodStatus[0].value
  );

  const editProduct = (product) => {
    modalref.current.alertToggledit(product);
  };

  const actionBodyTemplate = (rowData) => {
    // console.log("data",rowData);
    return (
      <React.Fragment>
        {rowData.status == "Draft" ? (
          <IcomoonReact
            iconSet={iconSet}
            color="#444"
            size={20}
            icon="edit"
            className="pointer"
            onClick={() => {
              history("/new-journal", {
                state: { id: rowData.journalID, data: false },
              });
            }}
          />
        ) : (
          // <IcomoonReact iconSet={iconSet} color="#444" size={20} icon="eye" className='pointer' onClick={() => { history('/new-journal',{state: rowData.journalID})}} />
          <i
            className="pi pi-eye mr-2 pointer"
            onClick={() => {
              history("/new-journal", {
                state: { id: rowData.journalID, data: true },
              });
            }}
          ></i>
        )}
        {/* onClick={() => { history('/journal')}} */}
        {/* navigate('/componentB',{state:{id:1,name:'sabaoon'}}); */}
        {/* onClick={() => console.log(rowData)} */}
        {/* <i className="pi pi-pencil mr-2 pointer" onClick={() => editProduct(rowData)}></i> */}
      </React.Fragment>
    );
  };
  const dateBodyTemplate = (row) => {
    return format(new Date(row.journalDate, "dd-MM-yyyy"));
  };

  const panelHeaderTemplate = () => {
    return (
      <div className="pt-2 ps-4">
        {/* <p className='mb-0 fs-6'>Select</p> */}
      </div>
    );
  };

  const basiccolumns = [
    // { field: 'journalDate', header: 'Date', sortable: false, body: '' },
    // { field: 'journalNumber', header: 'Journal No', sortable: false, body: '' },
    // { field: 'referenceNumber', header: 'Reference No', sortable: false, body: '' },
    // { field: 'note', header: 'Note', sortable: false, body: '' },
    // { field: 'totalCredits', header: 'Amount', sortable: false, body: '' },
    // { field: 'status', header: 'Status', sortable: false, body: '' },
    // { field: 'createdBy', header: 'Created By', sortable: false, body: '' },
  ];
  const columns = [
    // { field: 'journalDate', header: 'Date', sortable: false, body: ''},
    // { field: 'journalNumber', header: 'Journal No', sortable: false, body: '' },
    {
      field: "referenceNumber",
      header: "Reference No",
      sortable: false,
      body: "",
    },
    // { field: 'totalCredits', header: 'Amount', sortable: false, body: '' },
    { field: "note", header: "Note", sortable: false, body: "" },

    // { field: 'status', header: 'Status', sortable: false, body: '' },

    { field: "createdBy", header: "Created By", sortable: false, body: "" },
  ];
  const [selectedColumns, setSelectedColumns] = useState(basiccolumns);

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };
  const journalChange = (e) => {
    setFilterSelected(e.value);
    // fetchJournalList(page, sizePerPage, searchVal, '', '',)
    let obj = journalStatus.find((c) => c.value === e.value);
    if (obj.status === true) {
      setStatusSelected(e.value);
      fetchJournalList(
        page,
        sizePerPage,
        searchVal,
        "",
        "",
        e.value,
        periodStatusSelected
      );
    } else {
      setStatusSelected(e.value);
      fetchJournalList(
        page,
        sizePerPage,
        searchVal,
        "",
        "",
        e.value,
        periodStatusSelected
      );
    }
  };

  const periodChange = (e) => {
    setPeriodFilterSelected(e.value);
    // fetchJournalList(page, sizePerPage, searchVal, '', '',filterSelected,periodFilterSelected)
    let obj = periodStatus.find((c) => c.value === e.value);
    console.log("e", e);
    if (obj.period === true) {
      setPeriodStatusSelected(e.value);
      // setJournalSelected(e.value);
      if (e.value === "Custom") {
      } else {
        setDates2("");
        fetchJournalList(
          page,
          sizePerPage,
          searchVal,
          "",
          "",
          statusSelected,
          e.value
        );
      }
    } else {
      setPeriodStatusSelected(e.value);
      if (e.value === "Custom") {
      } else {
        setDates2("");
        fetchJournalList(
          page,
          sizePerPage,
          searchVal,
          "",
          "",
          statusSelected,
          e.value
        );
      }
    }
  };
  const keySearch = (e) => {
    setSearchVal(e.target.value);
    if (e.key === "Enter") {
      const searched = e.target.value;
      fetchJournalList(
        1,
        sizePerPage,
        searched,
        sortName,
        sortOrder,
        statusSelected,
        periodStatusSelected
      );
    }
    if (e.type === "keyup") {
      if (e.target.value === "") {
        fetchJournalList(
          1,
          sizePerPage,
          "",
          sortName,
          sortOrder,
          statusSelected,
          periodStatusSelected
        );
      }
    }
  };
  const emptySearch = () => {
    setSearchVal("");
    fetchJournalList(
      1,
      sizePerPage,
      "",
      sortName,
      sortOrder,
      statusSelected,
      periodStatusSelected
    );
  };

  function handleRangeDate(e) {
    console.log("range date", e.target.value);
    // format(new Date(startDate), 'yyyy-MM-dd')

    setDates2(e.target.value);

    if (e.target.value[1] !== null) {
      let sdate = format(new Date(e.target.value[0]), "yyy-MM-dd");
      let edate = format(new Date(e.target.value[1]), "yyy-MM-dd");
      setStartDate(format(new Date(sdate), "yyy-MM-dd"));
      setEndDate(format(new Date(edate), "yyy-MM-dd"));
      fetchJournalList(
        1,
        sizePerPage,
        "",
        sortName,
        sortOrder,
        statusSelected,
        periodStatusSelected,
        sdate,
        edate
      );
    }
    // let edate=e.target.value[1];

    // console.log(" custom date",sdate,edate);
    // setStartDate(format(new Date(sdate), 'yyyy-MM-dd'));
    // setEndDate(format(new Date(edate), 'yyyy-MM-dd'));
    // setDates2(e.target.value);
    // if(startDate!=null && edate!=new Date('1970-01-01')){
    //     // if(startDate<endDate){
    //     fetchJournalList(1, sizePerPage, '', sortName, sortOrder, statusSelected,periodStatusSelected ,startDate,endDate,);
    //     // }
    // }
    // fetchJournalList(1, sizePerPage, '', sortName, sortOrder, statusSelected,periodStatusSelected );
  }
  console.log(" start date", startDate);
  console.log(" end date", endDate);

  // const customOptionTemplate = (option) => {
  //     return (
  //         <div >
  //         <div>{option.label}</div>
  //         {option.status===true?
  //         <Calendar className=' p-3 ' id="range" value={dates2}  maxDate={new Date()} onChange={(e) => handleRangeDate(e)} selectionMode="range" readOnlyInput showIcon />
  //         :""}
  //         </div>
  //     );
  // }

  // const countries = [
  //     { name: 'Australia', code: 'AU' },
  //     { name: 'Brazil', code: 'BR' },
  //     { name: 'China', code: 'CN' },
  //     { name: 'Egypt', code: 'EG' },
  //     { name: 'France', code: 'FR' },
  //     { name: 'Germany', code: 'DE' },
  //     { name: 'India', code: 'IN' },
  //     { name: 'Japan', code: 'JP' },
  //     { name: 'Spain', code: 'ES' },
  //     { name: 'United States', code: 'US' }
  // ];

  // const countryTemplate = (option) => {
  //     return (
  //         <div className="country-item">
  //             <Calendar className=' p-3 ' id="range" value={dates2}  maxDate={new Date()} onChange={(e) => handleRangeDate(e)} selectionMode="range" readOnlyInput showIcon />
  //             <div>{option.name}</div>
  //         </div>
  //     );
  // }

  // const [selectedCountries, setSelectedCountries] = useState(null);

  const header = (
    <div className="d-flex justify-content-between flex-wrap">
      <div className="text-t-primary  kk-table-header kk-header-sec row">
        {/* <div className='row'> */}
        {/* <label className='my-auto me-3'>Status</label> */}
        <Dropdown
          className="col-auto text-start me-3"
          value={filterSelected}
          options={journalStatus}
          onChange={(e) => journalChange(e)}
          scrollHeight="400px"
          placeholder="Select Journal"
        />
        {/* </div> */}
        <div className="col  border rounded my-auto">
          <AiOutlineSearch />
          <input
            className=" py-1 ps-3 search"
            value={searchVal}
            onChange={(e) => keySearch(e)}
            onKeyUp={(e) => keySearch(e)}
            placeholder="Search"
          />
          {searchVal !== "" ? (
            <i className="pi pi-times" onClick={emptySearch} />
          ) : (
            ""
          )}
        </div>

        {/* <div className='col-auto '>
                
                <label className='my-auto me-3'>Period</label>
                

                <Dropdown className='col-auto text-start me-3 period-dropdown' value={periodFilterSelected} options={periodStatus} onChange={(e) => periodChange(e)} optionLabel="label"  
                   scrollHeight="400px" 
                //    itemTemplate={customOptionTemplate} 
                   />
            </div> */}
      </div>
      {/* <div className='col-md-3'>
                        <Row>
                            <label className='col-auto my-auto'>From</label>
                            <input type="text" className='form-control col '></input>
                            <label className='col-auto my-auto'>To</label>
                            <input type="text" className='form-control col '></input>
                        </Row>
            </div> */}
      <div className="d-flex my-1 ">
        {/* <FiPlus className='me-1' /> */}
        {/* <label className='my-auto me-2'>Period</label> */}
        {/* <Dropdown className='col-auto text-start me-3 period-dropdown' value={periodFilterSelected} options={periodStatus} onChange={(e) => periodChange(e)}
                    scrollHeight="400px"
                    placeholder="Select Journal" /> */}

        {/* <Dropdown className='col-auto text-start me-3 period-dropdown' value={periodFilterSelected} options={periodStatus} onChange={(e) => periodChange(e)} optionLabel="label"  
                   scrollHeight="400px" 
                   itemTemplate={customOptionTemplate} 
                   /> */}
        <div className="col-auto ">
          <label className="my-auto me-3">Period</label>

          <Dropdown
            className="col-auto text-start me-3 period-dropdown"
            value={periodFilterSelected}
            options={periodStatus}
            onChange={(e) => periodChange(e)}
            optionLabel="label"
            scrollHeight="400px"
            //    itemTemplate={customOptionTemplate}
          />
        </div>

        {periodStatusSelected === "Custom" ? (
          <Calendar
            className=" me-2 "
            id="range"
            value={dates2}
            maxDate={new Date()}
            onChange={(e) => handleRangeDate(e)}
            selectionMode="range"
            showIcon
          />
        ) : (
          ""
        )}

        {/* <MultiSelect value={selectedCountries} options={countries} onChange={(e) => setSelectedCountries(e.value)} optionLabel="name" placeholder="Select Countries" className="multiselect-custom"
                    itemTemplate={countryTemplate}  format(new Date(dates2), 'yyyy-MM-dd') />
                */}

        {/* <div className="dropdown">
                    <button className="btn  dropdown-toggle sm-2" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false">
                        Period
                    </button>
                    <ul className="dropdown-menu " style={{width:'300px'}} aria-labelledby="dropdownMenuButton1">
                        <li><label className="dropdown-item"  value="All" onChange={(e) => console.log(e.target.value)}>All</label></li>
                        <li><a className="dropdown-item" value={periodFilterSelected} options={periodStatus} onChange={(e) => periodChange(e)}>Today</a></li>
                        <li><Calendar className=' p-3 ' placeholder='Custom' id="range" value={dates2}  maxDate={new Date()} onChange={(e) => handleRangeDate(e)} selectionMode="range" readOnlyInput showIcon />
                        
                        </li>
                    </ul>
                   
                </div> */}

        <button
          className="btn btn-primary py-1 me-2 "
          onClick={() => {
            history("/new-journal", { state: { id: null, data: false } });
          }}
        >
          {" "}
          <i className="pi pi-plus"></i> New Journal
        </button>

        <MultiSelect
          value={selectedColumns}
          options={columns}
          optionLabel="header"
          optionDisabled="disabled"
          onChange={onColumnToggle}
          style={{ width: "3em" }}
          scrollHeight="400px"
          dropdownIcon="pi pi-table"
          tooltip="Custom Columns"
          tooltipOptions={{ position: "top" }}
          panelHeaderTemplate={panelHeaderTemplate}
        />
      </div>
    </div>
  );

  const dynamicColumns = selectedColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={col.sortable}
        body={col.body && actionBodyTemplate && dateBodyTemplate}
      ></Column>
    );
    // (
    //     <React.Fragment>

    //         <Column key={col.field} body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
    //     </React.Fragment>
    // );
  });

  const dynamicColumnsOne = selectedColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={col.sortable}
        body={col.body}
      ></Column>
    );
  });

  const onCustomPage1 = (event) => {
    setFirst1(event.first);

    const val = event.page + 1;
    const size = event.rows;

    setSizePerPage(size);
    setPage(val);
    setLazyParams(event);
    fetchJournalList(
      val,
      size,
      searchVal,
      "",
      "",
      statusSelected,
      periodStatusSelected
    );
  };
  //pagination end

  //sort start
  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });

  const onSort = (event) => {
    setLazyParams(event);
    // console.log(event)
  };

  //sort end

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  const loadLazyData = () => {
    setTdataLoad(true);
  };

  //apicall

  function fetchJournalList(
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder,
    status,
    period,
    sdate,
    edate
  ) {
    if (period !== "Custom") {
      sdate = "";
      edate = "";
    }
    // setTdataLoad(true);
    setTdataLoad(false);
    let data = {
      InputParams: {
        ID: 0,
        TenantID: GV.userDet.tenantDetail.tenantID,
        Status: status,
        Period: period,
        FromDate: sdate,
        ToDate: edate,
      },
      // "ServerSearchables":{"draw":1,"searchData":"Single Date","orderByColumn":"","orderBy":"","pageSize":10,"pageNumber":1}
      // "InputParams": { "ID": 0, "TenantID": 1, "Status": status, "Type": account },
      ServerSearchables: {
        draw: page,
        searchData: searchVal,
        orderByColumn: sortName,
        orderBy: sortOrder,
        currentPage: page,
        pageSize: sizePerPage,
        pageNumber: page,
      },
    };

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      acs
        .GetJournalList(data)
        .then((response) => {
          // if (isMounted) {
          if (response.data) {
            console.log("xyz", response.data);
            const grid = response.data.data.journalList;
            console.log(grid, "grid");
            if (response.data.statusCode == 200) {
              setTotalRecordsIn(
                response.data.data.serverSearchables.recordsTotal
              );
              setData(grid);
              setRequestData(response.data.data.journalList);
              //   setData(requestData.slice(0,sizePerPage));
              setTdataLoad(false);
            } else if (response.data.statusCode == 500) {
              setData([]);
              setRequestData([]);
              setTotalRecordsIn(0);
            }
            // }
          }
          // if (response.data) {
          //     console.log('d',response.data);
          //     const grid = response.data.data.journalList;

          //     setTotalRecordsIn(response.data.data.serverSearchables.recordsTotal);

          //     setData(grid);
          //     setRequestData(response.data.data.journalList);
          //     //   setData(requestData.slice(0,sizePerPage));
          //     setTdataLoad(false);
          // }
          // }
        })
        .catch(function (error) {
          if (error.response.data.statusCode == 500) {
            setData([]);
            setRequestData([]);
            setTotalRecordsIn(0);
          }
          console.log(error);
        });
    }, Math.random() * 1000 + 250);
  }

  const parentFunction = () => {
    fetchJournalList(
      page,
      sizePerPage,
      searchVal,
      "",
      "",
      statusSelected,
      periodStatusSelected
    );
  };
  useLayoutEffect(() => {
    fetchJournalList(
      page,
      sizePerPage,
      searchVal,
      "",
      "",
      statusSelected,
      periodStatusSelected
    );
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* <div
        className="d-flex justify-content-between p-3 mb-2 text-t-primary  kk-table-header kk-header-sec mb-0"
        style={{ backgroundColor: "white" }}
      >
        <h4 className="kk-bold">Journal</h4>
      </div> */}

      <div className="card card-borderless cc-greyCard-custm mb-3">
        <div className="ms-2 my-2 me-2">
          <div className="d-flex justify-content-between flex-wrap my-2">
            <h4 className="m-0">Journal</h4>
            <div className="d-flex justify-content-end text-t-primary kk-table-header kk-header-sec  cc-font-size-14">
              <div className="col">

              <Dropdown
                className="col-auto text-start me-3"
                value={filterSelected}
                options={journalStatus}
                onChange={(e) => journalChange(e)}
                scrollHeight="400px"
                placeholder="Select Journal"
              />

              <Space.Compact className="my-auto me-4">
                <Input
                  addonBefore={<SearchOutlined />}
                  allowClear
                  placeholder="Search"
                  value={searchVal}
                  onChange={(e) => keySearch(e)}
                  onKeyUp={(e) => keySearch(e)}
                />
              </Space.Compact>

                <strong className="my-auto me-3">Period</strong>

                <Dropdown
                  className="col-auto text-start me-3 period-dropdown"
                  value={periodFilterSelected}
                  options={periodStatus}
                  onChange={(e) => periodChange(e)}
                  optionLabel="label"
                  scrollHeight="400px"
                  //    itemTemplate={customOptionTemplate}
                />

              {periodStatusSelected === "Custom" ? (
                <Calendar
                  className=" me-2 "
                  id="range"
                  value={dates2}
                  maxDate={new Date()}
                  onChange={(e) => handleRangeDate(e)}
                  selectionMode="range"
                  showIcon
                />
              ) : (
                ""
              )}

              <button
                className="btn btn-primary py-1 me-2 ms-2 kk-btnThin my-auto"
                onClick={() => {
                  history("/new-journal", { state: { id: null, data: false } });
                }}
              >
                {" "}
                <i className="pi pi-plus"></i> New Journal
              </button>

              <MultiSelect
                value={selectedColumns}
                className="kk-btnThin my-auto"
                options={columns}
                optionLabel="header"
                optionDisabled="disabled"
                onChange={onColumnToggle}
                style={{ width: "3em" }}
                scrollHeight="400px"
                dropdownIcon="pi pi-table"
                tooltip="Custom Columns"
                tooltipOptions={{ position: "top" }}
                panelHeaderTemplate={panelHeaderTemplate}
              />
            </div>
            </div>

          </div>
        </div>
      </div>

      <div className="bg-white shadow-sm chart-of-account h-100 p-4">
        <div className="row coa-header kk-header-sec">
          <div className="col-auto header-right ms-auto"></div>
        </div>
        <div className="account-table">
          <DataTable
            stripedRows
            value={data}
            // header={header}
            // responsiveLayout="scroll"
            size="small"
            loading={tdataLoad}
            paginator
            lazy
            totalRecords={totalRecordsIn}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rowsPerPageOptions={[10, 20, 50, totalRecordsIn]}
            first={first1}
            rows={sizePerPage}
            onPage={onCustomPage1}
            onSort={onSort}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
          >
            <Column
              field="journalDate"
              header="Date"
              body={(row) => (
                <div>
                  {" "}
                  {format(
                    new Date(row.journalDate),
                    GV.userDet.tenantConfig.dateFormat
                  )}
                </div>
              )}
            />
            <Column field="journalNumber" header="Journal No" />
            {/* <Column field= "currencyCode" header= "Currency Code" body={(row) => GV.userDet.tenantConfig.currency}/> */}
            <Column
              field="totalCredits"
              className="text-end amount-align"
              header="Amount"
              min={0}
              max={10}
              step={0.0}
              value={Number(0).toFixed(2)}
              body={(row) =>
                GV.userDet.tenantConfig.currency +
                " " +
                row.totalCredits.toFixed(2)
              }
            />
            <Column field="status" header="Status" />
            {dynamicColumns}
            <Column
              field="actionBodyTemplate"
              header="Action"
              body={actionBodyTemplate}
            />
          </DataTable>
        </div>
      </div>
      {/* <NewJournal ref={modalref} parentFunction={parentFunction} /> */}
    </div>
  );
};

export default JournalList;
