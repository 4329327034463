import { Divider } from 'primereact/divider';
import React, { useEffect, useRef, useState } from 'react'
import { Card,Toast,Dropdown } from '../PrimeReact';
import {useForm, Controller } from 'react-hook-form';
import GV from '../../services/globalVar';
import BudgetService from '../../services/budget-service';
import { ProgressSpinner } from "primereact/progressspinner";


export default function Expenses() {
    let isFinancialYear = GV.userDet.tenantConfig.isFinancialYear;

    const bud = new BudgetService();
    const [userDrawer, setUserDrawer] = useState(false);
    const [userDrawerCategory, setUserDrawerCategory] = useState(false);
    const [userSubDrawer, setUserSubDrawer] = useState(false);
    const [subCategory, setSubCategory] = useState([]);
    const[test, setTest] = useState([]);
    const[test1, setTest1] = useState([]);
    const [subData, setSubData] = useState([]);
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [financialYearCatagery, setFinancialYearCatagery] = useState([]);
    const [dynamicMonth, setDynamicMonth] = useState([]);
    const [defaultValuess, setdefaultValuess] = useState([]);

    const currentYear = new Date().getFullYear(); 
    const options = Array.from({ length: 10 }, (_, index) => currentYear - index);
    const [selectedYear, setSelectedYear] = useState(currentYear);

    const years = options.map((cont,i) => {
        return (
            {
                label: cont,
                value: cont,
                id: i
            }
        )
    });
    const defaultValues = { //1
        // subcat:test?.budgetSubCategory,
        jan: '',
        feb: "",
        mar: "",
        apr: "",
        may: "",
        jun: "",
        jul: "",
        aug: "",
        sept: "",
        oct:"",
        nov: "",
        dec:"",
    };

    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        formState: { errors },
      } = useForm({ mode: "all", defaultValues });
    const handleYearChange = (e) => {
        setSelectedYear(e);
    };

    useEffect(() => {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        let startMonth = GV.userDet?.tenantConfig?.startMonth;
        let endMonth = GV.userDet?.tenantConfig?.endMonth ;
        let financialMonths = [];
        for (let i = (startMonth - 1); i < months.length; i++) {
          financialMonths = [...financialMonths,months[i].slice(0,3)];
        }
        for (let j = 0; j < endMonth; j++) {
            financialMonths = [...financialMonths,months[j].slice(0,3)];
        }
        setDynamicMonth(financialMonths);
      },[]);
      
    const yearsDetails = [
        { id: 1, years: "Apr 2015 - Mar 2016", selectYear: 2015 },
        { id: 2, years: "Apr 2016 - Mar 2017", selectYear: 2016 },
        { id: 3, years: "Apr 2017 - Mar 2018", selectYear: 2017 },
        { id: 4, years: "Apr 2018 - Mar 2019", selectYear: 2018 },
        { id: 5, years: "Apr 2019 - Mar 2020", selectYear: 2019 },
        { id: 6, years: "Apr 2020 - Mar 2021", selectYear: 2020 },
        { id: 7, years: "Apr 2021 - Mar 2022", selectYear: 2021 },
        { id: 8, years: "Apr 2022 - Mar 2023", selectYear: 2022 },
        { id: 9, years: "Apr 2023 - Mar 2024", selectYear: 2023 },
        { id: 10, years: "Apr 2024 - Mar 2025", selectYear: 2024 },
      ];
      let getSorted = yearsDetails.slice();
      getSorted.sort((a, b) => {
        const yearA = parseInt(a.years.split(" ")[1]);
        const yearB = parseInt(b.years.split(" ")[1]);
        return yearB - yearA;
      });
      const defaultvalueDropdown = getSorted[0];
      const [monthYears, setmonthYears] = useState(defaultvalueDropdown.years);
      const saveYears = generateYearsDropdown();

      function generateYearsDropdown() {
        let currentYear = new Date().getFullYear();
        let lastTenYears = currentYear - 10;
        let yearsArray = [];
        let financialYearStartingMonth = GV.userDet?.tenantConfig?.startMonthName.slice(0, 3);
        let financialYearStartingMonthID = GV.userDet?.tenantConfig?.startMonth;
        let financialYearEndingMonth = GV.userDet?.tenantConfig?.endMonthName.slice(0,3);
        let financialYearEndingMonthID = GV.userDet?.tenantConfig?.endMonth;
        for (let i = currentYear; i > lastTenYears; i--) {
          let yearObj = {
            id: i,
            years: `${financialYearStartingMonth} ${i} - ${financialYearEndingMonth} ${
              i + 1
            }`,
            selectYear: i,
            startMnth: financialYearStartingMonthID,
            startYr: i,
            endMnth: financialYearEndingMonthID,
            endYr: i+1
          };
          yearsArray = [...yearsArray, yearObj];
        }
        console.log(yearsArray, "yearsArray");
        return yearsArray;
      }

      function findYearRange(x) {
        let today = new Date();
        let financialYearStartingMonth = GV.userDet?.tenantConfig?.startMonthName.slice(0, 3);
        let financialYearEndingMonth = GV.userDet?.tenantConfig?.endMonthName.slice(0, 3);
        let lastDayOfMonth= new Date(today.getFullYear(),today.getMonth()+1, 0)
        let monthStart = new Date(financialYearStartingMonth +" "+ x.selectYear);
        let monthEnd = new Date(financialYearEndingMonth+" "+ (x.selectYear + 1) + " " + lastDayOfMonth.getDate());
        return (
          new Date() >= monthStart &&
          new Date() <= monthEnd
        );
      }
      let getSlectYear = "";

      const handleYearChange1 = (e) => {
        getSlectYear = e.split(" ")[1];
        getBudgetForecastingFinancialYear(getSlectYear);
        setmonthYears(e);
      };
      function getBudgetForecastingFinancialYear(year) {
        let tenid = GV.userDet.tenantDetail.tenantID;
        bud
          .GetBudgetForecasting(tenid, year)
          .then((response) => {
            if (response.data) {
              if (response.data.statusCode === 200) {
                setIsLoading(false);
                setSubCategory(response.data.data);
                setFinancialYearCatagery(response.data.data);
              }
            }
          })
          .catch(function (error) {});
      }
    const onClickEdit =(value)=>{
        // reset();
        // setValue('subcat',value?.budgetSubCategory);
        // setValue('jan',value.month[0]?.budgetInfo?.forecastingAmount);
        // setValue('feb',value.month[1]?.budgetInfo?.forecastingAmount);
        // setValue('mar',value.month[2]?.budgetInfo?.forecastingAmount);
        // setValue('apr',value.month[3]?.budgetInfo?.forecastingAmount);
        // setValue('may',value.month[4]?.budgetInfo?.forecastingAmount);
        // setValue('jun',value.month[5]?.budgetInfo?.forecastingAmount);
        // setValue('jul',value.month[6]?.budgetInfo?.forecastingAmount);
        // setValue('aug',value.month[7]?.budgetInfo?.forecastingAmount);
        // setValue('sept',value.month[8]?.budgetInfo?.forecastingAmount);
        // setValue('oct',value.month[9]?.budgetInfo?.forecastingAmount);
        // setValue('nov',value.month[10]?.budgetInfo?.forecastingAmount);
        // setValue('dec',value.month[11]?.budgetInfo?.forecastingAmount);
    }

    function getBudgetForecasting() {
        let tenid = GV.userDet.tenantDetail.tenantID;
        let year = selectedYear;

        bud.GetBudgetForecasting(tenid, year)
            .then((response) => {
                if (response.data) {
                    if (response.data.statusCode === 200) {
                        setIsLoading(false);
                        setSubCategory(response.data.data);
                    }
                }
            }).catch(function (error) {

            })
    }
    useEffect(() => {
      if (isFinancialYear === false) {
        getBudgetForecasting();
      } else {
        let dfaultFinancialCurreent = saveYears.find((x) => findYearRange(x));
        getBudgetForecastingFinancialYear(dfaultFinancialCurreent.selectYear);
        setmonthYears(dfaultFinancialCurreent);
      }
    }, [selectedYear]);
  return (
    <div>
        <div>
            <Toast ref={toast}></Toast>
           
            {/* <div className='right-fixed '> */}
            <div className='card'>
                <div className='card-body'>
                    <div className='d-flex justify-content-between flex-wrap'>
                        <h4>Expenses</h4>
                        <div>
                           
                        </div>
                    </div>
                </div>
            </div>

            {isFinancialYear === false && (
                <div className='card mt-2'>
                <div className='mt-2 row mb-2 '><div>
                    <div className=' row mx-auto'>
                        <div className='col-md-2  ps-0 me-1'>
                            <div className='ps-3 mt-0 '>
                            <Dropdown value={selectedYear} onChange={(e) =>{ setSelectedYear(e.value)}} options={years} optionlabel="label" optionvalue="value" 
                        placeholder="Select a Year" className="w-full md:w-14rem form-control" />
                                {/* <Controller
                                    render={({ field }) => (
                                        <Dropdown className='yr-down'  options={options}
                                            {...field} value={selectedYear} onChange={(e) => handleYearChange(e.value)}
                                        />
                                    )}
                                  
                                    name="contact"
                                /> */}
                            </div>
                        </div>

                        <div className='ps-1 col-md mt-1 pb-1 pt-1 text-center odd' >
                            Jan
                        </div>
                        <div className='ps-1 col-md mt-1 pb-1 pt-1 text-center'>
                            Feb
                        </div>
                        <div className='ps-1 col-md mt-1 pb-1 pt-1 text-center odd'>
                            Mar
                        </div>
                        <div className='ps-1 col-md mt-1 pb-1 pt-1 text-center'>
                            Apr
                        </div>
                        <div className='ps-1 col-md mt-1 pb-1 pt-1 text-center odd'>
                            May
                        </div>
                        <div className='ps-1 col-md mt-1 pb-1 pt-1 text-center'>
                            Jun
                        </div>
                        <div className='ps-1 col-md mt-1 pb-1 pt-1 text-center odd'>
                            Jul
                        </div>
                        <div className='ps-1 col-md mt-1 pb-1 pt-1 text-center'>
                            Aug
                        </div>
                        <div className='ps-1 col-md mt-1 pb-1 pt-1 text-center odd'>
                            Sept
                        </div>
                        <div className='ps-1 col-md mt-1 pb-1 pt-1 text-center'>
                            Oct
                        </div>
                        <div className='ps-1 col-md mt-1 pb-1 pt-1 text-center odd'>
                            Nov
                        </div>
                        <div className='ps-1 col-md mt-1 pb-1 pt-1 text-center'>
                            Dec
                        </div>
                    </div>
                </div>
                </div>
            </div>
            )}

{isFinancialYear === true && (
            <div className="card mt-2">
              <div className="mt-2 row mb-2 p-1">
                <div>
                  <div className=" row mx-auto">
                    <div className="col-md-2  ps-0 me-1">
                      <div className="mt-0 ">
                      <Controller
                          render={({ field }) => (
                            <Dropdown
                              className="yr-down budget-drop"
                              placeholder="Select an option"
                              options={saveYears.map((year) => ({
                                value: year,
                                label: year.years,
                              }))}
                              {...field}
                              value={monthYears}
                              onChange={(e) => handleYearChange1(e.value)}
                            />
                          )}
                          control={control}
                          name="monthWithYears"
                        />
                      </div>
                    </div>

                    {dynamicMonth?.map((item, index) => (
                      <div
                        className={`ps-1 col-md mt-1 pb-1 pt-1 text-center  ${
                            index % 2 === 0 ? "odd" : "even"
                          }`}
                        key={index}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isLoading && (
            <ProgressSpinner
              style={{
                width: "50px",
                height: "50px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              strokeWidth="4"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          )}
            {/* </div> */}
            {/* <div className='card-fixed'> */}
            <div className='mt-2 abc'>
                {
                    subCategory?.categories?.map((item, i) => {
                        // Initialize total for each month
                        const totalForecastingAmountByMonth = {};
                        return (
                            <Card className=' mt-2' key={i}>
                                <div className='d-flex justify-content-between flex-wrap'>
                                    <h6>{item.budgetCategory}</h6>
                                    <div>
                                        {/* <p className='customFont mb-0'> <span className='ms-3 pointer text-decoration-underline text-primary' onClick={() => {setUserSubDrawer(true);setTest(item)}}> Add Expense </span> </p> */}
                                    </div>
                                </div>
                               
                                <Divider />
                                {
                                    item?.subCategory?.map((sub, i) => {
                                        return (
                                            <div key={i} className='row ps-1 mt-1'>
                                                <div className='col-md-2 ps-0'>
                                                    {/* <div className='ps-1 mt-1 pb-1 pt-1 sub-cat pointer text-decoration-underline'  onClick={() =>{ setUserDrawer(true);onClickEdit(sub);setTest(item);setTest1(sub)}}>
                                                    <a  data-toggle="tooltip" data-placement="top" title="Edit sub category">{sub.budgetSubCategory}</a>
                                                    </div> */}
                                                    <div className='ps-1 mt-1 pb-1 pt-1 sub-cat pointer'>
                                                       
                                                            {sub.budgetSubCategory}
                                                    </div>
                                                </div>
                                                {
                                                    sub?.month?.map((mon, j) => {
                                                        // Calculate forecastingAmount for the current month
                                                        const expenses = mon?.budgetInfo ? mon.budgetInfo.expenses : null;
                                                        // Accumulate forecastingAmount to the total for the month
                                                        totalForecastingAmountByMonth[j] = (totalForecastingAmountByMonth[j] || null) + expenses;
                                                        return (
                                                            <div key={j} className={`ps-1 col-md mt-1 pb-1 pt-1  d-flex justify-content-end  ${j % 2 === 0 ? "odd" : "even"}`} >
                                                                {expenses === 0 ? expenses?.toFixed(2) :expenses }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        );
                                    })
                                }

                                {/* Display the total for each month in a single div */}
                                <div className='row ps-1 mt-1 total-forecasting-amount'>
                                    <div className='col-md-2 ps-0'>
                                        <div className='ps-1 mt-1 pb-1 pt-1 sub-cat pointer text-bold'>Total</div>
                                    </div>
                                    {
                                        Object.values(totalForecastingAmountByMonth).map((total, k) => (
                                            <div key={k} className={`ps-1 col-md mt-1 pb-1 pt-1   d-flex justify-content-end  ${k % 2 === 0 ? "teven" : "teven"}`}>
                                                {total.toFixed(2)}
                                            </div>
                                        ))
                                    }
                                </div>
                            </Card>
                        );
                    })
                }
            </div>
           {/* </div> */}
            

        </div>
    </div>
  )
}
