import React, { useEffect, useState } from 'react';
import LeftMenu from './LeftMenu';
import TopBar from './TopBar';
import '../../assets/styles/layout/layout.scss';
import LeftMenuOne from './LeftMenuOne';
import LoginService from '../../services/login';
import LocalStorageService from '../../services/localstorage';
import GV from '../../services/globalVar'
import { useNavigate } from "react-router-dom";
import LeftMenuTwo from './LeftMenuTwo'

const Layout = ({ children }) => {
    const lgs = new LoginService();
    const lss = new LocalStorageService();
  const navigate = useNavigate();

    const [activeMenu, setActiveMenu] = useState(false);
    const[showMenu,setShowMenu]= useState(false);

    const menuCallback = (val) => {
        setActiveMenu(val);
    }
    const menuCallback1=(val,val1)=>{
        setShowMenu(val);
        setActiveMenu(val1);
    }
    // const userDetails= ()=>{
    //     let vals =localStorage.getItem('user')
    //     lgs.UserProfile(vals).then(res=>{
    //         console.log(res.data.data,"DATA")
    //         // lss.setItem('userDet',res.data.data)
    //         GV.userDet = res.data.data
    //     })
    // }
    // useEffect(() =>{
    //     // userDetails();
    //     GV.userDet = lss.getItem('userDet')
    //     console.log(GV.userDet)
    // },[]);

    useEffect(()=>{
        if(GV.userDet === undefined){
          navigate('/')
        }
  },[])
    return (
     
        
        <React.Fragment>
           
            <div className='container-body'>
                <div  style={{position:'fixed',zIndex:'1000',width:'100vw'}}>
                <TopBar data={{ menuCallback: menuCallback,menuCallback1:menuCallback1 }} />
                </div>
                <div id="section" className='row g-0 ' style={{paddingTop:'50px'}}>
                    {GV.userDet.tenantConfig.isConfigCompleted === true ? 
                        <div className={`sidemenu ${activeMenu ? "activeMenu" : showMenu ? "showMenu" :""}  col-md-2 pt-3`} style={{width:'76px', zIndex:"1000"}} >
                            {/* <LeftMenu  data={{active:activeMenu,showMenu:showMenu}}/> */}
                            <LeftMenuTwo data={{active:activeMenu,showMenu:showMenu}} />
                            {/* <LeftMenuOne/> */}
                        </div>
                    : ''}
                       {/* ,borderRadius:'25px 0px 0px 0px',boxShadow: 'inset 0 3px 4px rgba(0, 0, 0, .1)' */}
                    <div className='child-container col pe-4 ms-2 '  style={{background:'#FAFBF9',paddingTop:'20px'}}>
                        <div className='pt-2 mt-2 ms-3 ps-3'>
                        {children}
                        </div>
                    </div>
                </div>
            </div>
             
        </React.Fragment>
      
    );
}

export default Layout;