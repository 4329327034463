import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Button as Primebutton } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import GV from "../../services/globalVar";
import PettycashServiceService from "../../services/pettycash-service";
import purchaseService from "../../services/purchase-sevice";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import "primeicons/primeicons.css";
import { Controller, useForm } from "react-hook-form";
import InvoiceService from "../../../../client/src/services/invoice-service";
import { Button, Divider, Input, Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Divider as Primediveder } from "primereact/divider";
import { BlockUI } from "primereact/blockui";

const NewPurchase = ({
  goBack,
  selectedPurchase,
  purchaseExpList,
  setSelectedPurchase,
  GetPurchaseListSearch,
  documentURL,
  showDocument,
  onHide,
  documentURLType,
  hidendShowUplod,
  setHidendShowUplod,
}) => {
  // const [purchaseNo, setPurchaseNo] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [gstTrteatment, setGstTrteatment] = useState(null);
  // const [vendorName, setVendorName] = useState(null);
  const [etRadio1, setEtRadio1] = useState("Goods");
  const ps = new purchaseService();
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [purchaseNo, setPurchaseNo] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [expenseType, setExpenseType] = useState([]);
  const [paidThroughDropDown, setPaidThroughDropDown] = useState();
  const [vendorNameList, setVendorNameList] = useState([]);
  const [selectVendorNameList, setSelectVendorNameList] = useState();
  const [selectedExpenseAccount, setSelectedExpenseAccount] = useState([]);
  const [selectedPaidThrough, setSelectedPaidThrough] = useState();
  const [gstTreatmentDropDown, setGstTreatmentDropDown] = useState();
  const [hsnCodeDropDown, setHsnCodeDropDown] = useState();
  const [clearUpload, setClearUpload] = useState(false);
  const [totalTax, setTotalTax] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [displayTotalExpenses, setDisplayTotalExpenses] = useState(0);
  const [displayTotalTax, setDisplayTotalTax] = useState(0);
  const [taxAmount, setTaxAmount] = useState([]);
  const [total, setTotal] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showTotalErr, setShowTotalErr] = useState(false);
  const [purchaseInstance, setPurchaseInstance] = useState([]);
  const [hideUpDoc, setHideUpDoc] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [countryTaxDetails, setCountryTaxDetails] = useState({});
  const [isProcess, setIsProcess] = useState(false);
  const [isAddItem, setIsAddItem] = useState(true);
  const [manualErr, setManualErr] = useState(false);
  const [productDrop, setProductDrop] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [sgstTax, setSgstTax] = useState(0);
  const [cgstTax, setCgstTax] = useState(0);
  const [sgstTaxAmount, setSgstTaxAmount] = useState(0);
  const [igstTax, setIgstTax] = useState(0);
  const [igstTaxAmount, setIgstTaxAmount] = useState(0);
  const [cgstTaxAmount, setCgstTaxAmount] = useState(0);
  const [netAmountValue, setNetAmountValue] = useState(0);
  const [name, setName] = useState("");
  const [productDrop1, setProductDrop1] = useState([]);
  const [discountValue, setDiscountValue] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState("0.00");
  const [roundOff, setRoundOff] = useState(0);
  const [tenantDetails, setTenantDetails] = useState({});

  const inputRef = useRef(null);

  const is = new InvoiceService();

  // let dataOutside;

  //to show required field based on dropdown
  const [isTax, setIsTax] = useState(false);
  const [isGstin, setIsGstin] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [errorsTax, setErrorsTax] = useState(false);
  const [formDataa, setFormData] = useState([
    {
      id: 1,
      inputtextarea: "",
      hsnsac: "",
      tax: "",
      seperateamount: "",
      taxamount: "",
      total: "",
    },
  ]);
  const [errorsField, setErrorsField] = useState(false);

  const businessList = [
    {
      id: 1,
      name: "Registered business - regular",
      value: "Registered business - regular",
    },
    {
      id: 2,
      name: "Registered business - composition",
      value: "Registered business - composition",
    },
    { id: 3, name: "Unregistered business", value: "Unregistered business" },
    { id: 4, name: "Consumer", value: "Consumer" },
    { id: 5, name: "Overseas", value: "Overseas" },
    { id: 6, name: "Special ecomoninc zone", value: "Special ecomoninc zone" },
    { id: 7, name: "Deemed export", value: "Deemed export" },
    { id: 8, name: "Tax deductor", value: "Tax deductor" },
    { id: 9, name: "SEZ developer", value: "SEZ developer" },
  ];

  const hslCodeDetails = [
    { id: 1, hscode: "0101.29.10 ", description: "Horses for polo" },
    { id: 1, hscode: "0302.53 00 ", description: "Coal fish" },
    { id: 1, hscode: "0409 ", description: "Natural honey." },
    { id: 1, hscode: "0504.00.31 ", description: "wild animals" },
    {
      id: 1,
      hscode: "0505.90.10 ",
      description: "Peacock tail and wing feather",
    },
    { id: 1, hscode: "0703.10.10 ", description: "Onions" },
    { id: 1, hscode: "0712.32.00 ", description: "Wood ears" },
    { id: 1, hscode: "0804.20 ", description: "Figs" },
  ];

  const [invoiceLines, setInvoiceLines] = useState([
    {
      id: 1,
      productname: "",
      quantity: 0,
      prequantity: "",
      rate: 0,
      discount: 0,
      statetax: 0,
      centraltax: 0,
      intertax: 0,
      returnreason: "",
      netamount: "",
      producttype: "",
      shippngcharge: "",
    },
  ]);

  const handleSelectProduct = (selectedProduct, index) => {
    setManualErr("");
    const updatedLines = invoiceLines.map((line, i) => {
      if (i === index) {
        return {
          id: line.id,
          productname: selectedProduct,
          quantity: 1,
          prequantity: 0,
          rate: selectedProduct.siSellingPrice?.toFixed(2),
          discount: 0,
          statetax: selectedProduct.localTaxRate,
          centraltax: selectedProduct.centralTaxRate,
          intertax: selectedProduct.interTaxRate,
          netamount: selectedProduct.siSellingPrice,
          returnreason: "",
          producttype: selectedProduct.itemType,
        };
      }

      return line;
    });
    handleTaxChangeWithoutIndex(updatedLines, index);
    handleTaxChange(updatedLines);
    setInvoiceLines(updatedLines);
  };

  const handleInputChangeQuantity = (e, i) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[i].quantity = e.target.value;
    setInvoiceLines(newInvoiceLines);
    let addSubTotal = newInvoiceLines
      .map((a) => a.rate * a.quantity)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSubTotal(addSubTotal);
    handleTaxChangeWithoutIndex(newInvoiceLines, i);
    handleTaxChange(newInvoiceLines);
  };

  const handleInputChangeDiscount = (e, i) => {
    setManualErr("");
    const inputValue = e.target.value;

    if (inputValue.includes("-")) {
      const neg = inputValue.replace("-", "");
      e.target.value = neg;
    }

    const newInvoiceLines = [...invoiceLines];
    console.log(newInvoiceLines, "tt");
    newInvoiceLines[i].discount = e.target.value;
    setDiscountValue(e.target.value);
    setInvoiceLines(newInvoiceLines);

    let disPer = newInvoiceLines
      .map((a) => +a.discount)
      .reduce((acc, total) => acc + total, 0);
    let calculatePer = (+disPer * 100) / (newInvoiceLines.length * 100);
    let a = calculatePer;
    setDiscountValue(a);
    handleTaxChangeWithoutIndex(newInvoiceLines, i);
    handleTaxChange(newInvoiceLines);
  };

  const handleTaxChangeWithoutIndex = (lines, i) => {
    const newInvoiceLines = [...lines];
    console.log(newInvoiceLines, "newInvoiceLines");
    let total = 0;

    let totalDiscountAmount = newInvoiceLines
      .map((a) => {
        let discountPercentage = parseFloat(a.discount || 0) / 100;
        let quant = Number(a.quantity) * (a.productname ? a.rate : a.rate);
        return quant * discountPercentage;
      })
      .reduce((acc, ttl) => acc + ttl, 0);

    let discount =
      totalDiscountAmount && isFinite(totalDiscountAmount)
        ? totalDiscountAmount.toFixed(2)
        : "0.00";
    setDiscountedAmount(discount);

    let separateLineItem =
      (newInvoiceLines[i]?.productname
        ? newInvoiceLines[i]?.rate
        : newInvoiceLines[i]?.rate) *
      (+newInvoiceLines[i]?.discount / 100);
    console.log(separateLineItem, "separateLineItem");
    let discountPrice =
      (newInvoiceLines[i]?.productname
        ? +newInvoiceLines[i]?.productname.selectedDates.siSellingPrice
        : newInvoiceLines[i]?.rate) - +separateLineItem;

    let itemPrice = newInvoiceLines[i]?.productname
      ? +newInvoiceLines[i]?.rate
      : +newInvoiceLines[i]?.rate;
    let finalAmount =
      +newInvoiceLines[i]?.quantity * itemPrice - +separateLineItem;

    let collectTax =
      +(newInvoiceLines[i]?.statetax || 0) +
      (+newInvoiceLines[i]?.centraltax || 0) +
      (+newInvoiceLines[i]?.intertax || 0);
    let findTax = (finalAmount * collectTax) / 100;
    total = finalAmount + findTax;
    newInvoiceLines[i].netamount = total.toFixed(2);
  };

  const handleTaxChange = (newlines) => {
    setInvoiceLines(newlines);
    // setNetAmountValue(0);
    let disPer = newlines
      .map((a) => +a.discount)
      .reduce((acc, total) => acc + total, 0);
    let calculatePer = (+disPer * 100) / (newlines.length * 100);
    setDiscountValue(calculatePer);

    let addSubTotal = newlines
      .map((a) => a.rate * a.quantity)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSubTotal(addSubTotal);

    let calculateStateTax = newlines
      .map(
        (a, i) =>
          (Number(
            a.quantity * a.productname
              ? a.productname.selectedDates.siSellingPrice
              : a.rate
          ) /
            100) *
          +a.statetax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateStateTaxPercentage = newlines
      .map((a) => +a.statetax)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSgstTax(calculateStateTaxPercentage);

    let calculateCentralTax = newlines
      .map(
        (a, i) =>
          (Number(
            a.quantity * a.productname
              ? a.productname.selectedDates.siSellingPrice
              : a.rate
          ) /
            100) *
          +a.centraltax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateCentralTaxPercentage = newlines
      .map((a) => +a.centraltax)
      .reduce((acc, ttl) => acc + ttl, 0);
    setCgstTax(calculateCentralTaxPercentage);

    let calculateInterTax = newlines
      .map(
        (a, i) =>
          (Number(
            a.quantity * a.productname
              ? a.productname.selectedDates.siSellingPrice
              : a.rate
          ) /
            100) *
          +a.intertax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateInterTaxPercentage = newlines
      .map((a) => +a.intertax)
      .reduce((acc, ttl) => acc + ttl, 0);

    setIgstTax(calculateInterTaxPercentage);

    setSgstTaxAmount(
      isFinite(calculateStateTax) ? calculateStateTax.toFixed(2) : 0
    );
    setCgstTaxAmount(
      isFinite(calculateCentralTax) ? calculateCentralTax.toFixed(2) : 0
    );
    setIgstTaxAmount(
      isFinite(calculateInterTax) ? calculateInterTax.toFixed(2) : 0
    );

    let roundVal = newlines
      .map((b) => +b.netamount - Math.trunc(+b.netamount))
      .reduce((acc, total) => acc + total, 0);
    setRoundOff(isFinite(roundVal) ? roundVal.toFixed(2) : 0);
    let totalAm = newlines
      .map((b) => +b.netamount)
      .reduce((acc, total) => acc + total, 0);

    setNetAmountValue(isFinite(totalAm) ? totalAm : 0);

    console.log(netAmountValue);
  };

  const deleteLineItem = (e, index) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    if (newInvoiceLines.length <= 1) {
      toast.current.show({
        severity: "error",
        summary: "You can't delete the last line item",
      });
      return;
    }
    toast.current.show({
      severity: "success",
      summary: "Deleted successfully",
    });
    newInvoiceLines.splice(index, 1);
    setInvoiceLines(newInvoiceLines);
    handleTaxChange(newInvoiceLines);
  };

  const toast = useRef(null);

  const handleGSTtreatment = (e) => {
    setIsTax(false);
    setIsGstin(false);
    setIsInvoice(false);
    const selectedValue = e.target.value;
    const selectedValue1 = e.target;
    setGstTrteatment(selectedValue);
    switch (selectedValue) {
      case "Registered business - regular":
        setIsTax(true);
        setIsGstin(true);
        setIsInvoice(true);
        break;

      case "Registered business - composition":
        setIsGstin(true);
        setIsInvoice(true);
        break;

      case "Unregistered business":
        break;

      case "Consumer":
        break;

      case "Overseas":
        break;

      case "Special ecomoninc zone":
        setIsTax(true);
        setIsGstin(true);
        setIsInvoice(true);
        break;

      case "Deemed export":
        setIsTax(true);
        setIsGstin(true);
        setIsInvoice(true);
        break;

      case "Tax deductor":
        setIsTax(true);
        setIsGstin(true);
        setIsInvoice(true);
        break;

      case "SEZ developer":
        setIsTax(true);
        setIsGstin(true);
        setIsInvoice(true);
        break;

      default:
        break;
    }
  };
  const handleDate = (e) => {
    setSelectedDate(e.value);
  };

  const clearUploadedFile = () => {
    setUploadedFiles(null);
    setClearUpload(true);
  };

  const onUploadFile = (event) => {
    setUploadedFiles(event.files[0]);
    setClearUpload(true);
  };

  const GetPurchaseCode = () => {
    const datas = {
      TenantID: GV.userDet.tenantDetail.tenantID,
      ServiceName: "PurchaseInvoice",
    };
    ps.GenerateExpConfigNumber(datas).then((res) => {
      console.log(res.data.data, "res.data.data");
      if (selectedPurchase) {
        setPurchaseNo(selectedPurchase.purchaseCode);
      } else {
        setPurchaseNo(res.data.data);
      }
    });
  };

  const GetMasCOACategories = () => {
    const data = {
      COAMasID: 0,
      TenantID: GV.userDet.tenantDetail.tenantID,
      Status: "Active",
      COAType: "Expense",
    };
    ps.GetMasCOACategories(data).then((res) => {
      const expenseTypes = res.data.data[0].coaCategoryList;
      setExpenseType(expenseTypes);
      if (selectedPurchase?.coaMasID) {
        let id = expenseTypes
          .map((c) => c?.masCOAList)
          ?.flat(1)
          .find((f) => f.coaMasID === selectedPurchase?.coaMasID);
        let x = expenseTypes.find((f) => f?.categoryID === id?.categoryID);
        setSelectedExpenseAccount(x);
        setPaidThroughDropDown(x?.masCOAList);
        setSelectedPaidThrough(id);
        // setPaidThroughDropDown(id);
      }
    });
  };

  const expenseAccountOnChange = (e) => {
    setSelectedExpenseAccount(e);
    let vals = e.masCOAList;
    setPaidThroughDropDown(vals);
  };

  const paidThroughOnchange = (e) => {
    setSelectedPaidThrough(e);
    // trigger('paidthrough')
  };
  const vendorNameOnchange = (e) => {
    setSelectVendorNameList(e);
    // trigger('paidthrough')
  };

  const GetContactFilteredList = () => {
    const data = {
      TenantID: GV.userDet.tenantDetail.tenantID,
      ContactType: "Vendor",
      SearchData: "",
    };
    ps.GetContactFilteredList(data).then((res) => {
      const contactName = res.data.data.map((a) => {
        return a;
      });
      setVendorNameList(contactName);
      if (selectedPurchase) {
        let vndr = res.data.data.find(
          (x) => x?.contactID === selectedPurchase.vendorID
        );
        setSelectVendorNameList(vndr);
        setValue("vendorname", vndr);
      }
    });
  };

  const defaultValues = {
    purchasenumber: "",
    calendar: "",
    gsttreatment: "",
    expenseaccount: null,
    paidthrough: "",
    enteramount: "",
    type: "",
    hsncode: "",
    // tax: "",
    vendorname: "",
    gstin: "",
    invoicee: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues });

  const clearFields = () => {
    setFormData([
      {
        id: 1,
        inputtextarea: "",
        hsnsac: "",
        tax: "",
        seperateamount: "",
        taxamount: "",
        total: "",
      },
    ]);
  };

  const cancelForm = () => {
    reset();
    clearFields();
    setSelectedPaidThrough(null);
    setPaidThroughDropDown(null);
    setSelectedExpenseAccount(null);
    setSelectVendorNameList(null);
    setIsTax(false);
    setIsGstin(false);
    setIsInvoice(false);
    setSelectedDate("");
    setEtRadio1("");
    clearUploadedFile();
    setErrorsField(false);
    setErrorsTax(false);
  };

  const handleGoBackClick = () => {
    setSelectedPurchase(null);
    reset();
    clearFields();
    cancelForm();
    goBack();
    setErrorsField(false);
  };

  const handleSaveBtn = (data, status) => {
    // if(data.enteramount !== netAmountValue) {
    //   setShowTotalErr(true);
    //   return;
    // } else {
    //   setShowTotalErr(false);
    // }
    if (selectedPurchase) {
      setIsLoading(true);
      const InputJson = {
        PurchaseID:
          selectedPurchase?.purchaseID > 0 ? selectedPurchase?.purchaseID : 0,
        PurchaseCode: purchaseNo,
        PurchaseOrderID: 0,
        PurchaseDate: `${data.calendar.getFullYear()}-${(
          data.calendar.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${data.calendar
          .getDate()
          .toString()
          .padStart(2, "0")}`,
        PONumber: "",
        GstTreatmentID: 0,
        GstTreatment: data.gsttreatment,
        CategoryID: selectedExpenseAccount?.categoryID,
        COACategory: selectedExpenseAccount?.coaCategoryName,
        COAMasID: selectedPaidThrough?.coaMasID,
        COAName: selectedPaidThrough?.coaName,
        InvoiceNo: data.invoicee,
        VendorID: selectVendorNameList?.contactID,
        VendorGSTIN: data.gstin,
        CompanyName: selectVendorNameList?.companyName,
        FirstName: selectVendorNameList?.firstName,
        LastName: selectVendorNameList?.lastName,
        Address1: selectVendorNameList?.baAddress1,
        Address2: selectVendorNameList?.baAddress2,
        City: selectVendorNameList?.baCity,
        State: selectVendorNameList?.baState,
        ZipCode: selectVendorNameList?.baZipCode,
        Country: selectVendorNameList?.baCountry,
        phone: selectVendorNameList?.phone,
        email: selectVendorNameList?.email,
        Fax: selectVendorNameList?.baFax,
        SubTotal: subTotal,
        DiscountType: "Percentage",
        DiscountRate: discountValue,
        DiscountAmount: discountedAmount,
        LocalTaxName: countryTaxDetails.localTaxName,
        LocalTaxRate: sgstTax,
        LocalTaxAmount: sgstTaxAmount,
        InterTaxName: countryTaxDetails?.interTaxName,
        InterTaxRate: igstTax,
        InterTaxAmount: igstTaxAmount,
        CentralTaxName: countryTaxDetails?.centralTaxName,
        CentralTaxRate: cgstTax,
        CentralTaxAmount: cgstTaxAmount,
        IsOtherTax: false,
        TotalAmount: netAmountValue,
        DocID: 0,
        PurchaseStatus: status,
        TenantID: GV.userDet.tenantDetail.tenantID,
        CreatedBy: GV.userDet.appUser.appuserID.toString(),
        IsExpenseChanged: true,
        PurchaseInstance: invoiceLines.map((e, j) => ({
          PurchaseInstID: purchaseInstance
            ? purchaseInstance[j]?.purchaseInstID
            : 0,
          PurchaseID: purchaseInstance ? purchaseInstance[j]?.purchaseID : 0,
          ItemID: e.productname
            ? e.productname.selectedDates?.itemID
            : 0 || e.productname.itemID,
          ItemName: e.productname ? e.productname.label : e.productname.label,
          ItemType: e.productname ? e.productname.selectedDates?.itemType : "",
          HsnCode: e.productname.selectedDates?.saCorHSNCode
            ? e.productname.selectedDates?.saCorHSNCode
            : e.productname.selectedDates?.hsnCode,
          HsnName: e.productname
            ? e.productname.selectedDates?.itemType === "goods"
              ? "HSN"
              : "SAC"
            : "",
          Unit: e.productname ? e.productname.selectedDates?.unit : "",
          Quantity: e.quantity,
          Rate: e.productname.selectedDates?.siSellingPrice
            ? e.productname.selectedDates?.siSellingPrice
            : e.productname.selectedDates?.rate,
          DiscountType: "Percentage",
          DiscountRate: discountValue,
          Discount: e.discount,
          LocalTaxName: countryTaxDetails.localTaxName,
          LocalTaxRate: +e.statetax,
          LocalTaxAmount: sgstTaxAmount,
          InterTaxName: countryTaxDetails?.interTaxName,
          InterTaxRate: +e.intertax,
          InterTaxAmount: igstTaxAmount,
          CentralTaxName: countryTaxDetails?.centralTaxName,
          CentralTaxRate: +e.centraltax,
          CentralTaxAmount: cgstTaxAmount,
          IsOtherTax: false,
          TotalAmount: +e.netamount,
          Description: e.productname.selectedDates?.siDescription
            ? e.productname.selectedDates?.siDescription
            : e.productname.selectedDates?.description,
          Status: "ACTIVE",
          TenantID: GV.userDet.tenantDetail.tenantID,
          CreatedBy: GV.userDet.appUser.appuserID.toString(),
        })),
      };
      console.log(InputJson, "InputJson if");
      const formData = new FormData();
      formData.append("inputJson", JSON.stringify(InputJson));
      formData.append("docs", uploadedFiles);

      ps.SavePurchases(formData).then((res) => {
        if (res.data.statusCode === 200) {
          setIsLoading(false);
          setShowTotalErr(false);

          new Promise((resolve) => {
            toast.current.show({
              severity: "success",
              detail: res.data.message,
            });
            setTimeout(() => {
              resolve();
            }, 1000);
          }).then(() => {
            cancelForm();
            GetPurchaseCode();
            clearUploadedFile();
            setSelectedPurchase(null);
            goBack();
          });

          // GetPurchaseListSearch()
        }
      });
    } else {
      setIsLoading(true);
      const InputJson = {
        PurchaseID:
          selectedPurchase?.purchaseID > 0 ? selectedPurchase?.purchaseID : 0,
        PurchaseCode: purchaseNo,
        PurchaseOrderID: 0,
        PurchaseDate: `${data.calendar.getFullYear()}-${(
          data.calendar.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${data.calendar
          .getDate()
          .toString()
          .padStart(2, "0")}`,
        PONumber: "",
        GstTreatmentID: 0,
        GstTreatment: data.gsttreatment,
        CategoryID: selectedExpenseAccount?.categoryID,
        COACategory: selectedExpenseAccount?.coaCategoryName,
        COAMasID: selectedPaidThrough?.coaMasID,
        COAName: selectedPaidThrough?.coaName,
        InvoiceNo: data.invoicee,
        VendorID: selectVendorNameList?.contactID,
        VendorGSTIN: data.gstin,
        CompanyName: selectVendorNameList?.companyName,
        FirstName: selectVendorNameList?.firstName,
        LastName: selectVendorNameList?.lastName,
        Address1: selectVendorNameList?.baAddress1,
        Address2: selectVendorNameList?.baAddress2,
        City: selectVendorNameList?.baCity,
        State: selectVendorNameList?.baState,
        ZipCode: selectVendorNameList?.baZipCode,
        Country: selectVendorNameList?.baCountry,
        phone: selectVendorNameList?.phone,
        email: selectVendorNameList?.email,
        Fax: selectVendorNameList?.baFax,
        SubTotal: subTotal,
        DiscountType: "Percentage",
        DiscountRate: discountValue,
        DiscountAmount: discountedAmount,
        LocalTaxName: countryTaxDetails.localTaxName,
        LocalTaxRate: sgstTax,
        LocalTaxAmount: sgstTaxAmount,
        InterTaxName: countryTaxDetails?.interTaxName,
        InterTaxRate: igstTax,
        InterTaxAmount: igstTaxAmount,
        CentralTaxName: countryTaxDetails?.centralTaxName,
        CentralTaxRate: cgstTax,
        CentralTaxAmount: cgstTaxAmount,
        IsOtherTax: false,
        TotalAmount: netAmountValue,
        DocID: 0,
        PurchaseStatus: status,
        TenantID: GV.userDet.tenantDetail.tenantID,
        CreatedBy: GV.userDet.appUser.appuserID.toString(),
        IsExpenseChanged: true,
        PurchaseInstance: invoiceLines.map((e, j) => ({
          PurchaseInstID: 0,
          PurchaseID: 0,
          ItemID: e.productname ? e.productname?.selectedDates.itemID : 0,
          ItemName: e.productname ? e.productname.selectedDates.name : e.label,
          ItemType: e.productname ? e.productname?.selectedDates.itemType : "",
          HsnCode: e.productname
            ? e.productname.selectedDates.saCorHSNCode
            : "",
          HsnName: e.productname
            ? e.productname?.selectedDates.itemType === "goods"
              ? "HSN"
              : "SAC"
            : "",
          Unit: e.productname ? e.productname?.selectedDates.unit : "",
          Quantity: e.quantity,
          Rate: e.productname
            ? e.productname?.selectedDates.siSellingPrice
            : e.rate,
          DiscountType: "Percentage",
          DiscountRate: discountValue,
          Discount: e.discount,
          LocalTaxName: countryTaxDetails.localTaxName,
          LocalTaxRate: +e.statetax,
          LocalTaxAmount: sgstTaxAmount,
          InterTaxName: countryTaxDetails?.interTaxName,
          InterTaxRate: +e.intertax,
          InterTaxAmount: igstTaxAmount,
          CentralTaxName: countryTaxDetails?.centralTaxName,
          CentralTaxRate: +e.centraltax,
          CentralTaxAmount: cgstTaxAmount,
          IsOtherTax: false,
          TotalAmount: +e.netamount,
          Description: e.productname
            ? e.productname.selectedDates.siDescription
            : "",
          Status: "ACTIVE",
          TenantID: GV.userDet.tenantDetail.tenantID,
          CreatedBy: GV.userDet.appUser.appuserID.toString(),
        })),
      };
      console.log(InputJson, "InputJson else");
      const formData = new FormData();
      formData.append("inputJson", JSON.stringify(InputJson));
      formData.append("docs", uploadedFiles);

      ps.SavePurchases(formData).then((res) => {
        if (res.data.statusCode === 200) {
          setIsLoading(false);
          setShowTotalErr(false);

          new Promise((resolve) => {
            toast.current.show({
              severity: "success",
              detail: res.data.message,
            });
            setTimeout(() => {
              resolve();
            }, 1000);
          }).then(() => {
            cancelForm();
            GetPurchaseCode();
            clearUploadedFile();
            setSelectedPurchase(null);
            goBack();
          });

          // GetPurchaseListSearch()
        }
      });
    }
  };

  const GetSalesItemList = () => {
    setIsProcess(true);

    return new Promise((resolve, reject) => {
      is.GetSalesItemList(GV.userDet.tenantDetail.tenantID)
        .then((res) => {
          setIsProcess(false);
          if (res.data.statusCode === 200) {
            let antDropdn = res.data.data.map((item) => ({
              label: item.name,
              value: item.itemID,
              selectedDates: item,
            }));
            setProductDrop1(antDropdn);
            setProductDrop(res.data.data);
            resolve(res.data.data);
            if (selectedPurchase) {
              let structObj = selectedPurchase?.purchaseInstance.filter(
                (a) => !a.itemID
              );
              if (structObj && structObj.length > 0) {
                let newItems = structObj.map((item) => ({
                  label: item.itemName,
                  value: item.itemID,
                  selectedDates: item,
                }));
                // console.log(newItems,'newItems')
                setProductDrop1((items) => [...items, ...newItems]);
                antDropdn = [...antDropdn, ...newItems];
              }
              setProductDrop1(antDropdn);
              let getVals = selectedPurchase?.purchaseInstance.map((a) => ({
                label: a.itemName,
                value: a.itemID,
                selectedDates: a,
              }));
              console.log(getVals, "getVals");

              const productDetails = getVals.map((a, i) => ({
                id: i + 1,
                productname: antDropdn.find((x) => x.value === a.value) || "",
                quantity: a.selectedDates.quantity || "",
                rate: a.selectedDates.rate || "",
                discount: a.selectedDates.discount || 0,
                statetax: a.selectedDates.localTaxRate || 0,
                centraltax: a.selectedDates.centralTaxRate || 0,
                intertax: a.selectedDates.interTaxRate || 0,
                netamount: a.selectedDates.totalAmount?.toFixed(2) || "",
                producttype: a.selectedDates.itemType || "",
                salesItemID: a.selectedDates.salesItemID || 0,
              }));
              setInvoiceLines(productDetails);
              console.log(productDetails, "productDetails");
            }
          } else {
            reject(new Error("Failed to fetch sales item list"));
          }
        })
        .catch((error) => {
          setIsProcess(false);
          reject(error);
        });
    });
  };

  useEffect(() => {
    if (selectedPurchase !== null) {
      // GetPurchaseCode();
    } else {
    }
    GetPurchaseCode();
    GetMasCOACategories();
    GetContactFilteredList();
    setGstTreatmentDropDown(businessList);
    setHsnCodeDropDown(hslCodeDetails);
    GetSalesItemList();
    GetTenantDetails();
  }, []);

  useEffect(() => {}, [trigger]);

  ///////////// from purchaselist start

  useEffect(() => {
    if (selectedPurchase) {
      console.log(selectedPurchase, "vjj");
      // setPurchaseNo(selectedPurchase.purchaseCode);
      const purchaseDate = new Date(selectedPurchase.purchaseDate || new Date());
      setValue("calendar", purchaseDate);
      setSelectedDate(purchaseDate);

      setValue("gsttreatment", selectedPurchase.gstTreatment);
      setValue("expenseaccount", selectedPurchase.coaCategory);
      setSelectedExpenseAccount(selectedPurchase.coaCategory);
      setValue("paidthrough", selectedPurchase.coaName);
      setValue("enteramount", selectedPurchase.totalAmount);
      setValue("hsncode", selectedPurchase.hsnCode);
      setValue("tax", selectedPurchase.tax);
      setValue("gstin", selectedPurchase.vendorGSTIN);
      setValue("invoicee", selectedPurchase.invoiceNo);
      setSubTotal(selectedPurchase.subTotal);
      setSgstTax(selectedPurchase.localTaxRate);
      setCgstTax(selectedPurchase.centralTaxRate);
      setIgstTax(selectedPurchase.interTaxRate);
      setNetAmountValue(selectedPurchase.totalAmount);
      setDiscountedAmount(selectedPurchase.discountAmount?.toFixed(2));
      setSgstTaxAmount(selectedPurchase.localTaxAmount);
      setCgstTaxAmount(selectedPurchase.centralTaxAmount);
      setIgstTaxAmount(selectedPurchase.interTaxAmount);
    }
    setPurchaseInstance(selectedPurchase?.purchaseInstance);
  }, [selectedPurchase, setValue]);

  ///////////// from purchaselist end

  useEffect(() => {
    if (selectedPurchase && selectedPurchase?.purchaseInstance) {
      const updatedFormData = selectedPurchase?.purchaseInstance.map(
        (item, index) => ({
          id: index + 1,
          inputtextarea: item.description || "",
          hsnsac: item.hsnName || "",
          tax: item.tax || "",
          seperateamount: item.amount || "",
          taxamount: item.taxAmount || "",
          total: item.totalAmount || "",
        })
      );
      console.log(updatedFormData, "updatedFormData");
      setFormData(updatedFormData);
    }
  }, [selectedPurchase]);

  const updateFileName = (e) => {
    console.log(e.target.value, "input");
    let name = e.target.value;
    let findName = name.split("\\").pop();
    console.log(findName, "findName");
    document.getElementById("file-label").innerText = findName;
  };

  const addProduct = () => {
    const newFormFields = [...formDataa];
    const newId = formDataa[formDataa.length - 1].id + 1;
    newFormFields.push({
      id: newId,
      inputtextarea: "",
      hsnsac: "",
      tax: "",
      seperateamount: "",
      taxamount: "",
      total: "",
    });
    setFormData(newFormFields);
    setTaxAmount([...taxAmount]);
    console.log(newFormFields, "addproduct");
  };

  const handleInputChangeDes = (id, field, value) => {
    setIsEmpty(false);
    console.log(value, "des");
    setTotalTax(value);
    const updatedFormData = formDataa.map((data) => {
      if (data.id === id) {
        return { ...data, [field]: value };
      }
      return data;
    });
    setFormData(updatedFormData);
  };
  const handleInputChangeHsnSac = (id, field, value) => {
    console.log(value, "hsnsac");
    setTotalTax(value);
    const updatedFormData = formDataa.map((data) => {
      if (data.id === id) {
        return { ...data, [field]: value };
      }
      return data;
    });
    setFormData(updatedFormData);
  };
  const handleInputChangeTax = (id, field, value) => {
    setIsEmpty(false);
    setTotalTax(value);
    setErrorsTax(Number(value.trim()) === 0);
    const updatedFormData = formDataa.map((data) => {
      if (data.id === id) {
        return { ...data, [field]: value };
      }
      return data;
    });
    setFormData(updatedFormData);
  };

  const handleInputChangeAmount = (id, field, e) => {
    setIsEmpty(false);
    setIsEmpty(false);
    setTotalExpenses(e.target.value);
    const updatedFormData = formDataa.map((data) => {
      if (data.id === id) {
        return { ...data, [field]: e.target.value };
      }
      return data;
    });
    setFormData(updatedFormData);
  };

  const deleteRow = (id) => {
    const updatedFormData = formDataa.filter((data) => data.id !== id);
    setFormData(updatedFormData);
  };

  const GetTenantDetails = () => {
    setIsProcess(true);
    is.GetTenantDetails(GV.userDet.tenantDetail.tenantID).then((res) => {
      setIsProcess(false);
      setTenantDetails(res.data.data);
      GetCountryTaxName(res.data.data.country);
    });
  };

  const GetCountryTaxName = (country) => {
    is.GetCountryTaxName(country).then((res) => {
      if (res.data.statusCode === 200) {
        setCountryTaxDetails(res.data.data);
      }
    });
  };

  const handleSelectDropdown = (value, index) => {
    setManualErr("");
    let findVal = productDrop1.find((a) => a.value === value);
    console.log(findVal, index, "findval");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[index].productname = findVal;
    setInvoiceLines(newInvoiceLines);

    // setSelectedItemDropDown(findVal);
    let check = Object.keys(findVal).includes("selectedDates");
    // setIsEnable(check);
    if (check) {
      const updatedLines = invoiceLines.map((line, i) => {
        if (i === index) {
          return {
            id: line.id,
            productname: findVal,
            quantity: 1,
            rate: findVal.selectedDates.siSellingPrice?.toFixed(2) || 0,
            discount: 0,
            statetax: findVal.selectedDates.localTaxRate || 0,
            centraltax: findVal.selectedDates.centralTaxRate || 0,
            intertax: findVal.selectedDates.interTaxRate || 0,
            netamount: findVal.selectedDates.siSellingPrice || 0,
            isEnbl: true,
            //   producttype: selectedProduct.itemType,
          };
        }

        return line;
      });
      handleTaxChangeWithoutIndex(updatedLines, index);
      handleTaxChange(updatedLines);
      setInvoiceLines(updatedLines);
      // setIsType(true);
    } else {
      const updatedLines = invoiceLines.map((line, i) => {
        if (i === index) {
          return {
            id: line.id,
            productname: check ? findVal.selectedDates : "",
            quantity: 1,
            rate: check
              ? findVal.selectedDates.siSellingPrice?.toFixed(2) || 0
              : 0,
            discount: 0,
            statetax: check ? findVal.selectedDates.localTaxRate || 0 : 0,
            centraltax: check ? findVal.selectedDates.centralTaxRate || 0 : 0,
            intertax: check ? findVal.selectedDates.interTaxRate || 0 : 0,
            netamount: check ? findVal.selectedDates.siSellingPrice || 0 : 0,
            label: findVal.label,
            isEnbl: false,
            //   producttype: selectedProduct.itemType,
          };
        }

        return line;
      });
      handleTaxChangeWithoutIndex(updatedLines, index);
      handleTaxChange(updatedLines);
      setInvoiceLines(updatedLines);
      // setIsType(true);
    }

    // setName(event.target.value);
  };
  const generateUniqueId = () => {
    return Math.floor(1000 + Math.random() * 9000);
  };

  const onNameChange = (event) => {
    const value = event.target.value.trim();
    setIsAddItem(value === "");
    setName(event.target.value);
  };

  const addItem = (index) => {
    setProductDrop1([
      ...productDrop1,
      { label: name, value: generateUniqueId() },
    ]);
    setName("");
    setIsAddItem(true);
  };

  const handleInputChangeStateTax = (e, index) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[index].statetax = e.target.value;
    setInvoiceLines(newInvoiceLines);
    handleTaxChangeWithoutIndex(newInvoiceLines, index);
    handleTaxChange(newInvoiceLines);
  };

  const handleInputChangeCentralTax = (e, index) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[index].centraltax = e.target.value;
    setInvoiceLines(newInvoiceLines);
    handleTaxChangeWithoutIndex(newInvoiceLines, index);
    handleTaxChange(newInvoiceLines);
  };

  const handleInputChangeInterTax = (e, index) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[index].intertax = e.target.value;
    setInvoiceLines(newInvoiceLines);
    handleTaxChangeWithoutIndex(newInvoiceLines, index);
    handleTaxChange(newInvoiceLines);
  };

  const handleInputChangeRate = (e, i) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[i].rate = e.target.value;
    setInvoiceLines(newInvoiceLines);
    let addSubTotal = newInvoiceLines
      .map((a) => a.rate * a.quantity)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSubTotal(addSubTotal);
    handleTaxChangeWithoutIndex(newInvoiceLines, i);
    handleTaxChange(newInvoiceLines);
  };

  const addNewLine = () => {
    // setIsDiscountDisabled(true)
    console.log(invoiceLines, "add");
    const invalidInvoiceLines = invoiceLines.filter(
      (line) => line.quantity === "" || line.discount === ""
    );

    if (invalidInvoiceLines.length > 0) {
      setManualErr("Unable to duplicate empty fields");
      return;
    } else {
      setManualErr("");
      const newId = invoiceLines[invoiceLines.length - 1].id + 1;
      const newLine = {
        id: newId,
        productname: "",
        quantity: "",
        rate: "",
        discount: "",
        statetax: "",
        centraltax: "",
        intertax: "",
        netamount: "",
        producttype: "",
      };

      setInvoiceLines((prevInvoiceLines) => [...prevInvoiceLines, newLine]);
    }
  };

  useEffect(() => {
    if (formDataa && formDataa.length > 0) {
      let tax = formDataa.map((c) => {
        const amount = parseFloat(c.seperateamount);
        const tax = parseFloat(c.tax);
        if (!isNaN(amount) && !isNaN(tax)) {
          const taxAmount1 = (amount * tax) / 100;
          return taxAmount1;
        }
        return 0;
      });

      setTaxAmount(tax);
      let totalTax = tax.reduce((acc, total) => acc + total, 0);
      setDisplayTotalTax(totalTax);

      let ttl = formDataa.map((c) => {
        const amount = parseFloat(c.seperateamount);
        const tax = parseFloat(c.tax);
        if (!isNaN(amount) && !isNaN(tax)) {
          const taxAmount1 = (amount * tax) / 100;
          const taxAmount2 = amount + taxAmount1;
          return taxAmount2;
        }
        return 0;
      });

      setTotal(ttl);
      let total = ttl.reduce((acc, total) => acc + total, 0);
      setDisplayTotalExpenses(total);
    } else {
      setTaxAmount([]);
      setDisplayTotalTax(0);
      setTotal([]);
      setDisplayTotalExpenses(0);
    }
  }, [formDataa]);

  return (
    <div>
      <BlockUI blocked={isLoading}></BlockUI>

      <form>
        <div>
          <div
            className="d-flex justify-content-between cc-greyCard-custm align-items-center mb-3"
            style={{ backgroundColor: "white" }}
          >
                        <div className="ms-2 my-2 me-2">

            <div className="col-md mb-2">
              <h4 className="kk-bold m-0">New Expenses</h4>
            </div>
</div>

            <div>
              <Primebutton
                type="button"
                label="Back to Purchase list"
                className="btn btn-primary me-2"
                onClick={handleGoBackClick}
                style={{ color: "white", backgroundColor: "#F74343" }}
              />
            </div>
          </div>
        </div>
        <div className="bg-white shadow-sm chart-of-account h-100 p-3">
          <div className="row mx-auto mb-4">
            <div className="col-12 p-0">
              <div className="journal-of-account p-2 pt-0 ">
                <div className="row mx-auto">
                  <div className="col-md-12 ps-0">
                    <div className="row newexpense1 pt-2 pb-2 ms-1">
                      <div className="col-sm-4 ">
                          <label className="ps-0 ">Purchase No:</label>
                          <input
                            type="text"
                            defaultValue={purchaseNo}
                            readOnly
                            disabled={disabled}
                            name="purchasenumber"
                            className="form-control sm-2"
                          />
                      </div>

                      <div className="col-sm-4 ">
                          <label
                            htmlFor="buttondisplay "
                            className="p-0"
                          >
                            {" "}
                            Date{" "}
                          </label>
                          <Calendar
                            id="buttondisplay"
                            name="calendar"
                            value={selectedDate}
                            onChange={handleDate}
                            showIcon
                            className="form-control p-0"
                            panelClassName="currentDate"
                            placeholder="Select date"
                            maxDate={new Date()}
                            {...register("calendar", { required: false })}
                          />
                          {errors.calendar && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                      </div>
                      <div className="col-sm-4 ">
                          <label className="ps-0 required">
                            GST Treatment:
                          </label>
                          <Controller
                            name="gsttreatment"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Dropdown
                                {...field}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  handleGSTtreatment(e);
                                }}
                                options={gstTreatmentDropDown}
                                optionLabel="name"
                                placeholder="Select your business type"
                                className="form-control"
                              />
                            )}
                          />

                          {errors.gsttreatment && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                      </div>
                      <div className="col-sm-4 ">
                          <label className="ps-0 required">
                            Expense Account:
                          </label>
                          <Controller
                            name="expenseaccount"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Dropdown
                                {...field}
                                value={selectedExpenseAccount}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  expenseAccountOnChange(e.value);
                                }}
                                options={expenseType}
                                optionLabel="coaCategoryName"
                                placeholder="Select Expense"
                                className="form-control"
                              />
                            )}
                          />

                          {errors.expenseaccount && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                      </div>
                      <div className="col-sm-4 ">
                          <label className="ps-0 required">Paid Through:</label>
                          <Controller
                            name="paidthrough"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Dropdown
                                {...field}
                                value={selectedPaidThrough}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  paidThroughOnchange(e.value);
                                }}
                                options={paidThroughDropDown}
                                optionLabel="coaName"
                                placeholder="Select an account"
                                className="form-control"
                              />
                            )}
                          />
                          {errors.paidthrough && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                      </div>

                      <div className="col-sm-4">
                          <label
                            className={`ps-0 ${isGstin ? "required" : ""} `}
                          >
                            Vendor GSTIN
                          </label>{" "}
                          <div className=" px-0">
                            <Controller
                              name="gstin"
                              control={control}
                              rules={{
                                required: isGstin
                                  ? "GSTIN number is required"
                                  : false,
                              }}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control sm-2"
                                  name="gstin"
                                  {...field}
                                />
                              )}
                            />
                          </div>
                          {errors.gstin && (
                            <p className="text-danger">
                              {errors.gstin.message}
                            </p>
                          )}
                      </div>
                      <div className="col-sm-4">
                          <label className={`ps-0 required`}>Vendor Name</label>{" "}
                          <Controller
                            name="vendorname"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Dropdown
                                {...field}
                                value={selectVendorNameList}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  vendorNameOnchange(e.value);
                                }}
                                options={vendorNameList}
                                optionLabel="fullName"
                                placeholder="Select vendor"
                                className="form-control"
                              />
                            )}
                          />
                          {errors.vendorname && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                      </div>
                      <div className="col-sm-3">
                        <div className="row mx-auto">
                          <label
                            className={`ps-0 ${isGstin ? "required" : ""} `}
                          >
                            Invoice
                          </label>{" "}
                          <div className=" px-0">
                            <Controller
                              name="invoicee"
                              control={control}
                              rules={{ required: isInvoice }}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  name="invoicee"
                                  value={field.value}
                                  onChange={(e) =>
                                    field.onChange(e.target.value)
                                  }
                                />
                              )}
                            />
                          </div>
                          {errors.invoicee && (
                            <p className="text-danger">
                              {errors.invoicee.message}
                            </p>
                          )}
                        </div>
                      </div>
                      
                    </div>
                  </div>

                  <div className="col-sm-12 p-0 m-2 mt-0 gap-5">
                    <div className="row">
                      <div className="col-md">
                        <div className="table-responsive">
                          <table className="table mt-3">
                            <thead>
                              <tr className="heading">
                                <th
                                  style={{ width: "18%" }}
                                  className="borderIn"
                                >
                                  Product Name
                                </th>
                                <th
                                  className="borderIn"
                                  style={{ width: "5%" }}
                                >
                                  Quantity
                                </th>
                                <th
                                  className="borderIn"
                                  style={{ width: "6%" }}
                                >
                                  Rate ({GV.userDet.tenantConfig.currency})
                                </th>
                                <th
                                  className="borderIn"
                                  style={{ width: "6%" }}
                                >
                                  Discount (%)
                                </th>
                                <th colSpan="3">Tax (%)</th>
                                <th
                                  className="borderIn"
                                  style={{ width: "7%" }}
                                >
                                  Net Amount ({GV.userDet.tenantConfig.currency}
                                  )
                                </th>
                                <th style={{ width: "3%" }}></th>
                              </tr>
                              <tr className="sub-heading">
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th className="borderIn">
                                  {countryTaxDetails?.localTaxName}
                                </th>
                                <th className="borderIn">
                                  {countryTaxDetails?.centralTaxName}
                                </th>
                                <th className="borderIn">
                                  {countryTaxDetails?.interTaxName}
                                </th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              {invoiceLines.map((line, index) => (
                                <tr key={index}>
                                  <td>
                                    <Select
                                      className="cust-form-control p-0"
                                      name="productname"
                                      placeholder="Choose to select item or add item"
                                      value={
                                        line.productname
                                          ? line.productname.value
                                          : undefined
                                      }
                                      onChange={(value) =>
                                        handleSelectDropdown(value, index)
                                      }
                                      dropdownRender={(menu) => (
                                        <>
                                          {menu}
                                          <Divider
                                            style={{ margin: "8px 0" }}
                                          />
                                          <Space className="ant-drop-search">
                                            <Input
                                              placeholder="Please enter item"
                                              className="ant-drop-input"
                                              ref={inputRef}
                                              value={name}
                                              onChange={(e) => onNameChange(e)}
                                            />
                                            <Button
                                              className="btn btn-primary"
                                              disabled={isAddItem}
                                              style={{
                                                backgroundColor: "#1E6444",
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                              type="text"
                                              icon={<PlusOutlined />}
                                              onClick={() => addItem(index)}
                                            >
                                              Add item
                                            </Button>
                                          </Space>
                                        </>
                                      )}
                                      options={productDrop1.map((item) => ({
                                        label: item.label,
                                        value: item.value,
                                      }))}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      type="text"
                                      name="quantity"
                                      className="form-control"
                                      value={line.quantity}
                                      onInput={(e) => {
                                        handleInputChangeQuantity(e, index);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      name="rate"
                                      className="form-control text-end"
                                      value={line.rate}
                                      onInput={(e) => {
                                        handleInputChangeRate(e, index);
                                      }}
                                    />
                                    {/* <AutoComplete
                                          value={selectedItem}
                                          suggestions={filteredItems}
                                          completeMethod={searchItems}
                                          virtualScrollerOptions={{
                                            itemSize: 38,
                                          }}
                                          field="name"
                                          dropdown
                                          onChange={(e) => setSelectedItem(e.value)}
                                        /> */}
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      name="discount"
                                      className="form-control text-end"
                                      value={line.discount}
                                      onInput={(e) => {
                                        handleInputChangeDiscount(e, index);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      name="statetax"
                                      className="form-control text-end"
                                      disabled={line.isEnbl}
                                      // disabled={!line.discount || !line.quantity}
                                      value={line.statetax}
                                      onInput={(e) => {
                                        handleInputChangeStateTax(e, index);
                                      }}
                                      title="To adjust the tax percentage, please access the Inventory section"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      name="centraltax"
                                      className="form-control text-end"
                                      // disabled={!line.discount || !line.quantity}
                                      disabled={line.isEnbl}
                                      value={line.centraltax}
                                      onInput={(e) => {
                                        handleInputChangeCentralTax(e, index);
                                      }}
                                      title="To adjust the tax percentage, please access the Inventory section"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      name="intertax"
                                      className="form-control text-end"
                                      disabled={line.isEnbl}
                                      // disabled={!line.discount || !line.quantity}
                                      value={line.intertax}
                                      onInput={(e) => {
                                        handleInputChangeInterTax(e, index);
                                      }}
                                      title="To adjust the tax percentage, please access the Inventory section"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      name="netamount"
                                      className="form-control text-end"
                                      value={line.netamount}
                                      disabled
                                    />
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      padding: "1%",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        background: "transparent",
                                      }}
                                      // disabled={fieldEnabled}

                                      onClick={() =>
                                        deleteLineItem(line, index)
                                      }
                                    >
                                      <i
                                        className="pi pi-trash"
                                        aria-hidden="true"
                                        style={{ color: "red" }}
                                      ></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {manualErr && (
                            <p className="text-danger my-3">{manualErr}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {isProcess && (
                      <ProgressSpinner
                        style={{
                          width: "50px",
                          height: "50px",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        strokeWidth="2"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                      />
                    )}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row mx-auto mt-4">
                          <div className="col-md p-0">
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={addNewLine}
                              // disabled={fieldEnabled}
                            >
                              + Add New Items
                            </button>
                          </div>
                          <div className="col-md"></div>
                          <div className="col-md"></div>
                          <div
                            className="col-md p-3"
                            style={{
                              background: "rgb(226 243 252)",
                            }}
                          >
                            <div className="d-flex justify-content-between">
                              <p> Sub Total</p>
                              <p>
                                {" "}
                                <i
                                  className="pi pi-indian-rupee"
                                  aria-hidden="true"
                                ></i>{" "}
                                {GV.userDet.tenantConfig.currency}
                                {subTotal}
                              </p>
                            </div>

                            <div className="d-flex justify-content-between">
                              <p> Tax(SGST)</p>
                              <p>
                                {" "}
                                <i
                                  className="pi pi-indian-rupee"
                                  aria-hidden="true"
                                ></i>{" "}
                                {GV.userDet.tenantConfig.currency}
                                {sgstTaxAmount}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p> Tax(CGST)</p>
                              <p>
                                {" "}
                                <i
                                  className="pi pi-indian-rupee"
                                  aria-hidden="true"
                                ></i>{" "}
                                {GV.userDet.tenantConfig.currency}
                                {cgstTaxAmount}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p> Tax(IGST)</p>
                              <p>
                                {" "}
                                <i
                                  className="pi pi-indian-rupee"
                                  aria-hidden="true"
                                ></i>{" "}
                                {GV.userDet.tenantConfig.currency}
                                {igstTaxAmount}
                              </p>
                            </div>

                            <Primediveder className="m-0" />
                            <div className="d-flex justify-content-between">
                              <p> Discount </p>
                              {discountedAmount !== "0.00" ? (
                                <p className="text-danger">
                                  - {GV.userDet.tenantConfig.currency}{" "}
                                  {discountedAmount}
                                </p>
                              ) : (
                                <p className="">
                                  {" "}
                                  {GV.userDet.tenantConfig.currency}{" "}
                                  {discountedAmount}
                                </p>
                              )}
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>
                                <strong> Total</strong>
                              </p>
                              <p>
                                {" "}
                                <strong>
                                  <i
                                    className="pi pi-indian-rupee"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {GV.userDet.tenantConfig.currency}
                                  {netAmountValue?.toFixed(2)}
                                </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0">
                    <div className="row mx-auto">
                      {selectedPurchase !== null ? (
                        <h6 className="bg-success1 text-white p-2 ">
                          UPLOADED DOCUMENT
                        </h6>
                      ) : (
                        <h6 className="bg-success1 text-white p-2 ">UPLOAD</h6>
                      )}
                      <div className="p-0 mt-4">
                        <Toast ref={toast}></Toast>
                        {selectedPurchase !== null ? (
                          <div style={{ width: "50%", margin: "auto" }}>
                            {showDocument ? (
                              documentURL ? (
                                documentURL.endsWith(".pdf") ? (
                                  <embed
                                    src={documentURL}
                                    type="application/pdf"
                                    style={{
                                      width: "100%",
                                      objectFit: "contain",
                                    }}
                                  />
                                ) : documentURLType.endsWith(".png") ||
                                  documentURLType.endsWith(".jpg") ||
                                  documentURLType.endsWith(".jpeg") ? (
                                  <img
                                    src={documentURL}
                                    alt="Document"
                                    style={{
                                      width: "100%",
                                      objectFit: "contain",
                                    }}
                                  />
                                ) : (
                                  <iframe
                                    src={documentURL}
                                    style={{
                                      width: "100%",
                                      height: "400px",
                                      objectFit: "contain",
                                    }}
                                  />
                                )
                              ) : (
                                <div
                                  className="text-center pb-3"
                                  style={{ paddingLeft: "10rem" }}
                                >
                                  No document found
                                </div>
                              )
                            ) : null}
                          </div>
                        ) : null}
                        <FileUpload
                          emptyTemplate={
                            <div className="mt-3 important-color">
                              Max 10mb
                              <br />
                              Drop files here or click to upload. <br />
                              Supported file formats: PNG, JPG, JPEG, CSV, XLSX,
                              PDF
                            </div>
                          }
                          className="newexpense newexpcontent deleteUploadCancel"
                          style={{ backgroundColor: "transparent" }}
                          accept="*"
                          maxFileSize={10000000}
                          onSelect={onUploadFile}
                          // uploadedFile={documentURL}
                          chooseLabel={
                            selectedPurchase ? "Replace / Upload" : "Choose"
                          }
                        />
                        {/* <FileUpload
                            emptyTemplate={
                              <div className="mt-3 important-color">
                                Max 10mb
                                <br />
                                Drop files here or click to upload. <br />
                                Supported file formats: PNG, JPG, JPEG, CSV,
                                XLSX, PDF
                              </div>
                            }
                            className="newexpense newexpcontent deleteUploadCancel"
                            style={{ backgroundColor: "transparent" }}
                            // mode="basic"
                            // multiple
                            accept="*"
                            maxFileSize={10000000}
                            onSelect={onUploadFile}
                            uploadedFile={documentURL}
                          /> */}
                        {/* <div className="text-center">
                          <Button
                            type="button"
                            label="ReUload Document"
                            className="btn btn-primary"
                            icon="pi pi-check"
                            onClick={hideUpload()}
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-3"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  {selectedPurchase === null ? (
                    <Primebutton
                      type="button"
                      label="Cancel"
                      icon="pi pi-times"
                      className="btn btn-primary me-2"
                      onClick={cancelForm}
                      style={{ color: "white", backgroundColor: "#F74343" }}
                    />
                  ) : (
                    <Primebutton
                      type="button"
                      label="Back to Purchase list"
                      className="btn btn-primary me-2"
                      onClick={handleGoBackClick}
                      style={{ color: "white", backgroundColor: "#F74343" }}
                    />
                  )}
                  <Primebutton
                    type="button"
                    label="Save Draft"
                    className="btn btn-primary me-2"
                    onClick={handleSubmit((e) => handleSaveBtn(e, "DRAFT"))}
                  />

                  {selectedPurchase === null ? (
                    <Primebutton
                      type="button"
                      label="Save"
                      icon="pi pi-check"
                      onClick={handleSubmit((e) => handleSaveBtn(e, "OPEN"))}
                      className="btn btn-primary"
                    />
                  ) : (
                    <Primebutton
                      type="button"
                      label="Update"
                      icon="pi pi-check"
                      onClick={handleSubmit((e) => handleSaveBtn(e, "OPEN"))}
                      className="btn btn-primary"
                    />
                  )}
                </div>
                {showTotalErr && (
                  <p className="text-danger text-end">
                    {" "}
                    Total amount and total expense mismatch{" "}
                  </p>
                )}
              </div>
            </div>

            {/* <div className="col-3">
              <div
                className="card tabb container-ht"
                style={{ height: "calc(100vh - (52.8 + 67 + 16)px)", overflow:'hidden' }}
              >
                <TabView>
                  <TabPanel
                    header="Last Transaction"
                    className="col-sm-6 p-0 "
                    style={{ backgroundColor: "#F7F7F7" }}
                  >
                    {purchaseExpList[0]?.map((item, index) => (
                      <div className="card mt-2" key={index}>
                        <Card className="p-0">
                          <strong>{item.companyName}</strong>
                          <p className="m-0" style={{fontSize:"smaller"}} >{item.gstTreatment}</p>
                          <p className="m-0" style={{fontSize:"smaller"}}>
                            {new Date(item.purchaseDate).toLocaleDateString()}
                          </p>
                          <p className="m-0" style={{fontSize:"smaller"}}>{item.purchaseCode}</p>
                          <p className="m-0" style={{fontSize:"smaller"}}>
                            {GV.userDet.tenantConfig.currency}
                            {item.totalAmount}
                          </p>
                        </Card>
                      </div>
                    ))}
                    <p
                      className="m-0 p-0 "
                    ></p>
                  </TabPanel>
                  <TabPanel
                    header="GST"
                    className="col-sm-6 p-0"
                    style={{ backgroundColor: "#F7F7F7" }}
                  >
                    <p
                      className="m-0 p-0"
                    ></p>
                  </TabPanel>
                </TabView>
              </div>
            </div> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewPurchase;
