import config from '../assets/config';
import { ax } from './base';

export default class OrganizationService {
    SaveTenant(payload){
      return ax.post(`${config.apiUrl}Tenant/SaveTenant`,payload)
    }

    GetTenant(TenantID){
        return ax.get(`${config.apiUrl}Tenant/GetTenant/${TenantID}`);
    }      
    GetIndustry(){
      return ax.get(`${config.apiUrl}Common/GetIndustry`);
  } 

  GetCountry(){
      return ax.get(`${config.apiUrl}Common/GetCountry`);
  } 
  GetState(CountryID){
    return ax.get(`${config.apiUrl}Common/GetState/${CountryID}`);
  }
  GetTenantConfig(tenantId){
    return ax.get(`${config.apiUrl}Tenant/GetTenantConfig/${tenantId}`);
  }
  SaveTenantConfig(payload){
    return ax.post(`${config.apiUrl}Tenant/SaveTenantConfig`,payload)
  }
  GetTenantDetails(tenantId){
    return ax.get(`${config.apiUrl}Tenant/GetTenantDetails/${tenantId}`);
  }
  SaveTenantDetails(payload){
    return ax.post(`${config.apiUrl}Tenant/SaveTenantDetails`,payload)
  }
  GetMasTimeZone(){
    return ax.get(`${config.apiUrl}common/GetMasTimeZone`);

  }
  GetMasCurrency(){
    return ax.get(`${config.apiUrl}Currency/GetMasCurrency`);

  }
  SaveTenantLogo(payload){
    return ax.post(`${config.apiUrl}Tenant/SaveTenantLogo`,payload)
  }
  
}