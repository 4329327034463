import React, { useState, useEffect, useRef } from "react";
import {
  TabView,
  TabPanel,
  DataTable,
  Column,
  TreeTable,
} from "../../../PrimeReact";
import { Dialog } from "primereact/dialog";
import {
  IcomoonReact,
  iconSet,
  Sidebar,
  Button,
  Toast,
} from "../../../PrimeReact";
import { Dropdown } from "primereact/dropdown";
import { useForm, Controller } from "react-hook-form";
import SecurityService from "../../../../services/security-service";
import { PlusOutlined } from "@ant-design/icons";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import GlobalVar from "../../../../services/globalVar";

export default function UserDetail(props) {
  const datas = props.data.rowData;
  const [activeIndex, setActiveIndex] = useState(0);
  const [addMemberDialog, setAddMemberDialog] = useState(false);
  const [appUserServiceList, setAppUserServiceList] = useState([]);
  const [serviceExceptionList, setServiceExceptionList] = useState([]);
  const [getRoles, setGetRoles] = useState([]);
  const [userGroup, setUserGroup] = useState([]);
  const [userService, setUserService] = useState([]);
  const [userServiceSide, setUserServiceSide] = useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const [sidebarDatas, setSidebarDatas] = useState([]);
  const [viweuserData, setViweuserData] = useState([]);
  const [selectedNodeKeysSide, setSelectedNodeKeysSide] = useState(null);

  const toast = useRef(null);

  const ss = new SecurityService();

  const defaultValues = {
    allowonly: "",
    equalto: "",
  };
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues });

  const backFunc = () => {
    props.data.handleBackUser(false);
  };

  const handleOpenMemb = () => {
    // setMemberDrawer(true);
    setAddMemberDialog(true);
  };

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Add Data Rule</span>
    </div>
  );

  const handleCloseFeature = () => {
    // setFeatureDrawr(false);
    setAddMemberDialog(false);
    reset();
  };

  const onInvalid = (errors) => console.error(errors);

  const onSubmit = (data) => {
    console.log(data, "dt");
    // Handle form submission here
  };

  const GetAppUserServiceList = (appuserId) => {
    setIsProcess(true);
    ss.GetAppUserServiceList(appuserId)
      .then((res) => {
        setIsProcess(false);
        console.log(res.data.data, "appuserlist");
        const userService = res.data.data.map((a) => ({
          serviceName: a.serviceName,
          Read: a.read_Attri ? (
            <i
              className="pi pi-check text-success"
              style={{ fontWeight: "900" }}
            ></i>
          ) : (
            <i
              className="pi pi-times text-danger"
              style={{ fontWeight: "900" }}
            ></i>
          ),
          Write: a.write_Attri ? (
            <i
              className="pi pi-check text-success"
              style={{ fontWeight: "900" }}
            ></i>
          ) : (
            <i
              className="pi pi-times text-danger"
              style={{ fontWeight: "900" }}
            ></i>
          ),
          Delete: a.delete_Attri ? (
            <i
              className="pi pi-check text-success"
              style={{ fontWeight: "900" }}
            ></i>
          ) : (
            <i
              className="pi pi-times text-danger"
              style={{ fontWeight: "900" }}
            ></i>
          ),
          Approve: a.approve_Attri ? (
            <i
              className="pi pi-check text-success"
              style={{ fontWeight: "900" }}
            ></i>
          ) : (
            <i
              className="pi pi-times text-danger"
              style={{ fontWeight: "900" }}
            ></i>
          ),
          Export: a.export_Attri ? (
            <i
              className="pi pi-check text-success"
              style={{ fontWeight: "900" }}
            ></i>
          ) : (
            <i
              className="pi pi-times text-danger"
              style={{ fontWeight: "900" }}
            ></i>
          ),
          dataCondition: (
            <button onClick={() => confirmDelete(a)}>
              <span
                style={{ border: "1px solid black", background: "#555555" }}
              >
                {" "}
                <i
                  className="pi pi-times"
                  style={{
                    rotate: "226deg",
                    fontSize: "90%",
                    margin: "2px",
                    color: "white",
                    fontWeight: "900",
                  }}
                ></i>
              </span>
            </button>
          ),
        }));
        setAppUserServiceList(userService);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const AddUserServiceException = (a) => {
    console.log(a, "aa");
    const data = {
      AppUserID: datas.appUserId,
      ServiceId: a.entServiceId,
    };
    console.log(data, "data");
    ss.AddUserServiceException(data)
      .then((res) => {
        toast.current.show({
          severity: "success",
          summary: res.data.message,
        });
        console.log(res.data, "AddUserServiceException");
        GetAppUserServiceList(datas.appUserId);
        GetUserServiceException(datas.appUserId);
      })
      .catch((err) => console.log(err, "err"));
  };

  const GetUserServiceException = (appuserId) => {
    setIsProcess(true);
    ss.GetUserServiceException(appuserId).then((res) => {
      setIsProcess(false);
      console.log(res.data.data, "exception");
      const excep = res.data.data.map((a) => ({
        serviceName: a.serviceName,
        RestoreServiceException: (
          <button style={{ background: "transparent" }}>
            <i
              className="pi pi-minus-circle"
              onClick={() => DeleteUserServiceException(a)}
              style={{ color: "red", fontWeight: "900" }}
            ></i>
          </button>
        ),
      }));
      setServiceExceptionList(excep);
    });
  };

  const reject = () => {};

  const confirmDelete = (entId) => {
    console.log(entId, "rowData");
    let obj = {
      // target: event.currentTarget,
      className: "pAdj addservice",
      message: "Are you sure you want to add this service?",
      header: "Add Service",
      icon: "pi pi-info-circle",
      defaultfocus: "accept",
      accept: () => AddUserServiceException(entId),
      reject: () => reject(),
    };
    confirmDialog(obj);
  };

  const DeleteUserServiceExceptionConfirm = (entExpId) => {
    console.log(entExpId, "entIdExcep");
    let obj = {
      // target: event.currentTarget,
      className: "pAdj addservice",
      message: "Are you sure you want to add this service?",
      header: "Add Service",
      icon: "pi pi-info-circle",
      defaultfocus: "accept",
      accept: () => DeleteUserServiceException(entExpId),
      reject: () => reject(),
    };
    confirmDialog(obj);
  };

  const DeleteUserServiceException = (entExpId) => {
    console.log(entExpId, "entIdExcep");
    ss.DeleteUserServiceException(
      entExpId.entServiceExpID,
      GlobalVar.userDet.appUser.productID
    )
      .then((res) => {
        console.log(res.data, "t20");
        toast.current.show({
          severity: "success",
          summary: res.data.message,
        });
        GetUserServiceException(datas.appUserId);
        GetAppUserServiceList(datas.appUserId);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const GetRoleByUsers = (datas) => {
    const data = {
      AppUserID: datas.appUserId,
      TenantID: datas.tenantId,
      EntityID: datas.entityID,
      EntityType: datas.entityType,
    };
    ss.GetRoleByUsers(data).then((res) => {
      setGetRoles(res.data.data);
    });
  };

  const GetEntUserGroupByMembers = (datas) => {
    const data = {
      AppUserID: datas.appUserId,
      TenantID: datas.tenantId,
      EntityID: datas.entityID,
      EntityType: datas.entityType,
      RoleID: datas.roleId,
    };
    ss.GetEntUserGroupByMembers(data).then((res) => {
      setUserGroup(res.data.data);
    });
  };

  const viewUserGroup = (item) => {
    setViweuserData(item);
    GetUserGroupServices(item);
    setUserServiceSide(true);
  };

  const GetUserGroupServices = (data) => {
    setIsProcess(true);
    const datas = {
      UserGroupID: data.userGroupID,
      IsAvailable: true,
    };
    ss.GetUserGroupServices(datas).then((res) => {
      setIsProcess(false);
      console.log(res.data.data, "service");
      setUserService(res.data.data);
      function recursiveCollect(urlArray, collectionArray) {
        urlArray.forEach((item) => {
          if (item.isAssociate) {
            collectionArray.push(item.entServiceId);
          }
          if (Array.isArray(item.subMenu)) {
            recursiveCollect(item.subMenu, collectionArray);
          }
        });
      }
      const urlCollectionArray = [];
      recursiveCollect(res.data?.data, urlCollectionArray);
      console.log(urlCollectionArray, "urlCollectionArray");

      const filteredData = urlCollectionArray.reduce((acc, b) => {
        acc[b] = {
          checked: true,
          partialChecked: false,
        };
        return acc;
      }, {});
      const treeTableData = res.data?.data?.map((a, i) => ({
        key: a.entServiceId,
        index: i,
        data: {
          serviceName: a.serviceName,
        },
        children: a.subMenu.map((b, j) => ({
          key: b.entServiceId,
          index: j,
          data: {
            serviceName: b.serviceName,
          },
        })),
      }));
      setSidebarDatas(treeTableData);
      setSelectedNodeKeysSide(filteredData);
    });
  };
  const renderHeader1 = () => {
    return (
      <div className="custom-sidebar-header d-flex py-2">
        <p style={{ fontSize: "1.1rem" }}>
          Features / Services to associated to this user group
        </p>
        <button
          onClick={() => setUserServiceSide(false)}
          className="p-sidebar-close p-link ms-auto"
        >
          <span className="pi pi-times" />
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (datas) {
      console.log(datas, "dt");
      GetAppUserServiceList(datas.appUserId);
      GetUserServiceException(datas.appUserId);
      GetRoleByUsers(datas);
      GetEntUserGroupByMembers(datas);
    }
  }, []);

  return (
    <BlockUI blocked={isProcess}>
      <Toast ref={toast} />

      <div className="user-detail">
        <div className="row">
          <h5 className="col my-auto">User Detail</h5>
          <button
            className="bg-danger text-white col-auto ms-auto p-2 rounded"
            onClick={backFunc}
          >
            Back to User List
          </button>
        </div>
        <hr className="m-0 mt-2"></hr>
        <div className="bg-green d-flex p-3 my-2">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md">
                <span className="fw-bold">User Name:</span>{" "}
                <span>{datas.fullName}</span>
              </div>
              <div className="col-md">
                <span className="fw-bold">Login ID:</span>{" "}
                <span>{datas.appUserName}</span>
              </div>
              <div className="col-md">
                <span className="fw-bold">User Type:</span>{" "}
                <span>{datas.userType}</span>
              </div>
              <div className="col-md">
                <span className="fw-bold">Primary Role:</span>{" "}
                <span>{datas.roleName}</span>
              </div>
              <div className="col-md">
                <span className="fw-bold">Other Roles:</span>{" "}
                <span>{datas.secondaryRoleName}</span>
              </div>
            </div>
          </div>
        </div>
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Associated User Group" className="p-0">
            <div className="row d-flex">
              {userGroup.length === 0 ? (
                <div className="col-md-3">
                  <p
                    className="d-flex justify-content-between"
                    style={{ padding: "1rem", background: "#ededed" }}
                  >
                    No data
                  </p>
                </div>
              ) : (
                userGroup.map((a, i) => (
                  <div className="col-md-3" key={i}>
                    <p
                      className="d-flex justify-content-between"
                      style={{ padding: "1rem", background: "#ededed" }}
                    >
                      {a.userGroup}
                      <button>
                        <i
                          className="pi pi-database"
                          style={{ fontSize: "1.1rem", color: "#027d84" }}
                          onClick={() => viewUserGroup(a)}
                        ></i>
                      </button>
                    </p>
                  </div>
                ))
              )}
            </div>
          </TabPanel>
          <TabPanel header="Entitled Function/Services">
            <DataTable
              value={appUserServiceList}
              className=" chofacc"
              // responsiveLayout="scroll"
              size="small"
              emptyMessage="No services available"
            >
              <Column field="serviceName" header="Service Name"></Column>
              <Column field="Read" header="Read"></Column>
              <Column field="Write" header="Write"></Column>
              <Column field="Delete" header="Delete"></Column>
              <Column field="Approve" header="Approve"></Column>
              <Column field="Export" header="Export"></Column>
              <Column field="dataCondition" header="Add to Exception"></Column>
            </DataTable>
            <ConfirmDialog />
            {isProcess && (
              <ProgressSpinner
                style={{
                  width: "50px",
                  height: "50px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                strokeWidth="2"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            )}
          </TabPanel>
          <TabPanel header="Exceptions">
            <DataTable
              value={serviceExceptionList}
              className=" chofacc"
              // responsiveLayout="scroll"
              size="small"
              emptyMessage="No service exceptions available"
            >
              <Column field="serviceName" header="Service Name"></Column>
              <Column
                field="RestoreServiceException"
                header="Restore Service Exception"
                className="col-md-2"
              ></Column>
            </DataTable>
          </TabPanel>
          {/* <TabPanel header="Data Entitlement">
          <div
            className="d-flex w-75 align-items-center"
            style={{
              border: "1px solid black",
              padding: "0.9rem",
              background: "#fafff5",
            }}
          >
            <h3 className="m-0 me-2">!</h3>
            <p className="m-0">No data rule associated</p>
            <button
              className="entitle-Btn btn-btn primary"
              style={{ marginLeft: "auto" }}
              onClick={handleOpenMemb}
            >
              + Add Data Rule
            </button>
          </div>
        </TabPanel> */}
          <TabPanel header="Associated User Role">
            <div className="row d-flex">
              {getRoles.map((a, i) => {
                return (
                  <div className="col-md-3" key={i}>
                    <p style={{ padding: "1rem", background: "#ededed" }}>
                      {a.roleName}
                    </p>
                  </div>
                );
              })}
            </div>
          </TabPanel>
        </TabView>
        {isProcess && (
                    <ProgressSpinner
                      style={{
                        width: "50px",
                        height: "50px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  )}
        <Dialog
          visible={addMemberDialog}
          modal
          header={headerElement}
          style={{ width: "25%" }}
          onHide={() => setAddMemberDialog(false)}
        >
          <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <div
              className="card flex justify-content-center entitle-model"
              style={{ marginTop: "2rem", border: "none" }}
            >
              <label className="required" htmlFor="">
                Allow Only
              </label>
              <div className="w-100 mb-3">
                <Controller
                  name="allowonly"
                  control={control}
                  rules={{ required: "This field is required" }}
                  //   register
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      {...register("allowonly", { required: true })}
                      optionLabel="name"
                      placeholder="Choose a Member"
                      // filter
                      style={{ width: "100%" }}
                    />
                  )}
                />
                {errors.allowonly && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div>
                <label htmlFor="" className="required">
                  {" "}
                  Equal to
                </label>
                <div className="w-100">
                  <Controller
                    name="equalto"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        {...register("equalto", { required: true })}
                        optionLabel="name"
                        placeholder="Choose a Member"
                        // filter
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                  {errors.equalto && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
            </div>
            <div className="text-end mt-3">
              <Button
                type="button"
                label="Cancel"
                icon="pi pi-times"
                onClick={handleCloseFeature}
                className="p-button-text btn-cancel ms-auto btn btn-danger me-1 pe-2"
              />
              <Button
                type="submit"
                label="save"
                icon="pi pi-check"
                autoFocus
                className="btn btn-primary"
              />
            </div>
          </form>
        </Dialog>
        <Sidebar
          visible={userServiceSide}
          position="right"
          className="security-drawer"
          onHide={() => setUserServiceSide(false)}
        >
          <div slot="header">{renderHeader1()}</div>
          <div className="row">
            <div
              className="col"
              style={{ padding: "1rem", background: "#ededed" }}
            >
              {" "}
              {viweuserData.userGroup}{" "}
            </div>
          </div>
      <BlockUI blocked={isProcess}>
      <div className="my-3">
            <TreeTable
              className="border-0 service-tbl mb-3"
              value={sidebarDatas}
              resizableColumns
              // selectionMode="checkbox"
              // expandedKeys={expandedKeys3}
              // onToggle={(e) => setExpandedKeys3(e.value)}
              selectionKeys={selectedNodeKeysSide}
              onSelectionChange={(e) => {
                // handleCheckIsCheckBox(e);
              }}
              tableStyle={{ width: "100%", height: "100%" }}
            >
              <Column
                field="serviceName"
                expander
                className="service-tbl-name userrole sidetree-header"
              ></Column>
            </TreeTable>
            {isProcess && (
                    <ProgressSpinner
                      style={{
                        width: "50px",
                        height: "50px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  )}
          </div>
          <div className="row">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-danger me-2"
                onClick={(e) => setUserServiceSide(false)}
              >
                Cancel
              </button>
            </div>
          </div>
</BlockUI>
          
        </Sidebar>
      </div>
    </BlockUI>
  );
}
