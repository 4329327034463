// test

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Column,
  ConfirmPopup,
  DataTable,
  Dropdown,
  IcomoonReact,
  iconSet,
  Toast,
  Tooltip,
} from "../PrimeReact";
import { format } from "date-fns";
import BudgetService from "../../services/budget-service";
import GV from "../../services/globalVar";
import { Controller, useForm } from "react-hook-form";
import { confirmPopup } from "primereact/confirmpopup";
import { Calendar } from "primereact/calendar";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export default function FundRequest() {
  const bts = new BudgetService();
  const [selectedMonth, setSelectedMonth] = useState(+format(new Date(), "MM"));
  const [selectYear, setSelectYear] = useState(new Date().getFullYear());
  const [itemData, setItemData] = useState([]);
  const [months, getMonths] = useState([
    { label: "January", value: 1, key: 1 },
    { label: "Feburary", value: 2, key: 2 },
    { label: "March", value: 3, key: 3 },
    { label: "April", value: 4, key: 4 },
    { label: "May", value: 5, key: 5 },
    { label: "June", value: 6, key: 6 },
    { label: "July", value: 7, key: 7 },
    { label: "August", value: 8, key: 8 },
    { label: "September", value: 9, key: 9 },
    { label: "October", value: 10, key: 10 },
    { label: "November", value: 11, key: 11 },
    { label: "December", value: 12, key: 12 },
  ]);
  const [fundRequestList, setFundRequestList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const toast = useRef(null);
  const [visible, setvisible] = useState();
  const [editmode, seteditmode] = useState("");
  const [dropDownYear, setDropDownYear] = useState(new Date());
  const [dropDownYear1, setDropDownYear1] = useState(new Date());
  const [isCurrentMonth, setisCurrentMonth] = useState(false);
  const [subCategoryList, setsubCategoryList] = useState();
  const [foreCastAmount, setForeCastAmount] = useState();
  const [balance, setBalance] = useState();
  const [bindDropdwn, setBindDropdwn] = useState([]);
  const [visible1, setVisible1] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [isProcess, setIsProcess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [categoryValue, setCategoryValue] = useState(null);
const [subcategoryValue, setSubcategoryValue] = useState(null);
  //form
  const defaultValues = {
    category: "",
    subcategory: "",
    drpdwnYear1: "",
    expenses: "",
    description: "",
    // balanceAmnt:""
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
    trigger 
  } = useForm({ mode: "all", defaultValues });

 

  const resetAsyncForm = async (event) => {
    event?.preventDefault();
    reset();
    setForeCastAmount("");
    setBalance("");
    setDropDownYear1(new Date());
    setCategoryList(null);
    setcategorySelected(null);
    seteditmode("")
    setCategoryList(null);
    setcategorySelected(null);
 };

//   const resetForm = () => {
//     resetAsyncForm();
//     // Clear dropdown values
//     setCategoryList(null);
//     setcategorySelected(null);
// };

  const onSubmit = (data) => {
    console.log(data, "poood");
    console.log(dropDownYear1, "dropDownYear1");

    const val = {
      FundID: editmode === "" ? 0 : editmode.fundID,
      BudgetCatID: categorySelected[0].budgetCatID,
      BudgetCategory: data.category.budgetCategory,
      BudgetSubCatID: data.subcategory.budgetSubCatID,
      BudgetSubCategory: data.subcategory.budgetSubCategory,
      RequestingAmount: data.expenses,
      RequestDate: format(new Date(), "yyyy-MM-dd"),
      FundReqYear: new Date(dropDownYear1).getFullYear(),
      FundReqMonth: new Date(dropDownYear1).getMonth() + 1,
      Description: data.description,
      Status: editmode === "" ? "" : data.status,
      TenantID: GV.userDet.tenantDetail.tenantID,
      CreatedBy: GV.userDet.appUser.appuserID.toString(),
    };
    console.log(val);
    bts.SaveFundRequest(val).then((res) => {
      if (res.data.message === "Saved Successfully") {
        toast.current.show({ severity: "success", summary: res.data.message });
        // resetAsyncForm()
      }
      if (res.data.message === "Updated Successfully") {
        toast.current.show({ severity: "success", summary: res.data.message });
        // resetAsyncForm()
      }

      console.log(res, "fb");
      onPageLoad();
      resetAsyncForm();
    });
  };
  //form
  let currentMonthForeCasting = "";
  const onPageLoad = () => {
    setIsProcess(true);
    bts
      .GetFundRequests(
        GV.userDet.tenantDetail.tenantID,
        dropDownYear.getFullYear(),
        dropDownYear.getMonth() + 1
      )
      .then((res) => {
        setIsProcess(false);
        setIsLoading(false);
        const value = res.data.data.budgetCategories;
        value.forEach((x) => {
          if (x.fundRequest.length !== 0) {
            let forecast = 0;
            let reqAmt = 0;
            let difference = 0;
            x.fundRequest.forEach((y, i) => {
              // forecast = forecast + y.forecastingAmount;
              forecast = '';
              reqAmt = reqAmt + y.requestingAmount;
              difference = difference + y.difference;
            });
            const val = {
              fundID: null,
              budgetCatID: x.budgetCatID,
              budgetCategory: x.budgetCategory,
              budgetSubCatID: 0,
              budgetSubCategory: "Total",
              forecastingAmount: forecast,
              availableBalance: 0.0,
              requestingAmount: reqAmt,
              difference: difference,
              requestDate: "",
              description: "",
              status: "",
              tenantID: "",
              createdOn: "",
              createdBy: "",
              modifiedOn: "",
              modifiedBy: null,
            };
            x.fundRequest.push(val);
          }
        });
        currentMonthForeCasting = value;
        setFundRequestList(value);
        console.log(value, "setFundRequestList");
      });
    const valuesIn = [];
    setIsProcess(true);
    bts
      .GetBudgetCategories(
        GV.userDet.tenantDetail.tenantID,
        dropDownYear1.getFullYear(),
        dropDownYear1.getMonth() + 1
      )
      .then((response) => {
        setIsProcess(false);
        setIsLoading(false);
        if (response.data.statusCode === 200) {
          console.log(response, "resp");
          setCategoryList(response.data.data);
          let subcategoryList = response.data.data.map((e) => e.subCategory);
          console.log(subcategoryList, "subcategoryList");
        }
      })
      .catch(function (error) {});
  };
  const [categorySelected, setcategorySelected] = useState();

  const clearErrorMessage = () => {
    console.log("rest");
    setErrorMessage("");
  };
  const changeType = (e) => {
    console.log(e, "e");
    setValue("category", e);
    trigger ("category")

    clearErrorMessage();
    setBindDropdwn(e);
    setCategoryValue(e)
    const vals = e.subCategory;
    console.log(vals, "vals");
    setcategorySelected(vals);
    // changeSubCat(e.BudgetCatID)
  };

  const changeSubCat = (e) => {
    console.log(e, "chngesub");
    setValue("subcategory", e);
    trigger ("subcategory")
    setSubcategoryValue(e)
    const data = {
      TenantID: GV.userDet.tenantDetail.tenantID,
      BudgetCatID: categorySelected[0]?.budgetCatID,
      BudgetSubCatID: e?.budgetSubCatID,
      FinancialYear: dropDownYear1.getFullYear(),
      FinancialMonth: dropDownYear1.getMonth() + 1,
    };

    bts.GetBudgetByCategory(data).then((res) => {
      setBalance(res.data?.data?.balanceAmount);
      setForeCastAmount(res.data?.data?.forecastingAmount);
    });
   
  };
  const [editDis, seteditDis] = useState(false);

  //   const editData = (data) => {
  //     console.log(data, 'data budgetCategory');
  //     seteditmode(data);
  //     setValue("category", data.budgetCategory);
  //     if (categoryList && categoryList.length > 0) {
  //         const vals = categoryList.filter(
  //             (x) => x.budgetCategory === data.budgetCategory
  //         );
  //         console.log(vals, "vals");
  //         setCategoryList(vals);
  //         if (vals !== undefined) {
  //             setsubCategoryList(vals.subCategory);
  //             const valsone = vals.subCategory.filter(
  //                 (x) => x.budgetSubCategory === data.budgetSubCategory
  //             );
  //             setValue("subcategory", valsone);
  //             setcategorySelected(valsone);
  //         } else {
  //             setsubCategoryList([]);
  //         }
  //     } else {
  //         setsubCategoryList([]);
  //     }
  //     setValue("drpdwnYear1", `${data.fundReqMonth}/${data.fundReqYear}`);
  //     setValue("expenses", data.requestingAmount);
  //     setValue("description", data.description);
  //     seteditDis(true);
  // };

  const editData = (data) => {
    setcategorySelected(data);
    seteditmode(data);
    setBalance(data.availableBalance);

    // changeSubCat(data)
    const vals = categoryList.find(
      (x) => x.budgetCategory === data.budgetCategory
    );
    setValue("category", vals);
    setCategoryList([vals]);
    // setCategoryValue([vals])
    vals !== undefined
      ? setsubCategoryList(vals.subCategory)
      : setsubCategoryList([]);
    const valsone = vals.subCategory?.find(
      (x) => x.budgetSubCategory === data.budgetSubCategory
    );
    setValue("subcategory", valsone);
    setcategorySelected([valsone]);
    const formattedDate = new Date(
      `${data.fundReqMonth}/01/${data.fundReqYear}`
    );
    setDropDownYear1(formattedDate);
    setValue("expenses", data.requestingAmount);
    setValue("description", data.description);
    setValue("forecstingamt", data.forecastingAmount);
    seteditDis(false);
  };

  const paymentDone = (event, data) => {
    setvisible(data);
    console.log(data, "lrt");
    console.log(visible,'visible');
    let obj = {
      target: event.currentTarget,
      header: "Confirmation",
      message: "Are you sure to proceed",
      icon: "pi pi-question-circle qcercile",
      // acceptClassName: 'p-button-danger',
      accept: () => accept(data),
      reject,
    }
    confirmDialog(obj);
  };
  const reject = () => {
    console.log("no");
  };

  const accept = (data) => {
    // const data = visible;
    console.log(data, "accept");
    const val = {
      FundID: data.fundID,
      BudgetCatID: data.budgetCatID,
      BudgetCategory: data.budgetCategory,
      BudgetSubCatID: data.budgetSubCatID,
      BudgetSubCategory: data.budgetSubCategory,
      RequestingAmount: data.requestingAmount,
      RequestDate: format(new Date(data.requestDate), "yyyy-MM-dd"),
      FundReqYear: data.fundReqYear,
      FundReqMonth: data.fundReqMonth,
      Description: data.description,
      Status: "Disbursed",
      TenantID: GV.userDet.tenantDetail.tenantID,
      CreatedBy: GV.userDet.appUser.appuserID.toString(),
    };
    // console.log(val)

    bts.SaveFundRequest(val).then((res) => {
      console.log(res);
      if (res.data.message === "Disbursed Successfully") {
        toast.current.show({ severity: "success", summary: res.data.message });
      }
      if (res.data.message !== "Disbursed Successfully") {
        toast.current.show({ severity: "error", summary: res.data.message });
      }
      onPageLoad();
    });
  };

  const popConfirm = (rowData) => {
    setRowData(rowData);
    setVisible1(true);
  };

  const hideConfirm = () => {
    setVisible1(false);
  };

  const confirmDelete = () => {
    bts
      .DeleteFundRequest(GV.userDet.tenantDetail.tenantID, rowData.fundID)
      .then((res) => {
        console.log(res.data);
        if (res.data.message === "Delete Successfully") {
          toast.current.show({
            severity: "success",
            summary: "Deleted Successfully",
          });
          onPageLoad();
        } else {
          toast.current.show({
            severity: "error",
            summary: "Failed to Delete",
          });
        }
      });
    hideConfirm();
  };


  //   const isPreviousMonth = () => {
  //     const selectedDate = new Date(dropDownYear1).getMonth() +1;
  //     const currentDate = new Date().getMonth() + 1;
  //     let isCrntMnth = selectedDate < currentDate;
  //     // setisCurrentMonth(isCrntMnth)
  //     return selectedDate < currentDate;
  // };

  useEffect(() => {
    const selectedMonth = new Date(dropDownYear1).getMonth() + 1;
    const currentMonth = new Date().getMonth() + 1;
    console.log(selectedMonth >= currentMonth, "isCrntMnth");
    setisCurrentMonth(selectedMonth >= currentMonth);
  }, [dropDownYear1]);

  const actionBodyTemplate = (rowData) => {
    // console.log(rowData.status,'rowData');
    return (
      <React.Fragment>
        {rowData.status === "Request Sent" ? (
          <div>
            <Tooltip target=".custom-edit-icon" />
            <Tooltip target=".custom-delete-icon" />
            <IcomoonReact
              iconSet={iconSet}
              color="#1763af"
              size={20}
              icon="edit"
              className="pointer  custom-edit-icon"
              onClick={() => editData(rowData)}
              data-pr-tooltip="Edit"
              data-pr-position="left"
            />
            <IcomoonReact
              iconSet={iconSet}
              color="#C20016 "
              size={20}
              icon="delete"
              className="pointer ms-2  custom-delete-icon"
              // onClick={() => deleteData(rowData)}
              onClick={() => popConfirm(rowData)}
              data-pr-tooltip="Delete"
            />
            <ConfirmPopup
              visible={visible1}
              onHide={hideConfirm}
              message="Are you sure you want to delete?"
              header="Confirm Delete"
              icon="pi pi-exclamation-triangle"
              acceptClassName="p-button-danger"
              acceptLabel="Yes"
              rejectLabel="No"
              accept={confirmDelete}
              reject={hideConfirm}
            />
          </div>
        ) : (
          ""
        )}
        {rowData.status === "Approved" ? (
          <div>
            
            <Tooltip target=".custom-disburse-icon" />
            <IcomoonReact
              iconSet={iconSet}
              color="#3e9d71"
              size={20}
              icon="payment"
              className="pointer custom-disburse-icon"
              onClick={(e) => paymentDone(e, rowData)}
              data-pr-tooltip="Disburse the amount"
            />
          </div>
        ) : (
          ""
        )}
        {rowData.status === "Rejected" ? (
          <div>
            <Tooltip target=".tooltip-icon" position="right" />

            <i
              className="pi pi-ban tooltip-icon"
              style={{ fontSize: "1.2rem", color: "red" }}
              data-pr-tooltip="Rejected"
            ></i>
          </div>
        ) : (
          ""
        )}
        {rowData.status === "Disbursed" ? (
          <div>
            <Tooltip target=".tooltip-icon" position="right" />
            <i
              className="pi pi-check tooltip-icon"
              style={{ fontSize: "1.2rem", color: "green" }}
              data-pr-tooltip="Approved"
            ></i>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  };
  const forecastingAmountbody = (data) => {
    if(data.budgetSubCategory !== 'Total') {
      return GV.userDet.tenantConfig.currency+ " " + data.forecastingAmount;
    } 
      
  };
  const differencebody = (data) => {
    const isNegative = data.difference < 0;
    const positiveClass = "positive-value";
    const negativeClass = "negative-value";
    const valueClass = isNegative ? negativeClass : positiveClass;

    return <span className={valueClass}>{GV.userDet.tenantConfig.currency+ " " + data.difference.toFixed(2)}</span>;
  };
  const reqAmountbody = (data) => {
    return GV.userDet.tenantConfig.currency+ " " +  data.requestingAmount.toFixed(2);
  };
  const rowClass = (data) => {
    return {
      tablehighlight: data.budgetSubCategory === "Total",
    };
  };
  useLayoutEffect(() => {
    onPageLoad();
    // register("accntType");
  }, [setsubCategoryList, selectedMonth]);

  // get last 10 years

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear; year >= currentYear - 9; year--) {
    years.push({ name: year, value: year });
  }
  // console.log(years);

  const handleDropDwnSelectYr = (e) => {
    setSelectYear(e.value);
    console.log(e.value, "selectYer");
  };

  // const DropdownYearSelect = () => {
  //   bts
  //   .GetFundRequests(
  //     GV.userDet.tenantDetail.tenantID,
  //     selectYear,
  //     selectedMonth
  //   ).then((res) => {
  //     console.log(res,'res');
  //   })
  // }

  useEffect(() => {
    onPageLoad();
    // trigger('subcategory')
    // triggerValidation('subcategory');
  }, [dropDownYear, dropDownYear1, trigger]);

  // useEffect(() => {
  //   if (dropDownYear && dropDownMonth) {
  //     DropdownYearMonthSelect(dropDownYear, dropDownMonth);
  //   }
  // }, [dropDownYear]);

  // const selectDateAndMonth = (e) => {
  //   let date = new Date(e.value);
  //   let month = date.getMonth() + 1;
  //   let year = date.getFullYear();
  //   setDropDownMonth(month)
  //   setDropDownYear(year)
  //   DropdownYearMonthSelect(year,month)
  //   onPageLoad();

  // }

  // const DropdownYearMonthSelect = (dropDownYear) => {
  //   let year = dropDownYear.getFullYear();
  //   let month = dropDownYear.getMonth() + 1;
  //   bts.GetFundRequests(GV.userDet.tenantDetail.tenantID,year,month).then((res) => {

  //   })
  // }

  const onChangeCalendar = (event) => {
    setDropDownYear(event.value);
  };

  const onChangeCalendar1 = (event) => {
    resetAsyncForm(event);
    let year = event.value.getFullYear();
    let month = event.value.getMonth() + 1;
    setDropDownYear1(event.value);
    let currentMonth = new Date().getMonth() + 1
    let currentYear = new Date().getFullYear()
    let checkMonthYear = month < currentMonth || (month === currentMonth && year < currentYear);
    setisCurrentMonth(checkMonthYear);
    bts
      .GetBudgetCategories(GV.userDet.tenantDetail.tenantID, year, month)
      .then((res) => {
        let newTable = res.data;
      });
    // resetAsyncForm()
  };

  return (
    <div>
      <BlockUI blocked={isProcess}>
        <Toast ref={toast}></Toast>

        <div className="card cc-greyCard-custm card-borderless" >
          <div className="card-body p-2">
            <div></div>
            <div className="d-flex justify-content-between flex-wrap align-items-center">
              <h4>Fund request</h4>
              <div className="d-flex justify-content-between">
                <Calendar
                  value={dropDownYear}
                  onChange={(e) => onChangeCalendar(e)}
                  view="month"
                  dateFormat="mm/yy"
                />
              </div>
            </div>
          </div>
        </div>
        {isLoading && (
          <ProgressSpinner
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            strokeWidth="4"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
        <div className="row">
          <div className="col-md-9  fund cc-card">
            {fundRequestList.map((x) => {
              return x.fundRequest.length !== 0 ? (
                <div className="card mt-3">
                  <p className="cc-title ms-2 mt-1 mb-3 cc-text-primary">
                    <b>{x.budgetCategory}</b>
                  </p>

                  <DataTable
                    value={x.fundRequest}
                    showGridlines
                    rowClassName={rowClass}
                    emptyMessage="No data found"
                  >
                    <Column
                      field="budgetSubCategory"
                      header="Expenses"
                      style={{ width: "20%" }}
                    ></Column>
                    <Column
                      field="forecastingAmount"
                      header="Current Month Forecasting"
                      body={forecastingAmountbody}
                      bodyStyle={{ textAlign: "right" }}
                      // style={{visibility:"hidden"}}
                    ></Column>
                    <Column
                      field="requestingAmount"
                      header="Requesting Amount"
                      body={reqAmountbody}
                      bodyStyle={{ textAlign: "right" }}
                    ></Column>
                    <Column
                      field="difference"
                      header="Difference"
                      body={differencebody}
                      bodyStyle={{ textAlign: "right" }}
                    ></Column>
                    <Column field="description" header="Description"></Column>
                    <Column field="status" header="Status"></Column>
                    <Column
                      header="Action"
                      field="actionBodyTemplate "
                      body={actionBodyTemplate}
                    ></Column>
                  </DataTable>
                </div>
              ) : (
                <div className="card mt-3">
                  <p className="cc-title ms-2 mt-1 mb-3 cc-text-primary">
                    <b>{x.budgetCategory}</b>
                  </p>

                  <DataTable
                    value={x.fundRequest}
                    showGridlines
                    rowClassName={rowClass}
                    emptyMessage="No data found"
                  >
                     <Column
                      field="budgetSubCategory"
                      header="Expenses"
                      style={{ width: "20%" }}
                    ></Column>
                    <Column
                      field="forecastingAmount"
                      header="Current Month Forecasting"
                      body={forecastingAmountbody}
                      bodyStyle={{ textAlign: "right" }}
                    ></Column>
                    <Column
                      field="requestingAmount"
                      header="Requesting Amount"
                      body={reqAmountbody}
                      bodyStyle={{ textAlign: "right" }}
                    ></Column>
                    <Column
                      field="difference"
                      header="Difference"
                      body={differencebody}
                      bodyStyle={{ textAlign: "right" }}
                    ></Column>
                    <Column field="description" header="Description"></Column>
                    <Column field="status" header="Status"></Column>
                    <Column
                      header="Action"
                      field="actionBodyTemplate "
                      body={actionBodyTemplate}
                    ></Column>
                  </DataTable>
                </div>
              );
            })}
          </div>
          <div className="col-md-3 mt-3 cc-card">
            <div className="card">
              <p className="cc-main-title text-center mt-2 mb-2">
                {" "}
                {editmode === "" ? "New" : "Update"} Fund Request
              </p>
            </div>
            <div className="card mt-2">
              {/* {categoryList.map(xy=>{
                        return(
                            <p>{xy. budgetCategory}</p>
                        )
                    })} */}
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <label>Select Month</label>
                      <br />

                      <Calendar
                        value={dropDownYear1}
                        onChange={(e) => onChangeCalendar1(e)}
                        view="month"
                        dateFormat="mm/yy"
                        disabled={editDis}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="required">Category</label>
                      <br />

                      <Controller
                        render={({ field }) => (
                          <Dropdown
                            options={categoryList}
                            {...field}
                            appendTo="self"
                            optionLabel="budgetCategory"
                            placeholder="Select category type"
                            disabled={editDis}
                            onChange={(e) => changeType(e.value)}
                            className="form-control"
                          />
                        )}
                        control={control}
                        name="category"
                        defaultValue={categoryValue}
                        rules={{ required: "This field is required" }}
                      />
                      <p className="text-danger">
                        {" "}
                        {errors.category?.message || errorMessage}{" "}
                      </p>
                    </div>

                    <div className="col-md-12">
                      <label className="required">Line Item </label>
                      <br />
                      <Controller
                        render={({ field }) => (
                          <Dropdown
                            options={categorySelected}
                            {...field}
                            appendTo="self"
                            optionLabel="budgetSubCategory"
                            placeholder="Select expense"
                            onChange={(e) => changeSubCat(e.value)}
                            className="form-control"
                            disabled={editDis}
                          />
                        )}
                        control={control}
                        name="subcategory"
                        defaultValue={subcategoryValue}
                        rules={{ required: "This field is required" }}
                      />
                      <p className="text-danger">
                        {errors.subcategory?.message}
                      </p>
                      {/* <Dropdown   defaultValue={itemData.subcategory} onChange={(e) => setItemData(...e.target.value)} options={categoryList} optionlabel="name" optionvalue="value"
                                        placeholder="Select a subcategory" className="w-full md:w-14rem form-control"    {...register('subcategory', { required: true })}/>
                        
                                    {errors.subcategory && <p className="text-danger">This field is required</p>} */}
                    </div>

                    <div className="col-md-12 mb-3">
                      <label>Forecasting Amount</label>
                      <input
                        type="number"
                        className="form-control px-1 py-2"
                        style={{ backgroundColor: "#e9e9e9" }}
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing"
                        defaultValue={foreCastAmount}
                        readOnly
                        {...register("forecstingamt", {})}
                      />
                    </div>

                    <div className="col-md-12 mb-3">
                      <label>Balance Amount</label>
                      <input
                        type="number"
                        className="form-control px-1 py-2"
                        style={{
                          backgroundColor: "#e9e9e9",
                          color: balance < 0 ? "red" : "",
                        }}
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing"
                        defaultValue={balance}
                        readOnly
                        {...register("balanceAmnt", {})}
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label className="required">Requesting Amount</label>
                      <input
                        type="number"
                        className="form-control px-1 py-2"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing"
                        defaultValue={itemData.expenses}
                        onChange={(e) => setItemData(...e.target.value)}
                        {...register("expenses", { required: true })}
                      />
                      {errors.expenses && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>

                    <div className="col-md-12">
                      <label>Description</label>
                      <textarea
                        type="text"
                        className="form-control"
                        defaultValue={itemData.description}
                        onChange={(e) => setItemData(...e.target.value)}
                        {...register("description", {})}
                      />

                      {errors.description && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                  <div className="col-md-3">
                    <button
                      className="btn btn-danger me-3"
                      onClick={resetAsyncForm}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-md-3">
                    {editmode === "" ? (
                      <button
                        // className={`${isCurrentMonth ? 'btn btn-primary' : 'btn btn-secondary'} `}
                        className={
                          isCurrentMonth
                            ? "btn btn-primary"
                            : "btn btn-secondary"
                        }
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isCurrentMonth}
                      >
                        Add
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary "
                        onClick={handleSubmit(onSubmit)}
                      >
                        Update
                      </button>
                    )}
                  </div>
                  <div className="col-md-3">
                    {editmode === "" ? (
                      ""
                    ) : ("" )}
                  </div>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </BlockUI>
    <ConfirmDialog />

    </div>
  );
}
