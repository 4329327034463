import config from '../assets/config';
import { ax } from './base';

export default class salesService {
    GetSalesEstimateListSearch(payload){
        return ax.post(`${config.apiUrl}Invoice/GetSalesEstimateListSearch`,payload);
    }
    
    SaveSalesEstimate(payload){
        return ax.post(`${config.apiUrl}Invoice/SaveSalesEstimate`,payload);
    }
    UpdateStatusEstimate(payload){
        return ax.post(`${config.apiUrl}Invoice/UpdateStatusEstimate`,payload);
    }
    DownloadDocs(payload){
        return ax.post(`${config.apiUrl}Common/DownloadDocs`,payload);
    }
    GenerateConfigNumber(payload){
        return ax.post(`${config.apiUrl}Common/GenerateConfigNumber`,payload);
    }

    // credit note
    GetSalesReturnListSearch(payload){
        return ax.post(`${config.apiUrl}Invoice/GetSalesReturnListSearch`,payload);
    }
    SearchSalesInvoice(payload){
        return ax.post(`${config.apiUrl}Invoice/SearchSalesInvoice`,payload);
    }
    SaveSalesCreditNote(payload){
        return ax.post(`${config.apiUrl}Invoice/SaveSalesCreditNote`,payload);
    }
    DownloadCreditDocs(payload){
        return ax.post(`${config.apiUrl}Common/DownloadDocs`,payload);
    }
}
