import React, { useState, useRef, useEffect } from 'react';
// import { render } from "react-dom";
import { Row } from 'react-bootstrap';
// import ProfileIcon from '../../assets/img/organization-icons/profile.png';
// import ConfigurationIcon from '../../assets/img/organization-icons/settings.png';
// import CompanyIcon from '../../assets/img/organization-icons/shop.png';
// import NotificationIcon from '../../assets/img/organization-icons/notification.png';
import '../../assets/styles/settings/organizationprofile.scss';
import OrganizationService from '../../services/organization';
import { Controller, useForm } from "react-hook-form";
// import { faCropSimple } from '@fortawesome/free-solid-svg-icons';
import { RiDeleteBin5Line } from 'react-icons/ri';
import {  Dropdown, Toast } from '../PrimeReact';
import Configuration from './Configuration';
import GV from '../../services/globalVar';
import {iconSet, IcomoonReact, Tooltip} from '../PrimeReact.js'
import OnBoardConfig from './OnBoardConfig';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

//.required('This field is required')  28
    //.required('This field is required').email('Provide a valid email')
let schema =  yup.object().shape({
 
    cname: yup.string().required('This field is required'),
    companytype:yup.string().required('This field is required'),
    dname: yup.string().required('This field is required') ,
    owner: yup.string().required('This field is required') ,
    // country: yup.string().required('This field is required'),
    pEmail:yup.string().required('This field is required'),
    pcontact:yup.string().required('This field is required') ,
    about:yup.string(),

    industryType: yup.string().required('This field is required') ,
    compRegId:yup.string().required('This field is required'),
    panNo:yup.string().required('This field is required'),
    licenseName:yup.string().required('This field is required'),
    licenseNo:yup.string().required('This field is required'),
    taxId:yup.string().required('This field is required'),
    taxNo:yup.string().required('This field is required'),
    siteLink:yup.string(),

    //billDetails
    address1:yup.string().required('This field is required') ,
    // address2:yup.string().required('This field is required') ,
    bcity:yup.string().required('This field is required') ,
    bstate:yup.string().required('This field is required'),
    bcountry:yup.string().required('This field is required'),
    bzip:yup.string().required('This field is required') ,
    //bankDetails
    bankname:yup.string().required('This field is required') ,
    branch:yup.string().required('This field is required') ,
    ifsc:yup.string().required('This field is required') ,
    acctNo:yup.string().required('This field is required') ,
    accntName:yup.string().required('This field is required') ,

   
})
//sent
export default function OrganizationProfile(props) {
    const toast = useRef(null);
    //logo Base64
    const [selectetdFile, setSelectedFile] = useState([]);
    const [fileBase64String, setFileBase64String] = useState("");
    //Shortlogo Base64
    const [selectetdFile1, setSelectedFile1] = useState([]);
    const [fileBase64String1, setFileBase64String1] = useState("");
    // show name logo
    const [imgName, SetImgName] = useState("");
    const [fileList, SetFileList] = useState(false);
    // show name shortlogo
    const [imgName1, SetImgName1] = useState("");
    const [fileList1, SetFileList1] = useState(false);

    ///////////api get in call//////////
    // const [organizationData, setOrganizationData] = useState([]);
    const [isMounted, setIsMounted] = useState(true);
    /////////////// dropdown get call ///////////
    const [industryData, setIndustryData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [tdataLoaded, setTdataLoaded] = useState(false);
    const [cdataLoad,setCdataLoaded] = useState('Init');
    const [stateData, setStateData] = useState([]);

    const [selectedState, setSelectedState] = useState();
    const [selectedIndustry, setSelectedIndustry] = useState([]);
    
    const [selectedCountry, setSelectedCountry] = useState();
    //   bind logo Base64 
    const [logo, setLogo] = useState("");
    const [shortLogo, setShortLogo] = useState("");
    //   bind shortlogo Base64 
    const [logo1, setLogo1] = useState("");
    //container hide
    const [showImg, setShowImg] = useState(false);
    const [showImg1, setShowImg1] = useState(false);
    // browser logo
    const [showLogo, setShowLogo] = useState(false);
    const [showShortLogo, setShowShortLogo] = useState(false);

    const [tenantInfo,setTenantInfo] = useState()

    const defaultValues = {
        cname: '',
        companytype:'',
        dname: "",
        owner: '',
        // country: '',
        industryType:'',

        pEmail:'',
        pcontact:'',

        compRegId:'',
        panNo:'',
        licenseName:'',
        licenseNo:'',
        taxId:'',
        taxNo:'',
        siteLink:'',
        about:'',
    
        //billDetails
        address1:'',
        // address2:'',
        bcity:'',
        bstate:'',
        bcountry:'',
        bzip:'',
        //bankDetails
        bankname:'',
        branch:'',
        ifsc:'',
        acctNo:'',
        accntName:'',
     
    };





    //API call
    const org = new OrganizationService();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }, control,
        setValue,
        getValues,
        watch
    } = useForm({
        defaultValues ,
             resolver:yupResolver(schema)
    });

    //  industryType 
    // const indstryType = [
    //     { label: 'Level 1', value: 'L1' },
    //     { label: 'Level 2', value: 'L2' },
    //     { label: 'Level 3', value: 'L3' },
    //     { label: 'Level 4', value: 'L4' },
    //     { label: 'Level 5', value: 'L5' }
    // ];

    // BusinessType 
    const BusinessType = [
        { label: 'Type 1', value: 'T1' },
        { label: 'Type 2', value: 'T2' },
        { label: 'Type 3', value: 'T3' },
        { label: 'Type 4', value: 'T4' },
        { label: 'Type 5', value: 'T5' }
    ];

    // const countries = [
    //     { label: 'India', value: 'IND' },
    //     { label: 'Australia', value: 'AUS' },
    //     { label: 'America', value: 'USA' },
    // ];

    const state = [
        { label: 'Tamil Nadu', value: 'TN' },
        { label: 'Karnataka', value: 'KA' },
        { label: 'Uttar Pradesh', value: 'UP' },
    ];

    //logo
    const [fileValidation, setFileValidation] = useState("");
    const [ftypeValidate,setFtypeValidate] = useState(false);
    const onFileChange = (e) => {
        let minFileSize = 1024;
        console.log(e.target.files[0]);
                setFtypeValidate(false);
                setFileValidation("");
        let selectedFileType = e.target.files[0].name;
        const lastSegment = selectedFileType.substring(selectedFileType.lastIndexOf(".") + 1);
        let selectedFileSize = e.target.files[0].size ;

        if (e.target.files.length > 0) {
            if(lastSegment!=="jpeg" && lastSegment!=="jpg" && lastSegment!=="png"){
               setFtypeValidate(true);
                setFileValidation("Supported formats are JPEG,JPG,PNG only");
            }
            else if (selectedFileSize >= 1048576) {
                setFileValidation("File size should be less than 2 MB");
            }
            else {
                SetFileList(true);
                console.log(e.target.files)
                setSelectedFile(e.target.files);
                encodeImageBase64(e.target.files[0]);
                SetImgName(e.target.files[0].name);
                // setShowImg(e.target.files[0]);
                setShowImg(false);
                setLogo("");
                setShowLogo(true);
                setFileValidation("");

                  const data = { 
                    "TenantID" : GV.userDet.tenantDetail.tenantID,
                  "IsLogoChanged" : true,
                  "IsSmallLogoChanged" : false }
                const formData = new FormData();
                formData.append('InputJson', JSON.stringify(data));
                formData.append('Logo', e.target.files[0]);
                formData.append('SmallLogo', '');
                org.SaveTenantLogo(formData).then(res=>{
                    console.log(res)
                    if(res.data.message == 'Document Updated'){
                        toast.current.show({ severity: 'success', summary: 'Successfully Updated Logo' });

                    }else{
                        toast.current.show({ severity: 'error', summary: 'Failed to update Logo'});

                    }
                })
            }

        }
    };


    const encodeImageBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var Base64 = reader.result;
                // console.log(Base64);
                setFileBase64String(Base64);
                // setLogo(Base64);
            };
            reader.onerror = (error) => {
                console.log("error: ", error);
            };
        }
    };

    const decodeImageBase64 = (base64String) => {

        return decodeURIComponent(
            (base64String)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
    };

    const decodeBase64 = decodeImageBase64(
        fileBase64String.substring(fileBase64String.indexOf(",") + 1)

    );



    // short logo
const [fileValidation1, setFileValidation1] = useState("");
    const [ftypeValidate1,setFtypeValidate1] = useState(false);
    const onFileChange1 = (e) => {
        //  encodeImageBase64short(selectetdFile);
             setFtypeValidate1(false);
                setFileValidation1('');
                let selectedFileType = e.target.files[0].name;
                const lastSegment = selectedFileType.substring(selectedFileType.lastIndexOf(".") + 1);
                let selectedFileSize = e.target.files[0].size ;
        
        if (e.target.files.length > 0) {
             if(lastSegment!=="jpeg" && lastSegment!=="jpg" && lastSegment!=="png"){
               setFtypeValidate1(true);
                setFileValidation1("Supported formats are JPEG,JPG,PNG only");
            }
            else if (selectedFileSize >= 1048576) {
                setFileValidation1("File size should be less than 2 MB");
            }
            else {
            setSelectedFile1(e.target.files);
            encodeImageBase64short(e.target.files[0]);
            SetFileList1(true);
            SetImgName1(e.target.files[0].name);
            setShowImg1(false);
            setShortLogo('');
            console.log("logo")
            setShowShortLogo(true);
            let idCard = e.target.files[0]
            let idCardBase64 = '';
            
            const data = { 
                "TenantID" : GV.userDet.tenantDetail.tenantID,
              "IsLogoChanged" : false,
              "IsSmallLogoChanged" : true }
            const formData = new FormData();
            formData.append('InputJson', JSON.stringify(data));
            formData.append('Logo','' );
            formData.append('SmallLogo',e.target.files[0]);
            org.SaveTenantLogo(formData).then(res=>{
                console.log(res)
                if(res.data.message == 'Document Updated'){
                    toast.current.show({ severity: 'success', summary: 'Successfully Updated Logo' });

                }else{
                    toast.current.show({ severity: 'error', summary: 'Failed to update Logo' });

                }
            })
            }
         
        }
    };

 
    const encodeImageBase64short = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var Base64 = reader.result;
                // console.log(Base64);
                setFileBase64String1(Base64);
            };
            reader.onerror = (error) => {
                console.log("error: ", error);
            };
        }
    };
    const decodeImageBase64short = (base64String1) => {
        // From Bytestream to Percent-encoding to Original string
        return decodeURIComponent(
            (base64String1)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
    };

    const decodeBase64short = decodeImageBase64short(
        fileBase64String1.substring(fileBase64String1.indexOf(",") + 1)
    );
    //get call
    function getOrganizationData() {
        let tid = GV.userDet.tenantDetail.tenantID;
       console.log(countryData,'we')
        org.GetTenantDetails(tid)
            .then((response) => {
                if (isMounted) {
                    if (response.data) {
                        if (response.data.statusCode === 200) {
                            let resp = response.data.data
                            setTenantInfo(response.data.data)
                            setTdataLoaded(true);
                            console.log(resp,'name')
                            setValue('cname',resp.tenantName);
                            setValue('dname',resp.shortName);
                            setValue('owner',resp.administratorName);
                            // setValue('country',resp.country);
                            setValue('pEmail',resp.email);
                            setValue('pcontact',resp.primaryPhone);
                            setValue('industryType',resp.industryType);
                            setValue('companytype',resp.companyType)
                            setValue('compRegId',resp.companyID);
                            setValue('panNo',resp.panNo);
                            setValue('licenseName',resp.licenseName);
                            setValue('licenseNo',resp.licenseNo);
                            setValue('taxId',resp.taxID != null ? resp.taxID : '');
                            setValue('taxNo',resp.taxNumber);
                            setValue('siteLink',resp.website);
                            setValue('about',resp.description);

                            setValue('address1',resp.billAddress1);
                            // setValue('address2',resp.billAddress2 != null ? resp.billAddress2 : '');
                            setValue('bcity',resp.billCity);
                            setValue('bcountry',resp.billCountry);
                            changeCountryProfile(resp.billCountry)
                            setValue('bzip',resp.billZipCode);
                            setValue('bstate',resp.billState);

                            setValue('bankname',resp.bankName);
                            setValue('branch',resp.bankBranch);
                            setValue('ifsc',resp.ifscCode);
                            setValue('acctNo',resp.bankAccountNo);
                            setValue('accntName',resp.bankAccountName);
                            // setOrganizationData(response.data.data[0]);
                            if (response.data.data.logo !== "" && response.data.data.logo !== null && response.data.data.logo !== undefined) {
                                setLogo(response.data.data.logo);
                                setShowImg(true);
                                setShowLogo(false);
                            }
                            if (response.data.data.smallLogo !== "" && response.data.data.smallLogo !== null && response.data.data.smallLogo !== undefined) {
                                setShortLogo(response.data.data.smallLogo);
                                setShowImg1(true);
                                setShowShortLogo(false);
                            }
                            // setSelectedCountry(response.data.data[0].country);
                            // setSelectedIndustry(response.data.data[0].industryType);
                            // setSelectedState(response.data.data[0].state);
                            console.log('st',response.data.data[0].state);
                            console.log("ONE",getValues('bcountry'))

                        } else {
                            setTdataLoaded(false);
                        }
                       
                    }
                }
            }).catch(function (error) {

            })
    }

    // useEffect(() => {
    //     // getOrganizationData();
    //     return () => { setIsMounted(false); }
    // }, []);
    // logo
    const toggleRemove = () => {
        setShowImg(false);
    }
    const removeLogo = () => {
        setShowLogo(false);
    }
    // short logo 

    const shortRemove = () => {
        setShowImg1(false);
    }

    // const removeLogo1 =()=>{

    // }

    ///SaveCall
const submit = () =>{
    console.log(getValues())
}
    const onSubmit = (data) => {
        console.log(data,'data')
        let logoImg = "";
       let  industryType  = industryData.filter(x=>x.industryName === data.industryType )
       console.log(industryType,'type') 
        const req = {
            
                "tenantID": GV.userDet.tenantDetail.tenantID,
                "tenantName":data.cname,
                "tenantType": tenantInfo.tenantType,
                "shortName":  data.dname,
                "taxID": data.taxId,
                "inCorpState": tenantInfo.inCorpState,
                "address1":  tenantInfo.address1,
                "city":tenantInfo.city,
                "state":tenantInfo.state,
                "zip":tenantInfo.zip,
                "country": tenantInfo.country,
                "website": data.siteLink,
                "primaryPhone": data.pcontact,
                "fax": tenantInfo.fax,
                "email":data.pEmail,
                "domainPrefix":tenantInfo.domainPrefix,
                "tenantStatus": tenantInfo.tenantStatus,
                "billAddress1":  data.address1,
                "billCity": data.bcity,
                "billZipCode": data.bzip,
                "billState": data.bstate,
                "billCountry": data.bcountry,
                "contactUsTelephone":tenantInfo.contactUsTelephone,
                "contactUsEmailID": tenantInfo.contactUsEmailID,
                "dataPartitionID": tenantInfo.dataPartitionID,
                "timeZoneID": tenantInfo.timeZoneID,
                "administratorName":tenantInfo.administratorName,
                "administratorEmail":tenantInfo.administratorEmail,
                "administratorPhone":tenantInfo.administratorPhone,
                "domicileCountry": tenantInfo.domicileCountry,
                "tenantKey": tenantInfo.tenantKey,
                "industryID": industryType[0].industryID,
                "industryType":  industryType[0].industryName,
                "businessType": tenantInfo.businessType,
                "companyType": data.companytype,
                "description":data.about,
                "companyID": data.compRegId,
                "licenseName": data.licenseName,
                "licenseNo": data.licenseNo,
                "taxNumber": data.taxNo,
                "panNo": data.panNo,
                "bankName": data.bankname,
                "bankBranch": data.branch,
                "ifscCode": data.ifsc,
                "bankAccountNo": data.acctNo,
                "bankAccountName": data.accntName,
                "createdBy": GV.userDet.appUser.firstName + " " +  GV.userDet.appUser.lastName
            
        }
       
        console.log(req,'request')
        org.SaveTenantDetails(req)
            .then((response) => {
                if (response.data) {
                    if (response.data.statusCode === 200) {
                        console.log(response.data);
                        toast.current.show({ severity: 'success', summary: 'Successfully Updated Organization Profile' });
                    }
                }
            })
            .catch(function (error) {
                if (error.response.data) {
                    toast.current.show({ severity: 'error', summary: 'Organization Profile cannot be updated' });
                }
                // console.log("test");
            })

    }





    //   industry get call 

    function getIndustry() {
        org.GetIndustry()
            .then((response) => {
                if (isMounted) {
                    if (response.data) {
                        if (response.data.statusCode === 200) {
                            setTdataLoaded(true);
                            setIndustryData(response.data.data);
                            console.log(cdataLoad,tdataLoaded,'ind')

                            // if(cdataLoad === true && tdataLoaded === true){
                            //     getOrganizationData();
                            //   }
                        } else {
                            setTdataLoaded(false);
                        }
                    }
                }
            }).catch(function (error) {

            })
            org.GetCountry()
            .then((response) => {
                // if (isMounted) {
                if (response.data) {
                    if (response.data.statusCode === 200) {
                        const countryListed = response.data.data
                        setCountryData(countryListed);
                        setCdataLoaded('Done');
                        console.log('c',response.data.data,countryData);

                        let country = response.data.data;
                        console.log(cdataLoad,tdataLoaded,'count')
                    } else {
                        setTdataLoaded(false);
                    }

                    // }
                }
            }).catch(function (error) {

            })
            
       

    }
 



   
let [stateList,setStateList] = useState([])
    function changeCountryProfile  (e) {
        console.log(e)
        setValue('bcountry',e)
        console.log(getValues('bcountry'),countryData)
      const countries = countryData.filter(x=>x.countryName ===  getValues('bcountry')) 
      console.log(countries,'coo')
    // 
    // if(v === countRef){
        org.GetState(countries[0].countryID).then((response) => {
            if (response.data) {
                if (response.data.statusCode === 200) {
                    
                    const state = response.data.data
                    setStateList(response.data.data)
                    const check = state.filter(x=>x.stateName === getValues('bstate'))
                    console.log(check,'checks',getValues('bstate'))
                    if(check.length === 0){
                        setValue('bstate','');
                    }else{
                        // setValue('bstate',tenantInfo?.billState);
                    }
                }
            }
        }).catch(function (error) {

        })
    // }
        // setStateList(states)
    //   getState(countries[0].countryID)
    
    }
   
    const [tabVal,setTabVal] = useState('Configuration');
    const TabChange = (val) =>{
            setTabVal(val)
    }

   function initialLoad(){
        getIndustry();
            // getOrganizationData();
    }
    
    useEffect(() => {
        console.log("repeat",countryData)
        if(cdataLoad === 'Init'){
        initialLoad()
        }
        if(cdataLoad === 'Done'){
            setTimeout(() => {
            // setCdataLoaded('changed');
            getOrganizationData();
        }, 3000);
        }
       
        // reset(organizationData);
        // return () => { setIsMounted(false); }
    }, [cdataLoad]);

    return (
        <><Toast ref={toast}></Toast>
        <div className='row'>
            <Row>
            <div className='card mb-3 ms-2 me-3 card-borderless cc-greyCard-custm'>
                <div className='d-flex justify-content-between align-items-center my-2'>
                <div className="ms-2 my-2 me-2">

                    <div style={{width:'300px'}}>
                        <h5 className=' text-bold m-0'>{tabVal}</h5>
                    </div>
</div>
                    <div className='d-flex justify-content-between align-items-center '>
                          <Tooltip target=".custom-config-icon" />
                            <Tooltip target=".custom-profile-icon" />
                            <Tooltip target=".custom-theme-icon" />
                        <IcomoonReact iconSet={iconSet} color="#1763af" size={25} icon='configure' className='pointer   custom-config-icon'  onClick={() => TabChange('Configuration')}   data-pr-tooltip="Configuration"
                data-pr-position="top" />
                        <IcomoonReact iconSet={iconSet} color="#1763af" size={25} icon='Test-Service-Master-Management' className='pointer  ms-5 custom-profile-icon'  onClick={() => TabChange('Company Profile')}  data-pr-tooltip="Company Profile"
                data-pr-position="top"/>
                        <IcomoonReact iconSet={iconSet} color="#1763af" size={25} icon='physician' className='pointer  ms-5 custom-theme-icon'  onClick={() => TabChange('Theme')} data-pr-tooltip="Theme"
                data-pr-position="top"/>
                        {/* <IcomoonReact iconSet={iconSet} color="#1763af" size={25} icon='Provider-Conflict' className='pointer mt-2 ms-5' onClick={() => TabChange('Partnership Details')}/> */}

                        {/* <img src={ProfileIcon} alt="profile-image" />
                        
                        <img src={ConfigurationIcon} alt="profile-image"  className='ms-3' />


                        <img src={ConfigurationIcon} alt="profile-image"  className='ms-3'/> */}
                    </div>
                </div>
            </div>
            </Row>
            {/* <div className='col-md-2 bg-white p-3'> */}
            {/* <div className="card"> */}
            {tabVal === 'Company Profile' ? 
            <div className='row'>
                    <div className='col-md-9'>
                            <div className='card card-borderless mb-4'>
                                <div className='card-header bg-white'>
                                    <h5 className='mb-0 '>Basic Details</h5>
                                </div>
                                <div className='card-body'>
                                        <div className='row mb-3'>

                                            <div className='col form-group'>
                                                <label className='form-control-label required'>Company Name</label>
                                                <input type="text" className='form-control'
                                                    {...register('cname')}   disabled
                                                     />
                                            {errors.cname && <p className="text-danger mb-0 ps-1">{errors.cname?.message}</p>}
                                                    
                                            </div>
                                            <div className='col'>
                                                <label className='required'>Company Type</label>
                                                <input type='text' className='form-control' {...register('companytype')} />
                                            {errors.companytype && <p className="text-danger mb-0 ps-1">{errors.companytype?.message}</p>}
                                            </div>
                                           
                                        </div>
                                        <div className='row mb-3'>
                                        <div className='col form-group'>
                                                <label className='form-control-label required'>Display Name</label>
                                                <input type="text" className='form-control' {...register('dname')}
                                                     />
                                            {errors.dname && <p className="text-danger mb-0 ps-1">{errors.dname?.message}</p>}

                                            </div>
                                            <div className='col form-group'>
                                                <label className='form-control-label required'>Owner</label>
                                                <input type="text" className='form-control' {...register('owner')}
                                                   disabled />
                                            {errors.owner && <p className="text-danger mb-0 ps-1">{errors.owner?.message}</p>}

                                            </div>

                                            {/* <div className='col form-group'>
                                                <label className='form-control-label'>Domicile Country</label>
                                                <input type="text" className='form-control'  {...register('country')}
                                                   disabled />
                                            {errors.country && <p className="text-danger mb-0 ps-1">{errors.country?.message}</p>}

                                            </div> */}

                                        </div>
                                        <div className='row mb-3'>
                                            <div className='col form-group'>
                                                <label className='form-control-label required'>Email</label>
                                                <input type="email" className='form-control' {...register('pEmail')} disabled />
                                                {/* , { required: "Email is Required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address"
                                        }})}*/}
                                                       {errors.pEmail && <p className="text-danger mb-0">{errors.pEmail.message}</p>}
                                            </div>
                                         
                                            <div className='col form-group'>
                                                <label className='form-control-label required'>Primary Contact</label>
                                                <input type="text" className='form-control'  {...register('pcontact')}
                                                   disabled />
                                                       {errors.pcontact && <p className="text-danger mb-0">{errors.pcontact.message}</p>}
                                            </div>
                                        </div>
                                        

                                        <div className='row mb-3'>
                                          
                                            <div className='col form-group'>
                                                <label className='form-control-label '>Official Website</label>
                                                <input type="text" className='form-control'  {...register('siteLink')}
                                                    />
                                                       {errors.siteLink && <p className="text-danger mb-0">{errors.siteLink.message}</p>}
                                            </div>
                                            <div className='col form-group'>
                                                <label className='form-control-label'>About</label>
                                                <textarea className='form-control' rows="2"{...register('about')}
                                                   
                                                />
                                                       {errors.about && <p className="text-danger mb-0">{errors.about.message}</p>}
                                            </div>
                                        </div>
                                        
                                    </div>
                            </div>

                             <div className='card card-borderless mb-4'>
                                    <div className='card-header bg-white'>
                                        <h5 className='mb-0 text-bold '>Company Details</h5>
                                    </div>
                                    <div className='card-body'>
                                    <div className='row mb-3'>
                                            <div className='col form-group'>
                                                <label className='form-control-label required'>Industry Type</label>

                                                <Controller
                                                    render={({ field }) => (
                                                        <Dropdown className="input-group " options={industryData} 
                                                            {...field}  optionLabel='industryName'  optionValue='industryName'
                                                            {...register('industryType')}
                                                        />

                                                    )}
                                                    control={control}
                                                    name="industryType"

                                                />
                                            {errors.industryType && <p className="text-danger mb-0 ps-1">{errors.industryType?.message}</p>}

                                            </div>


                                            <div className='col form-group'>
                                                {/* <label className='form-control-label'>Business Type</label>

                                                <Controller
                                                    render={({ field }) => (
                                                        <Dropdown className="input-group " options={BusinessType}
                                                            {...field}

                                                            optionLabel="label" optionValue="value" />

                                                    )}
                                                    control={control}
                                                    name="businessType"
                                                    defaultValue={organizationData.businessType}
                                                />*/}

                                            </div> 
                                        </div>
                                        

                                        <div className='row mb-3'>
                                            <div className='col form-group'>
                                                <label className='form-control-label required'>Company Registration ID</label>
                                                <input type="text" className='form-control'  {...register('compRegId')}
                                                    />
                                                       {errors.compRegId && <p className="text-danger mb-0">{errors.compRegId.message}</p>}

                                            </div>

                                            <div className='col form-group'>
                                                <label className='form-control-label required'>PAN Number</label>
                                                <input type="text" className='form-control'   {...register('panNo')}
                                                />
                                                       {errors.panNo && <p className="text-danger mb-0">{errors.panNo.message}</p>}
                                            </div>
                                        </div>

                                        <div className='row mb-3'>
                                            <div className='col form-group'>
                                                <label className='form-control-label'>License Name</label>
                                                <input type="text" className='form-control'   {...register('licenseName')}
                                                     />
                                                       {errors.licenseName && <p className="text-danger mb-0">{errors.licenseName.message}</p>}
                                            </div>

                                            <div className='col form-group'>
                                                <label className='form-control-label '>License Number</label>
                                                <input type="text" className='form-control'   {...register('licenseNo')}
                                                    />
                                                       {errors.licenseNo && <p className="text-danger mb-0">{errors.licenseNo.message}</p>}
                                            </div>
                                        </div>

                                        <div className='row mb-3'>


                                            <div className='col-6 form-group'>
                                                <label className='form-control-label required'>Tax Name</label>
                                                <input type="text" className='form-control'  {...register('taxId')}
                                                
                                                />
                                                       {errors.taxId && <p className="text-danger mb-0">{errors.taxId.message}</p>}
                                            </div>

                                            <div className='col form-group'>
                                                <label className='form-control-label required'>Tax No</label>
                                                <input type="text" className='form-control' {...register('taxNo')}
                                                   
                                                />
                                                       {errors.taxNo && <p className="text-danger mb-0">{errors.taxNo.message}</p>}
                                            </div>

                                        </div>
                                       
                                    </div>
                            </div>               
                            <div className='card card-borderless mb-4 bg-white'>
                                    <div className='card-header bg-white'>
                                        <h5 className='mb-0 text-bold'>Billing Address</h5>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row mb-3'>
                                            <div className='col form-group'>
                                                <label className='form-control-label required'>Address Line 1</label>
                                                <input type="text" className='form-control' {...register('address1')}
                                                  
                                                />
                                                       {errors.address1 && <p className="text-danger mb-0">{errors.address1.message}</p>}
                                            </div>

                                            <div className='col form-group'>
                                                {/* <label className='form-control-label'>Address Line 2</label>
                                                <input type="text" className='form-control'{...register('address2')}
                                                    
                                                />
                                                       {errors.address2 && <p className="text-danger mb-0">{errors.address2.message}</p>} */}

                                            </div>
                                        </div>
                                        <div className='row mb-3'>
                                            <div className='col form-group'>
                                                <label className='form-control-label required'>City</label>
                                                <input type="text" className='form-control'  {...register('bcity')}
                                                     />
                                                       {errors.bcity && <p className="text-danger mb-0">{errors.bcity.message}</p>}

                                            </div>

                                            <div className='col form-group'>
                                                <label className='form-control-label required'>Country</label>
                                                <Controller
                                                  control={control}
                                                  name="bcountry"
                                                   
                                                    render={({ field }) => (
                                                        <Dropdown  className='col-auto text-start me-3 input-group' options={countryData}
                                                            {...field}   optionLabel="countryName"   optionValue='countryName'   
                                                            scrollHeight="400px"   
                                                            onChange={(e)=>{changeCountryProfile(e.value)}}
                                                          
                                                        />
                                                    )}
                                                  

                                                />
                                                  {/* <input type="text" className='form-control'  {...register('bcountry')}
                                                     /> */}
                                                       {errors.bcountry && <p className="text-danger mb-0">{errors.bcountry.message}</p>}
                                               
                                            </div>

                                        </div>
                                        
                                                
                                                  
                                        <div className='row mb-3'>
                                            <div className='col form-group'>
                                                <label className='form-control-label required'>State</label>
                                                <Controller
                                                    render={({ field }) => (
                                                        <Dropdown className='col-auto text-start me-3 input-group' options={stateList}
                                                        {...field} optionLabel="stateName"   optionValue='stateName' {...register('bstate')}
                                                        scrollHeight="400px" 
                                                    />
                                                      

                                                    )}
                                                    control={control}
                                                    name="bstate"

                                                />
                                                  {/* <input type="text" className='form-control'  {...register('bstate')}
                                                     /> */}
                                                       {errors.bstate && <p className="text-danger mb-0">{errors.bstate.message}</p>}
                                            </div>

                                            <div className='col form-group'>
                                                <label className='form-control-label required'>ZIP</label>
                                                <input type="text" className='form-control'  {...register('bzip')}
                                                  />
                                                       {errors.bzip && <p className="text-danger mb-0">{errors.bzip.message}</p>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='card card-borderless bg-white'>
                                    <div className='card-header bg-white'>
                                        <h5 className='mb-0 text-bold'>Bank Details</h5>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row mb-3'>
                                            <div className='col form-group'>
                                                <label className='form-control-label required'>Bank Name</label>
                                                <input type="text" className='form-control' {...register('bankname')}
                                                  />
                                                       {errors.bankname && <p className="text-danger mb-0">{errors.bankname.message}</p>}
                                            </div>

                                            <div className='col form-group'>
                                                <label className='form-control-label required'>Branch</label>
                                                <input type="text" className='form-control' {...register('branch')}
                                                    />
                                                       {errors.branch && <p className="text-danger mb-0">{errors.branch.message}</p>}
                                            </div>
                                        </div>
                                        <div className='row mb-3'>
                                            <div className='col form-group'>
                                                <label className='form-control-label required'>IFSC Code</label>
                                                <input type="text" className='form-control' {...register('ifsc')}
                                                     />
                                                       {errors.ifsc && <p className="text-danger mb-0">{errors.ifsc.message}</p>}
                                            </div>

                                            <div className='col form-group'>
                                                <label className='form-control-label required'>Account No</label>
                                                <input type="text" className='form-control' {...register('acctNo')}
                                                     />
                                                       {errors.acctNo && <p className="text-danger mb-0">{errors.acctNo.message}</p>}
                                            </div>
                                        </div>
                                        <div className='row mb-3'>
                                            <div className='col-6 form-group'>
                                                <label className='form-control-label required'>Account Name</label>
                                                <input type="text" className='form-control'  {...register('accntName')}
                                                     />
                                                       {errors.accntName && <p className="text-danger mb-0">{errors.accntName.message}</p>}
                                            </div>

                                          
                                        </div>
                                        <div className='row text-end me-0'>
                                            <button type="button" className='col-auto btn btn-primary ms-auto' onClick={handleSubmit((onSubmit))}>Update</button>
                                        </div>
                                    </div>
                                </div>
                    </div>
                    <div className='col-md-3'>
                        <Row className='mb-2'>
                            <h6 className='col-auto  my-auto  text-black fw-bold'>Logo</h6>
                                                    {
                                                        showImg === true ?
                                                            <button type="submit" className='col-auto ms-auto  text-danger pt-0 mt-0 mb-1' onClick={toggleRemove} ><RiDeleteBin5Line /></button> : ""
                                                    }

                                                    {
                                                        showLogo === true ?
                                                            <button type="submit" className='col-auto ms-auto  text-danger pt-0 mt-0 mb-1' onClick={() => removeLogo()} ><RiDeleteBin5Line /></button> : ""

                                                    }

                                                </Row>


                                                {
                                                    showImg === true ?

                                                        <div>
                                                            <img width="400" height="400" className='mt-2 border' src={logo} alt="company-logo" />
                                                          
                                                        </div>
                                                        :
                                                        showLogo === false ?
                                                            <div className='col form-group'>
                                                                <div className="drop-container dropzone">
                                                                    <p className="mt-3">

                                                                        <label className="upload-button ">
                                                                            <input type="file" value={""} accept="image/*" onChange={onFileChange}
                                                                            /> Drag a file here or browse to upload.
                                                                        </label>

                                                                    </p>

                                                                </div>
                                                                {ftypeValidate===true? <p className='text-danger mb-0'>{fileValidation}</p>:fileValidation !==""?<p className='text-danger'>{fileValidation}</p> : ""}
                                                             
                                                            </div>
                                                            :
                                                            <div>
                                                                <img width="400" height="400" className='mt-2 border' src={fileBase64String} alt="compango" />
                                                            </div>
                                                }

                            
                        <Row className='mt-4'>
                            <h6 className='col-auto  my-auto  text-black fw-bold '>Short Logo</h6>
                                                    {
                                                        showImg1 === true ?
                                                            <button type="submit" className='col-auto ms-auto  text-danger pt-0 mt-0 mb-1' onClick={() => setShowImg1(false)} ><RiDeleteBin5Line /></button> : ""
                                                    }

                                                    {
                                                        showShortLogo === true ?
                                                            <button type="submit" className='col-auto ms-auto  text-danger pt-0 mt-0 mb-1' onClick={() => setShowShortLogo(false)} ><RiDeleteBin5Line /></button> : ""

                                                    }

                                                </Row>


                                                {
                                                    showImg1 === true ?

                                                        <div>
                                                            <img width="400" height="400" className='mt-2 border' src={shortLogo} alt="company-logo" />
                                                           
                                                        </div>
                                                        :
                                                        showShortLogo === false ?
                                                            <div className='col form-group'>
                                                                <div className="drop-container dropzone">
                                                                    <p className="mt-3">

                                                                        <label className="upload-button ">
                                                                            <input type="file" className="mx-2" value={""} accept="image/*" onChange={onFileChange1}
                                                                            />  Drag a file here or browse to upload.
                                                                        </label>

                                                                    </p>

                                                                </div>
                                                                {ftypeValidate1===true? <p className='text-danger mb-0'>{fileValidation1}</p>:fileValidation1 !==""?<p className='text-danger'>{fileValidation1}</p> : ""}

                                                            </div> :
                                                            <div>
                                                                <img width="400" height="400" className='mt-2 border' src={fileBase64String1} alt="compango" />
                                                            </div>
                                                }
                    </div>
            </div>
            
            : ''  }

            {tabVal === 'Configuration' ? 
                <OnBoardConfig></OnBoardConfig>
            : '' }

            {tabVal === 'Theme' ? 

                    <Configuration></Configuration> 
            : '' }
        </div >
        </>

    );
}