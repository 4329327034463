import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  Button,
  Sidebar,
  DataTable,
  Column,
  InputSwitch,
  ConfirmPopup, 
  iconSet,
  IcomoonReact,
  BlockUI,
} from "../../PrimeReact";
import {
  AiOutlineSearch,
  AiFillDelete,
  AiOutlineUserAdd,
} from "react-icons/ai";
import GroupDetail from "./sub-modules/GroupDetail";
import { Tooltip } from "primereact/tooltip";
import SecurityService from "../../../services/security-service";
import GlobalVariable from "../../../services/globalVar";
import { ProgressSpinner } from "primereact/progressspinner";
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { SearchOutlined } from '@ant-design/icons';
import { DatePicker, Space, Input } from "antd";

export default function UserGroup() {
  const toast = useRef(null);
  const buttonEl = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [showGroup, setShowGroup] = useState(false);
  const [userDrawer, setUserDrawer] = useState(false);
  const [visiblePopup, setVisiblePopup] = useState(false);
  const ss = new SecurityService();
  const [first1, setFirst1] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [userGroupList, setUserGroupList] = useState("");
  const [userTypeDropDown, setUserTypeDropDown] = useState([]);
  const [selectUserTypeDropDown, setSelectUserTypeDropDown] = useState([]);
  const [userRoleDropDown, setUserRoleDropDown] = useState([]);
  const [selectedUserRoleDropDown, setSecectedUserRoleDropDown] = useState([]);
  const [inputSwitchchecked, setInputSwitchchecked] = useState(false);
  const [userTypeInputSwitchchecked, setUserTypeInputSwitchchecked] = useState(false);
 const [isUpdate, setIsUpdate] = useState(false); 
 const [isUpdateUserGroupId, setIsUpdateUserGroupId] = useState("");
 const [isLoading, setIsLoading] = useState(true);
 const [checkedStates, setCheckedStates] = useState(Array(userGroupList.length).fill(false));
 const [isUserGroupChecked, setisUserGroupChecked] = useState(true);
 const [isProcess, setIsProcess] = useState(false);
 const [changeStatusIsAsso, setChangeStatusIsAsso] = useState(false);

  const defaultValues = {
    usertype: "",
    userrole: "",
    usergroupname: "",
    usergroupstatus: "",
    description: "",
    toggleinput: true
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues });

  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });

  const GetEntUserGroupList = (
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder
  ) => {
    setIsProcess(true);
    const data = {
      UserGroupParams: {
        TenantId: GlobalVariable.userDet.tenantDetail.tenantID,
        UserTypeID: GlobalVariable.userDet.appUser.userTypeID,
        EntityID: GlobalVariable.userDet.tenantDetail.tenantID,
        UserGroupStatus: isUserGroupChecked ? "Active" : "inActive",
        EntityType: "Tenant",
      },
      ServerSearchables: {
        draw: 1,
        searchData: searchVal || "",
        orderByColumn: sortName,
        orderBy: sortOrder,
        currentPage: 0,
        pageSize: sizePerPage,
        pageNumber: page,
      },
      userGrpOrderLists: [],
    };
    ss.GetEntUserGroupList(data).then((res) => {
     setIsProcess(false);
      setIsLoading(false)
      setUserGroupList(res.data.data.userGrpOrderLists);
      setTotalRecords(res.data.data.serverSearchables.recordsTotal);
    });
  };

  const changeStatus = (e) => {
    setisUserGroupChecked(e.value)
    
  }

const reject = () => {
}

  const confirm = (e,items) => {
    setIsProcess(true);
    const datas = 
    {
        "TenantID" : items.tenantID,
        "UserGroupID" : items.userGroupID,
        "IsMembersList" : true,
        "RoleName":items.roleName,
        "EntityID":items.entityID,
        "EntityType":items.entityType
    }
    ss.GetEntUserGroupMembers(datas).then((res) => {
    setIsProcess(false);
    let sts = res.data.data.length
    setChangeStatusIsAsso(sts);
      confirmDialog({
        message: res.data.data.length ? 'This user group is associated with users, do you still want to delete?' : 'Do you want to change the status?',
        className:"pAdj deletecircle",
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        defaultfocus: 'accept',
        accept: () => toggleRow(e,items),
        reject
    });
    }).catch((err) => {
    })
   
};


  const toggleRow = (e,items) => {
   const data = {
      "UserGroupID" : items.userGroupID,
      "UserGroupStatus" : items.userGroupStatus === "Active" ? 'Inactive' : 'Active' ,
      "TenantID" : GlobalVariable.userDet.tenantDetail.tenantID,
      "IsAssocUser": changeStatusIsAsso ? false: true
  }
        ss.SwitchUserGroupStatus(data).then(res=>{
          console.log(res)
          toast.current.show({
            severity: "success",
            detail: res.data.message,
          });
          GetEntUserGroupList(page, sizePerPage, searchVal, "", "");
          setChangeStatusIsAsso(false)
        })
        
  };

  const toggleStatus = (e, rowData) => {
    e.stopPropagation();
    const row = [...userGroupList];
    row.forEach((x) => {
      if (x.userGroupID === rowData.userGroupID) x.isStatus = !x.isStatus;
    });
    setUserGroupList(row);
    confirm(e, rowData);
  };


  const renderStatusColumn = (e) => {
    return  statusBodyTemplate(e, toggleStatus);
  };

  const statusBodyTemplate = (rowData, toggleStatus) => {
    
    return (
      <>
       <Tooltip target=".kk" content="Active / Inactive user" position="top" />
        <InputSwitch
          className="toggleSwitch kk"
          checked={rowData.userGroupStatus === "Active" ? true : false}
          onChange={(e) => toggleStatus(e,rowData)}
        />
      </>
     
    );
  };


 

  const inputSwitchToggle = (checked, rowData) => {
    setInputSwitchchecked(checked);
    const data = {
      UserGroupID: rowData.userGroupID,
      UserGroupStatus: checked ? "Active" : "Inactive",
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
    };
  };

  const inputSwitchToggle1 = (e) => {
    setUserTypeInputSwitchchecked(e);
    // const data =
    // {
    //     "UserGroupID" : rowData.userGroupID,
    //     "UserGroupStatus" : e ? "Active" : "Inactive", 
    // }
    // console.log(data,'dadata');
  };

  const actionBodyTemplate1 = (rowData) => {
    return (
      <div className="d-flex align-items-center">
        <Tooltip target=".pointerr" content="Edit" position="left" />
        <Tooltip target=".icon-lock" content="Delete" position="top" />
        <Tooltip target=".pi-eye" content="View" position="right" />

        <IcomoonReact
          iconSet={iconSet}
          color="#444"
          size={20}
          icon="edit"
          className="pointerr"
          onClick={(e) => editData(e, rowData)}
        />
        <AiFillDelete
          className="pointer icon-lock ms-1 text-danger"
          onClick={(e) => deleteUserGroup(e,rowData)}
        />
        <i
          className="pi pi-eye ms-1"
          style={{ color: "#027D84", fontSize: "1.3rem" }}
          onClick={(e) => handleRowClick1(e)}
        ></i>
      </div>
    );
  };

  const deleteUserGroup = (e, rowData) => {
    e.stopPropagation();
    const data = {
      UserGroupID: rowData.userGroupID,
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      IsAvailable: true,
    };
    ss.DeleteUserGroup(data).then((res) => {
        if(res.data.statusCode === 200) {
            toast.current.show({
              severity: "success",
              detail: res.data.message,
            });
         GetEntUserGroupList(page, sizePerPage, searchVal, "", "");

      }
    })
  };

  const editData = (e, rowData) => {
    e.stopPropagation();
    setIsUpdateUserGroupId(rowData.userGroupID)
    setRowData(rowData);
    setIsUpdate(true);
    setUserDrawer(true);
    let bindUserTYpe = userTypeDropDown.find(
      (a) => a.userType === rowData.userType
    );
    setSelectUserTypeDropDown(bindUserTYpe);
    setValue("usertype", bindUserTYpe);
    setUserRoleDropDown(bindUserTYpe.roles);
    let bindUserRole = bindUserTYpe.roles.find(
      (a) => a.roleId === rowData.roleID
    );
    setSecectedUserRoleDropDown(bindUserRole);
    setValue("userrole", bindUserRole);
    setValue('toggleinput',rowData.userGroupStatus === 'Active' ? true : false)
    setValue("usergroupname", rowData.userGroup);
    setValue("description", rowData.userGroupDesc);
  };

  const handleRowClick = (e) => {
    setRowData(e.data);
    const clickedElement = e.originalEvent.target;
    const isLastColumn = clickedElement.closest(".action");

    if (isLastColumn) {
      e.originalEvent.stopPropagation();
      setShowGroup(false);
    } else {
      setShowGroup(true);
    }
  };

  const handleRowClick1 = (e) => {
    setRowData(e.data);
    const clickedElement = e.originalEvent.target;
    const isLastColumn = clickedElement.closest(".actionn");

    if (isLastColumn) {
      e.originalEvent.stopPropagation();
      setShowGroup(false);
    } else {
      setShowGroup(true);
    }
  };


  function showDescription(e){
    setRowData(e);
    // const clickedElement = e;
    // const isLastColumn = clickedElement.closest(".action");

    // if (isLastColumn) {
      // e.originalEvent.stopPropagation();
      // setShowGroup(false);
    // } else {
      setShowGroup(true);
    // }
  }

  const handleBackGroup = (item) => {
    setShowGroup(item);
  };
  const handleClose = () => {
    reset();
    setUserTypeDropDown("");
    setUserRoleDropDown("");
    setSelectUserTypeDropDown(null);
    setSecectedUserRoleDropDown(null);
    setUserDrawer(false);
    setIsUpdate(false);
    GetUserTypeList();
  };
  const renderHeader = () => {
    return (
      <div className="custom-sidebar-header d-flex py-2">
        <h4>Add/Update User</h4>
        <button
          onClick={handleClose}
          className="p-sidebar-close p-link ms-auto"
        >
          <span className="pi pi-times" />
        </button>
      </div>
    );
  };

  const onCustomPage1 = (event) => {
    setFirst1(event.first);
    const val = event.page + 1;
    const size = event.rows;
    setSizePerPage(size);
    setPage(val);
    GetEntUserGroupList(val, size, searchVal, "", "");
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const saveUserGroup = (data) => {
    const datas = {
      UserGroupID: isUpdateUserGroupId? isUpdateUserGroupId : 0,
      UserGroup: data.usergroupname,
      UserGroupDesc: data.description,
      UserGroupStatus: data.toggleinput ? "Active" : "Inactive",
      CreatedBy: GlobalVariable.userDet.appUser.appuserID,
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      UserTypeID: data.usertype.userTypeID,
      RoleID: data.userrole.roleId,
      EntityID: GlobalVariable.userDet.tenantDetail.tenantID,
      EntityType: "Tenant",
    };
    ss.CreateUserGroup(datas).then((res) => {
      if (res.data.statusCode === 200) {
        toast.current.show({
          severity: "success",
          detail: res.data.message,
        });
        setUserDrawer(false);
        setIsUpdate(false);
        GetEntUserGroupList(page, sizePerPage, searchVal, "", "");
      }
    });
  };

  const GetUserTypeList = () => {
    const data = {
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      UserTypeID: GlobalVariable.userDet.appUser.userTypeID,
      EntityID: GlobalVariable.userDet.tenantDetail.tenantID,
      EntityType: "Tenant",
    };
    ss.GetUserTypeList(data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setUserTypeDropDown(res.data.data);
        }
      })
      .catch((err) => console.log(err, "usertypelistErr"));
  };

  const handleDropDownSelectUserType = (e) => {
   setValue('userrole', '')
    setSelectUserTypeDropDown(e.value);
    setUserRoleDropDown(e.target.value);
    let findId = e.target.value.userTypeID;
    let findSubIds = e.target.value.roles.filter(
      (a) => findId === a.userTypeID
    );
    setUserRoleDropDown(findSubIds);
  };

  const handleDropDownSelectUserRole = (e) => {
    setSecectedUserRoleDropDown(e);
  };

  const onHideSidebar = () => {
    setUserDrawer(false);
    setIsUpdate(false);
    reset();
    setUserTypeDropDown("");
    setUserRoleDropDown("");
    setSelectUserTypeDropDown(null);
    setSecectedUserRoleDropDown(null);
    GetUserTypeList();
    // addUser();
  };

  const addUser = () => {
    setUserDrawer(true);
    setIsUpdateUserGroupId("")
    reset();
    setUserTypeDropDown("");
    setUserRoleDropDown("");
    // setSelectUserTypeDropDown(null);
    // setSecectedUserRoleDropDown(null);
    GetUserTypeList();
  };
  function changegroupStatus (e) {
      setValue('toggleinput',e.target.value)
  }

  const keySearch = (e) => {
    const searched = e.target.value;
    setSearchVal(searched);
    if (e.key === 'Enter') {
      setPage(1);
      GetEntUserGroupList(1, sizePerPage, searchVal, "", "");

        }
        
        if (e.type === 'keyup' && searched === '') {
          GetEntUserGroupList(1, sizePerPage, searchVal, "", "");

        }
}

const emptySearch = () => {
  setSearchVal('')
  GetEntUserGroupList(1, sizePerPage, searchVal, "", "");

}

  const [checked, setChecked] = useState(false);
  useEffect(() => {
    GetEntUserGroupList(page, sizePerPage, searchVal, "", "");
  }, [isUserGroupChecked])
  

  useLayoutEffect(() => {
    GetUserTypeList();
  }, [userRoleDropDown, userRoleDropDown]);

  useLayoutEffect(() => {
    GetEntUserGroupList(page, sizePerPage, searchVal, "", "");
  }, []);

  return (
    <div>
      <BlockUI blocked={isProcess}>
      <Toast ref={toast} />
      {showGroup ? (
        <GroupDetail
          data={{ handleBackGroup: handleBackGroup, rowData: rowData }}
        />
      ) : (
        <>
              {isProcess && <ProgressSpinner
                style={{
                  width: "40px",
                  height: "50px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                strokeWidth="4"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />}
          {!isLoading && <>
          <div className="row coa-header kk-header-sec">
            <h5 className="col"> List of User Groups</h5>
            <div className="col-auto header-right align-items-center ms-auto d-flex mb-2">
              <div className="col  border rounded my-auto ">
              <Tooltip target=".ch" content="Create new group" position="top" />
              <Space.Compact  className='my-auto'>
                        <Input addonBefore={<SearchOutlined />} allowClear={emptySearch}  placeholder="Search"    value={searchVal}
                      onChange={(e) => keySearch(e)}
                      onKeyUp={(e) => keySearch(e)} />
           </Space.Compact>
                
              </div>
              <Tooltip target=".tt" content="View Active / Inactive user" position="top" />

              <InputSwitch className="mx-2 toggleSwitch tt" checked={isUserGroupChecked} onChange={(e) => changeStatus(e)} />
              <AiOutlineUserAdd
                className="col-auto my-auto user-icon ch ms-2"
                onClick={addUser}
              />
            </div>
          </div>
         <DataTable
            className=" chofacc"
            // responsiveLayout="scroll"
            size="small"
            totalRecords={totalRecords}
            onRowClick={(e) => handleRowClick(e)}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            lazy
            paginator
            rowsPerPageOptions={[10, 20, 50]}
            first={first1}
            rows={sizePerPage}
            onPage={onCustomPage1}
            onSort={onSort}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            value={userGroupList}
            emptyMessage="No user group available"
          >
            <Column field="userGroup" header="Group Name" style={{width:"25%"}}></Column>
            <Column field="userType" header="User Type"></Column>
            <Column field="roleName" header="User Role"></Column>
            <Column field="userGroupDesc" header="Description"></Column>
            <Column
              field="statusBodyTemplate"
              header="Status"
              className="action"
              body={(e) => renderStatusColumn(e)}
            ></Column>
            <Column
              field="actionBodyTemplate1"
              header="Action"
              className="actionn"
              body={(e) => actionBodyTemplate1(e)}
            />
          </DataTable>
          </>}
          {isProcess && (
                    <ProgressSpinner
                      style={{
                        width: "50px",
                        height: "50px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  )}
          <ConfirmDialog />
        </>
      )}
</BlockUI>
      
      <Sidebar
        visible={userDrawer}
        position="right"
        className="security-drawer"
        onHide={onHideSidebar}
      >
        <div slot="header">{renderHeader()}</div>
        <form onSubmit={handleSubmit(saveUserGroup)}>
          <div className="mt-4 px-0">
            <div className="row mx-0 mb-3 ">
              <div className="form-group col">
                <label className="ps-0 required ">User Type</label>
                <Controller
                  name="usertype"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      style={{ width: "100%" }}
                      placeholder="Select an userType"
                      options={userTypeDropDown}
                      optionLabel="userType"
                      value={selectUserTypeDropDown}
                      onChange={(e) => {
                        field.onChange(e.value);
                        handleDropDownSelectUserType(e);
                      }}
                    />
                  )}
                />
                {errors.usertype && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="row mx-0 mb-3">
              <div className="form-group col">
                <label className="ps-0 required">User Role</label>
                <Controller
                  name="userrole"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      style={{ width: "100%" }}
                      placeholder="Select an user role"
                      options={userRoleDropDown}
                      optionLabel="roleName"
                      value={selectedUserRoleDropDown}
                      onChange={(e) => {
                        field.onChange(e.value);
                        handleDropDownSelectUserRole(e.value);
                      }}
                    />
                  )}
                />
                {errors.userrole && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="row mx-0 mb-3">
              <div className="form-group col">
                <label className="ps-0 required">User Group Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="usergroupname"
                  {...register("usergroupname", { required: true })}
                />
                {errors.usergroupname && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col my-auto d-flex align-center justify-content-between pt-3">
                <label className="ps-0">User Group Status</label>

                <Controller
                    name="toggleinput"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                    
                          <InputSwitch className="toggleSwitch"  checked={getValues('toggleinput')}  onChange={(e) => setValue('toggleinput',e.target.value)}  />
                       
                    )}
                  />
              </div>
            </div>
            <div className="row mx-0">
              <div className="col form-group">
                <label className="ps-0 ">Description</label>
                <textarea
                  className="form-control"
                  name="description"
                  {...register("description")}
                />
              </div>
            </div>
          </div>
          <div className="text-end mt-3 px-2">
            <Button
              type="button"
              label="Cancel"
              icon="pi pi-times"
              onClick={handleClose}
              className="p-button-text btn-cancel ms-auto btn btn-danger me-1"
            />
            {!isUpdate && <Button
              type="submit"
              label="Save"
              icon="pi pi-check"
              autoFocus
              className="btn btn-primary"
            />}
            { isUpdate && <Button
              type="submit"
              label="Update"
              icon="pi pi-check"
              autoFocus
              className="btn btn-primary"
            />}
          </div>
        </form>
      </Sidebar>
    </div>
  );
}
