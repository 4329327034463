import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import classNames from "./classNames";
import GV from "../../services/globalVar";
import PettycashServiceService from "../../services/pettycash-service";
import {
  DataTable,
  Column,
  Dropdown,
  InputText,
  MultiSelect,
  iconSet,
  IcomoonReact,
  Checkbox,
  Dialog,
  Tooltip,
} from "../PrimeReact.js";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import NewPettyCash from "./NewPettyCash";
import CashTopup from "./CashTopup";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import { useNavigate } from "react-router";

export default function PettyCash({ showDetailsHandle }) {
  const [date, setDate] = useState(new Date());
  const [currentdate, setcurrentdate] = useState(new Date());
  const [dateSelected, setDateSelected] = useState(new Date());
  const navigate = useNavigate();
  // var first = currentdate.getDate() - currentdate.getDay(); // First day is the day of the month - the day of the week
  // var two = first + 1;
  // var three = first + 2;
  // var four = first + 3;
  // var five = first + 4;
  // var six = first + 5;
  // var seven = first + 6;

  // var firstday = new Date(currentdate.setDate(first)).toUTCString();
  // var secondday = new Date(currentdate.setDate(two)).toUTCString();
  // var thirdday = new Date(currentdate.setDate(three)).toUTCString();
  // var fourthday = new Date(currentdate.setDate(four)).toUTCString();
  // var fifthday = new Date(currentdate.setDate(five)).toUTCString();
  // var sixthday = new Date(currentdate.setDate(six)).toUTCString();
  // var seventhday = new Date(currentdate.setDate(seven)).toUTCString();
  const [gridMessage, setGridMessage] = useState("");
  const [weekdays, setweekdays] = useState([]);
  const modalref = useRef(null);
  const modalrefone = useRef(null);
  const pts = new PettycashServiceService();
  const [pettycash, setPettycash] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const [tdataLoad, setTdataLoad] = useState(false);
  const [data, setData] = useState([]);
  const [totalRecordsIn, setTotalRecordsIn] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [first1, setFirst1] = useState(0);
  const [pettyDetails, setPettyDetails] = useState();
  const [visibleDetail, setVisibleDetail] = useState();
  const [detailsTrans, setDetailsTrans] = useState();
  const [itemId, setItemId] = useState("");
  const panelHeaderTemplate = () => {
    return (
      <div className="pt-2 ps-4">
        {/* <p className='mb-0 fs-6'>Select</p> */}
      </div>
    );
  };
  const columns = [
    { field: "piDescription", header: "Purchase Description" },

    { field: "status", header: "Status" },
    { field: "itemType", header: "Type" },
  ];
  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const onSelect = (val) => {
    if (!val.isfuture) {
      const dayValues = weekdays;
      dayValues.forEach((x, i) => {
        if (x.date === val.date) {
          x.value = true;
        } else {
          x.value = false;
        }
      });
      setweekdays(dayValues);
      setDateSelected(val.fulldate);
      // setweekdays(weekdays => weekdays.map((x, i) => x.date  === val ? x.value = true : x.value = false));
      fetchItemList(page, sizePerPage, searchVal, "", "", val.fulldate);
    }
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {
          pettyDetails?.status !== "Week Closed" &&
          pettyDetails?.status !== "Month Closed" ? (
            <IcomoonReact
              iconSet={iconSet}
              color="#444"
              size={20}
              icon="edit"
              className="pointer"
              onClick={() => editProduct(rowData)}
            />
          ) : (
            <i
              className="pi pi-eye"
              style={{ color: "slateblue" }}
              onClick={() => {
                setVisibleDetail(true);
                setDetailsTrans(rowData);
              }}
            ></i>
          )
          //  <IcomoonReact iconSet={iconSet} color="#444" size={20} icon="pi-eye" className='pointer' onClick={() => editProduct(rowData)} />
        }
      </React.Fragment>
    );
  };
  const gstCont = (rowData) => {
    console.log(rowData, "row");
    return (
      <div>
        {/* {rowData.isGST ? <i className="pi pi-check-square"></i>: <Checkbox  checked={rowData.isGST}></Checkbox>}  */}
        <Checkbox checked={rowData.isGST} disabled></Checkbox>
      </div>
    );
  };

  const dynamicColumns = selectedColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={col.sortable}
        body={col.body && actionBodyTemplate}
      ></Column>
    );
  });

  const onCustomPage1 = (event) => {
    setFirst1(event.first);
    const val = event.page + 1;
    const size = event.rows;
    setSizePerPage(size);
    setPage(val);
    fetchItemList(val, size, searchVal, "", "", dateSelected);
  };
  //sort start
  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });
  const onSort = (event) => {
    setLazyParams(event);
  };
  //sort end
  function fetchItemList(
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder,
    date
  ) {
    // setTdataLoad(true);
    console.log(weekdays, "WEeek");
    setTdataLoad(false);
    let data = {
      InputParams: {
        TenantID: GV.userDet.tenantDetail.tenantID,
        TransactionDate: format(date, "yyyy-MM-dd"),
      },
      ServerSearchables: {
        draw: 1,
        searchData: searchVal || "",
        orderByColumn: sortName,
        orderBy: sortOrder,
        currentPage: page,
        pageSize: sizePerPage,
        pageNumber: page,
      },
    };
    pts
      .GetPettyCashListSearch(data)
      .then((response) => {
        // if (isMounted) "Successfully retrieved Items" "Failed to retrieve Items" {
        console.log(response.data.message, "one");
        if (response.data.message != "Day does not opened") {
          if (response.data) {
            console.log("POD");
            const grid = response.data.data.pettyCashList.transactionList;
            setGridMessage(response.data.message);
            // if (response.data.statusCode ===200) {
            console.log("xyz", response.data.data);
            // if(response.data.message !== 'Data Retrived'){
            //  }
            setTotalRecordsIn(
              response.data.data.serverSearchables.recordsTotal
            );
            setData(grid);
            setPettyDetails(response.data.data.pettyCashList);
            // setRequestData(response.data.data.itemsList);
            //   setData(requestData.slice(0,sizePerPage));
            setTdataLoad(false);

            // }
            // else if (response.data.statusCode ===500 ) {

            //     setData([]);
            //     // setRequestData([]);
            //     setTotalRecordsIn(0);
            // }
            console.log(gridMessage, "pool");
            // }
          }
        } else {
          setTotalRecordsIn(response.data.data.serverSearchables.recordsTotal);
          setGridMessage(response.data.message);
          setData([]);
          setPettyDetails([]);
        }
        // if (response.data) {
        //     const grid = response.data.data.itemsList;
        //     setTotalRecordsIn(response.data.data.serverSearchables.recordsTotal);
        //     setData(grid);
        //     setRequestData(response.data.data.itemsList);
        //     setTdataLoad(false);
        // }
      })
      .catch(function (error) {
        // if (error.data.statusCode === 500 ) {
        //     setData([]);
        //     // setRequestData([]);
        //     setTotalRecordsIn(0);
        // }
      });
  }

  //start
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());

  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
      const month = subWeeks(currentMonth, 1);
      renderCells(month);
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
      const month = addWeeks(currentMonth, 1);
      renderCells(month);
    }
  };

  const renderCells = (mon) => {
    if (weekdays.length != 0) {
      for (let i = 0; i < 7; i++) {
        weekdays.pop();
      }
    }
    const startDate = startOfWeek(mon, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(mon, { weekStartsOn: 1 });
    const dateFormat = "EEE, dd MMMM yyyy";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const oneday = {
          id: i,
          fulldate: day,
          day: format(new Date(day), "EEE"),
          date: format(new Date(day), "dd"),
          month: format(new Date(day), "MMM"),
          value:
            format(new Date(), dateFormat) === formattedDate ? true : false,
          isfuture: new Date(formattedDate) > new Date() ? true : false,
        };
        weekdays.push(oneday);
        day = addDays(day, 1);
      }
    }
    let count = 0;
    let dayin = {};
    weekdays.forEach((x) => {
      if (x.value === false) {
        count = count + 1;
      } else {
        dayin = x;
      }
    });
    const next = weekdays;
    if (count === 7) {
      if (!next[0].isfuture) {
        next[0].value = true;
        setSelectedDate(next[0].fulldate);
        setDateSelected(next[0].fulldate);
        setweekdays(next);
        fetchItemList(page, sizePerPage, searchVal, "", "", next[0].fulldate);
      } else {
        setweekdays(next);
        setDateSelected(next[0].fulldate);
        fetchItemList(page, sizePerPage, searchVal, "", "", next[0].fulldate);
      }
    } else {
      setDateSelected(dayin.fulldate);
      fetchItemList(page, sizePerPage, searchVal, "", "", dayin.fulldate);
    }
  };

  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
    showDetailsHandle(dayStr);
  };

  //end

  const parentFunction = () => {
    // setDateSelected(weekdays.forEach((x, i) => x.value  === true ? new Date() : new Date()));
    fetchItemList(
      page,
      sizePerPage,
      searchVal,
      "",
      "",
      dateSelected || new Date()
    );
    console.log("PPO");
  };
  const accept = () => {
    const data = {
      CloseType: "Week", //"Month"
      CloseDate: format(weekdays[6].fulldate, "yyyy-MM-dd"),
      TenantID: GV.userDet.tenantDetail.tenantID,
      CreatedBy: GV.userDet.appUser.appuserID.toString(),
    };
    pts
      .ClosePettyCash(data)
      .then((response) => {
        console.log(response);
        fetchItemList(
          page,
          sizePerPage,
          searchVal,
          "",
          "",
          new Date(dateSelected)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const reject = () => {
    console.log("no");
  };
  const closeWeek = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure to close the week?",
      icon: "pi pi-info-circle",
      // acceptClassName: 'p-button-danger',
      accept,
      reject,
    });
  };

  const editProduct = (product) => {
    setItemId(product?.transactionID);
    modalref.current.alertToggledit(product);
    console.log("setItemId", itemId);
  };

  const header = (
    <div className="d-flex justify-content-between flex-wrap">
      <div className="text-t-primary  kk-table-header kk-header-sec row">
        <p className="customFont mb-0">
          <span className="customFont mb-0">
            <span className="cc-text-primary">Weekly Topup Amount :</span>{" "}
            {GV.userDet.tenantConfig.currency} {pettyDetails?.topupAmount}
          </span>{" "}
          &nbsp; &nbsp;{" "}
          <span className="customFont mb-0">
            <span className="cc-text-primary">Balance Amount:</span>{" "}
            {GV.userDet.tenantConfig.currency} {pettyDetails?.closingBalance}
          </span>{" "}
          &nbsp; &nbsp;{" "}
          <span className="customFont mb-0">
            <span className="cc-text-primary">Weekly Expense:</span>{" "}
            {GV.userDet.tenantConfig.currency} {pettyDetails?.expence}
          </span>{" "}
        </p>
      </div>
      <div className="my-auto">
        {/* <span onClick={() => { modalref.current.alertToggle(); setItemId(0)}}><i className='pi pi-plus ' ></i> <br/> New Item</span>  */}
        {gridMessage !== "Day does not opened" &&
        pettyDetails?.status !== "Week Closed" &&
        pettyDetails?.status !== "Month Closed" ? (
          <span>
            <button
              className="btn btn-primary py-1 me-2 "
              onClick={() => {
                modalref.current.alertToggle();
                setItemId(0);
              }}
            >
              {" "}
              <i className="pi pi-plus"></i> New Item
            </button>
            <button
              className="btn btn-primary py-1 me-2 "
              onClick={() => {
                modalrefone.current.alertToggle(pettyDetails);
              }}
            >
              {" "}
              <i className="pi pi-plus"></i> Cash Topup
            </button>
          </span>
        ) : gridMessage === "Day does not opened" ? (
          <span>Please check Previous week status</span>
        ) : pettyDetails?.status === "Week Closed" ||
          pettyDetails?.status === "Month Closed" ? (
          <span>Week is closed</span>
        ) : (
          <span></span>
        )}
        {/* <MultiSelect  value={selectedColumns} options={columns} optionLabel="header" optionDisabled="disabled" onChange={onColumnToggle} style={{ width: '3em' }} scrollHeight="400px" dropdownIcon="pi pi-table"
                    tooltip="Custom Columns" tooltipOptions={{ position: 'top' }} panelHeaderTemplate={panelHeaderTemplate} /> */}
      </div>
    </div>
  );
  const amountbody = (data) => {
    return GV.userDet.tenantConfig.currency + " " + data.amountPaid.toFixed(2);
  };
  const balancebody = (data) => {
    return (
      GV.userDet.tenantConfig.currency + " " + data.balanceAmount.toFixed(2)
    );
  };
  const report = () => {
    navigate("/pettycash-report");
  };
  const weekviewReport = () => {
    navigate("/pettycash-weekview");
  };
  useLayoutEffect(() => {
    console.log(dateSelected, "DATEIN");
    // getDayDetails();
    // setDateSelected(weekdays.forEach((x, i) => x.value  === true ? new Date() : new Date()));
    renderCells(currentMonth);
    fetchItemList(page, sizePerPage, searchVal, "", "", new Date());
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(()=>{
  //     getList();
  // })
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-borderless cc-greyCard-custm p-2">
            <div className="row d-flex">
              <div className="col-md-3 col-sm-12 ">
                <h4 className="m-0">Petty Cash</h4>
              </div>
              
              <div className="col-md-9 col-sm-12 d-flex align-items-center justify-content-end">
              
              <p className=" customFont mb-0">
                  {" "}
                  <span className="cc-fixed">
                    {" "}
                    Week Opening Balance :{" "}
                    <b>
                      {" "}
                      {GV.userDet.tenantConfig.currency}{" "}
                      {pettyDetails?.openingBalance}{" "}
                    </b>
                  </span>{" "}
                  
                </p>
              <span
                    className="ms-3 border-btn"
                    onClick={() => changeWeekHandle("prev")}
                  >
                    {" "}
                    Previous Week{" "}
                  </span>{" "}
                  <span
                    className="ms-3 border-btn"
                    onClick={() => changeWeekHandle("next")}
                  >
                    {" "}
                    Next Week{" "}
                  </span>
                  <Tooltip target=".custom-edit-icon" />
                <Tooltip target=".custom-calen-icon" />
                  <i
                    className=" pi pi-file-excel mx-2 custom-edit-icon"
                    data-pr-tooltip="Petty Cash Report"
                    data-pr-position="bottom"
                    style={{ color: "#0874df", fontSize: "20px" }}
                    onClick={report}
                  ></i>
                  <i
                    className=" pi pi-calendar  custom-calen-icon"
                    data-pr-tooltip="Petty Cash Week View"
                    data-pr-position="bottom"
                    style={{ color: "#1e6444", fontSize: "20px" }}
                    onClick={weekviewReport}
                  ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 row ml-3 ps-4">
        {/* <i className='pi pi-angle-left'></i> */}
        {weekdays.map((item, i) => {
          return (
            // <div style={{marginRight:'10px'}}>
            // <div>
            // <div className={classNames('days', item.value && 'daysDisable',item.isfuture  && 'daysSelected' )}  onClick={() => onSelect(item)}>
            <div
              className={`days ${item.isfuture ? "daysDisable" : ""} ${
                item.value ? "daysSelected" : ""
              }`}
              onClick={() => onSelect(item)}
            >
              <div className="liClasses" onClick={() => onSelect(item)}>
                <div className=" row">
                  <div className="col-sm-4 day">{item.day}</div>
                  <div className="col-sm-8 date">
                    <span className="d-flex justify-content-center ">
                      {" "}
                      {item.month}{" "}
                    </span>{" "}
                    <span className="d-flex justify-content-center ">
                      {" "}
                      <b> {item.date}</b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            // </div>
          );
        })}
      </div>
      <div className="card card-borderless">
        <div className="strip"></div>
        <div className="card-body">
          <div className="account-table">
            <DataTable
              stripedRows
              paginator
              value={data}
              header={header}
              responsiveLayout="scroll"
              size="small"
              loading={tdataLoad}
              lazy
              totalRecords={totalRecordsIn}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              emptyMessage={gridMessage}
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rowsPerPageOptions={[10, 20, 50, totalRecordsIn]}
              first={first1}
              rows={sizePerPage}
              onPage={onCustomPage1}
              onSort={onSort}
              sortField={lazyParams.sortField}
              sortOrder={lazyParams.sortOrder}
            >
              <Column field="transaction" header="Transaction" />
              <Column field="transactionCode" header="Code" />
              <Column field="receiver" header="Receiver" />
              <Column
                field="amountPaid"
                header="Amount Paid"
                body={amountbody}
                className="text-end amount-align"
              />
              <Column field="isGST" header="GST" body={gstCont} />
              <Column field="description" header="Description" />
              <Column
                field="balanceAmount"
                header="Balance"
                body={balancebody}
                className="text-end amount-align"
              />
              {/* {dynamicColumns} */}
              <Column
                field="actionBodyTemplate"
                header="Action"
                body={actionBodyTemplate}
              />
            </DataTable>
            <ConfirmPopup />
            <div className="d-flex justify-content-end mt-2">
              {weekdays.length !== 0 ? (
                pettyDetails?.status !== "Week Closed" &&
                pettyDetails?.status !== "Month Closed" &&
                gridMessage !== "Day does not opened" ? (
                  <button
                    className="btn btn-danger py-1 me-2 "
                    onClick={closeWeek}
                  >
                    {" "}
                    Close Week
                  </button>
                ) : (
                  <p></p>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        header="Transaction Detail"
        visible={visibleDetail}
        style={{ width: "50vw" }}
        onHide={() => setVisibleDetail(false)}
      >
        <div className="row mt-3">
          <div className="col-md-2">
            <b>Transaction : </b> <br />
            <b>Code : </b>
            <br />
            <b>Receiver : </b>
            <br />
            <b>Amount Paid : </b>
            <br />
            <b>GST : </b> <br />
            <b>Voucher Code : </b> <br />
            <b>Description : </b> <br />
          </div>
          <div className="col-md-8">
            {detailsTrans?.transaction} <br />
            {detailsTrans?.transactionCode} <br />
            {detailsTrans?.receiver} <br />
            {detailsTrans?.amountPaid} <br />
            {detailsTrans?.isGST === true ? (
              <i className="pi pi-check"></i>
            ) : (
              <i className="pi pi-times"></i>
            )}{" "}
            <br />
            {detailsTrans?.voucher} <br />
            {detailsTrans?.description} <br />
          </div>
        </div>
      </Dialog>
      <NewPettyCash
        ref={modalref}
        parentFunction={parentFunction}
        data={{ itemId: itemId, dateSelected: dateSelected }}
      />

      <CashTopup
        ref={modalrefone}
        parentFunction={parentFunction}
        data={{ pettyDetails: pettyDetails, dateSelected: dateSelected }}
      />
    </div>
  );
}
