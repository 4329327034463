import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import InvoiceService from "../../../../client/src/services/invoice-service";
import GlobalVariable from "../../../../client/src/services/globalVar";
// import { Tooltip } from "primereact/tooltip";
import { Toast } from "../PrimeReact";
import { Controller, useForm } from "react-hook-form";
import { Button as Primebutton } from "primereact/button";
// import { ConfirmPopup } from "../PrimeReact";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ConfirmPopup } from "primereact/confirmpopup";
import { BlockUI } from "primereact/blockui";
import { Document, Page } from "react-pdf";
import { ProgressSpinner } from "primereact/progressspinner";
import { format } from "date-fns";
import { Tooltip, Tag, Input, Space, Alert } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import GV from "../../services/globalVar";
import { Dropdown as Primedropdown } from "primereact/dropdown";
import { Empty } from "antd";
import purchaseService from "../../services/purchase-sevice";
import { Button, Select } from "antd";
import { FileUpload } from "primereact/fileupload";

const DebitNoteForm = (props) => {
  const rowDatas = props.data.rowData;
  console.log(rowDatas, "rowDatas");
  const is = new InvoiceService();
  const ps = new purchaseService();
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const paymodal = useRef(null);
  const [payment, setPayment] = useState("");
  const [invoiceDateValue, setInvoiceDateValue] = useState(null);
  const [isNewvoice, setIsNewvoice] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const inputRef = useRef(null);
  const [quantityValue, setQuantityValue] = useState();
  const [discountValue, setDiscountValue] = useState(0);
  const [netAmountValue, setNetAmountValue] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState("0.00");
  const [subTotal, setSubTotal] = useState(0);
  const [paymentDueDateCount, setPaymentDueDateCount] = useState(null);
  const [productDrop, setProductDrop] = useState([]);
  const [selectProductDrop, setSelectProductDrop] = useState([]);
  const [itemType, setItemType] = useState();
  const [productRate, setProductRate] = useState();
  const [isType, setIsType] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [shipCharge, setShipCharge] = useState(0);
  const [manualErr, setManualErr] = useState(false);
  const [isCustChoose, setIsCustChoose] = useState(true);
  const [isDraft, setIsDraft] = useState(false);
  const [tenantDetails, setTenantDetails] = useState({});
  const [selectReturnDate, setSelectReturnDate] = useState();
  const [purchaseDate, setPurchaseDate] = useState(new Date());
  const [sgstTax, setSgstTax] = useState(0);
  const [cgstTax, setCgstTax] = useState(0);
  const [igstTax, setIgstTax] = useState(0);
  const [roundOff, setRoundOff] = useState(0);
  const [sgstTaxAmount, setSgstTaxAmount] = useState(0);
  const [cgstTaxAmount, setCgstTaxAmount] = useState(0);
  const [igstTaxAmount, setIgstTaxAmount] = useState(0);
  const [countryTaxDetails, setCountryTaxDetails] = useState({});
  const [isProcess, setIsProcess] = useState(false);
  const [isDiscountDisabled, setIsDiscountDisabled] = useState(true);
  const [docUrl, setDocUrl] = useState("");
  const [showDocument, setShowDocument] = useState(false);
  const [documentURL, setDocumentURL] = useState("");
  const [documentURLType, setDocumentURLType] = useState("");
  const [editable, setEditable] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(false);
  const [selectVendorNameList, setSelectVendorNameList] = useState();
  const [vendorNameList, setVendorNameList] = useState([]);
  const [returnReson, setReturnReson] = useState();
  const [uploadedFiles, setUploadedFiles] = useState(null);

  const toast = useRef(null);

  const defaultValues = {
    billto: "",
    // invoicedate: "",
    invoicenumber: "",
    purchasedate: "",
    paymentduedate: "",
    debitnotenumber: "",
    returnreason: "",
    invNum: "",
  };

  const {
    register,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange", defaultValues });

  const [invoiceLines, setInvoiceLines] = useState([
    {
      id: 1,
      productname: "",
      quantity: 0,
      rate: "",
      discount: 0,
      statetax: 0,
      centraltax: 0,
      intertax: 0,
      returnreason: "",
      netamount: "",
      producttype: "",
      shippngcharge: "",
    },
  ]);

  const clearFields = () => {
    setInvoiceLines([
      {
        id: 1,
        productname: "",
        quantity: "",
        rate: "",
        discount: "",
        statetax: "",
        centraltax: "",
        intertax: "",
        netamount: "",
        returnreason: "",
        producttype: "",
        shippngcharge: "",
      },
    ]);
  };

  const GetSalesItemList = () => {
    setIsProcess(true);
    const debitId = rowDatas
      ? Object.keys(rowDatas)?.includes("debitNoteID")
      : false;

    return new Promise((resolve, reject) => {
      is.GetSalesItemList(GlobalVariable.userDet.tenantDetail.tenantID)
        .then((res) => {
          setIsProcess(false);
          if (!debitId) {
            if (res.data.statusCode === 200) {
              let antDropdn = res.data.data.map((item) => ({
                label: item.name,
                value: item.itemID,
                selectedDates: item,
              }));
              setProductDrop(antDropdn);
              resolve(res.data.data);
              let structObj = rowDatas?.purchaseInstance.filter(
                (a) => !a.itemID
              );
              if (structObj && structObj.length > 0) {
                let newItems = structObj.map((item) => ({
                  label: item.itemName,
                  value: item.itemID,
                  selectedDates: item,
                }));
                // console.log(newItems,'newItems')
                setProductDrop((items) => [...items, ...newItems]);
                antDropdn = [...antDropdn, ...newItems];
              }
              setProductDrop(antDropdn);
              let getVals = rowDatas?.purchaseInstance.map((a) => ({
                label: a.itemName,
                value: a.itemID,
                selectedDates: a,
              }));

              const productDetails = getVals.map((a, i) => ({
                id: i + 1,
                productname: antDropdn.find((x) => x.value === a.value) || "",
                quantity: a.selectedDates.quantity || "",
                rate: a.selectedDates.rate || "",
                discount: a.selectedDates.discount || 0,
                statetax: a.selectedDates.localTaxRate || 0,
                centraltax: a.selectedDates.centralTaxRate || 0,
                intertax: a.selectedDates.interTaxRate || 0,
                netamount: a.selectedDates.totalAmount?.toFixed(2) || "",
                producttype: a.selectedDates.itemType || "",
                returnreason: a.selectedDates.reason || "",
                salesItemID: a.selectedDates.salesItemID || 0,
              }));
              setInvoiceLines(productDetails);
            }
          } else {
            if (res.data.statusCode === 200) {
              let antDropdn = res.data.data.map((item) => ({
                label: item.name,
                value: item.itemID,
                selectedDates: item,
              }));
              setProductDrop(antDropdn);
              resolve(res.data.data);
              let structObj = rowDatas?.purchaseReturnItemList.filter(
                (a) => !a.itemID
              );
              if (structObj && structObj.length > 0) {
                let newItems = structObj.map((item) => ({
                  label: item.itemName,
                  value: item.itemID,
                  selectedDates: item,
                }));
                // console.log(newItems,'newItems')
                setProductDrop((items) => [...items, ...newItems]);
                antDropdn = [...antDropdn, ...newItems];
              }
              setProductDrop(antDropdn);
              let getVals = rowDatas?.purchaseReturnItemList.map((a) => ({
                label: a.itemName,
                value: a.itemID,
                selectedDates: a,
              }));

              const productDetails = getVals.map((a, i) => ({
                id: i + 1,
                productname: antDropdn.find((x) => x.value === a.value) || "",
                quantity: a.selectedDates.purchaseQuantity || "",
                rate: a.selectedDates.rate || "",
                discount: a.selectedDates.discount || 0,
                statetax: a.selectedDates.localTaxRate || 0,
                centraltax: a.selectedDates.centralTaxRate || 0,
                intertax: a.selectedDates.interTaxRate || 0,
                netamount: a.selectedDates.totalAmount?.toFixed(2) || "",
                returnreason: a.selectedDates.reason || "",
                producttype: a.selectedDates.itemType || "",
                salesItemID: a.selectedDates.salesItemID || 0,
                purchaseRtnId: a.selectedDates.purchaseReturnItemID || 0,
              }));
              setInvoiceLines(productDetails);
            }
          }
        })
        .catch((error) => {
          setIsProcess(false);
          reject(error);
        });
    });
  };

  const backToDebit = () => {
    props.data.backToDebitNote(true);
    setIsType(false);
    setIsUpdate(false);
    reset(defaultValues);
    setPurchaseDate(new Date());
    clearFields();
    setIsNewvoice(!isNewvoice);
    setIsCustChoose(true);
    setNetAmountValue("");
    setInvoiceDateValue("");
    setPaymentDueDateCount("");
    setSelectProductDrop("");
    setItemType("");
    setProductRate("");
    setSubTotal(0);
    setDiscountValue(0);
    setSgstTax(0);
    setCgstTax(0);
    setIgstTax(0);
    setRoundOff(0);
    setNetAmountValue(0);
    setShipCharge(0);
    setCgstTaxAmount(0);
    setSgstTaxAmount(0);
    setIgstTaxAmount(0);
    setDiscountedAmount(0);
    GenerateDebitNoteConfigNumber();
    clearUploadedFile();
  };

  const handleSelectEstimateDateSelect = (e) => {
    setPurchaseDate(e.target.value);
  };

  const [editModeData, setEditModeData] = useState({});

  const vendorNameOnchange = (e) => {
    setIsCustChoose(false);
    setSelectVendorNameList(e);
  };

  const GetContactFilteredList = () => {
    setIsProcess(true);
    const debitId = Object.keys(rowDatas)?.includes("debitNoteID");
    if (!debitId) {
      const data = {
        TenantID: GV.userDet.tenantDetail.tenantID,
        ContactType: "Vendor",
        SearchData: "",
      };
      ps.GetContactFilteredList(data).then((res) => {
        const contactName = res.data.data.map((a) => {
          return a;
        });
        setVendorNameList(contactName);
        setIsType(true);
        setIsCustChoose(false);
        let bindvendor = res.data.data.find(
          (a) => a.firstName === rowDatas.firstName
        );
        setSelectVendorNameList(bindvendor);
        setValue("billto", bindvendor);
        setValue("referencenumber", rowDatas.referenceNo);
        const iDate1 = new Date(rowDatas.purchaseDate);
        setPurchaseDate(iDate1);
        setValue("purchasedate", iDate1);
        setValue("purchasedate", iDate1);

        // setSelectExpectedDate(deliveryDate);
        setValue("deliveryterms", rowDatas.deliveryTerm);
        setValue("invNum", rowDatas.invoiceNo);
        setSubTotal(rowDatas.subTotal);
        setSgstTax(rowDatas.localTaxRate);
        setCgstTax(rowDatas.centralTaxRate);
        setIgstTax(rowDatas.interTaxRate);
        setNetAmountValue(rowDatas.totalAmount);
        setSgstTaxAmount(rowDatas.localTaxAmount);
        setCgstTaxAmount(rowDatas.centralTaxAmount);
        setIgstTaxAmount(rowDatas.interTaxAmount);
      });
    } else {
      const data = {
        TenantID: GV.userDet.tenantDetail.tenantID,
        ContactType: "Vendor",
        SearchData: "",
      };
      ps.GetContactFilteredList(data).then((res) => {
        const contactName = res.data.data.map((a) => {
          return a;
        });
        setVendorNameList(contactName);
        setIsType(true);
        setIsCustChoose(false);
        let bindvendor = res.data.data.find(
          (a) => a.firstName === rowDatas.firstName
        );
        setSelectVendorNameList(bindvendor);
        setValue("billto", bindvendor);
        setValue("referencenumber", rowDatas.referenceNo);

        const iDate1 = new Date(rowDatas.purchaseDate);
        setPurchaseDate(iDate1);
        setValue("purchasedate", iDate1);

        const iDate2 = new Date(rowDatas.returnDate);
        setValue("returndate", iDate2);
        setSelectReturnDate(iDate2);
        // setSelectExpectedDate(deliveryDate);
        setValue("deliveryterms", rowDatas.deliveryTerm);
        setValue("invNum", rowDatas.invoiceNo);
        setSubTotal(rowDatas.subTotal);
        setSgstTax(rowDatas.localTaxRate);
        setCgstTax(rowDatas.centralTaxRate);
        setIgstTax(rowDatas.interTaxRate);
        setNetAmountValue(rowDatas.totalAmount);
        setSgstTaxAmount(rowDatas.localTaxAmount);
        setCgstTaxAmount(rowDatas.centralTaxAmount);
        setIgstTaxAmount(rowDatas.interTaxAmount);
      });
    }
  };

  const onHide = () => {
    setShowDocument(false);
    // setDocUrl("");
  };
  const proceedPay = (data, page) => {
    data.page = page;
    setPayment(data);
    paymodal.current.alertToggle(data);
  };

  const GenerateDebitNoteConfigNumber = () => {
    const datas = {
      TenantID: GV.userDet.tenantDetail.tenantID,
      ServiceName: "DebitNote",
    };
    ps.GenerateDebitNoteConfigNumber(datas).then((res) => {
      setIsProcess(false);
      setInvoiceNumber(rowDatas ? rowDatas.debitNoteNumber : res.data.data);
    });
  };

  const onInvalid = (errors) => console.error(errors);

  function formatDate(date) {
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    // Ensure month and day are two digits
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }

  const onSubmit = (data, status) => {
    setIsProcess(true);
    const iDate1 = new Date(rowDatas.purchaseDate);
    const formattedEstimatedDate = iDate1.toISOString().split("T")[0];
    const iDate2 = new Date(selectReturnDate);
    const formattedExpireDate = iDate2.toISOString().split("T")[0];

    const InputJson = {
      DebitNoteID: rowDatas.debitNoteID || 0,
      DebitNoteNumber: invoiceNumber,
      PurchaseID: rowDatas.purchaseID,
      PurchaseCode: rowDatas.purchaseCode,
      InvoiceNo: rowDatas.invoiceNo,
      PurchaseDate: formattedEstimatedDate,
      ReturnDate: formattedExpireDate,
      VendorID: selectVendorNameList?.contactID,
      VendorGSTIN: rowDatas.vendorGSTIN,
      CompanyName: selectVendorNameList?.companyName,
      FirstName: selectVendorNameList?.firstName,
      LastName: selectVendorNameList?.lastName,
      Address1: selectVendorNameList?.baAddress1,
      Address2: selectVendorNameList?.baAddress2,
      City: selectVendorNameList?.baCity,
      State: selectVendorNameList?.baState,
      ZipCode: selectVendorNameList?.baZipCode,
      Country: selectVendorNameList?.baCountry,
      phone: selectVendorNameList?.phone,
      email: selectVendorNameList?.email,
      Fax: selectVendorNameList?.baFax,
      SubTotal: subTotal,
      DiscountType: "Percentage",
      DiscountRate: discountValue,
      DiscountAmount: discountedAmount,
      LocalTaxName: countryTaxDetails.localTaxName,
      LocalTaxRate: sgstTax,
      LocalTaxAmount: sgstTaxAmount,
      InterTaxName: countryTaxDetails?.interTaxName,
      InterTaxRate: igstTax,
      InterTaxAmount: igstTaxAmount,
      CentralTaxName: countryTaxDetails?.centralTaxName,
      CentralTaxRate: cgstTax,
      CentralTaxAmount: cgstTaxAmount,
      IsOtherTax: false,
      ShippingCharge: shipCharge,
      RoundOff: roundOff,
      TotalAmount: netAmountValue,
      DocID: 0,
      Status: status,
      TenantID: GV.userDet.tenantDetail.tenantID,
      CreatedBy: GV.userDet.appUser.appuserID.toString(),
      IsItemChanged: true,
      PurchaseReturnItemList: invoiceLines.map((e, j) => ({
        PurchaseReturnItemID: e.purchaseRtnId || 0,
        DebitNoteID: rowDatas.debitNoteID || 0,
        ItemID: e.productname ? e.productname?.selectedDates.itemID : 0,
        ItemName: e.productname ? e.productname.selectedDates.name : 0,
        ItemType: e.productname ? e.productname?.selectedDates.itemType : "",
        HsnCode: e.productname ? e.productname.selectedDates.saCorHSNCode : "",
        HsnName: e.productname
          ? e.productname?.selectedDates.itemType === "goods"
            ? "HSN"
            : "SAC"
          : "",
        Unit: e.productname ? e.productname?.selectedDates.unit : "",
        PurchaseQuantity: e.quantity,
        ReturnQuantity: e.quantity,
        Rate: e.productname
          ? e.productname?.selectedDates.siSellingPrice
          : e.rate,
        DiscountType: "Percentage",
        DiscountRate: discountValue,
        Discount: e.discount,
        LocalTaxName: countryTaxDetails.localTaxName,
        LocalTaxRate: +e.statetax,
        LocalTaxAmount: sgstTaxAmount,
        InterTaxName: countryTaxDetails?.interTaxName,
        InterTaxRate: +e.intertax,
        InterTaxAmount: igstTaxAmount,
        CentralTaxName: countryTaxDetails?.centralTaxName,
        CentralTaxRate: +e.centraltax,
        CentralTaxAmount: cgstTaxAmount,
        IsOtherTax: false,
        TotalAmount: +e.netamount,
        Reason: e.returnreason || data.returnreason,
        Status: "ACTIVE",
        TenantID: GV.userDet.tenantDetail.tenantID,
      })),
    };
    const formData = new FormData();
    formData.append("inputJson", JSON.stringify(InputJson));
    formData.append("docs", uploadedFiles);

    ps.SavePurchaseDebitNote(formData)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setIsProcess(false);
          new Promise((resolve) => {
            toast.current.show({
              severity: "success",
              detail: res.data.message,
            });
            setTimeout(() => {
              resolve();
            }, 1000);
          }).then(() => {
            backToDebit();
            debitCancel();
            clearUploadedFile();
          });
        }
      })
      .catch((err) => {
        setIsProcess(false);
      });
  };

  const debitCancel = () => {
    reset();
    setPurchaseDate(new Date());
    setIsType(false);
    setIsUpdate(false);
    setIsCustChoose(true);
    setIsDiscountDisabled(false);
    setNetAmountValue("");
    setPaymentDueDateCount("");
    setSelectProductDrop("");
    setItemType("");
    setProductRate("");
    clearFields();
    setSubTotal(0);
    setDiscountValue(0);
    setSgstTax(0);
    setCgstTax(0);
    setIgstTax(0);
    setRoundOff(0);
    setNetAmountValue(0);
    setShipCharge(0);
    setCgstTaxAmount(0);
    setSgstTaxAmount(0);
    setIgstTaxAmount(0);
    setDiscountedAmount("0.00");
  };

  const onUploadFile = (event) => {
    setUploadedFiles(event.files[0]);
    // setClearUpload(true);
  };

  const clearUploadedFile = () => {
    setUploadedFiles(null);
    // setClearUpload(true);
  };
  const handleInputChangeQuantity = (e, i, name) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[i].quantity = e.target.value;
    // const val = +name.stocks + 1
    // newInvoiceLines[i].quantityError = name.itemType != 'service' ? name.stocks >= e.target.value ?  '' : 'Quantity must be less than ' + val + ' ' + name.unit  : '';
    setQuantityValue(e.target.value);
    setInvoiceLines(newInvoiceLines);
    let addSubTotal = newInvoiceLines
      .map((a) => a.rate * a.quantity)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSubTotal(addSubTotal);
    handleTaxChangeWithoutIndex(newInvoiceLines, i);
    handleTaxChange(newInvoiceLines);
  };

  const handleInputChangeDiscount = (e, i) => {
    setManualErr("");
    const inputValue = e.target.value;
    if (inputValue.includes("-")) {
      const removeNeg = inputValue.replace("-", "");
      e.target.value = removeNeg;
    }

    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[i].discount = e.target.value;
    setDiscountValue(e.target.value);
    setInvoiceLines(newInvoiceLines);

    let disPer = newInvoiceLines
      .map((a) => +a.discount)
      .reduce((acc, total) => acc + total, 0);
    let calculatePer = (+disPer * 100) / (newInvoiceLines.length * 100);
    let a = calculatePer;
    setDiscountValue(a);
    handleTaxChangeWithoutIndex(newInvoiceLines, i);
    handleTaxChange(newInvoiceLines);
  };

  const handleSelectDropdown = (value, index) => {
    setManualErr("");
    let findVal = productDrop.find((a) => a.value === value);
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[index].productname = findVal;
    setInvoiceLines(newInvoiceLines);

    const updatedLines = invoiceLines.map((line, i) => {
      if (i === index) {
        return {
          id: line.id,
          productname: findVal,
          quantity: 1,
          rate: findVal.selectedDates.siSellingPrice
            ? findVal.selectedDates.siSellingPrice.toFixed(2)
            : findVal.selectedDates.rate || 0,
          discount: 0,
          statetax: findVal.selectedDates.localTaxRate || 0,
          centraltax: findVal.selectedDates.centralTaxRate || 0,
          intertax: findVal.selectedDates.interTaxRate || 0,
          netamount: findVal.selectedDates.siSellingPrice || 0,
          label: findVal.label,
          isEnbl: false,
          //   producttype: selectedProduct.itemType,
        };
      }

      return line;
    });
    handleTaxChangeWithoutIndex(updatedLines, index);
    handleTaxChange(updatedLines);
    setInvoiceLines(updatedLines);
  };

  const handleTaxChange = (newlines) => {
    setInvoiceLines(newlines);
    // setNetAmountValue(0);
    let disPer = newlines
      .map((a) => +a.discount)
      .reduce((acc, total) => acc + total, 0);
    let calculatePer = (+disPer * 100) / (newlines.length * 100);
    setDiscountValue(calculatePer);

    let addSubTotal = newlines
      .map((a) => a.rate * a.quantity)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSubTotal(addSubTotal);

    let calculateStateTax = newlines
      .map(
        (a, i) =>
          (Number(a.quantity * a.productname.siSellingPrice) / 100) *
          +a.statetax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateStateTaxPercentage = newlines
      .map((a) => +a.statetax)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSgstTax(calculateStateTaxPercentage);

    let calculateCentralTax = newlines
      .map(
        (a, i) =>
          (Number(a.quantity * a.productname.siSellingPrice) / 100) *
          +a.centraltax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateCentralTaxPercentage = newlines
      .map((a) => +a.centraltax)
      .reduce((acc, ttl) => acc + ttl, 0);
    setCgstTax(calculateCentralTaxPercentage);

    let calculateInterTax = newlines
      .map(
        (a, i) =>
          (Number(a.quantity * a.productname.siSellingPrice) / 100) *
          +a.intertax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateInterTaxPercentage = newlines
      .map((a) => +a.intertax)
      .reduce((acc, ttl) => acc + ttl, 0);

    setIgstTax(calculateInterTaxPercentage);

    setSgstTaxAmount(
      isFinite(calculateStateTax) ? calculateStateTax.toFixed(2) : 0
    );
    setCgstTaxAmount(
      isFinite(calculateCentralTax) ? calculateCentralTax.toFixed(2) : 0
    );
    setIgstTaxAmount(
      isFinite(calculateInterTax) ? calculateInterTax.toFixed(2) : 0
    );

    let roundVal = newlines
      .map((b) => +b.netamount - Math.trunc(+b.netamount))
      .reduce((acc, total) => acc + total, 0);
    setRoundOff(isFinite(roundVal) ? roundVal.toFixed(2) : 0);
    let totalAm = newlines
      .map((b) => +b.netamount)
      .reduce((acc, total) => acc + total, 0);
    setNetAmountValue(isFinite(totalAm) ? totalAm : 0);

    console.log(netAmountValue);
  };

  const handleTaxChangeWithoutIndex = (lines, i) => {
    const newInvoiceLines = [...lines];
    let total = 0;
    let totalDiscountAmount = newInvoiceLines
      .map((a) => {
        let discountPercentage = parseFloat(a.discount || 0) / 100;
        let quant = Number(a.quantity * a.rate);
        return quant * discountPercentage;
      })
      .reduce((acc, ttl) => acc + ttl, 0);
    let discount =
      totalDiscountAmount && isFinite(totalDiscountAmount)
        ? totalDiscountAmount.toFixed(2)
        : "0.00";
    setDiscountedAmount(discount);

    let separateLineItem =
      +newInvoiceLines[i]?.rate * (+newInvoiceLines[i]?.discount / 100);
    let discountPrice = +newInvoiceLines[i]?.rate - +separateLineItem;

    let finalAmount =
      +newInvoiceLines[i]?.quantity * +newInvoiceLines[i]?.rate -
      +separateLineItem;
    let collectTax =
      +(newInvoiceLines[i]?.statetax || 0) +
      (+newInvoiceLines[i]?.centraltax || 0) +
      (+newInvoiceLines[i]?.intertax || 0);
    let findTax = (finalAmount * collectTax) / 100;
    total = finalAmount + findTax;
    newInvoiceLines[i].netamount = total.toFixed(2);
  };

  const handleReasonChange = (e, i) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[i].returnreason = e.target.value;
    setReturnReson(e.target.value);
    setInvoiceLines(newInvoiceLines);
  };

  const GetTenantDetails = () => {
    is.GetTenantDetails(GlobalVariable.userDet.tenantDetail.tenantID).then(
      (res) => {
        setTenantDetails(res.data.data);
        GetCountryTaxName(res.data.data.country);
      }
    );
  };

  const GetCountryTaxName = (country) => {
    is.GetCountryTaxName(country).then((res) => {
      if (res.data.statusCode === 200) {
        setCountryTaxDetails(res.data.data);
      }
    });
  };

  const getShippingCharge = (e) => {
    const newInvoiceLines = [...invoiceLines];
    const charge = +e.target.value;
    setShipCharge(charge);
    let totalAm = newInvoiceLines
      .map((b) => +b.netamount)
      .reduce((acc, total) => acc + total, 0);
    let withShippin = totalAm + charge;
    setNetAmountValue(withShippin);
  };

  const deleteLineItem = (e, index) => {
    const newInvoiceLines = [...invoiceLines];
    if (newInvoiceLines.length <= 1) {
      toast.current.show({
        severity: "error",
        summary:
          "Item deletion is only possible after adding a second line item",
      });
      return;
    }
    toast.current.show({
      severity: "success",
      summary: "Deleted successfully",
    });
    setIsType(true);
    newInvoiceLines.splice(index, 1);
    setInvoiceLines(newInvoiceLines);
    // handleTaxChangeWithoutIndex(newInvoiceLines, index);
    handleTaxChange(newInvoiceLines);
  };

  const DownloadDocs = (e) => {
    setIsProcess(true);

    const data = {
      DocIDs: e.docID.toString(),
      DocCategory: "SalesInvoice",
    };
    is.DownloadDocs(data).then((res) => {
      if (res.data.statusCode === 200) {
        setDocUrl(res.data?.data[0]?.documentURL);
        setShowDocument(true);
        setIsProcess(false);
      }
      console.log(res.data?.data[0]?.documentURL, "doc");
    });
  };

  useEffect(() => {
    GetSalesItemList();
    GenerateDebitNoteConfigNumber();
    GetTenantDetails();
    GetContactFilteredList();
  }, []);

  return (
    <BlockUI blocked={isProcess}>
      <div>
        <Toast ref={toast} />

        <ConfirmDialog />
        {isProcess && (
          <ProgressSpinner
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            strokeWidth="2"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}

        <form>
          <div>
            <div className="row mx-auto">
              <div
                className="col-md d-flex justify-content-between cc-greyCard-custm align-items-center mb-3 p-2 mx-2"
                style={{ background: "white" }}
              >
                            <div className="ms-2 my-2 me-2">

                <div>
                  <h4 className="m-0"> New Debit Note</h4>
                </div>
</div>
                <div>
                  <button
                    type="button"
                    className="btn btn-danger py-1 me-2"
                    onClick={backToDebit}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
            <div className="card p-3 ">
              <div className="row">
                <div className="row">
                  <div className="col-md-3">
                    <div className="d-flex justify-content-between pe-4">
                      <div className="col-md-2 me-3">
                        <label htmlFor="">
                          <strong className="">Bill To</strong>
                        </label>
                      </div>
                      <div className="col-md-9">
                        <Controller
                          name="billto"
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <Dropdown
                              {...field}
                              value={selectVendorNameList}
                              disabled
                              onChange={(e) => {
                                field.onChange(e.value);
                                vendorNameOnchange(e.value);
                              }}
                              options={vendorNameList}
                              optionLabel="fullName"
                              placeholder="Select vendor"
                              className="form-control"
                            />
                          )}
                        />
                        {errors.billto && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="">
                      <strong>Shipping to</strong>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-md-12 d-flex mb-5 justify-content-between">
                  <div className="col-md">
                    <div className="row">
                      <div className="col-md p-3 pe-2">
                        {!isCustChoose && (
                          <div
                            className=" p-2 h-100"
                            style={{ backgroundColor: "#FDFDFD" }}
                          >
                            <p className="m-0">
                              <strong>{selectVendorNameList?.fullName},</strong>
                            </p>
                            <p className="m-0 ps-2">
                              {selectVendorNameList?.baAddress1}
                            </p>
                            <p className="m-0 ps-2">
                              {selectVendorNameList?.baState},{" "}
                              {selectVendorNameList?.baZipCode}
                            </p>
                            <p className="m-0 ps-2">
                              {" "}
                              <strong className="me-2">
                                <i className="pi pi-phone"></i>
                              </strong>{" "}
                              {selectVendorNameList?.phone},{" "}
                              <strong className="m-2">
                                <i className="pi pi-envelope"></i>
                              </strong>{" "}
                              {selectVendorNameList?.email}
                              {}
                            </p>
                          </div>
                        )}
                        {isCustChoose && (
                          <div
                            className="text-center p-2 h-100"
                            style={{ backgroundColor: "#FDFDFD" }}
                          >
                            <Empty description="Select Customer to view details" />
                          </div>
                        )}
                      </div>
                      <div className="col-md p-3 pe-2">
                        <div
                          className=" p-2 h-100 "
                          style={{ backgroundColor: "#FDFDFD" }}
                        >
                          <p className="m-0">
                            <strong>{tenantDetails.tenantName},</strong>
                          </p>
                          <p className="m-0 ps-2">
                            {tenantDetails.billAddress1},{" "}
                            {tenantDetails.billAddress2}
                          </p>
                          <p className="m-0 ps-2">
                            <strong className="me-2">
                              <i className="pi pi-home"></i>
                            </strong>
                            {tenantDetails.billCity} -{" "}
                            {tenantDetails.billZipCode} -{" "}
                            {tenantDetails.billCountry}
                          </p>
                          <p className="m-0 ps-2">
                            <strong className="me-2">
                              <i className="pi pi-phone"></i>
                            </strong>{" "}
                            {tenantDetails.primaryPhone},{" "}
                            <strong className="m-2">
                              <i className="pi pi-envelope"></i>
                            </strong>{" "}
                            {tenantDetails.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider layout="vertical" />
                  <div className="col-md-6">
                    <div className="row mx-auto mb-3">
                      <div className="col-md">
                        <label htmlFor=""> Invoice Number</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          {...register("invNum")}
                        />
                      </div>
                      <div className="col-md">
                        <label htmlFor=""> Purchase Date</label>
                        <Calendar
                          name="purchasedate"
                          value={purchaseDate}
                          disabled
                          className="form-control p-0"
                          onChange={(e) => handleSelectEstimateDateSelect(e)}
                          placeholder="Select purchase date"
                        />
                      </div>
                    </div>

                    <div className="row mx-auto mb-3">
                      <div className="col-md">
                        <label htmlFor="" className="required">
                          {" "}
                          Return Date
                        </label>

                        <Controller
                          name="returndate"
                          control={control}
                          rules={{ required: "This field is required" }}
                          render={({ field }) => (
                            <Calendar
                              {...field}
                              value={selectReturnDate}
                              className="form-control p-0"
                              placeholder="Select return date"
                              disabled={rowDatas.status === "PAID"}
                              onChange={(e) => {
                                field.onChange(e.value);
                                setSelectReturnDate(e.value);
                              }}
                            />
                          )}
                        />

                        {errors.returndate && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>{" "}
                      <div className="col-md">
                        <label htmlFor="" className="">
                          Debit Note Number
                        </label>
                        <input
                          name="debitnotenumber"
                          type="text"
                          className="form-control"
                          disabled
                          value={invoiceNumber}
                        />
                        {errors.debitnotenumber && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Divider className="ms-5" style={{ width: "94%" }} />
              </div>
              {isProcess && (
                <ProgressSpinner
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  strokeWidth="2"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              )}
              <div className="row">
                <div className="col-md">
                  <div className="table-responsive">
                    <table className="table mt-3">
                      <thead>
                        <tr className="heading">
                          <th style={{ width: "18%" }} className="borderIn">
                            Product Name
                          </th>
                          <th className="borderIn" style={{ width: "5%" }}>
                            Quantity
                          </th>
                          <th className="borderIn" style={{ width: "6%" }}>
                            Rate ({GlobalVariable.userDet.tenantConfig.currency}
                            )
                          </th>
                          <th className="borderIn" style={{ width: "6%" }}>
                            Discount (%)
                          </th>
                          <th colSpan="3">Tax (%)</th>
                          <th className="borderIn">Reason Of Return (%)</th>
                          <th className="borderIn" style={{ width: "7%" }}>
                            Net Amount (
                            {GlobalVariable.userDet.tenantConfig.currency})
                          </th>
                          {/* <th style={{ width: "3%" }}></th> */}
                        </tr>
                        <tr className="sub-heading">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th className="borderIn">
                            {countryTaxDetails?.localTaxName}
                          </th>
                          <th className="borderIn">
                            {countryTaxDetails?.centralTaxName}
                          </th>
                          <th className="borderIn">
                            {countryTaxDetails?.interTaxName}
                          </th>
                          <th></th>
                          {/* <th></th> */}
                          <th></th>
                        </tr>
                      </thead>
                      {isProcess && (
                        <ProgressSpinner
                          style={{
                            width: "50px",
                            height: "50px",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                          strokeWidth="2"
                          fill="var(--surface-ground)"
                          animationDuration=".5s"
                        />
                      )}
                      <tbody>
                        {invoiceLines.map((line, index) => (
                          <tr key={index}>
                            <td>
                              {/* <Dropdown
                                name="productname"
                                value={line.productname}
                                onChange={(e) => {
                                  handleSelectProduct(e.value, index);
                                }}
                                options={productDrop}
                                disabled={fieldEnabled}
                                optionLabel="name"
                                placeholder="Choose to select"
                                className="form-control-custom sm-2"
                              /> */}
                              <Select
                                className="cust-form-control p-0"
                                name="productname"
                                placeholder="Choose to select item or add item"
                                disabled
                                value={
                                  line.productname
                                    ? line.productname.value
                                    : undefined
                                }
                                onChange={(value) =>
                                  handleSelectDropdown(value, index)
                                }
                                options={productDrop.map((item) => ({
                                  label: item.label,
                                  value: item.value,
                                }))}
                              />
                            </td>

                            <td>
                              <input
                                type="text"
                                name="quantity"
                                className="form-control"
                                value={line.quantity}
                                disabled={rowDatas.status === "PAID"}
                                onInput={(e) => {
                                  handleInputChangeQuantity(e, index);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="rate"
                                className="form-control"
                                disabled
                                value={line.rate}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="discount"
                                disabled
                                className="form-control"
                                value={line.discount}
                                onInput={(e) => {
                                  handleInputChangeDiscount(e, index);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="statetax"
                                className="form-control"
                                disabled
                                value={line.statetax}
                                title="To adjust the tax percentage, please access the Inventory section"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="centraltax"
                                className="form-control"
                                disabled
                                value={line.centraltax}
                                title="To adjust the tax percentage, please access the Inventory section"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="intertax"
                                className="form-control"
                                disabled
                                value={line.intertax}
                                title="To adjust the tax percentage, please access the Inventory section"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="returnreason"
                                className="form-control"
                                disabled={rowDatas.status === "PAID"}
                                defaultValue={line.returnreason}
                                {...register("returnreason")}
                                onInput={(e) => handleReasonChange(e, index)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="netamount"
                                className="form-control"
                                value={line.netamount}
                                disabled
                              />
                            </td>
                            {/* <td style={{ textAlign: "center", padding: "1%" }}>
                              <button
                                type="button"
                                style={{ background: "transparent" }}
                                disabled={fieldEnabled}
                                onClick={() => deleteLineItem(line, index)}
                              >
                                <i
                                  className="pi pi-trash"
                                  aria-hidden="true"
                                  style={{ color: "red" }}
                                ></i>
                              </button>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {manualErr && (
                      <p className="text-danger my-3">{manualErr}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row mx-auto my-4">
                    {/* {editable === false ? (
                      <div className="col-md p-0">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={addNewLine}
                        >
                          + Add New invoice Line
                        </button>
                      </div>
                    ) : (
                      ""
                    )} */}
                    <div className="col-md"></div>
                    <div className="col-md"></div>
                    <div
                      className="col-md-3 p-3"
                      style={{
                        background: "rgb(226 243 252)",
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p> Sub Total</p>
                        <p>
                          {" "}
                          <i
                            className="pi pi-indian-rupee"
                            aria-hidden="true"
                          ></i>{" "}
                          {GlobalVariable.userDet.tenantConfig.currency}{" "}
                          {subTotal}
                        </p>
                      </div>
                      {/* <Divider className="m-0" /> */}

                      {/* <Divider className="m-0" /> */}
                      <div className="d-flex justify-content-between">
                        <p> Tax(SGST)</p>
                        <p>
                          {" "}
                          <i
                            className="pi pi-indian-rupee"
                            aria-hidden="true"
                          ></i>{" "}
                          {GlobalVariable.userDet.tenantConfig.currency}{" "}
                          {sgstTaxAmount}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p> Tax(CGST)</p>
                        <p>
                          {" "}
                          <i
                            className="pi pi-indian-rupee"
                            aria-hidden="true"
                          ></i>{" "}
                          {GlobalVariable.userDet.tenantConfig.currency}{" "}
                          {cgstTaxAmount}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p> Tax(IGST)</p>
                        <p>
                          {" "}
                          <i
                            className="pi pi-indian-rupee"
                            aria-hidden="true"
                          ></i>{" "}
                          {GlobalVariable.userDet.tenantConfig.currency}{" "}
                          {igstTaxAmount}
                        </p>
                      </div>
                      {/* <div className="d-flex justify-content-between">
                          <p> Tax Amount</p>
                          <p>
                            {" "}
                            <i
                              className="pi pi-indian-rupee"
                              aria-hidden="true"
                            ></i>{" "}
                            {taxAmount}
                          </p>
                        </div> */}
                      <div className="d-flex justify-content-between">
                        <p> Shipping Charges</p>
                        <p className="text-end">
                          {" "}
                          {GlobalVariable.userDet.tenantConfig.currency}
                          <input
                            value={shipCharge}
                            type="number"
                            name="shippngcharge"
                            className="text-end"
                            style={{ width: "31%" }}
                            disabled
                            onInput={(e) => {
                              getShippingCharge(e);
                            }}
                          />
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p> Round off</p>
                        <p>
                          {" "}
                          <i
                            className="pi pi-indian-rupee"
                            aria-hidden="true"
                          ></i>{" "}
                          {GlobalVariable.userDet.tenantConfig.currency}{" "}
                          {roundOff}
                        </p>
                      </div>
                      <Divider className="m-0" />
                      <div className="d-flex justify-content-between">
                        <p> Discount </p>
                        {discountedAmount !== "0.00" ? (
                          <p className="text-danger">
                            - {GlobalVariable.userDet.tenantConfig.currency}{" "}
                            {discountedAmount}
                          </p>
                        ) : (
                          <p className="">
                            {" "}
                            {GlobalVariable.userDet.tenantConfig.currency}{" "}
                            {discountedAmount}
                          </p>
                        )}
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>
                          <strong> Total</strong>
                        </p>
                        <p>
                          {" "}
                          <strong>
                            <i
                              className="pi pi-indian-rupee"
                              aria-hidden="true"
                            ></i>{" "}
                            {GlobalVariable.userDet.tenantConfig.currency}{" "}
                            {netAmountValue?.toFixed(2)}
                          </strong>
                        </p>
                      </div>
                      {editable === true ? (
                        <div className="d-flex justify-content-between">
                          <p>
                            <strong> Balance Due</strong>
                          </p>
                          <p>
                            {" "}
                            <strong>
                              <i
                                className="pi pi-indian-rupee"
                                aria-hidden="true"
                              ></i>{" "}
                              {GlobalVariable.userDet.tenantConfig.currency}{" "}
                              {editModeData.balancePayment?.toFixed(2)}
                            </strong>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <Divider className="m-0" />
                    </div>
                    {rowDatas.status !== "PAID" && (
                      <div className="col-md-12 p-0 mt-5">
                        <div className="row mx-auto">
                          {rowDatas !== null ? (
                            <h6 className="bg-success1 text-white p-2 ">
                              UPLOADED DOCUMENT
                            </h6>
                          ) : (
                            <h6 className="bg-success1 text-white p-2 ">
                              UPLOAD
                            </h6>
                          )}
                          <div className="p-0 mt-4">
                            <Toast ref={toast}></Toast>
                            {rowDatas !== null ? (
                              <div style={{ width: "50%", margin: "auto" }}>
                                {showDocument ? (
                                  documentURL ? (
                                    documentURL.endsWith(".pdf") ? (
                                      <embed
                                        src={documentURL}
                                        type="application/pdf"
                                        style={{
                                          width: "100%",
                                          objectFit: "contain",
                                        }}
                                      />
                                    ) : documentURLType.endsWith(".png") ||
                                      documentURLType.endsWith(".jpg") ||
                                      documentURLType.endsWith(".jpeg") ? (
                                      <img
                                        src={documentURL}
                                        alt="Document"
                                        style={{
                                          width: "100%",
                                          objectFit: "contain",
                                        }}
                                      />
                                    ) : (
                                      <iframe
                                        src={documentURL}
                                        style={{
                                          width: "100%",
                                          height: "400px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    )
                                  ) : (
                                    <div
                                      className="text-center pb-3"
                                      style={{ paddingLeft: "10rem" }}
                                    >
                                      No document found
                                    </div>
                                  )
                                ) : null}
                              </div>
                            ) : null}
                            <FileUpload
                              emptyTemplate={
                                <div className="mt-3 important-color">
                                  Max 10mb
                                  <br />
                                  Drop files here or click to upload. <br />
                                  Supported file formats: PNG, JPG, JPEG, CSV,
                                  XLSX, PDF
                                </div>
                              }
                              className="newexpense newexpcontent deleteUploadCancel"
                              style={{ backgroundColor: "transparent" }}
                              accept="*"
                              maxFileSize={10000000}
                              onSelect={onUploadFile}
                              // uploadedFile={documentURL}
                              chooseLabel={"File Upload"}
                            />
                            {/* <FileUpload
                            emptyTemplate={
                              <div className="mt-3 important-color">
                                Max 10mb
                                <br />
                                Drop files here or click to upload. <br />
                                Supported file formats: PNG, JPG, JPEG, CSV,
                                XLSX, PDF
                              </div>
                            }
                            className="newexpense newexpcontent deleteUploadCancel"
                            style={{ backgroundColor: "transparent" }}
                            // mode="basic"
                            // multiple
                            accept="*"
                            maxFileSize={10000000}
                            onSelect={onUploadFile}
                            uploadedFile={documentURL}
                          /> */}
                            {/* <div className="text-center">
                          <Button
                            type="button"
                            label="ReUload Document"
                            className="btn btn-primary"
                            icon="pi pi-check"
                            onClick={hideUpload()}
                          />
                        </div> */}
                          </div>
                        </div>
                      </div>
                    )}

                    {editModeData.balancePayment?.toFixed(2) === "0.00" ? (
                      <div
                        className="row mt-3 "
                        style={{ paddingRight: "55px" }}
                      >
                        {" "}
                        <div className="col-md-8"></div>
                        <div className="col-md-4 ">
                          <Alert
                            message="Success "
                            description="No Due! Full amount paid"
                            type="success"
                            showIcon
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {rowDatas.status !== "PAID" && (
                      <div
                        className="d-flex mt-3 justify-content-end p-0 gap-2"
                        style={{ paddingRight: "80px" }}
                      >
                        {editable === false ? (
                          <Primebutton
                            className="btn btn-danger my-3 "
                            type="button"
                            onClick={debitCancel}
                            label="Cancel"
                          />
                        ) : (
                          <>
                            <Primebutton
                              className="btn btn-danger my-3 "
                              type="button"
                              onClick={backToDebit}
                              label="Back"
                            />
                            {editModeData.balancePayment?.toFixed(2) !==
                            "0.00" ? (
                              <Primebutton
                                className="btn btn-primary my-3 "
                                type="button"
                                onClick={() =>
                                  proceedPay(editModeData, "editpage")
                                }
                                label="Record Payment"
                              />
                            ) : (
                              ""
                            )}
                          </>
                        )}
                        {editable === false ? (
                          <>
                            <Primebutton
                              className="btn btn-primary my-3"
                              onClick={handleSubmit((e) =>
                                onSubmit(e, "DRAFT")
                              )}
                              type="button"
                              label="Save Draft"
                            />

                            {!isUpdate ? (
                              <Primebutton
                                type="button"
                                className="btn btn-primary my-3"
                                label="Save & Submit"
                                onClick={handleSubmit(
                                  (e) => onSubmit(e, "OPEN"),
                                  onInvalid
                                )}
                              />
                            ) : (
                              <Primebutton
                                type="button"
                                className="btn btn-primary my-3"
                                label="Update"
                                onClick={handleSubmit((e) =>
                                  onSubmit(e, "OPEN")
                                )}
                              />
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmPopup />
        </form>
      </div>
    </BlockUI>
  );
};
export default DebitNoteForm;
