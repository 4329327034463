import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import {
  DataTable,
  Column,
  Dropdown,
  InputText,
  MultiSelect,
  iconSet,
  IcomoonReact,
  BlockUI,
} from "../PrimeReact.js";
import { Tooltip } from "primereact/tooltip";
import { AiOutlineSearch } from "react-icons/ai";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import purchaseService from "../../services/purchase-sevice";
import GV from "../../services/globalVar";
import PettycashServiceService from "../../services/pettycash-service";
import { TabView, TabPanel } from "primereact/tabview";
import { TabMenu } from "primereact/tabmenu";
import { Controller, useForm } from "react-hook-form";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
// import NewPurchase from "./NewPurchase.js";
import NewPurchase from "./NewPurchase.js";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import * as XLSX from "xlsx";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { format } from "date-fns";
import { DatePicker, Space, Input, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Dropdown as Primedropdown } from "primereact/dropdown";
import InvoicePayment from "../Sales/InvoicePayment.js";

const PurchaseList = () => {
  const [searchVal, setSearchVal] = useState("");
  const [gridList, setGridList] = useState([]);
  const [first1, setFirst1] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activeItem, setActiveItem] = useState(null);
  const [selectedTabMenu, setSelectedTabMenu] = useState("Today");
  const [isShow, setIsShow] = useState(true);
  const [chooseFromDate, setChooseFromDate] = useState();
  const [chooseToDate, setChooseToDate] = useState();
  const [isCalendar, setIsCalendar] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isNewPurchase, setIsNewPurchase] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [purchaseExpList, setPurchaseExpList] = useState();
  const [triggerAfterDelete, setTriggerAfterDelete] = useState();
  const [documentURL, setDocumentURL] = useState("");
  const [documentURLType, setDocumentURLType] = useState("");
  const [showDocument, setShowDocument] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [useBackBtn, setUseBackBtn] = useState();
  const [hidendShowUplod, setHidendShowUplod] = useState(false);
  const [selectedDates, setSelectedDates] = useState(null);
  const [payment, setPayment] = useState("");
  const paymodal = useRef(null);

  const ps = new purchaseService();
  const { RangePicker } = DatePicker;

  const items = [
    { id: 1, label: "Today", value: "Today" },
    { id: 2, label: "This Week", value: "This Week" },
    { id: 3, label: "This Month", value: "This Month" },
    { id: 4, label: "This Year", value: "This Year" },
    { id: 5, label: "Custom", value: "Custom" },
  ];
  const [filterGrid, setFilterGrid] = useState(items[0].value);

  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });

  const onSort = (event) => {
    setLazyParams(event);
  };

  const onCustomPage1 = (event) => {
    setFirst1(event.first);
    const val = event.page + 1;
    const size = event.rows;
    setSizePerPage(size);
    setPage(val);
    GetPurchaseListSearch(page, size, searchVal, "", "", useBackBtn, "", "");
  };

  const GetPurchaseExpList = () => {
    ps.GetPurchaseExpList(GV.userDet.tenantDetail.tenantID, 5).then((res) => {
      if (res.data.statusCode === 200) {
        setPurchaseExpList([res.data.data]);
      }
    });
  };

  const GetPurchaseListSearch = (
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder,
    tabName,
    fromDate,
    toDate
  ) => {
    setIsCalendar(false);
    setTriggerAfterDelete(tabName);
    setUseBackBtn(tabName);
    const data = {
      InputParams: {
        TenantID: GV.userDet.tenantDetail.tenantID,
        ContactID: 0,
        Status: "",
        Period: tabName,
        FromDate:
          tabName === "Custom" ? fromDate.toISOString().split("T")[0] : null,
        ToDate:
          tabName === "Custom" ? toDate.toISOString().split("T")[0] : null,
      },
      ServerSearchables: {
        draw: 1,
        searchData: searchVal || "",
        orderByColumn: sortName,
        orderBy: sortOrder,
        currentPage: 1,
        pageSize: sizePerPage,
        pageNumber: page,
      },
    };

    ps.GetPurchaseListSearch(data).then((res) => {
      if (res.data && res.data.statusCode === 200) {
        console.log(res.data,'list')
        setIsLoading(false);
        if (res.data.data.purchaseList === null) {
          setGridList([]);
        } else {
          setGridList([...res.data.data.purchaseList]);
        }
        setTotalRecords(res.data.data.serverSearchables.recordsTotal);
      }
    });
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
    GetPurchaseListSearch(
      page,
      sizePerPage,
      event.target.value,
      sortName,
      sortOrder,
      selectedTabMenu,
      "",
      ""
    );
  };

  const handleGoBack = () => {
    setIsNewPurchase(false);
    GetPurchaseListSearch(
      page,
      sizePerPage,
      searchVal,
      "",
      "",
      useBackBtn,
      "",
      ""
    );
    setShowDocument(false);
  };

  const formatDatee = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatDecimal = (number) => {
    return parseFloat(number).toFixed(2);
  };

  const openFormNew = () => {
    setIsNewPurchase(true);
    setIsShow(false);
  };

  useLayoutEffect(() => {
    GetPurchaseListSearch(page,sizePerPage,searchVal,"","","","","");
    // GetPurchaseExpList();
  }, []);

  const exportToExcel = () => {
    console.log("EXPORT");
    const fileName = "purchase_data.xlsx";
    const fieldsToExport = [
      "coaName",
      "companyName",
      "description",
      "email",
      "expenseType",
      "gstTreatement",
      "firstName",
      "lastName",
      "phone",
      "invoiceNo",
      "totalAmount",
      "totalTaxAmount",
    ];
    const filteredData = gridList.map((item) => {
      const filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Purchase Data");
    XLSX.writeFile(wb, fileName);
  };

  const rightToolbarTemplate = () => {
    return (
      <div>
        {gridList.length !== 0 && (
          <Button
            label="Export"
            icon="pi pi-upload"
            className="py-1 me-2 mx-2 kk-btnThin my-auto p-button-help"
            style={{
              backgroundColor: "#dddddd",
              border: "none",
              color: "black",
            }}
            onClick={exportToExcel}
          />
        )}
        <Button
          label="Create New "
          icon="pi pi-plus"
          severity="success"
          className="btn btn-primary py-1 me-2 mx-2 kk-btnThin my-auto"
          onClick={openFormNew}
        />
      </div>
    );
  };

  const proceedPay = (e, data) => {
    e.stopPropagation();
    setPayment(data);
    paymodal.current.alertToggle(data);
  };

  const leftToolbarTemplate = () => {
    return (
      <>
        {/* <i className="pi pi-search" /> */}
        {/* <InputText
            placeholder="Search"
            style={{ width: "22rem" }}
            value={searchValue}
            onChange={handleSearchInputChange}
          />{" "} */}

        <Space.Compact className="my-auto">
          <Input
            addonBefore={<SearchOutlined />}
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchInputChange}
            // onKeyUp={(e) => keySearch(e)}
          />
        </Space.Compact>
      </>
    );
  };

  const buttonSelect = (buttonName) => {
    setSelectedTabMenu(buttonName);
    if (buttonName === "Custom") {
      setIsCalendar(true);
    } else {
      setIsCalendar(false);
      GetPurchaseListSearch(
        page,
        sizePerPage,
        searchVal,
        "",
        "",
        buttonName,
        "",
        ""
      );
    }
  };

  const selectDateRangeFrom = (e) => {
    setChooseFromDate(
      new Date(e).toLocaleDateString("fr-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    );
  };
  const selectDateRangTo = (e) => {
    setChooseToDate(
      new Date(e).toLocaleDateString("fr-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    );
  };

  const editData = (data) => {
    setHidendShowUplod(true);
    setIsNewPurchase(true);
    setSelectedPurchase(data);
    fetchDocuments(data);
    console.log(data, "data");
  };

  const deleteRowData = (data) => {
    const datas = {
      TenantID: GV.userDet.tenantDetail.tenantID,
      PurchaseID: data.purchaseID,
      PurchaseStatus: "Inactive",
    };
    ps.ArchivePurchase(datas).then((res) => {
      if (res.data.message === "Deleted Successfully") {
        toast.current.show({
          severity: "success",
          summary: "Deleted Successfully",
        });
        GetPurchaseListSearch(
          page,
          sizePerPage,
          searchVal,
          "",
          "",
          triggerAfterDelete,
          "",
          ""
        );
      }
    });
  };

  const toast = useRef(null);
  const reject = () => {};
  const confirm = (e) => {
    console.log(e, "eee");
    let obj = {
      message: "Do you want to delete this record?",
      className: "pAdj deletecircle",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultfocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => deleteRowData(e),
      reject: () => reject(),
    };
    confirmDialog(obj);
  };

  const fetchDocuments = (datas) => {
    setIsLoading(true);
    const data = {
      DocID: datas.docID,
    };
    console.log(datas,'datas')

    ps.GetPurchaseDocs(data)
      .then((res) => {
        if (res.data.data.length > 0) {

          setIsLoading(false);
          setDocumentURL(res.data.data[0].documentURL);
          setDocumentURLType(res.data.data[0].fileName);
          setShowDocument(true);
        } else {
          setShowDocument(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
      });
  };

  const onHide = () => {
    setShowDocument(false);
    setDocumentURL("");
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div style={{ display: "flex", justfyContent: "flex-start" }}>
        <Tooltip target=".pi-eye" content="View Document" position="top" />
        <Tooltip target=".custom-edit-icon" />
        {rowData.purchaseStatus === "DRAFT" ? 
        <IcomoonReact
        iconSet={iconSet}
        color="#1763af"
        size={20}
        icon="edit"
        className="pointer  custom-edit-icon"
        data-pr-tooltip="Edit"
        data-pr-position="top"
        onClick={() => editData(rowData)}
      /> :
      <IcomoonReact
        iconSet={iconSet}
        color="#1763af"
        size={20}
        icon="edit"
        className="pointer  custom-edit-icon"
        style={{visibility:'hidden'}}
        data-pr-tooltip="Edit"
        data-pr-position="top"
      />
       }
       {rowData.purchaseStatus === "OPEN" ? 
       <IcomoonReact
       iconSet={iconSet}
       color="#3e9d71"
       size={20}
       icon="payment"
       className="pointer"
       onClick={(e) => proceedPay(e, rowData)}
       data-pr-tooltip="Edit"
       data-pr-position="left"
     /> : <IcomoonReact
     iconSet={iconSet}
     color="#3e9d71"
     size={20}
     icon="payment"
     className="pointer"
     style={{visibility:'hidden'}}
     data-pr-tooltip="Edit"
     data-pr-position="left"
   />}
                

        <i
          className="pi pi-eye ms-2"
          style={{ color: "slateblue", fontSize: "1.3rem" }}
          onClick={() => fetchDocuments(rowData)}
        ></i>
       
        <Dialog
          visible={showDocument}
          onHide={onHide}
          header="Document"
          modal
          style={{ width: "50vw" }}
        >
          {showDocument ? (
            documentURL ? (
              documentURL.endsWith(".pdf") ? (
                <embed
                  src={documentURL}
                  type="application/pdf"
                  style={{ width: "100%", height: "400px" }}
                />
              ) : documentURLType.endsWith(".png") ||
                documentURLType.endsWith(".jpg") ||
                documentURLType.endsWith(".jpeg") ? (
                <img
                  src={documentURL}
                  alt="Document"
                  style={{ width: "100%", height: "400px" }}
                />
              ) : (
                <iframe
                  src={documentURL}
                  style={{ width: "100%", height: "400px" }}
                />
              )
            ) : (
              <div>No document found</div>
            )
          ) : null}
        </Dialog>
      </div>
    );
  };

  //   const actionBodyTemplate = (rowData) => {
  //     return (
  //         <React.Fragment>
  //             <IcomoonReact iconSet={iconSet} color="#444" size={20} icon="edit" className='pointer' />
  //         </React.Fragment>
  //     );
  // }

  const renderStatusColumn = (rowData) => {
    let status = rowData.purchaseStatus.toUpperCase();
    let statusClass;
    switch (status) {
      case "OPEN":
        statusClass = "orange";
        break;
      case "PARTIALLY PAID":
        statusClass = "processing";
        break;
      case "PAID":
        statusClass = "green";
        break;
      case "DRAFT":
        statusClass = "purple";
        break;
      case "OVERDUE":
        statusClass = "error";
        break;
      default:
        statusClass = "cyan";
    }
    return (
      <Tag bordered={false} color={statusClass}>
        {status}
      </Tag>
    );
  };

  const onChange = (values) => {
    console.log(values, "values");
    if (values && values.length === 2) {
      const [startDate, endDate] = values;
      setChooseFromDate(startDate.toISOString().split("T")[0]);
      setChooseToDate(endDate.toISOString().split("T")[0]);
      if (endDate) {
        GetPurchaseListSearch(
          page,
          sizePerPage,
          searchVal,
          "",
          "",
          "Custom",
          startDate,
          endDate
        );
      }
    }
  };

  const parentFunction = () => {
    GetPurchaseListSearch(page,sizePerPage,searchVal,"","","","","");
  };

  return (
    <>
      <BlockUI blocked={isLoading}>
      <InvoicePayment
        ref={paymodal}
        data={{ payment }}
        parentFunction={parentFunction}
      />
        {!isNewPurchase && (
          <div>
            <div
              className="d-flex justify-content-between cc-greyCard-custm mb-2 text-t-primary  mb-0"
              style={{ backgroundColor: "white" }}
            >
                          <div className="ms-2 my-2 me-2">

                    <div className="col-md mb-2">

              <h4 className="kk-bold m-2">New Expenses</h4>
                    </div>
</div>

              <Toolbar
                right={rightToolbarTemplate}
                style={{ padding: "0px", backgroundColor:"transparent", border:"none" }}
              ></Toolbar>
            </div>
          </div>
        )}
        {isLoading && (
          <ProgressSpinner
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            strokeWidth="4"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
        <Toast ref={toast} />
        {!isNewPurchase && (
          <div className="bg-white shadow-sm chart-of-account h-100 p-3 pt-1">
            <ConfirmDialog />

            {/* <div className="">
              <Toolbar
                left={leftToolbarTemplate}
                // right={tabView}
                style={{ padding: "0px" }}
                className="nExp"
              ></Toolbar>
            </div> */}

            <div className="account-table mt-1">
              <div className="card card-borderless ">
                <div className="mt-2">
                  <div className="d-flex justify-content-between flex-wrap align-items-center mb-2">
                    <div className="col-md ">
                      <Space.Compact className="my-auto">
                        <Input
                          addonBefore={<SearchOutlined />}
                          placeholder="Search"
                          value={searchValue}
                          onChange={handleSearchInputChange}
                          // onKeyUp={(e) => keySearch(e)}
                        />
                      </Space.Compact>
                    </div>
                    {/* <div className="col-md-1 mx-2 kk-table-header kk-header-sec">
                      <Primedropdown
                        value={filterGrid}
                        onChange={(e) => {
                          console.log(e.value, "v");
                          setSelectedTabMenu(e.value)
                          setFilterGrid(e.value);
                          if (e.value === "Custom") {
                            setIsCalendar(true);
                          } else {
                            setIsCalendar(false);
                            GetPurchaseListSearch(
                              page,
                              sizePerPage,
                              searchVal,
                              "",
                              "",
                              e.value,
                              "",
                              ""
                            );
                          }
                        }}
                        options={items}
                        optionLabel="label"
                        placeholder="Select status to filter"
                        className="col-auto text-start me-3 my-auto"
                      />
                      
                    </div>
                    {filterGrid === "Custom" && (
                        <div className="col-md-2">
                          <Space direction="vertical" size={12}>
                            <RangePicker onChange={onChange} />
                          </Space>
                        </div>
                      )} */}
                  </div>
                </div>
              </div>
              {showConfirmDialog && <ConfirmDialog />}
              <DataTable
                stripedRows
                value={gridList}
                //  header={header}
                // responsiveLayout="scroll"
                // size="small"
                //  loading={tdataLoad}
                paginator
                lazy
                totalRecords={totalRecords}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rowsPerPageOptions={[10, 20, 50, totalRecords]}
                first={first1}
                rows={sizePerPage}
                onPage={onCustomPage1}
                onSort={onSort}
                sortField={lazyParams.sortField}
                sortOrder={lazyParams.sortOrder}
              >
                <Column field="purchaseCode" header="Purchase No" />
                <Column
                  field="purchaseDate"
                  header="Date"
                  body={(rowData) =>
                    format(
                      new Date(rowData.purchaseDate),
                      GV.userDet.tenantConfig.dateFormat
                    )
                  }
                />

                <Column
                  field="gstTreatment"
                  header="GST Treatment"
                  style={{ width: "18%" }}
                />
                {/* <Column field="coaName" header="Account" /> */}
                {/* <Column
                  field="expenseType"
                  header="Expense Type"
                  style={{ width: "10%" }}
                /> */}
                <Column field="companyName" header="Company Name" />
                <Column
                  field="firstName"
                  header="Vendor Name"
                  body={(rowData) => `${rowData.firstName} ${rowData.lastName}`}
                  style={{ width: "15%" }}
                />
                <Column
                  field="totalAmount"
                  header="Amount"
                  className="amount-align"
                  bodyClassName="text-right-amount"
                  body={(rowData) =>
                    GV.userDet.tenantConfig.currency +
                    formatDecimal(rowData.totalAmount)
                  }
                  style={{ width: "10%" }}
                />
                <Column
                  field="totalAmount"
                  header="Balane"
                  className="amount-align"
                  bodyClassName="text-right-amount"
                  body={(rowData) =>
                    GV.userDet.tenantConfig.currency +
                    formatDecimal(rowData.balancePayment)
                  }
                  style={{ width: "10%" }}
                />
                 <Column
                field="renderStatusColumn"
                header="Status"
                body={(e) => renderStatusColumn(e)}
              ></Column>
                <Column
                  field="actionBodyTemplate"
                  header="Action"
                  body={actionBodyTemplate}
                />
              </DataTable>
            </div>
          </div>
        )}
        {isLoading && (
          <ProgressSpinner
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            strokeWidth="4"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
        {isNewPurchase && (
          <NewPurchase
            goBack={handleGoBack}
            selectedPurchase={selectedPurchase}
            setSelectedPurchase={setSelectedPurchase}
            purchaseExpList={purchaseExpList}
            documentURL={documentURL}
            showDocument={showDocument}
            onHide={onHide}
            documentURLType={documentURLType}
            setHidendShowUplod={setHidendShowUplod}
            hidendShowUplod={hidendShowUplod}
          ></NewPurchase>
        )}
        
      </BlockUI>
    </>
  );
};

export default PurchaseList;

