import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { Space, Input, Dropdown, Table, Tag, Menu, Flex } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ProgressSpinner } from "primereact/progressspinner";
import { DownOutlined } from "@ant-design/icons";
import { render } from "@testing-library/react";
import { Button, Modal } from "antd";
import { CreditNoteForm } from "./CreditNoteForm";
import GlobalVariable from "../../../../client/src/services/globalVar";
import salesService from "../../services/sales-service";
import { DataTable } from "primereact/datatable";
import { format } from "date-fns";
import { Dropdown as Primedropdown } from "primereact/dropdown";
import { Tooltip } from "antd";
import { IcomoonReact, iconSet } from "../PrimeReact";
import InvoicePayment from "./InvoicePayment";
import { Dialog } from "primereact/dialog";

const CreditNoteGrid = () => {
  const ss = new salesService();
  const paymodal = useRef(null);
  const [isProcess, setIsProcess] = useState(false);
  const [invoiceModelPop, setInvoiceModelPop] = useState(false);
  const [isHideCreditGrid, setIsHideCreditGrid] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [first1, setFirst1] = useState(0);
  const [creditGrid, setCreditGrid] = useState([]);
  const [invoiceNumberErr, setInvoiceNumberErr] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [payment, setPayment] = useState("");
  const [docUrl, setDocUrl] = useState("");
  const [showDocument, setShowDocument] = useState(false);

  const { Search } = Input;
  const items = [
    { id: 1, label: "All", value: "ALL" },
    { id: 2, label: "Pending", value: "PENDING" },
    { id: 3, label: "Paid", value: "PAID" },
  ];
  const [filterGrid, setFilterGrid] = useState(items[0].value);

  const { Column, ColumnGroup } = Table;

  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });

  const GetSalesReturnListSearch = (
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder,
    filterGrid
  ) => {
    setIsProcess(true);
    const data = {
      InputParams: {
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        ContactID: 0,
        Status: filterGrid,
      },
      ServerSearchables: {
        draw: 1,
        searchData: searchVal || "",
        orderByColumn: sortName,
        orderBy: sortOrder,
        currentPage: 1,
        pageSize: sizePerPage,
        pageNumber: page,
      },
    };

    ss.GetSalesReturnListSearch(data).then((res) => {
      setIsProcess(false);
      setCreditGrid(res.data.data?.salesCreditNoteList);
      setTotalRecords(res.data.data.serverSearchables.recordsTotal);
    });
  };

  const onCustomPage1 = (event) => {
    setFirst1(event.first);
    const val = event.page + 1;
    const size = event.rows;
    setSizePerPage(size);
    setPage(val);
    GetSalesReturnListSearch(val, size, searchVal, "", "", "");
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const handleRowClick = (event) => {
    setRowData(event.data);
    setIsHideCreditGrid(false);
  };

  const totalAmountBodyTemplate = (rowData) => {
    return (
      GlobalVariable.userDet.tenantConfig.currency +
      " " +
      rowData.totalAmount.toFixed(2)
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="d-flex">
        <div className="row">
          <div className="col-md d-flex p-0">
            {rowData.status === "PENDING" ? (
              <Tooltip title="Record Payment">
                <IcomoonReact
                  iconSet={iconSet}
                  color="#3e9d71"
                  size={20}
                  icon="payment"
                  className="pointer mx-2"
                  onClick={(e) => proceedPay(e, rowData)}
                  data-pr-tooltip="Edit"
                  data-pr-position="left"
                />
              </Tooltip>
            ) : (
              <Tooltip title="Record Payment">
                <IcomoonReact
                  iconSet={iconSet}
                  color="#3e9d71"
                  size={20}
                  style={{visibility:'hidden'}}
                  icon="payment"
                  className="pointer mx-2"
                  data-pr-tooltip="Edit"
                  data-pr-position="left"
                />
              </Tooltip>
            )}

            <Tooltip title="View Invoice">
              <i
                className="pi pi-eye icon-tooltip"
                icon="view"
                style={{ color: "slateblue", fontSize: "1.3rem" }}
                onClick={(e) => DownloadDocs(e, rowData)}
              ></i>
            </Tooltip>

            <Dialog
              visible={showDocument}
              onHide={onHide}
              header="Document"
              modal
              style={{ width: "70%", height: "100%" }}
            >
              {docUrl !== 0 && docUrl !== null && docUrl !== undefined ? (
                <iframe
                  src={docUrl}
                  style={{ width: "100%", height: "850px" }}
                />
              ) : (
                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    position: "relative",
                    top: "50%",
                    fontSize: "1.5rem",
                  }}
                >
                  No document found
                </div>
              )}
            </Dialog>
          </div>
        </div>
      </div>
    );
  };

  const onHide = () => {
    setShowDocument(false);
  };

  const proceedPay = (e, data) => {
    e.stopPropagation();
    setPayment(data);
    paymodal.current.alertToggle(data);
  };

  const dateFormBodyTemplate = (rowData) => {
    return (
      <div>
        {format(
          new Date(rowData.returnDate),
          GlobalVariable.userDet.tenantConfig.dateFormat
        )}
      </div>
    );
  };

  const nameFormBodyTemplate = (rowData) => {
    return rowData.firstName + " " + rowData.lastName;
  };

  const renderStatusColumn = (rowData) => {
    let status = rowData.status.toUpperCase();
    let statusClass;
    switch (status) {
      case "REJECTED":
        statusClass = "red";
        break;
      case "PAID":
        statusClass = "green";
        break;
      case "ACTIVE":
        statusClass = "orange";
        break;
      case "PENDING":
        statusClass = "blue";
        break;
      default:
        statusClass = "";
    }
    return (
      <Tag bordered={false} color={statusClass}>
        {status}
      </Tag>
    );
  };

  const columns = [
    { title: "Date", dataIndex: "date" },
    { title: "Credit Note Number", dataIndex: "creditNoteNumber" },
    { title: "Contact Number", dataIndex: "contactNumber" },
    { title: "Customer Name", dataIndex: "custName" },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        let color = "";
        switch (status) {
          case "Rejected":
            color = "red";
            break;
          case "InActive":
            color = "orange";
            break;
          case "Active":
            color = "green";
            break;
          case "Approved":
            color = "blue";
            break;
          default:
            color = "";
        }
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Amount",
      className: "text-end",
      dataIndex: "amount",
      render: (amount) => {
        return <span>₹ {amount.toFixed()}</span>;
      },
    },
  ];

  const onSearch = (value, _e, info) => {
    if (value === "") return setInvoiceNumberErr("Enter Invoice Number");
    const data = {
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      InvoiceNumber: value,
    };
    ss.SearchSalesInvoice(data).then((res) => {
      if (res.data.message === "This Invoice Already Returned") {
        setInvoiceNumberErr("This Invoice Already Returned");
        return
      } if (res.data.message === "Invoice Not Found") {
        setInvoiceNumberErr("Invoice Not Found");
        return
      } 
       else {
        setInvoiceNumberErr("");
        setIsHideCreditGrid(false);
        setInvoiceModelPop(false);
        setRowData(res.data.data);
      }
    });
  };

  const keySearch = (e) => {
    const searched = e.target.value;
    setSearchVal(searched);
    if (e.key === "Enter") {
      setPage(1);
      GetSalesReturnListSearch(1, sizePerPage, searched, "", "", "");
    }

    if (e.type === "keyup" && searched === "") {
      GetSalesReturnListSearch(1, sizePerPage, "", "", "", "");
    }
  };

  const emptySearch = () => {
    setSearchVal("");
    GetSalesReturnListSearch(1, sizePerPage, "", "", "", "");
  };

  const rowClick = (record) => {
    console.log(record, "row");
    setRowData(record);
  };

  const handleModelOkBtn = () => {
    setInvoiceModelPop(false);
    setIsHideCreditGrid(false);
  };
  const handleMenuClick = (e) => {
    setSelectedItem(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {items.map((item) => (
        <Menu.Item key={item.label}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  const backToCreditNote = (item) => {
    setIsHideCreditGrid(item);
    GetSalesReturnListSearch(page, sizePerPage, searchVal, "", "", "");
  };

  const parentFunction = () => {
    GetSalesReturnListSearch(page, sizePerPage, searchVal, "", "", "");
  };

  const DownloadDocs = (e, item) => {
    e.stopPropagation();
    setIsProcess(true);
    const data = {
      DocIDs: item.docID.toString(),
      DocCategory: "CreditNote",
    };
    console.log(data, "data");
    ss.DownloadCreditDocs(data).then((res) => {
      if (res.data.statusCode === 200) {
        setDocUrl(res.data?.data[0]?.documentURL);
        setShowDocument(true);
        setIsProcess(false);
      }
    });
  };

  useEffect(() => {
    GetSalesReturnListSearch(page, sizePerPage, searchVal, "", "", "");
  }, []);

  useEffect(() => {
    GetSalesReturnListSearch(page, sizePerPage, searchVal, "", "", filterGrid);
  }, [filterGrid]);

  return (
    <div className="row">
      <InvoicePayment
        ref={paymodal}
        data={{ payment }}
        parentFunction={parentFunction}
      />

      {isHideCreditGrid ? (
        <div className="col-md-12">
          <div className="card card-borderless cc-greyCard-custm mb-3">
            <div className="ms-2 mt-2 me-2">
              <div className="d-flex justify-content-between flex-wrap align-items-center mb-2">
                <h4 className="m-0">Credit Note</h4>
                <div className="d-flex justify-content-between kk-table-header kk-header-sec cc-font-size-14">
                 <div className="col">

                  <Primedropdown
                    value={filterGrid}
                    onChange={(e) => {
                      setFilterGrid(e.value);
                    }}
                    options={items}
                    optionLabel="label"
                    placeholder="Select status to filter"
                    className="col-auto text-start me-3 my-auto"
                  />

                    <Space.Compact className="me-2">
                      <Input
                        addonBefore={<SearchOutlined />}
                        allowClear={emptySearch}
                        placeholder="Search"
                        value={searchVal}
                        onChange={(e) => keySearch(e)}
                        onKeyUp={(e) => keySearch(e)}
                      />
                    </Space.Compact>

                  <button
                    className="btn btn-primary py-1 me-2 kk-btnThin my-2"
                    onClick={() => {
                      setInvoiceModelPop(true);
                      setInvoiceNumber("");
                    }}
                  >
                    {" "}
                    <i className="pi pi-plus"></i> Create New
                  </button>
                 </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="account-table">
           
            <DataTable
              className=" chofacc"
              value={creditGrid}
              // responsiveLayout="scroll"
              size="small"
              loading={isProcess}
              paginator
              lazy
              totalRecords={totalRecords}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rowsPerPageOptions={[10, 20, 50, totalRecords]}
              first={first1}
              rows={sizePerPage}
              onPage={onCustomPage1}
              onSort={onSort}
              sortField={lazyParams.sortField}
              sortOrder={lazyParams.sortOrder}
              onRowClick={handleRowClick}
            >
              <Column
                field="nameFormBodyTemplate"
                header="Customer Name"
                body={nameFormBodyTemplate}
              />
              <Column
                field="dateFormBodyTemplate"
                header="Return Date"
                body={dateFormBodyTemplate}
              />
              <Column field="invoiceNumber" header=" Invoice Number" />
              <Column field="creditNoteNumber" header="Credit Note Number" />
              <Column field="phone" header="Contact Number" />
              {/* {dynamicColumns} */}
              <Column
                field="statusBodyTemplate"
                header="Status"
                className="action"
                body={(e) => renderStatusColumn(e)}
              ></Column>
              <Column
                body={totalAmountBodyTemplate}
                header="Amount"
                className="text-end amount-align"
                field="totalAmountBodyTemplate"
              />
              <Column
                body={actionBodyTemplate}
                header="Action"
                field="actionBodyTemplate"
              />
              {/* <Column
              field="actionBodyTemplate"
              header="Action"
              // body={actionBodyTemplate}
            /> */}
            </DataTable>
            {/* {isProcess && (
              <ProgressSpinner
                style={{
                  width: "50px",
                  height: "50px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                strokeWidth="2"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            )} */}
          </div>
          {/*</div> */}
        </div>
      ) : (
        <CreditNoteForm
          data={{ rowData: rowData, backToCreditNote: backToCreditNote }}
        />
      )}

      <Modal
        title="Enter Invoice Number"
        centered
        footer={null}
        open={invoiceModelPop}
        onCancel={() => {
          setInvoiceModelPop(false);
          setInvoiceNumberErr("");
          setInvoiceNumber("");
        }}
        className="antMdl"
      >
        <div>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Search
              placeholder="INV00222"
              className="antBTn-clr"
              onSearch={onSearch}
              enterButton="GO"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
            />
          </Space>

          <p className="text-danger my-2">{invoiceNumberErr}</p>
        </div>
      </Modal>
    </div>
  );
};

export default CreditNoteGrid;
