import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import PettycashServiceService from '../../services/pettycash-service';
import { useForm } from 'react-hook-form';
import { Dialog, Button, InputText, InputTextarea, Dropdown, Toast, ToggleButton } from '../PrimeReact';
import { format } from 'date-fns';
import GV from '../../services/globalVar';

const CashTopup = forwardRef((props, ref) => {
    const toast = useRef(null);

    const its = new PettycashServiceService()
    const [itemID, setItemID] = useState(0);
    const [itemData, setItemData] = useState([]);
    const [upDisabled, setUpDisabled] = useState(true);
    const [displaynewItem, setdisplaynewItem] = useState(false);
    const [position, setPosition] = useState('center');
    const pettyDetails = props.data.pettyDetails
    const dateSelected = props.data.dateSelected

    console.log("pettyDetails abbbbbbbbcccccccc",pettyDetails?.closingBalance);
    const defaultValues = {
        balance:'',
      topupamount:'',
      total:''
       
    };
    const {
        register,
        handleSubmit, reset, formState: { errors }, control, setValue,getValues } = useForm({ mode: "all", defaultValues });

    const resetAsyncForm = (async () => {
        reset();
    });
    useImperativeHandle(ref, () => ({
        alertToggle(val) {
             console.log('pppp');
                onClick('displaynewItem',val)
        },
        // alertToggledit(val) {
        //     onClickEdit('displaynewItem', val)
        // }
    }));
    const onClick = (name, data,position) => {
        dialogFuncMap[`${name}`](true);
    
        resetAsyncForm();
        setUpDisabled(true);
        // setSiCheck(false);
        // setPiCheck(false);
        // setUpDisabled(true);
        // defaultValues.balance('200')
        setItemID(0);
        // setValue('status',true);
        if (position) {
            setPosition(position);
            console.log(val,'vals')
        }
        const val = {
            // balance:300,
            // topup:'230',
            // total:'1340'

            balance:pettyDetails?.closingBalance,
            topup:'',
            total:''
        }
        // setValue('balance', data.lastWeekBalance);
        setValue('balance', val.balance);
        setValue('topupamount', val.topup);
        setValue('total',val.total);

    }
    const dialogFuncMap = {
        'displaynewItem': setdisplaynewItem
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => onHide('displaynewItem')} className="p-button-text btn-cancel" />
                <Button type="submit" label={itemID !== "" || itemID !== 0 ? "Update" : "Save"} icon="pi pi-check" onClick={handleSubmit((onSubmit))} autoFocus className='btn btn-primary' />
            </div>
        );
    }
    const onSubmit = (data) => {
        const today = new Date()
        console.log(dateSelected,'dateSele')
        const datas ={
            "CreatedOn" : format(new Date(dateSelected), 'yyyy-MM-dd'),
            "Amount" : data.topupamount,
            "TenantID" : GV.userDet.tenantDetail.tenantID,
            "CreatedBy" : GV.userDet.appUser.appuserID.toString(),
            "UserName" : GV.userDet.appUser.appuserName
        }
        console.log(datas,"abc")

        its.SavePettyCashTopup(datas).then(res => {
            if(res.data.statusCode==200){
                props?.parentFunction();
                toast.current.show({ severity: 'success', summary: res.data.message });
            }
        })
        .catch(function (error) {
            // console.log("error",error);
            if (error.response.data.statusCode == 500) {
                toast.current.show({ severity: 'error', summary: error.response.data.message });
            }
        })
        dialogFuncMap['displaynewItem'](false);

        // props.parentFunction();
    }
  const  changeVal =(val) =>{
    // console.log(val,"VLAUe")
        setValue('topupamount',val);
        console.log('one',val,getValues('balance'))
        // console.log(Number(itemData.balance));
        const add = Number(getValues('balance')) + Number(val);
          setValue('total',add);
          console.log(add)
    }
  return (
    <div>
          <Toast ref={toast}></Toast>

<Dialog header={itemID != "" || itemID != 0 ? "Update Item" : "New Item"} visible={displaynewItem}
    onHide={() => onHide('displaynewItem')} breakpoints={{ '960px': '75vw' }}
    style={{ width: '30vw' }} footer={renderFooter('displaynewItem')}>
    <form>
        <div className='form-group row text-start'>
            <div className='row mt-3 ps-2 pe-0'>
                <div className='col-md-5'>
                <label className="form-label mb-1 required" htmlFor="type">Current Balance</label>
                </div>
                <div className='col-md-7 ps-3 pb-2'>
                    <input type="text" className="form-control px-1 py-2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing"
                        defaultValue={itemData.balance} onChange={(e) => setItemData(...e.target.value)}
                        {...register('balance', { required: false })}   disabled={upDisabled}/>
                    {/* {errors.topupamount && <p className="text-danger mb-0 ps-1">Type is required</p>} */}
                </div>
                < div className='col-md-5'>
                <label className='form-label mb-1 p-0 required' htmlFor="name">Top Up Amount</label>
                </div>
                <div className='col-md-7 ps-3 pb-2'>
                    <input type="number" className="form-control px-1 py-2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing"
                        defaultValue={itemData.topupamount}  
                        {...register('topupamount', { required: true })}    onChange={(e) => changeVal(e.target.value)}/>
                    {errors.topupamount && <p className="text-danger">This field is required</p>}
                </div>
                <div className='col-md-5'>
                <label className="form-label mb-1 required" htmlFor="type">Total Amount</label>
                </div>
                <div className='col-md-7 ps-3 pb-2'>
                    <input type="text" className="form-control px-1 py-2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing"
                        defaultValue={itemData.total} onChange={(e) => setItemData(...e.target.value)}
                        {...register('total', { required: false })}  disabled={upDisabled} />
                    {/* {errors.transaction && <p className="text-danger mb-0 ps-1">Type is required</p>} */}
                </div>

                
                
            </div>
        </div>
    </form>
</Dialog>
    </div>
  )
});

export default CashTopup;
