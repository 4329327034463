import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { Space, Input, Dropdown, Table, Tag, Menu, Flex } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import GlobalVariable from "../../../../client/src/services/globalVar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import purchaseService from "../../services/purchase-sevice";
import NewPurchaseOrderForm from "./NewPurchaseOrderForm";
import GV from "../../services/globalVar";
import { format } from "date-fns";
import { Tooltip } from "antd";
import { IcomoonReact, iconSet } from "../PrimeReact";
import salesService from "../../services/sales-service";
import { Dialog } from "primereact/dialog";

const NewPurchaseOrderGrid = () => {
  const ps = new purchaseService();
  const ss = new salesService();

  const [isProcess, setIsProcess] = useState(false);
  const [isGridShow, setIsGridShow] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [first1, setFirst1] = useState(0);
  const [purchaseOrderGrid, setPurchaseOrderGrid] = useState([]);
  const [docUrl, setDocUrl] = useState("");
  const [showDocument, setShowDocument] = useState(false);

  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });

  const GetPurchaseOrderListSearch = (
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder
  ) => {
    setIsProcess(true);
    const datas = {
      InputParams: {
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        ContactID: 0,
        Status: "",
      },
      ServerSearchables: {
        draw: 1,
        searchData: searchVal || "",
        orderByColumn: sortName,
        orderBy: sortOrder,
        currentPage: 0,
        pageSize: sizePerPage,
        pageNumber: page,
      },
    };
    ps.GetPurchaseOrderListSearch(datas).then((res) => {
      setIsProcess(false);
      setPurchaseOrderGrid(res.data.data.purchaseOrderList);
      setTotalRecords(res.data.data.serverSearchables.recordsTotal);
    });
  };

  const onCustomPage1 = (event) => {
    setFirst1(event.first);
    const val = event.page + 1;
    const size = event.rows;
    setSizePerPage(size);
    setPage(val);
    GetPurchaseOrderListSearch(val, size, searchVal, "", "", "");
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const handleRowClick = (event) => {
    if (
      event.data.purchaseStatus === "BILLED" ||
      event.data.purchaseStatus === "REJECTED"
    ) {
      event.stopPropagation();
    } else {
      setRowData(event.data);
      setIsGridShow(false);
    }
  };

  const keySearch = (e) => {
    const searched = e.target.value;
    setSearchVal(searched);
    if (e.key === "Enter") {
      setPage(1);
      GetPurchaseOrderListSearch(1, sizePerPage, searched, "", "", "");
    }

    if (e.type === "keyup" && searched === "") {
      GetPurchaseOrderListSearch(1, sizePerPage, "", "", "", "");
    }
  };

  const emptySearch = () => {
    setSearchVal("");
    GetPurchaseOrderListSearch(1, sizePerPage, "", "", "", "");
  };

  const backToPurchaseGrid = (item) => {
    setIsGridShow(item);
    GetPurchaseOrderListSearch(page, sizePerPage, searchVal, "", "");
  };

  const dateFormBodyTemplate = (rowData) => {
    return (
      <div>
        {format(
          new Date(rowData.poDate),
          GlobalVariable.userDet.tenantConfig.dateFormat
        )}
      </div>
    );
  };

  const DownloadDocs = (e, item) => {
    e.stopPropagation();
    setIsProcess(true);
    const datas = {
      DocIDs: item.docID.toString(),
      DocCategory: "PurchaseOrder",
    };
    ss.DownloadDocs(datas).then((res) => {
      if (res.data.statusCode === 200) {
        setDocUrl(res.data?.data[0]?.documentURL);
        setShowDocument(true);
        setIsProcess(false);
      }
    });
  };

  const renderStatusColumn = (rowData) => {
    let status = rowData.purchaseStatus.toUpperCase();
    let statusClass;
    switch (status) {
      case "DRAFT":
        statusClass = "#f0f0f0";
        break;
      case "OPEN":
        statusClass = "#32CD32";
        break;
      case "BILLED":
        statusClass = "rgb(199 199 233)";
        break;
      case "CONFIRMED":
        statusClass = "#FFA500";
        break;
      case "REJECTED":
        statusClass = "rgb(255 145 145)";
        break;
      default:
        statusClass = "";
    }
    return (
      <Tag bordered={false} color={statusClass}>
        {status}
      </Tag>
    );
  };

  const onHide = () => {
    setShowDocument(false);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="d-flex">
        <div className="row">
          <div className="col-md d-flex p-0">
            {rowData.purchaseStatus !== "BILLED" &&
            rowData.purchaseStatus !== "REJECTED" ? (
              <Tooltip title="Edit">
                <IcomoonReact
                  iconSet={iconSet}
                  size={20}
                  icon="edit"
                  className="pointer custom-edit-icon me-2"
                  // onClick={() => editData(rowData)}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Edit">
                <IcomoonReact
                  iconSet={iconSet}
                  size={20}
                  icon="edit"
                  className="pointer custom-edit-icon me-2"
                  style={{ visibility: "hidden" }}
                  // onClick={() => editData(rowData)}
                />
              </Tooltip>
            )}

{rowData.purchaseStatus !== "DRAFT" ? <Tooltip title="View document">
              <i
                className="pi pi-eye icon-tooltip"
                icon="view"
                style={{ color: "slateblue", fontSize: "1.3rem" }}
                onClick={(e) => DownloadDocs(e, rowData)}
              ></i>
            </Tooltip> :
            <Tooltip title="View document">
            <i
              className="pi pi-eye icon-tooltip"
              icon="view"
              style={{ color: "slateblue", fontSize: "1.3rem", visibility:"hidden" }}
            ></i>
          </Tooltip>} 
            

            <Dialog
              visible={showDocument}
              onHide={onHide}
              header="Document"
              modal
              style={{ width: "70%", height: "100%" }}
            >
              {docUrl !== 0 && docUrl !== null && docUrl !== undefined ? (
                <iframe
                  src={docUrl}
                  style={{ width: "100%", height: "850px" }}
                />
              ) : (
                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    position: "relative",
                    top: "50%",
                    fontSize: "1.5rem",
                  }}
                >
                  No document found
                </div>
              )}
            </Dialog>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    GetPurchaseOrderListSearch(page, sizePerPage, searchVal, "", "");
  }, []);

  return (
    <div className="row">
      {isGridShow ? (
        <div className="col-md-12">
          <div className="card card-borderless cc-greyCard-custm mb-3">
            <div className="ms-2 my-2 me-2">
              <div className="d-flex justify-content-between flex-wrap align-items-center mb-2">
                <h4 className="m-0">New Purchase Order</h4>
                <div className="col-md-3 d-flex justify-content-between kk-table-header kk-header-sec cc-font-size-14">
                  {/* <Primedropdown
                    value={filterGrid}
                    onChange={(e) => {
                      setFilterGrid(e.value);
                    }}
                    options={items}
                    optionLabel="label"
                    placeholder="Select status to filter"
                    className="col-auto text-start me-3 my-auto"
                  /> */}

                  <div className="col  border rounded my-auto aa-search">
                    <Space.Compact className="my-auto">
                      <Input
                        addonBefore={<SearchOutlined />}
                        allowClear={emptySearch}
                        placeholder="Search"
                        value={searchVal}
                        onChange={(e) => keySearch(e)}
                        onKeyUp={(e) => keySearch(e)}
                      />
                    </Space.Compact>
                  </div>

                  <button
                    className="btn btn-primary py-1 me-2 mx-2 kk-btnThin my-auto"
                    onClick={() => {
                      setIsGridShow(false);
                      setRowData("");
                    }}
                  >
                    {" "}
                    <i className="pi pi-plus"></i> Create New Order
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="account-table">
            <DataTable
              totalRecords={totalRecords}
              className="hdeEst"
              tableStyle={{ minWidth: "100%" }}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              lazy
              paginator
              loading={isProcess}
              first={first1}
              rows={sizePerPage}
              onPage={onCustomPage1}
              onSort={onSort}
              sortField={lazyParams.sortField}
              sortOrder={lazyParams.sortOrder}
              rowsPerPageOptions={[10, 20, 50]}
              onRowClick={handleRowClick}
              value={purchaseOrderGrid}
              emptyMessage="No user available"
            >
              <Column
                field="poNumber"
                header="PO Number"
                style={{ width: "12%" }}
              ></Column>

              <Column
                field="companyName"
                header="Company Name"
                style={{ width: "15%" }}
              ></Column>
              <Column
                field="dateBodyTemplate"
                header="PO Date"
                body={dateFormBodyTemplate}
              ></Column>
              <Column field="phone" header="Contact Number"></Column>
              <Column
                field="customerName"
                header="Customer Name"
                body={(row) => row.firstName + " " + row.lastName}
              ></Column>
              <Column
                field="totalAmount"
                header="Amount"
                className="text-end amount-align"
                body={(row) =>
                  GV.userDet.tenantConfig.currency +
                  " " +
                  row.totalAmount.toFixed(2)
                }
              ></Column>

              <Column
                field="renderStatusColumn"
                header="Status"
                body={(e) => renderStatusColumn(e)}
              ></Column>

              <Column
                field="actionBodyTemplate"
                header="Action"
                className="actionn"
                body={actionBodyTemplate}
              />
            </DataTable>
          </div>
          {/*</div> */}
        </div>
      ) : (
        <NewPurchaseOrderForm
          data={{ rowData: rowData, backToPurchaseGrid: backToPurchaseGrid }}
        />
      )}
    </div>
  );
};

export default NewPurchaseOrderGrid;
