import { format } from 'date-fns';
import React from "react";
import { useEffect } from 'react';
import { useState } from 'react';
import { AccordionTab,Accordion,DataTable,Column} from '../PrimeReact.js';
import noData from '../../assets/img/noData.png'
import { MultiSelect } from 'primereact/multiselect';

function LSContactClone({obj,setSearchInit}) {
    const [ledger,setLedger] = useState(obj)
    const [data,setData] = useState([]);
    const [totalRecordsIn,settotalRecordsIn]=useState(ledger.contactDetails.accountDetails.length)
   
   
    const [first1, setFirst1] = useState(0);
    const [tdataLoad, setTdataLoad] = useState(true);
    const [requestData, setRequestData] = useState([]);
    // const [data, setData] = useState([]);
    // const [totalRecordsIn, setTotalRecordsIn] = useState(0);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [sortName, setSortName] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [searchVal, setSearchVal] = useState('');
    const [isMounted, setIsMounted] = useState(true);

    const onCustomPage1 = (e,x) => {
        // console.log(event)
        // setFirst1(event.first);
        // const val = event.page + 1;
        // const size = event.rows;
        // setSizePerPage(size);
        // setPage(val);
       console.log(e,"E",x)

       x.first1 = e.first;
       const val = e.page + 1;
        const size = e.rows;
       x.sizeperPage = size;
       x.page = val
       console.log(e,"X",x)

    }
    const basiccolumns = [
    //    {field:'transactionDate',header:'Transaction Date',disabled:true,body:"(row)=><div> {format(new Date(row.entryDate),'MM/dd/yyyy')}</div>"},
        { field: 'contactName', header: 'Contact',disabled:true,body:"" },
        { field: 'debits', header: 'Debit',disabled:true,body:""  },
        { field: 'credits', header: 'Credit',disabled:true ,body:"" },
        { field: 'availableBalance', header: 'Closing Balance',disabled:true,body:""  },
        

    ]
    const columns = [
        
    //    {field:'transactionDate',header:'Transaction Date',disabled:true,body:"(row)=><div> {format(new Date(row.entryDate),'MM/dd/yyyy')}</div>"},
        { field: 'transactionNumber', header: 'Transaction No.',disabled:false,body:""  },
        { field: 'contactName', header: 'Contact',disabled:true,body:""  },
       
        { field: 'description', header: 'Description' ,disabled:false,body:"" },
        { field: 'openingBalance', header: 'Opening Balance',disabled:false ,body:"" },
        { field: 'debits', header: 'Debit',disabled:true ,body:"" },
        { field: 'credits', header: 'Credit',disabled:true ,body:"" },
        { field: 'availableBalance', header: 'Closing Balance',disabled:true,body:""  },
        

    ];
    
    const [selectedColumns, setSelectedColumns] = useState(basiccolumns);
    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    }

    const dynamicColumns = selectedColumns.map((col, i) => {
        return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={col.body } ></Column>
    });
    
  const headerTemplate = (data) => {
      console.log(data)
    return (
        <React.Fragment>
            <div className="d-flex justify-content-between">
            <span className="image-text">{data.accountName}</span>
                <div className="d-flex justify-content-end">
                    {
                        obj.contactDetails.accountDetails.map(x=>{
                            return(
                            x.coaName == data.accountName ? <><span className='me-4'>Opening Balance : <b>{x.openingBalance}</b></span>
                            <span >Closing Balance : <b>{x.closingBalance}</b></span> </>  : <></>
                            )
                        })
                    }
                       

                </div>
            </div>
        </React.Fragment>
    );
}
    useEffect(()=>{
   

        let datas=[]
        obj.contactDetails.accountDetails.forEach(x=>{
            x.ledgerTransaction.forEach(y=>{
                datas.push(y)
            })
        })
         setData(datas);
    },[])
    const countryBodyTemplate = (rowData) => {
            console.log(rowData)
            return (
                <React.Fragment>
                    <span >{format(new Date(rowData.transactionDate,"mm/dd/yyyy"))}</span>
                </React.Fragment>
            );
    }
  return <div>
       <div className="d-flex justify-content-between mb-2">
            <div>
            <h5>Detail Ledger</h5>

            </div>
            <div className="d-flex justify-content-end">
                    <button  className='btn btn-danger' onClick={e=>{setSearchInit(false)}}>Back</button>
            </div>
      </div>
        <div className="card min-height-700" >
            <div className="card-body">
                    <div className="row">
                        <div className="col-md-12  accordLeft">
                            <div className="  mb-2" style={{borderBottom:'2px solid #cbcbcb'}}>
                            {/* style={{backgroundColor:'#3ea0db8a'}} */}
                                <div className="card-body  ">
                                            <div className="row">
                                                {/* <div className="col-md-1"> */}
                                                    {/* <i className='pi pi-chart-line  ms-4' style={{fontSize:'4rem'}}></i> */}
                                                    {/* AiOutlineLineChart */}
                                                    {/* FaChartLine */}
                                                {/* </div> */}
                                                <div className="col-md-6"  style={{borderRight:'2px solid #cbcbcb'}}>
                                                    <b>Company Name :  </b> 
                                                    {ledger.contactDetails.contact.companyName}
                                                        <br/>
                                                    <b>Ledger Duration : </b> {ledger.inputParams.period}
                                                </div>
                                                <div className="col-md-6">
                                                    <b> Contact Person : </b>   {ledger.contactDetails.contact.displayName}
                                                    <br/>
                                                    <b> Contact Type : </b>   {ledger.contactDetails.contact.contactType}
                                                </div>
                                                {/* <div className="col-md-1"></div> */}
                                               
                                            </div>
                                </div>
                            </div>
                          
                            
                            {ledger.contactDetails.accountDetails.length == 0 ? 
                            <>
                             <div className="d-flex justify-content-center">
                                <img src={noData}    alt='' width={150} /> 
                             </div>
                             <div className="d-flex justify-content-center">
                                 <h5>No Transactions Found</h5> 
                            </div>
                            </>
                            
                            :
                            <div className='ledger'>
                                <div className="d-flex justify-content-end mm mb-1">
                                <MultiSelect  value={selectedColumns} options={columns} optionLabel="header" optionDisabled="disabled" onChange={onColumnToggle} style={{ width: '3em' }} scrollHeight="600px" dropdownIcon="pi pi-table"
                                    tooltip="Custom Columns" tooltipOptions={{ position: 'top' }} showSelectAll={false} />
                                </div>
                                    <DataTable value={data} rowGroupMode="subheader" groupRowsBy="accountName"   rowGroupHeaderTemplate={headerTemplate} 
                                    sortMode="single" sortField="accountName" sortOrder={1} 
                                    // responsiveLayout="scroll"
                                    >
                                    <Column field="transactionDate" header="Date"   body={(row)=><div> {format(new Date(row.transactionDate),'MM/dd/yyyy')}</div>} ></Column>
                               
                                    {dynamicColumns}
                                 
                                </DataTable>
                            </div>
                            }
                        </div>
                    </div>
            </div>
        </div>
  </div>;
}

export default LSContactClone;
