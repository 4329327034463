import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from "react";

import { Controller, useForm } from "react-hook-form";

import "../../assets/styles/items/newItem.scss";
import ItemsService from "../../services/items";
import {
  Dialog,
  Button,
  InputText,
  InputTextarea,
  Dropdown,
  Toast,
  ToggleButton,
} from "../PrimeReact";
import { Row } from "react-bootstrap";
import GV from "../../services/globalVar";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import JournalService from "../../services/journal";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";

let schema = yup.object().shape({
  //basic
  type: yup.string().required("This field is required"),
  name: yup.string().required("This field is required"),
  unit: yup.string().required("This field is required"),
  stocks: yup.string(),
  code: yup.string().required("This field is required"),
  status: yup.string().required("This field is required"),
  //sales info
  salescheck: yup.boolean(),
  sellingprice: yup.string(),
  siAccount: yup.string(),
  status: yup.string(),
  description: yup.string(),
  //cost info
  costcheck: yup.boolean(),
  costprice: yup.string(),
  piAccount: yup.string(),
  description1: yup.string(),
  //tax
  taxpreference: yup.string(),
  cgst: yup.string().required("This field is required"),
  sgst: yup.string().required("This field is required"),
  igst: yup.string().required("This field is required"),
});

const NewItem = forwardRef((props, ref) => {
  const toast = useRef(null);
  const jor = new JournalService();
  const categories = [
    { name: "Business", key: "A" },
    { name: "Individual", key: "M" },
  ];
  const [selectedAccount1, setSelectedAccount1] = useState(null);
  const [selectedAccount2, setSelectedAccount2] = useState(null);
  const [accounts1, setAccounts1] = useState(false);
  const [accounts2, setAccounts2] = useState(false);
  const [accountVl, setAccountVl] = useState("");
  const [accountVl1, setAccountVl1] = useState("");
  const [itemData, setItemData] = useState([]);
  const [upDisabled, setUpDisabled] = useState(true);
  const [upDisabled1, setUpDisabled1] = useState(true);
  const [siCheck, setSiCheck] = useState(false);
  const [piCheck, setPiCheck] = useState(false);
  const [itemID, setItemID] = useState(0);
  const [displaynewItem, setdisplaynewItem] = useState(false);
  const [position, setPosition] = useState("center");
  const [accountList, setAccountList] = useState([]);
  const [taxPreference, setTaxPreference] = useState("");
  const [isProcess, setIsProcess] = useState(false);

  const its = new ItemsService();
  // let iId = props.data.itemId;

  const defaultValues = {
    type: "",
    name: "",
    unit: "",
    stocks: "",
    code: "",

    salescheck: false,
    sellingprice: "",
    siAccount: "",
    status: "",
    description: "",

    costcheck: false,
    costprice: "",
    piAccount: "",
    description1: "",

    taxpreference: "",
    cgst: 0,
    sgst: 0,
    igst: 0,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const resetAsyncForm = async () => {
    reset();
  };

  const checkValidation = (e) => {
    console.log("vals", schema);
    setValue("salescheck", e.target.checked);
    if (getValues("salescheck")) {
      setUpDisabled(false);
      schema = yup.object().shape({
        ...schema.fields,
        sellingprice: yup.string().required("This field is required"),
        // siAccount: yup.string().required('This field is required'),
        // description: yup.string().required('This field is required')
      });
    } else {
      setUpDisabled(true);
      schema = yup.object().shape({
        ...schema.fields,
        sellingprice: yup.string().notRequired(""),
        siAccount: yup.string(),
        status: yup.string(),
        description: yup.string(),
      });
    }
  };
  const checkCostValidation = (e) => {
    setValue("costcheck", e.target.checked);
    console.log(e, "vals", schema);
    let copySchema = schema;
    if (getValues("costcheck")) {
      copySchema = yup.object().shape({
        ...schema.fields,
        costprice: yup.string().required("This field is required"),
        // piAccount: yup.string().required('This field is required'),
        // description1: yup.string().required('This field is required')
      });
    } else {
      setUpDisabled(true);
      copySchema = yup.object().shape({
        ...schema.fields,
        costprice: yup.string().notRequired(""),
        piAccount: yup.string(),
        description1: yup.string(),
      });
    }
    schema = copySchema;
    console.log("vals", schema);
  };

  useImperativeHandle(ref, () => ({
    alertToggle() {
      onClick("displaynewItem");
    },
    alertToggledit(val) {
      onClickEdit("displaynewItem", val);
    },
  }));

  const units = [
    { label: "meter", value: "m" },
    { label: "kilogram", value: "kg" },
    { label: "second", value: "s" },
    { label: "kelvin", value: "K" },
    { label: "ampere", value: "D" },
    { label: "units", value: "u" },
    { label: "liter", value: "l" },
  ];

  const taxpre = [
    {
      id: 1,
      name: "Taxable",
      value: "Taxable",
    },
    {
      id: 2,
      name: "Non-taxable",
      value: "Non-taxable",
    },
    { id: 3, name: "Out of scope", value: "Out of scope" },
    { id: 4, name: "Non-GST Supply", value: "Non-GST Supply" },
  ];

  // const account = [
  //     { label: 'Account 1', value: 'A1' },
  //     { label: 'Account 2', value: 'A2' },
  //     { label: 'Account 3', value: 'A3' },
  //     { label: 'Account 4', value: 'A4' },
  //     { label: 'Account 5', value: 'A5' }
  // ];

  // const account1 = [
  //     { label: 'Account 6', value: 'A6' },
  //     { label: 'Account 7', value: 'A7' },
  //     { label: 'Account 8', value: 'A8' },
  //     { label: 'Account 9', value: 'A9' },
  //     { label: 'Account 10', value: 'A10' }
  // ];

  const onAccountChange1 = (e) => {
    setSelectedAccount1(e.value);
    setAccountVl(e.value.code);
    setItemData(e.target.value);
    setAccounts1(true);
  };

  const onAccountChange2 = (e) => {
    setSelectedAccount2(e.value);
    setAccountVl1(e.value.code);
    setItemData(e.target.value);
    setAccounts2(true);
  };

  const dialogFuncMap = {
    displaynewItem: setdisplaynewItem,
  };

  const onClickEdit = (name, value) => {
    dialogFuncMap[`${name}`](true);
    resetAsyncForm();
    console.log("ed", value);
    setItemID(value.itemID);
    setValue("type", value.itemType);
    setValue("name", value.name);
    setValue("unit", value.unit);
    setValue("stocks", value.stocks);
    setValue("salescheck", value.isSale);
    setValue("code", value.saCorHSNCode);

    setValue("costcheck", value.isPurchase);
    setValue("sellingprice", value.siSellingPrice);
    setValue("siAccount", value.siAccount);
    setValue("description", value.siDescription);
    setValue("costprice", value.piCostPrice);
    setValue("piAccount", value.piAccount);
    setValue("description1", value.piDescription);

    setValue("taxpreference", value.taxPreference);
    setValue("cgst", value.centralTaxRate);
    setValue("sgst", value.localTaxRate);
    setValue("igst", value.interTaxRate);

    const taxPre = taxpre.find((a) => a.value === value.taxPreference);
    console.log(taxPre, "taxPre");
    setTaxPreference(taxPre.value);

    if (value.status === "Active") {
      setValue("status", true);
    } else {
      setValue("status", false);
    }

    // setUpDisabled(false);
    // setSiCheck(true);

    if (position) {
      setPosition(position);
    }
  };
  const handleSelectTaxPreference = (e) => {
    //    data.sgst
    setTaxPreference(e.target.value);
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
    resetAsyncForm();
    setUpDisabled(true);
    setSiCheck(false);
    setPiCheck(false);
    setUpDisabled1(true);
    setItemID(0);
    setValue("status", true);
    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setTaxPreference("");
  };

  const onInvalid = (errors) => console.error(errors);

  const onSubmit = (data) => {
    console.log(data);
setIsProcess(true);
    const payload = {
      ITemID: itemID,
      ItemType: data.type,
      Name: data.name,
      Unit: data.unit,
      ServiceCodeName: data.type === "service" ? "SAC" : "HSC",
      SACorHSNCode: data.code,
      TaxPreferenceID: 0,
      TaxPreference: taxPreference === "" ? "Non-taxable" : taxPreference,
      LocalTaxName: "SGST",
      LocalTaxRate: taxPreference === "Taxable" ? data.sgst : 0,
      LocalTaxAmount: 0,
      InterTaxName: "IGST",
      InterTaxRate: taxPreference === "Taxable" ? data.igst : 0,
      InterTaxAmount: 0,
      CentralTaxName: "CGST",
      CentralTaxRate: taxPreference === "Taxable" ? data.cgst : 0,
      CentralTaxAmount: 0,
      isSale: data.salescheck,
      SISellingPrice: data.sellingprice || 0.0,
      SIAccount: data.siAccount,
      SIDescription: data.description || "",
      isPurchase: data.costcheck,
      PICostPrice: data.costprice || 0.0,
      PIAccount: data.piAccount,
      PIDescription: data.description1 || "",
      VendorID: 0,
      VendorName: "",
      TenantID: GV.userDet.tenantDetail.tenantID,
      Status: data.status === "true" ? "Active" : "InActive",
      Stocks: data.stocks || 0,
      CreatedBy: GV.userDet.appUser.userName,
    };
    console.log(payload, "dats");
    its
      .SaveItems(payload)
      .then((res) => {
        console.log(res);
setIsProcess(false);

        if (res.data.message === "Successfully added Items") {
          props.parentFunction();
          toast.current.show({
            severity: "success",
            summary: res.data.message,
          });
        }
        if (res.data.message === "Successfully modified Items") {
          props.parentFunction();
          toast.current.show({
            severity: "success",
            summary: res.data.message,
          });
        }
        if (
          res.data.message !== "Successfully added Items" &&
          res.data.message !== "Successfully modified Items"
        ) {
          toast.current.show({
            severity: "warning",
            summary: res.data.message,
          });
        }
    dialogFuncMap["displaynewItem"](false);

      })
      .catch(function (error) {
        console.log("message", error.response.data.message);
        if (error.response.data.statusCode == 500) {
          toast.current.show({
            severity: "error",
            summary: error.response.data.message,
          });
        }
      });
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide("displaynewItem")}
          className="p-button-text btn-cancel"
        />
        <Button
          type="submit"
          label={itemID != "" || itemID != 0 ? "Update" : "Save"}
          icon="pi pi-check"
          onClick={handleSubmit(onSubmit)}
          autoFocus
          className="btn btn-primary"
        />
      </div>
    );
  };
  const changeVal = (ev) => {
    console.log(ev, "we");
  };
  const initMethods = () => {
    jor
      .GetMasCOA(0, GV.userDet.tenantDetail.tenantID, "Active", "All")
      .then((response) => {
        let b = [];
        if (response.data) {
          if (response.data.statusCode === 200) {
            const data = response.data.data;
            let accounts = [];
            data.forEach((x) => {
              x.category.forEach((y) => {
                accounts = [...accounts, y];
              });
              x.subCategory.forEach((z) => {
                accounts = [...accounts, z];
              });
            });
            console.log(accounts, "AAA");
            setAccountList(accounts);
          } else {
          }
        }
        // }
      })
      .catch(function (error) {});
  };
  // const accountOptions = setAccountList.map((acc) => {
  //     return (
  //         {
  //             label: acc.type,
  //             code: acc.type,

  //             items: subCategory.filter(sub => sub.coaType == acc.type).map(s => {
  //                 return (
  //                     {
  //                         label: s.coaName,
  //                         value: s.coaName,
  //                         id: s.coaMasID,
  //                         category:s.coaCategory,
  //                         type:s.coaType
  //                     }
  //                 )
  //             })
  //         })

  // });
  watch("type");

  useEffect(() => {
    register("type");
    register("status");
    initMethods();
  }, [register]);

  return (
    <div>
      <BlockUI blocked={isProcess}>
        <Toast ref={toast}></Toast>

        <Dialog
          header={itemID != "" || itemID != 0 ? "Update Item" : "New Item"}
          visible={displaynewItem}
          onHide={() => onHide("displaynewItem")}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "60vw" }}
          footer={renderFooter("displaynewItem")}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row text-start">
              <div className="row">
                <div className="col">
                  <fieldset className="scheduler-border">
                    <legend className="scheduler-border float-none w-auto p-2">
                      BASIC INFO
                    </legend>
                    <div className="row  pe-0">
                      <div className="col-md-3 ">
                        <label
                          className="form-label mb-1 required"
                          htmlFor="type"
                        >
                          Type
                        </label>
                        <div className="px-1 pt-2 mb-1">
                          <label className="md-3 g-0 me-4">
                            <input
                              type="radio"
                              {...register("type")}
                              name="type"
                              value="goods"
                              defaultValue={itemData.type}
                            />{" "}
                            Goods
                          </label>
                          <label className="form-label">
                            <input
                              type="radio"
                              {...register("type")}
                              name="type"
                              value="service"
                              defaultValue={itemData.type}
                            />{" "}
                            Service
                          </label>
                        </div>
                        {errors.type && (
                          <p className="text-danger mb-0 ps-1">
                            {errors.type?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-md-8 pe-4">
                        <label
                          className="form-label mb-1 p-0 required"
                          htmlFor="name"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control px-1 py-2"
                          aria-label="Sizing example input"
                          aria-describedby="inputGroup-sizing"
                          {...register("name")}
                        />
                        {errors.name && (
                          <p className="text-danger mb-0 ps-1">
                            {errors.name?.message}
                          </p>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-3 ">
                          <label
                            className="form-label mb-1 p-0 required"
                            htmlFor="stock"
                          >
                            {getValues("type") === "goods" ? "HSC" : "SAC"} Code
                          </label>
                          <input
                            type="text"
                            className="form-control px-1 py-2"
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing"
                            {...register("code")}
                          />
                          {errors.code && (
                            <p className="text-danger mb-0 ps-1">
                              {errors.code?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-md-3 px-4">
                          <Row>
                            <label className="p-0 form-label mb-1 required">
                              Unit{" "}
                            </label>
                            <Controller
                              render={({ field }) => (
                                <Dropdown
                                  options={units}
                                  {...field}
                                  appendTo="self"
                                  className="unit-dropdown"
                                  optionLabel="label"
                                  optionValue="value"
                                  placeholder="Select Unit"
                                  {...register("unit")}
                                />
                              )}
                              control={control}
                              name="unit"
                            />
                          </Row>
                          {errors.unit && (
                            <p className="text-danger mb-0 ps-1">
                              {errors.unit?.message}
                            </p>
                          )}
                        </div>

                        <div className="col-md-3 px-4">
                          <label
                            className="form-label mb-1 p-0"
                            htmlFor="stock"
                          >
                            Stock
                          </label>
                          <input
                            type="text"
                            className="form-control px-1 py-2"
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing"
                            defaultValue={itemData.stocks}
                            onChange={(e) => setItemData(...e.target.value)}
                            {...register("stocks")}
                          />
                          {errors.stocks && (
                            <p className="text-danger mb-0 ps-1">
                              {errors.stocks?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-md-3 pe-0 px-4">
                          <Row>
                            <label className="p-0 form-label mb-1 required">
                              Status{" "}
                            </label>
                            <Controller
                              render={({ field }) => (
                                <ToggleButton
                                  style={{ width: "35%" }}
                                  {...field}
                                  checked={field.value}
                                  className="item-status"
                                  onLabel="Active"
                                  offLabel="Inactive"
                                  onIcon="pi pi-check"
                                  offIcon="pi pi-times"
                                />
                              )}
                              control={control}
                              name="status"
                              defaultValue={defaultValues.status}
                            />
                            {errors.status && (
                              <p className="text-danger mb-0 ps-1">
                                {errors.status?.message}
                              </p>
                            )}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="form-group row">
                <div className="col">
                  <fieldset className="scheduler-border">
                    <legend className="scheduler-border float-none w-auto p-2">
                      Tax Preference
                      <Controller
                        render={({ field }) => (
                          <Dropdown
                            options={taxpre}
                            onChange={(e) => handleSelectTaxPreference(e)}
                            style={{ width: "300px" }}
                            value={taxPreference}
                            defaultValue={taxpre[0].value}
                            // {...field}
                            appendTo="self"
                            className="unit-dropdown ms-2"
                            optionLabel="name"
                            optionValue="value"
                            placeholder=""
                            // {...register('taxpreference')}
                          />
                        )}
                        control={control}
                        name="taxpreference"
                      />
                    </legend>
                    {taxPreference === "Taxable" && (
                      <div className="row">
                        <div className="col">
                          <label>CGST</label>
                          <input
                            type="number"
                            {...register("cgst")}
                            className="form-control"
                          />
                          {errors.cgst && (
                            <p className="text-danger mb-0 ps-1">
                              {errors.cgst?.message}
                            </p>
                          )}
                        </div>
                        <div className="col">
                          <label>SGST</label>
                          <input
                            type="number"
                            {...register("sgst")}
                            className="form-control"
                          />
                          {errors.sgst && (
                            <p className="text-danger mb-0 ps-1">
                              {errors.sgst?.message}
                            </p>
                          )}
                        </div>
                        <div className="col">
                          <label>IGST</label>
                          <input
                            type="number"
                            {...register("igst")}
                            className="form-control"
                          />
                          {errors.igst && (
                            <p className="text-danger mb-0 ps-1">
                              {errors.igst?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </fieldset>
                </div>
              </div>

              {isProcess && (
                      <ProgressSpinner
                        style={{
                          width: "50px",
                          height: "50px",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        strokeWidth="2"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                      />
                    )}
              {/* <div className="final"> */}
              <div className=" form-group row ">
                <div className="col">
                  <fieldset className="scheduler-border">
                    <legend className="scheduler-border float-none w-auto p-2">
                      <input
                        className="form-check-input me-2"
                        type="checkbox"
                        {...register("salescheck")}
                        onChange={(e) => checkValidation(e)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Sales Information
                      </label>
                    </legend>
                    <div>
                      <div className="row ">
                        <label
                          className="form-label mb-1 required"
                          htmlFor="name"
                        >
                          Selling Price
                        </label>

                        <div className="input-group input-group-sm">
                          <span className="input-group-text" id="basic-addon1">
                            {GV.userDet.tenantConfig.currency}
                          </span>
                          <input
                            type="number"
                            className="form-control px-1 py-2"
                            {...register("sellingprice", {
                              disabled: watch("salescheck") === false,
                            })}
                          />
                        </div>
                        {errors.sellingprice && (
                          <p className="text-danger mb-0 ps-3">
                            {errors.sellingprice?.message}
                          </p>
                        )}
                      </div>
                      <div className="form-group row mx-0 mb-1">
                        <label className="col-sm-4 col-form-label mb-1 pb-0 ps-0">
                          Account
                        </label>
                        <Controller
                          render={({ field }) => (
                            <Dropdown
                              options={accountList}
                              onChange={onAccountChange1}
                              {...field}
                              appendTo="self"
                              optionLabel="coaName"
                              optionValue="coaName"
                              {...register("siAccount", {
                                disabled: watch("salescheck") === false,
                              })}
                            />
                          )}
                          control={control}
                          name="siAccount"
                        />
                      </div>
                      <div className="mt-0">
                        <label
                          className="form-label mb-1"
                          htmlFor="description"
                        >
                          Description
                        </label>
                        <textarea
                          type="text"
                          className="form-control px-1 py-2"
                          {...register("description", {
                            disabled: watch("salescheck") === false,
                          })}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="col ">
                  <fieldset className="scheduler-border">
                    <legend className="scheduler-border float-none w-auto p-2">
                      <input
                        className="form-check-input me-2"
                        type="checkbox"
                        {...register("costcheck")}
                        onChange={(e) => checkCostValidation(e)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Purchases Information
                      </label>
                    </legend>
                    <div>
                      <div className="row">
                        <label
                          className="form-label mb-1 required"
                          htmlFor="name"
                        >
                          Cost Price
                        </label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text" id="basic-addon1">
                            {GV.userDet.tenantConfig.currency}
                          </span>
                          <input
                            type="number"
                            className="form-control px-1 py-2"
                            {...register("costprice", {
                              disabled: watch("costcheck") === false,
                            })}
                          />
                        </div>
                        {errors.costprice && (
                          <p className="text-danger mb-0 ps-3">
                            {errors.costprice?.message}
                          </p>
                        )}
                      </div>
                      <div className="form-group row mx-0 mb-1">
                        <label className="col-sm-4 col-form-label mb-1 pb-0 ps-0">
                          Account{" "}
                        </label>
                        <Controller
                          render={({ field }) => (
                            <Dropdown
                              options={accountList}
                              onChange={onAccountChange2}
                              {...field}
                              appendTo="self"
                              {...register("piAccount", {
                                disabled: watch("costcheck") === false,
                              })}
                              optionLabel="coaName"
                              optionValue="coaName"
                            />
                          )}
                          control={control}
                          name="piAccount"
                          defaultValue={itemData.piAccount}
                        />
                      </div>
                      <div className="mt-0">
                        <label
                          className="form-label mb-1"
                          htmlFor="description"
                        >
                          Description
                        </label>
                        <textarea
                          type="text"
                          className="form-control px-1 py-2"
                          {...register("description1", {
                            disabled: watch("costcheck") === false,
                          })}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              {/* </div> */}
            </div>
          </form>
        </Dialog>
      </BlockUI>
    </div>
  );
});

export default NewItem;
