import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../assets/styles/vendor/vendor.scss";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { format } from "date-fns";
import { Toast } from "primereact/toast";
import GV from "../../services/globalVar";
import { ProgressSpinner } from "primereact/progressspinner";

//API call
import ContactsService from "../../services/contactsService";
import { BlockUI } from "primereact/blockui";

const NewVendors = forwardRef((props, ref) => {
  const cos = new ContactsService();

  const [vendorID, setVenodrID] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [date, setDate] = useState(null);
  const [vendorData, setVendorData] = useState([]);
  const [displayNewVendor, setDisplayNewVendor] = useState(false);
  const [position, setPosition] = useState("center");
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [isProcess, setIsProcess] = useState(false);

  const defaultValues = {
    //1
    salType: "",
    fname: "",
    lname: "",
    cname: "",
    dname: "",
    email: "",
    con: "",
    website: "",
    pan: "",
    openingBalance: 0,
    // curType: "",
    bAddress1: "",
    bAddress2: "",
    bCity: "",
    bState: "",
    bCountry: "",
    bZipCode: "",
    sAddress1: "",
    sAddress2: "",
    sCity: "",
    sState: "",
    sCountry: "",
    sZipCode: "",
  };
  let vId = props.data.vendorId;
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    control,
    setValue,
  } = useForm({ mode: "all", defaultValues });
  const resetAsyncForm = async () => {
    reset();
  };

  useImperativeHandle(ref, () => ({
    alertToggle() {
      onClick("displayNewVendor");
    },
    alertToggledit(val) {
      onClickEdit("displayNewVendor", val);
    },
  }));

  const handleDateChange = (date) => {
    setDate(date);
  };

  const salutation = [
    { label: "Mr.", value: "Mr." },
    { label: "Ms.", value: "Ms." },
    { label: "Mrs.", value: "Mrs." },
    { label: "Miss.", value: "Miss." },
    { label: "Dr.", value: "Dr." },
  ];

  const currency = [
    {id:1, label: "INR-Indian Rupee", value: "INR" },
    {id:2, label: "Dollar", value: "Dlr" },
    {id:3, label: "Euro", value: "Ero" },
    {id:4, label: "Soil", value: "Sol" },
  ]
  const toast = useRef(null);

  const dialogFuncMap = {
    displayNewVendor: setDisplayNewVendor,
  };

  const onClickEdit = (name, value) => {
    console.log(value,'value');
    dialogFuncMap[`${name}`](true);
    resetAsyncForm();
    // console.log(value);
    setVenodrID(value.ContactID);
    setValue("salType", value.salutation);
    setValue("fname", value.firstName);
    setValue("lname", value.lastName);
    setValue("cname", value.companyName);
    setValue("dname", value.displayName);
    setValue("email", value.email);
    setValue("con", value.phone);
    setValue("website", value.website);
    setValue("pan", value.pan);
    setValue("openingBalance", value.openingBalance);
    setValue("bAddress1", value.baAddress1);
    setValue("bAddress2", value.baAddress2);
    setValue("bCity", value.baCity);
    setValue("bState", value.baState);
    setValue("bCountry", value.baCountry);
    setValue("bZipCode", value.baZipCode);
    setValue("sAddress1", value.saAddress1);
    setValue("sAddress2", value.saAddress2);
    setValue("sCity", value.saCity);
    setValue("sState", value.saState);
    setValue("sCountry", value.saCountry);
    setValue("sZipCode", value.saZipCode);
    // setValue("date",value.createdOn);
    setValue("date",format(new Date(value.createdOn), "MM-dd-yyyy"))

    if(value.baAddress1 === value.saAddress1 && value.baAddress2 === value.saAddress2
      && value.baCity === value.saCity && value.baState === value.saState && value.baCountry === value.saCountry && value.baZipCode === value.saZipCode
    ) {
      setIsSameAddress(true);
    } else {
      setIsSameAddress(false);
    }


    let findCurrency = currency.find((a) => a.value === value.currencySymbol);
    console.log(findCurrency,'findCurrency');
    setSelectedCurrency(findCurrency?.value);
    setValue("curType", findCurrency?.value);
    // if (value.status === 'Active') {
    //     setValue('accntActive', true)
    // } else {
    //     setValue('accntActive', false)
    // }
    if (position) {
      setPosition(position);
    }

    console.log("abc");
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
    resetAsyncForm();
    setVenodrID(0);

    setValue("salType", salutation[0].value);
    // setValue('accntActive', true)
    // setValue('accntCode',accntTypes[0].value)
    //  setValue('accntName',accntTypes[0].value)
    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    setSelectedCurrency('')
    dialogFuncMap[`${name}`](false);
  };
  const [currencys, setCurrencys] = useState("");

  const onSubmit = (data) => {
    console.log(data, "datasss");
    // console.log(data.curType.optionLabel);
    setIsProcess(true);

    const datas = {
      ContactID: vId || 0,
      Salutation: data.salType,
      FirstName: data.fname,
      LastName: data.lname,
      CompanyName: data.cname,
      DisplayName: data.dname,
      Email: data.email,
      Phone: data.con,
      SkypeNameNumber: "",
      Designation: "",
      Department: "",
      Website: data.website,
      PAN: data.pan,
      GSTTreatment: "",
      GSTNumber: "",
      Supply: "",
      CurrencyName: "",
      CurrencyCode: "",
      CurrencySymbol: selectedCurrency,
      ExchangeRate: 90.45,
      PaymentTerms: "",
      TDS: "",
      Facebook: "",
      Twitter: "",
      Remarks: "",
      TenantID: GV.userDet.tenantDetail.tenantID,
      BAAttention: "",
      Currency_Code: data.curType,
      BACountry: data.bCountry.trim(),
      BAAddress1: data.bAddress1.trim(),
      BAAddress2: data.bAddress2.trim(),
      BACity: data.bCity.trim(),
      BAState: data.bState.trim(),
      BAZipCode: data.bZipCode.trim(),
      BAPhone: "",
      BAFax: "",
      SAAttention: "",
      SACountry: data.sCountry.trim(),
      SAAddress1: data.sAddress1.trim(),
      SAAddress2: data.sAddress2.trim(),
      SACity: data.sCity.trim(),
      SAState: data.sState.trim(),
      SAZipCode: data.sZipCode.trim(),
      SAPhone: "",
      SAFax: "",
      Status: "Active",
      OpeningBalance: data.openingBalance,
      ClosingBalance: 5000.75,
      CreatedBy: "",
      Date: data.date ? format(new Date(data.date), "yyyy-MM-dd") : null,
    };
    console.log("df", datas);
    cos
      .SaveVendor(datas)
      .then((res) => {
        console.log(res);

        // if (res.data.message === 'Successfully added Vendor') {
        //     props.parentFunction();
        //     props.data.fetchVendorList(1,10);
        //     toast.current.show({ severity: 'success', summary: 'Successfully Created', detail: 'Successfully Created Vendor' });
        // }
        // if (res.data.message === 'Successfully modified Vendor') {
        //     props.parentFunction();
        //     props.data.fetchVendorList(1,10);
        //     toast.current.show({ severity: 'success', summary: 'Successfully Updated Vendor' });
        // }
        if (res.data.statusCode === 200) {
       setIsProcess(false);
          props.parentFunction();
          toast.current.show({
            severity: "success",
            summary: res.data.message,
          });
          dialogFuncMap["displayNewVendor"](false);
          setIsSameAddress(false);
          // props.data.fetchVendorList(1,10);
        } else {
       setIsProcess(false);

        }
      })
      .catch(function (error) {
        if (error.response?.data?.message === "Vendor Already Exists") {
          toast.current.show({
            severity: "error",
            summary: error.response.data.message,
            detail: "Vendor Already Exists",
          });
        }
      });
    

  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide("displayNewVendor")}
          className="p-button-text btn-cancel"
        />

        <Button
          type="submit"
          label={vId != "" || vId != 0 ? "Update" : "Save"}
          icon="pi pi-check"
          onClick={handleSubmit(onSubmit)}
          autoFocus
          className="btn btn-primary"
        />
        {/* <Button type="submit" label="Update" icon="pi pi-check" onClick={handleSubmit((onSubmit))} autoFocus className='btn btn-primary' /> */}
      </div>
    );
  };

  const handleCheckboxChange = (e) => {
    setIsSameAddress(e.target.checked);
    
    if (e.target.checked) {
      setValue("sAddress1", getValues("bAddress1"));
      setValue("sAddress2", getValues("bAddress2"));
      setValue("sCity", getValues("bCity"));
      setValue("sState", getValues("bState"));
      setValue("sCountry", getValues("bCountry"));
      setValue("sZipCode", getValues("bZipCode"));
    } else {
      setValue("sAddress1", "");
      setValue("sAddress2", "");
      setValue("sCity", "");
      setValue("sState", "");
      setValue("sCountry", "");
      setValue("sZipCode", "");
    }
  };

  // useEffect(() => {
  //   register("salType");
  //   // register("accntActive");
  // }, [register]);

  const handleDropdown = (e) => {
    console.log(e.target, "dropdoll");
    setSelectedCurrency(e.target.value);
  };

  return (
    <div>
      <BlockUI blocked={isProcess}> 

      <Toast ref={toast}></Toast>

      <Dialog
        header={vId != "" || vId != 0 ? "Update Vendor" : "New Vendor"}
        visible={displayNewVendor}
        onHide={() => onHide("displayNewVendor")}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
        footer={renderFooter("displayNewVendor")}
      >
        <form>
          <div className="p-3 form">
            <div className="row ">
                  <div className="col-4 p-0">
                    <label className="ps-0 ">Salutation</label>
                    <Controller
                      render={({ field }) => (
                        <Dropdown
                          className="form-control"
                          options={salutation}
                          {...field}
                          appendTo="self"
                          optionLabel="label"
                          optionValue="value"
                        />
                      )}
                      control={control}
                      name="salType"
                      defaultValue={vendorData?.salType}
                      // rules={{ required: "Salutation Type Required" }}
                    />
                    {/* <p className='text-danger'> {errors.salType?.message} </p> */}
                  </div>
                  <div className="col-4  px-1">
                      <label className="ps-0 required">First Name</label>
                      <input
                        type="text"
                        className="form-control "
                        defaultValue={vendorData?.fname}
                        onChange={(e) => setVendorData(...e.target.value)}
                        {...register("fname", { required: true })}
                      />
                      {errors.fname && (
                        <p className="text-danger">This field is required</p>
                      )}
                  </div>
                  <div className="col-4 p-0">
                    <label className="ps-0 required">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={vendorData?.lname}
                      onChange={(e) => setVendorData(...e.target.value)}
                      {...register("lname", { required: true })}
                    />
                    {errors.lname && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>

              <div className="col-6 pe-2 ps-0">
                  <label className="ps-0 required">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={vendorData?.cname}
                    onChange={(e) => setVendorData(...e.target.value)}
                    {...register("cname", { required: true })}
                  />
                  {errors.cname && (
                    <p className="text-danger">This field is required</p>
                  )}
              </div>
              <div className="col-6 p-0">
                  <label className="ps-0 required">Display Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={vendorData?.dname}
                    onChange={(e) => setVendorData(...e.target.value)}
                    {...register("dname", { required: true })}
                  />
                  {errors.dname && (
                    <p className="text-danger">This field is required</p>
                  )}
              </div>

              <div className="col-6 pe-2 ps-0">
                  <label className="ps-0 required">Email</label>
                  <input
                    type="text"
                    className=" form-control"
                    defaultValue={vendorData?.email}
                    onChange={(e) => setVendorData(...e.target.value)}
                    {...register("email", {
                      required: "This field is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                      validate: (value) => {
                        const domain = value.split("@")[1];
                        if (/\d/.test(domain)) {
                            return "Numbers are not allowed after '@' symbol";
                        }
                        return true;
                    },
                    })}
                  />
                  {errors.email && (
                    <p className="text-danger">{errors.email.message}</p>
                  )}
              </div>
              <div className="col-6 p-0">
                  <label className="ps-0 required">Contact</label>
                  <input
                    type="number"
                    className=" form-control"
                    defaultValue={vendorData?.con}
                    onChange={(e) => setVendorData(...e.target.value)}
                    {...register("con", { required: true })}
                  />
                  {errors.con && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>

              <div className="col-6 p-0">
                  <label className="ps-0">PAN</label>
                  <input
                    type="text"
                    className=" form-control"
                    defaultValue={vendorData?.pan}
                    onChange={(e) => setVendorData(...e.target.value)}
                    {...register("pan", { required: false })}
                  />
                  {errors.pan && (
                    <p className="text-danger">This field is required</p>
                  )}
              </div>

            </div>
            {isProcess && (
                      <ProgressSpinner
                        style={{
                          width: "50px",
                          height: "50px",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        strokeWidth="2"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                      />
                    )}

            <div className="mt-3 ">
              <TabView
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
                  className="customer-tab"

              >
               
                <TabPanel header="Billing Address">
                  <div className="row ">
                    <div className="col-6 pe-0">
                        <label className="ps-0 required">Address 1</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={vendorData?.bAddress1}
                          onInput={(e) => {setVendorData(...e.target.value); setIsSameAddress(false)}}
                          {...register("bAddress1", { required: true })}
                        />
                        {errors.bAddress1 && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                    </div>
                    <div className="col-6 pe-0">
                        <label className="ps-0 ">Address 2</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={vendorData?.bAddress2}
                          onInput={(e) => {setVendorData(...e.target.value);  setIsSameAddress(false)}}
                          {...register("bAddress2", { required: false })}
                        />
                    </div>
                    <div className="col-6 pe-0">
                        <label className="ps-0 required">City</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={vendorData?.bCity}
                          onInput={(e) => {setVendorData(...e.target.value); setIsSameAddress(false)}}
                          {...register("bCity", { required: true })}
                        />
                        {errors.bCity && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                    </div>
                    <div className="col-6 pe-0">
                        <label className="ps-0 required">State</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={vendorData?.bState}
                          onInput={(e) => {setVendorData(...e.target.value); setIsSameAddress(false)}}
                          {...register("bState", { required: true })}
                        />
                        {errors.bState && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                    </div>

                    <div className="col-6 pe-0">
                        <label className="ps-0 required">Country</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={vendorData?.bCountry}
                          onInput={(e) => {setVendorData(...e.target.value); setIsSameAddress(false)}}
                          {...register("bCountry", { required: true })}
                        />
                        {errors.bCountry && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                    </div>
                    <div className="col-6 pe-0">
                        <label className="ps-0 required">Zip/Postal Code</label>
                        <input
                          type="number"
                          className="form-control"
                          defaultValue={vendorData?.bZipCode}
                          onInput={(e) => {setVendorData(...e.target.value); setIsSameAddress(false)}}
                          {...register("bZipCode", { required: true })}
                        />
                        {errors.bZipCode && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                    </div>
                    <div className="row mx-0 mt-3 p-0">
                          <div className="form-group col d-flex align-items-baseline">
                            <input
                              type="checkbox"
                              name="checkbox"
                              checked={isSameAddress}
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                            <label className="ps-0 ms-2 mt-0">
                              Shipping address as same as billing address
                            </label>
                          </div>
                        </div>

                  </div>
                </TabPanel>
                <TabPanel header="Shipping Address">
                  <div className="row ">
                    <div className="col-6 pe-0">
                        <label className="ps-0 ">Address 1</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={vendorData?.sAddress1}
                          onInput={(e) => {setVendorData(...e.target.value); setIsSameAddress(false)}}
                          {...register("sAddress1", { required: false })}
                        />
                    </div>
                    <div className="col-6 pe-0">
                        <label className="ps-0 ">Address 2</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={vendorData?.sAddress2}
                          onInput={(e) => {setVendorData(...e.target.value); setIsSameAddress(false)}}
                          {...register("sAddress2", { required: false })}
                        />
                    </div>
                    <div className="col-6 pe-0">
                        <label className="ps-0 ">City</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={vendorData?.sCity}
                          onInput={(e) => {setVendorData(...e.target.value); setIsSameAddress(false)}}
                          {...register("sCity", { required: false })}
                        />
                    </div>
                    <div className="col-6 pe-0">
                        <label className="ps-0 ">State</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={vendorData?.sState}
                          onInput={(e) => {setVendorData(...e.target.value); setIsSameAddress(false)}}
                          {...register("sState", { required: false })}
                        />
                    </div>
                    <div className="col-6 pe-0">
                        <label className="ps-0 ">Country</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={vendorData?.sCountry}
                          onInput={(e) => {setVendorData(...e.target.value); setIsSameAddress(false)}}
                          {...register("sCountry", { required: false })}
                        />
                    </div>
                    <div className="col-6 pe-0">
                        <label className="ps-0 ">Zip/Postal Code</label>
                        <input
                          type="number"
                          className="form-control"
                          defaultValue={vendorData?.sZipCode}
                          onInput={(e) => {setVendorData(...e.target.value)}}
                          {...register("sZipCode", { required: false })}
                        />
                    </div>
                  </div>

                </TabPanel>
                <TabPanel header="Add more Details">
                  <div className="row">
                    <div className="col-6 pe-0">
                        <label className="ps-0 ">Website</label>
                        <input
                          type="text"
                          className=" form-control"
                          defaultValue={vendorData?.website}
                          onChange={(e) => setVendorData(...e.target.value)}
                          {...register("website", { required: false })}
                        />
                      </div>
                    <div className="col-6 pe-0">
                        <label className="ps-0">Date</label>
                        <Controller
                          name="date"
                          control={control}
                          render={({ field }) => (
                            <Calendar
                            className=" form-control p-0"
                              showIcon
                              value={getValues("date")}
                              appendTo="self"
                              onChange={(e) =>{ field.onChange(e);handleDateChange(e.value)}}
                              // {...register("date", { required: false })}
                            />
                          )}
                        />
                        {/* <Calendar className='form-control p-0' value={date} onChange={(e) => setDate(e.value)}></Calendar> */}
                    </div>
                    <div className="col-6 pe-0">
                        <label className="ps-0 ">Opening Balance</label>
                        <div className="input-group ps-0 pe-0">
                          <span className="input-group-text" id="basic-addon1">
                            INR
                          </span>
                          <input
                            type="number"
                            className=" form-control "
                            defaultValue={vendorData?.openingBalance}
                            onChange={(e) => setVendorData(...e.target.value)}
                            {...register("openingBalance", { required: false })}
                          />
                      </div>
                    </div>
                    <div className="col-6 pe-0">
                        <label className="ps-0">Currency</label>
                        <Controller
                          render={({ field }) => (
                            <Dropdown
                              options={currency}
                              {...field}
                              value={selectedCurrency}
                              className=" form-control"
                              appendTo="self"
                              onChange={(e) => {
                                console.log("e", e.optionLabel);
                                handleDropdown(e);
                              }}
                              optionLabel="label"
                              optionValue="value"
                            />
                          )}
                          control={control}
                          name="curType"
                          // defaultValue={vendorData.curType}
                        />
                    </div>
                  </div>

                </TabPanel>
              </TabView>
            </div>
          </div>
        </form>
      </Dialog>
      </BlockUI>
    </div>
  );
});

export default NewVendors;
