import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import {useLocation,useNavigate} from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
// import "rsuite/dist/rsuite.css";
import '../../assets/styles/accountant/journal.scss';
import JournalService from '../../services/journal';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
import { FcInfo } from 'react-icons/fc';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import GV from '../../services/globalVar';

// import { Drawer} from 'rsuite';

import Drawer from 'react-drag-drawer';

const UpdateJournal = forwardRef((props, ref) => {
    const history = useNavigate();
    const location = useLocation();
    const jor = new JournalService();
    const [drawer, setDrawer] = useState(false);
    const [date, setDate] = useState("");
    const [smDate, setSmDate] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [disabled, setDisabled] = useState(true);
    const {
        register,
        handleSubmit, reset, control,
        formState: { errors },
    } = useForm();
    const [journalNumber, setJournalNumber] = useState();
    const [description, setDescription] = useState();
    const [journalData, setJournalData] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [accountData, setAccountData] = useState([{ label: "", value: "", id: "" }]);
    const [isMounted, setIsMounted] = useState(true);
    const [tdataLoaded, setTdataLoaded] = useState(true);
    const [dates2, setDates2] = useState("");
    const [entries, setEntries] = useState(0);
    const [debitTotal, setDebitTotal] = useState(0);
    const [creditTotal, setCreditTotal] = useState(0);
    const [differenceDTotal, setDifferenceDTotal] = useState(0);
    const [differenceCTotal, setDifferenceCTotal] = useState(0);
    const [subCategory, setSubCategory] = useState([]);
    const [debitAmount, setDebitAmount] = useState(0);
    const [creditAmount, setCreditAmount] = useState(0);
    const [desValidation, setDesValidation] = useState("");
    const [tableValidation, setTableValidation] = useState(null);
    const [rowsData, setRowsData] = useState([]);
    const rowsInput = {
        entryId:0,
        dates: "",
        account: "",
        description: '',
        voucherno: '',
        contact: "",
        debit: '',
        credit: '',
        accountID: "",
        contactID: ''
    }
    const toast = useRef(null);
    const currency = [
        { label: 'INR-Indian Rupee', value: 'INR' },
        { label: 'Dollar', value: 'Dlr' },
        { label: 'Euro', value: 'Ero' },
        { label: 'Soil', value: 'Sol' },
    ];

console.log("vip",location.state);

const [journals, setJournals] = useState([]);

function getJournal() {
    
    let reqData = {
        "JournalID": location.state,
        "TenantID": GV.userDet.tenantDetail.tenantID,
        "Status": "All",
        "Period": "All",
        "FromDate": "",
        "ToDate": ""
    }

    jor.GetJournalData(reqData)
        .then((response) => {
            if (isMounted) {
                if (response.data) {
                    if (response.data.statusCode === 200) {
                        setTdataLoaded(true);
                        setJournalData(response.data.data);
                        let entries=response.data.data.entries;
                        let journal=response.data.data.journal[0];
                        setJournals(journal);
                        let data=[];
                        entries.forEach((items) => {
                            // dates, account, description, voucherno, contact, debit, credit
                            rowsData.push({
                                // EntryID: items.entryID,
                                entryId: items.entryID,
                                dates: format(new Date(items.entryDate), 'yyyy-MM-dd'),
                                // AccountID: items.accountID,
                                account: items.accountName,
                                description: items.description,
                                voucherno: "",
                                // contact: items.contactID,
                                contact: items.contactName,
                                credit: parseInt(items.credits, 10) || 0,
                                debit: parseInt(items.debits, 10) || 0
                            });
                        });
                        
                        console.log('journal,',journal);
                        if(journal.dateType=="Single Date"){
                            setSmDate("single");
                        }else{
                            setSmDate("multiple");
                        }
                        
                        
                        // setDate(format(new Date(journal.dateTypeFormatted), 'yyyy/MM/dd'));
                       setDate(format(new Date(journal.journalDate), 'yyyy-MM-dd'));
                       setDebitTotal(journal.totalDebits);
                       setJournalNumber(journal.journalNumber);
                       setCreditTotal(journal.totalCredits);
                       setDescription(journal.note);
                       console.log('debittotal',journal.totalDebits);
                       
                    } else {
                        setTdataLoaded(false);
                    }
                }
            }
        }).catch(function (error) {
        })
}console.log('debtot',typeof(debitTotal));
useEffect(() => {
    getJournal();
    return () => { setIsMounted(false); }
}, []);
console.log('test',rowsData);
console.log('monday', journals);


    function getAccount() {
        let id = 0;
        let tenid = 1;
        let status = "Active";
        let type = "All";
        jor.GetMasCOA(id, tenid, status, type)
            .then((response) => {
                if (isMounted) {
                    let b = [];
                    if (response.data) {
                        if (response.data.statusCode === 200) {
                            setTdataLoaded(true);
                            setAccountData(response.data.data);
                            response.data.data.map((acc) => {
                                acc.category.map((sub) => {
                                    return b.push(sub);
                                })
                            });
                            setSubCategory(b);
                        } else {
                            setTdataLoaded(false);
                        }
                    }
                }
            }).catch(function (error) {

            })
    }
    // console.log("nov", accountData, subCategory);
    useEffect(() => {
        getAccount();
        return () => { setIsMounted(false); }
    }, []);
    
    const groupedItemTemplate = (option) => {
        return (
            <div >
                <div className=''>{option.label}</div>
            </div>
        );
    }
    // console.log('acc', accountData[0].category);
    
    const accountOptions = accountData.map((acc) => {
        return (
            {
                label: acc.type,
                code: acc.type,
                
                items: subCategory.filter(sub => sub.coaType == acc.type).map(s => {
                    return (
                        {
                            label: s.coaName,
                            value: s.coaName,
                            id: s.coaMasID
                        }
                    )
                })
            })
    });
    //  const accountOptions = [
    //         {
    //             label: "acc.type",
    //             value: "s.coaName",
    //                         id: "s.coaMasID"
    //         }
            
    //      ]

    function getContact() {
        let reqData = {
            "TenantID": GV.userDet.tenantDetail.tenantID,
            "ContactType": "All",
            "CustomerType": "All",
            "Status": "All"
        }
        jor.GetContact(reqData)
            .then((response) => {
                if (isMounted) {
                    if (response.data) {
                        if (response.data.statusCode === 200) {
                            setTdataLoaded(true);
                            setContactData(response.data.data);
                        } else {
                            setTdataLoaded(false);
                        }
                    }
                }
            }).catch(function (error) {
            })
    }
    useEffect(() => {
        getContact();
        return () => { setIsMounted(false); }
    }, []);

    const contactOptions = contactData.map((cont) => {
        return (
            {
                label: cont.displayName,
                value: cont.displayName,
                id: cont.contactID
            }
        )
    })


   



    const accept = () => {
        onSubmit1("Published");
        history('/journal');
        // reset();
        // setRowsData([{
        //     dates: "",
        //     account: "",
        //     description: '',
        //     voucherno: '',
        //     contact: "",
        //     debit: '',
        //     credit: '',
        //     accountID: '',
        //     contactID: ''
        // }]);
        // getJournal();
        // setDescription("");
        // setDebitTotal("");
        // setCreditTotal("");
        // setDifferenceDTotal("");
        // setDifferenceCTotal("");
        // setSmDate("single");
        // setDate("");
        // setTableValidation("");
        // setDesValidation(true);
    }

    const reject = () => {
        onSubmit1("Draft");
        history('/journal');
        // reset();
        // setRowsData([{
        //     dates: "",
        //     account: "",
        //     description: '',
        //     voucherno: '',
        //     contact: "",
        //     debit: '',
        //     credit: '',
        //     accountID: '',
        //     contactID: ''
        // }]);
        // getJournal();
        // setDescription("");
        // setDebitTotal("");
        // setCreditTotal("");
        // setDifferenceDTotal("");
        // setDifferenceCTotal("");
        // setSmDate("single");
        // setDate("");
        // setTableValidation("");
        // setDesValidation(true);
    }

    function confirm1() {
        
        let deb = debitTotal;
        let crd = creditTotal;
        
                if (deb === 0 && crd === 0) {
                    setDesValidation(true);
                    toast.current.show({ severity: 'error', summary: "", detail: 'Debits and Credits should be equal' }); 
                    }
                    else if (deb !== crd) {
                        setDesValidation(true);
                        toast.current.show({ severity: 'error', summary: "", detail: 'Debits and Credits should be equal' }); 
                        
                    }else{
                        setDesValidation(false);
                    }
  
        validation("");
        if(desValidation==false){
            if(deb !== 0 || crd !== 0){
                
        confirmDialog({
            
            message: 'Are you sure move to Ledger?',
            header: 'Move to Ledger ',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
            
        });
        
        
        console.log("tday",desValidation); 
    }
    }
   
    };

    const handleDateChange = (date) => {
        setDate(format(new Date(date), 'yyyy-MM-dd'));

    };

    function handleRangeDate(e) {
        setStartDate(e.target.value[0]);
        setEndDate(e.target.value[1]);
        setDates2(e.target.value);
    }
    const [dateDisabled, setDateDisabled] = useState(false);
    
    const addtTableRows = () => {
       
        let deb = 0;
        let crd = 0;
        let sum = 0;
        let sumOfRows = [];
        setTableValidation("");

        // if(date !== ""){
        //     setTableValidation("");
        // }else{
        //     setTableValidation("Date is required.");
        // }
        for (let j = 0; j < rowsData.length; j++) {
            if (rowsData.length > 0) {

            }

            if (rowsData.length - 1 == 0) {

                // console.log("j", j);
                if (
                    // rowsData[j].dates != "" &&
                 rowsData[j].account != "" && rowsData[j].contact != "" && (rowsData[j].debit != "" || rowsData[j].credit != "")) {
                    setRowsData([...rowsData, rowsInput]);
                    sumOfRows = rowsData.concat(rowsInput);

                    // sumOfRows([...sumOfRows,rowsInput]);
                } else {
                    // if (rowsData[j].dates === "") {
                    //     if (smDate == "single" && date == "") {
                    //         setTableValidation('Date is required!');
                    //     }
                    //     else if (smDate == "single" && date !== "") {
                    //         console.log("mdate");
                    //         setTableValidation("");
                    //     }else if (smDate == "multiple" ){
                    //         if (rowsData[j].dates === ""){
                    //             setTableValidation('Date is required!');
                    //         }else{
                    //             setTableValidation("");
                    //         }
                    //     }

                    //     setTableValidation("Date is required!");

                    // }
                     if (rowsData[j].account == "") {
                        setTableValidation(" Account is required.");
                    } else if (rowsData[j].contact == "") {
                        setTableValidation(" Contact is required.");
                    }
                    else if (rowsData[j].debit == "" || rowsData[j].credit != "") {
                        setTableValidation(" Debit or Credit is required.");
                    }
                    else  {
                        setTableValidation(" Debits and Credits should be equal.");
                    }
                }
            }
            else if (j = rowsData.length - 1) {

                // console.log("j+!else", rowsData.length - 1);
                if (
                    // rowsData[j].dates != "" && 
                    rowsData[j].account != "" && rowsData[j].contact != "" && (rowsData[j].debit != "" || rowsData[j].credit != "")) {
                    setRowsData([...rowsData, rowsInput]);
                    sumOfRows = rowsData.concat(rowsInput);

                }
                else {
                    // if (rowsData[j].dates == "") {
                    //     setTableValidation("Date is required");
                    // }

                       
                     if (rowsData[j].account == "") {
                        setTableValidation(" Account is required.");
                    } else if (rowsData[j].contact == "") {
                        setTableValidation(" Contact is required.");
                    }
                    else if (rowsData[j].debit == "" || rowsData[j].credit != "") {
                        setTableValidation(" Debit or Credit is required.");
                    }
                    else  {
                        setTableValidation(" Debits and Credits should be equal.");
                    }
                }
            }

        }
        // console.log('rows', sumOfRows);
        for (let i = 0; i < sumOfRows.length; i++) {
            if (sumOfRows[i].debit !== 0 && sumOfRows[i].debit !== "") {
                deb = deb + Number(sumOfRows[i].debit);
            } else if (sumOfRows[i].credit !== 0 && sumOfRows[i].credit != "") {
                crd = crd + Number(sumOfRows[i].credit);
            }
        }
        if (deb !== 0 && crd !== 0) {
            if (deb == crd) {
                // console.log('deb-crd', deb, crd);
                setEntries(entries + 1);
            }
        }
        handleTotal();
    }

   const validation = ()=>{
    //    if ( date === "") {
    //        setTableValidation("Date is required!.");
    //    } else {
    //        setTableValidation("");
    //    }
    for (let j = 0; j < rowsData.length; j++) {
        if (rowsData.length > 0) {

        }

        if (rowsData.length - 1 == 0) {

            // console.log("j", j);
            if (
                // rowsData[j].dates != "" && 
            rowsData[j].account != "" && rowsData[j].contact != "" && (rowsData[j].debit != "" || rowsData[j].credit != "")) {
                // setRowsData([...rowsData, rowsInput]);
                // sumOfRows = rowsData.concat(rowsInput);

                // sumOfRows([...sumOfRows,rowsInput]);
            } else {
                // if (rowsData[j].dates === "") {
                //     if (smDate == "single" && date == "") {
                //         setTableValidation('Date is required!');
                //     }
                //     else if (smDate == "single" && date !== "") {
                //         console.log("mdate");
                //         setTableValidation("");
                //     }else if (smDate == "multiple" ){
                //         if (rowsData[j].dates === ""){
                //             setTableValidation('Date is required!');
                //         }else{
                //             setTableValidation("");
                //         }
                //     }

                //     setTableValidation("Date is required!");

                // }
                 if (rowsData[j].account == "") {
                    setTableValidation(" Account is required.");
                } else if (rowsData[j].contact == "") {
                    setTableValidation(" Contact is required.");
                }
                else if (rowsData[j].debit == "" || rowsData[j].credit != "") {
                    setTableValidation(" Debit or Credit is required.");
                }
                else  {
                    setTableValidation(" Debits and Credits should be equal.");
                }
            }
        }
        else if (j = rowsData.length - 1) {

            // console.log("j+!else", rowsData.length - 1);
            if (
                // rowsData[j].dates != "" && 
            rowsData[j].account != "" && rowsData[j].contact != "" && (rowsData[j].debit != "" || rowsData[j].credit != "")) {
                // setRowsData([...rowsData, rowsInput]);
                // sumOfRows = rowsData.concat(rowsInput);

            }
            else {
                // if (rowsData[j].dates == "") {
                //     setTableValidation("Date is required");
                // }
                 if (rowsData[j].account == "") {
                    setTableValidation(" Account is required.");
                } else if (rowsData[j].contact == "") {
                    setTableValidation(" Contact is required.");
                }
                else if (rowsData[j].debit == "" || rowsData[j].credit != "") {
                    setTableValidation(" Debit or Credit is required.");
                }
                else  {
                    setTableValidation(" Debits and Credits should be equal.");
                }
            }
        }

    }
   
   }

    const deletetTableRows = (index) => {
        const rows = [...rowsData];
        if(index !=0 ){
            rows.splice(index, 1);
            setRowsData(rows);
        }
        // else if(index >=0){
        //     rows.splice(index, 1);
        //     setRowsData(rows);
        // }
        // rows.splice(index, 1);
        // setRowsData(rows);
        // setSm();
        // let entry = entries;
        // entry = entry - 1;
        // setEntries(entry);
    }

    const handleTotal = () => {
        let dtotal = 0;
        let ctotal = 0;
        let d = rowsData;
        for (let i = 0; i < d.length; i++) {
            if (d[i].debit) {
                dtotal = dtotal + Number(d[i].debit);
            } else if (d[i].credit) {
                ctotal = ctotal + Number(d[i].credit);
            }
        }
        setDebitTotal(dtotal);
        setCreditTotal(ctotal);
        handleDifference(dtotal, ctotal);

    }

    const handleDifference = (debit, credit) => {
        if (debit > credit) {
            credit = debit - credit;
            debit = "";
        }
        else {
            debit = credit - debit;
            credit = "";
        }
        setDifferenceDTotal(debit);
        setDifferenceCTotal(credit);

    }

    const handletChange = (index, e, opt) => {
        // console.log("option",opt);
        const { name, value } = e.target;
        
        const rowsInput = [...rowsData];

        if (smDate === "single") {
            rowsInput[index]["dates"] = date;
        }
        if (name === "account") {
            rowsInput[index][name] = value;
            for (let i = 0; i < opt.length; i++) {
                // console.log("optiosn",opt[i].items);
                for(let j=0; j< opt[i].items.length; j++)
                if (value === opt[i].items[j].value) {
                    rowsInput[index]["accountID"] = opt[i].items[j].id;
                }
            }
        }
        else if (name === "contact") {
            rowsInput[index][name] = value;
            for (let i = 0; i < opt.length; i++) {
                if (value === opt[i].value) {
                    rowsInput[index]["contactID"] = opt[i].id;
                }
            }
        }
        else if (name === "credit") {
            rowsInput[index][name] = value;
            rowsInput[index]["debit"] = "";
            setCreditAmount(value);
        }
        else if (name === "debit") {
            rowsInput[index][name] = value;
            rowsInput[index]["credit"] = "";
            setDebitAmount(value);
        }
        else {
            rowsInput[index][name] = value;
        }
        setRowsData(rowsInput);
    }


    // const [selectedFile, setSelectedFile] = useState();
    // const changeHandler = (e) => {
    // 	setSelectedFile(e.target.files[0]);
    // };
    // const [journalData, setJournalData] = useState([]);
    // const data=props.data.journalData;

//     const saveAsDraft=(e)=>{
//         console.log("sts drft",e.target.value);
//    setStatus(e.target.value);
//    handleSubmit((onSubmit));
//     }

    let fdata = [];

    const onSubmit1 = (e) => {  
       

        validation("");
        rowsData.forEach((items) => {
            fdata.push({
                EntryID:  items.entryId,
                EntryDate: format(new Date(items.dates), 'yyyy-MM-dd'),
                AccountID: items.accountID,
                AccountName: items.account,
                Description: items.description,
                VoucherNo: items.voucherno,
                ContactID: items.contactID,
                ContactName: items.contact,
                Credits: parseInt(items.credit, 10) || 0,
                Debits: parseInt(items.debit, 10) || 0
            });
        });

        let reqData = {
            "JournalInputParams": {
                "JournalID": journals.journalID,
                "JournalDate": format(new Date(date), 'yyyy-MM-dd') || "",
                "DateType":smDate =="single"?"Single Date":"Multiple Date",
                "DateTypeFormatted":format(new Date(date), 'yyyy-MM-dd')||startDate,endDate,
                "JournalNumber": journalNumber,
                "ReferenceNumber": "",
                "Note":description,
                "JournalType": "Cash",
                "JournalEntryType":"Normal Journal",
                "CurrencyCode": "INR",
                "CurrencySymbol": "Rs",
                "TotalDebits": debitTotal.toFixed(2),
                "TotalCredits": creditTotal.toFixed(2),
                "TenantID": GV.userDet.tenantDetail.tenantID,
                "Status": e,
                "HasAttachment": false,
                "TemplateID": 1,
                "TemplateType": "Journal",
                "CreatedBy":GV.userDet.appUser.userName
            },
            "Entries": fdata,
            "History": [{ "JournalHistoryID": 0, "ChangeDescription": "New Journal Entry" }]
        }
        
      
        jor.SaveJournalEntry(reqData)
            .then((response) => {
                if (response.data) {
                    if (response.data.message === 'Successfully modified Journal Entry') {
                        reset();
                        setRowsData([{
                            entryId:0,
                            dates: "",
                            account: "",
                            description: '',
                            voucherno: '',
                            contact: "",
                            debit: '',
                            credit: '',
                            accountID: '',
                            contactID: ''
                        }]);
                        history('/journal');
                        getJournal();
                        setDescription("");
                        setDebitTotal("");
                        setCreditTotal("");
                        setDifferenceDTotal("");
                        setDifferenceCTotal("");
                        setSmDate("single");
                        setDate("");
                        setTableValidation("");
                        setDesValidation(true);
                        toast.current.show({ severity: 'success', summary: response.data.message });
                    } 
                    else if (response.data.message === 'Debits and Credits should be equal') {
                        toast.current.show({ severity: 'error', summary: response.data.message });
                    }
                     else {
                        
                    }
                }
                
            })
            .catch(function (error) {
                if (error.response.data.message === 'Journal Number Already Exists') {
                    toast.current.show({ severity: 'error', summary: error.response.data.message, detail: 'Journal Number Already Exists' });
                }
                // else if (error.response.data.message === 'Debits and Credits should be equal') {
                //     toast.current.show({ severity: 'error', summary: error.response.data.message, detail: 'Debits and Credits should be equal' });
                // }
            })
    }
    
    
      
    const onSubmit = (e) => {  
        let deb = debitTotal;
        let crd = creditTotal;
        // setDesValidation(false);
        // for (let i = 0; i < rowsData.length; i++) {
            // if (rowsData.length >1) {
                if (deb === 0 && crd === 0) {
                    // if (deb !== 0 || crd !== 0) {
                        // setDesValidation(true);
                        // toast.current.show({ severity: 'error', summary: "", detail: 'Debits and Credits should be equal' });  
                    }
                    else if (deb !== crd) {
                        // console.log('debcrd', deb, crd);
                        // setDesValidation(true);
                        // toast.current.show({ severity: 'error', summary: "", detail: 'Debits and Credits should be equal' });
                        // setTableValidation('Debits and Credits should be equal');
                    }else{
                        setDesValidation(false);
                    }
                    
                // }else{
                //     console.log("day",deb,crd,desValidation);
                // }
            // }
            console.log("validation",desValidation);
        // }

        validation("");
        rowsData.forEach((items) => {
            fdata.push({
                EntryID:  items.entryId,
                EntryDate: format(new Date(items.dates), 'yyyy-MM-dd'),
                AccountID: items.accountID,
                AccountName: items.account,
                Description: items.description,
                VoucherNo: items.voucherno,
                ContactID: items.contactID,
                ContactName: items.contact,
                Credits: parseInt(items.credit, 10) || 0,
                Debits: parseInt(items.debit, 10) || 0
            });
        });

        
            
        // let start=format(new Date(startDate), 'yyyy-MM-dd');
        // let end=format(new Date(endDate), 'yyyy-MM-dd')


        let reqData = {
            "JournalInputParams": {
                "JournalID": journals.journalID,
                "JournalDate": format(new Date(date), 'yyyy-MM-dd') || "",
                "DateType":smDate =="single"?"Single Date":"Multiple Date",
                "DateTypeFormatted":format(new Date(date), 'yyyy-MM-dd')||startDate,endDate,
                "JournalNumber": journalNumber,
                "ReferenceNumber": "",
                "Note":description,
                "JournalType": "Cash",
                "JournalEntryType":"Normal Journal",
                "CurrencyCode": "INR",
                "CurrencySymbol": "Rs",
                "TotalDebits": debitTotal.toFixed(2),
                "TotalCredits": creditTotal.toFixed(2),
                "TenantID": GV.userDet.tenantDetail.tenantID,
                "Status": e,
                "HasAttachment": false,
                "TemplateID": 1,
                "TemplateType": "Journal",
                "CreatedBy": GV.userDet.appUser.userName
            },
            "Entries": fdata,
            "History": [{ "JournalHistoryID": 0, "ChangeDescription": "New Journal Entry" }]
        }
        // console.log('rea',reqData);
        if(desValidation==false){
            if(deb !== 0 || crd !== 0){
        jor.SaveJournalEntry(reqData)
            .then((response) => {
                if (response.data) {
                    if (response.data.message === 'Successfully modified Journal Entry') {
                        reset();
                        setRowsData([{
                            entryId:0,
                            dates: "",
                            account: "",
                            description: '',
                            voucherno: '',
                            contact: "",
                            debit: '',
                            credit: '',
                            accountID: '',
                            contactID: ''
                        }]);
                        history('/journal');
                        getJournal();
                        setDescription("");
                        setDebitTotal("");
                        setCreditTotal("");
                        setDifferenceDTotal("");
                        setDifferenceCTotal("");
                        setSmDate("single");
                        setDate("");
                        setTableValidation("");
                        setDesValidation(true);
                        toast.current.show({ severity: 'success', summary: response.data.message });
                    } 
                    else if (response.data.message === 'Debits and Credits should be equal') {
                        toast.current.show({ severity: 'error', summary: response.data.message });
                    }
                     else {
                        // console.log("error", response.data);
                    }
                }
                
            })
            .catch(function (error) {
                if (error.response.data.message === 'Journal Number Already Exists') {
                    toast.current.show({ severity: 'error', summary: error.response.data.message, detail: 'Journal Number Already Exists' });
                }
                // else if (error.response.data.message === 'Debits and Credits should be equal') {
                //     toast.current.show({ severity: 'error', summary: error.response.data.message, detail: 'Debits and Credits should be equal' });
                // }
            })
        }
    }
    }
    // let a=journals.totalDebits;
    // let b=journals.totalCredits;
    console.log("tuesday",date)
    return (<>

        <Toast ref={toast}></Toast>
        <div
                className="d-flex justify-content-between p-3 mb-2 text-t-primary  kk-table-header kk-header-sec mb-0"
                style={{ backgroundColor: "white" }}
              >
        <h4 className='kk-bold'>Update Journal</h4>
                
              </div>
        {/* <div className='bg-white shadow-sm journal-account'> */}
        <form onKeyUp={() => handleTotal()}>
            <div className=' bg-white shadow-sm  chart-of-account h-100 p-4'>
                <div className='row coa-header kk-header-sec'>
                    <div className='col-auto header-right ms-auto'>
                    </div>
                </div>
                <div className='row '>
                    <div className='col-9 '>
                        <div className='  bag shadow-sm journal-of-account p-4'>
                            <div className='row '>
                                <div className='col-sm-3  mx-4'>
                                    <div className='row'>
                                        <label className='ps-0 required'>Journal No.</label>
                                        <input type="text" className="form-control sm-2"   value={journalNumber} readOnly 
                                        //  defaultValue={journalData.journalNumber}  onChange={(e) => setJournalData(e.target.value)} 
                                        />
                                        {/* {errors.jno && <p className="text-danger ps-0">Journal is required.</p>} */}
                                    </div>
                                </div>
                                <div className='col-sm-3 mx-4'>
                                    <div className='row'>
                                        <label className='ps-0 '>Currency</label>
                                        <Controller
                                            render={({ field }) => (
                                                <Dropdown className="w-100 " options={currency}
                                                    {...field}
                                                    optionLabel="label" optionValue="value" value="INR" disabled={disabled} />
                                            )}
                                            control={control}
                                            name="curType"
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-3 mx-4'>
                                    <div className='row'>
                                        <label className="col-sm-6  p-0"><input type="radio" name="type" className='mx-1 '
                                            {...register('type', { required: false })}  defaultValue={journals.dateType} checked={smDate == "single" ? true : false} onChange={(e) => { setSmDate(e.target.value); setTableValidation("") }} />Single Date </label>
                                        <label className="col-sm-6 p-0"><input type="radio" name="type" className='mx-1 '
                                            {...register('type', { required: false })} value="multiple"  checked={smDate == "multiple" ? true : false} onChange={(e) => { setSmDate(e.target.value); setDate(""); }} />Multiple Date </label>
                                        {smDate == "single" ? <Calendar className=' p-0 ' defaultValue={journals.dateTypeFormatted} onChange={(e) => handleDateChange(e.value)} 
                                            showIcon /> : <Calendar className=' p-0 ' id="range" value={dates2} maxDate={new Date()} onChange={(e) => handleRangeDate(e)} selectionMode="range" readOnlyInput showIcon />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-4 g-0'>
                                <div className='col-sm-7  pe-5'>
                                    <div className='row mx-2 ps-1'>
                                        <label className='ps-0 '>Description</label>
                                        <textarea type="text" className="form-control"  value={description} 
                                        // {...register('description', { required: true })} 
                                         onChange={(e) => setDescription(e.target.value)} />
                                        {/* {errors.description && <p className="text-danger ps-0">Description is required.</p>} */}
                                        {/* {desValidation ? <p className='text-danger ps-3'>{desValidation}</p> : ""} */}
                                    </div> 
                                </div>
                                <div className='col-sm-3 mx-4 '>
                                    <div className='row'>
                                        <label className=" ps-3" >
                                            <input className="form-check-input mx-1" type="checkbox" value="" />
                                            Reverse Entry
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='bgd shadow-sm journal-of-account p-4'>
                            <div className="col ">
                                <div className=" row mt-1 ">
                                    <label className="col-sm-3 col-form-label" >Entries</label>
                                    <div className="col-sm-5 ms-auto">
                                        <input type="text" value={entries}  className="form-control tborder bgd"  disabled={disabled} />
                                    </div>
                                </div>
                                <div className=" row mt-1 ">
                                    <label className="col-sm-3 col-form-label" >Debit</label>
                                    <div className="col-sm-5 ms-auto">
                                        <input type="text" min={0} max={10} step={0.00}   className="form-control tborder bgd" value={Number(debitTotal).toFixed(2)}   disabled={disabled} />
                                    </div>
                                </div>
                                <div className=" row mt-1 ">
                                    <label className="col-sm-3 col-form-label" >Credit</label>
                                    <div className="col-sm-5 ms-auto">
                                        <input type="text" min={0} max={10} step={0.00}   className="form-control tborder bgd" value={Number(creditTotal).toFixed(2)}  disabled={disabled}/>
                                    </div>
                                </div>
                                <div className=" row mt-1 ">
                                    <label className="col-sm-3 col-form-label" >Status</label>
                                    <div className="col-sm-5 ms-auto">
                                        <input type="text" className="form-control tborder bgd" defaultValue={journals.status} disabled={disabled}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='table-responsive'>
                    <table className="table mt-3" >
                        <thead>
                            <tr className='heading '>
                                <th >DATE</th>
                                <th >ACCOUNT</th>
                                <th >DESCRIPTION</th>
                                <th >VOUCHER NO</th>
                                <th >CONTACT</th>
                                <th >DEBIT</th>
                                <th >CREDIT</th>
                                <th >ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowsData.map((data, index) => {
                                const { dates, account, description, voucherno, contact, debit, credit } = data;
                                return (
                                    <tr key={index} >
                                        <td >
                                            {smDate == "single" ? <input type="text" name="dates" readOnly value={date} onChange={(e) => (handletChange(index, e))} /> :
                                                <Calendar name="dates" value={dates} minDate={startDate} maxDate={endDate}
                                                    onChange={(e) => (handletChange(index, e))} showIcon />
                                            }
                                        </td>
                                        <td><Controller
                                            render={({ field }) => (
                                                <Dropdown className='tborder accon acnt' options={accountOptions} placeholder='Accounts' optionLabel="label" filter filterBy="label" optionGroupLabel="label"
                                                    optionGroupChildren="items" optionGroupTemplate={groupedItemTemplate} {...field} value={account} onChange={(e) => (handletChange(index, e, accountOptions))}
                                                />
                                                // <Dropdown value={account} options={contactOptions} onChange={(e) => (handletChange(index, e, accountOptions))} 
                                                // optionLabel="label" filter filterBy="label"
                                                // // optionGroupLabel="label" filter showClear filterBy="label" optionGroupChildren="items" optionGroupTemplate={groupedItemTemplate} 
                                                // />
                                            )}
                                            control={control}
                                            name="account"

                                        /></td>
                                        <td><textarea type="text" className="form-control tborder a " placeholder='Description' name="description" value={description} onChange={(e) => (handletChange(index, e))} /></td>
                                        <td><input type="text" className="form-control tborder  " name="voucherno" value={voucherno} onChange={(e) => (handletChange(index, e))} /></td>
                                        <td><Controller
                                            render={({ field }) => (
                                                <Dropdown className=' tborder  accon' placeholder='Contacts' filter filterBy="label"  options={contactOptions}
                                                    {...field} value={contact} onChange={(e) => (handletChange(index, e, contactOptions))}
                                                // optionLabel="displayName"  optionValue="displayName"
                                                />
                                            )}
                                            control={control}
                                            name="contact"
                                        /></td>
                                        <td>
                                            <input type="number" name="debit" value={debit} onChange={(e) => (handletChange(index, e))} className="form-control tborder tot" />
                                        </td>
                                        <td>
                                            <input type="number" name="credit" value={credit} onChange={(e) => (handletChange(index, e))} className="form-control tborder tot" />
                                        </td>
                                        <td >
                                            <div className="d-flex align-items-center mt-3">
                                                <span className="not mx-2" onClick={() => setDrawer(true)} ><FcInfo /></span>
                                                <div>
                                                    <Drawer open={drawer} onRequestClose={() => setDrawer(true)} direction="" >
                                                        <div className='right'>
                                                            <div className='mt-2'>
                                                                <span className='close' onClick={() => setDrawer(false)}>< AiOutlineCloseCircle /></span>
                                                            </div>
                                                            <div className='col mx-4 mt-4'>
                                                                <div className='row-sm'>
                                                                    <label className='ps-0 '>Account</label>
                                                                    <Controller
                                                                        render={({ field }) => (
                                                                            <Dropdown className=' w-100' placeholder='Accounts' options={accountOptions}
                                                                                {...field} value={account} onChange={(e) => (handletChange(index, e, accountOptions))}
                                                                            />
                                                                        )}
                                                                        control={control}
                                                                        name="account"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col mx-4 mt-2'>
                                                                <div className='row-sm'>
                                                                    <label className='ps-0 '>Contact</label>
                                                                    <Controller
                                                                        render={({ field }) => (
                                                                            <Dropdown className='  w-100 ' placeholder='Contacts' options={contactOptions}
                                                                                {...field} value={contact} onChange={(e) => (handletChange(index, e, contactOptions))}


                                                                            />
                                                                        )}
                                                                        control={control}
                                                                        name="contact"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {debitAmount == 0 && creditAmount > 0 ?
                                                                <div className='col mx-4 mt-2'>
                                                                    <div className='row-sm'>
                                                                        <label className='ps-0 '>Debit</label>
                                                                        <input type="number" name="debit" onChange={(e) => handletChange(index, e)} className="form-control  tot" />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='col mx-4 mt-2'>
                                                                    <div className='row-sm'>
                                                                        <label className='ps-0 '>Credit</label>
                                                                        <input type="number" name="credit" onChange={(e) => handletChange(index, e)} className="form-control  tot" />
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className='add mt-4 '>
                                                                <button type="button" className="btn btn-info " onClick={() => addtTableRows()}>+ Add</button>
                                                            </div>
                                                            <div className='row mt-4'>
                                                                <span className='note ps-4 '><AiOutlineExclamationCircle />
                                                                    <p className=' text-danger'>Your Entry is still not balanced.</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Drawer>
                                                </div>
                                                
                                                <i className="pi pi-trash bin" onClick={() => (deletetTableRows(index))} />
                                                 
                                            </div>
                                        </td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                
                <div className="row ">
                    {tableValidation ? <p className='text-danger'>{tableValidation}</p> : ""}
                    <div className="col ">
                        <button type="button" className="btn btn-info " onClick={() => addtTableRows()}>+ Add more entry</button>
                        <div className=' mt-4'>
                            <ConfirmDialog />
                            <button type="button" className="btn btn-primary me-2"  onClick={()=>confirm1()} >Submit</button>
                            <button type="button" className="btn btn-primary me-2"  onClick={()=> onSubmit("Draft")}>Save as Draft</button>
                            <button type="button" className="btn btn-danger" onClick={() => { history('/journal')}}>Cancel</button>
                        </div>
                    </div>
                    <div className="col bg-light total ">
                    {/* {tableValidation ? <p className='text-danger'>{tableValidation}</p> : ""} */}
                        <div className=" row mt-2 ">
                            <label className="col-sm-3 col-form-label" >Total</label>
                            <div className="col-sm-3 ms-auto ">
                                <input type="text" min={0} max={10} step={0.00}  disabled={disabled} value={Number(debitTotal).toFixed(2)} className="form-control tborder bg-light tot" />
                            </div>
                            <div className="col-sm-3 ms-auto ">
                                <input type="text" min={0} max={10} step={0.00} disabled={disabled} value={Number(creditTotal).toFixed(2)} className="form-control tborder bg-light tot" />
                            </div>
                        </div>
                        <div className=" row my-2 ">
                            <label className="col-sm-3 col-form-label" >Difference</label>
                            <div className="col-sm-3 ms-auto">
                                <input type="text" min={0} max={10} step={0.00} value={Number(differenceDTotal).toFixed(2)} disabled={disabled} className="form-control tborder bg-light tot text-danger" />
                            </div>
                            <div className="col-sm-3 ms-auto">
                                <input type="text" min={0} max={10} step={0.00} value={Number(differenceCTotal).toFixed(2)} disabled={disabled} className="form-control tborder bg-light tot text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* {desValidation ? <p className='text-danger ps-3'>{desValidation}</p> : ""} */}
                {/* <div className='row mt-5'>
                            <label className="row-sm-1  " >Attach File(s)</label>
			                <input type="file" name="file" onChange={changeHandler} />	
		                </div> */}




                {/* <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Move to Ledger</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                Are you sure want to ledger
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
                                <button type="button" className="btn btn-primary" onClick={handleSubmit((onSubmit))}>Yes </button>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
        </form>
       
        {/* <div className="modal fade" id="exampleModal" data-bs-backdrop="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className=" modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Move to Ledger</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        
                    </div>
                    <div class="modal-body">
                        Are you sure want to ledger
                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-secondary" value="Draft" data-bs-dismiss="modal" onClick={(e)=> onSubmit(e)}>No</button>
                        <button type="button" className="btn btn-primary" value="Published" onClick={(e)=> onSubmit(e)}>Yes </button>
                    </div>
                </div>
            </div>
        </div> */}
        {/* <div className=' pe-4'>
            <Button onClick={() => confirm1()}  label="Confirm" className="me-2"></Button>
            <Button onClick={handleSubmit((onSubmit))}  label="Save & Draft" className="me-2"></Button>
            <Button  label="Cancel" className="me-2"></Button>
            </div> */}
        {/* </div> */}
    </>
    );
});


export default UpdateJournal;




