import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import {
  Dialog,
  Button,
  InputText,
  InputTextarea,
  Dropdown,
  Toast,
  ToggleButton,
} from "../PrimeReact";

import "../../assets/styles/accountant/chartofaccount.scss";
import AccountantService from "../../services/accountant";
import LocalStorageService from "../../services/localstorage";
import GlobalVariable from "../../services/globalVar";
import { act } from "@testing-library/react";

const defaultValues = {
  accntType: "",
  accntCode: "",
  accntName: "",
  accntActive: "",
  accntDesc: "",
  category: "",
  subcategory: "",
};

const ChartOfAccount = forwardRef((props, ref) => {
  const acs = new AccountantService();
  const lss = new LocalStorageService();
  const GV = new GlobalVariable();
  const [accData, setAccData] = useState([]);
  const [coaID, setCoaID] = useState([]);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState("center");
  const [acntTypeList, setacntTypeList] = useState();
  const [selectedAcctType, setSelectedAcctType] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
    trigger
  } = useForm({ mode: "onChange", defaultValues });

  const resetAsyncForm = async () => {
    reset();
  };

  useImperativeHandle(ref, () => ({
    alertToggle() {
      onClick("displayResponsive");
    },
    alertToggledit(val) {
      onClickEdit("displayResponsive", val);
    },
  }));

  const accntTypes = [
    { label: "Assets", value: "Assets" },
    { label: "Expenses", value: "Expense" },
    { label: "Liabilities", value: "Liability" },
    { label: "Equity", value: "Equity" },
    { label: "Income", value: "Income" },
    { label: "Revenue", value: "Revenue" },
  ];
  const toast = useRef(null);

  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };

  const onClickEdit = (name, value) => {
    dialogFuncMap[`${name}`](true);
    resetAsyncForm();
    console.log(value, "bind");
    setCoaID(value.coaMasID);
    setValue("accntType", value.coaType);
    setValue("accntCode", value.code);
    setValue("accntName", value.coaName);
    setValue("accntDesc", value.description);
    if (value.status === "Active") {
      setValue("accntActive", true);
    } else {
      setValue("accntActive", false);
    }
    if (position) {
      setPosition(position);
    }

    const cotype = acntTypeList.find((a) => a.type === value.coaType);
    console.log(cotype, "acntTypeList");
    setValue("accntType", cotype);
    setSelectedAcctType(cotype);

    const getCategory = cotype.categories;
    setCategory(getCategory);
    console.log(getCategory, "getCategory");
    const actSub = getCategory.find(
      (a) => a.categoryName === value.coaCategory
    );
    setValue("category", actSub);
    setSelectedCategory(actSub);

    const getSubCat = actSub.subCategoryName;
    setSubCategory(getSubCat);
    const getSubCats = getSubCat.find((a) => a === value.coaSubCategory);
    console.log(getSubCats, "getSubCats");
    setValue("subcategory", getSubCats);
    setSelectedSubCategory(getSubCats);
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
    resetAsyncForm();
    setCoaID(0);
    // setValue("accntType", accntTypes[0].value);
    setValue("accntActive", true);
    // setValue('accntCode',accntTypes[0].value)
    //  setValue('accntName',accntTypes[0].value)
    if (position) {
      setPosition(position);
    }
  };

  const onValid = (errors) => {
    console.log(errors);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const onSubmit = (data) => {
    console.log(data, "res");
    console.log(GV, "GV");
    const datas = {
      COAMasID: coaID,
      Code: data.accntCode || 0,
      COAName: data.accntName,
      COAType: selectedAcctType.type,
      COACategory: selectedCategory.categoryName,
      COASubCategory: selectedSubCategory && selectedSubCategory.length ? selectedSubCategory.length : '',

      Description: data.accntDesc,
      StartBalance: 0,
      EndingBalance: 0,
      Status: data.accntActive === false ? "Inactive" : "Active",
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
    };
    console.log(datas, "blACK");
    acs
      .SaveMasCOA(datas)
      .then((res) => {
        // console.log(res)
        if (res.data.message === "Successfully added COA") {
          props.parentFunction();
          toast.current.show({
            severity: "success",
            summary: "Successfully Created",
            detail: "Order submitted",
          });
        }
        if (res.data.message === "Successfully modified COA") {
          props.parentFunction();
          toast.current.show({
            severity: "success",
            summary: "Successfully Updated Chart of Account",
          });
        }
        if (res.data.message === "COAName Aready Exist") {
          toast.current.show({
            severity: "error",
            summary: "COA Name Already Exist",
          });
        }
       dialogFuncMap["displayResponsive"](false);

      })
      .catch(function (error) {
        if (error.response.data.message === "COAName Aready Exist") {
          toast.current.show({
            severity: "error",
            summary: error.response.data.message,
          });
        }
        dialogFuncMap["displayResponsive"](false);
      });
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide("displayResponsive")}
          className="p-button-text btn-cancel"
        />
        {coaID === 0 ? (
          <Button
            type="submit"
            label="Add"
            icon="pi pi-check"
            onClick={handleSubmit(onSubmit, onValid)}
            autoFocus
            className="btn btn-primary"
          />
        ) : (
          <Button
            type="submit"
            label="Update"
            icon="pi pi-check"
            onClick={handleSubmit(onSubmit)}
            autoFocus
            className="btn btn-primary"
          />
        )}
      </div>
    );
  };
  const getCategories = () => {
    acs.COATypes(GlobalVariable.userDet.tenantDetail.tenantID).then((res) => {
      const val = res.data.data;
      console.log(val);
      setacntTypeList(val);
      //   setValue("accntType", val[0]);
    });
  };
  const [category, setCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [subCategory, setSubCategory] = useState();

  const changeType = (e) => {
    console.log(e);
    setSelectedAcctType(e);
    setCategory(e.categories);
    setSubCategory();
    setValue("accntType", e);
    setValue("category", "");
    setValue("subcategory", "");
  };

  const changeCategory = (e) => {
    setSelectedCategory(e);
    setSubCategory(e.subCategoryName);
    setValue("category", e);
    setValue("subcategory", "");
  };

  const changeSubCategory = (e) => {
    setSelectedSubCategory(e);
    setValue("subcategory", e);
  };

  useEffect(() => {
    register("accntType");
    register("accntActive");
    getCategories();
  }, [register]);
  return (
    <div>
      <Toast ref={toast}></Toast>

      <Dialog
        header="New Account"
        visible={displayResponsive}
        onHide={() => onHide("displayResponsive")}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
        footer={renderFooter("displaynewItem")}
      >
        <form>
          <div className="form">
            <div className="row ">
              <div className="col mx-2 pe-1">
                <div className="row">
                  <label className="ps-1 required">Account Type</label>
                  <Controller
                    render={({ field }) => (
                      <Dropdown
                        options={acntTypeList}
                        {...field}
                        appendTo="self"
                        optionLabel="type"
                        placeholder="Select account type"
                        onChange={(e) => {
                          setValue("accntType", e.value);
                          changeType(e.value);
                          setTimeout(() => trigger("accntType"), 0); 
                        }}
                      />
                    )}
                    control={control}
                    name="accntType"
                    rules={{ required: "This field is required" }}
                  />
                  <p className="text-danger">{errors.accntType?.message}</p>
                </div>
              </div>
              <div className="col mx-2 pe-1">
                <div className="row">
                  <label className="ps-1 required">Account Category</label>
                  <Controller
                    render={({ field }) => (
                      <Dropdown
                        options={category}
                        {...field}
                        appendTo="self"
                        optionLabel="categoryName"
                        placeholder="Select Category"
                        onChange={(e) => {
                          setValue("category", e.value);
                          changeCategory(e.value);
                          setTimeout(() => trigger("category"), 0); 
                        }}

                      />
                    )}
                    control={control}
                    name="category"
                    rules={{ required: "This field is required" }}
                  />
                  <p className="text-danger">{errors.category?.message}</p>
                </div>
              </div>

              <div className="col mx-2 pe-1">
                <div className="row me-0">
                  <label className="ps-1">Account Sub-Category</label>
                  <Controller
                    render={({ field }) => (
                      <Dropdown
                        options={subCategory}
                        {...field}
                        appendTo="self"
                        // optionValue="value"
                        // optionLabel="label"
                        placeholder="Select SubCategory"
                        onChange={(e) => changeSubCategory(e.value)}
                        
                      />
                    )}
                    control={control}
                    name="subcategory"
                    value={selectedSubCategory}
                    rules={{}}
                  />
                  <p className="text-danger"> {errors.subcategory?.message} </p>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  <label className="ps-1">Account Status</label>
                  <Controller
                    render={({ field }) => (
                      <ToggleButton
                        {...field}
                        checked={field.value}
                        onLabel="Active"
                        offLabel="Inactive"
                        onIcon="pi pi-check"
                        offIcon="pi pi-times"
                      />
                    )}
                    control={control}
                    name="accntActive"
                    defaultValue={defaultValues.accntActive}
                  />
                  <p className="text-danger"> {errors.accntActive?.message} </p>
                </div>
              </div>
            </div>
            <div className="row ">
              {coaID !== 0 && (
                <div className="col mx-2">
                  <div className="row">
                    <label className="ps-1 required">Account Code</label>
                    <InputText
                      defaultValue={accData.accntCode}
                      disabled
                      onChange={(e) => setAccData(...e.target.value)}
                      {...register("accntCode", {
                        required: "This field is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Please enter numeric characters only",
                        },
                        minLength: {
                          value: 3,
                          message: "Code must be atleast 3",
                        },
                      })}
                      className="px-1 py-2"
                    />
                    <p className="text-danger"> {errors.accntCode?.message} </p>
                  </div>
                </div>
              )}
              <div className="col mx-2">
                <div className="row">
                  <label className="ps-1 required ">Account Name</label>
                  <InputText
                    defaultValue={accData.accntName}
                    onChange={(e) => setAccData(...e.target.value)}
                    {...register("accntName", {
                      required: "This field is required",
                    })}
                    className="px-1 py-2"
                  />
                  <p className="text-danger"> {errors.accntName?.message} </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col mx-2">
                <div className="row">
                  <label className="ps-1 required ">Description</label>
                  <Controller
                    render={({ field }) => (
                      <InputTextarea
                        {...field}
                        rows={5}
                        cols={30}
                        className="px-1 py-2"
                        value={field.value}
                      />
                    )}
                    control={control}
                    name="accntDesc"
                    defaultValue={defaultValues.accntDesc}
                    rules={{ required: "This field is required" }}
                  />
                  <p className="text-danger"> {errors.accntDesc?.message} </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="d-flex justify-content-end mt-3">
                      {
                          coaID === 0 ? <Button label="Create" icon="pi pi-check" className='btn btn-primary'   type='submit' />  : <Button label="Update" icon="pi pi-check" className='btn btn-primary'   type='submit' />
                      }

                        
                        <Button label="Cancel" icon="pi pi-times" className='btn btn-danger ms-2' type='button'  onClick={() => onHide('displayResponsive')} />

                    </div> */}
        </form>
      </Dialog>
    </div>
  );
});

export default ChartOfAccount;
