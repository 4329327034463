import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState } from "react";
import { useEffect } from 'react';
import ReportsService from '../../../services/reports';

function ProfitLoss() {
    const rps = new ReportsService
    const [data,setData]=useState();
    const [totalRecordsIn,settotalRecordsIn]=useState()
    const [first1, setFirst1] = useState(0);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);

    const basicColumns = [
        { field: 'accountName', header: 'Account Name',disabled:true,body:"" },
            { field: 'contactName', header: 'Contact',disabled:true,body:"" },
            { field: 'debits', header: 'Debit',disabled:true,body:""  },
            { field: 'credits', header: 'Credit',disabled:true ,body:"" },
            { field: 'availableBalance', header: 'Amount',disabled:true,body:""  },
            
    
        ]
        const dynamicColumns = [
        { field: 'accountName', header: 'Account Name',disabled:true,body:"" },
            { field: 'transactionNumber', header: 'Transaction No.',disabled:false,body:""  },
            { field: 'contactName', header: 'Contact',disabled:true,body:""  },
            { field: 'voucherNo', header: 'Voucher No.' ,disabled:false,body:"" },
            { field: 'description', header: 'Description' ,disabled:false,body:"" },
            { field: 'openingBalance', header: 'Opening Balance',disabled:false ,body:"" },
            { field: 'debits', header: 'Debit',disabled:true ,body:"" },
            { field: 'credits', header: 'Credit',disabled:true ,body:"" },
            { field: 'availableBalance', header: 'Amount',disabled:true,body:""  },
            
    
        ];

    const onCustomPage1 = (event) => {
        setFirst1(event.first);
        const val = event.page + 1;
        const size = event.rows;
        setSizePerPage(size);
        setPage(val);
        // fetchCoaList(val, size, searchVal, '', '', statusSelected, accountSelected);
    }
    const getData = () =>{
        const data ={
            "TenantID":109,
            "Status":"Active",
            "Period":"This Year",
            "FromDate":"",
            "ToDate":""
        }
        rps.GetProfitLoss(data).then(res=>{
            console.log(res.data.data)
        })
    }
    useEffect(()=>{
        getData()
    },[])
  return <div>
      <h5>Profit Loss Report</h5>
      <div className="card">
          <div className="card-body">
          {/* <DataTable className=' chofacc' stripedRows value={data} responsiveLayout="scroll" size="small" 
                                    paginator lazy totalRecords={totalRecordsIn}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rowsPerPageOptions={[10, 20, 50, totalRecordsIn]}
                                    first={first1} rows={sizePerPage} onPage={onCustomPage1}
                                >
                                      <Column field="transactionDate" header="Date"  body={(row)=><div> {format(new Date(row.transactionDate),'MM/dd/yyyy')}</div>} ></Column>
                                   
                                    {dynamicColumns}
                                </DataTable> */}
          </div>
      </div>
  </div>;
}

export default ProfitLoss;
