import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import { TabView, TabPanel } from "primereact/tabview";
import GV from "../../services/globalVar";
import {
  Card,
  Divider,
  Dropdown,
  Sidebar,
  Button,
  BlockUI,
} from "../PrimeReact.js";
import { Toast } from "primereact/toast";
import BudgetService from "../../services/budget-service";
import { ProgressSpinner } from "primereact/progressspinner";
import { Chips } from "primereact/chips";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";

export default function BudgetForecasting() {
  const bud = new BudgetService();
  const [userDrawer, setUserDrawer] = useState(false);
  const [financialUserDrawer, setfinancialUserDrawer] = useState(false);
  const [userDrawerCategory, setUserDrawerCategory] = useState(false);
  const [userSubDrawer, setUserSubDrawer] = useState(false);
  const [subCategory, setSubCategory] = useState([]);
  const [financialYearCatagery, setFinancialYearCatagery] = useState([]);
  const [test, setTest] = useState([]);
  const [test1, setTest1] = useState([]);
  const [subData, setSubData] = useState([]);
  const [monthsInBudgetCategory, setMonthsInBudgetCategory] = useState({});
  const [defaultValues, setdefaultValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcess, setIsProcess] = useState(false);
  const [dynamicMonth, setDynamicMonth] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [getMonthyear, setGetMonthyear] = useState({});
  const [rename, setRename] = useState(false);
  const [addCatRename, setAddCatRename] = useState(false);
  const [textRename, setTextRename] = useState();
  const [textCatRename, setTextCatRename] = useState("");
  const [textRenameBudgetCatId, setTextRenameBudgetCatId] = useState();
  const [disabledAddCatAndAddExpn, setDisabledAddCatAndAddExpn] =
    useState(false);
  const [indValue, setIndValue] = useState();
  const [addCatIndValue, setAddCatIndValue] = useState();
  const [enableField, setEnableField] = useState();
  const [getCatgryName, setGetCatgryName] = useState();
  const [catReName, setCatReName] = useState();
  const [isCategory, setIsCategory] = useState();
  const [isHidePreCat, setIsHidePreCat] = useState(new Date().getFullYear());
  const [chooseFromDate, setChooseFromDate] = useState();
  const [chooseToDate, setChooseToDate] = useState();
  const [isClone, setIsClone] = useState(false);
  const [cloneFromSelected, setCloneFromSelected] = useState(false);
  const [cloneToSelected, setCloneToSelected] = useState(false);
  const [isCloneErrFrom, setIsCloneErrFrom] = useState(false);
  const [isCloneErrTo, setIsCloneErrTo] = useState(false);
  const [isCloneErr, setIsCloneErr] = useState(false);
  const currentMonth = new Date().getMonth() + 1;
  let isFinancialYear = GV.userDet.tenantConfig.isFinancialYear;
  const categoryNameRef = useRef(null);

  const yearsDetails = [
    { id: 1, years: "Apr 2015 - Mar 2016", selectYear: 2015 },
    { id: 2, years: "Apr 2016 - Mar 2017", selectYear: 2016 },
    { id: 3, years: "Apr 2017 - Mar 2018", selectYear: 2017 },
    { id: 4, years: "Apr 2018 - Mar 2019", selectYear: 2018 },
    { id: 5, years: "Apr 2019 - Mar 2020", selectYear: 2019 },
    { id: 6, years: "Apr 2020 - Mar 2021", selectYear: 2020 },
    { id: 7, years: "Apr 2021 - Mar 2022", selectYear: 2021 },
    { id: 8, years: "Apr 2022 - Mar 2023", selectYear: 2022 },
    { id: 9, years: "Apr 2023 - Mar 2024", selectYear: 2023 },
    { id: 10, years: "Apr 2024 - Mar 2025", selectYear: 2024 },
  ];
  let getSorted = yearsDetails.slice();
  getSorted.sort((a, b) => {
    const yearA = parseInt(a.years.split(" ")[1]);
    const yearB = parseInt(b.years.split(" ")[1]);
    return yearB - yearA;
  });
  const saveYears = generateYearsDropdown();
  const saveYears1 = generateYearsDropdown1();
  const [monthYears, setmonthYears] = useState({});
  const [cloneFrom, setCloneFrom] = useState({});
  const [cloneTo, setCloneTo] = useState({});
  const [chooseDrpDwn, setChooseDrpDwn] = useState([]);
  const [selectCloneDrpDwnFrom, setSelectCloneDrpDwnFrom] = useState([]);
  const [selectCloneDrpDwnTo, setSelectCloneDrpDwnTo] = useState([]);

  function generateYearsDropdown() {
    let currentYear = new Date().getFullYear();
    let lastTenYears = currentYear - 10;
    let yearsArray = [];
    let financialYearStartingMonth =
      GV.userDet?.tenantConfig?.startMonthName.slice(0, 3);
    let financialYearStartingMonthID = GV.userDet?.tenantConfig?.startMonth;
    let financialYearEndingMonth = GV.userDet?.tenantConfig?.endMonthName.slice(
      0,
      3
    );
    let financialYearEndingMonthID = GV.userDet?.tenantConfig?.endMonth;
    for (let i = currentYear; i > lastTenYears; i--) {
      let yearObj = {
        id: i,
        years: `${financialYearStartingMonth} ${i} - ${financialYearEndingMonth} ${
          i + 1
        }`,
        selectYear: i,
        startMnth: financialYearStartingMonthID,
        startYr: i,
        endMnth: financialYearEndingMonthID,
        endYr: i + 1,
      };
      yearsArray = [...yearsArray, yearObj];
    }
    return yearsArray;
  }

  function generateYearsDropdown1() {
    let currentYear = new Date().getFullYear();
    let lastTenYears = currentYear - 10;
    let yearsArray = [];

    let financialYearStartingMonth =
      GV.userDet?.tenantConfig?.startMonthName.slice(0, 3);
    let financialYearStartingMonthID = GV.userDet?.tenantConfig?.startMonth;
    let financialYearEndingMonth = GV.userDet?.tenantConfig?.endMonthName.slice(
      0,
      3
    );
    let financialYearEndingMonthID = GV.userDet?.tenantConfig?.endMonth;

    for (let i = currentYear; i > lastTenYears; i--) {
      let yearObj = {
        id: i,
        years: `${financialYearStartingMonth} ${i} - ${financialYearEndingMonth} ${
          i + 1
        }`,
        selectYear: i,
        startMnth: financialYearStartingMonthID,
        startYr: i,
        endMnth: financialYearEndingMonthID,
        endYr: i,
      };
      yearsArray = [...yearsArray, yearObj];
    }
    return yearsArray;
  }

  const defaultValues3 = {
    clonefrom: "",
    cloneto: "",
  };

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    control: control1,
    reset: reset3,
    formState: { errors: errors3 },
  } = useForm({ mode: "all", defaultValues: defaultValues3 });

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues });

  const toast = useRef(null);

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const options = years.map((cont, i) => {
    return {
      label: cont,
      value: cont,
      id: i,
    };
  });

  function getBudgetForecasting() {
    setIsProcess(true);
    let tenid = GV.userDet.tenantDetail.tenantID;
    let year = selectedYear;
    bud
      .GetBudgetForecasting(tenid, year)
      .then((response) => {
        setIsProcess(false);
        if (response.data) {
          if (response.data.statusCode === 200) {
            setIsLoading(false);
            setSubCategory(response.data.data);
          }
        }
      })
      .catch(function (error) {});
  }

  const handleYearChange = (e) => {
    setSelectedYear(e);
  };

  useEffect(() => {
    console.log(monthYears, "monthYears");
  }, [monthYears]);

  const handleYearChange1 = (e) => {
    setIsHidePreCat(e);
    setGetMonthyear(e);
    setmonthYears(e);
    console.log(e, "handleYearChange1");
    let currentYr = new Date().getFullYear() - 1;
    let disableAddCatAndAddExpn = e.selectYear < currentYr;
    setDisabledAddCatAndAddExpn(disableAddCatAndAddExpn);
    getBudgetForecastingFinancialYear(e.selectYear);
  };

  const handleYearChangeCloneFrom = (e) => {
    setCloneFrom(e);
    setChooseFromDate(e.selectYear);
    setCloneFromSelected(e !== null && e !== "");

    console.log(e.selectYear, "handleYearChangeCloneFrom");
  };

  const handleYearChangeCloneTo = (e) => {
    setCloneTo(e);
    setChooseToDate(e.selectYear);
    setCloneToSelected(e !== null && e !== "");
    console.log(e.selectYear, "handleYearChangeCloneTo");
  };

  function getBudgetForecastingFinancialYear(year) {
    setSubCategory("");
    let tenid = GV.userDet.tenantDetail.tenantID;
    bud
      .GetBudgetForecasting(tenid, year)
      .then((response) => {
        if (response.data) {
          if (response.data.statusCode === 200) {
            setIsCategory(
              response.data.data.categories.map((a) => a.subCategory)
            );
            const cat = response.data.data.categories.map((a) => a);
            setIsLoading(false);
            let x = response.data.data;
            setSubCategory(x);
            setFinancialYearCatagery(response.data.data);
          }
        }
      })
      .catch(function (error) {});
  }

  function findYearRange(x) {
    let today = new Date();
    let financialYearStartingMonth =
      GV.userDet?.tenantConfig?.startMonthName.slice(0, 3);
    let financialYearEndingMonth = GV.userDet?.tenantConfig?.endMonthName.slice(
      0,
      3
    );
    let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    let monthStart = new Date(financialYearStartingMonth + " " + x.selectYear);
    let monthEnd = new Date(
      financialYearEndingMonth +
        " " +
        (x.selectYear + 1) +
        " " +
        lastDayOfMonth.getDate()
    );
    return new Date() >= monthStart && new Date() <= monthEnd;
  }

  useEffect(() => {
    GetFinYear();
    GetCloneFiscalYears();
    if (GV.userDet?.tenantConfig?.startMonth === 1) {
      getBudgetForecasting();
      let dfaultFinancialCurreent = saveYears1.find((x) => findYearRange(x));
      setGetMonthyear(dfaultFinancialCurreent);
      getBudgetForecastingFinancialYear(dfaultFinancialCurreent.selectYear);
    } else {
      let dfaultFinancialCurreent = saveYears.find((x) => findYearRange(x));
      setGetMonthyear(dfaultFinancialCurreent);
      getBudgetForecastingFinancialYear(dfaultFinancialCurreent.selectYear);
      // setmonthYears(dfaultFinancialCurreent);
    }
  }, []);

  const handleClose = () => {
    setUserDrawer(false);
  };
  const handleClose1 = () => {
    setfinancialUserDrawer(false);
  };

  const handleCloseCategory = () => {
    setUserDrawerCategory(false);
    reset1({
      category: "",
      description: "",
      subcat: "",
      subdes: "",
    });
  };

  const handleCloseSubCategory = () => {
    setUserSubDrawer(false);
    reset2({
      subcategory: "",
      subdescription: "",
    });
  };

  const renderHeader = () => {
    return (
      <div className="custom-sidebar-header d-flex py-2">
        <h4>Expenses Details</h4>
        {/* <h3>{i.budgetCategory}</h3> */}
        <button
          onClick={handleClose}
          className="p-sidebar-close p-link ms-auto"
        >
          <span className="pi pi-times" />
        </button>
      </div>
    );
  };

  const renderHeaderCategory = () => {
    return (
      <div className="custom-sidebar-header d-flex py-2">
        <h4>Add Category</h4>
        <button
          onClick={handleCloseCategory}
          className="p-sidebar-close p-link ms-auto"
        >
          <span className="pi pi-times" />
        </button>
      </div>
    );
  };

  const renderHeaderSubCategory = () => {
    return (
      <div className="custom-sidebar-header d-flex py-2">
        <h4>Add Line Item</h4>
        {disabledAddCatAndAddExpn && (
          <button
            onClick={handleCloseSubCategory}
            className="p-sidebar-close p-link ms-auto"
          >
            <span className="pi pi-times" />
          </button>
        )}
      </div>
    );
  };

  useEffect(() => {
    let temp = [];
    let val = test1.month;
    for (let i = 0; i < val?.length; i++) {
      temp.push(val[i]);
    }
    setSubData(temp);
  }, [test1]);

  const onSubmit1 = (data) => {
    setIsProcess(true);
    setUserDrawer(true);
    let category = subCategory.categories.find(
      (x) => x.budgetCatID === monthsInBudgetCategory.budgetCatID
    );
    let inputJson = monthsInBudgetCategory?.month
      .map((y) => ({
        BudgetID: y?.budgetInfo?.budgetID || 0,
        BudgetCatID: category?.budgetCatID || "",
        BudgetCategory: category?.budgetCategory || "",
        BudgetSubCatID: monthsInBudgetCategory?.budgetSubCatID || "",
        BudgetSubCategory: monthsInBudgetCategory?.budgetSubCategory || "",
        FinancialYear: y?.year,
        FinancialMonth: y?.month,
        ForecastingAmount: +data[y.monthName],
        Description: monthsInBudgetCategory?.description || "",
        TenantID: GV.userDet.tenantDetail.tenantID,
        CreatedBy: GV.userDet.appUser.userName,
      }))
      .filter((a) => a?.ForecastingAmount);
    bud
      .SaveBudget(inputJson)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setIsProcess(false);
          setUserDrawer(false);
          getBudgetForecastingFinancialYear(getMonthyear.selectYear);
          toast.current.show({
            severity: "success",
            summary: res.data.message,
          });
        }
      })
      .catch(function (error) {});
    handleClose();
  };

  const onSubmitCategory = (data) => {
    setIsProcess(true);
    const datas = {
      fiscalYearID: monthYears.fiscalYearID,
      BudgetCatID: catReName ? catReName.budgetCatID : 0,
      BudgetCategory: textCatRename ? textCatRename : data.category,
      Description: data.description,
      StartMonth: getMonthyear.startMnth,
      StartYear: getMonthyear.startYr,
      EndMonth: getMonthyear.endMnth,
      EndYear: getMonthyear.endYr,
      TenantID: GV.userDet.tenantDetail.tenantID,
      CreatedBy: GV.userDet.appUser.userName,
    };
    bud
      .SaveBudgetCategory(datas)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setAddCatIndValue(-1);
          setTextCatRename("");
          setIsProcess(false);
          getBudgetForecastingFinancialYear(getMonthyear.selectYear);
          setAddCatRename(false);
          toast.current.show({
            severity: "success",
            summary: res.data.message,
          });
        }
      })
      .catch(function (error) {
        if (error.response?.data?.message === "Already Exist") {
          toast.current.show({
            severity: "error",
            summary: error.response.data.message,
            detail: "Already Exist",
          });
        }
      });
    handleCloseCategory();
  };

  const onSubmitSubCategory = (data) => {
    if (data.BudgetSubCategory === "") {
      setRename(false);
      return;
    }
    setIsProcess(true);
    const datas = {
      fiscalYearID: monthYears.fiscalYearID,
      BudgetSubCatID: textRenameBudgetCatId
        ? textRenameBudgetCatId.budgetSubCatID
        : 0,
      BudgetSubCategory: textRename ? textRename : data.subcategory,
      BudgetCatID: textRenameBudgetCatId
        ? textRenameBudgetCatId.budgetCatID
        : test?.budgetCatID,
      Description: data.subdescription,
      StartMonth: getMonthyear.startMnth,
      StartYear: getMonthyear.startYr,
      EndMonth: getMonthyear.endMnth,
      EndYear: getMonthyear.endYr,
      TenantID: GV.userDet.tenantDetail.tenantID,
      CreatedBy: GV.userDet.appUser.userName,
    };
    console.log(datas, "li");
    bud
      .SaveBudgetSubCategory(datas)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setRename(false);
          setIsProcess(false);
          setAddCatRename(false);
          getBudgetForecastingFinancialYear(getMonthyear.selectYear);
          toast.current.show({
            severity: "success",
            summary: res.data.message,
          });
        }
      })
      .catch(function (error) {
        if (error.response?.data?.message === "Already Exists") {
          toast.current.show({
            severity: "error",
            summary: error.response?.data?.message,
          });
        }
      });
    handleCloseSubCategory();
  };
  const onClickEdit = (value, financialyear, item) => {
    const findCurrentMonth = new Date().getMonth() + 1;
    const findCurrentYear = new Date().getFullYear();
    console.log(value, "onClickEdit");
    console.log(item, "item");
    setMonthsInBudgetCategory(value);
    if (financialyear) {
      reset();
      setValue("subcat", value.budgetSubCategory);
      value.month.forEach((x) => {
        setValue(x.monthName, x.budgetInfo?.forecastingAmount ?? 0);
        if (x.month < findCurrentMonth || x.year < findCurrentYear) {
        }
      });
    }
    // renderHeader(item)
  };

  const inputFieldValidate = (e, index, item) => {
    // setErrorMessage('');
    if (e.target.value !== "" && parseFloat(e.target.value) < 0) {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [item.monthName]: "Please enter a non-negative value.",
      }));
      return;
    }
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [item.monthName]: "",
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "-") {
      e.preventDefault();
    }
  };

  const reName = (e, i, sub) => {
    setTextRename(sub.budgetSubCategory);
    setEnableField(i);
    setIndValue(i);
    setTextRenameBudgetCatId(sub);
    setRename(true);
    setAddCatRename(false);
  };

  const saveExpenseName = (data) => {
    onSubmitSubCategory(data);
    // setRename(false);
  };
  const saveCategoryeName = (data) => {
    onSubmitCategory(data);
    // setRename(false);
  };

  const handleInputChange = (event) => {
    setTextRename(event.target.value);
  };
  const handleInputChangeCat = (event, item) => {
    setTextCatRename(event.target.value);
  };

  const deleteRowData = (e) => {
    setIsProcess(true);
    const data = {
      TenantID: GV.userDet.tenantDetail.tenantID,
      BudgetCatID: e.budgetCatID,
      BudgetSubCatID: e.budgetSubCatID ? e.budgetSubCatID : 0,
      ForcedDelete: true,
    };
    bud.DeleteBudgetCategory(data).then((res) => {
      console.log(res.data, "lip");
      if (res.data.statusCode === 200) {
        setIsProcess(false);
        getBudgetForecastingFinancialYear(getMonthyear.selectYear);
        toast.current.show({
          severity: "success",
          summary: res.data.message,
        });
      }
    });
  };

  const reject = () => {};
  const confirmDelete = (e) => {
    const findBudgetInfo = e.month;
    const values = findBudgetInfo.some((a) => a.budgetInfo);
    let mesg = `Do you want to delete ${e.budgetSubCategory}?`;
    if (values) {
      mesg = `This ${e.budgetSubCategory} contains Expense Are you sure you want to continue?`;
    }
    let obj = {
      message: mesg,
      className: "pAdj deletecircle",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultfocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => deleteRowData(e),
      reject: () => reject(),
    };
    confirmDialog(obj);
  };

  const confirmDeleteCat = (e) => {
    const findExp = e.subCategory.map((a) => a.month);
    const findExp1 = findExp.flat().filter((z) => z.budgetInfo).length;
    let mesg = `Do you want to delete ${e.budgetCategory}?`;
    if (findExp1) {
      mesg = `This ${e.budgetCategory} contains Expense. Deleting it will permanently remove this information. Are you sure you want to continue?`;
    }
    let obj = {
      message: mesg,
      className: "pAdj deletecircle",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultfocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => deleteRowData(e),
      reject: () => reject(),
    };
    confirmDialog(obj);
  };

  const confirmCanel = (sub) => {
    console.log(sub, "sss");
    setAddCatIndValue(-1);
    setRename(false);
    setAddCatRename(false);
  };

  const confirmCanelCat = (item) => {
    document.querySelector(".onhover:not([disabled])").value =
      item.budgetCategory;
    setAddCatIndValue(-1);
    setRename(false);
    setAddCatRename(false);
    getBudgetForecastingFinancialYear(getMonthyear.selectYear);
  };

  const displayExpenseName = (e) => {
    setTextRename("");
    setGetCatgryName(e.budgetCategory);
  };

  const reNameAddCategoryName = (e, item, i) => {
    setTextCatRename(item.budgetCategory);
    console.log(e, "e");
    console.log(item, "item");
    console.log(i, "i");
    setAddCatRename(true);
    setRename(false);
    setAddCatIndValue(i);
    setCatReName(item);
  };

  const cloneCategory = (e) => {
    console.log(e, "e");
    setIsClone(true);
  };

  const cancel = () => {
    reset3();
    setIsClone(false);
    setIsCloneErrFrom(false);
    setIsCloneErrTo(false);
    setCloneFrom("");
    setCloneTo("");
  };

  const GetFinYear = () => {
    bud.GetFiscalYears(GV.userDet.tenantDetail.tenantID).then((res) => {
      const sortedYears = res.data.data.sort((a, b) => a.startYr - b.startYr);
      console.log(sortedYears, "sortedYears");
      setChooseDrpDwn(sortedYears);
      setSelectCloneDrpDwnFrom(sortedYears);
      const findYr = res.data.data.find(
        (a) => a.selectYear === new Date().getFullYear()
      );
      setmonthYears(findYr);
    });
  };

  const GetCloneFiscalYears = () => {
    bud.GetCloneFiscalYears(GV.userDet.tenantDetail.tenantID).then((res) => {
      const sortedYears = res.data.data.sort((a, b) => a.startYr - b.startYr);
      setSelectCloneDrpDwnTo(sortedYears);
    });
  };

  useEffect(() => {
    if (GV.userDet?.tenantConfig?.startMonth === 1) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let startMonth = GV.userDet?.tenantConfig?.startMonth;
      console.log(startMonth, "startMonth");
      let endMonth = GV.userDet?.tenantConfig?.endMonth;
      console.log(endMonth, "endMonth");
      let financialMonths = [];
      for (let i = startMonth - 1; i < months.length; i++) {
        financialMonths = [...financialMonths, months[i].slice(0, 3)];
      }

      setDynamicMonth(financialMonths);
    } else {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let startMonth = GV.userDet?.tenantConfig?.startMonth;
      console.log(startMonth, "startMonth");
      let endMonth = GV.userDet?.tenantConfig?.endMonth;
      console.log(endMonth, "endMonth");
      let financialMonths = [];
      for (let i = startMonth - 1; i < months.length; i++) {
        financialMonths = [...financialMonths, months[i].slice(0, 3)];
      }
      for (let j = 0; j < endMonth; j++) {
        financialMonths = [...financialMonths, months[j].slice(0, 3)];
      }
      setDynamicMonth(financialMonths);
    }
  }, []);

  const CloneBudgetCategories = () => {
    setIsProcess(true);
    const data = {
      CloneYearFrom: chooseFromDate,
      CloneYearTo: chooseToDate,
      TenantID: GV.userDet.tenantDetail.tenantID,
      CreatedBy: GV.userDet.appUser.userName,
    };
    console.log(data, "clonedata");

    bud.CloneBudgetCategories(data).then((res) => {
      if (res.data.statusCode === 200) {
        GetFinYear();
        GetCloneFiscalYears();
        setCloneFrom("");
        setCloneTo("");
        if (res.data.message === "Cloned Successfully") {
          setIsProcess(false);
          toast.current.show({
            severity: "success",
            summary: res.data.message,
          });
        }
        if (res.data.message === "Clone years not found") {
          setIsProcess(false);
          toast.current.show({
            severity: "error",
            summary: res.data.message,
          });
        }
        if (res.data.message === "Budget categories are empty") {
          setIsProcess(false);
          toast.current.show({
            severity: "error",
            summary: res.data.message,
          });
        }
        if (res.data.message === "Same year! Choose the different years") {
          setIsProcess(false);
          toast.current.show({
            severity: "error",
            summary: "Choose from date and to date",
          });
        }
      }

      console.log(res.data, "cloned");
      setIsClone(false);
    });
  };

  return (
    <div>
      <BlockUI blocked={isProcess}>
        <div className="card mb-3 card-borderless cc-greyCard-custm">
          <div className="card-body p-2">
            <div className="d-flex justify-content-between flex-wrap align-items-center my-2">
              <h4 className="m-0">Budget Forecasting</h4>
              <div className="d-flex align-items-center"></div>
            </div>
          </div>
        </div>
        <div className="pnlStl pnlStl1">
          <TabView>
            <TabPanel header="Work sheet">
              {" "}
              <div className="mt-0 p-2 abc pt-0 tbmrgn">
                <Toast ref={toast}></Toast>
                {/* <div className='right-fixed '> */}
                <div className="card" style={{ border: "none" }}>
                  <div className="card-body py-1">
                    <div className="d-flex pt-2 justify-content-between flex-wrap align-items-center">
                      {/* <h4>Budget Forecasting</h4> */}
                      <h4></h4>
                      <div className="d-flex align-items-center">
                        {isClone && (
                          <form onSubmit={handleSubmit3(CloneBudgetCategories)}>
                            <div className="col-sm-12 d-flex align-items-center ">
                              <div className="row mx-auto align-items-center">
                                <div className="col-sm p-0">
                                  <Controller
                                    name="clonefrom"
                                    control={control1}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                      <Dropdown
                                        {...field}
                                        className="yr-down budget-drop"
                                        placeholder="Select an from date"
                                        options={selectCloneDrpDwnFrom}
                                        optionLabel="years"
                                        value={cloneFrom}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                          handleYearChangeCloneFrom(e.value);
                                        }}
                                      />
                                    )}
                                  />
                                  {dynamicMonth?.map((item, index) => (
                                    <div
                                      className={`ps-1 col-md mt-1 pb-1 pt-1 text-center  ${
                                        index % 2 === 0 ? "odd" : "even"
                                      }`}
                                      style={{fontWeight:"700"}}
                                      key={index}
                                    >
                                      {item}
                                    </div>
                                  ))}
                                </div>
                                <div className="col-sm p-0">
                                  <Controller
                                    name="cloneto"
                                    control={control1}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                      <Dropdown
                                        {...field}
                                        className="yr-down budget-drop ms-2"
                                        // style={{ width: "100%", height: "67%" }}
                                        placeholder="Select an to date"
                                        options={selectCloneDrpDwnTo}
                                        optionLabel="years"
                                        value={cloneTo}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                          handleYearChangeCloneTo(e.value);
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                                <div className="col-sm p-0">
                                  <div>
                                    <button
                                      type="submit"
                                      className="btn btn-primary ms-3"
                                    >
                                      {" "}
                                      Clone
                                    </button>
                                  </div>
                                </div>
                                <div className="col-sm p-0">
                                  <button
                                    className="btn btn-danger mx-2"
                                    onClick={cancel}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="row mx-auto">
                                <div
                                  className="col-sm-3 p-0"
                                  style={{ width: "33%" }}
                                >
                                  {errors3.clonefrom && (
                                    <p className="text-danger p-0">
                                      This field is required
                                    </p>
                                  )}
                                </div>
                                <div
                                  className="col-sm-3"
                                  style={{ width: "33%" }}
                                >
                                  {errors3.cloneto && (
                                    <p className="text-danger p-0">
                                      This field is required
                                    </p>
                                  )}
                                </div>
                                <div className="col-sm"></div>
                                <div className="col-sm"></div>
                              </div>
                            </div>
                          </form>
                        )}
                        {!isClone && (
                          <>
                            {/* <div>
                                <button
                                  className="btn btn-primary py-1 mx-2 "
                                  onClick={(e) => cloneCategory(e)}
                                >
                                  {" "}
                                  <i className="pi pi-clone"></i> Clone
                                </button>
                              </div> */}
                            <div>
                              {!disabledAddCatAndAddExpn && (
                                <button
                                  className="btn btn-primary py-1 me-2 "
                                  onClick={() => {
                                    setUserDrawerCategory(true);
                                    setTextCatRename("");
                                    setCatReName("");
                                  }}
                                >
                                  {" "}
                                  <i className="pi pi-plus"></i> Add Category
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {isFinancialYear === true && (
                  <div className="card mt-2">
                    <div className="mt-2 row mb-2 ">
                      <div>
                        <div className=" row mx-auto me-2">
                          <div className="col-md-2  ps-0 me-1">
                            <div className="ps-2 mt-0 ">
                              <Controller
                                render={({ field }) => (
                                  <Dropdown
                                    className="yr-down budget-drop"
                                    placeholder="Select an option"
                                    // options={saveYears.map((year) => ({
                                    //   value: year,
                                    //   label: year.years,
                                    // }))}
                                    options={chooseDrpDwn}
                                    optionLabel="years"
                                    {...field}
                                    value={monthYears}
                                    onChange={(e) => handleYearChange1(e.value)}
                                  />
                                )}
                                control={control}
                                name="monthWithYears"
                              />
                            </div>
                          </div>
                          {dynamicMonth?.map((item, index) => (
                            <div
                              className={`ps-1 col-md mt-1 pb-1 pt-1 text-center  ${
                                index % 2 === 0 ? "odd" : "even"
                              }`}
                              style={{fontWeight:"700"}}
                              key={index}
                            >
                              {item}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isLoading && (
                  <ProgressSpinner
                    style={{
                      width: "50px",
                      height: "50px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    strokeWidth="4"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                )}
                {subCategory.categories && subCategory.categories.length > 0
                  ? subCategory.categories.map((item, i) => {
                      const totalForecastingAmountByMonth = {};
                      return (
                        <Card className="custAccrdP mt-2 " key={i}>
                          <div className="d-flex justify-content-between flex-wrap">
                              <div className="col-sm-12">
                            <div className="row ">
                                <Accordion
                                  activeIndex={i === 0 ? 0 : null}
                                  className="col-sm-12 "
                                >
                                  <AccordionTab
                                    header={
                                      <div
                                        className={`col-sm-12 pe-2 d-flex ${
                                          new Date().getFullYear() <=
                                          monthYears.id
                                            ? "month-expense"
                                            : ""
                                        }  pointer`}
                                      >
                                        <div
                                          className="col-sm mx-2"
                                          ref={categoryNameRef}
                                          // onInput={handleInputChangeCat}
                                          // contentEditable={
                                          //   addCatRename && i === addCatIndValue
                                          //     ? "true"
                                          //     : "false"
                                          // }
                                          style={{
                                            width: "70rem",
                                            display: "flex",
                                            alignItems: "center",
                                            background:
                                              addCatRename && i === addCatIndValue
                                                ? "white"
                                                : "",
                                            // background: addCatRename && i === addCatIndValue ?  "white": "#e3f2f2"
                                          }}
                                        >
                                          {/* {item.budgetCategory} */}
                                          <div className="col-sm">
                                            <input
                                              type="text"
                                              className="me-2 onhover"
                                              defaultValue={item.budgetCategory}
                                              disabled={i !== addCatIndValue}
                                              onChange={(e) =>
                                                handleInputChangeCat(e, item)
                                              }
                                              onClick={(e) => {
                                                e.stopPropagation();
                                              }}
                                              // ref={contentRef}
                                              style={{
                                                background:
                                                  addCatRename &&
                                                  i === addCatIndValue
                                                    ? "#FFF"
                                                    : "transparent",
                                                fontWeight: "600",
                                                width: "100%",
                                                // cursor: !addCatRename && i === addCatIndValue ? "pointer": ''
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-sm-8 text-start">
                                          {addCatRename &&
                                          i === addCatIndValue ? (
                                            <i
                                              className="pi pi-check me-2"
                                              style={{ color: "#16d616" }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                saveCategoryeName(e, i);
                                              }}
                                              title="Save Category Name"
                                            ></i>
                                          ) : (
                                            <i
                                              className="pi pi-pencil me-2 ticksuccess"
                                              style={{ color: "chocolate" }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                reNameAddCategoryName(e, item, i);
                                              }}
                                              title="Rename Category"
                                            ></i>
                                          )}
                                          {addCatRename &&
                                          i === addCatIndValue ? (
                                            <i
                                              className="pi pi-times me-1"
                                              style={{ color: "#ff5c5c" }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                confirmCanelCat(item);
                                              }}
                                              title="Cancel Delete"
                                            ></i>
                                          ) : (
                                            <i
                                              className="pi pi-trash me-1 ticksuccess"
                                              style={{ color: "#ff5c5c" }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                confirmDeleteCat(item);
                                              }}
                                              title="Delete Category"
                                            ></i>
                                          )}
                                        </div>
                                        <div
                                          className="col-sm"
                                          style={{
                                            textAlign: "end",
                                            width: "19rem",
                                          }}
                                        >
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setRename(false);
                                              setAddCatRename(false);
                                              setTextRename("");
                                              setTextRenameBudgetCatId("");
                                              console.log(
                                                textRename,
                                                "textRename button"
                                              );
                                              e.stopPropagation();
                                              setUserSubDrawer(true);
                                              setTest(item);
                                              displayExpenseName(item);
                                            }}
                                            className="accordbtn"
                                          >
                                            Add Line Item
                                          </button>
                                        </div>
                                      </div>
                                    }
                                    className="custAccrd custAccrdbg custacm accHeader"
                                  >
                                    <div
                                      className={`d-flex ${
                                        new Date().getFullYear() <= monthYears.id
                                          ? "month-expense"
                                          : ""
                                      }  pointer`}
                                    >
                                      {/* <div className="d-flex col-sm-2 justify-content-between pe-2">
                                    <h6
                                      className=" m-1"
                                      onInput={handleInputChangeCat}
                                      contentEditable={
                                        addCatRename && i === addCatIndValue
                                          ? "true"
                                          : "false"
                                      }
                                      style={{}}
                                    >
                                      {item.budgetCategory}
                                    </h6>
                                    <div className=" text-start">
                                      {addCatRename && i === addCatIndValue ? (
                                        <i
                                          className="pi pi-check me-2"
                                          style={{ color: "#16d616" }}
                                          onClick={(e) => saveCategoryeName(e, i)}
                                          title="Save Category Name"
                                        ></i>
                                      ) : (
                                        <i
                                          className="pi pi-pencil me-2 ticksuccess"
                                          style={{ color: "chocolate" }}
                                          onClick={(e) =>
                                            reNameAddCategoryName(e, item, i)
                                          }
                                          title="Rename Category"
                                        ></i>
                                      )}
                                      {addCatRename && i === addCatIndValue ? (
                                        <i
                                          className="pi pi-times me-1"
                                          style={{ color: "#ff5c5c" }}
                                          onClick={() => confirmCanel()}
                                          title="Cancel Deletion"
                                        ></i>
                                      ) : (
                                        <i
                                          className="pi pi-trash me-1 ticksuccess"
                                          style={{ color: "#ff5c5c" }}
                                          onClick={() => confirmDeleteCat(item)}
                                          title="Delete Category"
                                        ></i>
                                      )}
                                    </div>
                                  </div> */}
                                      {/* <div className="col-sm text-end">
                                    <p className="customFont mb-0">
                                      {" "}
                                      <span
                                        className="ms-1 pointer text-decoration-underline text-primary"
                                        onClick={() => {
                                          setUserSubDrawer(true);
                                          setTest(item);
                                          displayExpenseName(item);
                                        }}
                                      >
                                        {" "}
                                        Add Line Item{" "}
                                      </span>{" "}
                                    </p>
                                                               </div> */}
                                    </div>
                                    {item?.subCategory?.map((sub, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="row ps-1 mt-1 mx-auto pe-1"
                                        >
                                          <Toast ref={toast} />
                                          <div
                                            className={`col-md-2 col-sm-1 ps-0 ${
                                              new Date().getFullYear() <=
                                              monthYears.id
                                                ? "month-expense"
                                                : ""
                                            } `}
                                          >
                                            <div
                                              className="ps-1 mt-1 pb-1 pt-1"
                                              style={{
                                                borderBottom: "1px solid #d3d3d3",
                                                background:
                                                  rename &&
                                                  i === indValue &&
                                                  sub.budgetSubCategory ===
                                                    textRenameBudgetCatId.budgetSubCategory
                                                    ? "rgb(255 255 255)"
                                                    : "#FFE6B9",
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <a
                                                className="text-decoration-none budget-category-link scrollBarReName"
                                                style={{
                                                  color:
                                                    rename &&
                                                    i === indValue &&
                                                    sub.budgetSubCategory ===
                                                      textRenameBudgetCatId.budgetSubCategory
                                                      ? "white"
                                                      : "black",
                                                }}
                                                // contentEditable={
                                                //   rename && i === indValue
                                                //     ? "true"
                                                //     : "false"
                                                // }
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Edit expense"
                                                onInput={handleInputChange}
                                                onMouseEnter={(e) => {
                                                  e.stopPropagation();
                                                  !rename &&
                                                    e.target.classList.add(
                                                      "underline-hover"
                                                    );
                                                }}
                                                onMouseLeave={(e) =>
                                                  e.target.classList.remove(
                                                    "underline-hover"
                                                  )
                                                }
                                                onClick={(e) => {
                                                  {
                                                    rename
                                                      ? e.stopPropagation()
                                                      : setUserDrawer(true);
                                                  }
                                                  onClickEdit(
                                                    sub,
                                                    isFinancialYear,
                                                    item
                                                  );
                                                  setTest(item);
                                                  setTest1(sub);
                                                }}
                                                // onClick={isFinancialYear === true
                                                //     ? () => { setUserDrawer(true); onClickEdit1(sub); setTest(item); setTest1(sub); }
                                                //     : () => { setUserDrawer(true); onClickEdit(sub); setTest(item); setTest1(sub); }
                                                // }
                                              >
                                                {sub.budgetSubCategory}
                                              </a>
                                              {rename &&
                                              i === indValue &&
                                              sub.budgetSubCategory ===
                                                textRenameBudgetCatId.budgetSubCategory ? (
                                                <input
                                                  type="text"
                                                  defaultValue={
                                                    sub.budgetSubCategory
                                                  }
                                                  onChange={handleInputChange}
                                                  // ref={contentRef}
                                                  style={{
                                                    background: "#FFF",
                                                    width: "80%",
                                                  }}
                                                />
                                              ) : (
                                                ""
                                              )}
                                              {/* <input type="text" value={sub.budgetSubCategory} /> */}
                                              {/* <Chips value={chipsValue} onChange={(e) => setValue(e.value)} /> */}
                                              <div
                                                className={`${
                                                  new Date().getFullYear() <=
                                                  monthYears.id
                                                    ? "month-expense"
                                                    : ""
                                                }`}
                                              >
                                                {rename &&
                                                i === indValue &&
                                                sub.budgetSubCategory ===
                                                  textRenameBudgetCatId.budgetSubCategory ? (
                                                  <i
                                                    className="pi pi-check me-2"
                                                    style={{ color: "#16d616" }}
                                                    onClick={(e) =>
                                                      saveExpenseName(e, i, sub)
                                                    }
                                                    title="Save Expense Name"
                                                  ></i>
                                                ) : (
                                                  <i
                                                    className="pi pi-pencil me-2 ticksuccess"
                                                    style={{ color: "chocolate" }}
                                                    onClick={(e) =>
                                                      reName(e, i, sub)
                                                    }
                                                    title="Rename Expense"
                                                  ></i>
                                                )}
                                                {rename &&
                                                i === indValue &&
                                                sub.budgetSubCategory ===
                                                  textRenameBudgetCatId.budgetSubCategory ? (
                                                  <i
                                                    className="pi pi-times me-1"
                                                    style={{ color: "#ff5c5c" }}
                                                    onClick={() =>
                                                      confirmCanel(sub)
                                                    }
                                                    title="Cancel Deletion"
                                                  ></i>
                                                ) : (
                                                  <i
                                                    className="pi pi-trash me-1 ticksuccess"
                                                    style={{ color: "#ff5c5c" }}
                                                    onClick={() =>
                                                      confirmDelete(sub)
                                                    }
                                                    title="Delete Expense"
                                                  ></i>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          {sub?.month?.map((mon, j) => {
                                            // Calculate forecastingAmount for the current month
                                            const forecastingAmount =
                                              mon?.budgetInfo
                                                ? mon.budgetInfo.forecastingAmount
                                                : null;
                                            // Accumulate forecastingAmount to the total for the month
                                            totalForecastingAmountByMonth[j] =
                                              (totalForecastingAmountByMonth[j] ||
                                                null) + forecastingAmount;
                                            return (
                                              <div
                                                key={j}
                                                className={`ps-1 col-md mt-1 pb-1 pt-1 d-flex justify-content-end  ${
                                                  j % 2 === 0 ? "odd" : "even"
                                                }`}
                                              >
                                                {/* {GV.userDet.tenantConfig.currency} */}
                                                {forecastingAmount
                                                  ? forecastingAmount?.toFixed(2)
                                                  : "0.00"}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      );
                                    })}
                                    {/* Display the total for each month in a single div */}
                                    <div className="row ps-1 mt-1 total-forecasting-amount mx-auto mb-1">
                                      <div className="col-md-2 ps-0">
                                        {isCategory?.length === 0 ? (
                                          <div> There is no Expenses</div>
                                        ) : (
                                          <div
                                            className="ps-1 mt-1 pb-1 pt-1  text-bold"
                                            style={{
                                              fontWeight: "bold",
                                              color: "black",
                                              background: "#f6f6f6",
                                            }}
                                          >
                                            Total ({GV.userDet.tenantConfig.currency})
                                          </div>
                                        )}
                                      </div>
                                      {Object.values(
                                        totalForecastingAmountByMonth
                                      ).map((total, k) => (
                                        <div
                                          key={k}
                                          className={`ps-1 col-md mt-1 pb-1 pt-1  d-flex justify-content-end   ${
                                            k % 2 === 0 ? "teven" : "teven"
                                          }`}
                                          style={{
                                            fontWeight: "bold",
                                            background: "#f6f6f6",
                                          }}
                                        >
                                          {GV.userDet.tenantConfig.currency}{" "}
                                          {total.toFixed(2)}
                                        </div>
                                      ))}
                                    </div>
                                  </AccordionTab>
                                </Accordion>
                              </div>
                            </div>
                            {/* <div className="col-sm text-end">
                          <p className="customFont mb-0">
                            {" "}
                            <span
                              className="ms-3 pointer text-decoration-underline text-primary"
                              onClick={() => {
                                setUserSubDrawer(true);
                                setTest(item);
                                displayExpenseName(item);
                              }}
                            >
                              {" "}
                              Add Expense{" "}
                            </span>{" "}
                          </p>
                        </div> */}
                          </div>
                          <Divider />
                        </Card>
                      );
                    })
                  : !isLoading && (
                      <Card
                        style={{
                          height: "500px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <h5>No data available for this year</h5>
                        </div>
                      </Card>
                    )}
                <ConfirmDialog />
                {/* {subCategory?.categories?.map((item, i) => {
              const totalForecastingAmountByMonth = {};
              return (
                <Card className=" mt-2" key={i}>
                  <div className="d-flex justify-content-between flex-wrap">
                    <h6>{item.budgetCategory}</h6>
                    <div>
                      <p className="customFont mb-0">
                        {" "}
                        <span
                          className="ms-3 pointer text-decoration-underline text-primary"
                          onClick={() => {
                            setUserSubDrawer(true);
                            setTest(item);
                          }}
                        >
                          {" "}
                          Add Expense{" "}
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                  <Divider />
                  {item?.subCategory?.map((sub, i) => {
                    return (
                      <div key={i} className="row ps-1 mt-1">
                        <div className="col-md-2 ps-0">
            
                          <div className="ps-1 mt-1 pb-1 pt-1 sub-cat pointer">
                            <a
                              className="text-decoration-none budget-category-link text-black"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit expense"
                              onMouseEnter={(e) =>
                                e.target.classList.add("underline-hover")
                              }
                              onMouseLeave={(e) =>
                                e.target.classList.remove("underline-hover")
                              }
                              onClick={() => {
                                setUserDrawer(true);
                                onClickEdit(sub, isFinancialYear);
                                setTest(item);
                                setTest1(sub);
                              }}
            
                            >
                              {sub.budgetSubCategory}
                            </a>
                          </div>
                        </div>
                        {sub?.month?.map((mon, j) => {
                          const forecastingAmount = mon?.budgetInfo
                            ? mon.budgetInfo.forecastingAmount
                            : null;
                          totalForecastingAmountByMonth[j] =
                            (totalForecastingAmountByMonth[j] || null) +
                            forecastingAmount;
                          return (
                            <div
                              key={j}
                              className={`ps-1 col-md mt-1 pb-1 pt-1 d-flex justify-content-end  ${
                                j % 2 === 0 ? "odd" : "even"
                              }`}
                            >
                              {forecastingAmount == 0
                                ? ""
                                : forecastingAmount?.toFixed(2)}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  <div className="row ps-1 mt-1 total-forecasting-amount">
                    <div className="col-md-2 ps-0">
                      <div
                        className="ps-1 mt-1 pb-1 pt-1 sub-cat pointer text-bold"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Total
                      </div>
                    </div>
                    {Object.values(totalForecastingAmountByMonth).map(
                      (total, k) => (
                        <div
                          key={k}
                          className={`ps-1 col-md mt-1 pb-1 pt-1  d-flex justify-content-end   ${
                            k % 2 === 0 ? "teven" : "teven"
                          }`}
                          style={{ fontWeight: "bold" }}
                        >
                          {total.toFixed(2)}
                        </div>
                      )
                    )}
                  </div>
                </Card>
              );
            })} */}
              </div>
              {isProcess && (
                <div className="login-spin d-flex justify-content-center">
                  {" "}
                  <ProgressSpinner
                    style={{
                      width: "40px",
                      height: "50px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    strokeWidth="1"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />{" "}
                </div>
              )}
            </TabPanel>
            <TabPanel header="Summary view">
              {" "}
              <div className="mt-0 p-2 abc pt-0">
                <Toast ref={toast}></Toast>

                {isFinancialYear === true && (
                  <div className="card mt-2">
                    <div className="mt-2 row mb-2 ">
                      <div>
                        <div className=" row mx-auto me-2">
                          <div className="col-md-2  ps-0 me-1">
                            <div className="ps-2 mt-0 ">
                              <Controller
                                render={({ field }) => (
                                  <Dropdown
                                    className="yr-down budget-drop"
                                    placeholder="Select an option"
                                   
                                    options={chooseDrpDwn}
                                    optionLabel="years"
                                    {...field}
                                    value={monthYears}
                                    onChange={(e) => handleYearChange1(e.value)}
                                  />
                                )}
                                control={control}
                                name="monthWithYears"
                              />
                            </div>
                          </div>
                          {dynamicMonth?.map((item, index) => (
                            <div
                              className={`ps-1 col-md mt-1 pb-1 pt-1 text-center  ${
                                index % 2 === 0 ? "odd" : "even"
                              }`}
                              style={{fontWeight:"bold"}}
                              key={index}
                            >
                              {item}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isLoading && (
                  <ProgressSpinner
                    style={{
                      width: "50px",
                      height: "50px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    strokeWidth="4"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                )}
                {subCategory.categories && subCategory.categories.length > 0
                  ? subCategory.categories.map((item, i) => {
                      const totalForecastingAmountByMonth = {};

                      return (
                        <div className="custAccrdP mt-2" key={i}>
                          <div className="d-flex justify-content-between flex-wrap">
                            <div className="col-sm">
                              <div
                                className={`col-sm-12 d-flex ${
                                  new Date().getFullYear() <= monthYears.id
                                    ? "month-expense"
                                    : ""
                                } pointer`}
                                style={{ width: "100%" }}
                              >
                               
                                <div
                                  className="col-sm"
                                  style={{
                                    textAlign: "end",
                                    width: "19rem",
                                  }}
                                >
                                </div>
                              </div>

                              {item?.subCategory?.map((sub, j) => (
                                <div key={j} className="ps-1 mt-1 mx-auto">
                                  <Toast ref={toast} />{" "}
                                  <div
                                    className={`col-md-2 ps-0 ${
                                      new Date().getFullYear() <= monthYears.id
                                        ? "month-expense"
                                        : ""
                                    }`}
                                  >
                                  </div>
                                  {sub?.month?.map((mon, k) => {
                                    const forecastingAmount =
                                      mon?.budgetInfo?.forecastingAmount || 0;
                                    totalForecastingAmountByMonth[k] =
                                      (totalForecastingAmountByMonth[k] || 0) +
                                      forecastingAmount;
                                    return (
                                      <div key={k}>
                                      </div>
                                    );
                                  })}
                                </div>
                              ))}

                              <div className="row ps-1 mt-1 total-forecasting-amount mx-auto mb-1">
                                <div className="col-md-2 ps-0">
                                  {isCategory?.length === 0 ? (
                                    <div>There are no Expenses</div>
                                  ) : (
                                    <div
                                      className="ps-1 mt-1 pb-1 pt-1 sub-cat pointer text-bold"
                                      style={{
                                        // fontWeight: "bold",
                                        fontWeight:"500",
                                        color: "black",
                                        background: "#f6f6f6",
                                        width:"auto"
                                      }}
                                    >
                                      {item.budgetCategory}
                                    </div>
                                  )}
                                </div>
                                {Object.values(
                                  totalForecastingAmountByMonth
                                ).map((total, k) => (
                                  <div
                                    key={k}
                                    className={`ps-1 col-md mt-1 pb-1 pt-1 d-flex justify-content-end ${
                                      k % 2 === 0 ? "teven" : "teven"
                                    }`}
                                    style={{
                                      // fontWeight: "bold",
                                      background: "#f6f6f6",
                                    }}
                                  >
                                    {GV.userDet.tenantConfig.currency}{" "}
                                    {total.toFixed(2)}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : 
                    !isLoading && (
                      <Card
                        style={{
                          height: "500px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <h5>No data available for this year</h5>
                        </div>
                      </Card>
                    )}
                <ConfirmDialog />
              </div>
              {isProcess && (
                <div className="login-spin d-flex justify-content-center">
                  {" "}
                  <ProgressSpinner
                    style={{
                      width: "40px",
                      height: "50px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    strokeWidth="1"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />{" "}
                </div>
              )}
            </TabPanel>
            <TabPanel header="Detail view">
              {" "}
              <div className="mt-0 p-2 abc pt-0">
                <Toast ref={toast}></Toast>

                {isFinancialYear === true && (
                  <div className="card mt-2">
                    <div className="mt-2 row mb-2 ">
                      <div>
                        <div className=" row mx-auto me-2">
                          <div className="col-md-2  ps-0 me-1">
                            <div className="ps-2 mt-0 ">
                              <Controller
                                render={({ field }) => (
                                  <Dropdown
                                    className="yr-down budget-drop"
                                    placeholder="Select an option"
                                    options={chooseDrpDwn}
                                    optionLabel="years"
                                    {...field}
                                    value={monthYears}
                                    onChange={(e) => handleYearChange1(e.value)}
                                  />
                                )}
                                control={control}
                                name="monthWithYears"
                              />
                            </div>
                          </div>
                          {dynamicMonth?.map((item, index) => (
                            <div
                              className={`ps-1 col-md mt-1 pb-1 pt-1 text-center  ${
                                index % 2 === 0 ? "odd" : "even"
                              }`}
                              style={{fontWeight:"bold"}}

                              key={index}
                            >
                              {item}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isLoading && (
                  <ProgressSpinner
                    style={{
                      width: "50px",
                      height: "50px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    strokeWidth="4"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                )}
                {subCategory.categories && subCategory.categories.length > 0
                  ? subCategory.categories.map((item, i) => {
                      const totalForecastingAmountByMonth = {};
                      return (
                        <Card className="custAccrdP mt-2 " >
                          <div key={i} className="d-flex justify-content-between flex-wrap">
                            <div className="col-sm">
                              <Accordion
                                activeIndex={i === 0 ? 0 : null}
                                className="col-sm-12 "
                              >
                                <AccordionTab
                                  header={
                                    <div
                                      className={`col-sm-12 d-flex ${
                                        new Date().getFullYear() <=
                                        monthYears.id
                                          ? "month-expense"
                                          : ""
                                      }  pointer`}
                                    >
                                      <div
                                        className="col-sm mx-2"
                                        ref={categoryNameRef}
                                        // onInput={handleInputChangeCat}
                                        // contentEditable={
                                        //   addCatRename && i === addCatIndValue
                                        //     ? "true"
                                        //     : "false"
                                        // }
                                        style={{
                                          width: "70rem",
                                          display: "flex",
                                          alignItems: "center",
                                          background:
                                            addCatRename && i === addCatIndValue
                                              ? "white"
                                              : "",
                                          // background: addCatRename && i === addCatIndValue ?  "white": "#e3f2f2"
                                        }}
                                      >
                                        {/* {item.budgetCategory} */}
                                        <div className="col-sm">
                                          <input
                                            type="text"
                                            className="me-2 onhover"
                                            defaultValue={item.budgetCategory}
                                            disabled={i !== addCatIndValue}
                                            onChange={(e) =>
                                              handleInputChangeCat(e, item)
                                            }
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                            // ref={contentRef}
                                            style={{
                                              background:
                                                addCatRename &&
                                                i === addCatIndValue
                                                  ? "#FFF"
                                                  : "transparent",
                                              fontWeight: "600",
                                              width: "100%",
                                              // cursor: !addCatRename && i === addCatIndValue ? "pointer": ''
                                            }}
                                          />
                                        </div>
                                      </div>

                                      <div
                                        className="col-sm"
                                        style={{
                                          textAlign: "end",
                                          width: "19rem",
                                        }}
                                      ></div>
                                    </div>
                                  }
                                  className="custAccrd custAccrdbg custacm "
                                >
                                  <div
                                    className={`d-flex ${
                                      new Date().getFullYear() <= monthYears.id
                                        ? "month-expense"
                                        : ""
                                    }  pointer`}
                                  ></div>
                                  {item?.subCategory?.map((sub, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="row ps-1 mt-1 mx-auto pe-2"
                                      >
                                        <Toast ref={toast} />
                                        <div
                                          className={`col-md-2 ps-0 ${
                                            new Date().getFullYear() <=
                                            monthYears.id
                                              ? "month-expense"
                                              : ""
                                          } `}
                                        >
                                          <div
                                            className="ps-1 mt-1 pb-1 pt-1 sub-cat pointer"
                                            style={{
                                              borderBottom: "1px solid #d3d3d3",
                                              background:
                                                rename &&
                                                i === indValue &&
                                                sub.budgetSubCategory ===
                                                  textRenameBudgetCatId.budgetSubCategory
                                                  ? "rgb(255 255 255)"
                                                  : "#FFE6B9",
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <a
                                              className="text-decoration-none budget-category-link scrollBarReName"
                                              style={{
                                                color:
                                                  rename &&
                                                  i === indValue &&
                                                  sub.budgetSubCategory ===
                                                    textRenameBudgetCatId.budgetSubCategory
                                                    ? "white"
                                                    : "black",
                                              }}
                                              // contentEditable={
                                              //   rename && i === indValue
                                              //     ? "true"
                                              //     : "false"
                                              // }
                                              data-toggle="tooltip"
                                              data-placement="top"

                                              // onClick={isFinancialYear === true
                                              //     ? () => { setUserDrawer(true); onClickEdit1(sub); setTest(item); setTest1(sub); }
                                              //     : () => { setUserDrawer(true); onClickEdit(sub); setTest(item); setTest1(sub); }
                                              // }
                                            >
                                              {sub.budgetSubCategory}
                                            </a>
                                            {rename &&
                                            i === indValue &&
                                            sub.budgetSubCategory ===
                                              textRenameBudgetCatId.budgetSubCategory ? (
                                              <input
                                                type="text"
                                                defaultValue={
                                                  sub.budgetSubCategory
                                                }
                                                onChange={handleInputChange}
                                                // ref={contentRef}
                                                style={{
                                                  background: "#FFF",
                                                  width: "80%",
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {/* <input type="text" value={sub.budgetSubCategory} /> */}
                                            {/* <Chips value={chipsValue} onChange={(e) => setValue(e.value)} /> */}
                                          </div>
                                        </div>
                                        {sub?.month?.map((mon, j) => {
                                          // Calculate forecastingAmount for the current month
                                          let forecastingAmount =
                                            mon?.budgetInfo
                                              ? mon.budgetInfo.forecastingAmount
                                              : null;
                                          // Accumulate forecastingAmount to the total for the month
                                          totalForecastingAmountByMonth[j] =
                                            (totalForecastingAmountByMonth[j] ||
                                              null) + forecastingAmount;
                                          return (
                                            <div
                                              key={j}
                                              className={`ps-1 col-md mt-1 pb-1 pt-1 d-flex justify-content-end  ${
                                                j % 2 === 0 ? "odd" : "even"
                                              }`}
                                            >
                                              {GV.userDet.tenantConfig.currency}
                                              {forecastingAmount
                                                ? forecastingAmount?.toFixed(2)
                                                : "0.00"}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  })}
                                  {/* Display the total for each month in a single div */}
                                  <div className="row ps-1 mt-1 total-forecasting-amount mx-auto mb-1">
                                    <div className="col-md-2 ps-0">
                                      {isCategory?.length === 0 ? (
                                        <div> There is no Expenses</div>
                                      ) : (
                                        <div
                                          className="ps-1 mt-1 pb-1 pt-1 sub-cat pointer text-bold"
                                          style={{
                                            fontWeight: "bold",
                                            color: "black",
                                            background: "#f6f6f6",
                                          }}
                                        >
                                          Total ({GV.userDet.tenantConfig.currency})
                                        </div>
                                      )}
                                    </div>
                                    {Object.values(
                                      totalForecastingAmountByMonth
                                    ).map((total, k) => (
                                      <div
                                        key={k}
                                        className={`ps-1 col-md mt-1 pb-1 pt-1  d-flex justify-content-end   ${
                                          k % 2 === 0 ? "teven" : "teven"
                                        }`}
                                        style={{
                                          fontWeight: "bold",
                                          background: "#f6f6f6",
                                        }}
                                      >
                                        {GV.userDet.tenantConfig.currency}{" "}
                                        {total.toFixed(2)}
                                      </div>
                                    ))}
                                  </div>
                                </AccordionTab>
                              </Accordion>
                            </div>
                            {/* <div className="col-sm text-end">
                          <p className="customFont mb-0">
                            {" "}
                            <span
                              className="ms-3 pointer text-decoration-underline text-primary"
                              onClick={() => {
                                setUserSubDrawer(true);
                                setTest(item);
                                displayExpenseName(item);
                              }}
                            >
                              {" "}
                              Add Expense{" "}
                            </span>{" "}
                          </p>
                        </div> */}
                          </div>
                          <Divider />
                        </Card>
                      );
                    })
                  : !isLoading && (
                      <Card
                        style={{
                          height: "500px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <h5>No data available for this year</h5>
                        </div>
                      </Card>
                    )}
                <ConfirmDialog />
                {/* {subCategory?.categories?.map((item, i) => {
              const totalForecastingAmountByMonth = {};
              return (
                <Card className=" mt-2" key={i}>
                  <div className="d-flex justify-content-between flex-wrap">
                    <h6>{item.budgetCategory}</h6>
                    <div>
                      <p className="customFont mb-0">
                        {" "}
                        <span
                          className="ms-3 pointer text-decoration-underline text-primary"
                          onClick={() => {
                            setUserSubDrawer(true);
                            setTest(item);
                          }}
                        >
                          {" "}
                          Add Expense{" "}
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                  <Divider />
                  {item?.subCategory?.map((sub, i) => {
                    return (
                      <div key={i} className="row ps-1 mt-1">
                        <div className="col-md-2 ps-0">
            
                          <div className="ps-1 mt-1 pb-1 pt-1 sub-cat pointer">
                            <a
                              className="text-decoration-none budget-category-link text-black"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit expense"
                              onMouseEnter={(e) =>
                                e.target.classList.add("underline-hover")
                              }
                              onMouseLeave={(e) =>
                                e.target.classList.remove("underline-hover")
                              }
                              onClick={() => {
                                setUserDrawer(true);
                                onClickEdit(sub, isFinancialYear);
                                setTest(item);
                                setTest1(sub);
                              }}
            
                            >
                              {sub.budgetSubCategory}
                            </a>
                          </div>
                        </div>
                        {sub?.month?.map((mon, j) => {
                          const forecastingAmount = mon?.budgetInfo
                            ? mon.budgetInfo.forecastingAmount
                            : null;
                          totalForecastingAmountByMonth[j] =
                            (totalForecastingAmountByMonth[j] || null) +
                            forecastingAmount;
                          return (
                            <div
                              key={j}
                              className={`ps-1 col-md mt-1 pb-1 pt-1 d-flex justify-content-end  ${
                                j % 2 === 0 ? "odd" : "even"
                              }`}
                            >
                              {forecastingAmount == 0
                                ? ""
                                : forecastingAmount?.toFixed(2)}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  <div className="row ps-1 mt-1 total-forecasting-amount">
                    <div className="col-md-2 ps-0">
                      <div
                        className="ps-1 mt-1 pb-1 pt-1 sub-cat pointer text-bold"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Total
                      </div>
                    </div>
                    {Object.values(totalForecastingAmountByMonth).map(
                      (total, k) => (
                        <div
                          key={k}
                          className={`ps-1 col-md mt-1 pb-1 pt-1  d-flex justify-content-end   ${
                            k % 2 === 0 ? "teven" : "teven"
                          }`}
                          style={{ fontWeight: "bold" }}
                        >
                          {total.toFixed(2)}
                        </div>
                      )
                    )}
                  </div>
                </Card>
              );
            })} */}
              </div>
              {isProcess && (
                <div className="login-spin d-flex justify-content-center">
                  {" "}
                  <ProgressSpinner
                    style={{
                      width: "40px",
                      height: "50px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    strokeWidth="1"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />{" "}
                </div>
              )}
            </TabPanel>
          </TabView>
        </div>
      </BlockUI>

      <Sidebar
        visible={userDrawerCategory}
        position="right"
        className="security-drawer"
        onHide={handleCloseCategory}
      >
        <div slot="header">{renderHeaderCategory()}</div>
        <div className=" px-2  mt-1">
          <div className="row-sm">
            <label htmlFor="category" className="form-label required">
              Category
            </label>
            <input
              type="text"
              className="form-control"
              {...register1("category", { required: true })}
            />
            {errors1.category && (
              <p className="text-danger">This field is required</p>
            )}
          </div>
          <div className="row-sm mt-3">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              {...register1("description")}
            />
            {/* {errors.description && <p className="text-danger">Description is required.</p>} */}
          </div>
        </div>
        <div className="text-end mt-3">
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={handleCloseCategory}
            className="p-button-text btn-cancel ms-auto btn btn-danger me-1"
          />
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            // autoFocus
            className="btn btn-primary"
            onClick={handleSubmit1(onSubmitCategory)}
          />
        </div>
      </Sidebar>
      {/* Add Sub Category */}
      <Sidebar
        visible={userSubDrawer}
        position="right"
        className="security-drawer"
        onHide={handleCloseSubCategory}
      >
        <div slot="header">{renderHeaderSubCategory()}</div>
        <div className=" px-2 py-3  mt-4">
          <h5>{getCatgryName}</h5>
          <div className="row-sm">
            <label htmlFor="subcategory" className="form-label required">
              Expense Type
            </label>
            <input
              type="text"
              className="form-control"
              {...register2("subcategory", { required: true })}
            />
            {errors2.subcategory && (
              <p className="text-danger">This field is required</p>
            )}
          </div>
          <div className="row-sm mt-3">
            <label htmlFor="subdescription" className="form-label ">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              {...register2("subdescription")}
            />
            {/* {errors.subdescription && <p className="text-danger">Description is required.</p>} */}
          </div>
        </div>
        <div className="text-end mt-3">
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={handleCloseSubCategory}
            className="p-button-text btn-cancel ms-auto btn btn-danger me-1"
          />
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            // autoFocus
            className="btn btn-primary"
            onClick={handleSubmit2(onSubmitSubCategory)}
          />
        </div>
      </Sidebar>

      {/*Sub Category Details*/}
      <Sidebar
        visible={userDrawer}
        className="w-full md:w-10rem lg:w-20rem"
        position="right"
        onHide={handleClose1}
      >
        <div slot="header">{renderHeader()}</div>
        <div className=" px-2 py-3  mt-4">
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-end">Expense</label>
            <div className="col-sm-7 bg-white">
              <input
                type="text"
                className="form-control tborder"
                disabled
                value={monthsInBudgetCategory?.budgetSubCategory}
                onChange={(e) => setTest({ ...test, subcat: e.target.value })}
              />
            </div>
          </div>

          {monthsInBudgetCategory?.month?.map((item, index) => (
            <div className="form-group row mt-2" key={index}>
              <label
                htmlFor={item?.monthName}
                className="col-sm-3 col-form-label text-end"
              >
                {item?.monthName}
              </label>
              <div className="col-sm-7">
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  onKeyDown={handleKeyPress}
                  disabled={
                    item?.year < new Date().getFullYear() ||
                    (item.month < new Date().getMonth() + 1 &&
                      item?.year == new Date().getFullYear())
                  }
                  defaultValue={item?.budgetInfo?.forecastingAmount}
                  onInput={(e) => inputFieldValidate(e, index, item)}
                  {...register(item?.monthName)}
                />
                {/* {errorMessage && <p className="text-danger">{errorMessage}</p>} */}
                {formErrors[item.monthName] && (
                  <span style={{ color: "red" }}>
                    {formErrors[item.monthName]}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="text-end mt-3">
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={handleClose}
            className="p-button-text btn-cancel ms-auto btn btn-danger me-1"
          />
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            // autoFocus
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit1)}
          />
        </div>
      </Sidebar>
    </div>
  );
}
