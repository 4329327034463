import config from '../assets/config';
import { ax } from './base';

export default class JournalService {
    SaveJournalEntry(payload){
        return ax.post(`${config.apiUrl}JournalEntry/SaveJournalEntry`,payload)
    }
    GetMasCOA(ID,TenantID,Status,Type){
        return ax.get(`${config.apiUrl}COA/GetMasCOA/${ID}/${TenantID}/${Status}/${Type} `);
    }

    GetContact(payload){
        return ax.post(`${config.apiUrl}Contact/GetContact`,payload)
    }
    GetJournalList(payload){
        return ax.post(`${config.apiUrl}JournalEntry/GetJournalSearchData`,payload);
    }
    GetJournalNumber(TenantID){
        return ax.get(`${config.apiUrl}JournalEntry/GetJournalNumber/${TenantID} `);
    }
    GetJournalData(payload){
        return ax.post(`${config.apiUrl}JournalEntry/GetJournalEntry`,payload);
    }
    DeleteJournalTransaction(payload){
        return ax.post(`${config.apiUrl}JournalEntry/DeleteTransaction`,payload);
    }
    // https://dev-api.kokadamba.com/api/JournalEntry/DeleteTransaction
   
    
}
