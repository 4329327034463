import React, { useImperativeHandle,forwardRef, useState, useEffect, useRef } from 'react'
import { Button, Modal,DatePicker,Select,Space  } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import GV from '../../services/globalVar';
import dayjs from 'dayjs';
import JournalService from '../../services/journal';
import InvoiceService from '../../services/invoice-service'
import {  Dropdown, Toast, ToggleButton } from '../PrimeReact';
import { ProgressSpinner } from "primereact/progressspinner";

// import { Toast } from "primereact/toast";

const schema = yup.object().shape({
  cusName:yup.string().required('This field is required'),
  paymentID:yup.string().required('This field is required'),
  amount:yup.string().required('This field is required'),
  payDate:yup.string().required('This field is required'),
  depositTo:yup.string().required('This field is required'),
  paymode:yup.string().required('This field is required'),
  notes:yup.string()
});

const InvoicePayment = forwardRef((props, ref) => {
    const ins = new InvoiceService;
    const jor = new JournalService;
    const toast = useRef(null); 
  const defaultvalue = {
    cusName:'',
    paymentID:'',
    amount:'',
    payDate:'',
    depositTo:'',
    paymode:'',
    notes:''
  }
  const [displaynewCust, setDisplaynewCust] = useState(false);
  const {register,handleSubmit,watch,setValue,getValues,formState: { errors },reset,control}=useForm({
    mode:'all',defaultvalue,resolver:yupResolver(schema),
  } )

  console.log(errors,'errors');
  
  let invoice = props.data.payment;
  const dateFormat = GV.userDet.tenantConfig.dateFormat === 'MM/dd/yyyy'? 'MM/DD/YYYY' : 'DD/MM/YYYY' ;
  const [accountList,setAccountList]=useState([])
  const [accountData, setAccountData] = useState([{ label: "", value: "", id: "" }]);
  const [subCategory, setSubCategory] = useState([]);
  const [exceedAmtErr, setExceedAmtErr] = useState('');
  const [isProcess, setIsProcess] = useState(false);

  const payModes = [
    {label:'Cash',value:'Cash'},
    {label:'Bank Transfer',value:'Bank Transfer'},
    {label:'Cheque',value:'Cheque'},
    {label:'Credit Card',value:'Credit Card'}
  ]
const dialogFuncMap = {
    'displaynewCust': setDisplaynewCust,
  };

  const onClick = (name, data) => {
    reset();
    let check = Object.keys(invoice).includes("vendorID");
    if(check){
      const datas = {
        "TenantID" : GV.userDet.tenantDetail.tenantID,
        "ServiceName" : "PurchasePayment"
    }
    ins.GeneratePurchseOrderConfigNumber(datas).then((res) => {
      console.log(res.data.data)
      setValue('paymentID',res.data.data);
      setValue('cusName',data.companyName)
      setValue('amount',data.balancePayment?.toFixed(2))
      setValue('payDate',dayjs())
      dialogFuncMap[`${name}`](true);
    })
    } else {
      const pay = {
        TenantID: GV.userDet.tenantDetail.tenantID,
        ServiceName: "InvoicePayment",
      };
      ins.GenerateConfigNumber(pay).then((res) => {
        setValue('paymentID',res.data.data);
        setValue('cusName',data.companyName)
        setValue('amount',data.balancePayment?.toFixed(2))
        setValue('payDate',dayjs())
        dialogFuncMap[`${name}`](true);
      });
    }
  
  };
   const handleOk = () => {
    handleSubmit((onSubmit))
    console.log('we')
    // dialogFuncMap[`${"displaynewCust"}`](false);

   }

   const handleCancel = () =>{
    setExceedAmtErr('')
    dialogFuncMap[`${"displaynewCust"}`](false);
   }

   const onSubmit = (data) => {
    const check = Object.keys(invoice).includes("vendorID");
    const debit = Object.keys(invoice).includes("debitNoteID");
    setIsProcess(true);
    
    const coa = subCategory.find(x => x.coaName === data.depositTo);
    const baseData = {
      CompanyName: invoice.companyName,
      FirstName: invoice.firstName,
      LastName: invoice.lastName,
      PaymentCode: data.paymentID,
      PaymentDate: new Date(data.payDate),
      PaymentMode: data.paymode,
      Reference: "",
      Amount: data.amount,
      Notes: data.notes,
      COAID: coa.coaMasID,
      COACode: coa.code,
      COAName: coa.coaName,
      TenantID: GV.userDet.appUser.tenantID,
      CreatedBy: GV.userDet.appUser.appuserID,
    };
  
    const dataValue = check
    ? {
        ...baseData, VendorID: invoice.vendorID,
        ...(debit 
          ? { debitNoteID: invoice.debitNoteID } 
          : { PurchaseID: invoice.purchaseID }
        ),
      }
    : {
        ...baseData,
        InvoicePaymentID: 0,
        CustomerID: invoice.customerID,
        SalesInvoiceID: invoice?.creditNoteID ? 0 : invoice.salesInvoiceID,
        CreditNoteID: invoice.creditNoteID || 0,
      };
  
    const handleResponse = (res) => {
      setIsProcess(false);
      if (res.data.message === 'Successfully Paid') {
        dialogFuncMap["displaynewCust"](false);
        props.parentFunction();
        toast.current.show({
          severity: "success",
          summary: res.data.message,
        });
      } else if (res.data.message === 'Payment amount exceeds the required amount') {
        setExceedAmtErr(`Payment amount exceeds the required amount ${GV.userDet.tenantConfig.currency + invoice.balancePayment}`);
      }
    };
  
    const saveFunction = check ? ins.SavePurchasePayment : ins.SaveSalesInvoicePayment;
    saveFunction(dataValue).then(handleResponse);
  };
  
  

  const changeVal = (val) =>{
    console.log(val)
  }

  const handleAmountCgange = () => [
    setExceedAmtErr('')
  ]

   const initMethods = () =>{
        jor.GetMasCOA(0, GV.userDet.tenantDetail.tenantID, "Active", "All")
            .then((response) => {
                    let b = [];
                    if (response.data) {
                        if (response.data.statusCode === 200) {
                            // const data = response.data.data;
                            // let accounts = []
                            // data.forEach(x=>{
                            //     x.category.forEach(y=>{
                            //         accounts = [...accounts,y]
                            //     })
                            //     x.subCategory.forEach(z=>{
                            //         accounts =[...accounts,z]
                            //     })
                            // })
                            // console.log(accounts,"AAA")
                            // setAccountList(accounts);
                            setAccountData(response.data.data);
                            response.data.data.map((acc) => {
                                acc.category.map((sub) => {
                                    return b.push(sub);
                                })
                                acc.subCategory.map((sub) => {
                                    return b.push(sub);
                                })
                            });
                            setSubCategory(b);
                        } else {
                            
                        }
                    }
                // }
            }).catch(function (error) {

            })
    }
    const accountOptions = accountData.map((acc) => {
      return (
          {
              label: acc.type,
              code: acc.type,

              items: subCategory.filter(sub => sub.coaType == acc.type).map(s => {
                  return (
                      {
                          label: s.coaName,
                          value: s.coaName,
                          id: s.coaMasID,
                          category:s.coaCategory,
                          type:s.coaType
                      }
                  )
              })
          })

  });
  const groupedItemTemplate = (option) => {
    return (
        <div >
            <div className=''>{option.label}</div>
        </div>
    );
}

useImperativeHandle(ref, () => ({
    alertToggle(val) {
      onClick("displaynewCust",val);
    },
   
  }));
  const modalTitle = invoice.invoiceNumber ? `Payment for ${invoice.invoiceNumber}` : `Payment for ${invoice.invoiceNo}`;
   
  useEffect(() => {
    initMethods()
    }, [register]);
  return (
    <div>
       <Toast ref={toast}></Toast>
       {/* onOk={handleOk} onCancel={handleCancel} */}
       <Modal title={modalTitle} open={displaynewCust}  onCancel={handleCancel}
        footer={() => (
          <>
            <Button type="button" className='btn btn-danger kk-btnThin' onClick={handleCancel} >Cancel</Button>
            <Button type="submit" className='btn btn-primary kk-btnThin' onClick={handleSubmit((onSubmit))} autoFocus>Pay</Button>
           
          </>
        )}
       width={800}>
        <form >
            <div className='row'>
              <div className='col-md-6'>
                <label className='form-label mb-1 p-0 required' htmlFor="name">Bill To </label>
                <input type="text" className="form-control px-1 py-2" disabled
                  {...register('cusName')} />
                 {errors.cusName && <p className="text-danger mb-0 ps-1">{errors.cusName?.message}</p>}
               
              </div>
              <div className='col-md-4'>
                <label className='form-label mb-1 p-0 required' htmlFor="name">Payment ID </label>
                <input type="text" className="form-control px-1 py-2" disabled
                  {...register('paymentID')} />
                 {errors.paymentID && <p className="text-danger mb-0 ps-1">{errors.paymentID?.message}</p>}
               
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <label className='form-label mb-1 p-0 required' htmlFor="name">Amount </label>
                  <input type="number" className="form-control px-1 py-2" disabled = {Object.keys(invoice).includes("creditNotePayment")}
                  onInput={handleAmountCgange}
                    {...register('amount')} />
                    <p className='text-danger'>{exceedAmtErr}</p>
                  {errors.amount && <p className="text-danger mb-0 ps-1">{errors.amount?.message}</p>}
                
              </div>
              <div className='col-md-6'>
                <label className='form-label mb-1 p-0 required' htmlFor="name">Payment Date </label>
                
                <DatePicker  className='form-control ' name='payDate' defaultValue={getValues('payDate')} onChange={changeVal}  format={dateFormat}  {...register('payDate')}   />
              
                
                  {errors.payDate && <p className="text-danger mb-0 ps-1">{errors.payDate?.message}</p>}
                
              </div>
              {isProcess && (
                      <ProgressSpinner
                        style={{
                          width: "50px",
                          height: "50px",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        strokeWidth="2"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                      />
                    )}
              <div className='col-md-6'>
                <label className='form-label mb-1 p-0 required' htmlFor="name">Payment Mode </label>
                  {/* <input type="text" className="form-control px-1 py-2" 
                    {...register('paymode')} /> */}
                      <Controller
                         render={({ field }) => (
                            <Dropdown options={payModes}  
                              {...field} appendTo="self"  optionLabel="label" optionValue="value" 
                               {...register('paymode')}  className='form-control'/>
                              )}
                              control={control}
                              name="paymode"
                      />
                  {errors.paymode && <p className="text-danger mb-0 ps-1">{errors.paymode?.message}</p>}
                
              </div>
              <div className='col-md-6'>
                <label className='form-label mb-1 p-0 required' htmlFor="name">Deposit To </label>
                
                     <Controller
                         render={({ field }) => (
                            <Dropdown
                            className='form-control' options={accountOptions} placeholder='' optionLabel="label" filter filterBy="label" optionGroupLabel="label"
                                                        optionGroupChildren="items" optionGroupTemplate={groupedItemTemplate} {...field} 
                                                        />    
                            // options={accountList}   filter 
                            //   {...field} appendTo="self"  optionLabel="coaName" optionValue="coaName" 
                            //    {...register('depositTo')}  className='form-control'
                              )}
                              control={control}
                              name="depositTo"
                      />
                      {/* <Select
                        className='cc-form-control'
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search to Select"
                        optionFilterProp="label"
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={accountList}
                        optionRender={(option) => (
                          <Space>
                            <span >
                              {option.data.coaName}
                            </span>
                            
                          </Space>
                        )}
                       
                      /> */}
                  {errors.depositTo && <p className="text-danger mb-0 ps-1">{errors.depositTo?.message}</p>}
                
              </div>
              <div className='col-md-8'>
                <label className='form-label mb-1 p-0 ' htmlFor="name">Notes </label>
                  
                    <textarea className='form-control'   {...register('notes')} />
                  {errors.notes && <p className="text-danger mb-0 ps-1">{errors.notes?.message}</p>}
                
              </div>
            </div>
        </form>

      </Modal>
    </div>
  )
})

export default InvoicePayment