import config from '../assets/config';
import { ax } from './base';

export default class purchaseService {
    GetPurchaseCode(tenantId){
        return ax.get(`${config.apiUrl}Purchase/GetPurchaseCode/${tenantId}`);
    }

    GetMasCOACategories(payload){
        return ax.post(`${config.apiUrl}COA/GetMasCOACategories`,payload);
    }
    GetContactFilteredList(payload){
        return ax.post(`${config.apiUrl}Contact/GetContactFilteredList`,payload);
    }
    SavePurchases(payload){
        return ax.post(`${config.apiUrl}Purchase/SavePurchases`,payload);
    }
    GetPurchaseListSearch(payload){
        return ax.post(`${config.apiUrl}Purchase/GetPurchaseListSearch`,payload);
    }
    GenerateExpConfigNumber(payload){
        return ax.post(`${config.apiUrl}Common/GenerateConfigNumber`,payload);
    }

    DeletePurchaseOrder(TenantID,PurchaseExpID,Status){
        return ax.get(`${config.apiUrl}Purchase/ArchivePurchaseorder/${TenantID}/${PurchaseExpID}/${Status}`);
    }
    GetPurchaseExpList(TenantID,PurchaseCount){
        return ax.get(`${config.apiUrl}purchase/GetPurchaseList/${TenantID}/${PurchaseCount}`);
    }
    ArchivePurchase(payload){
        return ax.post(`${config.apiUrl}Purchase/ArchivePurchase`,payload);
    }
    GetPurchaseDocs(payload){
        return ax.post(`${config.apiUrl}Purchase/GetPurchaseDocs`,payload);
    }
    
    //credit note
    GenerateConfigNumber(payload){
        return ax.post(`${config.apiUrl}Common/GenerateConfigNumber`,payload);
    }
    
    // newpurchase order grid
    
    GetPurchaseOrderListSearch(payload){
        return ax.post(`${config.apiUrl}Purchase/GetPurchaseOrderListSearch`,payload);
    }

    SavePurchaseOrder(payload){
        return ax.post(`${config.apiUrl}Purchase/SavePurchaseOrder`,payload);
    }
    UpdateStatusPurchaseOrder(payload){
        return ax.post(`${config.apiUrl}Purchase/UpdateStatusPurchaseOrder`,payload);
    }
    DownloadDocs(payload){
        return ax.post(`${config.apiUrl}Common/DownloadDocs`,payload);
    }
    GeneratePurchaseOrderConfigNumber(payload){
        return ax.post(`${config.apiUrl}Common/GenerateConfigNumber`,payload);
    }

    // debit note
    SavePurchaseDebitNote(payload){
        return ax.post(`${config.apiUrl}purchase/SavePurchaseDebitNote`,payload);
    }
    GetPurchaseReturnListSearch(payload){
        return ax.post(`${config.apiUrl}purchase/GetPurchaseReturnListSearch`,payload);
    }
    GenerateDebitNoteConfigNumber(payload){
        return ax.post(`${config.apiUrl}Common/GenerateConfigNumber`,payload);
    }
    GetPurchaseByCode(payload){
        return ax.post(`${config.apiUrl}purchase/GetPurchaseByCode`,payload);
    }

} 