import {
  DataTable,
  Column,
  Dropdown,
  InputText,
  MultiSelect,
  iconSet,
  IcomoonReact,
} from "../PrimeReact.js";
import React, { useLayoutEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import AccountantService from "../../services/accountant";
import ChartOfAccount from "./ChartOfAccount";
import LocalStorageService from "../../services/localstorage.js";
import GV from "../../services/globalVar";
import LoginService from "../../services/login.js";
import { Tooltip, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Table, Tag, Menu, Flex } from "antd";

const COATable = () => {
  const modalref = useRef(null);
  const acs = new AccountantService();
  const lss = new LocalStorageService();
  const lgs = new LoginService();
  const [first1, setFirst1] = useState(0);
  const [tdataLoad, setTdataLoad] = useState(true);
  const [requestData, setRequestData] = useState([]);
  const [data, setData] = useState([]);
  const [totalRecordsIn, setTotalRecordsIn] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [isMounted, setIsMounted] = useState(true);
  const [accountSelected, setAccountSelected] = useState("");
  const [acntTypeList, setacntTypeList] = useState();
  const [category, setCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [categorys, setCategorys] = useState("");
  const [subCategorys, setSubCategorys] = useState("");
  const [subCategory, setSubCategory] = useState();
  const [selectedAcctType, setSelectedAcctType] = useState([]);

  //coastatus start
  const coaStatus = [
    { label: "All Accounts", value: "All", status: true },
    { label: "Active Accounts", value: "Active", status: true },
    { label: "Inactive Accounts", value: "InActive", status: true },
    { label: "Asset Accounts", value: "Assets", status: false },
    { label: "Liability Accounts", value: "Liability", status: false },
    { label: "Expenses Accounts", value: "Expense", status: false },
    { label: "Income Accounts", value: "Income", status: false },
    { label: "Equity Accounts", value: "Equity", status: false },
  ];
  const [statusSelected, setStatusSelected] = useState("");
  const [filterSelected, setFilterSelected] = useState("");

  const editProduct = (product) => {
    modalref.current.alertToggledit(product);
    // setProduct({...product});
    // setProductDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.isEditable ? (
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon="edit"
            style={{ fill: `${rowData.isEditable ? "black" : "#c9c9c9"}` }}
            className="pointer"
            onClick={() => editProduct(rowData)}
          />
        ) : (
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon="edit"
            style={{ fill: `${rowData.isEditable ? "#444" : "#c9c9c9"}` }}
            className="pointer"
          />
        )}
      </React.Fragment>
    );
  };

  const getCategories = () => {
    acs.COATypes(GV.userDet.tenantDetail.tenantID).then((res) => {
      const val = res.data.data;
      console.log(val);
      setacntTypeList(val);
      //   setValue("accntType", val[0]);
    });
  };

  const changeType = (e) => {
    console.log(e);
    let cat = e.type;
    setPage(1);
    
    setAccountSelected(cat);
    setSelectedAcctType(e);
    setCategory(e.categories);
    setSubCategory();
    
    fetchCoaList(
      1,  
      sizePerPage,
      searchVal,
      "",
      "",
      statusSelected,
      cat,
      "",
      ""
    );
};

  const changeCategory = (e) => {
    let catName = e.categoryName;
    setSelectedCategory(e);
    setSubCategory(e.subCategoryName);
    fetchCoaList(page,sizePerPage,searchVal,"","",statusSelected,accountSelected,catName,"");
  };

  const changeSubCategory = (e) => {};
  const panelHeaderTemplate = () => {
    return (
      <div className="pt-2 ps-4">
        {/* <p className='mb-0 fs-6'>Select</p> */}
      </div>
    );
  };

  const basiccolumns = [
    // { field: 'coaName', header: 'Account Name', sortable: false, body: '' },
    // { field: 'code', header: 'Account Code', sortable: false, body: '' },
    // { field: 'coaType', header: 'Account Type', sortable: false, body: '' },
  ];
  const columns = [
    // { field: 'coaName', header: 'Account Name', sortable: false, body: '' },
    // { field: 'code', header: 'Account Code', sortable: false, body: '' },
    // { field: 'coaType', header: 'Account Type', sortable: false, body: '' },
    // {
    //   field: "coaCategory",
    //   header: "Parent Account Name",
    //   sortable: false,
    //   body: "",
    // },
    // { field: 'status', header: 'Status', sortable: false, body: '' },
    { field: "description", header: "Description", sortable: false, body: "" },
  ];

  const [selectedColumns, setSelectedColumns] = useState(basiccolumns);

  const [disabled1, setDisabled1] = useState(true);
  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    // if(columns ===true && columns === false){
    //     setSelectedColumns(orderedSelectedColumns);
    // }
    setSelectedColumns(orderedSelectedColumns);
    // setDisabled1(true);
  };
  //   const accountChange = (e) => {
  //     setFilterSelected(e.value);
  //     let obj = coaStatus.find((c) => c.value === e.value);
  //     if (obj.status === true) {
  //       setStatusSelected(e.value);
  //       setAccountSelected("All");
  //       fetchCoaList(page, sizePerPage, searchVal, "", "", e.value, "All","","");
  //     } else {
  //       setStatusSelected("Active");
  //       setAccountSelected(e.value);
  //       fetchCoaList(page, sizePerPage, searchVal, "", "", "Active", e.value,"","");
  //     }
  //   };

  const keySearch = (e) => {
    setSearchVal(e.target.value);
    if (e.key === "Enter") {
      const searched = e.target.value;

      fetchCoaList(
        1,
        sizePerPage,
        searched,
        sortName,
        sortOrder,
        statusSelected,
        accountSelected,
        "",
        ""
      );
    }
    if (e.type === "keyup") {
      if (e.target.value === "") {
        fetchCoaList(
          page,
          sizePerPage,
          "",
          sortName,
          sortOrder,
          statusSelected,
          accountSelected,
          "",
          ""
        );
      }
    }
  };
  const emptySearch = () => {
    setSearchVal("");
    fetchCoaList(
      page,
      sizePerPage,
      "",
      sortName,
      sortOrder,
      statusSelected,
      accountSelected,
      "",
      ""
    );
  };

  //   const header = (
  //     <div className="d-flex justify-content-between flex-wrap">
  //       <div className="text-t-primary  kk-table-header kk-header-sec row">
  //         <Dropdown
  //           className="col-auto text-start me-3"
  //           value={filterSelected}
  //           options={coaStatus}
  //           onChange={(e) => accountChange(e)}
  //           scrollHeight="400px"
  //           placeholder="Select account"
  //         />
  //         <div className="col  border rounded my-auto">
  //           <AiOutlineSearch />
  //           <input
  //             className=" py-1 ps-3 search"
  //             value={searchVal}
  //             onChange={(e) => keySearch(e)}
  //             onKeyUp={(e) => keySearch(e)}
  //             placeholder="Search"
  //           />
  //           {searchVal !== "" ? (
  //             <i className="pi pi-times" onClick={emptySearch} />
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //       </div>
  //       <div className="my-auto">
  //         {/* <FiPlus className='me-1' /> */}
  //         <button
  //           className="btn btn-primary py-1 me-2 "
  //           onClick={() => {
  //             modalref.current.alertToggle();
  //           }}
  //         >
  //           {" "}
  //           <i className="pi pi-plus"></i> New Account
  //         </button>

  //         <MultiSelect
  //           value={selectedColumns}
  //           options={columns}
  //           optionLabel="header"
  //           optionDisabled="disabled"
  //           onChange={onColumnToggle}
  //           style={{ width: "3em" }}
  //           scrollHeight="400px"
  //           dropdownIcon="pi pi-table"
  //           tooltip="Custom Columns"
  //           tooltipOptions={{ position: "top" }}
  //           panelHeaderTemplate={panelHeaderTemplate}
  //         />
  //       </div>
  //     </div>
  //   );

  const dynamicColumns = selectedColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={col.sortable}
        body={col.body && actionBodyTemplate}
      ></Column>
    );
  });

  const dynamicColumnsOne = selectedColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={col.sortable}
        body={col.body}
      ></Column>
    );
  });

  const onCustomPage1 = (event) => {
    setFirst1(event.first);
    const val = event.page + 1;
    const size = event.rows;
    setSizePerPage(size);
    setPage(val);
    fetchCoaList(
      val,
      size,
      searchVal,
      "",
      "",
      statusSelected,
      accountSelected,
      "",
      ""
    );
  };
  //pagination end
  //sort start
  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });

  const onSort = (event) => {
    setLazyParams(event);
  };
  //sort end
  //apicall
  function fetchCoaList(
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder,
    status,
    account,
    categorys,
    subCategorys
  ) {
    // setTdataLoad(true);
    setTdataLoad(true);
    console.log(page, "PAGE");
    let data = {
      InputParams: {
        ID: 0,
        TenantID: GV.userDet.tenantDetail.tenantID,
        Status: status,
        Type: account,
        Category: categorys,
        SubCategory: subCategorys,
      },
      ServerSearchables: {
        draw: page,
        searchData: searchVal,
        orderByColumn: sortName,
        orderBy: sortOrder,
        currentPage: page,
        pageSize: sizePerPage,
        pageNumber: page,
      },
    };
    acs
      .GetCOASearchData(data)
      .then((response) => {
        // if (isMounted) {
        if (response.data) {
          const grid = response.data.data.coaLists;
          setTotalRecordsIn(response.data.data.serverSearchables.recordsTotal);
          setData(grid);
          setRequestData(response.data.data.coaLists);
          //   setData(requestData.slice(0,sizePerPage));
          setTdataLoad(false);
        }
        // }
      })
      .catch(function (error) {
        setTdataLoad(false);
        if (error.response.status == "401") {
          lgs.RefreshToken().then((res) => {
            localStorage.setItem("token", res.data.data.accessToken);
            // window.location.reload();
          });
        }
      });
  }
  const parentFunction = () => {
    fetchCoaList(
      page,
      sizePerPage,
      searchVal,
      "",
      "",
      statusSelected,
      accountSelected,
      "",
      ""
    );
    setSelectedAcctType("");
    setSelectedCategory("");
  };

  const renderStatusColumn = (rowData) => {
    let status = rowData.status.toUpperCase();
    let statusClass;
    switch (status) {
      case "ACTIVE":
        statusClass = "rgb(186 231 196)";
        break;
      case "INACTIVE":
        statusClass = "rgb(180 153 153)";
        break;
      default:
        statusClass = "";
    }
    return (
      <Tag bordered={false} color={statusClass}>
        {status}
      </Tag>
    );
  };
  useLayoutEffect(() => {
    getCategories();
    fetchCoaList(
      page,
      sizePerPage,
      searchVal,
      "",
      "",
      statusSelected,
      accountSelected,
      categorys,
      subCategorys
    );
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div>
        {/* <div
                className="d-flex justify-content-between p-2 mb-2 text-t-primary  kk-table-header kk-header-sec mb-0"
                style={{ backgroundColor: "white" }}
              >
              <h4 className='kk-bold'>Chart of Accounts</h4>
                
              </div> */}
      </div>
      <div className="card card-borderless cc-greyCard-custm mb-3">
        <div className="ms-2 my-2 me-2">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h4 className="m-0">Chart of Accounts</h4>
            <div className="d-flex justify-content-end text-t-primary kk-table-header kk-header-sec  cc-font-size-14">
              <div className="col me-4 pe-1">
                    
                  <Dropdown
                    options={acntTypeList}
                    appendTo="self"
                    optionLabel="type"
                    value={selectedAcctType}
                    placeholder="Select account type"
                    onChange={(e) => {
                      changeType(e.value);
                    }}
                  />
                  <Dropdown
                    options={category}
                    appendTo="self"
                    value={selectedCategory}
                    optionLabel="categoryName"
                    placeholder="Select Category"
                    onChange={(e) => {
                      changeCategory(e.value);
                    }}
                  />
                  <Space.Compact className="my-auto">
                <Input
                  addonBefore={<SearchOutlined />}
                  allowClear
                  placeholder="Search"
                  value={searchVal}
                  onChange={(e) => keySearch(e)}
                  onKeyUp={(e) => keySearch(e)}
                />
              </Space.Compact>

              <button
                className="btn btn-primary py-1 me-2 ms-2 kk-btnThin my-2 "
                onClick={() => {
                  modalref.current.alertToggle();
                }}
              >
                {" "}
                <i className="pi pi-plus"></i> New Account
              </button>

              <MultiSelect
                value={selectedColumns}
                className="kk-btnThin my-auto"
                options={columns}
                optionLabel="header"
                optionDisabled="disabled"
                onChange={onColumnToggle}
                style={{ width: "3em" }}
                scrollHeight="400px"
                dropdownIcon="pi pi-table"
                tooltip="Custom Columns"
                tooltipOptions={{ position: "top" }}
                panelHeaderTemplate={panelHeaderTemplate}
              />
              </div>

              {/* <div className="col mx-4 pe-1">
                <div className="row me-0">
                  <Dropdown
                    options={subCategory}
                    appendTo="self"
                    // optionValue="value"
                    // optionLabel="label"
                    placeholder="Select SubCategory"
                    onChange={(e) => changeSubCategory(e.value)}
                  />
                </div>
              </div> */}
              {/* <Dropdown
                className="col-auto text-start me-3"
                value={filterSelected}
                options={coaStatus}
                onChange={(e) => accountChange(e)}
                scrollHeight="400px"
                placeholder="Select account"
              /> */}

              

              
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white shadow-sm chart-of-account h-100 p-4">
        <div className="row coa-header kk-header-sec">
          <div className="col-auto header-right ms-auto"></div>
        </div>
        <div className="account-table ">
          <DataTable
            className="chofacc"
            stripedRows
            value={data}
            //  header={header}
            // responsiveLayout="scroll"
            size="small"
            loading={tdataLoad}
            paginator
            lazy
            totalRecords={totalRecordsIn}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rowsPerPageOptions={[10, 20, 50, totalRecordsIn]}
            first={first1}
            rows={sizePerPage}
            onPage={onCustomPage1}
            onSort={onSort}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
          >
            <Column field="coaName" header="Account Name" />
            <Column field="code" header="Account Code" />
            <Column field="coaType" header="Account Type" />
            <Column field="coaCategory" header="Category Name" />
            <Column field="renderStatusColumn" header="Status"  
                body={(e) => renderStatusColumn(e)}
                />
            {dynamicColumns}
            {}
            <Column
              field="actionBodyTemplate"
              header="Action"
              body={actionBodyTemplate}
            />
          </DataTable>
        </div>
      </div>
      <ChartOfAccount ref={modalref} parentFunction={parentFunction} />
    </div>
  );
};

export default COATable;
