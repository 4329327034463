// import config from '../config';
import config from '../assets/config';
import { ax } from './base';

export default class ItemsService {
   
    GetItemsSearchData(payload){
        return ax.post(`${config.apiUrl}Items/GetItemsSearchData`,payload)
    }
    // SaveMasCOA(payload){
    //     return ax.post(`${config.apiUrl}COA/SaveMasCOA`,payload)
    // }
    
    SaveItems(payload){
        return ax.post(`${config.apiUrl}Items/SaveItems`,payload)
    }

    GetItemsTenant(ID,TenantID,Status,Type){
        return ax.get(`${config.apiUrl}Items/GetItemsTenant/${ID}/${TenantID}/${Status}/${Type}`);
    }      
}
