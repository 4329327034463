import config from '../assets/config';
import { ax } from './base';

export default class LoanService {
    GetLoanDataSearch(payload){
        return ax.post(`${config.apiUrl}Loan/GetLoanDataSearch`,payload);
    }

    SaveLoanDetails(payload){
        return ax.post(`${config.apiUrl}Loan/SaveLoanDetails`,payload);
    }

    GenerateConfigNumber(payload){
        return ax.post(`${config.apiUrl}Common/GenerateConfigNumber`,payload);
    }

    SaveLoanPaymentDetails(payload){
        return ax.post(`${config.apiUrl}Loan/SaveLoanPaymentDetails`,payload);
    }
    
    GetLoanPaymentDetails(TenantID,LaonID,LoanPaymentID){
        return ax.get(`${config.apiUrl}Loan/GetLoanPaymentDetails/${TenantID}/${LaonID}/${LoanPaymentID}`);
    }

    GetLoanSumnmary(TenantID){
        return ax.get(`${config.apiUrl}Loan/GetLoanSumnmary/${TenantID}`);
    }
    
}