import React, { useState } from "react";
import  {Dropdown} from "../PrimeReact";
import { InputSwitch } from 'primereact/inputswitch';

function Configuration() {
    const itemStatus = [
        { label: 'blue', value: 'blue', light: '#c7d7ff',dark:'#032a89'},
        { label: 'red ', value: '#016aa9',  light: '#319bdbdb',dark:'#001c2d'},
        { label: 'Green ', value: '#3e9d71',  light: '#baedd5',dark:'#1e6444'},
        { label: 'Green ', value: '#efc52c',  light: '#ffedad',dark:'#c39900'},
        {label:'customblue', value:'#143444', light : '#b7e7ffbd', dark : '#2f6f8f'}

    ];
    const [checked,setChecked]= useState(false)
    const [filterSelected, setFilterSelected] = useState(itemStatus[0].value)
    const changeColor=(prime,lightPrime,darkPrime) =>{
        console.log(prime)
        document.body.style.setProperty('--kit-color-primary',prime)
        document.body.style.setProperty('--color-primary-darker',darkPrime)
        document.body.style.setProperty('--color-primary-lighter',lightPrime)
        
    }
   
  const changeBackground=(e)=>{
    console.log(e)
    if(e.value == true){
        setChecked(true )
        const bgPrime = '#0a0a0a'
        document.body.style.setProperty('--bgColor-primary',bgPrime)
        document.body.style.setProperty('--bgColor-primary-light','#1a1a1a')
        document.body.style.setProperty('--bgColor-primary-dark','#000000')
        document.body.style.setProperty('--kit-txtcolor-primary','#fff')
    }else{
         setChecked(false)
         const bgPrime = '#f7f8f9'
         document.body.style.setProperty('--bgColor-primary',bgPrime)
         document.body.style.setProperty('--bgColor-primary-light','#fff')
         document.body.style.setProperty('--bgColor-primary-dark','#e4e4e4')
        document.body.style.setProperty('--kit-txtcolor-primary','#000000')

    }

  }
  return <div>
      <div className="card height-400">
      {/* <Dropdown className='col-auto text-start me-3' value={filterSelected} options={itemStatus} onChange={(e) => changedIn(e)}
                    scrollHeight="400px"
                    placeholder="Select account" /> */}
                     <div className='row m-2'>
                    {
                       itemStatus.map((item, i) => {
                           return(
                               <div className='col-md-1'>
                                  
                                       {/* <div > */}
                                            <div className="circle" style={{backgroundColor:item.value,cursor: "pointer"}}  onClick={() => changeColor(item.value,item.light,item.dark)}  ></div> 
                                            {/* <span className="clrStyle" style={{ cursor: "pointer" }}   > {item.label}</span> */}
                                            {/* <span className="clrStyle" style={{ cursor: "pointer" }}   > </span> */}
                                        {/* </div> */}
                                       
                                    </div>
                                
                            )
                       })
                    }
                     <div className='mt-4'>
                         <InputSwitch  checked={checked} onChange={(e) => changeBackground(e)} />  <span className='ms-3' >Enable Black Theme </span>
                     </div>
                    </div>
     {/* <div className='circle'></div>  <p className='dropdown-tem clrStyle'  style={{ cursor: "pointer" }}  onClick={() => changeColor('red','#f96969','#8f0101')}>  Red</p> */}
      </div>
      

  </div>;
}

export default Configuration;
