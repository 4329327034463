import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState, useRef } from "react";
import BudgetService from "../../services/budget-service";
import GV from "../../services/globalVar";
import { format } from "date-fns";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

export default function FundApproval() {
  const bts = new BudgetService();
  const [reqApproval, setreqApproval] = useState([]);
  const [dropDownYear, setDropDownYear] = useState(new Date());
  const [dropDownMonth, setDropDownMonth] = useState(new Date().getMonth());
  const [isProcess, setIsProcess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);

  const [selectedMonth, setSelectedMonth] = useState(+format(new Date(), "MM"));
  const [months, getMonths] = useState([
    { label: "January", value: 1, key: 1 },
    { label: "Feburary", value: 2, key: 2 },
    { label: "March", value: 3, key: 3 },
    { label: "April", value: 4, key: 4 },
    { label: "May", value: 5, key: 5 },
    { label: "June", value: 6, key: 6 },
    { label: "July", value: 7, key: 7 },
    { label: "August", value: 8, key: 8 },
    { label: "September", value: 9, key: 9 },
    { label: "October", value: 10, key: 10 },
    { label: "November", value: 11, key: 11 },
    { label: "December", value: 12, key: 12 },
  ]);
  const onload = () => {
    setIsProcess(true);
    bts
      .GetFundRequests(
        GV.userDet.tenantDetail.tenantID,
        dropDownYear.getFullYear(),
        dropDownYear.getMonth() + 1
      )
      .then((res) => {
        setIsProcess(false);
        setIsLoading(false);
        const value = res.data.data.budgetCategories;
        value.forEach((x) => {
          if (x.fundRequest.length !== 0) {
            let forecast = 0;
            let reqAmt = 0;
            let difference = 0;
            x.fundRequest.forEach((y, i) => {
              forecast = forecast + y.forecastingAmount;
              reqAmt = reqAmt + y.requestingAmount;
              difference = difference + y.difference;
            });
            const val = {
              fundID: null,
              budgetCatID: x.budgetCatID,
              budgetCategory: x.budgetCategory,
              budgetSubCatID: 0,
              budgetSubCategory: "Total",
              forecastingAmount: forecast,
              availableBalance: 0.0,
              requestingAmount: reqAmt,
              difference: difference,
              requestDate: "",
              description: "",
              status: "",
              tenantID: "",
              createdOn: "",
              createdBy: "",
              modifiedOn: "",
              modifiedBy: null,
            };
            x.fundRequest.push(val);
          }
        });
        setreqApproval(value);
      });
  };
  const reject = (data) => {
    console.log(data);
    const val = {
      FundID: data.fundID,
      BudgetCatID: data.budgetCatID,
      BudgetCategory: data.budgetCategory,
      BudgetSubCatID: data.budgetSubCatID,
      BudgetSubCategory: data.budgetSubCategory,
      RequestingAmount: data.requestingAmount,
      RequestDate: format(new Date(data.requestDate), "yyyy-MM-dd"),
      FundReqYear: data.fundReqYear,
      FundReqMonth: data.fundReqMonth,
      Description: data.description,
      Status: "Rejected",
      TenantID: GV.userDet.tenantDetail.tenantID,
      CreatedBy: GV.userDet.appUser.appuserID.toString(),
    };
    // console.log(val)
    bts.SaveFundRequest(val).then((res) => {
      if (res.data.message === "Rejected Successfully") {
        toast.current.show({ severity: "error", summary: res.data.message });
      }

      console.log(res);
      onload();
    });
  };
  const accept = (data) => {
    const val = {
      FundID: data.fundID,
      BudgetCatID: data.budgetCatID,
      BudgetCategory: data.budgetCategory,
      BudgetSubCatID: data.budgetSubCatID,
      BudgetSubCategory: data.budgetSubCategory,
      RequestingAmount: data.requestingAmount,
      RequestDate: format(new Date(data.requestDate), "yyyy-MM-dd"),
      FundReqYear: data.fundReqYear,
      FundReqMonth: data.fundReqMonth,
      Description: data.description,
      Status: "Approved",
      TenantID: GV.userDet.tenantDetail.tenantID,
      CreatedBy: GV.userDet.appUser.appuserID.toString(),
    };
    // console.log(val)
    bts.SaveFundRequest(val).then((res) => {
      if (res.data.message === "Approved Successfully") {
        toast.current.show({ severity: "success", summary: res.data.message });
      }
      console.log(res);
      onload();
    });
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.status === "Request Sent" ? (
          <div>
            <span
              className="cc-text-danger me-4 cc-hover-line cc-edit cc-hover-bold"
              onClick={() => reject(rowData)}
            >
              Reject
            </span>
            <span
              className="cc-text-primary cc-hover-line cc-edit cc-hover-bold"
              onClick={() => accept(rowData)}
            >
              Approve
            </span>
          </div>
        ) : (
          <div>{rowData.status}</div>
        )}
      </React.Fragment>
    );
  };
  const forecastingAmountbody = (data) => {
    return GV.userDet.tenantConfig.currency+ " " +  data.forecastingAmount.toFixed(2);
  };
  const differencebody = (data) => {
    const isNegative = data.difference < 0;
    const positiveClass = "positive-value";
    const negativeClass = "negative-value";
    const valueClass = isNegative ? negativeClass : positiveClass;

    return <span className={valueClass}>{GV.userDet.tenantConfig.currency+ " " + data.difference.toFixed(2)}</span>;
    // return data.difference.toFixed(2);
  };
  const reqAmountbody = (data) => {
    return GV.userDet.tenantConfig.currency+ " " +  data.requestingAmount.toFixed(2);
  };
  const rowClass = (data) => {
    return {
      tablehighlight: data.budgetSubCategory === "Total",
    };
  };
  useEffect(() => {
    onload();
  }, [dropDownYear]);

  const selectDateAndMonth = (e) => {
    let date = new Date(e.value);
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    setDropDownMonth(month);
    setDropDownYear(year);
    DropdownYearMonthSelect(year, month);
    onload();
  };

  const DropdownYearMonthSelect = (dropDownYear) => {
    let year = dropDownYear.getFullYear();
    let month = dropDownYear.getMonth() + 1;
    bts
      .GetFundRequests(GV.userDet.tenantDetail.tenantID, year, month)
      .then((res) => {});
  };
  const onChangeCalendar = (event) => {
    setDropDownYear(event.value);
    console.log(event.value, "onChangeCalendar");
  };
  return (
    <div>
      <BlockUI blocked={isProcess}>
        <Toast ref={toast}></Toast>
        <div className="card cc-greyCard-custm card-borderless" >
          <div className="card-body p-2">
            <div className="d-flex justify-content-between flex-wrap">
              <h4>Fund Request Approval</h4>
              {/* <div>
                             <Dropdown value={selectedMonth} onChange={(e) =>{ setSelectedMonth(e.value)}} options={months} optionlabel="name" optionvalue="value" 
                            placeholder="Select a Month" className="w-full md:w-14rem form-control" /> 
                        </div> */}
              <div className="d-flex justify-content-between">
                <Calendar
                  value={dropDownYear}
                  onChange={(e) => onChangeCalendar(e)}
                  view="month"
                  dateFormat="mm/yy"
                />
              </div>
            </div>
          </div>
        </div>
        {isLoading && (
          <ProgressSpinner
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            strokeWidth="4"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
        <div className=" mt-2 fund cc-card">
          {reqApproval.map((x) => {
            return x.fundRequest.length != 0 ? (
              <div className="card mt-3">
                <p className="cc-title ms-2 mt-1 mb-3 cc-text-primary">
                  <b>{x.budgetCategory}</b>
                </p>
                <div className="ms-2 me-2 mb-2">
                  <DataTable
                    value={x.fundRequest}
                    showGridlines
                    rowClassName={rowClass}
                    emptyMessage="No data found"
                  >
                    <Column
                      field="budgetSubCategory"
                      header="Expenses"
                      style={{ width: "10%" }}
                    ></Column>
                    <Column
                      field="forecastingAmount"
                      header="Current Month Forecasting"
                      headerStyle={{ textAlign: "right" }}
                      body={forecastingAmountbody}
                      bodyStyle={{ textAlign: "right" }}
                      style={{ width: "10%" }}
                    ></Column>
                    <Column
                      field="requestingAmount"
                      header="Requesting Amount"
                      body={reqAmountbody}
                      bodyStyle={{ textAlign: "right" }}
                      style={{ width: "10%" }}
                    ></Column>
                    <Column
                      field="difference"
                      header="Difference"
                      body={differencebody}
                      bodyStyle={{ textAlign: "right" }}
                      style={{ width: "10%" }}
                    ></Column>
                    <Column
                      field="description"
                      header="Description"
                      style={{ width: "10%" }}
                    ></Column>
                    {/* <Column field="status" header="Status"></Column> */}
                    <Column
                      header="Status"
                      field="actionBodyTemplate "
                      body={actionBodyTemplate}
                      style={{ width: "5%" }}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            ) : (
              <div className="card mt-3">
                <p className="cc-title ms-2 mt-1 mb-3 cc-text-primary">
                  <b>{x.budgetCategory}</b>
                </p>
                <div className="ms-2 me-2 mb-2">
                  <DataTable
                    value={x.fundRequest}
                    showGridlines
                    rowClassName={rowClass}
                    emptyMessage="No data found"
                  >
                     <Column
                      field="budgetSubCategory"
                      header="Expenses"
                      style={{ width: "10%" }}
                    ></Column>
                    <Column
                      field="forecastingAmount"
                      header="Current Month Forecasting"
                      headerStyle={{ textAlign: "right" }}
                      body={forecastingAmountbody}
                      bodyStyle={{ textAlign: "right" }}
                      style={{ width: "10%" }}
                    ></Column>
                    <Column
                      field="requestingAmount"
                      header="Requesting Amount"
                      body={reqAmountbody}
                      bodyStyle={{ textAlign: "right" }}
                      style={{ width: "10%" }}
                    ></Column>
                    <Column
                      field="difference"
                      header="Difference"
                      body={differencebody}
                      bodyStyle={{ textAlign: "right" }}
                      style={{ width: "10%" }}
                    ></Column>
                    <Column
                      field="description"
                      header="Description"
                      style={{ width: "10%" }}
                    ></Column>
                    {/* <Column field="status" header="Status"></Column> */}
                    <Column
                      header="Status"
                      field="actionBodyTemplate "
                      body={actionBodyTemplate}
                      style={{ width: "5%" }}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            );
          })}
          {/* <div className='col-md-12'> */}
          {/* <DataTable value={reqApproval} tableStyle={{ minWidth: '50rem' }}>
                                <Column field="budgetSubCategory" header="Category"></Column>
                                <Column field="forecastingAmount" header="Current Month Forecasting"></Column>
                                <Column field="requestingAmount" header="Requested Amount"></Column>
                                <Column field="difference" header="Difference"></Column>
                                <Column field="description" header="Description"></Column>
                                <Column  header="Status"></Column>
             </DataTable> */}
          {/* </div>      */}
        </div>
      </BlockUI>
    </div>
  );
}
