import config from '../assets/config';
import { ax } from './base';

export default class BudgetService {
    // Budget
    
    GetBudgetForecasting(TenantID,Year){
        return ax.get(`${config.apiUrl}Budget/GetBudgetList/${TenantID}/${Year} `);
    }  

    SaveBudgetCategory(payload){
        return ax.post(`${config.apiUrl}Budget/SaveBudgetCategory`,payload);
    }

    SaveBudgetSubCategory(payload){
        return ax.post(`${config.apiUrl}Budget/SaveBudgetSubCategory`,payload);
    }

    SaveBudget(payload){
        return ax.post(`${config.apiUrl}Budget/SaveBudget`,payload);
    }
    SaveBudgetExpense(payload){
        return ax.post(`${config.apiUrl}Budget/SaveBudgetExpense`,payload);
    }

    //fund-req
    GetFundRequests(tenantID,year,month){
        return ax.get(`${config.apiUrl}Budget/GetFundRequests/${tenantID}/${year}/${month} `);
    }
    SaveFundRequest(payload){
        return ax.post(`${config.apiUrl}Budget/SaveFundRequest`,payload);

    }
    DeleteFundRequest(tenantID,fundId){
        return ax.delete(`${config.apiUrl}Budget/DeleteFundRequest?TenantID=${tenantID}&FundID=${fundId} `);
    }

    //analysis
    GetBudgetBarcart(tenantID,year){
        return ax.get(`${config.apiUrl}Budget/GetBudgetBarcart/${tenantID}/${year}`);

    }
    GetBudgetChart(tenantID,year){
        return ax.get(`${config.apiUrl}Budget/GetBudgetChart/${tenantID}/${year}`);

    }

    //Fund request
    GetBudgetCategories(tenantID,yearid,monthid){
        return ax.get(`${config.apiUrl}Budget/GetBudgetCategories/${tenantID}/${yearid}/${monthid}`);

    }
    
    GetBudgetByCategory(payload){
        return ax.post(`${config.apiUrl}Budget/GetBudgetByCategory`,payload);
    }
    
    // Budget tracking QuaterDropDown.
    GenerateQuaterDropDown(tenantID,year){
        return ax.get(`${config.apiUrl}Budget/GenerateQuaterDropDown/${tenantID}/${year}`);
    }

    GetBudgetQuarterList(tenantID,year,QuarterID){
        return ax.get(`${config.apiUrl}Budget/GetBudgetQuarterList/${tenantID}/${year}/${QuarterID}`);
    }
    GetFiscalYears(tenantID){
        return ax.get(`${config.apiUrl}Budget/GetFiscalYears/${tenantID}`);
    }
    GetCloneFiscalYears(tenantID){
        return ax.get(`${config.apiUrl}Budget/GetCloneFiscalYears/${tenantID}`);
    }

    UpdateBudgetStatus(payload){
        return ax.post(`${config.apiUrl}Budget/UpdateBudgetStatus`,payload);
    }
    
    DeleteBudgetCategory(payload){
        return ax.post(`${config.apiUrl}Budget/DeleteBudgetCategory`,payload);
    }
    CloneBudgetCategories(payload){
        return ax.post(`${config.apiUrl}Budget/CloneBudgetCategories`,payload);
    }
    

    
}