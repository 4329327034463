import React, { Children, useEffect, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import SecurityService from "../../../services/security-service";
import GlobalVariable from "../../../services/globalVar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { VscDash } from "react-icons/vsc";
import { TreeTable } from 'primereact/treetable';



export default function ServiceReference() {
  const ss = new SecurityService();
  const [usertypelist, setUsertypelist] = useState([]);
  const [selectusertypelist, setSelectusertypelist] = useState([]);
  const [first1, setFirst1] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [userTypeServiceSearch, setUserTypeServiceSearch] = useState();
  const [userTypeId, setUserTypeId] = useState(GlobalVariable.userDet.appUser.userTypeID);
  const [selectedNodeKeys, setSelectedNodeKeys] = useState(null);
  const [expandedKeys2, setExpandedKeys2] = useState([]);


  const data = [
    {
      entServiceId: 434,
      serviceName: "Dashboard",
      serviceOrder: 1,
      globalOrderBy: 1,
      resourceAction: "/administrative/dashboard",
      resourceActionV2: null,
      menuIcon: "icon-dashboardnew",
      menuIconV2: null,
      resourceType: "menu_service",
      parentServiceId: 0,
      serviceDesc: "q",
      serviceGroup: null,
      read_Attri: true,
      write_Attri: true,
      delete_Attri: true,
      approve_Attri: true,
      export_Attri: true,
      isEntitlable: false,
      displayIndicator: null,
      isChild: false,
      isRoleRestricted: false,
      allowedRoles: null,
      kproductId: 1080,
      serviceCategory: null,
      srvCode: null,
      isAssociate: false,
      subMenu: [],
    },
    {
      entServiceId: 435,
      serviceName: "Project & Workspace",
      serviceOrder: 2,
      globalOrderBy: 7,
      resourceAction: "#",
      resourceActionV2: null,
      menuIcon: "icon-projectandworkspace",
      menuIconV2: null,
      resourceType: "menu_service",
      parentServiceId: 0,
      serviceDesc: "q",
      serviceGroup: null,
      read_Attri: true,
      write_Attri: true,
      delete_Attri: true,
      approve_Attri: true,
      export_Attri: true,
      isEntitlable: false,
      displayIndicator: null,
      isChild: true,
      isRoleRestricted: false,
      allowedRoles: null,
      kproductId: 1080,
      serviceCategory: null,
      srvCode: null,
      isAssociate: false,
      subMenu: [
        {
          entServiceId: 436,
          serviceName: "Projects",
          serviceOrder: 1,
          globalOrderBy: 65,
          resourceAction: "/administrative/project",
          resourceActionV2: null,
          menuIcon: null,
          menuIconV2: null,
          resourceType: "menu_service",
          parentServiceId: 435,
          serviceDesc: null,
          serviceGroup: null,
          read_Attri: true,
          write_Attri: true,
          delete_Attri: true,
          approve_Attri: true,
          export_Attri: true,
          isEntitlable: false,
          displayIndicator: null,
          isChild: false,
          isRoleRestricted: false,
          allowedRoles: null,
          kproductId: 1080,
          serviceCategory: null,
          srvCode: null,
          isAssociate: false,
          subMenu: null,
        },
      ],
    },
  ];

  const GetUserTypeList = () => {
    const data = {
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      UserTypeID: GlobalVariable.userDet.appUser.userTypeID,
      EntityID: GlobalVariable.userDet.tenantDetail.tenantID,
      EntityType: "Tenant",
    };
    console.log(data, "data");
    ss.GetUserTypeList(data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setUsertypelist(res.data.data);
          setSelectusertypelist(res.data.data[0])
        }
        console.log(usertypelist, "usertypelist");
      })
      .catch((err) => console.log(err, "usertypelistErr"));
  };

  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });

  

  const GetUserTypeServiceSearch = (
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder,
    userTypeId
  ) => {
    const data = {
      UserTypeParams: {
        UserTypeID: userTypeId,
      },
      ServerSearchables: {
        draw: 1,
        searchData: searchVal || "",
        orderByColumn: sortName,
        orderBy: sortOrder,
        currentPage: 0,
        pageSize: sizePerPage,
        pageNumber: page,
      },
      userTypeServiceLists: [],
    };
    ss.GetUserTypeServiceSearch(data).then((res) => {
      if (res.data.statusCode === 200) {
        console.log(res.data.data, "GetUserTypeServiceSearch");
        setTotalRecords(res.data.data.serverSearchables.recordsTotal)
        console.log(totalRecords,'tt');
        const mainAndSubMenus = res.data.data.userTypeServiceLists.map((a) => ({
          key: a.entServiceId,
          data: {
            serviceName:a.serviceName,
            Read:a.read_Attri ? <span className="text-danger">Y</span> : <VscDash className="text-danger"/>,
            Write:a.write_Attri ? <span className="text-danger">Y</span> : <VscDash className="text-danger"/>,
            Delete:a.delete_Attri ? <span className="text-danger">Y</span> : <VscDash className="text-danger"/>,
            Approve:a.approve_Attri ? <span className="text-danger">Y</span> :<VscDash className="text-danger"/>,
            Export:a.export_Attri ? <span className="text-danger">Y</span> : <VscDash className="text-danger"/>
          },
          children: a.subMenu.map((b) => ({
            key:b.entServiceId,
            data: {
              serviceName:b.serviceName,
              Read:b.read_Attri ? <AiOutlineCheck className="text-success" /> : <VscDash />,
              Write:b.write_Attri ? <AiOutlineCheck className="text-success" /> : <VscDash />,
              Delete:b.delete_Attri ? <AiOutlineCheck className="text-success" /> : <VscDash />,
              Approve:b.approve_Attri ? <AiOutlineCheck className="text-success" /> : <VscDash />,
              Export:b.export_Attri ? <AiOutlineCheck className="text-success" /> : <VscDash />
            }
          }))
        }));
        console.log(mainAndSubMenus,'mainAndSubMenus');
        setUserTypeServiceSearch(mainAndSubMenus)
        // if (res.data.data.userTypeServiceLists === null) {
        //   setUserTypeServiceSearch([]);
        //   const total = res.data.data.serverSearchables.recordsTotal
        //   setTotalRecords(total);
        // } else {
        //   setUserTypeServiceSearch([...res.data.data.userTypeServiceLists]);
        //   console.log(userTypeServiceSearch, "hirt");
        // }
      }
    });
  };
  const onCustomPage1 = (event) => {
    console.log(event, "event");
    setFirst1(event.first);
    const val = event.page + 1;
    const size = event.rows;
    setSizePerPage(size);
    setPage(val);
    GetUserTypeServiceSearch(page, size, searchVal, "", "","");
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const handleDropDownSelect = (e) => {
    console.log(e.target.value,'e');
    // setUserTypeId(e.target.value.userTypeID)
    const userid = e.target.value.userTypeID
    setSelectusertypelist(e.value)
    GetUserTypeServiceSearch(page, sizePerPage, searchVal, "", "",userid);

  }

  useEffect(() => {
    GetUserTypeList();
    GetUserTypeServiceSearch(page, sizePerPage, searchVal, "", "",userTypeId);
  }, []);

  const renderBooleanCell = (rowData, field) => {
    return rowData[field] ? <AiOutlineCheck className="text-success" /> : <VscDash />;
  };

  
  return (
    <div>
      <div className="row mx-auto kk-table-header kk-header-sec">
        <p className="p-0" style={{ fontSize: "1.1rem" }}>
          {" "}
          Available Service for User Type -
          <Dropdown
            className="ms-2"
            value={selectusertypelist}
            onChange={(e) => handleDropDownSelect(e)}
            options={usertypelist}
            optionLabel="userType"
            placeholder="Select user type"
          />
        </p>
      </div>
      {/* <table className="w-100 ">
        <thead>
          <tr>
            <th>Service Name</th>
            <th className="text-center">Read</th>
            <th className="text-center">Write</th>
            <th className="text-center">Delete</th>
            <th className="text-center">Approve</th>
            <th className="text-center">Export</th>
            <th>Data Condition</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody className="mt-2">
          {data.map((item) => {
            return (
              <>
                <tr className="border-bottom" key={item.entServiceId}>
                  <td className="tr-title bck-clr p-2">
                    <div>{item.serviceName}</div>
                  </td>
                  <td className="tr-title bck-clr p-2"></td>
                  <td className="tr-title bck-clr p-2"></td>
                  <td className="tr-title bck-clr p-2"></td>
                  <td className="tr-title bck-clr p-2"></td>
                  <td className="tr-title bck-clr p-2"></td>
                  <td className="tr-title bck-clr p-2"></td>
                  <td className="tr-title bck-clr p-2"></td>
                </tr>
                {item.subMenu.map((itm) => {
                  return (
                    <tr className="border-bottom p-2" key={itm.entServiceId}>
                      <td className="text-end border-end pe-3">
                        {itm.serviceName}
                      </td>
                      <td className="text-center border-end">
                        {itm.read_Attri ? (
                          <AiOutlineCheck className="text-success" />
                        ) : (
                          <AiOutlineClose />
                        )}
                      </td>
                      <td className="text-center border-end">
                        {itm.write_Attri ? (
                          <AiOutlineCheck className="text-success" />
                        ) : (
                          <AiOutlineClose />
                        )}
                      </td>
                      <td className="text-center border-end">
                        {itm.read_Attri ? (
                          <AiOutlineCheck className="text-success" />
                        ) : (
                          <AiOutlineClose />
                        )}
                      </td>
                      <td className="text-center border-end">
                        {itm.read_Attri ? (
                          <AiOutlineCheck className="text-success" />
                        ) : (
                          <AiOutlineClose />
                        )}
                      </td>
                      <td className="text-center border-end">
                        {itm.read_Attri ? (
                          <AiOutlineCheck className="text-success" />
                        ) : (
                          <AiOutlineClose />
                        )}
                      </td>
                      <td className="text-center border-end">{itm.datacond}</td>
                      <td className="text-center border-end">
                        {itm.description}
                      </td>
                    </tr>
                  );
                })}
              </>
            );
          })}
        </tbody>
      </table> */}
    <TreeTable
      stripedRows
      paginator
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      lazy
      totalRecords={totalRecords}
      rowsPerPageOptions={[10, 20, 50, totalRecords]}
      first={first1}
      rows={sizePerPage}
      onPage={onCustomPage1}
      onSort={onSort}
      sortField={lazyParams.sortField}
      sortOrder={lazyParams.sortOrder}
      value={userTypeServiceSearch}
      selectionKeys={selectedNodeKeys}
      onSelectionChange={(e) => setSelectedNodeKeys(e.value)}
      expandedKeys={expandedKeys2}
      onToggle={(e) => setExpandedKeys2(e.value)}
      expanded ={true}
      tableStyle={{ minWidth: '100%' }}
      className="tableheader"
    >
      <Column field="serviceName" header="Service Name"  expander style={{ width: "20%"}}></Column>
      <Column field="Read" header="Read" ></Column>
      <Column field="Write" header="Write" ></Column>
      <Column field="Delete" header="Delete" ></Column>
      <Column field="Approve" header="Approve" ></Column>
      <Column field="Export" header="Export" ></Column>
      <Column field="dataCondition" header="Data Condition" ></Column>
      <Column field="description" header="Description" ></Column>
    </TreeTable>
    </div>
  );
}
