import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react'
import GV from '../../services/globalVar';
import BudgetService from '../../services/budget-service';

export default function ExpenseSummaryView() {

    const bud = new BudgetService();
    const toast = useRef(null);
    const [subCategory, setSubCategory] = useState([]);
    const currentYear = new Date().getFullYear(); 
    const options = Array.from({ length: 10 }, (_, index) => currentYear - index);
    const [selectedYear, setSelectedYear] = useState(currentYear);

    const years = options.map((cont,i) => {
        return (
            {
                label: cont,
                value: cont,
                id: i
            }
        )
    });
    const [months,getMonths] =useState([{label:'Jan',value:1,key:1},
    {label:'Feb',value:2,key:2},
    {label:'Mar',value:3,key:3},
    {label:'Apr',value:4,key:4},
    {label:'May',value:5,key:5},
    {label:'Jun',value:6,key:6},
    {label:'Jul',value:7,key:7},
    {label:'Aug',value:8,key:8},
    {label:'Sep',value:9,key:9},
    {label:'Oct',value:10,key:10},
    {label:'Nov',value:11,key:11},
    {label:'Dec',value:12,key:12}
   ])
    const [sales] = useState([
        { product: 'Bamboo Watch', lastYearSale: 51, thisYearSale: 40, lastYearProfit: 54406, thisYearProfit: 43342 },
        { product: 'Black Watch', lastYearSale: 83, thisYearSale: 9, lastYearProfit: 423132, thisYearProfit: 312122 },
        { product: 'Blue Band', lastYearSale: 38, thisYearSale: 5, lastYearProfit: 12321, thisYearProfit: 8500 },
        { product: 'Blue T-Shirt', lastYearSale: 49, thisYearSale: 22, lastYearProfit: 745232, thisYearProfit: 65323 },
        { product: 'Brown Purse', lastYearSale: 17, thisYearSale: 79, lastYearProfit: 643242, thisYearProfit: 500332 },
        { product: 'Chakra Bracelet', lastYearSale: 52, thisYearSale: 65, lastYearProfit: 421132, thisYearProfit: 150005 },
        { product: 'Galaxy Earrings', lastYearSale: 82, thisYearSale: 12, lastYearProfit: 131211, thisYearProfit: 100214 },
        { product: 'Game Controller', lastYearSale: 44, thisYearSale: 45, lastYearProfit: 66442, thisYearProfit: 53322 },
        { product: 'Gaming Set', lastYearSale: 90, thisYearSale: 56, lastYearProfit: 765442, thisYearProfit: 296232 },
        { product: 'Gold Phone Case', lastYearSale: 75, thisYearSale: 54, lastYearProfit: 21212, thisYearProfit: 12533 }
    ]);


    const headerGroup = (
        <ColumnGroup>
           
            <Row>
            <Column header="Product" rowSpan={2}  frozen/>
                {months.map(x=>{
                    return(
                     <Column header={x.label} colSpan={2} />
                    )
                })}
                     {/* <Column header="Jan" colSpan={2} />
                     <Column header="Feb" colSpan={2} />
                     <Column header="Mar" colSpan={2} />
                     <Column header="Apr" colSpan={2} />
                      <Column header="May" colSpan={2} />
                     <Column header="Jun" colSpan={2} />
                     <Column header="Jul" colSpan={2} />
                     <Column header="Aug" colSpan={2} />
                     <Column header="Sep" colSpan={2} />
                     <Column header="Oct" colSpan={2} />
                     <Column header="Nov" colSpan={2} />
                     <Column header="Dec" colSpan={2} />  */}


            </Row>
            <Row>

         
                
             <Column header="Forecast" field="lastYearSale" />
            <Column header="Expense" field="thisYearSale" />
            <Column header="Forecast" field="lastYearSale" />
            <Column header="Expense" field="thisYearSale" />
           <Column header="Forecast" field="lastYearSale" />
            <Column header="Expense" field="thisYearSale" />

         

            
            <Column header="Forecast" field="lastYearSale" />
            <Column header="Expense" field="thisYearSale" />
            <Column header="Forecast" field="lastYearSale" />
            <Column header="Expense" field="thisYearSale" />
             <Column header="Forecast" field="lastYearSale" />
            <Column header="Expense" field="thisYearSale" />
               
            <Column header="Forecast" field="lastYearSale" />
            <Column header="Expense" field="thisYearSale" />


            <Column header="Forecast" field="lastYearSale" />
            <Column header="Expense" field="thisYearSale" />

         

            
            <Column header="Forecast" field="lastYearSale" />
            <Column header="Expense" field="thisYearSale" />
            <Column header="Forecast" field="lastYearSale" />
            <Column header="Expense" field="thisYearSale" />
             <Column header="Forecast" field="lastYearSale" />
            <Column header="Expense" field="thisYearSale" />
               
            <Column header="Forecast" field="lastYearSale" />
            <Column header="Expense" field="thisYearSale" /> 

            </Row>
        </ColumnGroup>
    );

    const lastYearSaleBodyTemplate = (rowData) => {
        return `${rowData.lastYearSale}`;
    };

    const thisYearSaleBodyTemplate = (rowData) => {
        return `${rowData.thisYearSale}`;
    };


    function getBudgetForecasting() {
        let tenid = GV.userDet.tenantDetail.tenantID;
        let year = selectedYear;

        bud.GetBudgetForecasting(tenid, year)
            .then((response) => {
                if (response.data) {
                    if (response.data.statusCode === 200) {
                        // const val = response.data.data
                        
                        setSubCategory(response.data.data);
                    }
                }
            }).catch(function (error) {

            })
    }
  
    useEffect(() => {
        getBudgetForecasting();
    }, [selectedYear]);
  return (
    <div>
        <div>
            {/* <Toast ref={toast}></Toast> */}
           
            <div className='card'>
                <div className='card-body'>
                    <div className='d-flex justify-content-between flex-wrap'>
                        <h4>Expenses-Analysis</h4>
                        <div>
                           
                        </div>
                    </div>
                </div>
            </div>

            <div className='card mt-2 '>
                < div className='row fund'>
                    <div className='col-md'>
                    <DataTable value={sales}  showGridlines  headerColumnGroup={headerGroup} scrollable scrollHeight="700px"   tableStyle={{ minWidth: '50rem' }}>
                        <Column field="product" frozen  style={{ minWidth: '178px' }}/>
                      
                        <Column field="lastYearSale" body={lastYearSaleBodyTemplate} />
                         <Column field="thisYearSale" body={thisYearSaleBodyTemplate} />  
                         <Column field="lastYearSale" body={lastYearSaleBodyTemplate} />
                         <Column field="thisYearSale" body={thisYearSaleBodyTemplate} />  
                          <Column field="lastYearSale" body={lastYearSaleBodyTemplate} />
                         <Column field="thisYearSale" body={thisYearSaleBodyTemplate} />  
                        
                         <Column field="lastYearSale" body={lastYearSaleBodyTemplate} />
                         <Column field="thisYearSale" body={thisYearSaleBodyTemplate} />  
                         <Column field="lastYearSale" body={lastYearSaleBodyTemplate} />
                         <Column field="thisYearSale" body={thisYearSaleBodyTemplate} />  
                         <Column field="lastYearSale" body={lastYearSaleBodyTemplate} />
                         <Column field="thisYearSale" body={thisYearSaleBodyTemplate} />  

                         <Column field="lastYearSale" body={lastYearSaleBodyTemplate} />
                         <Column field="thisYearSale" body={thisYearSaleBodyTemplate} /> 

                           <Column field="lastYearSale" body={lastYearSaleBodyTemplate} />
                         <Column field="thisYearSale" body={thisYearSaleBodyTemplate} />  
                        
                         <Column field="lastYearSale" body={lastYearSaleBodyTemplate} />
                         <Column field="thisYearSale" body={thisYearSaleBodyTemplate} />  
                         <Column field="lastYearSale" body={lastYearSaleBodyTemplate} />
                         <Column field="thisYearSale" body={thisYearSaleBodyTemplate} />  
                         <Column field="lastYearSale" body={lastYearSaleBodyTemplate} />
                         <Column field="thisYearSale" body={thisYearSaleBodyTemplate} />  

                         <Column field="lastYearSale" body={lastYearSaleBodyTemplate} />
                         <Column field="thisYearSale" body={thisYearSaleBodyTemplate} />  
                    </DataTable>
                    </div>
                </div>
            </div>
            
            

        </div>
    </div>
  )
}
