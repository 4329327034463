import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { AiOutlineSearch } from 'react-icons/ai';
import { DataTable, Column, Dropdown, InputText, MultiSelect, iconSet, IcomoonReact } from '../PrimeReact.js';
import NewVendors from './NewVendors';
import GV from '../../services/globalVar';
import { InputSwitch } from '../PrimeReact.js';
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import {Space,Input} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

// API call
import ContactsService from '../../services/contactsService';

const Vendor = () => {

    const modalref = useRef(null);
    const toast = useRef(null);
    const cos = new ContactsService();
    const [pageInputTooltip, setPageInputTooltip] = useState('Press \'Enter\' key to go to this page.');
    const [first1, setFirst1] = useState(0);
    const [tdataLoad, setTdataLoad] = useState(true);
    const [requestData, setRequestData] = useState([]);
    const [data, setData] = useState([]);
    const [totalRecordsIn, setTotalRecordsIn] = useState(0);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [sortName, setSortName] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [searchVal, setSearchVal] = useState('');
    const [isMounted, setIsMounted] = useState(true);
    const [accountSelected, setAccountSelected] = useState('All')
    const [vendorId, setVendorId] = useState("");
    const [isProcess, setIsProcess] = useState(false);

    const VendorStatus = [
        { label: 'All Vendors', value: 'All', status: true },
        { label: 'Active Vendors', value: 'Active', status: true },
        { label: 'Inactive Vendors', value: 'InActive', status: true },
    ];
    const [statusSelected, setStatusSelected] = useState(VendorStatus[0].value)
    const [filterSelected, setFilterSelected] = useState(VendorStatus[0].value)
    const editProduct = (product) => {
        setVendorId(product.contactID)
        modalref.current.alertToggledit(product);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <IcomoonReact iconSet={iconSet} color="#444" size={20} icon="edit" className='pointer' onClick={() => editProduct(rowData)} />
            </React.Fragment>
        );
    }

    const panelHeaderTemplate = () => { 
        return (
            <div className="pt-2 ps-4">
                {/* <p className='mb-0 fs-6'>Select</p> */}
            </div>
        );
    }

   
    
      const openingBalanceTemplateBody = (rowdata) => {
        return (
          <div className="text-end">
            {/* { GV.userDet.tenantConfig.currency+ ' ' +rowdata.openingBalance.toFixed(2)} */}
            { GV.userDet.tenantConfig.currency+ " " + rowdata.openingBalance.toFixed(2)}
          </div>
        )
      }

      const closingBalanceTemplateBody = (rowdata) => {
        return (
          <div className="text-end">
            {/* { GV.userDet.tenantConfig.currency+ ' ' +rowdata.closingBalance.toFixed(2)} */}
            {GV.userDet.tenantConfig.currency+ " " +rowdata.closingBalance.toFixed(2)}
          </div>
        )
      }

    const basiccolumns = [
        // { field: 'firstName', header: 'Name', sortable: false, body: '' },
        // { field: 'companyName', header: 'Company Name', sortable: false, body: '' },
        // { field: 'email', header: 'Email', sortable: false, body: '' },
        // { field: 'phone', header: 'Phone', sortable: false, body: '' },
        //   {field:'actionBodyTemplate',exportable:false,  header: 'Action',sortable:false,body:{actionBodyTemplate},disabled: true}    
    ];
    const columns = [
        // { field: 'firstName', header: 'Name', sortable: false, body: '' },
        { field: 'displayName', header: 'Display Name', sortable: false, body: '' },
        // { field: 'companyName', header: 'Company Name', sortable: false, body: '' },
        // { field: 'email', header: 'Email', sortable: false, body: '' },
        // { field: 'phone', header: 'Phone', sortable: false, body: '' },
        { field: 'openingBalance', header: 'Payables', sortable: false, body: 'openingBalanceTemplateBody' },
        { field: 'closingBalance', header: 'Unused Credits', sortable: false, body: 'closingBalanceTemplateBody' },
        { field: 'saPhone', header: 'Mobile Phone', sortable: false, body: '' },
        // {field:'actionBodyTemplate',exportable:false,  header: 'Action',sortable:false,body:{actionBodyTemplate},disabled: true}
    ];
    const [selectedColumns, setSelectedColumns] = useState(basiccolumns);

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    }
    const accountChange = (e) => {
        setFilterSelected(e.value)
        let obj = VendorStatus.find(c => c.value === e.value)
        if (obj.status === true) {
            setStatusSelected(e.value);
            // setAccountSelected('All');
            fetchVendorList(page, sizePerPage, searchVal, '', '', e.value);
        } else {
            setStatusSelected('Active');
            // setAccountSelected(e.value);
            fetchVendorList(page, sizePerPage, searchVal, '', '', 'Active', e.value);
        }
    }
    const keySearch = (e) => {
        setSearchVal(e.target.value);
        if (e.key === 'Enter') {
            const searched = e.target.value;setPage(1);
            fetchVendorList(1, sizePerPage, searched, sortName, sortOrder, statusSelected);
        }
        if (e.type === 'keyup') {
            if (e.target.value === '') {
                fetchVendorList(1, sizePerPage, '', sortName, sortOrder, statusSelected);
            }
        }
    }
    const emptySearch = () => {
        setSearchVal('');
        fetchVendorList(1, sizePerPage, '', sortName, sortOrder, statusSelected);
    }

    const header = (
        <div className='d-flex justify-content-between flex-wrap'>
            <div className="text-t-primary  kk-table-header kk-header-sec row">
                <Dropdown className='col-auto text-start me-3' value={filterSelected} options={VendorStatus} onChange={(e) => accountChange(e)}
                    scrollHeight="400px"
                    placeholder="Select account" />
                <div className='col  border rounded my-auto ' >
                    <AiOutlineSearch />
                    <input
                        className=' py-1 ps-3 search' value={searchVal} onChange={(e) => keySearch(e)}
                        onKeyUp={(e) => keySearch(e)} 
                        placeholder="Search" />
                    {
                        searchVal !== '' ? <i className='pi pi-times' onClick={emptySearch} /> : ''
                    }
                </div>
            </div>
            <div className='my-auto'>
                {/* <FiPlus className='me-1' /> */}
                <button className='btn btn-primary py-1 me-2 ' onClick={() => { modalref.current.alertToggle(); setVendorId(0) }} > <i className='pi pi-plus'></i>  New Vendor</button>
                <MultiSelect value={selectedColumns} options={columns} resetFilterOnHide={selectedColumns} optionLabel="header" optionDisabled="disabled" onChange={onColumnToggle} style={{ width: '3em' }} scrollHeight="400px" dropdownIcon="pi pi-table"
                    tooltip="Custom Columns" tooltipOptions={{ position: 'top' }} panelHeaderTemplate={panelHeaderTemplate}/>
            </div>
        </div>
    );

    const dynamicColumns = selectedColumns.map((col, i) => {
        return (
            <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} 
            // body={col.body && actionBodyTemplate} 
            body={col.field === 'openingBalance' ? openingBalanceTemplateBody : col.field === 'closingBalance' ? closingBalanceTemplateBody : col.body }

            ></Column>
        )
    });

    const dynamicColumnsOne = selectedColumns.map((col, i) => {
        return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={col.body} ></Column>
    });

    const onCustomPage1 = (event) => {
        setFirst1(event.first);
        const val = event.page + 1;
        const size = event.rows;
        setSizePerPage(size);
        setPage(val);
        fetchVendorList(val, size, searchVal, '', '', statusSelected);
    }
    //pagination end
    //sort start
    const [lazyParams, setLazyParams] = useState({
        first: first1,
        rows: sizePerPage,
        page: page,
        sortField: sortName,
        sortOrder: sortOrder,
    });


    const onSort = (event) => {
        setLazyParams(event);
    }
    //sort end
    //apicall
    function fetchVendorList(page, sizePerPage, searchVal, sortName, sortOrder, status, account) {
        setTdataLoad(false);
        let data = {
            "InputParams":
            {
                "TenantID": GV.userDet.tenantDetail.tenantID,
                "ContactType": "Vendor",
                "CustomerType": "All",
                "Status": status
            },
            "ServerSearchables":
            {
                "Draw": page,
                "SearchData": searchVal || "",
                "OrderByColumn": sortName,
                "OrderBy": sortOrder,
                "PageSize": sizePerPage,
                "PageNumber": page
            }
        }
        cos.GetContactSearchData(data).then((response) => {
            // if (isMounted) {
            if (response.data) {
                // response.data.data.contactList.forEach((a) => {
                //     a.openingBalance = GV.userDet.tenantConfig.currency+ " " + a.openingBalance
                //     a.closingBalance = GV.userDet.tenantConfig.currency+ " " +a.closingBalance
                // });
                setTotalRecordsIn(response.data.data.serverSearchables.recordsTotal);
                setData(response.data.data.contactList);
                setRequestData(response.data.data.contactList);
                //   setData(requestData.slice(0,sizePerPage));
                setTdataLoad(false);
            }
            // }
        })
            .catch(function (error) {

            })
    }

    const parentFunction = () => {
        fetchVendorList(page, sizePerPage, searchVal, '', '', statusSelected);
    }

    const toggleStatus = (e, rowData) => {
        e.originalEvent.stopPropagation();
        const row = [...data];
        row.forEach((x) => {
          if (x.contactID === rowData.contactID) x.status = !x.status;
        });
        setData(row);
        SwitchStatusVendor(rowData);
      };

      const SwitchStatusVendor = (items) => {
        setIsProcess(true);
        cos.SwitchStatusVendor(items.tenantID,items.contactID).then((res) => {
            setIsProcess(false);
            toast.current.show({
                severity: "success",
                summary: res.data.message,
              });
              fetchVendorList(page, sizePerPage, searchVal, '', '', statusSelected);
    
        }).catch((err) => {
        setIsProcess(false); 
       });
      };

    const renderStatusColumn = (e) => {
        return statusBodyTemplate(e, toggleStatus);
      };

      const statusBodyTemplate = (rowData, toggleStatus) => {
        return (
          <>
            <Tooltip target=".kk" content="Active / Inactive user" position="top" />
            <InputSwitch
              className="toggleSwitch kk"
              checked={rowData.status === "Active" ? true : false}
              onChange={(eve) => toggleStatus(eve, rowData)}
            />
          </>
        );
      };

    useLayoutEffect(() => {
        fetchVendorList(page, sizePerPage, searchVal, '', '', statusSelected);
        return () => { setIsMounted(false) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div>
        <Toast ref={toast} />
       <div className='row'>
        <div className='col-md-12'>
        <div className='card card-borderless cc-greyCard-custm mb-3'>
            <div className='ms-2 my-2 me-2'>
                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    <h4 className="m-0">Vendors</h4>
                    <div className='d-flex justify-content-start  kk-table-header kk-header-sec  flex-wrap'>
                    <Dropdown className='col-auto text-start me-3' value={filterSelected} options={VendorStatus} onChange={(e) => accountChange(e)}
                    scrollHeight="400px"
                    placeholder="Select account" />

                    <Space.Compact  className='my-auto me-2'>
                        <Input addonBefore={<SearchOutlined />} allowClear  placeholder="Search"    value={searchVal}
                      onChange={(e) => keySearch(e)}
                      onKeyUp={(e) => keySearch(e)} />
                      </Space.Compact>

                      <button className='btn btn-primary py-1 me-2 kk-btnThin my-2' onClick={() => { modalref.current.alertToggle(); setVendorId(0) }} > <i className='pi pi-plus'></i>  New Vendor</button>
                        <MultiSelect   className='kk-btnThin my-auto' value={selectedColumns} options={columns} resetFilterOnHide={selectedColumns} optionLabel="header" optionDisabled="disabled" onChange={onColumnToggle} style={{ width: '3em' }} scrollHeight="400px" dropdownIcon="pi pi-table"
                    tooltip="Custom Columns" tooltipOptions={{ position: 'top' }} panelHeaderTemplate={panelHeaderTemplate}/>
                    </div>               
                 </div>

            </div>
        </div>
        {/* <div className='bg-white shadow-sm chart-of-account h-100 p-4'>
            <div className='row coa-header kk-header-sec'>
                <div className='col-auto header-right ms-auto'>
                </div>
            </div>*/}
            <div > 
                <DataTable className=' chofacc' value={data}  
                // responsiveLayout="scroll" 
                size="small" loading={tdataLoad}
                    paginator lazy totalRecords={totalRecordsIn}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rowsPerPageOptions={[10, 20, 50, totalRecordsIn]}
                    first={first1} rows={sizePerPage} onPage={onCustomPage1}
                    onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                >
                    <Column field="firstName" header="Name" />
                    <Column field="companyName" header="Company Name" />
                    <Column field="email" header="Email" />
                    <Column field="phone" header="Phone" />
                    {dynamicColumns}
                    <Column
                  field="statusBodyTemplate"
                  header="Status"
                  className="action"
                  body={(e) => renderStatusColumn(e)}
                ></Column>
                    <Column field="actionBodyTemplate" header="Action" body={actionBodyTemplate} />
                </DataTable>
                {isProcess && (   
                <ProgressSpinner
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  strokeWidth="2"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              )}
            </div>
         {/*</div> */}
        </div>
        </div>
        <NewVendors ref={modalref} parentFunction={parentFunction} data={{ vendorId: vendorId, fetchVendorList: fetchVendorList }} />
    </div>;
};

export default Vendor;



