import config from '../assets/config';
import { ax } from './base';

export default class SecurityService {

    GetSalesInvoiceListSearch(payload){
        return ax.post(`${config.apiUrl}Invoice/GetSalesInvoiceListSearch`,payload);
    }
    GetContact(payload){
        return ax.post(`${config.apiUrl}Contact/GetContact`,payload);
    }
    GenerateConfigNumber(payload){
        return ax.post(`${config.apiUrl}Common/GenerateConfigNumber`,payload);
    }
    SaveSalesInvoice(payload){
        return ax.post(`${config.apiUrl}Invoice/SaveSalesInvoice`,payload);
    }
    DownloadDocs(payload){
        return ax.post(`${config.apiUrl}Common/DownloadDocs`,payload);
    }
    GetItemsTenant(ItemID,TenantID,Status,ItemType){
        return ax.get(`${config.apiUrl}Items/GetItemsTenant/${ItemID}/${TenantID}/${Status}/${ItemType}`);
    }
    DeleteSalesInvoice(TenantID,SaleInvoiceID,SaleItemID){
        return ax.delete(`${config.apiUrl}Invoice/DeleteSalesInvoice/${TenantID}/${SaleInvoiceID}/${SaleItemID}`);
    }
    GetTenantDetails(TenantID,){
        return ax.get(`${config.apiUrl}Tenant/GetTenantDetails/${TenantID}`);
    }
    GetSalesItemList(TenantID,){
        return ax.get(`${config.apiUrl}Items/GetSalesItemList/${TenantID}`);
    }
    GetCountryTaxName(Country,){
        return ax.get(`${config.apiUrl}Common/GetCountryTaxName?CountryName=${Country}`);
    }
    SaveSalesInvoicePayment(payload){
        return ax.post(`${config.apiUrl}Invoice/SaveSalesInvoicePayment`,payload);
    }
    GetInvoiceSummary(tenantID){
        return ax.get(`${config.apiUrl}invoice/GetInvoiceSummary/${tenantID}`);
    }

    //purchase order payment
    GeneratePurchseOrderConfigNumber(payload){
        return ax.post(`${config.apiUrl}Common/GenerateConfigNumber`,payload);
    }
    SavePurchasePayment(payload){
        return ax.post(`${config.apiUrl}Purchase/SavePurchasePayment`,payload);
    }
    
}