import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import InvoiceService from "../../services/invoice-service";
import GlobalVariable from "../../services/globalVar";
import { Tooltip } from "primereact/tooltip";
import { IcomoonReact, iconSet, Toast } from "../PrimeReact";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { format } from "date-fns";
import { DatePicker, Space, Input } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import salesService from "../../services/sales-service";
import { BlockUI } from "primereact/blockui";
import { Empty } from "antd";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { invalid } from "moment/moment";
import { STATUS } from "rsuite/esm/utils";
import { Estimate } from "./Estimate";
import { SearchOutlined } from "@ant-design/icons";


export const EstimateGrid = () => {
  const ss = new salesService();
  const [first1, setFirst1] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [isProcess, setIsProcess] = useState(false);
  const [estimateUserList, setEstimateUserList] = useState([]);
  const [isShowEstimate, setIsShowEstimate] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [discountedAmount, setDiscountedAmount] = useState("0.00");
  const [docUrl, setDocUrl] = useState("");
  const [showDocument, setShowDocument] = useState(false);

  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });

  const GetSalesEstimateListSearch = (
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder
  ) => {
    setIsProcess(true);
    const datas = {
      InputParams: {
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        ContactID: 0,
        Status: "",
      },
      ServerSearchables: {
        draw: 1,
        searchData: searchVal || "",
        orderByColumn: sortName,
        orderBy: sortOrder,
        currentPage: 0,
        pageSize: sizePerPage,
        pageNumber: page,
      },
    };
    ss.GetSalesEstimateListSearch(datas).then((res) => {
      setIsProcess(false);
      setEstimateUserList(res.data.data.salesEstimateList);
      setTotalRecords(res.data.data.serverSearchables.recordsTotal);
    });
  };

  const keySearch = (e) => {
    const searched = e.target.value;
    setSearchVal(searched);
    if (e.key === "Enter") {
      setPage(1);
      GetSalesEstimateListSearch(page, sizePerPage, searchVal, "", "");
    }

    if (e.type === "keyup" && searched === "") {
      GetSalesEstimateListSearch(page, sizePerPage, searchVal, "", "");
    }
  };

  const emptySearch = () => {
    // setSearchVal("");
    GetSalesEstimateListSearch(page, sizePerPage, searchVal, "", "");
  };

  const createNewEstimate = () => {
    setIsShowEstimate(false);
    setRowData("");
    setDiscountedAmount("0.00");
  };

  const onCustomPage1 = (event) => {
    setFirst1(event.first);
    const val = event.page + 1;
    const size = event.rows;
    setSizePerPage(size);
    setPage(val);
    GetSalesEstimateListSearch(val, size, searchVal, "", "");
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const dateBodyTemplate = (rowData) => {
    return (
      <div>
        {format(
          new Date(rowData.estimateDate),
          GlobalVariable.userDet.tenantConfig.dateFormat
        )}
      </div>
    );
  };

  const amountBodyTemplate = (rowData) => {
    return (
      GlobalVariable.userDet.tenantConfig.currency +
      " " +
      rowData.totalAmount.toFixed(2)
    );
  };

  const amountBodyStatusTemplate1 = (rowData) => {
    let status = rowData.status.toLowerCase();

    const getStatusColor = (status) => {
      switch (status) {
        case "draft":
          return "#f0f0f0";
        case "estimated":
          return "#c7ecc7";
        case "expired":
          return "rgb(255 227 227)";
        case "rejected":
          return "rgb(255 145 145)";
        case "invoiced":
          return "#d5f7d5";
        default:
          return "#ffffff";
      }
    };

    const backgroundColor = getStatusColor(status);

    const statusStyle = {
      backgroundColor: backgroundColor,
    };

    return (
      <p className="m-0 status-color" style={statusStyle}>
        {rowData.status}
      </p>
    );
  };

  const onHide = () => {
    setShowDocument(false);
    // setDocUrl("");
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center">
        <Tooltip target=".pi-eye" content="View" position="right" />
        <Tooltip target=".icon-lock" content="Change password" position="top" />
        <Tooltip target=".custom-edit-icon" />

        <i
          className="pi pi-file-pdf ms-1"
          onClick={(e) => DownloadDocs(e, rowData)}
          style={{ color: "slateblue", fontSize: "large" }}
        ></i>

        <Dialog
          visible={showDocument}
          onHide={onHide}
          header="Document"
          modal
          style={{ width: "70%", height: "100%" }}
        >
          {docUrl !== 0 && docUrl !== null && docUrl !== undefined ? (
            <iframe src={docUrl} style={{ width: "100%", height: "850px" }} />
          ) : (
            <div
              style={{
                display: "grid",
                placeItems: "center",
                position: "relative",
                top: "50%",
                fontSize: "1.5rem",
              }}
            >
              No document found
            </div>
          )}
        </Dialog>
      </div>
    );
  };

  const backToUser = (item) => {
    setIsShowEstimate(item);
    GetSalesEstimateListSearch(page, sizePerPage, searchVal, "", "");
  };

  const DownloadDocs = (e, items) => {
    e.stopPropagation();
    setIsProcess(true);
    const datas = {
      DocIDs: items.docID.toString(),
      DocCategory: "Estimate",
    };
    ss.DownloadDocs(datas).then((res) => {
      if (res.data.statusCode === 200) {
        setDocUrl(res.data?.data[0]?.documentURL);
        setShowDocument(true);
        setIsProcess(false);
      }
    });
  };

  const handleRowClick = (event) => {
    event.originalEvent.stopPropagation();
    console.log(event.data, "rows");
    setRowData(event.data);
    setIsShowEstimate(false);
  };

  useEffect(() => {
    GetSalesEstimateListSearch(page, sizePerPage, searchVal, "", "");
  }, []);

  return (
    <div>
      <BlockUI blocked={isProcess}>
        {isShowEstimate ? (
          <div className="row mx-auto">
              <div className="card card-borderless cc-greyCard-custm mb-3">
                <div className="ms-2 my-2 me-2">
                  <div className="d-flex justify-content-between align-items-center flex-wrap mx-2">
                      <h4 className="m-0">Estimates</h4>
                    <div className=" d-flex justify-content-end flex-wrap">
                      <div className="col">
                        <Space.Compact className="hdeEst my-auto">
                          <Input
                            addonBefore={<SearchOutlined />}
                            allowClear={emptySearch}
                            placeholder="Search"
                            value={searchVal}
                            onChange={(e) => keySearch(e)}
                            onKeyUp={(e) => keySearch(e)}
                          />
                        </Space.Compact>
                      </div>

                        <button
                          className="btn btn-primary py-1 ms-2 me-2 my-auto"
                          onClick={createNewEstimate}
                        >
                          {" "}
                          <i className="pi pi-plus"></i> Create New
                        </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-0">
                <DataTable
                  totalRecords={totalRecords}
                  className="hdeEst"
                  tableStyle={{ minWidth: "100%" }}
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  lazy
                  paginator
                  loading={isProcess}
                  first={first1}
                  rows={sizePerPage}
                  onPage={onCustomPage1}
                  onSort={onSort}
                  sortField={lazyParams.sortField}
                  sortOrder={lazyParams.sortOrder}
                  rowsPerPageOptions={[10, 20, 50]}
                  onRowClick={handleRowClick}
                  value={estimateUserList}
                  emptyMessage="No data available"
                >
                  <Column
                    field="estimateNumber"
                    header="Estimate Number"
                    style={{ width: "12%" }}
                  ></Column>

                  <Column
                    field="companyName"
                    header="Company Name"
                    style={{ width: "15%" }}
                  ></Column>
                  <Column
                    field="dateBodyTemplate"
                    header="Date"
                    body={dateBodyTemplate}
                  ></Column>
                  <Column field="phone" header="Contact Number"></Column>
                  <Column field="customerName" header="Customer Name"></Column>
                  <Column
                    field="amountBodyTemplate"
                    header="Amount"
                    className="text-end amount-align"
                    body={amountBodyTemplate}
                  ></Column>
                  {/* <Column
                field="amountBodyStatusTemplate"
                header="Status"
                body={amountBodyStatusTemplate}
              ></Column> */}
                  <Column
                    field="amountBodyStatusTemplate"
                    header="Status"
                    body={amountBodyStatusTemplate1}
                  ></Column>

                  <Column
                    field="actionBodyTemplate"
                    header="Action"
                    className="actionn"
                    body={actionBodyTemplate}
                  />
                </DataTable>
              </div>
          </div>
        ) : (
          <Estimate data={{ rowData: rowData, backToUser: backToUser }} />
        )}
      </BlockUI>
    </div>
  );
};
