import React, { useLayoutEffect, useState } from "react";
import PettycashServiceService from "../../services/pettycash-service";
import {
  format,
  startOfWeek,
  addDays,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import GV from "../../services/globalVar";
import "../../assets/styles/pettycash.scss";
import { useNavigate } from "react-router";
import { Dialog, Tooltip } from "../PrimeReact";

export default function PettyCashWeek() {
  const pts = new PettycashServiceService();
  const [weekdata, setWeekdata] = useState([]);
  const [dates, setDates] = useState();
  const navigate = useNavigate();
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const [total, setTotal] = useState([]);

  const getDetails = (mon) => {
    const start = startOfWeek(mon, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(mon, { weekStartsOn: 1 });
    console.log(start, endDate, "poo");
    let day = start;
    let weekdaysClone = [];

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const oneday = { id: i, fulldate: day };
        weekdaysClone.push(oneday);
        day = addDays(day, 1);
      }
    }
    let end = weekdaysClone[6].fulldate;
    console.log(start, end);
    const data = {
      TenantID: GV.userDet.tenantDetail.tenantID,
      FromDate: format(start, "yyyy-MM-dd"),
      ToDate: format(end, "yyyy-MM-dd"),
    };
    pts.GetCustomDailyBalance(data).then((res) => {
      let max = 0;
      let totalArray = [];
      res.data.data.forEach((a) => {
        let c = a.transactions
          .map((b) => b.amountPaid)
          .reduce((tot, acc) => tot + acc, 0);
        totalArray = [...totalArray, c];
      });
      setTotal(totalArray);
      res.data.data.forEach((x) => {
        x.count = x.transactions.length;
        if (x.count > max) {
          max = x.count;
        }
      });
      res.data.data.forEach((x) => {
        x.maxcount = max < 3 ? 3 : max;
        x.diff = x.maxcount - x.count;
      });
      setWeekdata(res.data.data);
    });
  };

  const vals = (item) => {
    let content = [];
    for (let i = 0; i < item; i++) {
      content.push(
        <div className="block-empty">
          <p className="mb-0">&nbsp;</p>
          <p className="mb-0">&nbsp;</p>
          <p className="mb-0">&nbsp;</p>
        </div>
      );
    }
    return content;
  };

  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());

  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
      const month = subWeeks(currentMonth, 1);
      getDetails(month);
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
      const month = addWeeks(currentMonth, 1);
      getDetails(month);
    }
  };
  const [pettyDetail, setPettyDetail] = useState("");
  const [visible, setVisible] = useState(false);
  const details = (item) => {
    setVisible(true);
    setPettyDetail(item);
  };
  const backtopetty = () => {
    navigate("/pettycash");
  };
  useLayoutEffect(() => {
    setWeekdata([]);
    getDetails(currentMonth);
  }, []);
  return (
    <div>
      <div className="row mx-auto">
        <div className="col-md-12">
          <div className="row">
            <div className="card card-borderless cc-greyCard-custm">
              <div className="d-flex ms-2 my-2 me-2">
                <div className="col-md-3 col-sm-12 d-flex justify-content-between align-items-center flex-wrap">
                  <h4 className="m-0">Petty Cash Week View</h4>
                </div>
                  <div className="col-md-9 col-sm-12 text-end cc-calen">
                    {/* <Calendar showIcon  value={dates} onChange={(e) => changeDuration(e.value)}  selectionMode="range"  dateFormat="mm/dd/yy"   style={{width:'250px'}}  /> */}
                    <span
                      className="ms-3 border-btn"
                      onClick={() => changeWeekHandle("prev")}
                    >
                      {" "}
                      Previous Week{" "}
                    </span>{" "}
                    <span
                      className="ms-3 border-btn"
                      onClick={() => changeWeekHandle("next")}
                    >
                      {" "}
                      Next Week{" "}
                    </span>
                    <button className="btn btn-danger ms-3" onClick={backtopetty}>
                      Back
                    </button>
                    {/* <Calendar className='cc-calen' value={dates} onChange={(e) => changeDuration(e.value)} selectionMode="range" readOnlyInput  style={{width:'250px'}}/> */}
                    {/* <p className='customFont mb-0'> <span className='cc-fixed'> Week Opening Balance : <b> {GV.userDet.tenantConfig.currency} {pettyDetails?.openingBalance} </b></span>   <span className='ms-3 border-btn'   onClick={() => changeWeekHandle("prev")}> Previous Week </span> <span className='ms-3 border-btn'   onClick={() => changeWeekHandle("next")}> Next Week </span></p> */}
                  </div>
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              {weekdata.length != 0 ? (
                <div className="row mx-auto p-0">
                  {weekdata.map((item) => {
                    return (
                      <>
                        <div className="cc-col-md-2 ">
                          <div className="block-head">
                            <b className="d-flex justify-content-center">
                              {" "}
                              {format(
                                new Date(item.balanceDate),
                               GV.userDet.tenantConfig.dateFormat
                              )}{" "}
                            </b>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              ) : (
                ""
              )}

              {weekdata.length !== 0 ? (
                <div className="row mx-auto p-0">
                  {weekdata.map((item, i) => {
                    return (
                      <>
                        {/* <div className='cc-col-md-2'>
                                                {format(new Date(item.balanceDate),'MM/dd/yyyy')}
                                            </div> */}

                        <div className="cc-col-md-2">
                          {
                            item.transactions.length !== 0 ? (
                              <>
                                {item.transactions.map((x) => {
                                  return (
                                    <div
                                      className="block"
                                      onClick={(e) => details(x)}
                                    >
                                      <Tooltip target=".tooltip" />
                                      <p className="mb-0 shorten ">
                                        {" "}
                                        {x.transaction}
                                      </p>
                                      <p className="mb-0">
                                        <b>Paid To:</b> {x.receiver}
                                      </p>
                                      <p className="mb-0">
                                        <b>Amount:</b> {GV.userDet.tenantConfig.currency+ " " +x.amountPaid.toFixed(2)}
                                      </p>
                                    </div>
                                  );
                                })}
                                {vals(item.diff)}

                                {/* <div className="row" style={{background:"aliceblue"}}>
                                                        <p> Total Amount: {total[i]}</p>
                                                    </div> */}
                              </>
                            ) : (
                              <>
                                {vals(item.diff)}
                                {/* <div className='totalblock' >
                                                      <p> <strong> Total Amount:</strong> {total[i]}</p>
                                                   </div> */}
                              </>
                            )
                            // {item.transactions.length}
                            //  for(let i=0 ;i<item.diff;i++){
                            //         return(
                            //         <div className='block'>

                            //             <p className='mb-0'><b>Transaction:</b> {x.transaction}</p>
                            //             <p className='mb-0'><b>Paid To:</b> {x.receiver}</p>
                            //             <p className='mb-0'><b>Amount:</b> {x.amountPaid}</p>

                            //         </div>
                            //         )
                            //     }
                          }

                          {/* {format(new Date(item.balanceDate),'MM/dd/yyyy')} */}
                        </div>
                      </>
                    );
                  })}
                  <div className="row mx-auto p-0">
                    {total?.map((a) => {
                      return (
                        <div className="cc-col-md-2">
                          <div className="totalblock">
                            <p>
                              {" "}
                              <strong> Total Amount:</strong> {GV.userDet.tenantConfig.currency+ " " +a.toFixed(2)}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Dialog
          header="Petty Details"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => setVisible(false)}
        >
          {pettyDetail !== "" ? (
            <div className="mt-4">
              <p className="m-0">
                {" "}
                <b>Date : </b>{" "}
                {format(new Date(pettyDetail.transactionDate), "MM/dd/yyyy")}
              </p>
              <p className="m-0">
                {" "}
                <b>Transaction : </b> {pettyDetail?.transaction}
              </p>
              <p className="m-0">
                {" "}
                <b>Receiver : </b> {pettyDetail?.receiver}
              </p>
              <p className="m-0">
                {" "}
                <b>Amount : </b> {GV.userDet.tenantConfig.currency+ " " +pettyDetail?.amountPaid.toFixed(2)}
              </p>
              <p className="m-0">
                {" "}
                <b>GST : </b> {pettyDetail?.isGST == false ? "False" : "True"}{" "}
              </p>
              <p className="m-0">
                {" "}
                <b>Mode of Payment : </b> {pettyDetail?.paymentMode}
              </p>
              <p className="m-0">
                {" "}
                <b>Description : </b> {pettyDetail?.description}
              </p>
            </div>
          ) : (
            ""
          )}
        </Dialog>
      </div>
    </div>
  );
}
