import config from '../assets/config';
import { ax } from './base';

export default class SecurityService {
    GetUserTypeRoles(payload){
        return ax.post(`${config.apiUrl}Security/GetUserTypeRoles`,payload);
    }
    GetUserTypeList(payload){
        return ax.post(`${config.apiUrl}Security/GetUserTypeList`,payload);
    }
    GetUserTypeServiceSearch(payload){
        return ax.post(`${config.apiUrl}Security/GetUserTypeServiceSearch`,payload);
    }
    GetAssociateRoleServices(payload){
        return ax.post(`${config.apiUrl}Security/GetAssociateRoleServices`,payload);
    }
    AddRoleServices(payload){
        return ax.post(`${config.apiUrl}Security/AddRoleServices`,payload);
    }
    RemoveRoleService(payload){
        return ax.post(`${config.apiUrl}Security/RemoveRoleService`,payload);
    }
    DeleteUserRole(payload){
        return ax.post(`${config.apiUrl}Security/DeleteUserRole`,payload);
    }
    GetUserTypeServices(payload){
        return ax.post(`${config.apiUrl}Security/GetUserTypeServices`,payload);
    }

    //permission management
    GetEntUserGroupList(payload){
        return ax.post(`${config.apiUrl}Security/GetEntUserGroupList`,payload);
    }
    DeleteUserGroup(payload){
        return ax.post(`${config.apiUrl}Security/DeleteUserGroup`,payload);
    }
    SwitchUserGroupStatus(payload){
        return ax.post(`${config.apiUrl}Security/SwitchUserGroupStatus`,payload);
    }
    CreateUserGroup(payload){
        return ax.post(`${config.apiUrl}Security/CreateUserGroup`,payload);
    }
    GetAssocUserGroupServices(payload){
        return ax.post(`${config.apiUrl}Security/GetAssocUserGroupServices`,payload);
    }
    GetAppUserList(payload){
        return ax.post(`${config.apiUrl}Security/GetAppUserList`,payload);
    }
    AddServiceUserGroup(payload){
        return ax.post(`${config.apiUrl}Security/AddServiceUserGroup`,payload);
    }
    AddUserGroupMember(payload){
        return ax.post(`${config.apiUrl}Security/AddUserGroupMember`,payload);
    }
    GetServiceUserGroup(userGroupID){
        return ax.get(`${config.apiUrl}Security/GetServiceUserGroup/${userGroupID}`);
    }
    DeleteUserGroupMember(userGroupID,userID){
        return ax.delete(`${config.apiUrl}Security/DeleteUserGroupMember/${userGroupID}/${userID}`);
    }
   

    //user account management
    GetAppUserServiceList(AppUserID){
        return ax.get(`${config.apiUrl}Security/GetAppUserServiceList/${AppUserID}`);
    }
    GetUserServiceException(AppUserID){
        return ax.get(`${config.apiUrl}Security/GetUserServiceException/${AppUserID}`);
    }
    AddUserServiceException(payload){
        return ax.post(`${config.apiUrl}Security/AddUserServiceException`,payload);
    }
    CreateUser(payload){
        return ax.post(`${config.apiUrl}Security/CreateUser`,payload);
    }
    GetEntUserGroupMembers(payload){
        return ax.post(`${config.apiUrl}Security/GetEntUserGroupMembers`,payload);
    }
    AddUserGroupMember(payload){
        return ax.post(`${config.apiUrl}Security/AddUserGroupMember`,payload);
    }
    GetUserGroupByRole(payload){
        return ax.post(`${config.apiUrl}Security/GetUserGroupByRole`,payload);
    }
    UpdateUserPassword(payload){
        return ax.post(`${config.apiUrl}Security/UpdateUserPassword`,payload);
    }
    DeleteUserServiceException(entServiceExpID,ProductID){
        return ax.delete(`${config.apiUrl}Security/DeleteUserServiceException/${entServiceExpID}/${ProductID}`);
    }
    UpdateServiceUserGroup(payload){
        return ax.post(`${config.apiUrl}Security/UpdateServiceUserGroup`,payload);
    }
    SwitchUserStatus(payload){
        return ax.post(`${config.apiUrl}Security/SwitchUserStatus`,payload);
    }
    GetRoleByUsers(payload){
        return ax.post(`${config.apiUrl}Security/GetRoleByUsers`,payload);
    }
    GetEntUserGroupByMembers(payload){
        return ax.post(`${config.apiUrl}Security/GetEntUserGroupByMembers`,payload);
    }
    GetUserGroupServices(payload){
        return ax.post(`${config.apiUrl}Security/GetUserGroupServices`,payload);
    }
    
}