import React, { useEffect, useState } from 'react';
// import { TieredMenu } from 'primereact/tieredmenu';
// import { useRouter } from 'next/router';
import '../../assets/styles/layout/leftmenu.scss';
// import type { MenuProps } from 'antd';
import { Button, Menu } from 'antd';
import {iconSet, IcomoonReact} from '../PrimeReact.js'
import GV from '../../services/globalVar';
import { NavLink } from 'react-router-dom';
export default function LeftMenuTwo(props) {
    const [menuItems, setMenuItems] = useState([]);
    // let active = props.data.active;
    // const [open, setOpen] = useState(active);
    // const showMenu = props.data.showMenu;
// type MenuItem = Required<MenuProps>['items'][number];
    
const items= [
    { key: '1', icon: <IcomoonReact iconSet={iconSet}  size={25} icon='inventory'  className='pointer   px-0 menu-logo' />, label: 'Option 1' },
    { key: '2', icon: <IcomoonReact iconSet={iconSet}  size={25} icon='inventory'  className='pointer   px-0 menu-logo' />, label: 'Option 2' },
    { key: '3', icon: <IcomoonReact iconSet={iconSet}  size={25} icon='inventory'  className='pointer   px-0 menu-logo' />, label: 'Option 3' },
    {
      key: 'sub1',
      label: 'Navigation One',
      icon: <IcomoonReact iconSet={iconSet}  size={25} icon='inventory'  className='pointer   px-0 menu-logo' />,
      children: [
        { key: '5', label: 'Option 5' },
        { key: '6', label: 'Option 6' },
        { key: '7', label: 'Option 7' },
        { key: '8', label: 'Option 8' },
      ],
    },
    {
      key: 'sub2',
      label: 'Navigation Two',
      icon: <IcomoonReact iconSet={iconSet}  size={25} icon='inventory'  className='pointer   px-0 menu-logo' />,
      children: [
        { key: '9', label: 'Option 9' },
        { key: '10', label: 'Option 10' },
        {
          key: 'sub3',
          label: 'Submenu',
          children: [
            { key: '11', label: 'Option 11' },
            { key: '12', label: 'Option 12' },
          ],
        },
      ],
    },
  ];
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  async function getMenu() {
    // console.log(GV.userDet.entService,"COA")
    let data = GV.userDet.entService
    let value = [];
    // console.log(data,'dataopp')
    let z=0;
    data.forEach((e,i) => {
        let subdiv = []

        e.subMenu.forEach((x,j)=>{
            const sub =  {
               key: z, 
              //  label: x.serviceName
               label: <NavLink to={`${x.resourceAction}`}  className='d-flec justify-content-center'  style={{fontSize:'13px'}}  key={x.serviceOrder}> {x.serviceName} </NavLink> 
            }
            z = z + 1;
            subdiv.push(sub)
        })
        let label = e.resourceAction != '#' ? 
        <NavLink to={`${e.resourceAction}`}  className='d-flex justify-content-center' style={{fontSize:'12px'}}  key={e.serviceOrder}><br/> {e.serviceName} </NavLink> : 
        <span  style={{fontSize:'12px'}} key={e.serviceOrder}><br/> <span className='d-flex justify-content-center'> {e.serviceName}</span></span> ;
        const val = {
            key: z,  
               label:  label ,
                icon: <span className='d-flex justify-content-center mt-2'><IcomoonReact iconSet={iconSet}  size={25} icon={e.menuIcon}  className='pointer    px-0 menu-logo' /></span>,
               children:  subdiv.length != 0 ? subdiv  : null,
               title:""
                
        }
        z = z + 1;
        value.push(val);
    });
    setMenuItems(value)
  
    console.log(localStorage.getItem('menu'),value,'ss')
}

function selectMenu(val){
  console.log(val)
      localStorage.setItem('menu',val.selectedKeys)
      localStorage.setItem('mainmenu',val.keyPath[1])
}
const [screenwidth, setScreenWidth] = useState(window.innerWidth);
useEffect(() => {
    getMenu();
   const val = localStorage.getItem('menu') === null || localStorage.getItem('menu') === undefined ?  localStorage.setItem('menu',0) : ''

}, []);
    
  return (
    <div className= {screenwidth < 800 ?  'mobile-menu' : null} style={{position:'fixed', boxShadow:"0 4px 4px #00000040"}}>
      {menuItems.length !== 0 ?  
          <Menu className='bx-shadow' style={{height:'1000px',borderRight:'0px solid white'}}
            defaultSelectedKeys={[  localStorage.getItem('menu').toString()]}
            // selectedKeys={['2','7']}
            // defaultOpenKeys={[localStorage.getItem('mainmenu') === null || localStorage.getItem('mainmenu') === undefined ?  localStorage.setItem('mainmenu',0).toString() :  localStorage.getItem('mainmenu').toString() ]}
            mode="inline"
            theme="light"
            inlineCollapsed={collapsed}
            items={menuItems}
            onSelect={e=>selectMenu(e)}
        />
        : ''}
    </div>
  )
}
