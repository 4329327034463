import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import LoginService from "../../services/login";
import "../../assets/styles/login/login.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/img/fulllogo.png";
import LocalStorageService from "../../services/localstorage";
import { useEffect } from "react";
import GV from "../../services/globalVar";
import { useForm, Controller } from "react-hook-form";
import {
  ProgressSpinner,
  Message,
  Messages,
  BlockUI,
  Divider,
  Toast,
} from "../PrimeReact.js";
import { Password } from "primereact/password";
import "primeicons/primeicons.css";

export default function LoginForm() {
  const defaultValues = {
    email: "",
    password: "",
  };

  const defaultValues1 = {
    email1: "",
    password: "",
  };
  const defaultValues2 = {
    setpass: "",
    setconfirmpass: "",
    otp: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm({ mode: "all", defaultValues });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm({ mode: "all", defaultValues1 });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm({ mode: "all", defaultValues2 });

  const navigate = useNavigate();
  const lgs = new LoginService();
  const lss = new LocalStorageService();
  const [passwordShown, setPasswordShown] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [credError, setCredError] = useState(false);
  const [process, setProcess] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(true);
  const [inputEmail, setInputEmail] = useState();
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState("");
  const [showPassMissMatch, setShowPassMissMatch] = useState(false);
  const [showPassMissMatch1, setShowPassMissMatch1] = useState(false);
  const [checkPass, setCheckPass] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const toast = useRef(null);

  //TOGGLE PASSWORD FUNCTION
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  // const navigateToDashboard = () => {
  //   history.push('/layout');
  // }

  //forgetPassWord
  function forgetPass() {
    setCredError(false);
    setIsForgetPassword(false);
    setIsConfirmPassword(true);
  }

  function backToLogin() {
    setIsForgetPassword(false);
    setCredError(false);
  }

  const loginCall = (data) => {
    // e.preventDefault();
    setCredError(false);
    setProcess(true);
    let reqData = {
      emailAddress: data.email,
      password: data.password,
    };
    localStorage.removeItem('menu')
    lgs
      .UserLogin(reqData)
      .then((response) => {
        if (response) {
          console.log(response, "RES");
          localStorage.setItem("token", response.data.data.accessToken);
          localStorage.setItem("lv", response.data.data.refreshToken);
          localStorage.setItem("user", response.data.data.appUserID);
          // navigate('/coa');
          if (response.data.data.appUserID !== 0) {
            lgs.UserProfile(response.data.data.appUserID).then((res) => {
              setProcess(false);
              GV.userDet = res.data.data;
              lss.setItem("userDet", GV.userDet);
              setUserLoggedIn(true);
              GV.userDet.tenantConfig.isConfigCompleted ===  true ? 
              navigate("/pages/dashboard") :   navigate("/organization-profile")
            });
          }
        }
      })
      .catch(function (error) {
        setProcess(false);
        toast.current?.show({
          severity: "error",
          summary: error.response?.data?.message,
        });
        console.log(error.response?.data?.message);
        if (error.response?.data?.message === "Invalid username or password") {
          setCredError(true);
        }
        setUserLoggedIn(false);
      });
  };

  const checkValidMail = (data) => {
    setProcess(true);
    setCredError(false);
    setInputEmail(data.email1);
    let email = {
      EmailAddress: data.email1,
    };
    lgs
      .ForgetPassword(email)
      .then((res) => {
        console.log("check");
        if (res.data.statusCode === 200) {
          console.log(res.data, "resData");
          setShowPassMissMatch(false);
          setProcess(false);
          setIsOtpValid(false);
          setIsForgetPassword(false);
          setIsConfirmPassword(false);
          setCheckPass(true);
        } else {
          setIsEmailValid(true);
        }
      })
      .catch((err) => {
        if (err.response.data.statusCode === 500) {
          setProcess(false);
          setCredError(true);
        }
      });
  };
  const passConfirm = (data) => {
    setProcess(true);
    if (newPassword !== newConfirmPassword) {
      setShowPassMissMatch(true);
      return
    } 

    if (showPassMissMatch1 === true) {
      return
    }

    let datas = {
      emailAddress: inputEmail,
      NewPassword: newPassword,
      ConfirmationCode: data.otp,
    };
    console.log(datas, "datas");
    lgs.ResetPassword(datas).then((res) => {
      if (res.data.data.statusMessage === "Invalid OTP") {
        setProcess(false);
        setIsOtpValid(true);
      } else {
        setProcess(false);
        setIsOtpValid(false);
      }
      if (res.data.data.statusMessage === "Your Password Changed") {
        console.log(toast.current, "toast.current");
        console.log(toast.current.show, "toast.current.show");
        toast.current?.show({
          severity: "success",
          summary: res.data?.data?.statusMessage,
        });
        setProcess(false);
        setIsForgetPassword(true);
        setIsConfirmPassword(false);
        setCheckPass(false);
        reset({
          email: "",
          password: "",
        });
        reset1();
        reset2();
      }
      console.log(res.data.data, "forgetpass");
    }).catch((err) => {
      if(err.response.data.statusCode === 500) {
        setProcess(false);
      }
    });
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPasswordValue(e.target.value);
    if (passwordValue !== e.target.value) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }
  };

  const validatePassword = (password) => {
    const regex =
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/;
    return regex.test(password);
  };

  const handleNewPasswordChange = (e) => {
    const newPassword = e.target.value;
    const isValid = validatePassword(newPassword);
    setShowPassMissMatch1(!isValid);
    setNewPassword(e.target.value);
  };

  // useEffect(() => {
  //   // test()
  //   console.log(newPassword, newConfirmPassword, "NewPassword");
  //   if (newPassword !== newConfirmPassword) {
  //     setShowPassMissMatch(true);
  //   } else {
  //     setShowPassMissMatch(false);
  //   }
  // }, [newConfirmPassword, newPassword]);

  const footer = (
    <>
      <Divider />
      <p className="mt-2">Suggestions</p>
      <ul className="pl-2 ml-2 mt-0 ">
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </>
  );

  return (
    <>
      {/* style={{width: '157px',height: '55px'}} */}
      <BlockUI blocked={process}>
        <Toast ref={toast}></Toast>

        {isForgetPassword && (
          <div className="login-cont p-4 " style={{ background: "#8dc54114" }}>
            <div className="row g-0 pt-5 pb-5">
              {/* <h3 className="text-center text-bolder mt-5">KADAMBA</h3> */}
              <div className="d-flex justify-content-center">
                <img src={logo} alt="" />
              </div>
              {/* <div className='col-md-7 mt-4 image-section'>
          {/* <img src={LoginImage} alt='design' /> */}
              {/* </div> */}
              <div className="col-md-3 mt-5 px-5 ryt mx-auto bg-white shadow-lg cont-login ">
                <div className="right-side mt-5 ">
                  <h2 className="text-center text-bolder">Login</h2>
                  <h5 className="text-center login-subtitle">
                    Access to Successful Business
                  </h5>
                  {/* <div className="header-logo mt-3 mb-4 pt-4"> */}
                  {/* <img className="ms-0 " src={BrandName} alt='brand name' /> */}
                  {/* </div> */}
                  {/* <div className="header-content mb-4">
            
            </div> */}
                  <div className="body-content mt-5">
                    <form>
                      <div className="form-group mb-2 row text-start">
                        <label className="form-control-label mb-1">
                          Email Address
                        </label>
                        <input
                          type="text"
                          placeholder="Email"
                          className=" form-control mx-2 py-2 shadow-none"
                          {...register("email", {
                            required: "Email is Required",
                          })}
                        />
                        <p className="text-danger mb-0">
                          {" "}
                          {errors.email?.message}{" "}
                        </p>
                      </div>
                      <div className="form-group mb-3 row text-start">
                        <label className="form-control-label mb-1">
                          Password
                        </label>
                        <div className="input-group mb-0">
                          <input
                            type={passwordShown ? "text" : "password"}
                            className="form-control py-2 shadow-none"
                            placeholder="Password"
                            aria-label="password"
                            aria-describedby="basic-addon2"
                            {...register("password", {
                              required: "Password is Required",
                            })}
                          />
                          <span
                            className="input-group-text"
                            id="basic-addon2"
                            onClick={() => togglePassword()}
                          >
                            {passwordShown ? (
                              <AiFillEye />
                            ) : (
                              <AiFillEyeInvisible />
                            )}
                          </span>
                        </div>
                        <p className="text-danger mb-0">
                          {" "}
                          {errors.password?.message}{" "}
                        </p>
                        {/* <input
                    type="password"
                    placeholder="Password"
                    className="form-control py-2 mx-2"
                  >
                   
                  </input> */}
                        {/* <input type={passwordShown ? "text" : "password"} autoComplete='new-password' placeholder='Password' className={`form-control p-2 ${errors.password ? "is-invalid" : ""}`} {...register('password', { required: true })} /> */}
                        {/* <img onClick={togglePassword}
                    src={passwordShown ? ToggleEyeIcon : ToggleEyeClosedIcon}
                    alt="show password" style={{ width: '32px', height: '32px' }} />
                  {(errors.password && errors.password.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''} */}
                      </div>
                      <div className="row mb-3 px-1">
                        <div className="form-check ms-2 col-auto">
                          <input
                            className="form-check-input ps-1"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Remember Me
                          </label>
                        </div>
                        <div className="col-auto ms-auto">
                          <button
                            onClick={forgetPass}
                            type="button"
                            style={{
                              backgroundColor: "transparent",
                              color: "var(--bs-link-color)",
                            }}
                          >
                            Forgot Password ?
                          </button>
                        </div>
                      </div>
                      {credError == true ? (
                        <div>
                          <Message
                            severity="error"
                            text="Invalid Credential"
                          ></Message>
                        </div>
                      ) : (
                        ""
                      )}
                      {process == true ? (
                        <div className="login-spin d-flex justify-content-center">
                          {" "}
                          <ProgressSpinner />{" "}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="mb-5">
                        <button
                          className="btn btn-login mt-4 w-100 py-2 "
                          onClick={handleSubmit(loginCall)}
                        >
                          Login
                        </button>
                        {/* <Button type="submit" loading className="btn btn-login text-white mt-4 w-100 py-2" label="Login" onClick={()=> loginCall()} /> */}
                      </div>
                      {/* <Link to='/chartofaccount'>login</Link> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isConfirmPassword && (
          <div className="login-cont p-4 " style={{ background: "#8dc54114" }}>
            <div className="row g-0 pt-5 pb-5">
              <div className="d-flex justify-content-center">
                <img src={logo} alt="" />
              </div>
              <div className="col-md-3 mt-5 px-5 ryt mx-auto bg-white shadow-lg cont-login ">
                <div className="right-side mt-5 ">
                  <h2 className="text-center text-bolder">Forgot Password</h2>
                  <Divider />
                  <p className="mt-4 important-color">
                    Kindly provide the email address associated with your
                    account. You will receive a one-time password (OTP) to reset
                    your password at this address.
                  </p>{" "}
                  <div className="body-content mt-5">
                    <form>
                      <div className="form-group mb-2 row text-start">
                        <label className="form-control-label mb-1">
                          Email Address
                        </label>
                        <input
                          type="text"
                          placeholder="Email"
                          className=" form-control mx-2 py-2 shadow-none"
                          // value={inputValue}
                          // onChange={(event) => handleChange(event)}
                          {...register1("email1", {
                            required: "Email is Required",
                          })}
                        />
                        <p className="text-danger mb-0">
                          {" "}
                          {errors1.email1?.message}{" "}
                        </p>
                      </div>

                      {credError == true ? (
                        <div>
                          <Message
                            severity="error"
                            text="Invalid Credential"
                          ></Message>
                        </div>
                      ) : (
                        ""
                      )}
                      {process == true ? (
                        <div className="login-spin d-flex justify-content-center">
                          {" "}
                          <ProgressSpinner />{" "}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="mb-1">
                        <button
                          className="btn btn-login mt-4 w-100 py-2 "
                          onClick={handleSubmit1(checkValidMail)}
                          type="submit"
                        >
                          Send OTP
                        </button>
                      </div>
                      {isEmailValid && (
                        <p className="text-danger">Invalid Email!</p>
                      )}
                      <Divider />

                      <div className="row mt-3 px-1">
                        <div className="mb-4 ">
                          <button
                            onClick={backToLogin}
                            style={{
                              backgroundColor: "transparent",
                              color: "var(--bs-link-color)",
                            }}
                          >
                            Back to Login
                          </button>
                        </div>
                      </div>
                      {/* <Link to='/chartofaccount'>login</Link> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {checkPass && (
          <div className="login-cont p-4 " style={{ background: "#8dc54114" }}>
            <div className="row g-0 pt-5 pb-5">
              <div className="d-flex justify-content-center">
                <img src={logo} alt="" />
              </div>
              <div
                className="col-md-3 mt-5 px-5 ryt mx-auto bg-white shadow-lg cont-login "
                style={{ width: "34%" }}
              >
                <div className="right-side mt-5 ">
                  <h2 className="text-center text-bolder">
                    Change your Password
                  </h2>
                  <Divider />
                  {/* <div className="mt-4">
                  <p className="text-center important-color m-0">
                    A strong password helps prevent unauthorised access to your
                    account.
                  </p>
                  <p className="important-color m-0">
                    Now you can proceed to change the password for the user
                    account test@kosoft.co
                  </p>
                </div>{" "} */}
                  <div className="body-content mt-5">
                    <form>
                      <div className="form-group mb-2 row text-start passrange">
                        <label className="form-control-label mb-1">
                          New Password
                        </label>
                        <Controller
                          name="setpass"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Password
                              value={field.value}
                              onInput={(e) => {
                                handleNewPasswordChange(e);
                                field.onChange(e.target.value);
                              }}
                              {...register2("setpass", {
                                required: "Field is Required",
                              })}
                              toggleMask
                              footer={footer}
                            />
                          )}
                        />
                        {errors2.setpass && (
                          <p className="text-danger mb-0">
                            {errors2.setpass.message}
                          </p>
                        )}
                        {showPassMissMatch1 && (
                          <p className="text-danger mb-0">
                            Password must include alphabets, numerics, and
                            symbols
                          </p>
                        )}
                      </div>

                      <div className="form-group mb-2 row text-start">
                        <label className="form-control-label mb-1">
                          Confirm New Password
                        </label>
                        <Controller
                          name="setconfirmpass"
                          control={control}
                          rules={{ required: "Field is Required" }}
                          render={({ field }) => (
                            <Password
                              value={field.value}
                              onInput={(e) => {
                                setNewConfirmPassword(e.target.value);
                                field.onChange(e.target.value);
                              }}
                              {...register2("setconfirmpass")}
                              toggleMask
                              feedback={false}
                            />

                           
                          )}
                        />
                        {errors2.setconfirmpass && (
                          <p className="text-danger mb-0">
                            {errors2.setconfirmpass.message}
                          </p>
                        )}
                        {showPassMissMatch && (
                          <p className="text-danger mb-0">Password Missmatch</p>
                        )}
                      </div>

                      <div className="form-group mb-2 row text-start">
                        <label className="form-control-label mb-1">
                          Enter OTP
                        </label>
                        <div
                          className="d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div className="col-sm">
                            <input
                              type="number"
                              placeholder="OTP"
                              className=" form-control py-2 shadow-none"
                              name="otp"
                              style={{ width: "50%" }}
                              {...register2("otp", {
                                required: "Field is Required",
                              })}
                            />
                          </div>
                          <div className="col-sm">
                            <button
                              className="btn btn-login w-100 py-2 "
                              onClick={handleSubmit1(checkValidMail)}
                              type="submit"
                            >
                              Resend OTP
                            </button>
                          </div>
                        </div>
                        <p className="text-danger mb-0">
                          {" "}
                          {errors2.otp?.message}{" "}
                        </p>
                        {isOtpValid && (
                          <p className="text-danger">Invalid OTP</p>
                        )}
                      </div>

                      {credError == true ? (
                        <div>
                          <Message
                            severity="error"
                            text="Invalid Credential"
                          ></Message>
                        </div>
                      ) : (
                        ""
                      )}
                      {process == true ? (
                        <div className="login-spin d-flex justify-content-center">
                          {" "}
                          <ProgressSpinner />{" "}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="mb-4">
                        <button
                          className="btn btn-login mt-4 w-100 py-2 "
                          onClick={handleSubmit2(passConfirm)}
                        >
                          Set Password
                        </button>
                      </div>
                      <Divider />
                      <div className="row mt-3 px-1">
                        <div className="mb-4 ">
                          <button
                            onClick={backToLogin}
                            style={{
                              backgroundColor: "transparent",
                              color: "var(--bs-link-color)",
                            }}
                          >
                            Back to Login
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <div className="login-cont p-4 " style={{ background: "#8dc54114" }}>
          <div className="row g-0 pt-5 pb-5">
            <div className="d-flex justify-content-center">
              <img src={logo} alt="" />
            </div>
            <div
              className="col-md-3 mt-5 px-5 ryt mx-auto bg-white shadow-lg cont-login "
              style={{ width: "34%" }}
            >
              <div className="right-side mt-5 ">
                <i
                  className="pi pi-exclamation-triangle"
                  style={{
                    fontSize: "3.5rem",
                    display: "flex",
                    justifyContent: "center",
                    color: "#FAAD14",
                  }}
                ></i>
                <div className="body-content mt-3 mb-5">
                  <h1 className="text-center"> Link Expired!</h1>
                  <p className="important-color text-center">
                    Go to login page, click forgot password to get the new link
                  </p>
                </div>
                <div className="mb-5 text-center">
                  <button
                    className="btn btn-login w-50 py-2"
                    // onClick={handleSubmit(loginCall)}
                  >
                    Go to Login
                  </button>
                </div>{" "}
              </div>
            </div>
          </div>
        </div> */}
      </BlockUI>
    </>
  );
}
