import config from '../assets/config';
import { ax } from './base';

export default class ContactsService {
    // Customer and Vendor
    
    SaveCustomer(payload){
        return ax.post(`${config.apiUrl}Contact/SaveCustomer`,payload)
    }

    GetContactSearchData(payload){
        return ax.post(`${config.apiUrl}Contact/GetContactSearchData`,payload)
    }

    SaveVendor(payload){
        return ax.post(`${config.apiUrl}Contact/SaveVendor`,payload)
    }

    GetContact(ContactID,TenantID){
        return ax.get(`${config.apiUrl}Contact/GetContact/${ContactID}/${TenantID} `);
    }  
    SwitchStatusCustomer(TenantID,CustomerID){
        return ax.get(`${config.apiUrl}Contact/SwitchStatusCustomer/${TenantID}/${CustomerID} `);
    }  
    SwitchStatusVendor(TenantID,ContactID){
        return ax.get(`${config.apiUrl}Contact/SwitchStatusVendor/${TenantID}/${ContactID} `);
    }  
}