import React, { useLayoutEffect, useRef, useState } from 'react'
import PettycashServiceService from '../../services/pettycash-service';
import GV from '../../services/globalVar';
import { format } from 'date-fns';
import '../../assets/styles/compStyles/Pettycash.scss';
import { Toast,Dropdown } from '../PrimeReact';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

export default function PettyCashMonth() {
    const pts = new PettycashServiceService()
    const toast = useRef(null);
    const [months,getMonths] =useState([{name:'Jan',val:1,selected:false},
    {name:'Feb',val:2,selected:false},
    {name:'Mar',val:3,selected:false},
    {name:'Apr',val:4,selected:false},
    {name:'May',val:5,selected:false},
    {name:'Jun',val:6,selected:false},
    {name:'Jul',val:7,selected:false},
    {name:'Aug',val:8,selected:false},
    {name:'Sep',val:9,selected:false},
    {name:'Oct',val:10,selected:false},
    {name:'Nov',val:11,selected:false},
    {name:'Dec',val:12,selected:false}
   ])
   const weeks = [{day:'Mon',value:'static'},{day:'Tue',value:'static'},{day:'Wed',value:'static'},{day:'Thu',value:'static'},{day:'Fri',value:'static'},{day:'Sat',value:'static'},{day:'Sun',value:'static'}]
   const [monthData,setMonthData] = useState([])
   const [monthSelected,setMonthSelected] = useState()
   const[overall,setOverall]=useState([])
   const [monthDetails,setMonthDetails] = useState()
   const currentYear = new Date().getFullYear(); 
   const allyears = Array.from({ length: 10 }, (_, index) => currentYear - index);
   
   const years = allyears.map((cont,i) => {
    return (
            {
                label: cont,
                value: cont,
                id: i
            }
        )
    });
    const [selectYear,setSelectYear] = useState(+format(new Date(),'yyyy'))
   


    const getMonthData = (val) =>{
        pts.GetMonthTrancationView(GV.userDet.tenantDetail.tenantID,selectYear,val).then(res=>{
            const val = res.data.data.transactions
            setMonthDetails(res.data.data)
            let value = []
            value = [...weeks]
            value =[...value]
            switch(val[0].monthDay){
                    case 'Monday':{
                        break;
                    }
                    case 'Tuesday':{
                        value.push({day:'empty',value:'empty'});
                        break;
                    }
                    case 'Wednesday':{
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        break;
                    }
                    case 'Thursday':{
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        break;
                    }
                    case 'Friday':{
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        break;
                    }
                    case 'Saturday':{
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        break;
                    }
                    case 'Sunday':{
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        value.push({day:'empty',value:'empty'});
                        break;
                    }
                    default:{
                        console.log('none');
                        break;
                    }
            }
           
            value = [...value,...val]

            switch(val[val.length-1].monthDay){
                case 'Monday':{
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    break;
                }
                case 'Tuesday':{
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    break;
                }
                case 'Wednesday':{
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    
                    break;
                }
                case 'Thursday':{
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    break;
                }
                case 'Friday':{
                    value.push({day:'empty',value:'empty'});
                    value.push({day:'empty',value:'empty'});
                    break;
                }
                case 'Saturday':{
                    value.push({day:'empty',value:'empty'});
                    break;
                }
                case 'Sunday':{
                    
                    break;
                }
                default:{
                    console.log('none');
                    break;
                }
        }
           
         let fin = []
            value.forEach(x=>{
                let b = value.splice(0,7);
                fin.push(b)
               
            })
            let finalArray = []
            console.log(fin,'fin');
            const length = fin.length < 6 ? 6 : fin.length
            for(let j=0 ; j<7;j++){
            for(let i=0 ; i<length;i++){
                    if(fin.length === 6){
                        if(j <= fin[i].length){
                            fin[i][j].show = fin[i][j].status === 'Week Closed' || fin[i][j].status === 'Month Closed' || fin[i][j].status === 'Closed' ? true : false;
                            finalArray.push(fin[i][j])
                        }else{
                            finalArray.push({day:'empty',value:'empty'})

                        }
                    }
                    else{
                        // if(i !== 5){
                            if(j < fin[i].length){
                                fin[i][j].show = fin[i][j].status === 'Week Closed' || fin[i][j].status === 'Month Closed' || fin[i][j].status === 'Closed' ? true : false;
                                finalArray.push(fin[i][j])
                            }else{
                                finalArray.push({day:'empty',value:'empty'})

                            }
                          
                        // }
                        // else{
                        //     finalArray.push({day:'empty',value:'empty'})
                        // }
                    }
                }
            }
            setOverall(finalArray)
            setMonthData(val)
       })
    }
    const choosenMonth = (value) =>{
        value.selected = true
        const monthlist = months
        monthlist.forEach(x=>{
            x.val === value.val ? x.selected = true :x.selected = false
        })
        setMonthSelected(value.val)
        getMonthData(value.val)
        getMonths(monthlist)
        console.log(monthlist)
    }
   const  onPageLoad = () =>{
        const date = new Date();
        setMonthSelected(format(date,'MM'))
        getMonthData(format(date,'MM'));
        const monthlist = months
        monthlist.forEach(x=>{
            x.val === +format(date,'MM') ? x.selected = true :x.selected = false
        })
        getMonths(monthlist)
    }


    const accept = () =>{
        console.log(monthDetails.transactions)
        const data = {
            "CloseType" :  "Month" ,
            "CloseDate" :format(new Date(monthDetails.transactions[0].monthDate), 'yyyy-MM-dd')  ,
            "TenantID" : GV.userDet.tenantDetail.tenantID,
            "CreatedBy" : GV.userDet.appUser.appuserID.toString()
        }
        pts.ClosePettyCash(data).then((response) => {
            console.log(response)
            if(response.message === 'Month Closed Successfully'){
                toast.current.show({ severity: 'success', summary: response.message });
            }
        // fetchItemList(page, sizePerPage, searchVal, '', '',new Date(dateSelected));

        }).catch((err)=>{console.log(err)})
    }
    const reject = () =>{
        console.log('no')
        
    }
    const closeMonth = (event) =>{
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure to close the month?',
            icon: 'pi pi-info-circle',
            // acceptClassName: 'p-button-danger',
            accept,
            reject
        });
       
    }

    useLayoutEffect(()=>{
        onPageLoad();
    },[selectYear])
  return (
    <div>
        <Toast ref={toast}></Toast>
        <div className='row'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-body'>
                    <h4>Petty Cash</h4>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-1 mt-2'>
                    <Dropdown value={selectYear} onChange={(e) =>{ setSelectYear(e.value)}} options={years} optionLabel="label" optionValue="value" 
                        placeholder="Select a Year" className="w-full md:w-14rem form-control" />
                    </div>
                    <div className='col-md-11'>
                        <div className='row mt-2'>
                    
                                {
                                        months.map(item =>{
                                            return (
                                                <div className={`col-md-1 `}>
                                                    <div style={{padding:'8px'}} className={`${item.selected ? 'monthSelected':'monthnotselected'}`} onClick={()=>choosenMonth(item)}>
                                                        <div className='d-flex justify-content-center'>
                                                            <p style={{color:'black',fontSize:'14px',marginBottom:'0px',fontWeight:'bold'}} >{item.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                }
                        
                        </div>
                    </div>
                </div>
                
                <div className='card mt-2'>
                    <div className='card-body'>
                        <div className='row mb-4 mt-3'>
                            <div className='col-md-12'>
                                <span className='pc-blue'>Last Month Balance : <b>{monthDetails?.openingBalance}</b></span>
                                <span className='ms-2 pc-green'>Current Month Balance : <b>{monthDetails?.closingBalance}</b></span>

                            </div>
                        </div>
                    <div className='row '>

                        {
                    overall.map((x,i)=>{
                        return(
                            // i%7 == 0 && i!==0 ? <div className='col-md-3'></div>
                                
                                // : 
                                x.value === 'static' || x.value === 'empty'? x.value === 'static' ? 
                                 overall.length <= 42 ?
                                    <div className='col-md-1' style={{marginBottom:'10px'}}>
                                        <div className='daysCard col-md-12' >
                                            <p className='mb-0 dayscardtxt' ><b> {x.day}</b></p>
                                    
                                        </div>   
                                    </div> 
                                 :
                                i%7 === 0 & i != 0  ?
                                  <>
                                           
                                            <div className='col-md-4'></div>
                                            <div className='col-md-1' style={{ marginBottom: '10px' }}>
                                                <div className='daysCard col-md-12'>
                                                    <p className='mb-0 dayscardtxt'><b> {x.day} </b></p>

                                                </div>
                                            </div>
                                        </> 
                                
                                :
                                    <div className='col-md-1' style={{marginBottom:'10px'}}>
                                        <div className='daysCard col-md-12' >
                                            <p className='mb-0 dayscardtxt' ><b> {x.day}</b></p>
                                    
                                        </div>   
                                    </div> 
                                :
                                overall.length <= 42 ? 
                                    <div className="pc-card-dis col-md-2  ms-3" style={{marginBottom:'10px'}}>
                                        <div className="card-inner">
                                    
                                            <div className="pc-card-body-dis">
                                            {/* <p><b>Date : </b> {x.monthDate }</p>
                                                       <p><b>Total Entry :</b>{x.totalRecord}</p>
                                                       <p><b>Status :</b>{x.status}</p> */}
                                                <p>&nbsp;</p>
                                            </div>
                                            <div className="card-footer-dis">
                                                <p>&nbsp;</p>
                                                     {/* Total Amount : {x.expenses} */}
                                            </div>
                                        </div>
                                    </div>    
                                    :
                                    <div className="pc-card-dis col-md-1  ms-3" style={{marginBottom:'10px'}}>
                                        <div className="card-inner">
                                    
                                            <div className="pc-card-body-dis">
                                            {/* <p><b>Date : </b> {x.monthDate }</p>
                                                       <p><b>Total Entry :</b>{x.totalRecord}</p>
                                                       <p><b>Status :</b>{x.status}</p> */}
                                                <p>&nbsp;</p>
                                            </div>
                                            <div className="card-footer-dis">
                                                <p>&nbsp;</p>
                                                     {/* Total Amount : {x.expenses} */}
                                            </div>
                                        </div>
                                    </div>    
                                :
                                overall.length <= 42 ?   
                                    <div className="pc-card col-md-2  ms-3" style={{marginBottom:'10px'}}>
                                        <div className="card-inner">
                                    
                                            <div className="pc-card-body">
                                            <p><b>Date : </b> {format(new Date(x.monthDate),'MM/dd/yyyy') }</p>
                                                       <p><b>Total Entry :</b>{x.totalRecord}</p>
                                                       <p ><b>Status :</b><span className={`${x.show ? 'c-status' : 'a-status'}`}>{x.status}</span></p>

                                            </div>
                                            {/* {`days ${item.isfuture ? 'daysDisable' : ''} ${item.value ? 'daysSelected' : ''}`}  */}
                                            <div className={`card-footer ${x.show ? 'closed' : 'activeweek'} ${ x.status === null || x.status === '' ? 'unknown':''}`}>
                                           <p className='amount'> Total Amount : <b>{x.expenses}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                    : 
                                   
                                    
                                    // <ng-template>
                                    <div className="pc-card col-md-1  ms-3" style={{marginBottom:'10px'}}>
                                        <div className="card-inner">
                                    
                                            <div className="pc-card-body">
                                            <p><b>Date : </b> {format(new Date(x.monthDate),'MM/dd/yyyy') }</p>
                                                    <p><b>Total Entry :</b>{x.totalRecord}</p>
                                                    <p ><b>Status :</b><span className={`${x.show ? 'c-status' : 'a-status'}`}>{x.status}</span></p>

                                            </div>
                                            {/* {`days ${item.isfuture ? 'daysDisable' : ''} ${item.value ? 'daysSelected' : ''}`}  */}
                                            <div className={`card-footer ${x.show ? 'closed' : 'activeweek'} ${ x.status === null || x.status === '' ? 'unknown':''}`}>
                                        <p className='amount'> Total Amount : <b>{x.expenses}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    // {i%7 === 0 ?  <div className='col-md-3'></div>   : ''} 
                                     // </ng-template> 
                                
                          
                            )
                            })
                        }
                            </div>  

                            <ConfirmPopup />
                        <div className='d-flex justify-content-end mt-3'>
                            {monthDetails?.monthCloseButton ?
                                <button className='btn btn-success' onClick={closeMonth} >Close Month</button>  :''}
                        </div>
                       
                    
                            
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
