import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Space, Input, Dropdown, Table, Tag, Select, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ProgressSpinner } from "primereact/progressspinner";
import { DownOutlined } from "@ant-design/icons";
import { render } from "@testing-library/react";
import { Button, Modal } from "antd";
import GlobalVariable from "../../../../client/src/services/globalVar";
import salesService from "../../services/sales-service";
import { DataTable } from "primereact/datatable";
import { format } from "date-fns";
import { Dropdown as Primedropdown } from "primereact/dropdown";
import { Tooltip } from "antd";
import { IcomoonReact, iconSet } from "../PrimeReact";
import { Dialog } from "primereact/dialog";
import { Radio } from "antd";
import { Flex, Progress } from "antd";
import LoanService from "../../services/loanService";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "../PrimeReact";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Divider } from "primereact/divider";

const LoanGrid = () => {
  const toast = useRef(null);
  const ls = new LoanService();
  const [ingredient, setIngredient] = useState("");
  const { Column, ColumnGroup } = Table;
  const [isProcess, setIsProcess] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [first1, setFirst1] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRecoedPymt, setIsRecoedPymt] = useState(false);
  const [isBorrowerHistory, setIsBorrowerHistory] = useState(false);
  const [radioValue, setRadioValue] = useState("employee");
  const [borrowerHist, setBorrowerHist] = useState([]);
  const [borrowerHistView, setBorrowerHistView] = useState([]);
  const [loanGrid, setLoanGrid] = useState();
  const [confgNo, setConfgNo] = useState();
  const [getSummary, setGetSummary] = useState();
  const [percentPaid, setPercentPaid] = useState();
  const [percentPending, setPercentPending] = useState();
  const [recordAmt, setRecordAmt] = useState();
  const [showErr, setShowErr] = useState("");
  const [showDateErr, setShowDateErr] = useState("");
  const items = [
    { id: 1, label: "All", value: "" },
    { id: 2, label: "Active", value: "ACTIVE" },
    { id: 3, label: "Cancelled", value: "CANCELLED" },
    { id: 3, label: "Closed", value: "CLOSED" },
    { id: 4, label: "Overdue", value: "OVERDUE" },
    { id: 5, label: "Paid", value: "PAID" },
  ];
  const [filterGrid, setFilterGrid] = useState(items[0].value);
  const defaultValues = {
    loanId: "",
    borrowerName: "",
    loandate: new Date(),
    mnthinstamnt: "",
    loanAmount: "",
    inststdate: "",
    paymentTerms: "",
    paymentSchedule: "",
    maturityDate: "",
    monthlyDueDate: "",
    employeeCategory: "",
  };

  const {
    register,
    reset,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange", defaultValues });

  const defaultValuesTwo = {
    pymntloandate: "",
    pymntamount: "",
    loanid: "",
    brname: "",
    mnthlyloanamount: "",
  };
  const {
    register: registerSecondForm,
    reset: resetSecondForm,
    control: controlSecondForm,
    getValues: getValuesSecondForm,
    setValue: setValueSecondForm,
    formState: { errors: errorsSecondForm },
    handleSubmit: handleSubmitSecondForm,
  } = useForm({ mode: "onChange", defaultValuesTwo });

  const GetLoanDataSearch = (
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder,
    filterGrid
  ) => {
    setIsProcess(true);
    const data = {
      InputParams: {
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        Status: filterGrid,
      },
      ServerSearchables: {
        draw: 1,
        searchData: searchVal || "",
        orderByColumn: sortName,
        orderBy: sortOrder,
        currentPage: 1,
        pageSize: sizePerPage,
        pageNumber: page,
      },
    };
    ls.GetLoanDataSearch(data).then((res) => {
      setIsProcess(false);
      setLoanGrid(res.data.data.loanDetailList);
      setTotalRecords(res.data.data.serverSearchables.recordsTotal);
    });
  };

  // const onSubmit = (data) => {
  //   console.log(data);
  //   handleOk();
  // };

  const secondForm = (data) => {
    console.log(data);
    handleOk();
  };

  const onCustomPage1 = (event) => {
    setFirst1(event.first);
    const val = event.page + 1;
    const size = event.rows;
    setSizePerPage(size);
    setPage(val);
    GetLoanDataSearch(val, size, searchVal, "", "", "");
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const handleRowClick = (event) => {
    setRowData(event.data);
  };

  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });

  const renderStatusColumn = (rowData) => {
    let status = rowData.status.toUpperCase();
    let statusClass;
    let textColor;
    switch (status) {
      case "OVERDUE":
        statusClass = "rgb(235 162 149 / 58%)";
        break;
      case "ACTIVE":
        statusClass = "rgb(240 233 193)";
        textColor = "black";
        break;
      case "CLOSED":
        statusClass = "rgb(219 219 219)";
        textColor = "black";
        break;
      case "PAID":
        statusClass = "rgb(171 246 171)";
        textColor = "rgb(0 124 0)";

        break;
      case "CANCELLED":
        statusClass = "rgb(255 230 226)";
        textColor = "rgb(255 36 1)";
        break;
      default:
        statusClass = "";
        break;
    }
    return (
      <Tag bordered={false} color={statusClass} style={{ color: textColor }}>
        {status}
      </Tag>
    );
  };

  const renderStatusColumn1 = (rowData) => {
    let status = rowData.status.toUpperCase();
    let statusClass;
    let textColor;

    switch (status) {
      case "OVERDUE":
        statusClass = "rgb(230 187 180)";
        break;
      case "UNPAID":
        statusClass = "rgb(210 210 210)";
        break;
      case "PAID":
        statusClass = "rgb(171 246 171)";
        textColor = "rgb(0 124 0)";

        break;
      case "PARTIALLY PAID":
        statusClass = "#d6d6d6";
        break;
      default:
        statusClass = "";
    }
    return (
      <Tag bordered={false} color={statusClass} style={{ color: textColor }}>
        {status}
      </Tag>
    );
  };

  const { Option } = Select;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    reset();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    reset();
    setValue("loandate", new Date());
    setRadioValue("employee");
  };

  const handleCancelPymnt = () => {
    setIsRecoedPymt(false);
    resetSecondForm();
  };

  const onChange = (e) => {
    const name = e.target.value === 1 ? "employee" : "others";
    setRadioValue(name);
  };

  const proceedPay = (e, items) => {
    console.log(items, "check");
    resetSecondForm({ pymntamount: "" });
    setRecordAmt(items);
    setShowErr("");
    setIsRecoedPymt(true);
    setValueSecondForm("loanid", items.loanCode);
    setValueSecondForm("brname", items.borrowerName);
    setValueSecondForm("mnthlyloanamount", items.installmentAmount.toFixed(2));
  };

  const borrowerHistory = (e, items) => {
    console.log(items, "hjg");
    setBorrowerHist([items]);
    const totalAmount = items.loanAmount.toFixed(2);
    const paidAmount = items.paidAmount.toFixed(2);
    const pendingAmount = totalAmount - paidAmount;

    const percentPaid = (paidAmount / totalAmount) * 100;
    setPercentPaid(percentPaid.toFixed(2));
    const percentPending = (pendingAmount / totalAmount) * 100;
    setPercentPending(percentPending.toFixed(2));
    loanPaymentDetails(items.tenantID, items.loanID, 0);
    setIsBorrowerHistory(true);
  };

  const loanPaymentDetails = (tenent, loanid, paymentloanid) => {
    ls.GetLoanPaymentDetails(tenent, loanid, paymentloanid).then((res) => {
      setBorrowerHistView(res.data.data);
    });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="d-flex">
        <div className="row">
          <div className="col-md d-flex p-0">
            {rowData.balanceAmount !== 0 && rowData.status !== "CANCELLED" ? (
              <Tooltip title="Record Payment">
                <IcomoonReact
                  iconSet={iconSet}
                  color="#3e9d71"
                  size={20}
                  icon="payment"
                  className="pointer mx-2"
                  onClick={(e) => proceedPay(e, rowData)}
                  data-pr-tooltip="Edit"
                  data-pr-position="left"
                />
              </Tooltip>
            ) : (
              <Tooltip title="Record Payment">
                <IcomoonReact
                  iconSet={iconSet}
                  style={{ visibility: "hidden" }}
                  color="#3e9d71"
                  size={20}
                  icon="payment"
                  className="pointer mx-2"
                  data-pr-tooltip="Edit"
                  data-pr-position="left"
                />
              </Tooltip>
            )}

            <Tooltip title="View Details">
              <i
                className="pi pi-eye icon-tooltip me-2"
                icon="view"
                style={{ color: "slateblue", fontSize: "1.3rem" }}
                onClick={(e) => borrowerHistory(e, rowData)}
              ></i>
            </Tooltip>
            {rowData.status === "Active" && (
              <Tooltip title="Cancel Loan">
                <i
                  className="pi pi-times "
                  icon="view"
                  style={{ color: "red", fontSize: "1.1rem" }}
                  onClick={() => confirmDelete(rowData, "CANCELLED")}
                ></i>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    );
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const GenerateConfigNumber = () => {
    const datas = {
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      ServiceName: "Loan",
    };
    ls.GenerateConfigNumber(datas).then((res) => setConfgNo(res.data.data));
  };

  const onSubmit = (data, status) => {
    let datas = {};
    if (status === "Active") {
      const instDate = new Date(data.inststdate);
      const instdate = instDate.toISOString().split("T")[0];
      const mature = new Date(data.maturityDate);
      const maturedate = mature.toISOString().split("T")[0];
      const loan = new Date(data.loandate);
      const loandate = loan.toISOString().split("T")[0];
      const monthly = new Date(data.monthlyDueDate);
      const dayOnly = String(monthly.getDate()).padStart(2, "0");
      setIsProcess(true);
      datas = {
        LoanID: 0,
        LoanCode: confgNo,
        LoanDate: formatDate(new Date(data.loandate)),
        BorrowerID: 0,
        BorrowerName: data.borrowerName,
        BorrowerCategory: "employee",
        Paymentterm: data.paymentTerms,
        PaymentSchedule: "Month",
        LoanAmount: data.loanAmount,
        InstallmentAmount: data.mnthinstamnt,
        InstallmentStartPeriod: formatDate(new Date(data.inststdate)),
        MonthlyDueDate: dayOnly,
        MaturityDate: formatDate(new Date(data.maturityDate)),
        DocID: 0,
        Status: status,
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        CreatedBy: GlobalVariable.userDet.appUser.appuserID.toString(),
      };
    } else if (status === "CANCELLED") {
      const instDate = new Date(data.installmentStartPeriod);
      const instdate = instDate.toISOString().split("T")[0];
      const mature = new Date(data.maturityDate);
      const maturedate = mature.toISOString().split("T")[0];
      const loan = new Date(data.loanDate);
      const loandate = loan.toISOString().split("T")[0];
      const monthly = new Date(data.monthlyDueDate);
      const dayOnly = String(monthly.getDate()).padStart(2, "0");
      setIsProcess(true);
      datas = {
        LoanID: data.loanID,
        LoanCode: confgNo,
        LoanDate: loandate,
        BorrowerID: 0,
        BorrowerName: data.borrowerName,
        BorrowerCategory: radioValue,
        Paymentterm: data.paymentTerm,
        PaymentSchedule: "Month",
        LoanAmount: data.loanAmount,
        InstallmentAmount: data.installmentAmount,
        InstallmentStartPeriod: instdate,
        MonthlyDueDate: data.monthlyDueDate,
        MaturityDate: maturedate,
        DocID: 0,
        Status: status,
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        CreatedBy: GlobalVariable.userDet.appUser.appuserID.toString(),
      };
    }

    console.log(datas, "savte");
    ls.SaveLoanDetails(datas).then((res) => {
      setIsProcess(false);
      toast.current.show({
        severity: "success",
        detail: res.data.message,
      });
      setIsModalOpen(false);
      reset();
      loanSummary();
      GetLoanDataSearch(page, sizePerPage, searchVal, "", "", "");
      GenerateConfigNumber();
    });
  };

  const paymentSave = (data) => {
    setIsProcess(true);
    const instDate = new Date(data.pymntloandate);
    const adjustedDate = new Date(
      instDate.getTime() - instDate.getTimezoneOffset() * 60000
    );
    const instdates = adjustedDate.toISOString().split("T")[0];

    const datas = {
      LoanPaymentID: 0,
      LoanPaymentDate: instdates,
      // LoanPaymentDate: formatDate(new Date(data.pymntloandate)),
      LoanID: recordAmt.loanID,
      LoanCode: recordAmt.loanCode,
      DueAmount: recordAmt.installmentAmount,
      PaidAmount: recordAmt.paidAmount,
      BalanceAmount: recordAmt.balanceAmount,
      PaymentAmount: +data.pymntamount,
      Status: "PAID",
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      CreatedBy: GlobalVariable.userDet.appUser.appuserID.toString(),
    };
    console.log(datas, "datas");
    ls.SaveLoanPaymentDetails(datas).then((res) => {
      setIsProcess(false);
      if (res.data.message === "Payment Failed") {
        toast.current.show({
          severity: "error",
          detail: res.data.message,
        });
      }
      if (res.data.message === "Payment Saved Successfully") {
        toast.current.show({
          severity: "success",
          detail: res.data.message,
        });
      }

      handleCancelPymnt();
      loanSummary();
      GetLoanDataSearch(page, sizePerPage, searchVal, "", "", "");
    });
  };

  const handleLoanAmountChange = (e) => {
    let value = e.target.value;
    setShowErr("");
    if (value.includes("-")) {
      value = value.replace("-", "");
    }
    e.target.value = value;
  };

  const dateFormBodyTemplate1 = (rowData) => {
    return (
      <div>
        {format(
          new Date(rowData?.dueDate),
          GlobalVariable.userDet.tenantConfig.dateFormat
        )}
      </div>
    );
  };

  const dateFormBodyTemplate = (rowData) => {
    return (
      <div>
        {format(
          new Date(rowData.loanDate),
          GlobalVariable.userDet.tenantConfig.dateFormat
        )}
      </div>
    );
  };

  const loanSummary = () => {
    setIsProcess(true);
    ls.GetLoanSumnmary(GlobalVariable.userDet.tenantDetail.tenantID).then(
      (res) => {
        setIsProcess(false);
        setGetSummary(res.data.data);
      }
    );
  };

  const getOrdinalSuffix = (num) => {
    if (num === 1 || num === 21 || num === 31) return `${num}st`;
    if (num === 2 || num === 22) return `${num}nd`;
    if (num === 3 || num === 23) return `${num}rd`;
    return `${num}th`;
  };

  const reject = () => {};

  const confirmDelete = (rowData, status) => {
    console.log(rowData, "rowData");
    let obj = {
      // target: event.currentTarget,
      className: "pAdj deletecircle",
      message: "Are you sure you want to proceed?",
      header: "Loan Cancel Confirmation",
      icon: "pi pi-exclamation-triangle",
      defaultfocus: "accept",
      accept: () => onSubmit(rowData, status),
      reject: () => reject(),
    };
    confirmDialog(obj);
  };

  const loanAmount = watch("loanAmount");
  const paymentTerms = watch("paymentTerms");
  const instaDate = watch("inststdate");

  useEffect(() => {
    if (loanAmount && paymentTerms) {
      const monthlyInstallment = loanAmount / paymentTerms;
      setValue("mnthinstamnt", monthlyInstallment.toFixed(2));
    } else {
      setValue("mnthinstamnt", "");
    }
  }, [loanAmount, paymentTerms, setValue]);

  useEffect(() => {
    if (instaDate && paymentTerms) {
      const termInMonths = parseInt(paymentTerms, 10);
      if (!isNaN(termInMonths)) {
        const maturityDate = new Date(instaDate);
        maturityDate.setMonth(maturityDate.getMonth() + termInMonths);
        setValue("maturityDate", maturityDate);
      }
    }
  }, [instaDate, paymentTerms, setValue]);

  useEffect(() => {
    GenerateConfigNumber();
    loanSummary();
    GetLoanDataSearch(page, sizePerPage, searchVal, "", "", "");
  }, []);

  useEffect(() => {
    GetLoanDataSearch(page, sizePerPage, searchVal, "", "", filterGrid);
  }, [filterGrid]);

  return (
    <div>
      <Toast ref={toast} />
      {!isBorrowerHistory ? (
        <>
          <div className="row mx-auto">
            <div className="card card-borderless cc-greyCard-custm mb-3">
              <div className="ms-2 mt-2 me-2">
                <div className="d-flex justify-content-between flex-wrap align-items-center mb-2">
                  <h4 className="m-0">Employee Loan</h4>
                  <div className=" d-flex justify-content-between kk-table-header kk-header-sec cc-font-size-14">
                    <div className="col ">
                      <Primedropdown
                        value={filterGrid}
                        onChange={(e) => {
                          setFilterGrid(e.value);
                        }}
                        options={items}
                        optionLabel="label"
                        placeholder="Select status to filter"
                        className="col-auto text-start me-3 my-auto"
                      />
                      <Space.Compact className="me-2">
                        <Input
                          addonBefore={<SearchOutlined />}
                          allowClear
                          placeholder="Search"
                          // value={searchVal}
                          // onChange={(e) => keySearch(e)}
                          // onKeyUp={(e) => keySearch(e)}
                        />
                      </Space.Compact>
                    </div>
                    <button
                      className="btn btn-primary py-1 me-2 mx-2 kk-btnThin my-auto"
                      onClick={() => {
                        setIsModalOpen(true);
                        setShowDateErr("");
                      }}
                    >
                      {" "}
                      <i className="pi pi-plus"></i> New Loan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="cc-greyCard-custm  card-borderless mb-3 p-2">
            <p className="mt-1">
              <b>Payment Summary</b>
            </p>
            <div className="row">
              {getSummary?.map((x, i) => {
                let iconColor = "slateblue";

                switch (x.title) {
                  case "Total Outstanding Receivables":
                    iconColor = "slateblue";
                    break;
                  case "Due For Month":
                    iconColor = "green";
                    break;
                  case "Overdue":
                    iconColor = "red";
                    break;
                  default:
                    iconColor = "slateblue";
                }

                return (
                  <div key={i} className="col-md-3">
                    <div className="d-flex ps-2">
                      <i
                        className="pi pi-wallet mt-1"
                        style={{ color: iconColor, fontSize: "1.1rem" }}
                      ></i>
                      <p className="ms-3">
                        {x.title}
                        <br />
                        <b>
                          {GlobalVariable.userDet.tenantConfig.currency}{" "}
                          {x?.amount.toFixed(2)}
                        </b>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <div className="row mx-auto">
          <div className="card card-borderless cc-greyCard-custm mb-3">
            <div className="ms-2 mt-2 me-2">
              <div className="d-flex justify-content-between flex-wrap align-items-center mb-3">
                <h4 className="m-0">Employee Loan</h4>
                <div className="text-end">
                  <button
                    className="btn btn-danger py-1 me-2 mx-2 kk-btnThin my-auto"
                    onClick={() => {
                      setIsBorrowerHistory(false);
                    }}
                  >
                    {" "}
                    <i className=""></i> Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!isBorrowerHistory ? (
        <DataTable
          totalRecords={totalRecords}
          className=" hdeEst"
          tableStyle={{ minWidth: "100%" }}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          lazy
          paginator
          loading={isProcess}
          first={first1}
          rows={sizePerPage}
          onPage={onCustomPage1}
          onSort={onSort}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          rowsPerPageOptions={[10, 20, 50, totalRecords]}
          onRowClick={handleRowClick}
          value={loanGrid}
          emptyMessage="No data available"
        >
          <Column field="loanCode" header="Loan ID" />
          <Column
            field="loanPaymentDate"
            header="Loan Date"
            body={dateFormBodyTemplate}
          />
          <Column field="borrowerName" header="Borrower" />
          <Column field="paymentTerm" header="Payment Terms" />
          <Column
            field="loanAmount"
            className="text-end amount-align"
            header="Loan Amount"
            body={(a) =>
              GlobalVariable.userDet.tenantConfig.currency +
              " " +
              a.loanAmount.toFixed(2)
            }
          />
          <Column
            field="balanceAmount"
            className="text-end amount-align"
            header="Balance Amount"
            body={(a) =>
              GlobalVariable.userDet.tenantConfig.currency +
              " " +
              a.balanceAmount.toFixed(2)
            }
          />
          <Column
            field="installmentAmount"
            className="text-end amount-align"
            header="Installment Amount"
            body={(a) =>
              GlobalVariable.userDet.tenantConfig.currency +
              " " +
              a.installmentAmount.toFixed(2)
            }
          />
          <Column
            field="statusBodyTemplate"
            header="Status"
            className="action"
            body={(e) => renderStatusColumn(e)}
          ></Column>
          <Column
            body={actionBodyTemplate}
            header="Action"
            field="actionBodyTemplate"
          />
        </DataTable>
      ) : (
        <div>
          {borrowerHist?.map((loan, index) => (
            <div
              key={index}
              className="card card-borderless  my-3"
              style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
            >
              <div className="d-flex p-3">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md d-flex">
                      <div className="d-flex align-items-center">
                        <span className="fw-bold fs-4">
                          {borrowerHist[0]?.borrowerName}
                        </span>{" "}
                      </div>
                    </div>
                    <div className="col-md"></div>

                    <div className="col-md">
                      <div className="">
                        <div>
                          <span className="fw-bold">Loan Amount</span>{" "}
                        </div>
                        <div>
                          <span>
                            {GlobalVariable.userDet.tenantConfig.currency +
                              " " +
                              borrowerHist[0]?.loanAmount?.toFixed(2)}
                          </span>
                        </div>
                        <div>
                          {" "}
                          <Flex vertical>
                            <Flex vertical gap="small" style={{ width: 300 }}>
                              {/* <Progress
                              percent={100}
                              size="small"
                              strokeColor={"#69e069"}
                            /> */}
                            </Flex>
                          </Flex>
                        </div>
                      </div>
                    </div>

                    <div className="col-md">
                      <div>
                        <span className="fw-bold">Pending</span>{" "}
                      </div>
                      <div>
                        <span>
                          {GlobalVariable.userDet.tenantConfig.currency +
                            " " +
                            borrowerHist[0]?.balanceAmount?.toFixed(2)}
                        </span>
                      </div>
                      <div>
                        {" "}
                        <Flex vertical>
                          <Flex vertical gap="small" style={{ width: 300 }}>
                            <Progress
                              percent={percentPending}
                              size="small"
                              strokeColor={"#ff6363"}
                            />
                          </Flex>
                        </Flex>
                      </div>
                    </div>

                    <div className="col-md">
                      <div>
                        <span className="fw-bold">Paid</span>{" "}
                      </div>
                      <div>
                        <span>
                          {GlobalVariable.userDet.tenantConfig.currency +
                            " " +
                            borrowerHist[0]?.paidAmount?.toFixed(2)}
                        </span>
                      </div>
                      <div>
                        {" "}
                        <Flex vertical>
                          <Flex vertical gap="small" style={{ width: 300 }}>
                            <Progress
                              percent={percentPaid}
                              size="small"
                              strokeColor={"#00d800"}
                            />
                          </Flex>
                        </Flex>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex p-3 mt-4">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md">
                      <span className="fw-bold ">Loan ID:</span>{" "}
                      <span>{loan.loanCode}</span>
                    </div>
                    <div className="col-md">
                      <span className="fw-bold">Borrower Name:</span>{" "}
                      <span>{loan?.borrowerName}</span>
                    </div>
                    <div className="col-md">
                      <span className="fw-bold">Loan Date:</span>{" "}
                      <span>
                        {new Date(loan?.loanDate).toISOString().split("T")[0]}
                      </span>
                    </div>
                    <div className="col-md">
                      <span className="fw-bold">Payment Schedule:</span>{" "}
                      <span>{loan?.paymentSchedule}</span>
                    </div>
                    <div className="col-md">
                      <span className="fw-bold"> Due Date:</span>{" "}
                      <span>
                        {"Every Month" +
                          " " +
                          getOrdinalSuffix(loan?.monthlyDueDate)}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md">
                      <span className="fw-bold">Maturity Date:</span>{" "}
                      <span>
                        {
                          new Date(loan?.maturityDate)
                            .toISOString()
                            .split("T")[0]
                        }
                      </span>
                    </div>
                    <div className="col-md">
                      <span className="fw-bold">Payment Terms:</span>{" "}
                      <span>{loan?.paymentTerm + " " + "months"}</span>
                    </div>
                    <div className="col-md">
                      <span className="fw-bold">Loan Amount:</span>{" "}
                      <span>
                        {GlobalVariable.userDet.tenantConfig.currency +
                          " " +
                          loan?.loanAmount.toFixed(2)}
                      </span>
                    </div>

                    <div className="col-md">
                      <span className="fw-bold">Installment Amount:</span>{" "}
                      <span>
                        {GlobalVariable.userDet.tenantConfig.currency +
                          " " +
                          loan?.installmentAmount.toFixed(2)}
                      </span>
                    </div>
                    {/* <div className="col-md">
                        <span className="fw-bold">
                          Balance Amount:
                        </span>{" "}
                        <span>
                          {GlobalVariable.userDet.tenantConfig.currency +
                            " " +
                            loan?.balanceAmount.toFixed(2)}
                        </span>
                      </div> */}
                    <div className="col-md">
                      <span className="fw-bold">Category:</span>{" "}
                      <span>{loan?.borrowerCategory}</span>
                    </div>
                  </div>
                </div>
              </div>
              
              <Divider className="ms-5" style={{ width: "94%" }} />
              

              <DataTable
            className="chofacc cust-heightp py-3 px-2"
            value={borrowerHistView}
            // responsiveLayout="scroll"
            size="small"
            loading={isProcess}
            // paginator
            lazy
            // totalRecords={totalRecords}
            // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            // currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            // rowsPerPageOptions={[10, 20, 50, totalRecords]}
            first={first1}
            rows={sizePerPage}
            onPage={onCustomPage1}
            onSort={onSort}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onRowClick={handleRowClick}
            style={{ minHeight: "400PX" }}
          >
            <Column field="loanCode" header="Loan ID" />
            <Column
              field="dateFormBodyTemplate1"
              header="Loan Date"
              body={dateFormBodyTemplate1}
            />
            <Column
              field="dueAmount"
              className="text-end amount-align"
              header="Installment Amount"
              body={(a) =>
                GlobalVariable.userDet.tenantConfig.currency +
                " " +
                a?.dueAmount.toFixed(2)
              }
            />
            <Column
              field="paidAmount"
              className="text-end amount-align"
              header="Payment Recived"
              body={(a) =>
                GlobalVariable.userDet.tenantConfig.currency +
                " " +
                a?.paidAmount.toFixed(2)
              }
            />
            <Column
              field="paymentAmount"
              className="text-end amount-align"
              header="Monthly Paid Amount"
              body={(a) =>
                GlobalVariable.userDet.tenantConfig.currency +
                " " +
                a?.paymentAmount.toFixed(2)
              }
            />
            <Column
              field="paymentBalanceAmount"
              className="text-end amount-align"
              header="Balance Amount"
              body={(a) =>
                GlobalVariable.userDet.tenantConfig.currency +
                " " +
                a?.paymentBalanceAmount.toFixed(2)
              }
            />
            <Column
              field="status"
              header="Status"
              body={(e) => renderStatusColumn1(e)}
            />
          </DataTable>
            </div>
          ))}
          
        </div>
      )}
      <ConfirmDialog />

      <Modal
        title="Add New Loan"
        open={isModalOpen}
        onOk={handleSubmit((data) => onSubmit(data, "Active"))}
        onCancel={handleCancel}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="col-md d-flex justify-content-end text-end">
            <div className="me-2">
              <RadioButton
                name="employee"
                value="employee"
                onChange={(e) => setRadioValue(e.target.value)}
                checked={radioValue === "employee"}
              />
              <label className="ml-2">Employee</label>
            </div>
            <div>
              <RadioButton
                name="other"
                value="other"
                onChange={(e) => setRadioValue(e.target.value)}
                checked={radioValue === "other"}
              />
              <label className="ml-2">Other</label>
            </div>
          </div> */}

          <div className="col-md d-flex justify-content-between align-items-center">
            <label className="">Loan ID</label>
            <input
              style={{ height: "2.2rem" }}
              defaultValue={confgNo}
              disabled
              className="form-control p-0 mt-2 w-50 p-1"
            />
          </div>
          {errors.loanId && (
            <p className="text-danger text-end mb-0">This field is required</p>
          )}

          <div className="d-flex justify-content-between align-items-center">
            <label className="required">Loan Date</label>
            <Controller
              name="loandate"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Calendar
                  style={{ height: "2.2rem" }}
                  {...field}
                  // value={field.value || ""}
                  placeholder="select loan date"
                  className="form-control p-0 mt-2 w-50"
                  defaultValue={new Date()}
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
            />
          </div>
          {errors.loandate && (
            <p className="text-danger text-end mb-0">This field is required</p>
          )}

          <div className="d-flex justify-content-between align-items-center">
            <label className="required">Borrower Name</label>
            <input
              style={{ height: "2.2rem" }}
              className="form-control p-0 mt-2 w-50 p-1"
              {...register("borrowerName", {
                required: true,
              })}
            />
          </div>
          {errors.borrowerName && (
            <p className="text-danger text-end mb-0">This field is required</p>
          )}

          <div className="d-flex justify-content-between align-items-center">
            <label className="required">Loan Amount</label>
            <input
              type="number"
              style={{ height: "2.2rem" }}
              className="form-control p-0 mt-2 w-50 p-1"
              {...register("loanAmount", {
                required: "Loan Amount is required",
              })}
            />
          </div>
          {errors.loanAmount && (
            <p className="text-danger text-end mb-0">This field is required</p>
          )}

          <div className="d-flex justify-content-between align-items-center">
            <label className="required">Payment Term</label>
            <input
              style={{ height: "2.2rem" }}
              className="form-control p-0 mt-2 w-50 p-1"
              {...register("paymentTerms", {
                required: "Payment Terms are required",
              })}
            />
          </div>
          {errors.paymentTerms && (
            <p className="text-danger text-end mb-0">This field is required</p>
          )}

          <div className="d-flex justify-content-between align-items-center">
            <label className="">Monthly Installment Amount</label>
            <input
              type="number"
              disabled
              style={{ height: "2.2rem" }}
              className="form-control p-0 mt-2 w-50 p-1"
              {...register("mnthinstamnt", {
                required: false,
              })}
            />
          </div>
          {errors.mnthinstamnt && (
            <p className="text-danger text-end mb-0">This field is required</p>
          )}

          <div className="d-flex justify-content-between align-items-center">
            <label className="required">Monthly Due Date</label>

            <Controller
              name="monthlyDueDate"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Calendar
                  {...field}
                  // value={field.value || ""}
                  style={{ height: "2.2rem" }}
                  placeholder="choose date between 1 to 28"
                  className="form-control p-0 mt-2 w-50"
                  onChange={(e) => {
                    setShowDateErr("");
                    const selectedDate = e.value;
                    const day = selectedDate.getDate();
                    if (day >= 1 && day <= 28) {
                      field.onChange(selectedDate);
                    } else {
                      field.onChange(null);
                      setShowDateErr("Please select a date between 1 to 28.");
                    }
                  }}
                />
              )}
            />
          </div>
          {errors.monthlyDueDate && (
            <p className="text-danger text-end mb-0">This field is required</p>
          )}
          <p className="text-danger text-end mb-0">{showDateErr}</p>

          <div className="d-flex justify-content-between align-items-center">
            <label className="required">Installment Start Date</label>
            <Controller
              name="inststdate"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Calendar
                  style={{ height: "2.2rem" }}
                  {...field}
                  // value={field.value || ""}
                  // view="month"
                  placeholder="select date"
                  className="form-control p-0 mt-2 w-50"
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
            />
          </div>
          {errors.inststdate && (
            <p className="text-danger text-end mb-0">This field is required</p>
          )}

          <div className="d-flex justify-content-between align-items-center">
            <label className="">Installment End Date</label>
            <Controller
              name="maturityDate"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Calendar
                  style={{ height: "2.2rem" }}
                  {...field}
                  value={field.value || ""}
                  disabled
                  placeholder="select Installment Start Date"
                  className="form-control p-0 mt-2 w-50"
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
            />
          </div>
          {errors.maturityDate && (
            <p className="text-danger text-end mb-0">This field is required</p>
          )}
        </form>
      </Modal>

      <Modal
        title="Record Payment"
        open={isRecoedPymt}
        onOk={handleSubmitSecondForm(paymentSave)}
        onCancel={handleCancelPymnt}
      >
        <div className="d-flex justify-content-between align-items-center">
          <label className="">Loan ID</label>
          <input
            style={{ height: "2.2rem" }}
            className="form-control p-0 mt-2 w-50 p-1"
            {...registerSecondForm("loanid", { required: false })}
            disabled
          />
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <label className="">Name</label>
          <input
            style={{ height: "2.2rem" }}
            className="form-control p-0 mt-2 w-50 p-1"
            {...registerSecondForm("brname", { required: false })}
            disabled
          />
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <label className="">Monthly Installment Amount</label>
          <input
            style={{ height: "2.2rem" }}
            className="form-control p-0 mt-2 w-50 p-1"
            {...registerSecondForm("mnthlyloanamount", { required: false })}
            disabled
          />
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <label className="required">Loan Date</label>
          <Controller
            name="pymntloandate"
            control={controlSecondForm}
            rules={{ required: true }}
            render={({ field }) => (
              <DatePicker
                style={{ height: "2.2rem" }}
                // maxDate={new Date()}
                {...field}
                className="form-control p-0 mt-2 w-50 p-1"
                onChange={(date) => field.onChange(date)}
              />
            )}
          />
        </div>
        {errorsSecondForm.pymntloandate && (
          <p className="text-danger text-end mb-0">This field is required</p>
        )}

        <div className="d-flex justify-content-between align-items-center">
          <label className="required">Loan Amount</label>
          <input
            type="number"
            style={{ height: "2.2rem" }}
            className="form-control p-0 mt-2 w-50 p-1"
            {...registerSecondForm("pymntamount", { required: true })}
            onChange={handleLoanAmountChange}
          />
        </div>
        {errorsSecondForm.pymntamount && (
          <p className="text-danger text-end mb-0">This field is required</p>
        )}
        {<p className="text-danger text-end mb-0">{showErr}</p>}
      </Modal>
    </div>
  );
};

export default LoanGrid;
