import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
// import "rsuite/dist/rsuite.css";
import '../../assets/styles/accountant/journal.scss';
import JournalService from '../../services/journal';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
import { FcInfo } from 'react-icons/fc';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import GV from '../../services/globalVar';
import { BlockUI } from 'primereact/blockui';
// import { Drawer} from 'rsuite';

// import Drawer from 'react-drag-drawer';

const NewJournal = forwardRef((props, ref) => {
    const history = useNavigate();
    const location = useLocation();
    const jor = new JournalService();
    const [drawer, setDrawer] = useState(false);
    const [date, setDate] = useState("");
    const [smDate, setSmDate] = useState("single");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [disabled, setDisabled] = useState(true);
    const {
        register,
        handleSubmit, reset, control,
        formState: { errors },
    } = useForm();
    const [journalNumber, setJournalNumber] = useState([]);
    const [description, setDescription] = useState("");
    const [contactData, setContactData] = useState([]);
    const [accountData, setAccountData] = useState([{ label: "", value: "", id: "" }]);
    const [transactionId, setTransactionId] = useState(0);
    const [transactionData, setTransactionData] = useState("");
    const [isMounted, setIsMounted] = useState(true);
    const [tdataLoaded, setTdataLoaded] = useState(true);
    const [dates2, setDates2] = useState("");
    const [entries, setEntries] = useState(0);
    const [debitTotal, setDebitTotal] = useState(0);
    const [creditTotal, setCreditTotal] = useState(0);
    const [differenceDTotal, setDifferenceDTotal] = useState(0);
    const [differenceCTotal, setDifferenceCTotal] = useState(0);
    const [subCategory, setSubCategory] = useState([]);
    const [tableValidation, setTableValidation] = useState(null);
    const [dateValidation, setDateValidation] = useState(null);
    const [journals, setJournals] = useState([]);
    const [journalData, setJournalData] = useState([]);
    const [rowsData, setRowsData] = useState([]);
    const [blockedDocument, setBlockedDocument] = useState(true);
    const [oddEven, setOddEven] = useState(true);
    const toast = useRef(null);
    const [findIndex, setFindIndex] = useState(null);
    const currency = [
        { label: 'INR-Indian Rupee', value: 'INR' },
        { label: 'Dollar', value: 'Dlr' },
        { label: 'Euro', value: 'Ero' },
        { label: 'Soil', value: 'Sol' },
    ];
    // console.log("Jid",location.state.data);
    // console.log('debtot',debitTotal);
    // console.log('crdtot',debitTotal);
    // console.log('status',journals.status);

    useEffect(() => {
        if (blockedDocument) {
            setTimeout(() => {
                setBlockedDocument(false);
            }, Math.random() * 1000 + 250);
        }
    }, [])

    const blockDocument = () => {
        setBlockedDocument(false);
    }

    function getJournals() {

        let reqData = {
            "JournalID": location.state.id || 0,
            "TenantID": GV.userDet.tenantDetail.tenantID,
            "Status": "All",
            "Period": "All",
            "FromDate": "",
            "ToDate": ""
        }

        jor.GetJournalData(reqData)
            .then((response) => {
                if (isMounted) {
                    if (response.data) {
                        if (response.data.statusCode === 200) {
                            setTdataLoaded(true);
                            setJournalData(response.data.data);
                            let entries = response.data.data.entries;
                            let journal = response.data.data.journal[0];
                            setJournals(journal);
                            let data = [];
                            entries.forEach((items) => {

                                // dates, account, description, voucherno, contact, debit, credit
                                rowsData.push({
                                    transId: items.entryGroupID,
                                    color: items.entryGroupID % 2 == 0? "even":"odd",
                                    entryId: items.entryID,
                                    dates: format(new Date(items.entryDate), 'MM-dd-yyyy'),
                                    accountID: items.accountID,
                                    account: items.accountName,
                                    accountType: items.accountType,
                                    accountCategory:items.accountCategory,
                                    description: items.description,
                                    voucherno: items.voucherNo,
                                    contactID: items.contactID,
                                    contact: items.contactName,
                                    credit: parseInt(items.credits, 10) || 0,
                                    debit: parseInt(items.debits, 10) || 0
                                });
                            });

                            // console.log('journal,',journal);
                            if (journal.dateType == "Single Date") {
                                setSmDate("single");
                            } else {
                                setSmDate("multiple");
                            }
                            setDate(format(new Date(journal.journalDate), "MM-dd-yyyy"));
                            setDebitTotal(journal.totalDebits);
                            setJournalNumber(journal.journalNumber);
                            setCreditTotal(journal.totalCredits);
                            setEntries(journal.totalEntries);
                            // setTransactionId(journal.totalEntries);
                            setDescription(journal.note);
                        } else {
                            setTdataLoaded(false);
                        }
                    }
                }
            }).catch(function (error) {
            })
    }
   
    useEffect(() => {
        if (location.state.id != 0 && location.state.id != null) {
            getJournals();
            return () => { setIsMounted(false); }
        } else {
            setRowsData([{
                transId: transactionId,
                dates: "",
                account: "",
                accountType:"",
                accountCategory:"",
                description: '',
                voucherno: '',
                contact: '',
                debit: '',
                credit: '',
                accountID: "",
                contactID: '',
                color: "even"
            }])
        }

    }, []);
  
    function getJournal() {
        let tenid = GV.userDet.tenantDetail.tenantID;
        jor.GetJournalNumber(tenid)
            .then((response) => {
                if (isMounted) {
                    if (response.data) {
                        if (response.data.statusCode === 200) {

                            setTdataLoaded(true);
                            setJournalNumber(response.data.data);
                        } else {
                            setTdataLoaded(false);
                        }
                    }
                }
            }).catch(function (error) {

            })
    }
    useEffect(() => {
        if (location.state.id == null) {
            getJournal();
        }
        return () => { setIsMounted(false); }
    }, []);


    function getAccount() {
        let id = 0;
        let tenid = GV.userDet.tenantDetail.tenantID;
        let status = "Active";
        let type = "All";
        jor.GetMasCOA(id, tenid, status, type)
            .then((response) => {
                if (isMounted) {
                    let b = [];

                    if (response.data) {
                        if (response.data.statusCode === 200) {
                            setTdataLoaded(true);
                            setAccountData(response.data.data);
                            response.data.data.map((acc) => {
                                acc.category.map((sub) => {
                                    return b.push(sub);
                                })
                                acc.subCategory.map((sub) => {
                                    return b.push(sub);
                                })
                            });
                            setSubCategory(b);
                        } else {
                            setTdataLoaded(false);
                        }
                    }
                }
            }).catch(function (error) {

            })
    }

    useEffect(() => {
        getAccount();
        return () => { setIsMounted(false); }
    }, []);

    const groupedItemTemplate = (option) => {
        return (
            <div >
                <div className=''>{option.label}</div>
            </div>
        );
    }


    const accountOptions = accountData.map((acc) => {
        return (
            {
                label: acc.type,
                code: acc.type,

                items: subCategory.filter(sub => sub.coaType == acc.type).map(s => {
                    return (
                        {
                            label: s.coaName,
                            value: s.coaName,
                            id: s.coaMasID,
                            category:s.coaCategory,
                            type:s.coaType
                        }
                    )
                })
            })

    });
// console.log("accOption",accountOptions);

    function getContact() {
        let reqData = {
            "TenantID": GV.userDet.tenantDetail.tenantID,
            "ContactType": "All",
            "CustomerType": "All",
            "Status": "All"
        }
        jor.GetContact(reqData)
            .then((response) => {
                if (isMounted) {
                    if (response.data) {
                        if (response.data.statusCode === 200) {
                            setTdataLoaded(true);
                            setContactData(response.data.data);
                        } else {
                            setTdataLoaded(false);
                        }
                    }
                }
            }).catch(function (error) {
            })
    }
    useEffect(() => {
        getContact();
        return () => { setIsMounted(false); }
    }, []);
    const contactOptions = contactData.map((cont) => {
        return (
            {
                label: cont.displayName,
                value: cont.displayName,
                id: cont.contactID
            }
        )
    })



    function deleteTransaction(val) {
        let reqData = {
            "JournalID": location.state.id || journalNumber,
            "TenantID": GV.userDet.tenantDetail.tenantID,
            "EntryID": val,
            "Status": "Draft"
        }
        jor.DeleteJournalTransaction(reqData)
            .then((response) => {
                if (isMounted) {
                    if (response.data) {
                        if (response.data.statusCode === 200) {
                            setTdataLoaded(true);
                            setTransactionData(response.data.data);

                        } else {
                            setTdataLoaded(false);
                        }
                    }
                }
            }).catch(function (error) {
            })
    }

    const accept = () => {
        onSubmit1("Published");
        reset();
        setRowsData([{
            transId: transactionId,
            dates: "",
            account: "",
            accountType:"",
            accountCategory:"",
            description: '',
            voucherno: '',
            contact: "",
            debit: '',
            credit: '',
            accountID: '',
            contactID: ''
        }]);
        getJournal();
        setDescription("");
        setDebitTotal("");
        setCreditTotal("");
        setTransactionId("");
        // setEntries("");
        setDifferenceDTotal("");
        setDifferenceCTotal("");
        setSmDate("single");
        setDate("");
        setTableValidation("");
        toast.current.show({ severity: 'success', summary: "", detail: 'Successfully added Journal Entry' });
        setTimeout(() => { history('/journal') }, 1000);
    }

    const reject = () => {
        onSubmit1("Draft");
        reset();
        setRowsData([{
            transId: transactionId,
            dates: "",
            account: "",
            accountType:"",
            accountCategory:"",
            description: '',
            voucherno: '',
            contact: "",
            debit: '',
            credit: '',
            accountID: '',
            contactID: '',
        }]);
        getJournal();
        setDescription("");
        setDebitTotal("");
        setCreditTotal("");
        setTransactionId("");
        // setEntries("");
        setDifferenceDTotal("");
        setDifferenceCTotal("");
        setSmDate("single");
        setDate("");
        setTableValidation("");
        setTimeout(() => { history('/journal') }, 1000);
    }

    function confirmPublished() {
        validation();
        let deb = debitTotal;
        let crd = creditTotal;

        if (deb === 0 && crd === 0) {
            toast.current.show({ severity: 'error', summary: "", detail: 'Debits and Credits should be equal' });
        }
        else if (deb !== crd) {
            toast.current.show({ severity: 'error', summary: "", detail: 'Debits and Credits should be equal' });
        } 
        else { 
            confirmDialog({
                message: 'Are you sure move to Ledger?',
                header: 'Move to Ledger ',
                icon: 'pi pi-exclamation-triangle',
                accept,
                reject
            });
        }
    };

    const handleDateChange = (date) => {
        setDate(format(new Date(date), 'MM-dd-yyyy'));
    };

    function handleRangeDate(e) {
        setStartDate(e.target.value[0]);
        setEndDate(e.target.value[1]);
        setDates2(e.target.value);
    }

    const validation = () => {
        if (smDate == "single" && date == "") {
            setDateValidation('This field is required');
        } else {
            setDateValidation("");
        }
    }

    const addTableRow = (index, val) => {
        const rowsInput = {
            transId: val.transId,
            dates: "",
            account: "",
            accountType:"",
            accountCategory:"",
            description: '',
            voucherno: '',
            contact: "",
            debit: '',
            credit: '',
            accountID: '',
            contactID: '',
            color: val.color
        }
        setFindIndex(findIndex + 1);
        // const data=[...rowsData];
        rowsData.splice(index + 1, 0, rowsInput);
        setRowsData([...rowsData]);
        // setRowsData([...data,rowsData.splice(index+1,0,rowsInput)]);
    }

    const addtTableRows = () => {
        // validation();
        // if(rowsData.length=0){
        //     const rowsInput = {
        //         transId: transactionId ,
        //         dates: "",
        //         account: "",
        //         description: '',
        //         voucherno: '',
        //         contact: "",
        //         debit: '',
        //         credit: '',
        //         accountID: '',
        //         contactID: '',
        //         color: "even"
        //     }
        //     setRowsData([...rowsData, rowsInput]);
        // }else{

        validation("");
        for (let i = rowsData.length - 1; i > -1; i++) {
            if ( rowsData[i].account == "" || rowsData[i].contact == "" || (rowsData[i].credit == "" && rowsData[i].debit == "")) {
                // alert("please fill mandatory fields");
                if (rowsData[i].account == "") {
                    setTableValidation("Account is required");
                } else if (rowsData[i].contact == "") {
                    setTableValidation("Contact is required"
                    )
                } else {
                    setTableValidation("Debit or Credit is required");
                }
                break;
            } else {
                setTableValidation("");
                let deb = 0, crd = 0;
                rowsData.forEach(x => {
                    deb = deb + Number(x.debit);
                    crd = crd + Number(x.credit);
                })
                let colorStatus = "even";
                if (deb == crd) {
                    setTransactionId(transactionId + 1);
                    // setEntries(entries + 1);
                    if ((transactionId + 1) % 2 == 0) {
                        colorStatus = "even"
                    }
                    else {
                        colorStatus = "odd"
                    }
                    const rowsInput = {
                        transId: transactionId + 1,
                        dates: "",
                        account: "",
                        accountType:"",
                        accountCategory:"",
                        description: '',
                        voucherno: '',
                        contact: "",
                        debit: '',
                        credit: '',
                        accountID: '',
                        contactID: '',
                        color: colorStatus
                    }
                    setRowsData([...rowsData, rowsInput]);
                    break;
                }
                else {
                    if (transactionId % 2 == 0) {
                        colorStatus = "even"
                    } else {
                        colorStatus = "odd"
                    }
                    const rowsInput = {
                        transId: transactionId,
                        dates: "",
                        account: "",
                        accountType:"",
                        accountCategory:"",
                        description: '',
                        voucherno: '',
                        contact: "",
                        debit: '',
                        credit: '',
                        accountID: '',
                        contactID: '',
                        color: colorStatus
                    }
                    setRowsData([...rowsData, rowsInput]);
                    break;
                }
            }
        }
       
    // }
    }


    const deletetTableRows = (index, val) => {
        let deb = 0, crd = 0;
        let findIndex = null;
        rowsData.forEach((x, i) => {
            if (x.transId == val.transId) {
                deb = Number(x.debit) + deb;
                crd = Number(x.credit) + crd;
                findIndex = i-1;
            }
        });
       
        let dtotal = debitTotal;
        let ctotal = creditTotal;
        
        if(val.debit !=""){
            dtotal =  dtotal - val.debit  ;
        }else if(val.credit !=""){
            ctotal = ctotal - val.credit ;
        }

        for (let i = 0; i < rowsData.length; i++) {
             if (dtotal !== ctotal) {
                console.log("abc",entries);
                setEntries(entries - 1);
                // setTransactionId(transactionId - 1);
            }

        }
        
        setFindIndex(findIndex );
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
        if(location.state.data != null || location.state.data != undefined)  {
            deleteTransaction(val.entryId);
        }

        setDebitTotal(dtotal);
        setCreditTotal(ctotal);
        handleDifference(dtotal, ctotal);
        
    }   

    const handleTotal = (id,val) => {
        let deb = 0, crd = 0;
        let deb1 = 0, crd1 = 0;
        let diffC = 0, diffD = 0;
        let idx = findIndex || null;

        rowsData.forEach((x, i) => {
            if (x.transId == id) {
                deb1 = Number(x.debit) + deb1;
                crd1 = Number(x.credit) + crd1;
                if (deb1 == crd1 && deb1!="" && crd1!="") {
                    idx = null;
                }else {
                    idx = i + 1;
                }
            }
        })
 setFindIndex(idx - 1);

        // setEntries(0);
        let enty=0;
        for(let j=0;j<transactionId+1;j++){
        rowsData.forEach((x, i) => {
            if (j == x.transId) {
                console.log("evng",x.transId,j);
                // if(i == x){
                deb = Number(x.debit) + deb;
                crd = Number(x.credit) + crd;
                    if(deb!=0 && crd!=0){
                        if (deb == crd ) {
                            // idx = null;
                            // setEntries(entries + 1);
                            enty = enty + 1;
                            setEntries(enty);
                            
                        }else if (deb !== crd && entries>0 ){
                            // idx = i + 1;
                            setEntries(enty);
                            // continue;
                            
                            
                           
                        }
                    }
            // }
            }
        })        
    }
       
        
        // }
        // rowsData.forEach((x,i) => {
        //     deb = deb + Number(x.debit);
        //     crd = crd + Number(x.credit);
        // });


   



    //     let totdeb=0,totcrd=0;
    //     setEntries(0);let d=0;
    //     for(let j=0; j< transactionId + 1; j++){
    //   for(let i=0; i<rowsData.length;i++){
     
        
    //         if(j == i){
               
    //             console.log("ji",j,i,d);
    //             // if(rowsData[i].debit !=""){
    //                 totdeb=totdeb+Number(rowsData[i].debit);
    //             // }else if(rowsData[i].credit !=""){
    //                 totcrd=totcrd+Number(rowsData[i].credit);
    //             if(totdeb == totcrd && totdeb !=0 && totcrd !=0){
    //                 d=d+1;
    //                 setEntries(entries + 1);
    //             }else{
    //                 // setEntries(0);
    //                 // continue;
    //                 console.log("notequal",totdeb,totcrd)
    //             }
    //         }
           
    //     }
      
    //   }
    //   console.log("ety",entries,d);


    //    setEntries(0);
    //     let totdeb=0,totcrd=0;
    //     for (let j = 0; j < rowsData.length; j++) {
            
    //         for (let i = 0; i < transactionId +1; i++) {
    //             console.log("reowsdata",rowsData);
    //             console.log("deb1",totdeb,totcrd,"j,i",j,i);
    //             // console.log("deb", deb, "crd", crd, "entry", transactionId);
    //             if(j==i && deb !== "" && crd !== "") {
    //                 totdeb=totdeb+Number(rowsData[j].debit);
    //                 totcrd=totcrd+Number(rowsData[j].credit);
    //                 // console.log("deb",totdeb,totcrd,"j,i",j,i);
    //                 setEntries(entries+1);
    //             }
    //             // if (deb !== "" && crd !== "") {
    //             //     if (deb == crd) {
                      
    //             //     }
    //             //     else if (deb != crd ) {
    //             //         //  setEntries(entries - 1);
    //             //         continue;
    //             //     }
    //             // }
    //         }
    //     }
   

    
    // setEntries(0);
    //     for (let i = 0; i < transactionId + 1; i++) {
    //         for (let j = 0; j < rowsData.length; j++) {
    //             console.log("deb", deb, "crd", crd, "entry", entries);
    //             if(i == j){
                  
    //                 if (deb !== "" && crd !== "") {
    //                     if (deb == crd) {
    //                         setEntries(entries + 1);
    //                         console.log("equal",deb,crd);
    //                     }
    //                     else if (deb != crd && entries > 0) {
    //                         console.log("notequal",deb,crd);
    //                         // setEntries(entries - 1);
    //                     }
    //                 }
    //             }
    //         }
    //     }

        setDebitTotal(deb);
        setCreditTotal(crd);
        handleDifference(deb,crd);
       
    } 
 

    const handleDifference = (debit, credit) => {
        if (debit > credit) {
            credit = debit - credit;
            debit = "";
        }
        else {
            debit = credit - debit;
            credit = "";
        }
        setDifferenceDTotal(debit);
        setDifferenceCTotal(credit);
    }

    const handletChange = (index, e, id) => {
        const { name, value } = e.target;
        const rowsInput = [...rowsData];       
        if (name == "account") {
            rowsInput[index][name] = value;
            let opt = accountOptions;
            for (let i = 0; i < opt.length; i++) {
                for (let j = 0; j < opt[i].items.length; j++) {
                    if (value === opt[i].items[j].value) {
                        rowsInput[index]["accountID"] = opt[i].items[j].id;
                        rowsInput[index]["accountType"] = opt[i].items[j].type;
                        rowsInput[index]["accountCategory"] = opt[i].items[j].category;
                    }
                }
            }
        }else if(name=="contact"){
            rowsInput[index][name]=value;
            contactOptions.forEach(x=>{
                if(x.value==value){
                    return rowsInput[index]["contactID"]=x.id;
                }
            })
        }
        else if (name == "credit") {
            // console.log("cre",name)
            rowsInput[index][name] = value;
            rowsInput[index]['debit'] = "";
        }
        else if (name == "debit") {
            rowsInput[index][name] = value;
            rowsInput[index]['credit'] = "";
        }
        // else if (name == "transId") {
        //     rowsInput[index][name] = value;
        // }
         else {
            rowsInput[index][name] = value;

        }
          if (smDate === "single") {
            rowsInput[index][name] = value;
            rowsInput[index]["dates"] = date;
        }
        setRowsData(rowsInput);
    }


    let fdata = [];
    const onSubmit1 = (e) => {
        rowsData.forEach((items) => {
            fdata.push({
                EntryGroupID: items.transId,
                EntryID: items.entryId || 0,
                EntryDate: format(new Date(items.dates), 'yyyy-MM-dd'),
                AccountID: items.accountID,
                AccountName: items.account,
                AccountType:items.accountType,
                AccountCategory:items.accountCategory,
                Description: items.description,
                VoucherNo: items.voucherno,
                ContactID: items.contactID,
                ContactName: items.contact,
                Credits: parseInt(items.credit, 10) || 0,
                Debits: parseInt(items.debit, 10) || 0
            });
        });

        let id = "";
        if (journals.journalID != 0) {
            id = journals.journalID;
        } else {
            id = 0;
        }
        let reqData = {
            "JournalInputParams": {
                "JournalID": id || 0,
                "JournalDate": format(new Date(date), 'yyyy-MM-dd') || "",
                "DateType": smDate == "single" ? "Single Date" : "Multiple Date",
                "DateTypeFormatted": format(new Date(date), 'yyyy-MM-dd') || startDate, endDate,
                "JournalNumber": journals.journalNumber || journalNumber,
                "ReferenceNumber": "",
                "Note": description,
                "JournalType": "Cash",
                "JournalEntryType": "Normal Journal",
                "CurrencyCode": "INR",
                "CurrencySymbol": "Rs",
                "TotalDebits": debitTotal.toFixed(2),
                "TotalCredits": creditTotal.toFixed(2),
                "TotalEntries": entries || 0,
                "TenantID": GV.userDet.tenantDetail.tenantID,
                "Status": e,
                "HasAttachment": false,
                "TemplateID": 1,
                "TemplateType": "Journal",
                "CreatedBy": GV.userDet.appUser.userName
            },
            "Entries": fdata,
            "History": [{ "JournalHistoryID": 0, "ChangeDescription": "New Journal Entry" }]
        }


        jor.SaveJournalEntry(reqData)
            .then((response) => {
                if (response.data) {
                    if (response.data.message === 'Successfully added Journal Entry' || response.data.message === 'Successfully modified Journal Entry') {
                        reset();
                        setRowsData([{
                            transId: "",
                            dates: "",
                            account: "",
                            description: '',
                            voucherno: '',
                            contact: "",
                            debit: '',
                            credit: '',
                            accountID: '',
                            contactID: ''
                        }]);
                        getJournal();
                        setDescription("");
                        setDebitTotal("");
                        setCreditTotal("");
                        setTransactionId(0);
                        setDifferenceDTotal("");
                        setDifferenceCTotal("");
                        setSmDate("single");
                        setDate("");
                        setTableValidation("");
                        // setDesValidation(true);
                        toast.current.show({ severity: 'success', summary: response.data.message });
                        if (response.data.message === 'Successfully modified Journal Entry') {
                            setTimeout(() => { history('/journal') }, 1000);
                        } else {
                            return null;
                        }
                    }
                    else if (response.data.message === 'Debits and Credits should be equal') {
                        toast.current.show({ severity: 'error', summary: response.data.message });
                    }
                    else {

                    }
                }

            })
            .catch(function (error) {
                if (error.response.data.message === 'Journal Number Already Exists') {
                    toast.current.show({ severity: 'error', summary: error.response.data.message, detail: 'Journal Number Already Exists' });
                }
            })
    }

    const onSubmit = (e) => {
        // console.log("test 01", rowsData);
        let deb = debitTotal;
        let crd = creditTotal;

        if (deb === 0 && crd === 0) {

        }
        else if (deb !== crd) {

        } else {
            // setDesValidation(false);
        }

        // console.log("date issue", fdata);
        validation("");
        rowsData.forEach((items) => {

            fdata.push({
                EntryGroupID: items.transId,
                EntryID: items.entryId || 0,
                EntryDate: format(new Date(items.dates), 'yyyy-MM-dd'),
                AccountID: items.accountID,
                AccountName: items.account,
                AccountType:items.accountType,
                AccountCategory:items.accountCategory,
                Description: items.description,
                VoucherNo: items.voucherno,
                ContactID: items.contactID,
                ContactName: items.contact,
                Credits: parseInt(items.credit, 10) || 0,
                Debits: parseInt(items.debit, 10) || 0
            });
        });




        // let start=format(new Date(startDate), 'yyyy-MM-dd');
        // let end=format(new Date(endDate), 'yyyy-MM-dd')

        let id = "";
        if (journals.journalID != 0) {
            id = journals.journalID;
        } else {
            id = 0;
        }

        let reqData = {
            "JournalInputParams": {
                "JournalID": id || 0,
                "JournalDate": format(new Date(date), 'yyyy-MM-dd') || "",
                "DateType": smDate == "single" ? "Single Date" : "Multiple Date",
                "DateTypeFormatted": format(new Date(date), 'yyyy-MM-dd') || startDate, endDate,
                "JournalNumber": journals.journalNumber || journalNumber,
                "ReferenceNumber": "",
                "Note": description,
                "JournalType": "Cash",
                "JournalEntryType": "Normal Journal",
                "CurrencyCode": "INR",
                "CurrencySymbol": "Rs",
                "TotalDebits": debitTotal.toFixed(2),
                "TotalCredits": creditTotal.toFixed(2),
                "TotalEntries": entries || 0,
                "TenantID": GV.userDet.tenantDetail.tenantID,
                "Status": e,
                "HasAttachment": false,
                "TemplateID": 1,
                "TemplateType": "Journal",
                "CreatedBy": GV.userDet.appUser.userName
            },
            "Entries": fdata,
            "History": [{ "JournalHistoryID": 0, "ChangeDescription": "New Journal Entry" }]
        }

        // if (desValidation == false) {
            if (deb !== 0 || crd !== 0) {
                jor.SaveJournalEntry(reqData)
                    .then((response) => {
                        if (response.data) {
                            if (response.data.message === 'Successfully added Journal Entry' || response.data.message === 'Successfully modified Journal Entry') {
                                reset();
                                setRowsData([{
                                    transId: "",
                                    dates: "",
                                    account: "",
                                    description: '',
                                    voucherno: '',
                                    contact: "",
                                    debit: '',
                                    credit: '',
                                    accountID: '',
                                    contactID: ''
                                }]);
                                getJournal();
                                setDescription("");
                                setDebitTotal("");
                                setCreditTotal("");
                                setTransactionId(0);
                                setDifferenceDTotal("");
                                setDifferenceCTotal("");
                                setSmDate("single");
                                setDate("");
                                setTableValidation("");
                                // setDesValidation(true);
                                toast.current.show({ severity: 'success', summary: response.data.message });
                                setTimeout(() => { history('/journal') }, 1000);
                                if (response.data.message === 'Successfully modified Journal Entry') {
                                    setTimeout(() => { history('/journal') }, 1000);
                                } else {
                                    return null;
                                }

                            }
                            else if (response.data.message === 'Debits and Credits should be equal') {
                                toast.current.show({ severity: 'error', summary: response.data.message });
                            }
                            else {

                            }
                        }

                    })
                    .catch(function (error) {
                        if (error.response.data.message === 'Journal Number Already Exists') {
                            toast.current.show({ severity: 'error', summary: error.response.data.message, detail: 'Journal Number Already Exists' });
                        }
                    })
            }

        // }
    }

    return (<>

        <Toast ref={toast}></Toast>
        {location.state.id ? 
        <div
        className="d-flex justify-content-between p-3 mb-2 text-t-primary  kk-table-header kk-header-sec mb-0"
        style={{ backgroundColor: "white" }}
      >
<h4 className='kk-bold'>Update Journal</h4>        
      </div>
            :
            <div
        className="d-flex justify-content-between cc-greyCard-custm p-3 mb-2 text-t-primary  kk-table-header kk-header-sec mb-0"
        style={{ backgroundColor: "white" }}
      >
<h4 className='kk-bold m-0'>New Journal</h4>        
      </div>
        }
        <BlockUI blocked={blockedDocument} onClick={blockDocument}>
            <form  >
                <div className=' bg-white shadow-sm  chart-of-account h-100 p-4'>
                    <div className='row coa-header kk-header-sec'>
                        <div className='col-auto header-right ms-auto'>
                        </div>
                    </div>
                    <div className='row '>
                        <div className='col-md-9 col-sm-12 my-2'>
                            <div className='row  bag shadow-sm journal-of-account p-4'>
                                    <div className='col-md-4 col-sm-12 '>
                                            <label className='ps-0 required'>Journal No.</label>
                                            <input type="text" className="form-control sm-2 " value={journalNumber} readOnly disabled={disabled}
                                            //  defaultValue={journalData.JournalNumber}  onChange={(e) => setJournalData(e.target.value)} 
                                            />
                                            {/* {errors.jno && <p className="text-danger ps-0">Journal is required.</p>} */}
                                    </div>
                                    <div className='col-md-4 col-sm-12'>
                                            <label className='ps-0 '>Currency</label>
                                            <Controller
                                                render={({ field }) => (
                                                    <Dropdown className="form-control " options={currency}
                                                        {...field}
                                                        optionLabel="label" optionValue="value" value="INR" disabled={disabled} />
                                                )}
                                                control={control}
                                                name="curType"
                                            />
                                    </div>
                                    <div className='col-md-4 col-sm-12'>
                                            <div className="row">
                                                <label className="col-sm-6  p-0"><input type="radio" name="type" className='mx-1 ' disabled={location.state.data == true ? true : false}
                                                    {...register('type', { required: false })} value="single" checked={smDate == "single" ? true : false} onChange={(e) => { setSmDate(e.target.value); setTableValidation("") }} />Single Date </label>
                                                <label className="col-sm-6 p-0"><input type="radio" name="type" className='mx-1 '
                                                    {...register('type', { required: false })} value="multiple" checked={smDate == "multiple" ? true : false} disabled={location.state.data == true ? true : false} onChange={(e) => { setSmDate(e.target.value); setDate(""); }} />Multiple Date </label>
                                                {smDate == "single" ? <Calendar className=' p-0 ' value={location.state.data != null ? (new Date(date)) : date || ""} disabled={location.state.data == true ? true : false} onChange={(e) => handleDateChange(e.value)}
                                                    showIcon /> : <Calendar className=' p-0 ' id="range" value={dates2} disabled={location.state.data == true ? true : false} maxDate={new Date()} onChange={(e) => handleRangeDate(e)} selectionMode="range" readOnlyInput showIcon />
                                                }
                                                                                        {dateValidation ? <p className='text-danger'>{dateValidation}</p> : ""}
                                            </div>
                                    </div>
                                    <div className='col-md-8 col-sm-12'>
                                            <label className='ps-0 '>Description</label>
                                            <textarea type="text" className="form-control" value={description} disabled={location.state.data == true ? true : false}
                                                // {...register('description', { required: true })}
                                                onChange={(e) => setDescription(e.target.value)} />
                                            {/* {errors.description && <p className="text-danger ps-0">Description is required.</p>} */}
                                            {/* {desValidation ? <p className='text-danger ps-3'>{desValidation}</p> : ""} */}
                                    </div>
                                    <div className='col-md-4 col-sm-12'>
                                            <label className=" ps-3" >
                                                <input className="form-check-input mx-1" type="checkbox" value="" />
                                                Reverse Entry
                                            </label>
                                    </div>
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-12 my-2 ps-2'>
                            <div className='bgd shadow-sm journal-of-account p-4'>
                                <div className="col ">
                                    <div className=" row mt-1 ">
                                        <label className="col-sm-3 col-form-label" >Entries</label>
                                        <div className="col-sm-5 ms-auto">
                                            <input type="text" value={entries} className="form-control tborder bgd" disabled={disabled} />
                                        </div>
                                    </div>
                                    <div className=" row mt-1 ">
                                        <label className="col-sm-3 col-form-label" >Debit ({GV.userDet.tenantConfig.currency})</label>
                                        <div className="col-sm-5 ms-auto">
                                            <input type="text" min={0} max={10} step={0.00} value={Number(debitTotal).toFixed(2)} className="form-control tborder bgd" disabled={disabled} />
                                        </div>
                                    </div>
                                    <div className=" row mt-1 ">
                                        <label className="col-sm-3 col-form-label" >Credit ({GV.userDet.tenantConfig.currency})</label>
                                        <div className="col-sm-5 ms-auto">
                                            <input type="text" min={0} max={10} step={0.00} value={Number(creditTotal).toFixed(2)} className="form-control tborder bgd" disabled={disabled} />
                                        </div>
                                    </div>
                                    <div className=" row mt-1 ">
                                        <label className="col-sm-3 col-form-label" >Status</label>
                                        <div className="col-sm-5 ms-auto">
                                            <input type="text" className="form-control tborder bgd" value={journals.status || "New"} disabled={disabled} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='table-responsive'>
                        <table className="table mt-3 ">
                            <thead>
                                <tr className='heading '>
                                    <th >DATE</th>
                                    <th >ACCOUNT</th>
                                    <th >DESCRIPTION</th>
                                    <th >VOUCHER NO</th>
                                    <th >CONTACT</th>
                                    <th >DEBIT ({GV.userDet.tenantConfig.currency})</th>
                                    <th >CREDIT ({GV.userDet.tenantConfig.currency})</th>
                                    {location.state.data ? ""
                                        :
                                        <th >ACTION</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {rowsData.map((data, index) => {
                                    const { dates, account, description, voucherno, contact, debit, credit, color, transId } = data;

                                    return (
                                        <tr key={index} className={`tablerow ${data.color}`} >
                                            <td >
                                                {smDate == "single" ? <input type="text" name="dates" className='form-control tborder' readOnly value={date} disabled={location.state.data == true ? true : false} onChange={(e) => (handletChange(index, e, data.transId))} /> :
                                                    <Calendar name="dates" value={dates} minDate={startDate} maxDate={endDate} disabled={location.state.data == true ? true : false}
                                                        onChange={(e) => (handletChange(index, e, data.transId))} showIcon />
                                                }
                                                {/* location.state.data!=null?format(new Date(date),"MM-dd-yyyy"):date */}
                                            </td>
                                            <td><Controller
                                                render={({ field }) => (
                                                    <Dropdown className='tborder accon acnt' options={accountOptions} placeholder='Accounts' optionLabel="label" filter filterBy="label" optionGroupLabel="label"
                                                        disabled={location.state.data == true ? true : false} optionGroupChildren="items" optionGroupTemplate={groupedItemTemplate} {...field} value={account} onChange={(e) => (handletChange(index, e, data.transId))}
                                                    />
                                                )}
                                                control={control}
                                                name="account"

                                            /></td>
                                            <td><textarea type="text" className="form-control tborder a " placeholder='Description' name="description" value={description} disabled={location.state.data == true ? true : false} onChange={(e) => (handletChange(index, e, data.transId))} /></td>
                                            <td><input type="text" className="form-control tborder  " name="voucherno" value={voucherno} disabled={location.state.data == true ? true : false} onChange={(e) => (handletChange(index, e, data.transId))} /></td>
                                            <td><Controller
                                                render={({ field }) => (
                                                    <Dropdown className=' tborder  accon' placeholder='Contacts' filter filterBy="label" options={contactOptions}
                                                        {...field} value={contact} disabled={location.state.data == true ? true : false} onChange={(e) => (handletChange(index, e, data.transId))}
                                                    // optionLabel="displayName"  optionValue="displayName"
                                                    />
                                                )}
                                                control={control}
                                                name="contact"
                                            /></td>
                                            <td>
                                                <input type="number" name="debit" value={debit} disabled={location.state.data == true ? true : false} onKeyUp={() => handleTotal(data.transId)} onChange={(e) => (handletChange(index, e, data.transId))} className="form-control tborder tot" />
                                            </td>
                                            <td>
                                                <input type="number" name="credit" value={credit} disabled={location.state.data == true ? true : false} onKeyUp={() => handleTotal(data.transId)} onChange={(e) => (handletChange(index, e, data.transId))} className="form-control tborder tot" />
                                            </td>
                                            {location.state.data ? ""
                                                :
                                                <td >
                                                    <div className="d-flex align-items-center mt-3">
                                                
                                                        {findIndex == index ? <i className="pi pi-plus-circle mx-2 plus" onClick={() => addTableRow(findIndex, data)} /> : ""}

                                                      {index == 0?"":  <i className="pi pi-trash bin" value={transactionData} onClick={() => (deletetTableRows(index, data))} />}
                            
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="row ">
                        {tableValidation ? <p className='text-danger'>{tableValidation}</p> : ""}

                        <div className="col-md-6 col-sm-12 my-2 p-0">
                            {location.state.data ?
                                ""
                                : <button type="button" className="btn btn-info " onClick={() => addtTableRows()}>+ Add more entry</button>
                            }
                            <div className=' mt-4'>
                                <ConfirmDialog />
                                {location.state.data ? <button type="button" className="btn btn-primary" onClick={() => { history('/journal') }}>Back to Journal</button>
                                    : <>
                                        <button type="button" className="btn btn-primary me-2" onClick={() => confirmPublished()} >Submit</button>
                                        <button type="button" className="btn btn-primary me-2" onClick={() => onSubmit("Draft")}>Save as Draft</button>
                                        <button type="button" className="btn btn-danger" onClick={() => { history('/journal') }}>Cancel</button></>
                                }

                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 bg-light total m-0">
                            {/* {tableValidation ? <p className='text-danger'>{tableValidation}</p> : ""} */}
                            <div className=" row mt-2 ">
                                <label className="col-sm-3 col-form-label" >Total ({GV.userDet.tenantConfig.currency})</label>
                                <div className="col-sm-3 ms-auto ">
                                    <input type="text" min={0} max={10} step={0.00} value={Number(debitTotal).toFixed(2)} disabled={disabled} className="form-control tborder bg-light tot" />
                                </div>
                                <div className="col-sm-3 ms-auto ">
                                    <input type="text" min={0} max={10} step={0.00} value={Number(creditTotal).toFixed(2)} disabled={disabled} className="form-control tborder bg-light tot" />
                                </div>
                            </div>
                            <div className=" row my-2 ">
                                <label className="col-sm-3 col-form-label" >Difference ({GV.userDet.tenantConfig.currency})</label>
                                <div className="col-sm-3 ms-auto">
                                    <input type="text" min={0} max={10} step={0.00} value={Number(differenceDTotal).toFixed(2)} disabled={disabled} className="form-control tborder bg-light tot text-danger" />
                                </div>
                                <div className="col-sm-3 ms-auto">
                                    <input type="text" min={0} max={10} step={0.00} value={Number(differenceCTotal).toFixed(2)} disabled={disabled} className="form-control tborder bg-light tot text-danger" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </BlockUI>
    </>
    );
});


export default NewJournal;



