

import { DataTable, Column, Dropdown, InputText, MultiSelect, iconSet, IcomoonReact } from '../PrimeReact.js';
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {Row} from 'react-bootstrap';
import { format } from 'date-fns';
import AccountantService from '../../services/accountant';
import '../../assets/styles/reports/ledger.scss';
import LedgerService from '../../services/ledger.js';
import { AiOutlineSearch } from 'react-icons/ai';



const Ledger = () => {

    const modalref = useRef(null)
    const acs = new AccountantService();
    const ldgs = new LedgerService();

    const [first1, setFirst1] = useState(0);
    const [tdataLoad, setTdataLoad] = useState(true);
    const [requestData, setRequestData] = useState([]);
    const [data, setData] = useState([]);
    const [totalRecordsIn, setTotalRecordsIn] = useState(0);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [sortName, setSortName] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [searchVal, setSearchVal] = useState('');
    const [isMounted, setIsMounted] = useState(true);
    const [accountSelected, setAccountSelected] = useState('All')

    //coastatus start

    const coaStatus = [
        { label: 'All Accounts', value: 'All', status: true },
        { label: 'Active Accounts', value: 'Active', status: true },
        { label: 'Inactive Accounts', value: 'InActive', status: true },
        { label: 'Asset Accounts', value: 'Assets', status: false },
        { label: 'Liability Accounts', value: 'Liability', status: false },
        { label: 'Expenses Accounts', value: 'Expense', status: false },
        { label: 'Income Accounts', value: 'Income', status: false },
        { label: 'Equity Accounts', value: 'Equity', status: false },
    ];
    const [statusSelected, setStatusSelected] = useState(coaStatus[0].value)
    const [filterSelected, setFilterSelected] = useState(coaStatus[0].value)

    const editProduct = (product) => {
        modalref.current.alertToggledit(product);

        // setProduct({...product});
        // setProductDialog(true);
    }

    // const actionBodyTemplate = (rowData) => {
    //     return (
    //         <React.Fragment>

    //             <IcomoonReact iconSet={iconSet} color="#444" size={20} icon="edit" className='pointer' onClick={() => editProduct(rowData)} />

    //         </React.Fragment>
    //     );
    // }
    const dateBodyTemplate = (row) => {
        return format(new Date(row.transactionDate,"dd-MM-yyyy"));
        
    }

    const basiccolumns = [
        // { field: 'transactionDate', header: 'Date', sortable: false, body: '' },
        { field: 'transactionNumber', header: 'Journal No', sortable: false, body: '' },
        { field: 'description', header: 'Description', sortable: false, body: '' },
        { field: 'contactName', header: 'Contact Name', sortable: false, body: '' },
        { field: 'debits', header: 'Debit', sortable: false, body: '' },
        { field: 'credits', header: 'Credit', sortable: false, body: '' },
        { field: 'availableBalance', header: 'Closing Balance', sortable: false, body: '' },
    ];

    const columns = [
        { field: 'contactName', header: 'Contact Name', sortable: false, body: '' },
        { field: 'debits', header: 'Debit', sortable: false, body: '' },
        { field: 'credits', header: 'Credit', sortable: false, body: '' },
        { field: 'availableBalance', header: 'Closing Balance', sortable: false, body: '' },
    ];

    const [selectedColumns, setSelectedColumns] = useState(basiccolumns);

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    }
    const accountChange = (e) => {
        setFilterSelected(e.value)
        let obj = coaStatus.find(c => c.value === e.value)
        if (obj.status === true) {
            setStatusSelected(e.value);
            setAccountSelected('All')
            fetchLedgerList(page, sizePerPage, searchVal, '', '', e.value, 'All')
        } else {
            setStatusSelected('Active')
            setAccountSelected(e.value);
            fetchLedgerList(page, sizePerPage, searchVal, '', '', 'Active', e.value)

        }

    }
    const keySearch = (e) => {
        setSearchVal(e.target.value);
        if (e.key === 'Enter') {
            const searched = e.target.value
            fetchLedgerList(1, sizePerPage, searched, sortName, sortOrder, statusSelected, accountSelected,filterSelected);
        }
        if (e.type === 'keyup') {
            if (e.target.value === '') {
                fetchLedgerList(1, sizePerPage, '', sortName, sortOrder, statusSelected, accountSelected,filterSelected)
            }
        }
    }
    const emptySearch = () => {
        setSearchVal('')
        fetchLedgerList(1, sizePerPage, '', sortName, sortOrder, statusSelected, accountSelected,filterSelected);

    }

    const header = (
        <div className='d-flex justify-content-between flex-wrap'>
            <div className="text-t-primary  kk-table-header kk-header-sec">
                <Dropdown className='col-auto text-start me-3' value={filterSelected} options={coaStatus} onChange={(e) => accountChange(e)}
                    scrollHeight="400px"
                    placeholder="Select account" />

                <div className='col  border rounded my-auto' >
                    <AiOutlineSearch />
                    <input
                        className=' py-1 ps-3 search' value={searchVal} onChange={(e) => keySearch(e)}
                        onKeyUp={(e) => keySearch(e)} placeholder="Search" />
                    {
                        searchVal !== '' ? <i className='pi pi-times' onClick={emptySearch} /> : ''
                    }

                </div>


                
            </div>
            <div>
                {/* <FiPlus className='me-1' /> */}
                <button className='btn btn-primary py-1 me-2 ' onClick={() => { modalref.current.alertToggle(); }} > <i className='pi pi-plus'></i>  New Account</button>

                <MultiSelect value={selectedColumns} options={columns} optionLabel="header" optionDisabled="disabled" onChange={onColumnToggle} style={{ width: '3em' }} scrollHeight="400px" dropdownIcon="pi pi-table"
                    tooltip="Custom Columns" tooltipOptions={{ position: 'top' }} />

            </div>
        </div>
    );




    const dynamicColumns = selectedColumns.map((col, i) => {

        return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={col.body &&  dateBodyTemplate} ></Column>
        // (
        //     <React.Fragment>

        //         <Column key={col.field} body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
        //     </React.Fragment>
        // );



    });

    const dynamicColumnsOne = selectedColumns.map((col, i) => {

        return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={col.body} ></Column>
    });

    const onCustomPage1 = (event) => {
        setFirst1(event.first);

        const val = event.page + 1;
        const size = event.rows;

        setSizePerPage(size);
        setPage(val);
        fetchLedgerList(val, size, searchVal, '', '', statusSelected, accountSelected);

    }
    //pagination end

    //sort start
    const [lazyParams, setLazyParams] = useState({
        first: first1,
        rows: sizePerPage,
        page: page,
        sortField: sortName,
        sortOrder: sortOrder,

    });


    const onSort = (event) => {
        setLazyParams(event);
        // console.log(event)
    }
    //sort end

    //apicall
    function fetchLedgerList(page, sizePerPage, searchVal, sortName, sortOrder) {
        let data = {
            "InputParams": 
            { 
                "ID": 0,
                "TenantID": 5,
                "TransactionType": "All",
                "Period": "This Year", 
                "FromDate": "", 
                "ToDate": ""
            },
            "ServerSearchables": 
            { 
                "draw": page,  
                "searchData": searchVal || "", 
                "orderByColumn": sortName, 
                "orderBy": sortOrder, 
                "pageSize": sizePerPage, 
                "pageNumber": page 
            }
        }

        ldgs.GetLedgerList(data).then((response) => {
             // if (isMounted) {
                if (response.data) {
                    console.log("xyz",response.data);
                    const grid = response.data.data.ledgerList;
                    if (response.data.statusCode ==200) {
                        setTotalRecordsIn(response.data.data.serverSearchables.recordsTotal);
                        setData(grid);
                        setRequestData(response.data.data.ledgerList);
                        //   setData(requestData.slice(0,sizePerPage));  
                        setTdataLoad(false);
                    }
                    else if (response.data.statusCode ==500 ) {
                        
                        setData([]);
                        setRequestData([]);
                        setTotalRecordsIn(0);
                    }
                    // }
                }
     
            // if (response.data) {
            //     console.log("xyz",response.data);
            //     const grid = response.data.data.ledgerList;
            //     setTotalRecordsIn(response.data.data.serverSearchables.recordsTotal);
            //     setData(grid);
            //     setRequestData(response.data.data.ledgerList);
            //     //   setData(requestData.slice(0,sizePerPage));
            //     setTdataLoad(false);
            // }
            // }
        })
            .catch(function (error) {
                if (error.response.data.statusCode ==500 ) {
                    
                    setData([]);
                    setRequestData([]);
                    setTotalRecordsIn(0);
                }
                console.log(error);
            })
       
    }
    useEffect(() => {
        fetchLedgerList(page, sizePerPage, searchVal, sortName, sortOrder);
    }, []);

    
    const parentFunction = () => {
        fetchLedgerList(page, sizePerPage, searchVal, '', '', statusSelected, accountSelected,filterSelected);
    }
    useLayoutEffect(() => {
        fetchLedgerList(page, sizePerPage, searchVal, '', '', statusSelected, accountSelected,filterSelected);
        return () => { setIsMounted(false) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return <div>
        <h4 className='kk-bold'>General Ledger</h4>

        <div className='bg-white shadow-sm chart-of-account h-100 p-4'>
            <div className='row coa-header kk-header-sec border mx-0 mb-2 p-2'>
                {/* <div className='col-auto header-right mb-2 border'> */}
                <div className='col-sm-2 pe-3 border rounded ' >
                    <AiOutlineSearch />
                    {/* <input
                        className='py-1 ps-3 ' placeholder="Search " /> */}
                         <input
                        className=' py-1 ps-2 ' value={searchVal} onChange={(e) => keySearch(e)}
                        onKeyUp={(e) => keySearch(e)} placeholder="Search" />
                    {
                        searchVal !== '' ? <i className='pi pi-times' onClick={emptySearch} /> : ''
                    }
                </div>
                
                <select className='col-md-2 border rounded ms-auto'></select>
                {/* </div> */}
            </div>
            <div className='scnd-header mb-2 p-2'>
                <div className='row px-4'>
                    <div className='col-md-3'>
                        <Row >
                            <label className='col-auto my-auto'>Account Name</label>
                            <input type="text" className='form-control col border-0'></input>
                        </Row>
                    </div>
                    <div className='col-md-3 ms-auto'>
                        <Row >
                            <label className='col-auto my-auto'>Opening Balance</label>
                            <input type="number" className="form-control col border-0 "></input>
                        </Row>
                    </div>
                </div>
                
                <div className='row mt-4 px-4'>
                    <div className='col-md-3'>
                        <Row>
                            <label className='col-auto my-auto'>From</label>
                            <input type="text" className='form-control col border-0'></input>
                            <label className='col-auto my-auto'>To</label>
                            <input type="text" className='form-control col border-0'></input>
                        </Row>
                    </div>
                    <div className='col-md-3 ms-auto'>
                        <Row >
                            <label className='col-auto my-auto'>Closing Balance</label>
                            <input type="number" className="form-control col border-0"></input>
                        </Row>
                    </div>
                </div>
            </div>
            <div className='account-table'>
                <DataTable stripedRows value={data} 
                // responsiveLayout="scroll"
                 size="small" loading={tdataLoad}
                    paginator lazy totalRecords={totalRecordsIn}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rowsPerPageOptions={[10, 20, 50, totalRecordsIn]}
                    first={first1} rows={sizePerPage} onPage={onCustomPage1}
                    onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}>
                    <Column field="transactionDate" header="Date" body={(row)=><div> {format(new Date(row.transactionDate),'dd-MM-yyyy')}</div>}/>
                    {dynamicColumns}
                    
                    {/* <Column field="actionBodyTemplate" header="Action" body={actionBodyTemplate}/> */}
                </DataTable>
            </div>
        </div>
    </div>;
};

export default Ledger;