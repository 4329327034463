// import React from 'react'

// export default function NewPettyCash() {
//   return (
//     <div>NewPettyCash</div>
//   )
// }


import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from "react";

import { Controller, useForm } from "react-hook-form";

import "../../assets/styles/items/newItem.scss"
import { Dialog, Button, InputText, InputTextarea, Dropdown, Toast, ToggleButton } from '../PrimeReact';
import { Row } from "react-bootstrap";
import GV from '../../services/globalVar';
import PettycashServiceService from '../../services/pettycash-service';
import moment from 'moment';
import { format } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import classNames from './classNames';

const NewPettyCash = forwardRef((props, ref) => {
    const toast = useRef(null);
    
    const [itemData, setItemData] = useState([]);
    const [upDisabled, setUpDisabled] = useState(true);
    
    const [itemID, setItemID] = useState(0);
    const [displaynewItem, setdisplaynewItem] = useState(false);
    const [position, setPosition] = useState('center');
    const [date, setDate] = useState("");

    const dateSelected=props?.data?.dateSelected;

    console.log("dateSelectednewwwwwwwww",dateSelected);
    
    const its = new PettycashServiceService()
    // let iId = props.data.itemId;

    const defaultValues = {
        pdate: moment(dateSelected)?.format('MM/dd/yyyy'),
        date: new Date(dateSelected),
        receiver: "",
        transaction: '',
        amount: '',
        tCode: "",
        gst: 'false',
        modeofPay:"",
        description: "",
       
    };
    let maxDate = new Date();
    const {
        register,
        handleSubmit, reset, formState: { errors },control, setValue } = useForm({ mode: "all", defaultValues });

    const resetAsyncForm = (async () => {
        reset();
    });

    useImperativeHandle(ref, () => ({
        alertToggle() {
            onClick('displaynewItem')
        },
        alertToggledit(val) {
            onClickEdit('displaynewItem', val)
        }
    }));

    const dialogFuncMap = {
        'displaynewItem': setdisplaynewItem
    }

    const onClickEdit = (name, value) => {
        dialogFuncMap[`${name}`](true);
        resetAsyncForm();
        console.log('ed', value);
        setItemID(value.transactionID);
        setValue('pdate',format(new Date(value.transactionDate), 'MM/dd/yyyy') );
        setValue('receiver', value.receiver);
        setValue('transaction', value.transaction);
        setValue('amount', value.amountPaid);
        setValue('tCode', value.voucher);
        setValue('gst', value.isGST  == true ? 'true' :'false');
        setValue('modeofPay', value.paymentMode);
        setValue('description', value.description);
        if (position) {
            setPosition(position);
        }
    }


    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        resetAsyncForm();
        setUpDisabled(true);
       
        setItemID(0);
        setValue('status',true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const handleDateChange = (date) => {
        setDate(format(new Date(date), 'MM-dd-yyyy'));
    };

    const onSubmit = (data) => {
        const datas ={
            "TransactionID" : itemID||0,
            "TransactionDate" :  itemID != "" || itemID != 0 ? format(new Date(data.pdate),'yyyy-MM-dd') : format(new Date(data.date),'yyyy-MM-dd'),
            "Transaction" :  data.transaction,
            "ReceiverID" : 0,
            "Receiver" :  data.receiver,
            "IsGST" :  data.gst == 'true' ? true :false,
            "PaymentMode" :  data.modeofPay,
            "AmountPaid" : data.amount,
            "Voucher" :  data.tCode,
            "Description" :  data.description,
            "TransactionStatus" : "Active",
            "TenantID" : GV.userDet.tenantDetail.tenantID,
            "CreatedBy" : GV.userDet.appUser.appuserID.toString()
        }
        console.log(datas,'save')
        its.SavePettyCashTransaction(datas).then(res => {
            console.log(res.data,"resssssss");
           
            // setDate(format(new Date(pdate.TransactionDate), "MM-dd-yyyy"));
            // if (res.data.message === 'Saved Successfully') {
            //     console.log('ONE')
            //     props.parentFunction();
            //     toast.current.show({ severity: 'success', summary: res.data.message });
            // }
            if (res.data.message === 'Updated Successfully' || res.data.message === 'Saved Successfully') {
                props.parentFunction();
                toast.current.show({ severity: 'success', summary: res.data.message });
                dialogFuncMap['displaynewItem'](false);
            }else{
                toast.current.show({ severity: 'error', summary: res.data.message });
                dialogFuncMap['displaynewItem'](false);
            }
        }).catch( (error) => {
            console.log(error)
            // if (error.response.data.statusCode === 500) {
            //     toast.current.show({ severity: 'error', summary: error.response.data.message });
            // }
        })
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => onHide('displaynewItem')} className="p-button-text btn-cancel" />
                <Button type="submit" label={itemID != "" || itemID != 0 ? "Update" : "Save"} icon="pi pi-check" onClick={handleSubmit((onSubmit))} autoFocus className='btn btn-primary' />
            </div>
        );
    }

   
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };
    return <div>

        <Toast ref={toast}></Toast>

        <Dialog header={itemID != "" || itemID != 0 ? "Update Item" : "New Item"} visible={displaynewItem}
            onHide={() => onHide('displaynewItem')} 
            style={{ width: '60vw',height:'80vh' }} footer={renderFooter('displaynewItem')}>
            <form>
                <div className='form-group row text-start'>
                    <div className='row mt-3 ps-2 pe-0'>
                        <div className='col-md-6 ps-3'>
                                <label className="form-label mb-1 required" htmlFor="type">Date</label> <br/>
                             
                               {itemID != "" || itemID != 0 ?
                                    <input type="text" className="form-control px-1 py-2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing" disabled={upDisabled}
                                        defaultValue={itemData?.pdate} onChange={(e) => setItemData(...e.target.value)}
                                        {...register('pdate', { required: true })} />  
                                    :
                                    <div className='row cc-cal'>
                                        {/* <Controller name="date" control={control} render={({ date }) => (
                                            <Calendar className='ps-2 p-0 pe-2' showIcon defaultValue={itemData?.pdate}  appendTo="self"  maxDate={maxDate} onChange={(e) => setItemData(...e.target.value)} 
                                            {...register('pdate', { required: true })}/>
                                        )} /> */}
                                        <Controller
                                            name="date"
                                            control={control}
                                            rules={{ required: 'This field is required' }}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    {/* <label htmlFor={field.name}>Date</label> */}
                                                    <Calendar showIcon inputId={field.name} value={field.value} onChange={field.onChange} appendTo="self" maxDate={maxDate} dateFormat="mm/dd/yy" className={classNames({ 'p-invalid': fieldState.error })}    />
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </div>
                                 } 
                                   {itemID != "" || itemID != 0 ? errors.pdate && <p className="text-danger mb-0 ps-1">This field is required</p> : ''} 
                                
                        </div>
                        {/* <div className='col-md-6'>
                            <div className='row  cc-cal'>
                                <Controller
                                        name="itemData.pdate"
                                        control={control}
                                        rules={{ required: 'Date is required.' }}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <label htmlFor={field.name}>Date</label>
                                                <Calendar showIcon inputId={field.name} value={field.value} onChange={(e) => setItemData(...e.target.value)}  dateFormat="dd/mm/yy" appendTo="self" className={classNames({ 'p-invalid': fieldState.error })} />
                                                {getFormErrorMessage(field.name)}
                                            </>
                                        )}
                                    />
                            </div>
                        </div> */}
                        <div className='col-md-6 pe-4'>
                            <label className='form-label mb-1 p-0 required' htmlFor="name">Receiver</label>
                            <input type="text" className="form-control px-1 py-2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing"
                                defaultValue={itemData.receiver} onChange={(e) => setItemData(...e.target.value)}
                                {...register('receiver', { required: true })} />
                            {errors.receiver && <p className="text-danger">This field is required</p>}
                        </div>

                        <div className='col-md-6 ps-3'>
                            <label className="form-label mb-1 required" htmlFor="type">Transaction</label>
                            <input type="text" className="form-control px-1 py-2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing"
                                defaultValue={itemData.transaction} onChange={(e) => setItemData(...e.target.value)}
                                {...register('transaction', { required: true })} />
                            {errors.transaction && <p className="text-danger mb-0 ps-1">This field is required</p>}
                        </div>

                        <div className='col-md-6 pe-4'>
                            <label className='form-label mb-1 p-0 required' htmlFor="name">Amount</label>
                            <input type="text" className="form-control px-1 py-2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing"
                                defaultValue={itemData.amount} onChange={(e) => setItemData(...e.target.value)}
                                {...register('amount', { required: true })} />
                            {errors.amount && <p className="text-danger">This field is required</p>}
                        </div>

                        <div className='col-md-6 ps-3'>
                            <label className="form-label mb-1 " htmlFor="type">Voucher Code</label>
                            <input type="text" className="form-control px-1 py-2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing"
                                defaultValue={itemData.tCode} onChange={(e) => setItemData(...e.target.value)}
                                {...register('tCode', { required: false })} />
                            {/* {errors.tCode && <p className="text-danger mb-0 ps-1">Type is required</p>} */}
                        </div>

                        <div className='col-md-6 pe-4'>
                            <label className='form-label mb-1 p-0 required' htmlFor="name">GST Applicable</label>
                            <div className="px-1 pt-2 mb-1">
                                <label className="md-3 g-0 me-4"><input type="radio"
                                    {...register('gst', { required: true })} name="gst" value="true"
                                    defaultValue={itemData.gst} onChange={(e) => setItemData(...e.target.value)} /> Yes</label>
                                <label className="form-label"><input type="radio"
                                    {...register('gst', { required: true })} name="gst" value="false"
                                    defaultValue={itemData.gst} onChange={(e) => setItemData(...e.target.value)} /> No</label>
                            </div>
                            {errors.gst && <p className="text-danger mb-0 ps-1">This field is required</p>}
                        
                        </div>

                        <div className='col-md-12 pe-4'>
                            <label className='form-label mb-1 p-0 required' htmlFor="name">Mode of Payment</label>
                            <div className="px-1 pt-2 mb-1">
                                <label className="md-3 g-0 me-4"><input type="radio"
                                    {...register('modeofPay', { required: true })} name="modeofPay" value="Cash"
                                    defaultValue={itemData.modeofPay} onChange={(e) => setItemData(...e.target.value)} /> Cash</label>
                                <label className="form-label me-4"><input type="radio"
                                    {...register('modeofPay', { required: true })} name="modeofPay" value="Cheque"
                                    defaultValue={itemData.modeofPay} onChange={(e) => setItemData(...e.target.value)} /> Cheque</label>
                                     <label className="form-label"><input type="radio"
                                    {...register('modeofPay', { required: true })} name="modeofPay" value="Online Transaction"
                                    defaultValue={itemData.modeofPay} onChange={(e) => setItemData(...e.target.value)} /> Online Transaction</label>
                            </div>
                            {errors.modeofPay && <p className="text-danger mb-0 ps-1">This field is required</p>}
                        
                        </div>
                        <div className='col-md-12 pe-4'>
                            <label className='form-label mb-1 p-0 ' htmlFor="name">Description</label>
                            <textarea type='text' className="form-control px-1 py-2"
                                                {...register('description', { required: false })} 
                                                defaultValue={itemData.description} onChange={(e) => setItemData(e.target.value)} />
                            {/* {errors.receiver && <p className="text-danger">Name is required</p>} */}
                        </div>
                        
                    </div>
                </div>
            </form>
        </Dialog>
    </div>;
});

export default NewPettyCash;
