import { Calendar } from 'primereact/calendar';
import React, { useLayoutEffect, useState } from 'react'
import { DataTable, Column, Dropdown, InputText, MultiSelect, iconSet, IcomoonReact, Checkbox,Dialog } from '../PrimeReact.js';
import GV from '../../services/globalVar';
import { startOfWeek,lastDayOfWeek, format} from "date-fns";
import PettycashServiceService from '../../services/pettycash-service';
import { useNavigate } from 'react-router';

export default function PettyCashReport() {
    const pts = new PettycashServiceService();
    const [dates,setDates] = useState();
    const [tdataLoad, setTdataLoad] = useState(false);
    const [data, setData] = useState([]);
      const [totalRecordsIn, setTotalRecordsIn] = useState(0);
      const [page, setPage] = useState(1);
      const [sizePerPage, setSizePerPage] = useState(10);
      const [sortName, setSortName] = useState("");
      const [sortOrder, setSortOrder] = useState("");
      const [searchVal, setSearchVal] = useState('');
      const [selectedColumns, setSelectedColumns] = useState([]);
      const [first1, setFirst1] = useState(0);
      const [cashReport,setCashReport] = useState([])
      const [currentMonth, setCurrentMonth] = useState(new Date());
     const[startDate,setStartDate] = useState();
     const[endDate,setEndDate] = useState();
    const navigate = useNavigate();
      const renderCells = (mon) =>{
        const sdate = startOfWeek(currentMonth, { weekStartsOn: 1 });
        const edate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
        setStartDate(sdate)
        setEndDate(edate)
        setDates([sdate,edate])
        fetchDetails(sdate,edate);
      }
      const fetchDetails = (start,end) =>{
        console.log(startDate,endDate,'klo')
        // setDates([start,date])
        const data = {
            "TenantID" :  GV.userDet.tenantDetail.tenantID,
            "FromDate" : format(start,'yyyy-MM-dd'),
            "ToDate" : format(end,'yyyy-MM-dd'),
            "Status" : "Active"
        }
        console.log(data)
            pts.GetCustomTransactions(data).then(res=>{
                console.log(res)
                setCashReport(res.data.data)
            })
      }
   const changeDuration = (val) =>{
    setDates(val)
    if(val[0] != null && val[1] != null){
        fetchDetails(val[0],val[1])
    }
    console.log(val)
   }
   const backtopetty = () =>{
            navigate('/pettycash')
   }
   const amountbody = (data) => {
        return (<p>{format(new Date(data.transactionDate),GV.userDet.tenantConfig.dateFormat)}</p>)
    };
    useLayoutEffect(() => {
        // getDayDetails();
        // setDateSelected(weekdays.forEach((x, i) => x.value  === true ? new Date() : new Date()));
        renderCells(currentMonth);
        // fetchItemList(page, sizePerPage, searchVal, '', '',new Date());
        // return () => { setIsMounted(false) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  return (
    <div>
        <div className='card card-borderless cc-greyCard-custm'>
            <div className='ms-2 mt-2 me-2'>
                <div className='d-flex justify-content-between align-items-center mb-2 flex-wrap'>
                    <h4 className="m-0">Petty Cash Report</h4>
                    <div className='cc-calen'>
                        <Calendar className='me-2' showIcon  value={dates} onChange={(e) => changeDuration(e.value)}  selectionMode="range"  dateFormat={GV.userDet.tenantConfig.dateFormat === 'MM/dd/yyyy'? 'mm/dd/yy' : 'dd/mm/yy'}   style={{width:'250px'}}  />
                        <button className='btn btn-danger my-2' onClick={backtopetty}>Back</button>                        
                                              
                        {/* <Calendar className='cc-calen' value={dates} onChange={(e) => changeDuration(e.value)} selectionMode="range" readOnlyInput  style={{width:'250px'}}/> */}
                        {/* <p className='customFont mb-0'> <span className='cc-fixed'> Week Opening Balance : <b> {GV.userDet.tenantConfig.currency} {pettyDetails?.openingBalance} </b></span>   <span className='ms-3 border-btn'   onClick={() => changeWeekHandle("prev")}> Previous Week </span> <span className='ms-3 border-btn'   onClick={() => changeWeekHandle("next")}> Next Week </span></p> */}
                    </div>
                </div>
            </div>
        </div>
        
        <div className='card mt-3'>
            <div className='card-body'>
                <div className='row'>
                    <div className='  col-md-12'>
                        <DataTable value={cashReport} >
                            <Column field="transaction" header="Transaction"></Column>
                            <Column field="transactionCode" header="Code"></Column>
                            <Column  header="Transaction Date"  body={amountbody}></Column>
                            <Column field="receiver" header="Receiver"></Column>
                            <Column field="amountPaid" className="text-end amount-align" header="Amount Paid" body={(row) => GV.userDet.tenantConfig.currency+ " " + row.amountPaid.toFixed(2)}></Column>
                            <Column field="isGST" header="GST"></Column>
                            <Column field="description" header="Description"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
