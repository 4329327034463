import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { VscNewFile } from "react-icons/vsc";
import { InputTextarea } from "primereact/inputtextarea";
import InvoiceService from "../../../../client/src/services/invoice-service";
import GlobalVariable from "../../../../client/src/services/globalVar";
// import { Tooltip } from "primereact/tooltip";
import { IcomoonReact, iconSet, MultiSelect, Toast } from "../PrimeReact";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
// import { ConfirmPopup } from "../PrimeReact";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Document, Page } from "react-pdf";
import { ProgressSpinner } from "primereact/progressspinner";
import { format } from "date-fns";
import { Tooltip, Tag, Input, Space, Alert } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import InvoicePayment from "./InvoicePayment";
import GV from "../../services/globalVar";
import { AiOutlineSearch } from "react-icons/ai";

// import { Viewer } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const Invoice = () => {
  const is = new InvoiceService();
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const paymodal = useRef(null);
  const [payment, setPayment] = useState("");
  const [invoiceDateValue, setInvoiceDateValue] = useState(null);
  const [paymentDueDate, setPaymentDueDate] = useState(null);
  const [currencyType, setCurrencyType] = useState([
    { id: 1, name: "india", label: "INR" },
    { id: 2, name: "Us", label: "DOLLER" },
  ]);
  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [isNewvoice, setIsNewvoice] = useState(true);
  const [tablesDatas, setTablesDatas] = useState([
    {
      id: 1,
      PRODUCTNAME: "Computer tabloe with power adapter",
      ITEMTYPE: "Goods",
      QUANTITY: 2,
      RATE: 350,
      DISCOUNT: "10%",
      TAX: "15%",
      NETAMOUNT: 35000.0,
      HSN: 457841,
    },
  ]);
  const [productInput, setProductInput] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const inputRef = useRef(null);
  const [first1, setFirst1] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [invoiceGridDatas, setInvoiceGridDatas] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [billToDropDown, setBillToDropDown] = useState([]);
  const [selectedBillToDropDown, setSelectedBillToDropDown] = useState([]);
  const [quantityValue, setQuantityValue] = useState();
  const [discountValue, setDiscountValue] = useState(0);
  const [taxValue, setTaxValue] = useState();
  const [netAmountValue, setNetAmountValue] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState("0.00");
  const [taxAmount, setTaxAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [paymentDueDateCount, setPaymentDueDateCount] = useState(null);
  const [paymentDueFormat, setPaymentDueFormat] = useState();
  const [productDrop, setProductDrop] = useState([]);
  const [selectProductDrop, setSelectProductDrop] = useState([]);
  const [itemType, setItemType] = useState();
  const [productRate, setProductRate] = useState();
  const [isType, setIsType] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [invoiceNumberD, setInvoiceNumberD] = useState();
  const [shipCharge, setShipCharge] = useState(0);
  const [manualErr, setManualErr] = useState(false);
  const [isCustChoose, setIsCustChoose] = useState(true);
  const [isDraft, setIsDraft] = useState(false);
  const [tenantDetails, setTenantDetails] = useState({});
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [sgstTax, setSgstTax] = useState(0);
  const [sgstTaxIndijual, setSgstTaxIndijual] = useState(0);
  const [cgstTax, setCgstTax] = useState(0);
  const [cgstTaxIndijual, setCgstTaxIndijual] = useState(0);
  const [igstTax, setIgstTax] = useState(0);
  const [igstTaxIndijual, setIgstTaxIndijual] = useState(0);
  const [roundOff, setRoundOff] = useState(0);
  const [sgstTaxAmount, setSgstTaxAmount] = useState(0);
  const [cgstTaxAmount, setCgstTaxAmount] = useState(0);
  const [igstTaxAmount, setIgstTaxAmount] = useState(0);
  const [countryTaxDetails, setCountryTaxDetails] = useState({});
  const [isProcess, setIsProcess] = useState(false);
  const [isDiscountDisabled, setIsDiscountDisabled] = useState(true);
  const [salesID, setSalesID] = useState([]);
  const [docUrl, setDocUrl] = useState("");
  const [showDocument, setShowDocument] = useState(false);
  const [rowDataDetails, setRowDataDetails] = useState([]);
  const [showLineItemMesssage, setShowLineItemMesssage] = useState(false);
  const [documentURL, setDocumentURL] = useState("");
  const [documentURLType, setDocumentURLType] = useState("");
  const basiccolumns = [];
  const [selectedColumns, setSelectedColumns] = useState(basiccolumns);
  const [summaryval, setSummaryval] = useState([]);
  const [termName, setTermName] = useState([
    { id: 1, count: 0, name: "Today", label: "today" },
    { id: 2, count: 15, name: "Net 15", label: "Net 15" },
    { id: 3, count: 30, name: "Net 30", label: "Net 30" },
    { id: 4, count: 45, name: "Net 45", label: "Net 45" },
    { id: 5, count: 60, name: "Net 60", label: "Net 60" },
    { id: 6, count: 75, name: "Net 75", label: "Net 75" },
    { id: 7, count: 90, name: "Net 90", label: "Net 90" },
  ]);
  const [selectedTerm, setSelectedTerm] = useState();
  const [editable, setEditable] = useState(false);

  const toast = useRef(null);

  const defaultValues = {
    billto: "",
    // invoicedate: "",
    invoicenumber: "",
    paymentduedate: "",
    ponumber: "",
    term: "",
  };

  const {
    register,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange", defaultValues });

  const [invoiceLines, setInvoiceLines] = useState([
    {
      id: 1,
      productname: "",
      quantity: 0,
      rate: "",
      discount: 0,
      statetax: 0,
      centraltax: 0,
      intertax: 0,
      netamount: "",
      producttype: "",
      shippngcharge: "",
    },
  ]);

  const clearFields = () => {
    setInvoiceLines([
      {
        id: 1,
        productname: "",
        quantity: "",
        rate: "",
        discount: "",
        statetax: "",
        centraltax: "",
        intertax: "",
        netamount: "",
        producttype: "",
        shippngcharge: "",
      },
    ]);
  };

  const addNewLine = () => {
    setIsType(true);
    // setIsDiscountDisabled(true)
    const invalidInvoiceLines = invoiceLines.filter(
      (line) =>
        line.productname === "" ||
        line.quantity === "" ||
        line.discount === "" ||
        line.tax === ""
    );

    if (invalidInvoiceLines.length > 0) {
      setManualErr("Unable to duplicate empty fields");
      return;
    } else {
      setManualErr("");
      const newId = invoiceLines[invoiceLines.length - 1].id + 1;
      const newLine = {
        id: newId,
        productname: "",
        quantity: "",
        rate: "",
        discount: "",
        statetax: "",
        centraltax: "",
        intertax: "",
        netamount: "",
        producttype: "",
      };

      setInvoiceLines((prevInvoiceLines) => [...prevInvoiceLines, newLine]);
    }
  };

  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });

  const invoiceGrid = (page, sizePerPage, searchVal, sortName, sortOrder) => {
    console.log(page);
    setIsProcess(true);
    const data = {
      InputParams: {
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        ContactID: 0,
        Status: "",
      },
      ServerSearchables: {
        draw: 1,
        searchData: searchVal || "",
        orderByColumn: sortName,
        orderBy: sortOrder,
        currentPage: 0,
        pageSize: sizePerPage,
        pageNumber: page,
      },
    };
    is.GetSalesInvoiceListSearch(data).then((res) => {
      setIsProcess(false);
      setInvoiceGridDatas(res.data.data.salesInvoiceList);
      setTotalRecords(res.data.data.serverSearchables.recordsTotal);
    });
  };

  const GetSalesItemList = () => {
    is.GetSalesItemList(GlobalVariable.userDet.tenantDetail.tenantID).then(
      (res) => {
        if (res.data.statusCode === 200) {
          setProductDrop(res.data.data);
        }
      }
    );
  };

  const GenerateConfigNumber = () => {
    const data = {
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      ServiceName: "invoice",
    };
    is.GenerateConfigNumber(data).then((res) => {
      setInvoiceNumberD(res.data.data);
    });
  };

  const handleSelectProduct = (selectedProduct, index) => {
    setIsDiscountDisabled(false);
    setManualErr("");
    const updatedLines = invoiceLines.map((line, i) => {
      if (i === index) {
        return {
          id: line.id,
          productname: selectedProduct,
          quantity: 1,
          rate: selectedProduct.siSellingPrice?.toFixed(2),
          discount: 0,
          statetax: selectedProduct.localTaxRate,
          centraltax: selectedProduct.centralTaxRate,
          intertax: selectedProduct.interTaxRate,
          netamount: selectedProduct.siSellingPrice,
          producttype: selectedProduct.itemType,
        };
      }

      return line;
    });
    handleTaxChangeWithoutIndex(updatedLines, index);
    handleTaxChange(updatedLines);
    setInvoiceLines(updatedLines);
    setIsType(true);
    setSelectProductDrop(selectedProduct);
    setItemType(selectedProduct);
    setProductRate(selectedProduct);
    // handleTaxChange(selectedProduct,index);
  };

  const onCustomPage1 = (event) => {
    console.log("tio");
    setFirst1(event.first);
    const val = event.page + 1;
    const size = event.rows;
    setSizePerPage(size);
    setPage(val);
    invoiceGrid(val, size, searchVal, "", "");
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const handleRowClick = (event) => {
    setRowData(event.data);
  };

  const itemTypeBody = (rowData) => {
    return (
      <div className="row ">
        <div className="d-flex align-items-center p-0">
          <p
            className="m-0 me-2 px-1 custom-p1 d-flex align-items-center"
            style={{
              width: "27%",
              background: rowData.ITEMTYPE === "Goods" ? "green" : "blue",
            }}
          >
            {rowData.ITEMTYPE}
          </p>

          <div className="col-md mx-3">
            <p
              className={`m-0 me-2 ${
                rowData.ITEMTYPE === "Goods" ? "custom-p2" : "custom-p"
              }`}
            >
              {rowData.ITEMTYPE === "Goods" ? "HSN:457841" : " SAC:457841"}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const itemTypeDiscount = (rowData) => {
    return (
      <div className="row ">
        <div className="d-flex align-items-center p-0 justify-content-around">
          <p className="m-0"> {rowData.DISCOUNT} </p>
          <div className="col-md mx-3"></div>
          <Divider layout="vertical" className="m-0" />
          <div className="text-center" style={{ width: "35%" }}>
            <Dropdown
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.value)}
              options={currencyType}
              optionLabel="label"
              className="form-control-custom sm-2 item-type-drp-label"
              style={{ width: "85%", border: "none" }}
            />
          </div>
        </div>
      </div>
    );
  };
  const itemTypeTax = (rowData) => {
    return (
      <div className="row ">
        <div className="d-flex align-items-center p-0 justify-content-around">
          <p
            className="m-0 me-2 px-1 d-flex align-items-center"
            style={{ height: "60%", width: "27%" }}
          >
            {rowData.TAX}
          </p>

          <div className="col-md mx-3"></div>
          <Divider layout="vertical" className="m-0" />
          <div className="text-center" style={{ width: "45%" }}>
            <Dropdown
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.value)}
              options={currencyType}
              optionLabel="label"
              className="form-control-custom sm-2 item-type-drp-label"
              style={{ width: "54%", border: "none" }}
            />
          </div>
        </div>
      </div>
    );
  };

  const itemTypeDelete = (rowData) => {
    return (
      <button
        style={{ background: "transparent" }}
        onClick={() => itemTypeRowDelete(rowData.id)}
      >
        <i
          className="pi pi-trash"
          aria-hidden="true"
          style={{ color: "red" }}
        ></i>
      </button>
    );
  };

  const itemTypeRowDelete = (id) => {
    const updatedData = tablesDatas.filter((item) => item.id !== id);
    setTablesDatas(updatedData);
  };

  const clickTextAreaInput = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const itemTypeProduct = () => {
    return (
      <div style={{ height: "5rem" }}>
        <InputTextarea
          className="w-100"
          style={{ height: "5rem" }}
          placeholder="Type or click to select an item."
          value={productInput}
          onChange={(e) => setProductInput(e.target.value)}
          onClick={clickTextAreaInput}
          rows={5}
          cols={30}
        />
        <Dropdown
          value={selectedCity}
          style={{ top: "-46px", zIndex: "-1" }}
          onChange={(e) => setSelectedCity(e.value)}
          options={cities}
          optionLabel="name"
          editable
          placeholder="Select a City"
          className="w-100"
          show={dropdownVisible}
        />
      </div>
    );
  };

  // const createNewInvoice = () => {
  //   setIsNewvoice(!isNewvoice);
  //   setRowData("");
  // };

  const createNewInvoice = () => {
    setIsNewvoice(!isNewvoice);
    setRowData("");
    setDiscountedAmount("0.00");
    setEditable(false);
    // setIsUpdateDeleteBtn(false)
    // setIsCancelBtn(true)
  };

  const backToAllInvoice = () => {
    setIsType(false);
    setIsUpdate(false);
    reset(defaultValues);
    setInvoiceDate(new Date());
    clearFields();
    setIsNewvoice(!isNewvoice);
    setIsCustChoose(true);
    setNetAmountValue("");
    setInvoiceDateValue("");
    setPaymentDueDateCount("");
    setSelectProductDrop("");
    setItemType("");
    setProductRate("");
    setSubTotal(0);
    setDiscountValue(0);
    setSgstTax(0);
    setCgstTax(0);
    setIgstTax(0);
    setRoundOff(0);
    setNetAmountValue(0);
    setShipCharge(0);
    setCgstTaxAmount(0);
    setSgstTaxAmount(0);
    setIgstTaxAmount(0);
    setDiscountedAmount(0);
    invoiceGrid(page, sizePerPage, searchVal, "", "");
    GenerateConfigNumber();
  };

  const nameBodyTemplate = (rowData) => {
    return rowData.firstName + " " + rowData.lastName;
  };
  const dateBodyTemplate = (rowData) => {
    let formattedDate = "-";

    if (rowData.dueDate !== null) {
      const parsedDate = new Date(rowData.dueDate);
      if (!isNaN(parsedDate.getTime())) {
        formattedDate = format(
          parsedDate,
          GlobalVariable.userDet.tenantConfig.dateFormat
        );
      } else {
        console.error("Invalid date format:", rowData.dueDate);
      }
    }

    return <div>{formattedDate}</div>;
  };
  const idateBodyTemplate = (rowData) => {
    let formattedDate = "-";

    if (rowData.invoiceDate !== null) {
      const parsedDate = new Date(rowData.invoiceDate);
      if (!isNaN(parsedDate.getTime())) {
        formattedDate = format(
          parsedDate,
          GlobalVariable.userDet.tenantConfig.dateFormat
        );
      } else {
        console.error("Invalid date format:", rowData.invoiceDate);
      }
    }

    return <div>{formattedDate}</div>;
  };

  const columns = [
    { field: "companyName", header: "Company Name", sortable: false, body: "" },

    {
      field: "invoiceDate",
      header: "Invoice Date",
      sortable: false,
      body: "idateBodyTemplate",
    },
    {
      field: "discountAmount",
      header: "Discount Amount",
      sortable: false,
      body: "disAmountBodyTemplate",
    },
  ];
  const statusBodyTemplate = (rowData) => {
    let status = rowData.status.toUpperCase();
    let statusClass;

    switch (status) {
      case "OPEN":
        statusClass = "orange";
        break;
      case "PARTIALLY PAID":
        statusClass = "processing";
        break;
      case "PAID":
        statusClass = "green";
        break;
      case "DRAFT":
        statusClass = "purple";
        break;
      case "OVERDUE":
        statusClass = "error";
        break;
      default:
        statusClass = "cyan";
    }
    // switch (status) {
    //   case "OPEN":
    //     statusClass = "#d46b08";
    //     break;
    //   case "PARTIALLY PAID":
    //     statusClass = "#61a3ffb5";
    //     break;
    //   case "PAID":
    //     statusClass = "#87d068";
    //     break;
    //   case "DRAFT":
    //     statusClass = "#b283ff";
    //     break;
    //   case "OVERDUE":
    //     statusClass = "#fd7475";
    //     break;
    //   default:
    //     statusClass = "cyan";
    // }
    return (
      <>
        {" "}
        <Tag bordered={false} color={statusClass}>
          {status}
        </Tag>
      </>
    );
  };

  const reject = () => {};

  const confirmDelete = (rowData) => {
    console.log(rowData, "rowData");
    let obj = {
      // target: event.currentTarget,
      className: "pAdj deletecircle",
      message: "Are you sure you want to proceed?",
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      defaultfocus: "accept",
      accept: () => DeleteSalesInvoice(rowData),
      reject: () => reject(),
    };
    confirmDialog(obj);
  };

  const DeleteSalesInvoice = (rowData) => {
    is.DeleteSalesInvoice(
      GlobalVariable.userDet.tenantDetail.tenantID,
      rowData.salesInvoiceID,
      0
    ).then((res) => {
      if (res.data.statusCode === 200) {
        toast.current.show({
          severity: "success",
          summary: res.data.message,
        });
      }
      invoiceGrid(page, sizePerPage, searchVal, "", "");
    });
  };
  const [editModeData, setEditModeData] = useState({});
  const editData = (rowData) => {
    setEditModeData(rowData);
    const ids = rowData.salesItemsList.flatMap((item) => item);
    console.log(ids, "ids");
    if (rowData.status === "DRAFT") {
      setEditable(false);
    } else {
      setEditable(true);
    }
    setIsNewvoice(!isNewvoice);
    setIsType(true);
    setIsUpdate(true);
    setIsCustChoose(false);
    setIsDiscountDisabled(false);
    let bindBilltoValue = billToDropDown.find(
      (a) => a.firstName === rowData.firstName
    );
    setSelectedBillToDropDown(bindBilltoValue);
    setValue("billto", bindBilltoValue);
    console.log(rowData, "rowData");

    if (rowData.invoiceDate === null) {
      setInvoiceDate(new Date());
    } else {
      const iDate = new Date(rowData.invoiceDate);
      setInvoiceDate(iDate);
    }

    if (rowData.dueDate === null) {
      setValue("paymentduedate", "");
    } else {
      const iDate1 = new Date(rowData.dueDate);
      setValue("paymentduedate", iDate1);
      console.log(getValues("paymentduedate"), '"paymentduedate"');
      const iDate2 = new Date(iDate1);
      const formattedInvoiceDate = iDate2.toISOString().split("T")[0];
      const [year, month, day] = formattedInvoiceDate.split("-");
      const yearInt = parseInt(year);
      const monthInt = parseInt(month) - 1;
      const dayInt = parseInt(day);
      const newDate = new Date(yearInt, monthInt, dayInt);
      const formattedDay = String(newDate.getDate()).padStart(2, "0");
      const formattedMonth = String(newDate.getMonth() + 1).padStart(2, "0");
      const formattedNewDate = `${newDate.getFullYear()}-${formattedMonth}-${formattedDay}`;

      setPaymentDueFormat(iDate2);
    }

    // setValue("invoicenumber", rowData.invoiceNumber);
    setInvoiceNumberD(rowData.invoiceNumber);
    // setValue("paymentduedate", rowData.dueDate);
    setValue("ponumber", rowData.orderNumber);

    let termname = termName.find((a) => a.name === rowData.termName);
    setSelectedTerm(termname);
    setValue("term", termname);

    setSubTotal(rowData.subTotal);
    setShipCharge(rowData.shippingCharge);
    setRoundOff(rowData.roundOff?.toFixed(2));
    setDiscountValue(rowData.discountRate);
    setSgstTax(rowData.localTaxRate);
    setCgstTax(rowData.centralTaxRate);
    setIgstTax(rowData.interTaxRate);
    setNetAmountValue(rowData.totalAmount);
    setDiscountedAmount(rowData.discountAmount?.toFixed(2));
    setSgstTaxAmount(rowData.localTaxAmount);
    setCgstTaxAmount(rowData.centralTaxAmount);
    setIgstTaxAmount(rowData.interTaxAmount);

    const productDetails = rowData?.salesItemsList.map((a, i) => ({
      id: i + 1,
      productname: productDrop.find((x) => x.name === a.itemName) || "",
      quantity: a.quantity || "",
      rate: a.rate || "",
      discount: a.discount || 0,
      statetax: a.localTaxRate || 0,
      centraltax: a.centralTaxRate || 0,
      intertax: a.interTaxRate || 0,
      netamount: a.totalAmount?.toFixed(2) || "",
      producttype: a.itemType || "",
      salesItemID: a.salesItemID || 0,
    }));
    // handleTaxChangeWithoutIndex(i)
    setInvoiceLines(productDetails);
  };

  const onHide = () => {
    setShowDocument(false);
    // setDocUrl("");
  };
  const proceedPay = (data, page) => {
    data.page = page;
    console.log(data, "rowdata");
    setPayment(data);
    paymodal.current.alertToggle(data);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="d-flex">
        <div className="row">
          <div className="col-md d-flex p-0">
            {rowData.status !== "DRAFT" ? (
              <Tooltip title="Edit">
                <IcomoonReact
                  iconSet={iconSet}
                  color="#1763af"
                  size={20}
                  icon="edit"
                  className="pointer  custom-edit-icon"
                  onClick={() => editData(rowData)}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Edit">
                <IcomoonReact
                  iconSet={iconSet}
                  color="#1763af"
                  size={20}
                  icon="edit"
                  className="pointer  custom-edit-icon"
                  onClick={() => editData(rowData)}
                />
              </Tooltip>
            )}

            {rowData.status !== "DRAFT" ? (
              <Tooltip title="View Invoice">
                <i
                  className="pi pi-eye icon-tooltip ms-2"
                  icon="view"
                  style={{ color: "slateblue", fontSize: "1.3rem" }}
                  onClick={() => DownloadDocs(rowData)}
                ></i>
              </Tooltip>
            ) : (
              ""
            )}
            {rowData.status !== "DRAFT" && rowData.status !== "PAID" ? (
              <Tooltip title="Record Payment">
                <IcomoonReact
                  iconSet={iconSet}
                  color="#3e9d71"
                  size={20}
                  icon="payment"
                  className="pointer   ms-2"
                  onClick={() => proceedPay(rowData, "table")}
                />
              </Tooltip>
            ) : (
              ""
            )}
            {rowData.status === "DRAFT" ? (
              <Tooltip title="Delete">
                <IcomoonReact
                  iconSet={iconSet}
                  color="#C20016 "
                  size={20}
                  icon="delete"
                  className="pointer ms-2 "
                  onClick={() => confirmDelete(rowData)}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>

          <Dialog
            visible={showDocument}
            onHide={onHide}
            header="Document"
            modal
            style={{ width: "70%", height: "100%" }}
          >
            {docUrl !== 0 && docUrl !== null && docUrl !== undefined ? (
              <iframe src={docUrl} style={{ width: "100%", height: "850px" }} />
            ) : (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  position: "relative",
                  top: "50%",
                  fontSize: "1.5rem",
                }}
              >
                No document found
              </div>
            )}
          </Dialog>
        </div>
      </div>
    );
  };

  const totalAmountBodyTemplate = (rowData) => {
    return (
      GlobalVariable.userDet.tenantConfig.currency +
      " " +
      rowData.totalAmount.toFixed(2)
    );
  };
  const blanceDueBodyTemplate = (rowData) => {
    return (
      GlobalVariable.userDet.tenantConfig.currency +
      " " +
      rowData.balancePayment.toFixed(2)
    );
  };
  const disAmountBodyTemplate = (rowData) => {
    return (
      <div className="text-end">
        {" "}
        {GlobalVariable.userDet.tenantConfig.currency +
          " " +
          rowData.discountAmount.toFixed(2)}{" "}
      </div>
    );
  };
  const GetContact = () => {
    const data = {
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      ContactType: "Customer",
      CustomerType: "all",
      Status: "Active",
    };
    is.GetContact(data).then((res) => {
      setBillToDropDown(res.data.data);
    });
  };

  const handleSelectToBillDropdown = (e) => {
    setIsCustChoose(false);
    setSelectedBillToDropDown(e);
  };

  const handleSelectTerm = (e) => {
    setSelectedTerm(e);
    const count = e.count;
    const iDate = new Date(invoiceDate);
    const formattedInvoiceDate = iDate.toISOString().split("T")[0];
    const [year, month, day] = formattedInvoiceDate.split("-");
    const yearInt = parseInt(year);
    const monthInt = parseInt(month) - 1;
    const dayInt = parseInt(day);
    const newDate = new Date(yearInt, monthInt, dayInt + count);
    const formattedDay = String(newDate.getDate()).padStart(2, "0");
    const formattedMonth = String(newDate.getMonth() + 1).padStart(2, "0");
    const formattedNewDate = `${newDate.getFullYear()}-${formattedMonth}-${formattedDay}`;

    setPaymentDueFormat(newDate);
    setPaymentDueDateCount(newDate);
    setValue("paymentduedate", newDate);
    console.log(newDate, "newDate");
  };

  const onInvalid = (errors) => console.error(errors);

  function formatDate(date) {
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    // Ensure month and day are two digits
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }

  const onSubmit = (data, status) => {
    setIsProcess(true);
    const invalidInvoiceLines = invoiceLines.filter(
      (line) =>
        line.productname === "" ||
        line.quantity === "" ||
        line.discount === "" ||
        line.tax === ""
    );
    const invalidInvoiceLineserror = invoiceLines.filter(
      (line) => line.quantityError !== ""
    );
    if (invalidInvoiceLines.length > 0) {
      setManualErr("All fields are required for each invoice line");
      setIsProcess(false);
      return;
    } else {
      setManualErr("");
      setIsProcess(false);
    }
    // if(invalidInvoiceLineserror.length != 0){
    //   setManualErr("Check Quantity levels");
    //   setIsProcess(false);
    //   return;
    // }else{
    //   setManualErr("");
    //   setIsProcess(false);
    // }
    setIsProcess(true);
    const iDate = new Date(invoiceDate);
    const formattedInvoiceDate = iDate.toISOString().split("T")[0];
    const datas = {
      SalesInvoiceID: rowData.salesInvoiceID || 0,
      InvoiceType: "",
      InvoiceNumber: invoiceNumberD,
      InvoiceDate: formattedInvoiceDate,
      TermID: 0,
      TermName: selectedTerm.name,
      NumberOfDays: selectedTerm.count,
      DueDate: formatDate(new Date(data.paymentduedate)),
      OrderNumber: data.ponumber,
      CustomerID: selectedBillToDropDown.contactID,
      CustomerGSTIN: selectedBillToDropDown.gstNumber,
      CompanyName: selectedBillToDropDown.companyName,
      FirstName: selectedBillToDropDown.firstName,
      LastName: selectedBillToDropDown.lastName,
      Address1: selectedBillToDropDown.baAddress1,
      Address2: selectedBillToDropDown.baAddress2,
      City: selectedBillToDropDown.saCity,
      State: selectedBillToDropDown.saState,
      ZipCode: selectedBillToDropDown.saZipCode,
      Country: selectedBillToDropDown.saCountry,
      Phone: selectedBillToDropDown.phone,
      Fax: selectedBillToDropDown.saFax,
      Email: selectedBillToDropDown.email,
      SubTotal: subTotal,
      DiscountType: "Percentage",
      DiscountRate: discountValue,
      DiscountAmount: discountedAmount,
      LocalTaxName: countryTaxDetails.localTaxName,
      LocalTaxRate: sgstTax,
      LocalTaxAmount: sgstTaxAmount,
      InterTaxName: countryTaxDetails?.interTaxName,
      InterTaxRate: igstTax,
      InterTaxAmount: igstTaxAmount,
      CentralTaxName: countryTaxDetails?.centralTaxName,
      CentralTaxRate: cgstTax,
      CentralTaxAmount: cgstTaxAmount,
      IsOtherTax: false,
      ShippingCharge: shipCharge,
      RoundOff: roundOff,
      TotalAmount: netAmountValue,
      DocID: 0,
      Status: status || "OPEN",
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      CreatedBy: GlobalVariable.userDet.appUser.appuserID,
      IsItemChanged: true,
      SalesItemsList: invoiceLines.map((a, i) => ({
        SalesItemID: a.salesItemID || 0,
        SalesInvoiceID: rowData.salesInvoiceID || 0,
        ItemID: a.productname.itemID,
        ItemName: a.productname.name,
        ItemType: a.productname.itemType,
        HsnCode: a.productname.saCorHSNCode,
        HsnName: a.productname.itemType === "goods" ? "HSN" : "SAC",
        Unit: a.productname.unit,
        Quantity: a.quantity,
        Rate: a.productname.siSellingPrice,
        DiscountType: "Percentage",
        DiscountRate: discountValue,
        Discount: a.discount,
        LocalTaxName: countryTaxDetails.localTaxName,
        LocalTaxRate: +a.statetax,
        LocalTaxAmount: sgstTaxAmount,
        InterTaxName: countryTaxDetails?.interTaxName,
        InterTaxRate: +a.intertax,
        InterTaxAmount: igstTaxAmount,
        CentralTaxName: countryTaxDetails?.centralTaxName,
        CentralTaxRate: +a.centraltax,
        CentralTaxAmount: cgstTaxAmount,
        IsOtherTax: false,
        TotalAmount: +a.netamount,
        Description: a.productname.piDescription,
        Status: status || "OPEN",
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        CreatedBy: GlobalVariable.userDet.appUser.appuserID,
      })),
    };
    console.log(datas, "datasfgj");
    is.SaveSalesInvoice(datas).then((res) => {
      if (res.data.statusCode === 200) {
        setIsProcess(false);
        toast.current.show({
          severity: "success",
          summary: res.data.message,
        });
        backToAllInvoice();
        getSummary();
      } else {
        setIsProcess(false);
      }
    });
  };

  const invoiceCancel = () => {
    reset();
    setInvoiceDate(new Date());
    setIsType(false);
    setIsUpdate(false);
    setIsCustChoose(true);
    setIsDiscountDisabled(false);
    setNetAmountValue("");
    setPaymentDueDateCount("");
    setSelectProductDrop("");
    setItemType("");
    setProductRate("");
    clearFields();
    setSubTotal(0);
    setDiscountValue(0);
    setSgstTax(0);
    setCgstTax(0);
    setIgstTax(0);
    setRoundOff(0);
    setNetAmountValue(0);
    setShipCharge(0);
    setCgstTaxAmount(0);
    setSgstTaxAmount(0);
    setIgstTaxAmount(0);
    setDiscountedAmount("0.00");
  };

  const invoiceDateselect = (e) => {
    e.stopPropagation();
    setPaymentDueDateCount("");
    setValue("term", "");
    setValue("paymentduedate", "");
    setInvoiceDate(e.target.value);
  };

  const handleInputChangeQuantity = (e, i, name) => {
    console.log(
      i,
      "ei",
      name,
      name.stocks <= e.target.value,
      name.stocks,
      e.target.value
    );
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[i].quantity = e.target.value;
    // const val = +name.stocks + 1
    // newInvoiceLines[i].quantityError = name.itemType != 'service' ? name.stocks >= e.target.value ?  '' : 'Quantity must be less than ' + val + ' ' + name.unit  : '';
    setQuantityValue(e.target.value);
    setInvoiceLines(newInvoiceLines);
    let addSubTotal = newInvoiceLines
      .map((a) => a.rate * a.quantity)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSubTotal(addSubTotal);
    handleTaxChangeWithoutIndex(newInvoiceLines, i);
    handleTaxChange(newInvoiceLines);
  };

  const handleInputChangeDiscount = (e, i) => {
    setManualErr("");
    const inputValue = e.target.value;

    if (inputValue.includes("-")) {
      const sanitizedInput = inputValue.replace("-", "");
      e.target.value = sanitizedInput;
    }

    const newInvoiceLines = [...invoiceLines];
    console.log(newInvoiceLines, "tt");
    newInvoiceLines[i].discount = e.target.value;
    setDiscountValue(e.target.value);
    setInvoiceLines(newInvoiceLines);

    let disPer = newInvoiceLines
      .map((a) => +a.discount)
      .reduce((acc, total) => acc + total, 0);
    let calculatePer = (+disPer * 100) / (newInvoiceLines.length * 100);
    let a = calculatePer;
    setDiscountValue(a);
    handleTaxChangeWithoutIndex(newInvoiceLines, i);
    handleTaxChange(newInvoiceLines);
  };

  // const handleInputChangeTaxState = (e, i) => {
  //   const newInvoiceLines = [...invoiceLines];
  //   newInvoiceLines[i].taxstate = e.target.value;
  //   setInvoiceLines(newInvoiceLines);
  // };
  const handleInputChangeStateTax = (e, index) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[index].statetax = e.target.value;
    setInvoiceLines(newInvoiceLines);
    handleTaxChangeWithoutIndex(newInvoiceLines, index);
    handleTaxChange(newInvoiceLines);
    // let discountPercentage = parseFloat(newInvoiceLines[i].discount) / 100;
    // let quant = Number(quantityValue * selectProductDrop.siSellingPrice);

    // let discountAmount = quant * discountPercentage;

    // console.log(newInvoiceLines[index],'stax');
  };

  const handleInputChangeCentralTax = (e, index) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[index].centraltax = e.target.value;
    setInvoiceLines(newInvoiceLines);
    handleTaxChangeWithoutIndex(newInvoiceLines, index);
    handleTaxChange(newInvoiceLines);
    // console.log(newInvoiceLines[index],'ctax');
  };

  const handleInputChangeInterTax = (e, index) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[index].intertax = e.target.value;
    setInvoiceLines(newInvoiceLines);
    handleTaxChangeWithoutIndex(newInvoiceLines, index);
    handleTaxChange(newInvoiceLines);
    // console.log(newInvoiceLines[index],'itax');
  };

  const handleTaxChange = (newlines) => {
    console.log(newlines, "newlines");
    setInvoiceLines(newlines);
    // setNetAmountValue(0);
    let disPer = newlines
      .map((a) => +a.discount)
      .reduce((acc, total) => acc + total, 0);
    let calculatePer = (+disPer * 100) / (newlines.length * 100);
    setDiscountValue(calculatePer);
    console.log(calculatePer, "calculatePer");

    let addSubTotal = newlines
      .map((a) => a.rate * a.quantity)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSubTotal(addSubTotal);

    let calculateStateTax = newlines
      .map(
        (a, i) =>
          (Number(a.quantity * a.productname.siSellingPrice) / 100) *
          +a.statetax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateStateTaxPercentage = newlines
      .map((a) => +a.statetax)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSgstTax(calculateStateTaxPercentage);

    console.log(calculateStateTaxPercentage, "calculateStateTaxPercentage");
    let calculateCentralTax = newlines
      .map(
        (a, i) =>
          (Number(a.quantity * a.productname.siSellingPrice) / 100) *
          +a.centraltax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateCentralTaxPercentage = newlines
      .map((a) => +a.centraltax)
      .reduce((acc, ttl) => acc + ttl, 0);
    setCgstTax(calculateCentralTaxPercentage);

    let calculateInterTax = newlines
      .map(
        (a, i) =>
          (Number(a.quantity * a.productname.siSellingPrice) / 100) *
          +a.intertax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateInterTaxPercentage = newlines
      .map((a) => +a.intertax)
      .reduce((acc, ttl) => acc + ttl, 0);

    setIgstTax(calculateInterTaxPercentage);

    setSgstTaxAmount(
      isFinite(calculateStateTax) ? calculateStateTax.toFixed(2) : 0
    );
    setCgstTaxAmount(
      isFinite(calculateCentralTax) ? calculateCentralTax.toFixed(2) : 0
    );
    setIgstTaxAmount(
      isFinite(calculateInterTax) ? calculateInterTax.toFixed(2) : 0
    );

    let roundVal = newlines
      .map((b) => +b.netamount - Math.trunc(+b.netamount))
      .reduce((acc, total) => acc + total, 0);
    setRoundOff(isFinite(roundVal) ? roundVal.toFixed(2) : 0);
    let totalAm = newlines
      .map((b) => +b.netamount)
      .reduce((acc, total) => acc + total, 0);
    console.log(
      totalAm,
      newlines.map((b) => +b.netamount),
      "totalAm"
    );
    setNetAmountValue(isFinite(totalAm) ? totalAm : 0);

    console.log(netAmountValue);
  };

  const handleTaxChangeWithoutIndex = (lines, i) => {
    const newInvoiceLines = [...lines];
    let total = 0;

    let totalDiscountAmount = newInvoiceLines
      .map((a) => {
        let discountPercentage = parseFloat(a.discount || 0) / 100;
        let quant = Number(a.quantity * a.productname.siSellingPrice);
        return quant * discountPercentage;
      })
      .reduce((acc, ttl) => acc + ttl, 0);
    console.log(totalDiscountAmount, "totalDiscountAmount");
    let discount =
      totalDiscountAmount && isFinite(totalDiscountAmount)
        ? totalDiscountAmount.toFixed(2)
        : "0.00";
    setDiscountedAmount(discount);

    let sgstIndijual = Number(+newInvoiceLines[i]?.statetax);
    setSgstTaxIndijual(sgstIndijual);

    let cgstIndijual = Number(+newInvoiceLines[i]?.centraltax);
    setCgstTaxIndijual(cgstIndijual);

    let igstIndijual = Number(+newInvoiceLines[i]?.intertax);
    setIgstTaxIndijual(igstIndijual);

    let separateLineItem =
      +newInvoiceLines[i]?.productname.siSellingPrice *
      (+newInvoiceLines[i]?.discount / 100);
    console.log(separateLineItem, "separateLineItem");
    let discountPrice =
      +newInvoiceLines[i]?.productname.siSellingPrice - +separateLineItem;
    console.log(discountPrice, "discountPrice");

    let finalAmount =
      +newInvoiceLines[i]?.quantity *
        +newInvoiceLines[i]?.productname.siSellingPrice -
      +separateLineItem;
    let collectTax =
      +(newInvoiceLines[i]?.statetax || 0) +
      (+newInvoiceLines[i]?.centraltax || 0) +
      (+newInvoiceLines[i]?.intertax || 0);
    let findTax = (finalAmount * collectTax) / 100;
    total = finalAmount + findTax;
    newInvoiceLines[i].netamount = total.toFixed(2);
  };

  const GetTenantDetails = () => {
    is.GetTenantDetails(GlobalVariable.userDet.tenantDetail.tenantID).then(
      (res) => {
        setTenantDetails(res.data.data);
        console.log(res.data.data, "check");
        GetCountryTaxName(res.data.data.country);
      }
    );
  };

  const GetCountryTaxName = (country) => {
    is.GetCountryTaxName(country).then((res) => {
      if (res.data.statusCode === 200) {
        setCountryTaxDetails(res.data.data);
      }
    });
  };

  const getShippingCharge = (e) => {
    const newInvoiceLines = [...invoiceLines];
    const charge = +e.target.value;
    setShipCharge(charge);
    let totalAm = newInvoiceLines
      .map((b) => +b.netamount)
      .reduce((acc, total) => acc + total, 0);
    let withShippin = totalAm + charge;
    setNetAmountValue(withShippin);
  };

  const deleteLineItem = (e, index) => {
    const newInvoiceLines = [...invoiceLines];
    if (newInvoiceLines.length <= 1) {
      toast.current.show({
        severity: "error",
        summary:
          "Item deletion is only possible after adding a second line item",
      });
      return;
    }
    toast.current.show({
      severity: "success",
      summary: "Deleted successfully",
    });
    setIsType(true);
    newInvoiceLines.splice(index, 1);
    setInvoiceLines(newInvoiceLines);
    // handleTaxChangeWithoutIndex(newInvoiceLines, index);
    handleTaxChange(newInvoiceLines);
  };

  const deleteConfirmLineItem = (e, index) => {
    confirmPopup({
      message: "Do you want to delete this lineitem?",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => deleteLineItem(e, index),
      reject,
    });
  };

  const DownloadDocs = (e) => {
    setIsProcess(true);

    const data = {
      DocIDs: e.docID.toString(),
      DocCategory: "SalesInvoice",
    };
    console.log(data, "data");
    is.DownloadDocs(data).then((res) => {
      if (res.data.statusCode === 200) {
        setDocUrl(res.data?.data[0]?.documentURL);
        setShowDocument(true);
        setIsProcess(false);
      }
      console.log(res.data?.data[0]?.documentURL, "doc");
    });
  };
  const invoiceGridEdit = (
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder
  ) => {
    const data = {
      InputParams: {
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        ContactID: 0,
        Status: "",
      },
      ServerSearchables: {
        draw: 1,
        searchData: searchVal || "",
        orderByColumn: sortName,
        orderBy: sortOrder,
        currentPage: 0,
        pageSize: sizePerPage,
        pageNumber: page,
      },
    };
    is.GetSalesInvoiceListSearch(data).then((res) => {
      const val = res.data.data.salesInvoiceList;
      const find = val.filter(
        (x) => x.salesInvoiceID === editModeData.salesInvoiceID
      );
      setEditModeData(find[0]);
    });
  };
  const parentFunction = () => {
    getSummary();
    if (payment.page === "editpage") {
      invoiceGridEdit(page, sizePerPage, searchVal, "", "");
    } else {
      invoiceGrid(page, sizePerPage, searchVal, "", "");
    }
  };
  const summary = (
    <>
      <div
        className="card  card-borderless mb-3 p-2"
        style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
      >
        <p className="mt-1">
          <b>Payment Summary</b>
        </p>
        <div className="row">
          {summaryval.map((x) => {
            return (
              <div className="col-md-3">
                <div className="d-flex ps-2">
                  <IcomoonReact
                    iconSet={iconSet}
                    color={x.color}
                    size={20}
                    icon={x.icon}
                    className="mt-1"
                  />
                  <p className="ms-3">
                    {x.title}
                    <br />
                    <b>
                      {GV.userDet.tenantConfig.currency} {x.amount}
                    </b>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
  const dynamicColumns = selectedColumns.map((col, i) => {
    console.log(col.body);
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={col.sortable}
        body={
          col.body === "idateBodyTemplate"
            ? idateBodyTemplate
            : col.body === "disAmountBodyTemplate"
            ? disAmountBodyTemplate
            : col.body
        }
      ></Column>
    );
  });

  const onColumnToggle = (event) => {
    console.log(event.value, "event");
    let selectedColumns = event.value;
    let addCurrency = selectedColumns.find((a) => {
      if (a.header === "Payables" || "Unused Credits") {
        console.log(GV.userDet.tenantConfig.currency, "gft");
      }
    });
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };
  const keySearch = (e) => {
    setSearchVal(e.target.value);
    console.log(e, "out");
    if (e.key === "Enter") {
      console.log("enter");
      const searched = e.target.value;
      setPage(1);
      invoiceGrid(1, sizePerPage, e.target.value, "", "");
    }
    if (e.type === "keyup") {
      console.log("keyup");
      if (e.target.value === "") {
        invoiceGrid(1, sizePerPage, "", "", "");
      }
    }
    if (e.target.value === "") {
      invoiceGrid(1, sizePerPage, "", "", "");
    }
  };
  const emptySearch = () => {
    setSearchVal("");
    invoiceGrid(1, sizePerPage, "", "", "");
  };
  const getSummary = () => {
    is.GetInvoiceSummary(GV.userDet.appUser.tenantID).then((res) => {
      const responseVal = res.data.data;

      responseVal.forEach((x) => {
        x.amount = x.amount.toFixed(2);
        switch (x.title) {
          case "Total Outstanding Receivables": {
            x.icon = "Asset-13";
            x.color = "#389674";
            break;
          }
          case "Due Today": {
            x.icon = "User-Access";
            x.color = "#384796";
            break;
          }
          case "Due within 30 days": {
            x.icon = "Blocked-Date-and-Time";
            x.color = "#6D3896";
            break;
          }
          case "Overdue Invoice": {
            x.icon = "user";
            x.color = "#965A38";
            break;
          }
          default: {
            x.icon = "Asset-13";
            x.color = "#389674";
          }
        }
      });

      setSummaryval(responseVal);
    });
  };
  const customerDetails = (option) => {
    return (
      <div className="flex align-items-center">
        <div>
          {option.companyName} ( {option.fullName} )
        </div>
      </div>
    );
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    invoiceGrid(page, sizePerPage, searchVal, "", "");
    GetContact();
    getSummary();
    GetSalesItemList();
    GenerateConfigNumber();
    GetTenantDetails();
  }, []);

  return (
    <div>
      <InvoicePayment
        ref={paymodal}
        data={{ payment }}
        parentFunction={parentFunction}
      />
      <BlockUI blocked={isProcess}>
        <Toast ref={toast} />
        {isNewvoice && (
          <div className="row mx-auto">
            <div className="card card-borderless cc-greyCard-custm mb-3">
              <div className="ms-2 my-2 me-2">
                <div className="d-flex justify-content-between flex-wrap mb-2">
                  <h4 className="m-0">Invoice</h4>
                  <div className="d-flex justify-content-end  kk-table-header kk-header-sec cc-font-size-14">
                    <div className="col ">
                      <Space.Compact className=" my-auto">
                        <Input
                          addonBefore={<SearchOutlined />}
                          allowClear
                          placeholder="Search"
                          value={searchVal}
                          onChange={(e) => keySearch(e)}
                          onKeyUp={(e) => keySearch(e)}
                        />
                      </Space.Compact>
                    </div>
                    <button
                      className="btn btn-primary py-1 me-2 mx-2 kk-btnThin my-auto"
                      type="button"
                      onClick={createNewInvoice}
                    >
                      <VscNewFile />
                      Create New Invoice
                    </button>

                    <MultiSelect
                      className="kk-btnThin my-auto"
                      value={selectedColumns}
                      options={columns}
                      optionLabel="header"
                      optionDisabled="disabled"
                      onChange={onColumnToggle}
                      style={{ width: "3em" }}
                      scrollHeight="400px"
                      dropdownIcon="pi pi-table"
                      tooltip="Custom Columns"
                      tooltipOptions={{ position: "top" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {summary}
            <div className="p-0">
              <DataTable
                value={invoiceGridDatas}
                totalRecords={totalRecords}
                tableStyle={{ minWidth: "100%" }}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                lazy
                paginator
                first={first1}
                rows={sizePerPage}
                onPage={onCustomPage1}
                onSort={onSort}
                sortField={lazyParams.sortField}
                sortOrder={lazyParams.sortOrder}
                rowsPerPageOptions={[10, 20, 50]}
                onRowClick={handleRowClick}
              >
                {/* <Column field="code" header="DATE"></Column> */}
                <Column
                  field="invoiceNumber"
                  header="Invoice Number"
                  style={{ width: "15%" }}
                ></Column>
                <Column field="email" header="Email"></Column>
                <Column
                  field="nameBodyTemplate"
                  header="Customer Name"
                  body={nameBodyTemplate}
                ></Column>

                <Column
                  field="dateBodyTemplate"
                  header="Due Date"
                  body={dateBodyTemplate}
                ></Column>
                <Column
                  field="totalAmountBodyTemplate"
                  header="Amount"
                  body={totalAmountBodyTemplate}
                  className="text-end ph"
                ></Column>
                <Column
                  field="blanceDueBodyTemplate"
                  header="Balance Due"
                  body={blanceDueBodyTemplate}
                  className="text-end ph"
                  style={{ justifyContent: "end" }}
                ></Column>
                <Column
                  field="statusBodyTemplate"
                  //  className="text-center"
                  header="Status"
                  body={statusBodyTemplate}
                ></Column>
                {dynamicColumns}

                <Column
                  field="actionBodyTemplate"
                  header="Action"
                  body={actionBodyTemplate}
                ></Column>
              </DataTable>
            </div>
          </div>
        )}
        <ConfirmDialog />
        {isProcess && (
          <ProgressSpinner
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            strokeWidth="2"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
        {!isNewvoice && (
          <form>
            <div>
              <div className="row mx-auto">
                <div
                  className="col-md d-flex justify-content-between align-items-center my-3 p-3"
                  style={{ background: "white" }}
                >
                  <div>
                    <h4> Create New Invoice</h4>
                  </div>
                  <div>
                    <i
                      className="pi pi-download pe-3"
                      aria-hidden="true"
                      style={{ color: "#3E9D71", fontSize: "20px" }}
                    ></i>
                    <i
                      className="pi pi-print"
                      aria-hidden="true"
                      style={{ color: "#3E9D71", fontSize: "20px" }}
                    ></i>
                    <button
                      type="button"
                      className="btn btn-danger ms-4"
                      onClick={backToAllInvoice}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
              <div className="card p-3 ">
                <div className="row ">
                  <div className="col-md-12 d-flex mb-5 justify-content-between">
                    <div className="col-md-5">
                      <label htmlFor="">
                        <strong>Bill from</strong>
                      </label>
                      <div className="p-3 mb-3 card-bill">
                        <p className="m-0">
                          <strong>{tenantDetails.tenantName},</strong>
                        </p>
                        <p className="m-0 ps-2">
                          {tenantDetails.billAddress1},{" "}
                          {tenantDetails.billAddress2}
                        </p>

                        <p className="m-0 ps-2">
                          <strong className="me-2">
                            <i className="pi pi-home"></i>
                          </strong>{" "}
                          {tenantDetails.billCity} - {tenantDetails.billZipCode}{" "}
                          - {tenantDetails.billCountry}
                        </p>
                        <p className="m-0 ps-2">
                          <strong className="me-2">
                            <i className="pi pi-phone"></i>
                          </strong>{" "}
                          {tenantDetails.primaryPhone},{" "}
                          <strong className="m-2">
                            <i className="pi pi-envelope"></i>
                          </strong>{" "}
                          {tenantDetails.email}
                        </p>
                      </div>
                      {/* <Divider className="mb-3" /> */}
                      <div className="row d-flex mb-2 align-items-center">
                        <div className="col-md-2">
                          <label htmlFor="">
                            <strong>Bill to</strong>
                          </label>
                        </div>
                        <div className="col-md-5">
                          <Controller
                            name="billto"
                            control={control}
                            rules={{ required: "This field is required" }}
                            render={({ field }) => (
                              <Dropdown
                                {...field}
                                value={field.value || ""}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  handleSelectToBillDropdown(e.value);
                                }}
                                options={billToDropDown}
                                optionLabel="companyName"
                                itemTemplate={customerDetails}
                                placeholder="Select customer"
                                className="form-control"
                                disabled={editable}
                              />
                            )}
                          />
                          {errors.billto && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                      {!isCustChoose && (
                        <div className="p-3 card-bill">
                          <p className="m-0">
                            <strong>{selectedBillToDropDown.fullName},</strong>
                          </p>
                          <p className="m-0 ps-2">
                            {selectedBillToDropDown.baAddress1}
                          </p>

                          <p className="m-0 ps-2">
                            {selectedBillToDropDown.baState},{" "}
                            {selectedBillToDropDown.baZipCode}
                          </p>
                          <p className="m-0 ps-2">
                            {" "}
                            <strong className="me-2">
                              <i className="pi pi-phone"></i>
                            </strong>{" "}
                            {selectedBillToDropDown.phone},{" "}
                            <strong className="m-2">
                              <i className="pi pi-envelope"></i>
                            </strong>{" "}
                            {selectedBillToDropDown.email}
                            {}
                          </p>
                        </div>
                      )}
                      {isCustChoose && (
                        <div className="p-3 card-bill">
                          <p className="m-0 text-danger">
                            Select Customer to view details
                          </p>
                        </div>
                      )}
                    </div>
                    <Divider layout="vertical" />
                    <div className="col-md-5">
                      <strong>Invoice Details</strong>
                      <div className="row mx-auto mb-2">
                        <div className="col-md">
                          <label htmlFor=""> Invoice Date</label>
                          <Calendar
                            name="invoicedate"
                            value={invoiceDate}
                            className="form-control p-0"
                            onChange={(e) => invoiceDateselect(e)}
                            placeholder="Select invoice date"
                            disabled={editable}
                            // {...register("invoicedate", { required: true })}
                          />
                          {errors.invoicedate && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md">
                          <label htmlFor=""> Invoice Number</label>
                          <input
                            name="invoicenumber"
                            type="text"
                            className="form-control"
                            disabled
                            value={invoiceNumberD}
                          />
                        </div>
                      </div>
                      <div className="row mx-auto mb-3">
                        <div className="col-md">
                          <label htmlFor=""> Term</label>
                          <Controller
                            name="term"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Dropdown
                                {...field}
                                value={field.value || ""}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  handleSelectTerm(e.value);
                                }}
                                options={termName}
                                optionLabel="name"
                                placeholder="Select Term"
                                className="form-control "
                                disabled={editable}
                              />
                            )}
                          />
                          {errors.term && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>{" "}
                        <div className="col-md">
                          <label htmlFor=""> Payment Due Date</label>
                          <Calendar
                            name="paymentduedate"
                            value={getValues("paymentduedate")}
                            className="form-control p-0"
                            disabled
                            placeholder="Select term"
                            {...register("paymentduedate")}
                          />
                        </div>
                      </div>
                      <div className="row mx-auto mt-2">
                        <div className="col-md">
                          <label htmlFor=""> PO Number </label>
                          <input
                            name="ponumber"
                            type="text"
                            className="form-control sm-2"
                            placeholder="NA"
                            {...register("ponumber", { required: false })}
                            disabled={editable}
                          />
                          {errors.ponumber && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md"> </div>
                      </div>
                    </div>
                  </div>
                  <Divider className="ms-5" style={{ width: "94%" }} />
                </div>
                {isProcess && (
                  <ProgressSpinner
                    style={{
                      width: "50px",
                      height: "50px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    strokeWidth="2"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                )}
                <div className="row">
                  <div className="col-md">
                    <div className="table-responsive">
                      <table className="table mt-3 ">
                        <thead>
                          <tr className="heading">
                            <th style={{ width: "18%" }} className="borderIn">
                              Product Name
                            </th>
                            <th className="borderIn">Item Type</th>
                            <th className="borderIn" style={{ width: "5%" }}>
                              Quantity
                            </th>
                            <th className="borderIn" style={{ width: "6%" }}>
                              Rate (
                              {GlobalVariable.userDet.tenantConfig.currency})
                            </th>
                            <th className="borderIn" style={{ width: "6%" }}>
                              Discount (%)
                            </th>
                            <th colSpan="3">Tax (%)</th>
                            <th className="borderIn" style={{ width: "7%" }}>
                              Net Amount (
                              {GlobalVariable.userDet.tenantConfig.currency})
                            </th>
                            {editable === false ? (
                              <th style={{ width: "3%" }}></th>
                            ) : (
                              <></>
                            )}
                          </tr>
                          <tr className="sub-heading">
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th className="borderIn">
                              {countryTaxDetails?.localTaxName}
                            </th>
                            <th className="borderIn">
                              {countryTaxDetails?.centralTaxName}
                            </th>
                            <th className="borderIn">
                              {countryTaxDetails?.interTaxName}
                            </th>
                            <th></th>
                            {editable === false ? <th></th> : <></>}
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceLines.map((line, index) => (
                            <tr key={index}>
                              <td>
                                <Dropdown
                                  name="productname"
                                  value={line.productname}
                                  onChange={(e) => {
                                    handleSelectProduct(e.value, index);
                                  }}
                                  options={productDrop}
                                  optionLabel="name"
                                  placeholder="Choose to select"
                                  className="form-control-custom sm-2 "
                                  disabled={editable}
                                />
                              </td>
                              <td>
                                {isType && (
                                  <div className="row">
                                    <div className="col-md">
                                      <input
                                        style={{
                                          background: "black !important",
                                        }}
                                        type="text"
                                        name="producttype"
                                        className={`form-control text-center ${
                                          line.producttype === "goods"
                                            ? "custom-p2-back"
                                            : "custom-p-back"
                                        }`}
                                        value={line.producttype}
                                        disabled
                                      />
                                    </div>
                                    <div
                                      className={`col-md d-flex justify-content-center align-items-center ${
                                        line.producttype === "goods"
                                          ? "custom-p2"
                                          : "custom-p"
                                      }`}
                                    >
                                      {line.producttype === "goods" ? (
                                        <label htmlFor="">HSN :</label>
                                      ) : (
                                        <label htmlFor="">SAC :</label>
                                      )}
                                      {line.productname?.saCorHSNCode}{" "}
                                    </div>
                                  </div>
                                )}
                              </td>
                              <td>
                                <div className="d-flex justify-content-between">
                                  <input
                                    type="text"
                                    name="quantity"
                                    className="form-control cc-alignend "
                                    disabled={
                                      !line.productname
                                        ? true
                                        : false || editable
                                    }
                                    value={line.quantity}
                                    onInput={(e) => {
                                      handleInputChangeQuantity(
                                        e,
                                        index,
                                        line.productname
                                      );
                                    }}
                                  />
                                  <span className="ms-2 mt-1">
                                    {line?.productname.unit}
                                  </span>
                                </div>
                                <p className="text-danger">
                                  {" "}
                                  {line.quantityError}
                                </p>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="rate"
                                  className="form-control cc-alignend"
                                  disabled
                                  value={line.rate}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="discount"
                                  // disabled={!line.productname ? true : false}
                                  className="form-control cc-alignend"
                                  // defaultValue={0}
                                  value={line.discount}
                                  onInput={(e) => {
                                    handleInputChangeDiscount(e, index);
                                  }}
                                  disabled={editable}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="statetax"
                                  className="form-control cc-alignend"
                                  disabled
                                  title="To adjust the tax percentage, please access the Inventory section"
                                  value={line.statetax}
                                  onInput={(e) => {
                                    handleInputChangeStateTax(e, index);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="centraltax"
                                  className="form-control cc-alignend"
                                  disabled
                                  title="To adjust the tax percentage, please access the Inventory section"
                                  value={line.centraltax}
                                  onInput={(e) => {
                                    handleInputChangeCentralTax(e, index);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="intertax"
                                  className="form-control cc-alignend"
                                  disabled
                                  title="To adjust the tax percentage, please access the Inventory section"
                                  value={line.intertax}
                                  onInput={(e) => {
                                    handleInputChangeInterTax(e, index);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="netamount"
                                  className="form-control cc-alignend"
                                  value={line.netamount}
                                  disabled
                                />
                              </td>
                              {editable === false ? (
                                <td
                                  style={{ textAlign: "center", padding: "1%" }}
                                >
                                  <button
                                    type="button"
                                    style={{ background: "transparent" }}
                                    onClick={() => deleteLineItem(line, index)}
                                  >
                                    <i
                                      className="pi pi-trash"
                                      aria-hidden="true"
                                      style={{ color: "red" }}
                                    ></i>
                                  </button>
                                </td>
                              ) : (
                                <></>
                              )}
                            </tr>
                          ))}
                          {manualErr ? (
                            <p className="text-danger my-3">{manualErr}</p>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mx-auto my-4">
                      {editable === false ? (
                        <div className="col-md p-0">
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={addNewLine}
                          >
                            + Add New invoice Line
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-md"></div>
                      <div className="col-md"></div>
                      <div
                        className="col-md p-3"
                        style={{
                          background: "rgb(226 243 252)",
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <p> Sub Total</p>
                          <p>
                            {" "}
                            <i
                              className="pi pi-indian-rupee"
                              aria-hidden="true"
                            ></i>{" "}
                            {GlobalVariable.userDet.tenantConfig.currency}{" "}
                            {subTotal}
                          </p>
                        </div>
                        {/* <Divider className="m-0" /> */}

                        {/* <Divider className="m-0" /> */}
                        <div className="d-flex justify-content-between">
                          <p> Tax(SGST)</p>
                          <p>
                            {" "}
                            <i
                              className="pi pi-indian-rupee"
                              aria-hidden="true"
                            ></i>{" "}
                            {GlobalVariable.userDet.tenantConfig.currency}{" "}
                            {sgstTaxAmount}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p> Tax(CGST)</p>
                          <p>
                            {" "}
                            <i
                              className="pi pi-indian-rupee"
                              aria-hidden="true"
                            ></i>{" "}
                            {GlobalVariable.userDet.tenantConfig.currency}{" "}
                            {cgstTaxAmount}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p> Tax(IGST)</p>
                          <p>
                            {" "}
                            <i
                              className="pi pi-indian-rupee"
                              aria-hidden="true"
                            ></i>{" "}
                            {GlobalVariable.userDet.tenantConfig.currency}{" "}
                            {igstTaxAmount}
                          </p>
                        </div>
                        {/* <div className="d-flex justify-content-between">
                        <p> Tax Amount</p>
                        <p>
                          {" "}
                          <i
                            className="pi pi-indian-rupee"
                            aria-hidden="true"
                          ></i>{" "}
                          {taxAmount}
                        </p>
                      </div> */}
                        <div className="d-flex justify-content-between">
                          <p> Shipping Charges</p>
                          <p className="text-end">
                            {" "}
                            {GlobalVariable.userDet.tenantConfig.currency}
                            <input
                              value={shipCharge}
                              type="number"
                              name="shippngcharge"
                              className="text-end"
                              style={{ width: "31%" }}
                              disabled={editable}
                              onInput={(e) => {
                                getShippingCharge(e);
                              }}
                            />
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p> Round off</p>
                          <p>
                            {" "}
                            <i
                              className="pi pi-indian-rupee"
                              aria-hidden="true"
                            ></i>{" "}
                            {GlobalVariable.userDet.tenantConfig.currency}{" "}
                            {roundOff}
                          </p>
                        </div>
                        <Divider className="m-0" />
                        <div className="d-flex justify-content-between">
                          <p> Discount </p>
                          {discountedAmount !== "0.00" ? (
                            <p className="text-danger">
                              - {GlobalVariable.userDet.tenantConfig.currency}{" "}
                              {discountedAmount}
                            </p>
                          ) : (
                            <p className="">
                              {" "}
                              {
                                GlobalVariable.userDet.tenantConfig.currency
                              }{" "}
                              {discountedAmount}
                            </p>
                          )}
                        </div>
                        <div className="d-flex justify-content-between">
                          <p>
                            <strong> Total</strong>
                          </p>
                          <p>
                            {" "}
                            <strong>
                              <i
                                className="pi pi-indian-rupee"
                                aria-hidden="true"
                              ></i>{" "}
                              {GlobalVariable.userDet.tenantConfig.currency}{" "}
                              {netAmountValue?.toFixed(2)}
                            </strong>
                          </p>
                        </div>
                        {editable === true ? (
                          <div className="d-flex justify-content-between">
                            <p>
                              <strong> Balance Due</strong>
                            </p>
                            <p>
                              {" "}
                              <strong>
                                <i
                                  className="pi pi-indian-rupee"
                                  aria-hidden="true"
                                ></i>{" "}
                                {GlobalVariable.userDet.tenantConfig.currency}{" "}
                                {editModeData.balancePayment?.toFixed(2)}
                              </strong>
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <Divider className="m-0" />
                      </div>

                      {editModeData.balancePayment?.toFixed(2) === "0.00" ? (
                        <div className="row mx-auto mt-3 p-0">
                          {" "}
                          <div className="col-md-8 p-0"></div>
                          <div className="col-md-4 p-0">
                            <Alert
                              message="Success "
                              description="No Due! Full amount paid"
                              type="success"
                              showIcon
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="d-flex mt-3 justify-content-end gap-3 p-0">
                        {editable === false ? (
                          <Button
                            className="btn btn-danger my-3 "
                            type="button"
                            onClick={invoiceCancel}
                            label="Cancel"
                          />
                        ) : (
                          <>
                            <Button
                              className="btn btn-danger my-3 "
                              type="button"
                              onClick={backToAllInvoice}
                              label="Back"
                            />
                            {editModeData.balancePayment?.toFixed(2) !==
                            "0.00" ? (
                              <Button
                                className="btn btn-primary my-3 "
                                type="button"
                                onClick={() =>
                                  proceedPay(editModeData, "editpage")
                                }
                                label="Record Payment"
                              />
                            ) : (
                              ""
                            )}
                          </>
                        )}
                        {editable === false ? (
                          <>
                            <Button
                              className="btn btn-primary my-3"
                              onClick={handleSubmit((e) =>
                                onSubmit(e, "DRAFT")
                              )}
                              type="button"
                              label="Save Draft"
                            />

                            {!isUpdate ? (
                              <Button
                                type="button"
                                className="btn btn-primary my-3"
                                label="Save & Submit"
                                onClick={handleSubmit(
                                  (e) => onSubmit(e, "OPEN"),
                                  onInvalid
                                )}
                                style={{ marginRight: "3%" }}
                              />
                            ) : (
                              <Button
                                type="button"
                                className="btn btn-primary my-3"
                                label="Update"
                                onClick={handleSubmit((e) =>
                                  onSubmit(e, "OPEN")
                                )}
                                style={{ marginRight: "3%" }}
                              />
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ConfirmPopup />
          </form>
        )}
      </BlockUI>
    </div>
  );
};

export default Invoice;
