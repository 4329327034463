import React, { useEffect, useState, useRef } from "react";
import { Tree, TreeTable, Column, Checkbox, Button } from "../../PrimeReact";
import GlobalVariable from "../../../services/globalVar";
import SecurityService from "../../../services/security-service";
import { InputSwitch } from "primereact/inputswitch";
import { useForm } from "react-hook-form";
import { Toast } from "primereact/toast";
import { Sidebar } from "primereact/sidebar";
import { ProgressSpinner } from "primereact/progressspinner";

const UserRole = () => {
  const ss = new SecurityService();
  const toast = useRef(null);
  const [isProcess, setIsProcess] = useState(false);
  const [selectedNodeKeys, setSelectedNodeKeys] = useState(null);
  const [selectedNodeKeys1, setSelectedNodeKeys1] = useState(null);
  const [selectedNodeKeysSide, setSelectedNodeKeysSide] = useState(null);
  const [userRoleDatas, setUserRoleDatas] = useState([]);
  const [nodess, setNodess] = useState([]);
  const [getRoleClick, setGetRoleClick] = useState([]);
  const [getRoleClick1, setGetRoleClick1] = useState([]);
  const [checked, setChecked] = useState(false);
  const [isChildClicked, setIsChildClicked] = useState(true);
  const [isChildClicked1, setIsChildClicked1] = useState(false);
  const [isHideBtn, setIsHideBtn] = useState(true);
  const [visibleRight, setVisibleRight] = useState(false);
  const [updateRoleId, setUpdateRoleId] = useState(false);
  const [isCheckBoxShow, setIsCheckBoxShow] = useState(true);
  const [sidebarDatas, setSidebarDatas] = useState([]);
  const [associatedFutureList, setAssociatedFutureList] = useState([]);
  const [deleteSer, setDeleteSer] = useState(null);
  const [role, setRole] = useState();
  const [roleRename, setRoleRename] = useState();
  const [selectService, setSelectService] = useState('');
  const [showErr, setShowErr] = useState('');
  const [storeUserType, setStoreUserType] = useState();
  const [expandedKeys1, setExpandedKeys1] = useState([]);
  const [expandedKeys2, setExpandedKeys2] = useState([]);
  const [expandedKeys3, setExpandedKeys3] = useState([]);
  const [getTreeDetails, setGetTreeDetails] = useState([]);

  const [data, setData] = useState([
    {
      key: "1",
      data: {
        name: "User Type: Employee",
        type: "parent",
        background: "light",
        expanded: false,
        icon: "pi pi-plus",
      },
      children: [
        {
          key: "1.1",
          data: {
            name: "Node 1.0",
            type: "child",
            background: "white",
            icon: "pi pi-leaf",
          },
        },
        {
          key: "1.2",
          data: {
            name: "Nodes 1.2",
            type: "child",
            background: "white",
            icon: "pi pi-leaf",
          },
        },
      ],
    },
    {
      key: "2",
      data: {
        name: "User Type: Human Resource",
        type: "parent",
        background: "light",
        expanded: false,
        icon: "pi pi-plus",
      },
      children: [
        {
          key: "2.1",
          data: {
            name: "Nodes 1.3",
            type: "child",
            background: "white",
            icon: "pi pi-leaf",
          },
        },
        {
          key: "2.2",
          data: {
            name: "Nodes 1.3",
            type: "child",
            background: "white",
            icon: "pi pi-leaf",
          },
        },
      ],
    },
    {
      key: "3",
      data: {
        name: "User Type: Management",
        type: "parent",
        background: "light",
        expanded: false,
        icon: "pi pi-plus",
      },
      children: [
        {
          key: "3.1",
          data: {
            name: "Nodes 1.4",
            type: "child",
            background: "white",
            icon: "pi pi-leaf",
          },
        },
        {
          key: "3.2",
          data: {
            name: "Nodes 2.4",
            type: "child",
            background: "white",
            icon: "pi pi-leaf",
          },
        },
      ],
    },
  ]);

  const handleCheckboxChange = (node) => {
    node.selected = !node.selected;
    setData([...data]);
  };

  const checkboxTemplate = (node) => {
    if (node.children) {
      // Parent node
      return (
        <div className="checkbox-parent">
          <Checkbox
            checked={node.selected}
            onChange={() => handleCheckboxChange(node)}
          />
          <span className="mx-2">{node.name}</span>
        </div>
      );
    } else {
      // Leaf node
      return (
        <div className="checkbox-leaf">
          <Checkbox
            checked={node.selected}
            onChange={() => handleCheckboxChange(node)}
          />
          <span className="mx-2">{node.name}</span>
        </div>
      );
    }
  };

 
  const defaultValues = {
    rolename: "",
    roledesc: "",
  };

  const {
    control,
    setValue,
    formState: { errors },
    reset,
    register,
    handleSubmit,
  } = useForm({ mode: "all", defaultValues });

  const userRoloe = (usertypeid) => {
    const data = {
      EntityID: GlobalVariable.userDet.tenantDetail.tenantID,
      EntityType: "Tenant",
      ProductID: 6810,
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      UserTypeID: GlobalVariable.userDet.appUser.userTypeID,
    };
    
    ss.GetUserTypeRoles(data).then((res) => {
      if (!usertypeid) userTypeService(res.data.data[0].userTypeID);
      setGetRoleClick(res.data?.data[0]);

      const transformedData = res.data?.data?.map((data) => ({
        key: data.userTypeInstID.toString(),
        label: data.userType,
        data: {
          serviceName: data.userType,
          description: data.description,
          // cn: (
          //   <i
          //     className="pi pi-times userrole-remove me-2"
          //     onClick={(e) => deleteRole(data)}
          //   ></i>
          // ),
          data: data,
        },
        isParent: Object.keys(data).includes("userType"),
        children: renderSubMenu(data.roles, data),
      }));
   
      setUserRoleDatas(transformedData);
    });
  };

  const renderSubMenu = (roles, parentData) => {
    console.log(roles,'check');
    return roles.map((role) => ({
      key: role.roleId.toString(),
      label: role.roleName,
      data: {
        serviceName: role.roleName,
        cn:
          role.roles.length < 0 ? (
            <i
              className="pi pi-times userrole-remove me-2"
              // onClick={(e) => deleteRole(parentData)}
            ></i>
          ) : null,
        data: role,
      },
      isParent: Object.keys(role).includes("userType"),
      icon: role.roles.length > 0,
      children: role.roles.length > 0 ? renderSubMenu(role.roles, role) : [],
    }));
  };

  const nodeTemplate = (node) => {
    let nodeClassName = "";
    let iconClassName = "pi pi-plus";
    let iconClassName1 = "pi pi-times";

    if (node.isParent) {
      nodeClassName = "parent-node";
      iconClassName = "pi pi-plus";
    } else if (node.icon) {
      nodeClassName = "child-node";
      iconClassName = "pi pi-times";
    } else if (!node.icon) {
      nodeClassName = "leaf-node";
      iconClassName = "pi pi-times";
      iconClassName1 = "pi pi-plus";
    }

    return (
      <div
        className={`tree-node ${nodeClassName} d-flex `}
        style={{ width: "100%" }}
      >
        <div className="tree-node-content ">
          <span>{node.label}</span>
          <div className="text-end">
            {node.isParent && <span
              className={`pi pi-plus`}
              onClick={(e) => addNewRole(e, node,"first")}
            ></span>}
            {!node.isParent && <span
              className={`pi pi-plus`}
              onClick={(e) => addNewRole(e, node,"second")}
            ></span>}
            
            {!node.icon && !node.isParent && <span
              className={`pi pi-times`}
              onClick={(e) => DeleteUserRole(e, node)}
            ></span>}
          </div>
        </div>
      </div>
    );
  };


  const addNewRole = (e, data, item) => {
    reset({
      rolename: "",
      roledesc: "",
    });
    setRoleRename('');
    setIsCheckBoxShow(true);
    renderRolePath();
    e.stopPropagation();
    setIsChildClicked(false);
    setIsHideBtn(true);
    setUpdateRoleId(false);
    const isShow = Object.keys(data.data.data).includes("userType");
    setIsChildClicked1(isShow);
    setGetRoleClick(data);
    userTypeService(data.data.data.userTypeID);
    setStoreUserType(data.data.data.userTypeID);
  };

 const roleClick = (e, item) => {
  console.log(e.node.data.data, 'path'); 
  setRoleRename(item);
  setIsCheckBoxShow(false);
  setIsChildClicked1(true);
  setIsHideBtn(false);
  setUpdateRoleId(true);
  setIsChildClicked(Object.keys(e.node.data.data).includes("userType"));
 
  setGetRoleClick1(e, item);
  setRole(e.node.data.data);
  setValue("rolename", e.node.data.data.roleName);
  setValue("roledesc", e.node.data.data.roleDescription);
  AssociatedFeaturesServices(e.node.data.data);
  AssociatedFeaturesServices1(e.node.data.data);
  setGetRoleClick(e.node.data.data);
  
  const selectedNodeKey = e.node.key;
  const ancestors = findAncestors(userRoleDatas, selectedNodeKey);
  setGetTreeDetails(ancestors);
};


  useEffect(() => {
    if (getRoleClick) {
      console.log('getRoleClick updated:', getRoleClick);
    }
  }, [getRoleClick]); 

  const YourComponent = () => {
    
    return (
      <div className="my-2">
        <span style={{ fontSize: "17px" }}>Role Path :</span>
        {getTreeDetails.map((a, i) => (
          <span key={i}>
            {a.label}
            {i !== getTreeDetails.length - 1 && ' > '}
          </span>
        ))}
      </div>
    );
  };

  // const deleteRole = (datas) => {
  //   setIsProcess(true);
  //   const currentGetRoleClick = deleteSer;

  //   console.log(datas,currentGetRoleClick,'deleteSer');
  //   const data = {
  //     RoleID: currentGetRoleClick.roleId,
  //     EntServiceIDs: datas.entServiceId.toString(),
  //   };

  //   console.log(data,'remove');
  //   // ss.RemoveRoleService(data).then((res) => {
  //   //   if(res.data.statusCode === 200) {
  //   //     setIsProcess(false);
  //   //     toast.current.show({
  //   //       severity: "success",
  //   //       detail: res.data.errors,
  //   //     });
  //   //   }
  //   // })
  //   // AssociatedFeaturesServices1(getRoleClick);
  //   // AssociatedFeaturesServices(getRoleClick);
  //   setIsProcess(false);
   

  // };

  const DeleteUserRole = (e, node) => {
    e.stopPropagation();
    const datas = {
      RoleID: node.data.data.roleId,
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
    };
    ss.DeleteUserRole(datas).then((res) => {
      if(res.data.statusCode === 200) {
        toast.current.show({
          severity: "success",
          // summary: "Success",
          detail: res.data.message,
        });
        userRoloe(node.data.data.userTypeID);
      }
    });
  };



  const AssociatedFeaturesServices = (e) => {
    // setVisibleRight(true)
    // setDeleteSer(e.roleId);
    console.log(e,'AssociatedFeaturesServices');
    const deleteRole = (datas,roleId) => {
      setIsProcess(true);
      console.log(datas,roleId,'deleteSer');
      const data = {
        RoleID: roleId,
        EntServiceIDs: datas.entServiceId.toString(),
      };
      console.log(data,'remove');
      ss.RemoveRoleService(data).then((res) => {
        if(res.data.statusCode === 200) {
          setIsProcess(false);
          toast.current.show({
            severity: "success",
            detail: res.data.errors,
          });
        }
      })
      AssociatedFeaturesServices1(e);
      AssociatedFeaturesServices(e);
      setIsProcess(false);
    };
    const data = {
      RoleID: e.roleId,
      UserTypeID: e.userTypeID,
    };
    ss.GetAssociateRoleServices(data).then((res) => {
      const filteredData = res.data?.data?.filter((a) => a.isAssociate);
      const treeTableData = filteredData?.map((a, i) => ({
        key: a.entServiceId,
        index: i,
        data: {
          serviceName: a.serviceName,
          icon: (
            <i
              className="pi pi-times userrole-remove me-2"
              onClick={(e) => deleteRole(a,data.RoleID)}
            ></i>
          ),
        },
        children: a.subMenu.filter(x => x.isAssociate).map((b, j) => ({
          key: b.entServiceId,
          index: j,
          data: {
            serviceName: b.serviceName,
            icon: (
              <i
                className="pi pi-times userrole-remove me-2"
                onClick={(e) => deleteRole(b,data.RoleID)}
              ></i>
            ),
          },
        })),
      }));
      setAssociatedFutureList(treeTableData);
    });
   
  };

  const AssociatedFeaturesServices1 = (e) => {
    const data = {
      RoleID: e.roleId,
      UserTypeID: e.userTypeID,
    };
    ss.GetAssociateRoleServices(data).then((res) => {
      function recursiveCollect(urlArray, collectionArray) {
        urlArray.forEach((item) => {
          if (item.isAssociate) {
            collectionArray.push(item.entServiceId);
          }
          if (Array.isArray(item.subMenu)) {
            recursiveCollect(item.subMenu, collectionArray);
          }
        });
      }
      const urlCollectionArray = [];
      recursiveCollect(res.data?.data, urlCollectionArray);

      const filteredData = urlCollectionArray.reduce((acc, b) => {
        acc[b] = {
          checked: true,
          partialChecked: false,
        };
        return acc;
      }, {});
      const treeTableData = res.data?.data?.map((a, i) => ({
        key: a.entServiceId,
        index: i,
        data: {
          serviceName: a.serviceName,
        },
        children: a.subMenu.map((b, j) => ({
          key: b.entServiceId,
          index: j,
          data: {
            serviceName: b.serviceName,
          },
        })),
      }));
      setSidebarDatas(treeTableData);
      setSelectedNodeKeysSide(filteredData);
    });
  };

  const handleCheckIsCheckBox = (e) => {
    setSelectedNodeKeys(e.value);
    setSelectedNodeKeysSide(e.value);
  };

  const handleSelectionChange = (e) => {
    setSelectedNodeKeys1(e.value);
    setSelectedNodeKeysSide(e.value);

  };

  const addRoleService = (data) => {

    if (!data.rolename || data.rolename.trim() === "") {
      setShowErr("This field is requird");
      return; 
    } else {
      setShowErr("");
    }
  
    if (roleRename === "row") {
      renderRolePath();
      const numericKeys = Object.keys(selectedNodeKeysSide)
      const EntServices = numericKeys.join(',');
      setIsProcess(true);
      const datas = {
        RoleID: getRoleClick.roleId,
        RoleName:  data.rolename.trim(),
        RoleDescription: data.roledesc,
        ReportToRoleID: getRoleClick.reportToRoleId,
        RoleCategory:getRoleClick.roleCategory,
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        UserTypeID: getRoleClick.userTypeID || 0,
        EntServices: EntServices,
        IsManager: false,
        EntityID: GlobalVariable.userDet.tenantDetail.tenantID,
        EntityType: "Tenant",
      };
      ss.AddRoleServices(datas).then((res) => {
        if (res.data.statusCode === 200) {
          setIsProcess(false);
          toast.current.show({
            severity: "success",
            detail: res.data.message,
          });
          reset();
          userRoloe(getRoleClick.userTypeID);
          AssociatedFeaturesServices1(getRoleClick);
        }
      });
    } else {
      renderRolePath();
      if(!selectedNodeKeysSide) {
        setSelectService("Choose atleast one service")
        return
      } else {
        setSelectService("")

      }
      const numericKeys = Object?.keys(selectedNodeKeysSide)
      const EntServices = numericKeys.join(',');
      setIsProcess(true);
      const datas = {
        RoleID: getRoleClick.isParent ? getRoleClick?.data?.data?.roleId : 0 ,
        RoleName:  data.rolename.trim(),
        RoleDescription: data.roledesc,
        ReportToRoleID: getRoleClick?.data?.data?.roleId || 0 ,
        RoleCategory: getRoleClick ? getRoleClick?.data?.data?.userType : getRoleClick?.data.data.roleCategory,
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        UserTypeID: getRoleClick?.data?.data?.userTypeID,
        EntServices: EntServices,
        IsManager: false,
        EntityID: GlobalVariable.userDet.tenantDetail.tenantID,
        EntityType: "Tenant",
      };
      ss.AddRoleServices(datas).then((res) => {
        if (res.data.statusCode === 200) {
          setIsProcess(false);
          toast.current.show({
            severity: "success",
            detail: res.data.message,
          });
          reset();
          userRoloe(getRoleClick.userTypeID);
          AssociatedFeaturesServices1(getRoleClick);
          setSelectedNodeKeys1("")
         
        }
      });
    }
   
  };

  const addRoleServiceSideDrawer = (data) => {
    renderRolePath();
    const numericKeys = Object.keys(selectedNodeKeysSide)
    const EntServices = numericKeys.join(',');
    const datas = {
      RoleID: getRoleClick.roleId  ,
      RoleName:  getRoleClick.roleName,
      RoleDescription: getRoleClick.roleDescription,
      ReportToRoleID: getRoleClick.reportToRoleId  ,
      RoleCategory: getRoleClick.userType ? getRoleClick.userType : getRoleClick.roleCategory,
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      UserTypeID: getRoleClick.userTypeID,
      EntServices: EntServices,
      IsManager: false,
      EntityID: GlobalVariable.userDet.tenantDetail.tenantID,
      EntityType: "Tenant",
    };
    ss.AddRoleServices(datas).then((res) => {
      if (res.data.statusCode === 200) {
        toast.current.show({
          severity: "success",
          detail: res.data.message,
        });
        userRoloe(getRoleClick.userTypeID);
        AssociatedFeaturesServices(getRoleClick);
        setVisibleRight(false);
        userTypeService(getRoleClick.userTypeID);
      }
    });
  };

  const renderRolePath = () => {
    return `${getRoleClick.roleCategory} > ${getRoleClick.roleName}`;
  };


  // const renderRolePath = (role, items) => {
  //   console.log(role.node ,'rr');
  //   let collect = role.node.data;
  //   let collectChildren = role.node.children;
  //   const rolePath = [];
  //   let currentRole = role;
  //   while (currentRole) {
  //     rolePath.unshift(currentRole.roleName); 
  //     currentRole = currentRole.parentRole; 
  //   }
  //   return rolePath.join(' > ');
  // };
  

  const findAncestors = (treeData, selectedNodeKey) => {
    const ancestors = [];
    const traverseTree = (nodes, key, ancestors) => {
      for (let node of nodes) {
        if (node.key === key) {
          ancestors.push(node);
          return true;
        }
        if (node.children && node.children.length > 0) {
          if (traverseTree(node.children, key, ancestors)) {
            ancestors.push(node);
            return true;
          }
        }
      }
      return false;
    };
    traverseTree(treeData, selectedNodeKey, ancestors);
    return ancestors.reverse();
  };
  
  

  const associatedRenderSubMenu = (roles, parentData) => {
    return roles.map((role) => ({
      key: role.roleId?.toString(),
      label: role.roleName,
      data: {
        serviceName: role.serviceName,
        data: role,
      },
      isParent: Object.keys(role).includes("userType"),
      icon: role.roles?.length > 0,
      children: role.roles?.length > 0 ? renderSubMenu(role.roles, role) : [],
    }));
  };

  const userTypeService = (userTypeId) => {
    const data = {
      UserTypeID: userTypeId,
    };
    ss.GetUserTypeServices(data).then((res) => {
      if (res.data.statusCode === 200) {
        const transformedData = res.data?.data?.map((data,i) => ({
          key: data.entServiceId,
          index:i,
          label: data.userType,
          data: {
            serviceName: data.serviceName,
            description: data.description,
            cn: (
              <i
                className="pi pi-times userrole-remove me-2"
                // onClick={(e) => deleteRole(data)}
              ></i>
            ),
            data: data,
          },
          children: data.subMenu.map((a,j) => ({
           key: a.entServiceId,
           index:j,
           data : {
            serviceName: a.serviceName
           }
          })),
          isParent: Object.keys(data).includes("userType"),
          // associatedRenderSubMenu(transformedData),
        }));
        setAssociatedFutureList(transformedData);
      }
    });
  };

  useEffect(() => {
    userRoloe(0);
  }, []);

  return (
    <div className="row">
      <Toast ref={toast} />
      <div className="col-md-4 bg-light user-type-role p-2">
        <h6 className="mb-0 fw-bold text-dark">User Roles</h6>
        <Tree
          value={userRoleDatas}
          expandedKeys={expandedKeys1}
          onToggle={(e) => setExpandedKeys1(e.value)}
          onNodeClick={(e) => roleClick(e,"row")}
          nodeTemplate={nodeTemplate}
          selectionMode="single"
          className="custom-tree p-0 urole border-0"
        />
      </div>

      <div className="col-md-8">
        <div>
          <div>
            <div>
              {isChildClicked && (
                <div className="p-3" style={{ background: "#ebf8ff" }}>
                  <h6 className="text-dark">
                    <strong>User Type:</strong>
                    <span > {getRoleClick.userType} </span>
                  </h6>
                  <h6 className="text-dark">
                    <strong>Description:</strong>
                    <span > {getRoleClick.description}</span>
                  </h6>
                </div>
              )}
            </div>
            {!isChildClicked && (
              <div>
                <p
                  className="p-2"
                  style={{ background: "#dee1e6", fontSize: "17.5px" }}
                >
                  Add/Edit Role
                </p>
              </div>
            )}
            {!isChildClicked && (
              <div className="my-2">
                {YourComponent()}
              </div>
            )}
            <form onSubmit={handleSubmit(addRoleService)}>
              <div className="row mx-auto">
                {!isChildClicked && (
                  <div className="col-md-12 p-0">
                    <div className="row mx-auto my-2">
                      <div className="col-md-3 ps-0">
                        <label
                          htmlFor=""
                          className="required"
                          style={{ fontSize: "17px" }}
                        >
                          Role Name
                        </label>
                        <input
                          type="text"
                          name="rolename"
                          className="form-control"
                          {...register("rolename", { required: true })}
                        />
                        {errors.rolename && (
                          <p className="text-danger">This field is required</p>
                        )}
                        {<p className="text-danger">{showErr}</p>}
                      </div>
                      <div className="col-md-7">
                        <label htmlFor="" style={{ fontSize: "17px" }}>
                          Role Description
                        </label>
                        <input
                          type="text"
                          name="roledesc"
                          className="form-control"
                          {...register("roledesc")}
                        />
                      </div>
                      <div className="col-md">
                        <label htmlFor="" style={{ fontSize: "15px" }}>
                          User Role External?
                        </label>
                        <div className="text-center mt-1">
                          <InputSwitch
                            checked={checked}
                            onChange={(e) => setChecked(e.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  {!isHideBtn && (
                    <div className="d-flex my-2">
                      <p style={{ fontSize: "16px" }} className="fw-bold">
                        Associated Features / Services
                      </p>
                      <h6 className="ms-auto">
                        <Button
                          type="button"
                          label="Associated Features / Services"
                          icon="pi pi-plus"
                          className="btn btn-primary"
                          onClick={(e) => setVisibleRight(true)}
                        />
                      </h6>
                    </div>
                  )}
                </div>
              </div>
              <div className="my-3"></div>
              {isProcess && (
                  <div className="login-spin d-flex justify-content-center">
                    {" "}
                    <ProgressSpinner
                      style={{
                        width: "40px",
                        height: "50px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      strokeWidth="1"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />{" "}
                  </div>
                )}
              <div style={{overflowY:"auto", height:"30rem"}}>
                <p style={{ fontSize: "17.5px" }}>
                  Following are features & services associated with this user
                  type
                </p>
                <TreeTable
                  className="border-0 service-tbl mb-3"
                  value={associatedFutureList}
                  resizableColumns
                  selectionMode={isCheckBoxShow ? "checkbox" : ""}
                  selectionKeys={selectedNodeKeys1}
                  expandedKeys={expandedKeys2}
                  onToggle={(e) => setExpandedKeys2(e.value)}
                  onSelectionChange={(e) => handleSelectionChange(e)}
                  tableStyle={{ minWidth: "50rem" }}
                >
                  <Column
                    field="serviceName"
                    header="Service Name"
                    expander
                    className="service-tbl-name userrole"
                  ></Column>
                  <Column field="description" header="Description"></Column>
                  <Column field="icon" header="" className="text-end" ></Column>
                </TreeTable>
              </div>
              {!isChildClicked && (
                <div className="row mx-auto  mb-2">
                  <div className="col-md d-flex justify-content-between">
                  <p className="text-danger">{selectService}</p>
                    <button
                      type="submit"
                      className="btn btn-primary "
                      style={{ width: "8%" }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <div className="card">
        <Sidebar
          visible={visibleRight}
          position="right"
          onHide={() => setVisibleRight(false)}
        >
          <h6 className="my-3">ASSOCIATE SERVICES / FEATURES</h6>
          <div style={{ fontSize: "17px" }}>
            Check all the features / Services
          </div>
          <div className="my-3">
            <TreeTable
              className="border-0 service-tbl mb-3"
              value={sidebarDatas}
              resizableColumns
              selectionMode="checkbox"
              expandedKeys={expandedKeys3}
              onToggle={(e) => setExpandedKeys3(e.value)}
              selectionKeys={selectedNodeKeysSide}
              onSelectionChange={(e) => {
                handleCheckIsCheckBox(e);
              }}
              tableStyle={{ width: "100%", height: "100%" }}
            >
              <Column
                field="serviceName"
                expander
                className="service-tbl-name userrole sidetree-header"
              ></Column>
            </TreeTable>
          </div>
          <div className="row">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-danger me-2"
                onClick={(e) => setVisibleRight(false)}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={addRoleServiceSideDrawer}>
                Save
              </button>
            </div>
          </div>
        </Sidebar>
      </div>
    </div>
  );
};

export default UserRole;
