import React, { useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import recentinvoices from './json/recentinvoices';
import recentestimates from './json/recentestimates';
// import ApexCharts from 'apexcharts';
// import Chart from "react-apexcharts";

// import Header from '../layouts/Header'
// import Sidebar from '../layouts/Sidebar'
import '../../assets/styles/dashboard.scss';

function Dashboard() {
    const [menu, setMenu] = useState(false)

	const toggleMobileMenu = () => {
		setMenu(!menu)
	  }

	const salesOptions= {
			colors: ['#7638ff', '#fda600'],
			chart: {
				type: 'bar',
				fontFamily: 'Poppins, sans-serif',
				height: 350,
				toolbar: {
					show: false
				}
			},
			series : [
				{
				name: "Received",
				type: "column",
				data: [70, 150, 80, 180, 150, 175, 201, 60, 200, 120, 190, 160, 50]
				},
				{
				name: "Pending",
				type: "column",
				data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16, 80]
				}
			],  
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '60%',
					endingShape: 'rounded'
				},
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent']
			},
			xaxis: {
				categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
			},
			yaxis: {
				title: {
					text: '$ (thousands)'
				}
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return "$ " + val + " thousands"
					}
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					chart: {
						width: 200
					},
					legend: {
						position: 'bottom'
					}
				}
			}]
		},
		invoiceOptions = {
            colors: ['#7638ff', '#ff737b', '#fda600', '#1ec1b0'],
            chart: {
                fontFamily: 'Poppins, sans-serif',
                height: 350,
                type: 'donut',
			},
			series: [55, 40, 20, 10],
            labels: ['Paid', 'Unpaid', 'Overdue', 'Draft'],
            legend: {show: false},
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
		recentestimates_= recentestimates,
		recentinvoices_ =  recentinvoices;
		useEffect(() => {
			// let salesColumn =  document.getElementById("sales_chart");
			// let salesChart = new ApexCharts(salesColumn, salesOptions);
			// salesChart.render();
			
			// let invoiceColumn =  document.getElementById("invoice_chart");
			// let invoiceChart = new ApexCharts(invoiceColumn, invoiceOptions);
			// invoiceChart.render();
		  },[]);
		  
		
        return (
	
			<div className={`main-wrapper ${menu ? 'slide-nav': ''}`}> 
          
              {/* <Header onMenuClick={(value) => toggleMobileMenu()} />
              <Sidebar /> */}
			
			<div className="page-wrapper">
				<div className="content container-fluid">

					<div className="row mt-2">
						<div className="col-xl-3 col-sm-6 col-12">
							<div className="card">
								<div className="card-body dash">
									<div className="dash-widget-header">
										<span className="dash-widget-icon bg-1">
                                        <i className="pi pi-dollar" style={{'fontSize':'32px'}}></i>
                                            {/* <FontAwesomeIcon icon="check-square" /> */}
										</span>
										<div className="dash-count">
											<div className="dash-title">Amount Due</div>
											<div className="dash-counts">
												<p>1,642</p>
											</div>
										</div>
									</div>
									<div className="progress progress-sm mt-3">
										<div className="progress-bar bg-5" role="progressbar" style={{width: '75%'}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
									<p className="text-muted mt-3 mb-0"><span className="text-danger me-1"><i className="fas fa-arrow-down me-1"></i>1.15%</span> since last week</p>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-sm-6 col-12">
							<div className="card">
								<div className="card-body dash">
									<div className="dash-widget-header">
										<span className="dash-widget-icon bg-2">
                                        <i className="pi pi-user" style={{'fontSize':'32px'}}></i>

										</span>
										<div className="dash-count">
											<div className="dash-title">Customers</div>
											<div className="dash-counts">
												<p>3,642</p>
											</div>
										</div>
									</div>
									<div className="progress progress-sm mt-3">
										<div className="progress-bar bg-6" role="progressbar" style={{width: '65%'}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
									<p className="text-muted mt-3 mb-0"><span className="text-success me-1"><i className="fas fa-arrow-up me-1"></i>2.37%</span> since last week</p>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-sm-6 col-12">
							<div className="card">
								<div className="card-body dash">
									<div className="dash-widget-header">
										<span className="dash-widget-icon bg-3">
                                        <i className="pi pi-file" style={{'fontSize':'32px'}}></i>
										</span>
										<div className="dash-count">
											<div className="dash-title">Invoices</div>
											<div className="dash-counts">
												<p>1,041</p>
											</div>
										</div>
									</div>
									<div className="progress progress-sm mt-3">
										<div className="progress-bar bg-7" role="progressbar" style={{width: '85%'}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
									<p className="text-muted mt-3 mb-0"><span className="text-success me-1"><i className="fas fa-arrow-up me-1"></i>3.77%</span> since last week</p>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-sm-6 col-12">
							<div className="card">
								<div className="card-body dash">
									<div className="dash-widget-header">
										<span className="dash-widget-icon bg-4">
                                        <i className="pi pi-flag" style={{'fontSize':'32px'}}></i>
										</span> 
										<div className="dash-count">
											<div className="dash-title">Estimates</div>
											<div className="dash-counts">
												<p>2,150</p>
											</div>
										</div>
									</div>
									<div className="progress progress-sm mt-3">
										<div className="progress-bar bg-8" role="progressbar" style={{width: '45%'}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
									<p className="text-muted mt-3 mb-0"><span className="text-danger me-1"><i className="fas fa-arrow-down me-1"></i>8.68%</span> since last week</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row">

					</div>
                    {/* <div className="row mt-2">
                            <div className="col-md-8">
                                <div className="card">
                                    <div className="card-body">
                                        <Chart
                                            options={this.state.options}
                                            series={this.state.series}
                                            type="bar"
                                            width="500"
                                            />
                                    </div>
                                </div>
                            </div>
                    </div> */}
				
				</div>
			
			</div>
		</div>
        );
}

export default Dashboard;
