import React , {useState} from 'react';
import { IoMenuSharp } from 'react-icons/io5';
import { ImSearch } from 'react-icons/im';
import { FaRegBell } from 'react-icons/fa';
import {HiChevronDown} from 'react-icons/hi';
import userImage from '../../assets/img/user.png';
import '../../assets/styles/layout/topbar.scss'
import logo from '../../assets/img/leftmenu.png'
import LoginService from '../../services/login';
import GV from '../../services/globalVar'

export default function TopBar(props) {
    const lgs = new LoginService()
    const [activeMenu,setActiveMenu]= useState(true);
    const [showMenu,setShowMenu]=useState(false);
    
    const callbackMenu=(val)=>{
        props.data.menuCallback(val);
    }
    const callbackMenu1=(val)=>{
        props.data.menuCallback1(val);
    }

    const toggleMenu=()=>{
        setActiveMenu(!activeMenu);
        // setShowMenu(false);
        callbackMenu(!activeMenu); 
    }
    const toggleSideMenu= ()=>{
        setShowMenu(!showMenu);
        setActiveMenu(!activeMenu);
        callbackMenu1(!showMenu,!activeMenu); 
    }
  const refreshTok = () =>{
      console.log(GV.userDet.appUser.appuserID)
      const data = {
        "RefreshToken":localStorage.getItem('lv'),
        "AppUserID":GV.userDet.appUser.appuserID
      }
            lgs.RefreshToken(data).then(res=>{
            localStorage.setItem('token',res.data.data.accessToken);
                window.location.reload();
            })
  }

    const [compLogo,setComplogo] = useState('assets/images/kolims grn.svg')
    return (
        <div className='wrapper topbar'>
            <nav className="navbar navbar-expand-lg  p-0">
                <div className="container-fluid bx-shadow " style={{height:'67px', zIndex:"1000"}}>
                    {/* <a className="navbar-brand me-0" href="#"><h3>KADAMBA</h3></a> */}
                    <div className='me-5'>
                        {GV.userDet.tenantDetail.logo  != '' &&  GV.userDet.tenantDetail.logo != null ?   <img src={GV.userDet.tenantDetail.logo}  alt='Company Logo' style={{width: 'auto',height: '50px'}}/>   : 
                         <img src={logo}  alt='Kokadamba' style={{width:'82%'}}/>
                         }
                   
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation"
                    onClick={toggleSideMenu}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" >
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {GV.userDet.tenantConfig.isConfigCompleted === true ? 
                            <li className='nav-item menu-icon ' style={{lineHeight:'0'}}>
                                {/* <IoMenuSharp size="35" onClick={toggleMenu} /> */}
                            </li> : ''}
                            <li className="nav-item ms-5 mt-2 pt-1 ">   
                                <div className="input-group search-group mb-3 rounded ">
                                    <input style={{display:'none'}} type="text" className="form-control py-2" placeholder="Search Here" aria-label="search" aria-describedby="basic-addon2"  />
                                    <span style={{display:'none'}} className="input-group-text" id="basic-addon2"><ImSearch /></span>
                                </div>
                            </li>
                        </ul>
                        <span className="navbar-text">
                            {/* <button className='btn btn-primary me-3'  onClick={()=>{refreshTok()}}><i className='pi pi-refresh  font-size-18' ></i></button> */}
                            <FaRegBell size="22" />
                        </span>
                        {/* <span className='username'> id="navbarText" */}

                        <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false">
                               <img src={userImage} className="rounded-circle me-2" width="36" height="36" alt="user image"/>
                               {GV.userDet.appUser.userName}
                               <HiChevronDown color='#838383' size={25} style={{ cursor: "pointer" }} />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                               
                                <li><a className="dropdown-item" href="/organization-profile">Settings</a></li>
                                <li><a className="dropdown-item" onClick={()=>{localStorage.clear()}}href="/">Logout</a></li>
                                {/* <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                            </ul>
                        </div>
                        {/* </span> */}
                    </div>
                </div>
            </nav>
        </div>
    );
}