import { Calendar } from 'primereact/calendar';
import React, { useState } from "react";
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import JournalService from '../../services/journal';
import LedgerService from '../../services/ledger';
import {Dropdown} from '../PrimeReact'
import GV from '../../services/globalVar';
import LSContact from './LSContact';
import LSContactClone from './LSContactClone';
import LSCOA from './LSCOA';
import { format } from 'date-fns';

const defaultValues = {
    searchbased: '',
    search: "",
    period:'',
    from:'',
    to:''
  };

function LedgerSearch() {
    const ldgs = new LedgerService();
    const jor = new JournalService();
  const { register,handleSubmit,reset, formState:{errors}, control,setValue} = useForm({mode:"all",defaultValues});
  const periodStatus=[
    // {label: "All", value:"All", status:false},
    {label: "Today", value:"Today", status:false},
    {label: "This Week", value:"This Week", status:false},
    {label: "This Month", value:"This Month", status:false},
    {label: "This Quarter", value:"This Quarter", status:false},
    {label: "This Year", value:"This Year", status:false},
    {label: "Custom", value:"Custom", status:true}
];
const [searchInit,setSearchInit] = useState(false);
const [periodStatusSelected, setPeriodStatusSelected] = useState(null);
const [dates2, setDates2] = useState("");
const [startDate, setStartDate] = useState("");
const [endDate, setEndDate] = useState("");
const [accountData,setAccountData] = useState([]);
const [contactData,setContactData] = useState([]);
const [searchoptData,setSearchoptData] = useState([]);
const [searchSelected,setSearchSelected] = useState([]);
const [ledgerData,setLedgerData]=useState({})
const periodChange =(e) =>{
    console.log(e)
    setPeriodStatusSelected(e.value)
    setValue('period',e.value)
      
}
const searchLedger=(data)=>{
    console.log(data.search)
    const datatoPass = 
    {
        "InputParams": {"TenantID":109,"TransactionType":"All","SearchBasedOn":data.searchbased,"ContactIDorCOAID":data.searchbased == 'Contact' ? data.search.contactID:data.search.coaMasID,"ContactNameorCOAName":data.searchbased == 'Contact' ? data.search.displayName:data.search.coaName,"Period":data.period,"FromDate":data.period == 'Custom'?format(new Date(data.from),'MM/dd/yyyy'):'',"ToDate":data.period == 'Custom'?format(new Date(data.to),'MM/dd/yyyy'):''},
        "ServerSearchables":{"draw":1,"searchData":"","orderByColumn":"","orderBy":"","pageSize":5,"pageNumber":1}
        }
    
    ldgs.GetLedgerSearchData(datatoPass).then((res) => {
            console.log(res)
            setLedgerData(res.data.data)
            setSearchInit(true)
    })
    console.log(datatoPass,'GetLedgerSearchData')
}
function handleRangeDate(e) {
    setStartDate(e.target.value[0]);
    setEndDate(e.target.value[1]);
    setDates2(e.target.value);
    setValue('from',e.target.value[0])
    setValue('to',e.target.value[1])
}
const getContactandCoaDetails = () =>{
    jor.GetMasCOA(0, GV.userDet.tenantDetail.tenantID, "Active", "All")
            .then((response) => {
                // if (isMounted) {
                    let b = [];
                    // let a = [];
                    if (response.data) {
                        if (response.data.statusCode === 200) {
                            // setTdataLoaded(true);
                            const data = response.data.data;
                            let accounts = []
                            data.forEach(x=>{
                                x.category.forEach(y=>{
                                    accounts = [...accounts,y]
                                })
                                x.subCategory.forEach(z=>{
                                    accounts =[...accounts,z]
                                })
                                // accounts = [...accounts,x.category,x.subCategory]
                            })
                            console.log(accounts,"AAA")
                            setAccountData(accounts);
                            // response.data.data.map((acc) => {
                            //     acc.category.map((sub) => {
                            //         return b.push(sub);
                            //     })
                            //     acc.subCategory.map((sub) => {
                            //         return b.push(sub);
                            //         })
                            // });
                            // setSubCategory(b);
                        } else {
                            // setTdataLoaded(false);
                        }
                    }
                // }
            }).catch(function (error) {

            })

    //Contact
    let reqData = {
        "TenantID": GV.userDet.tenantDetail.tenantID,
        "ContactType": "All",
        "CustomerType": "All",
        "Status": "All"
    }
    jor.GetContact(reqData)
        .then((response) => {
            // if (isMounted) {
                if (response.data) {
                    if (response.data.statusCode === 200) {
                        // setTdataLoaded(true);
                        setContactData(response.data.data);
                    } else {
                        // setTdataLoaded(false);
                    }
                }
            // }
        }).catch(function (error) {
        })
}
const changeSearchBased = (val) =>{
    console.log(val,"Ss")
    setSearchSelected(val)
    if(val == 'Contact'){
        setSearchoptData(contactData);

    }else{
    setSearchoptData(accountData);
    }
}
const groupedItemTemplate = (option,props) => {
    // console.log(props)
    if (option) {
        return (
            <div >
                <div> {option?.displayName} - {option?.companyName}</div>
            </div>
        );
    }
    return (
        <span>
            {props.placeholder}
        </span>
    );
}
const groupedValueTemplate = (option) =>{
    return (
        <div >
            <div> {option?.displayName} - {option?.companyName}</div>
        </div>
    );
}
const handletChange = (e, opt) => {
        console.log(e.value)
        setValue('search',e.value)
}
useEffect(()=>{
    setValue('period',periodStatus[0].value)
    setPeriodStatusSelected(periodStatus[0].value)
    getContactandCoaDetails();
},[])
console.log(defaultValues.search,"POOF")
  return <div>
      {searchInit == false? <>
        <h5>Detail Ledger</h5>
        <div className="card">
            <div className="card-body">
                <form>
                    <div className="row">
                        <div className="col-6">
                           
                            <label className="form-label mb-1 required" htmlFor="searchbased">Search Based on</label>
                            <div className="px-1 pt-2 mb-1">
                                <label className="md-3 g-0 me-4"  onChange={(e)=> changeSearchBased('Contact')} ><input type="radio"   value="Contact"    
                                    name="searchbased"   {...register("searchbased",{required:"This field is required"})}
                                    onChange={(e) => setValue(...e.target.value)} /> Conatcts</label>
                                <label className="form-label"  onChange={(e)=>{changeSearchBased('ChartOfAccount')}}><input type="radio"   value="ChartOfAccount"  
                                    name="searchbased"  {...register("searchbased",{required:"This field is required"})}
                                    onChange={(e) => setValue(...e.target.value)} /> Chart of Accounts</label>
                                    
                                     {/* <label htmlFor="searchbased1">
                                        <input   
                                            {...register("searchbased")}
                                            type="radio"
                                            value="Contacts"
                                            id="searchbased1"
                                        />
                                        Contacts
                                    </label>
                                    <label htmlFor="searchbased2" className='ms-4'>
                                        <input  
                                            {...register("searchbased")}
                                            type="radio"
                                            value="Chart of Accounts"
                                            id="searchbased2"
                                        />
                                        Chart of Accounts
                                    </label> */}
                            </div>
                            <p className='text-danger mb-0'> {errors.searchbased?.message} </p>  
                        </div>
                        <div className="col-6">
                          
                                <label className='form-control-label'>Period</label><br/>
                               
                                <Controller  
                                        render={({ field }) => (
                                            <Dropdown  options={periodStatus}  className="col-md-12 "
                                            {...field}  
                                            appendTo="self"
                                            optionLabel="label" optionValue="value"   placeholder="Select " onChange={periodChange}   />
                                        )}
                                        control={control}
                                        name="period"
                                        defaultValue={defaultValues.period}
                                        rules={{required:"This field is Required"}}
                                    />
                           
                                <p className='text-danger mb-0'> {errors.period?.message} </p>  
                        </div>
                        <div className="col-6">
                                <label className='form-label'>Search</label>
                                {/* <input type="text" className="form-control"     {...register("search",{required:"This field is required"})} /> */}
                                {/* <Controller  
                                        render={({ field }) => (
                                            <Dropdown  options={searchoptData}  className="col-md-12 "
                                            {...field}  
                                            // appendTo="self"
                                            optionLabel="displayName" 
                                            // optionValue="contactID" 
                                            placeholder="Select"   
                                            onChange={(e) => (handletChange( e, searchoptData))}
                                            value={defaultValues.search}
                                            valueTemplate={groupedItemTemplate}
                                             itemTemplate={groupedValueTemplate} 
                                              />                                   
                                        )}
                                        control={control}
                                        name="search"
                                        defaultValue={defaultValues.search}
                                        rules={{required:"This field is Required"}}
                                    /> */}
                                    {searchSelected == 'Contact'? 
                                    <Controller  
                                        render={({ field }) => (
                                            <Dropdown  options={searchoptData}  className="col-md-12 "
                                            {...field}  
                                            appendTo="self" 
                                            optionLabel="displayName"  placeholder="Select "   onChange={(e) => (handletChange( e, searchoptData))} 
                                            filter showClear filterBy="displayName" />
                                        )}
                                        control={control}
                                        name="search"
                                        defaultValue={defaultValues.search}
                                        rules={{required:"This field is Required"}}
                                    />
                                    : <Controller  
                                    render={({ field }) => (
                                        <Dropdown  options={searchoptData}  className="col-md-12 "
                                        {...field}  
                                        appendTo="self"
                                        optionLabel="coaName"  placeholder="Select "   onChange={(e) => (handletChange( e, searchoptData))} 
                                        filter showClear filterBy="coaName" />
                                    )}
                                    control={control}
                                    name="search"
                                    defaultValue={defaultValues.search}
                                    rules={{required:"This field is Required"}}
                                />}
                                <p className='text-danger mb-0'> {errors.search?.message} </p>  
                        </div>
                        <div className="col-6 cc-calendar">
                        {periodStatusSelected == 'Custom' ? 
                           <>
                                <label className='form-control-label'>Duration</label> <br/>
                                {/* <input type="text" className="form-control"     {...register("from",{required:"This field is required"})} /> */}
                                {/* onChange={(e) => (handletChange(index, e))}   minDate={startDate} maxDate={endDate}  */}
                                <Calendar className=' p-0 col-md-12 ' id="range" value={dates2}  maxDate={new Date()} onChange={(e) => handleRangeDate(e)} selectionMode="range" readOnlyInput showIcon />
                                <p className='text-danger mb-0'> {errors.from?.message} </p>  
                            </>
                        :''}
                         </div>
                    </div>
                    <div className="d-flex justify-content-end mt-4">
                        <button className='btn btn-primary' onClick={ handleSubmit( searchLedger)}>Search</button>
                    </div>
                </form>
            </div>
        </div>
        </> 
        :  searchSelected == 'Contact' ? 
                <>
                    <LSContactClone obj={ledgerData}  setSearchInit={setSearchInit}/>
                </> :
                 <>
                    <LSCOA  obj={ledgerData}  setSearchInit={setSearchInit}/>
                </>
                }
  </div>;
}

export default LedgerSearch;
