import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import {
  DataTable,
  Column,
  Dropdown,
  InputText,
  MultiSelect,
  iconSet,
  IcomoonReact,
} from "../PrimeReact.js";
import "../../assets/styles/accountant/chartofaccount.scss";
import { AiOutlineSearch } from "react-icons/ai";
import NewItem from "./NewItem";
import ItemsService from "../../services/items";
import { propTypes } from "react-bootstrap/esm/Image";
import GV from "../../services/globalVar";
import { SearchOutlined } from "@ant-design/icons";
import { Tooltip, Input, Space } from "antd";

const ListofItems = () => {
  const modalref = useRef(null);
  const its = new ItemsService();
  const [first1, setFirst1] = useState(0);
  const [tdataLoad, setTdataLoad] = useState(false);
  const [data, setData] = useState([]);
  const [totalRecordsIn, setTotalRecordsIn] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [isMounted, setIsMounted] = useState(true);
  const [requestData, setRequestData] = useState([]);
  const [accountSelected, setAccountSelected] = useState("All");
  const [itemId, setItemId] = useState("");

  const itemStatus = [
    { label: "Active & Inactive", value: "All", status: true, type: "All" },
    { label: "Active ", value: "Active", status: true, type: "All" },
    { label: "Inactive ", value: "Inactive", status: true, type: "All" },
  ];
  const itemType = [
    { label: "Goods & Service", value: "All", status: true },
    { label: "Goods", value: "Goods", status: true },
    { label: "Service", value: "Service", status: true },
  ];
  const [statusSelected, setStatusSelected] = useState(itemStatus[0].value);
  const [filterSelected, setFilterSelected] = useState(itemStatus[0].value);
  const [typeSelected, settypeSelected] = useState(itemType[0].value);

  const editProduct = (product) => {
    setItemId(product.itemID);
    modalref.current.alertToggledit(product);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <IcomoonReact
          iconSet={iconSet}
          color="#444"
          size={20}
          icon="edit"
          className="pointer"
          onClick={() => editProduct(rowData)}
        />
      </React.Fragment>
    );
  };
  const siSellingPriceBodyTemplatePrice = (rowData) => {
    return (
      <div className="text-end amount-align">
        {" "}
        {GV.userDet.tenantConfig.currency +
          " " +
          rowData.siSellingPrice.toFixed(2)}{" "}
      </div>
    );
  };

  const sipiCostPriceBodyTemplatePrice = (rowData) => {
    return (
      GV.userDet.tenantConfig.currency + " " + rowData.piCostPrice.toFixed(2)
    );
  };

  const panelHeaderTemplate = () => {
    return (
      <div className="pt-2 ps-4">
        {/* <p className='mb-0 fs-6'>Select</p> */}
      </div>
    );
  };
  const basiccolumns = [
    // { field: 'name', header: 'Name' },
    // { field: 'stocks', header: 'In Stock' },
    // { field: 'siSellingPrice', header: 'Rate' },
    // { field: 'siDescription', header: 'Description' },
    // { field: 'action', exportable: false, header: 'Action', sortable: false, body: { actionBodyTemplate }, disabled: true }
  ];
  const columns = [
    // { field: 'name', header: 'Name' },
    // { field: 'stocks', header: 'In Stock' },
    // { field: 'receivable', header: 'Usage Unit' },
    // { field: 'siSellingPrice', header: 'Rate' },

    { field: "piDescription", header: "Purchase Description" },

    { field: "status", header: "Status" },
    { field: "itemType", header: "Type" },
    // { field: 'siDescription', header: 'Description' },
    // { field: 'action', exportable: false, header: 'Action', sortable: false, body: { actionBodyTemplate }, disabled: true }
  ];
  const [selectedColumns, setSelectedColumns] = useState(basiccolumns);
  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const accountChange = (e) => {
    setFilterSelected(e.value);
    fetchItemList(page, sizePerPage, searchVal, "", "", e.value, typeSelected);
    let obj = itemStatus.find((c) => c.value === e.value);
    if (obj.status === true) {
      setStatusSelected(obj.value);
      setAccountSelected(obj.type);
      fetchItemList(page, sizePerPage, searchVal, "", "", obj.value, obj.type);
    } else {
      setStatusSelected(e.value);
      setAccountSelected(e.type);
      fetchItemList(page, sizePerPage, searchVal, "", "", "Active", obj.type);
    }
  };

  const typeChange = (e) => {
    settypeSelected(e.value);
    fetchItemList(
      page,
      sizePerPage,
      searchVal,
      "",
      "",
      filterSelected,
      e.value
    );
  };
  const keySearch = (e) => {
    console.log(e, "ttt");
    setSearchVal(e.target.value);
    if (e.key === "Enter") {
      const searched = e.target.value;
      fetchItemList(
        1,
        sizePerPage,
        searched,
        sortName,
        sortOrder,
        statusSelected,
        accountSelected
      );
    }
    if (e.type === "keyup") {
      if (e.target.value === "") {
        fetchItemList(
          1,
          sizePerPage,
          "",
          sortName,
          sortOrder,
          statusSelected,
          accountSelected
        );
      }
    }
  };
  const emptySearch = () => {
    setSearchVal("");
    fetchItemList(
      1,
      sizePerPage,
      "",
      sortName,
      sortOrder,
      statusSelected,
      accountSelected
    );
  };

  const header = (
    <div className="d-flex justify-content-between flex-wrap">
      <div className="text-t-primary  kk-table-header kk-header-sec row">
        <Dropdown
          className="col-auto text-start me-3"
          value={filterSelected}
          options={itemStatus}
          onChange={(e) => accountChange(e)}
          scrollHeight="400px"
          placeholder="Select account"
        />
        <Dropdown
          className="col-auto text-start me-3"
          value={typeSelected}
          options={itemType}
          onChange={(e) => typeChange(e)}
          scrollHeight="400px"
          placeholder="Select type"
        />
        <div className="col  border rounded my-auto">
          <AiOutlineSearch />
          <input
            className=" py-1 ps-3 ms-auto pe-2 search"
            value={searchVal}
            onChange={(e) => keySearch(e)}
            onKeyUp={(e) => keySearch(e)}
            placeholder="Search"
          />
          {searchVal !== "" ? (
            <i className="pi pi-times" onClick={emptySearch} />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="my-auto">
        {/* <FiPlus className='me-1' /> */}
        <button
          className="btn btn-primary py-1 me-2 "
          onClick={() => {
            modalref.current.alertToggle();
          }}
        >
          {" "}
          <i className="pi pi-plus"></i> New Item
        </button>

        <MultiSelect
          value={selectedColumns}
          options={columns}
          optionLabel="header"
          optionDisabled="disabled"
          onChange={onColumnToggle}
          style={{ width: "3em" }}
          scrollHeight="400px"
          dropdownIcon="pi pi-table"
          tooltip="Custom Columns"
          tooltipOptions={{ position: "top" }}
          panelHeaderTemplate={panelHeaderTemplate}
        />
      </div>
    </div>
  );

  const dynamicColumns = selectedColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={col.sortable}
        body={col.body && actionBodyTemplate}
      ></Column>
    );
  });

  const onCustomPage1 = (event) => {
    setFirst1(event.first);
    const val = event.page + 1;
    const size = event.rows;
    setSizePerPage(size);
    setPage(val);
    fetchItemList(
      val,
      size,
      searchVal,
      "",
      "",
      statusSelected,
      accountSelected
    );
  };
  //sort start
  const [lazyParams, setLazyParams] = useState({
    first: first1,
    rows: sizePerPage,
    page: page,
    sortField: sortName,
    sortOrder: sortOrder,
  });
  const onSort = (event) => {
    setLazyParams(event);
  };
  //sort end
  //apicall
  function fetchItemList(
    page,
    sizePerPage,
    searchVal,
    sortName,
    sortOrder,
    status,
    type
  ) {
    // setTdataLoad(true);
    setTdataLoad(false);
    let data = {
      InputParams: {
        ID: 0,
        TenantID: GV.userDet.tenantDetail.tenantID,
        Status: status,
        Type: type,
      },
      ServerSearchables: {
        draw: 1,
        searchData: searchVal || "",
        orderByColumn: sortName,
        orderBy: sortOrder,
        pageSize: sizePerPage,
        pageNumber: page,
      },
    };
    its
      .GetItemsSearchData(data)
      .then((response) => {
        // if (isMounted) "Successfully retrieved Items" "Failed to retrieve Items" {
        if (response.data) {
          console.log("xyz", response.data);
          const grid = response.data.data.itemsList;
          console.log(grid, "grid");
          if (response.data.statusCode == 200) {
            setTotalRecordsIn(
              response.data.data.serverSearchables.recordsTotal
            );
            setData(grid);
            setRequestData(response.data.data.itemsList);
            //   setData(requestData.slice(0,sizePerPage));
            setTdataLoad(false);
          } else if (response.data.statusCode == 500) {
            setData([]);
            setRequestData([]);
            setTotalRecordsIn(0);
          }
          // }
        }
        // if (response.data) {
        //     const grid = response.data.data.itemsList;
        //     setTotalRecordsIn(response.data.data.serverSearchables.recordsTotal);
        //     setData(grid);
        //     setRequestData(response.data.data.itemsList);
        //     setTdataLoad(false);
        // }
      })
      .catch(function (error) {
        if (error.response.data.statusCode == 500) {
          setData([]);
          setRequestData([]);
          setTotalRecordsIn(0);
        }
      });
  }
  ///////callback Function////
  const parentFunction = () => {
    fetchItemList(
      page,
      sizePerPage,
      searchVal,
      "",
      "",
      statusSelected,
      accountSelected
    );
  };

  useLayoutEffect(() => {
    fetchItemList(
      page,
      sizePerPage,
      searchVal,
      "",
      "",
      statusSelected,
      accountSelected
    );
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {/* <div className='card card-borderless mb-3'>
            <div className='ms-2 mt-2 me-2'>
                <div className='d-flex justify-content-between flex-wrap'>
                    <h4>Items</h4>
                    
                </div>

            </div>
        </div> */}
          <div className="card card-borderless cc-greyCard-custm mb-3">
            <div className="ms-2 my-2 me-2 ">
              <div className="d-flex justify-content-between flex-wrap align-items-center">
                <h4 className="m-0">Items</h4>
                <div className="d-flex justify-content-end text-t-primary kk-table-header kk-header-sec  cc-font-size-14">
                  <div className="col">
                    <Dropdown
                      className="col-auto text-start me-3"
                      value={filterSelected}
                      options={itemStatus}
                      onChange={(e) => accountChange(e)}
                      scrollHeight="400px"
                      placeholder="Select account"
                    />
                        <Dropdown
                          className="col-auto text-start me-3"
                          value={typeSelected}
                          options={itemType}
                          onChange={(e) => typeChange(e)}
                          scrollHeight="400px"
                          placeholder="Select type"
                        />

                    <Space.Compact className="my-auto">
                      <Input
                        addonBefore={<SearchOutlined />}
                        allowClear
                        placeholder="Search"
                        value={searchVal}
                        onChange={(e) => keySearch(e)}
                        onKeyUp={(e) => keySearch(e)}
                      />
                    </Space.Compact>

                    {/* <div className="col  border rounded my-auto">
                    <AiOutlineSearch />
                    <input
                      className=" py-1 ps-3 ms-auto pe-2 search"
                      value={searchVal}
                      onChange={(e) => keySearch(e)}
                      onKeyUp={(e) => keySearch(e)}
                      placeholder="Search"
                    />
                    {searchVal !== "" ? (
                      <i className="pi pi-times" onClick={emptySearch} />
                    ) : (
                      ""
                    )}
                  </div> */}

                    {/* <FiPlus className='me-1' /> */}
                    <button
                      className="btn btn-primary py-1 m-2 "
                      onClick={() => {
                        modalref.current.alertToggle();
                      }}
                    >
                      {" "}
                      <i className="pi pi-plus"></i> New Item
                    </button>

                    <MultiSelect
                      value={selectedColumns}
                      options={columns}
                      optionLabel="header"
                      optionDisabled="disabled"
                      onChange={onColumnToggle}
                      style={{ width: "3em" }}
                      scrollHeight="400px"
                      dropdownIcon="pi pi-table"
                      tooltip="Custom Columns"
                      tooltipOptions={{ position: "top" }}
                      panelHeaderTemplate={panelHeaderTemplate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-sm chart-of-account h-100">
            <div className="row coa-header kk-header-sec">
              <div className="col-auto header-right ms-auto"></div>
            </div>
            <div className="account-table">
              <DataTable
                stripedRows
                value={data}
                // header={header}
                // responsiveLayout="scroll"
                size="small"
                loading={tdataLoad}
                paginator
                lazy
                totalRecords={totalRecordsIn}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rowsPerPageOptions={[10, 20, 50, totalRecordsIn]}
                first={first1}
                rows={sizePerPage}
                onPage={onCustomPage1}
                onSort={onSort}
                sortField={lazyParams.sortField}
                sortOrder={lazyParams.sortOrder}
              >
                <Column field="name" header="Name" />
                <Column field="stocks" header="In Stock" />
                <Column field="unit" header="Unit" />
                <Column
                  field="siSellingPriceBodyTemplatePrice"
                  body={siSellingPriceBodyTemplatePrice}
                  header="Selling Price"
                  className="text-end amount-align"
                />
                <Column field="siDescription" header="Sales Description" />
                <Column
                  field="sipiCostPriceBodyTemplatePrice"
                  body={sipiCostPriceBodyTemplatePrice}
                  header="Purchase Price"
                  className="text-end amount-align"
                />
                {dynamicColumns}
                <Column
                  field="actionBodyTemplate"
                  header="Action"
                  body={actionBodyTemplate}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <NewItem
        ref={modalref}
        parentFunction={parentFunction}
        data={{ itemId: itemId, fetchItemList: fetchItemList }}
      />
    </div>
  );
};

export default ListofItems;
