import config from '../assets/config';
import { ax } from './base';


export default class dashboardService {
    
    GetDashboardCards(tenantID){
        return ax.get(`${config.apiUrl}common/GetDashboardCards/${tenantID}`);
    }

    GetHomeBudgetBarChart(tenantID,year){
        return ax.get(`${config.apiUrl}budget/GetHomeBudgetBarChart/${tenantID}/${year}`);
    }

    GetBudgetBarcart(tenantID,year,month){
        return ax.get(`${config.apiUrl}budget/GetBudgetBarcart/${tenantID}/${year}/${month}`);
    }

    GetPettyChashSplineChart(tenantID){
        return ax.get(`${config.apiUrl}common/GetPettyChashSplineChart/${tenantID}`);
    }
}
