
export default class LocalStorageService {
    userDet;
    setItem(keyName, keyVal) {
        localStorage.setItem(keyName, JSON.stringify(keyVal));
    }

    getItem(keyName) {
        return JSON.parse(localStorage.getItem(keyName));
    }

    removeItem(keyName) {
        localStorage.removeItem(keyName);
    }

}
