import React, { useEffect,useRef, useState } from 'react'
import { Controller, useForm } from "react-hook-form";
import { Dialog, Button, InputText, InputTextarea, Dropdown, Toast, ToggleButton } from '../PrimeReact';
import OrganizationService from '../../services/organization';
import GV from '../../services/globalVar';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import LoginService from '../../services/login';
import LocalStorageService from '../../services/localstorage';

let schema = yup.object().shape({
    dateFormat:yup.string().required('This field is required'),
    currencytype: yup.string().required('This field is required'),
    pettycashclose:yup.string().required('This field is required'),
    timezone :yup.string().required('This field is required'),
    fiscalyear:yup.string().required('This field is required'),
    journaltype:yup.string().required('This field is required')
})
function OnBoardConfig() {
    //OTHERS
    const toast = useRef(null);
    //API
    const org = new OrganizationService();
    const lgs = new LoginService();
    const lss = new LocalStorageService();
    //FORM
    const defaultValues = {
        dateFormat:'',
        currencytype: '',
        pettycashclose:'',
        timezone :'',
        fiscalyear:'',
        journaltype:''
    }
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }, control,
        setValue,
        getValues
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema)
    });
    //VARIABLES
    const [onboardData, setOnboardData] = useState([]);
    const [countryList,setCountryList] = useState([])
    const [appDetails,setAppDetails] = useState()
    const [currencyList,setCurrencyList] = useState()

        // BusinessType 
        const BusinessType = [
            { label: 'Type 1', value: 'T1' },
            { label: 'Type 2', value: 'T2' },
            { label: 'Type 3', value: 'T3' },
            { label: 'Type 4', value: 'T4' },
            { label: 'Type 5', value: 'T5' }
        ];
        
        const journalType = [
            { label: 'Single Entry', value: 'Single Entry' },
            { label: 'Double Entry', value: 'Double Entry' },
           
        ];
        const pettyList = [
            { label: 'Month', value: 'Month' },
            { label: 'Week', value: 'Week' },
           
        ];
        const dateFormatList = [
            { label: 'MM/DD/YYYY', value: 'MM/dd/yyyy' },
            { label: 'DD/MM/YYYY', value: 'dd/MM/yyyy' },
           
        ]
        const months = [
            {id:1,label:"January - December",start:'1',end:'12',startMonth:'January',endMonth:'December'},
            {id:2,label:"February - January",start:'2',end:'1',startMonth:'February',endMonth:'January'},
            {id:3,label:"March - Feburary",start:'3',end:'2',startMonth:'March',endMonth:'February'},
            {id:4,label:"April - March",start:'4',end:'3',startMonth:'April',endMonth:'March'},
            {id:5,label:"May - April",start:'5',end:'4',startMonth:'May',endMonth:'April'},
            {id:6,label:"June - May",start:'6',end:'5',startMonth:'June',endMonth:'May'},
            {id:7,label:"July - June",start:'7',end:'6',startMonth:'July',endMonth:'June'},
            {id:8,label:"August - July",start:'8',end:'7',startMonth:'August',endMonth:'July'},
            {id:9,label:"September - August",start:'9',end:'8',startMonth:'September',endMonth:'August'},
            {id:10,label:"October - September",start:'10',end:'9',startMonth:'October',endMonth:'September'},
            {id:11,label:"November - October",start:'11',end:'10',startMonth:'November',endMonth:'October'},
            {id:12,label:"December - November",start:'12',end:'11',startMonth:'December',endMonth:'November'}
          ];
          const [timeZoneList,setTimeZoneList] = useState([])
    const getCountry =() => {
            
           
                org.GetMasTimeZone().then(resp =>{
                    console.log(resp.data.data)
                    setTimeZoneList(resp.data.data)
                })

                org.GetMasCurrency().then(response=>{
                    setCurrencyList(response.data.data)
                })
        }
    const setDetails =() =>{
      console.log(GV.userDet.tenantConfig.currency,'op')
    //   setOnboardData({
    //     countrySelected:'',
    //     currency:GV.userDet.tenantConfig.currency,
    //     endmnth: +GV.userDet.tenantConfig.endMonth,
    //     pettyClose:'Week',
    //     timeZone:'',
    //     startmnth:'3',
    //     journaltype:'single entry'
    //   })
    org.GetTenantConfig(GV.userDet.tenantDetail.tenantID).then(res=>{
        const resp = res.data.data
        setAppDetails(resp)
        const fisc = months.filter(x=>x.start === resp.startMonth.toString()) 
        console.log(fisc,'dol')
        setValue('currencytype',resp.currencyID)
        setValue('dateFormat',resp.dateFormat )
        setValue('fiscalyear',fisc[0].start)
        setValue('pettycashclose',resp.pettyCashCloseType)
        setValue('timezone',resp.timeZoneID)
        setValue('journaltype',resp.journalType != null ? GV.userDet.tenantConfig.journalType : '')
  
    })
     
        console.log(getValues('fiscalyear'))
    }
const submit = (data) =>{
    console.log(data)
   const monthSelected = months.filter(x=>x.start === data.fiscalyear)
   const currencySel = currencyList.filter(y=>y.masCurrency_ID === +data.currencytype)
   const timeZoneSel = timeZoneList.filter(y=>y.timeZoneID === +data.timezone)

//    const monthEnd = months.filter(x=>x.value === data.fiscalyear)
console.log(monthSelected,currencySel,timeZoneSel)
const req = {
    "TenantID": GV.userDet.tenantDetail.tenantID,
    "PettyCashCloseType": data.pettycashclose,
    "IsFinancialYear": true,
    "YearType": "Fiscal",
    "StartMonth":+monthSelected[0].start,
    "StartMonthName": monthSelected[0].startMonth,
    "EndMonth":  +monthSelected[0].end,
    "EndMonthName": monthSelected[0].endMonth,
    "JournalType": data.journaltype,
    "CurrencyID": currencySel[0].masCurrency_ID,
    "CurrencyCode": currencySel[0].currency_Code,
    "Currency": currencySel[0].currency_Symbol,
    "CurrencyName":currencySel[0].currency_Name,
    "DateFormat": data.dateFormat,
    "TimeZoneID": +timeZoneSel[0].timeZoneID,
    "TimeZoneCode":  timeZoneSel[0].timeZoneCode,
    "TimeZoneName":  timeZoneSel[0].timeZoneName,
    "TimeDifference": timeZoneSel[0].timeDifference.toString(),
    "CountryID": appDetails.countryID,
    "CountryCode": appDetails.countryCode,
    "CountryName":appDetails.countryName,
    "IsConfigCompleted": true,
    "CreatedOn": new Date(),
    "CreatedBy": GV.userDet.appUser.firstName + " " +  GV.userDet.appUser.lastName
}
 
    console.log(req)
    org.SaveTenantConfig(req).then((resp)=>{
        console.log(resp);
        if(resp.data.message == 'Updated Successfully'){
            toast.current.show({ severity: 'success', summary: 'Updated Successfully' });
            console.log(GV.userDet.appUser.appuserID,'appuser')
            setDetails();
            lgs.UserProfile(GV.userDet.appUser.appuserID).then((res) => {
                GV.userDet = res.data.data;
                lss.setItem("userDet", GV.userDet);
                window.location.reload();
                // navigate("/pages/dashboard");
              });
        }else{
            toast.current.show({ severity: 'error', summary: 'Updation Failed' });
        }
       
    })

}
const [nonedit,setnonEdit] = useState()
    useEffect(() => {
        getCountry();
        setDetails();
        setnonEdit(GV.userDet.tenantConfig.isConfigCompleted)
    }, []);
  return (
    <><Toast ref={toast}></Toast>
    <div>
        <div className='row'>
                    <div className='col-md-12'>
                        <div className='card card-borderless cc-greyCard-custm '>
                            <div className='card-header bg-white'>
                                    <h5 className='mb-0 '>Application Configuration</h5>
                            </div>
                            <div className='card-body '>
                            <form onSubmit={handleSubmit(submit)}>
                                <div className='row'>
                                    
                                    <div className='col-md-4'>
                                        <label className='required'>Currency Type</label>
                                        <Controller
                                                        render={({ field }) => (
                                                            <Dropdown className="input-group " options={currencyList}
                                                                {...field}
                                                                optionLabel="currency_Name" optionValue="masCurrency_ID"
                                                                {...register('currencytype')}
                                                                disabled={nonedit}
                                                            />
                                                        )}
                                                        control={control}
                                                        name="currencytype"
                                                    />
                                        {errors.currencytype && <p className="text-danger mb-0 ps-1">{errors.currencytype?.message}</p>}

                                    </div>
                                    <div className='col-md-4'>
                                        <label className='required'>Time Zone</label>
                                        <Controller
                                                        render={({ field }) => (
                                                            <Dropdown className="input-group " options={timeZoneList}
                                                            filter
                                                                {...field}
                                                                optionLabel="timeZoneName" optionValue="timeZoneID" 
                                                                {...register('timezone')}   disabled={nonedit}/>
                                                        )}
                                                        control={control}
                                                        name="timezone"
                                                    />
                                        {errors.timezone && <p className="text-danger mb-0 ps-1">{errors.timezone?.message}</p>}
                                                    
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='required'>Date Format</label>
                                        <Controller
                                             render={({ field }) => (
                                                <Dropdown className="input-group " options={dateFormatList}
                                                    {...field}
                                                    
                                                    optionLabel="label" optionValue="value" 
                                                    {...register('dateFormat')}   disabled={nonedit}
                                                />
                                            )}
                                            control={control}
                                             name="dateFormat"
                                         />
                                            {errors.dateFormat && <p className="text-danger mb-0 ps-1">{errors.dateFormat?.message}</p>}
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='required'>Petty Cash Close Duration</label>
                                        <Controller
                                                        render={({ field }) => (
                                                            <Dropdown className="input-group " options={pettyList}
                                                                {...field}

                                                                optionLabel="label" optionValue="value"
                                                                {...register('pettycashclose')}   disabled={nonedit}/>

                                                        )}
                                                        control={control}
                                                        name="pettycashclose"
                                                    />
                                        {errors.pettycashclose && <p className="text-danger mb-0 ps-1">{errors.pettycashclose?.message}</p>}

                                    </div>
                                    
                                    <div className='col-md-4'>
                                        
                                                <label className='required'>Fiscal Year  </label>
                                                {/* months */}
                                                <Controller
                                                        render={({ field }) => (
                                                            <Dropdown className="input-group " options={months}
                                                                {...field}
                                                                optionLabel="label"   optionValue="start" 
                                                                {...register('fiscalyear')}  disabled={nonedit}/>

                                                        )}
                                                        control={control}
                                                        name="fiscalyear"
                                                       
                                                    />
                                            {errors.fiscalyear && <p className="text-danger mb-0 ps-1">{errors.fiscalyear?.message}</p>}

                                                {/* <input type="text" className='form-control'  {...register('acname', { required: false })}
                                                    defaultValue={onboardData.bankAccountName} onChange={(e) => setOnboardData({ ...onboardData, bankAccountName: e.target.value })} /> */}

                                    </div>
                                    <div className='col-md-4'>
                                    <label className='required'>Journal Type</label>
                                        <Controller
                                                        render={({ field }) => (
                                                            <Dropdown className="input-group " options={journalType}
                                                                {...field}

                                                                optionLabel="label" optionValue="value" 
                                                                {...register('journaltype')}  disabled={nonedit}/>

                                                        )}
                                                        control={control}
                                                        name="journaltype"
                                                    />
                                        {errors.journaltype && <p className="text-danger mb-0 ps-1">{errors.journaltype?.message}</p>}

                                    </div>
                                </div>
                               
                                <div className='d-flex justify-content-end mt-3'>
                                { nonedit === false ?  <button className='btn btn-primary' onClick={handleSubmit(submit)}>Save</button> : ''}
                                </div>
                               
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
    </>
  )
}

export default OnBoardConfig