import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import {
  height,
  width,
} from "@fortawesome/free-regular-svg-icons/faAddressBook";
import growup from "../../assets/img/growUp.png";
import { Calendar } from "primereact/calendar";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import GlobalVariable from "../../services/globalVar";
import dashboardService from "../../services/dashboard-service";

const NewDashboard = () => {
  const [accountNameDate, setAccountNameDate] = useState(new Date());
  const [chartOptions, setChartOptions] = useState({});
  const [chartData2, setChartData2] = useState({});
  const ds = new dashboardService();
  const [chartOptions2, setChartOptions2] = useState({});
  const [chartData3, setChartData3] = useState({});
  const [chartOptions3, setChartOptions3] = useState({});
  const [test, setTest] = useState("Trend");
  const [chartData, setChartData] = useState([]);
  const [accountName, setAccountName] = useState([]);
  const [actualBudget, setActualBudget] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "Actual",
          data: [25, 59, 30, 81, 36, 75, 40, 82, 47, 66, 70, 53],
          fill: false,
          tension: 0.4,
          borderColor: documentStyle.getPropertyValue("--blue-500"),
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: "red",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };
    // setChartData(data);
    setChartOptions(options);
  }, []);

  const [state, setState] = useState({
    options: {
      chart: {
        id: "area-datetime",
        type: "area",

        zoom: {
          autoScaleYaxis: true,
        },
      },
      annotations: {
        yaxis: [
          {
            y: 30,
            borderColor: "#999",
            label: {
              show: true,
              text: "Support",
              style: {
                color: "#fff",
                background: "#00E396",
              },
            },
          },
        ],
        xaxis: [
          {
            x: new Date("14 Nov 2012").getTime(),
            borderColor: "#999",
            yAxisIndex: 0,
            label: {
              show: true,
              text: "Rally",
              style: {
                color: "#fff",
                background: "#775DD0",
              },
            },
          },
        ],
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        min: new Date("01 Mar 2012").getTime(),
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },
    selection: "one_year",
  });

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          type: "bar",
          label: "Actual",
          backgroundColor: documentStyle.getPropertyValue("--yellow-500"),
          data: [21, 84, 24, 75, 37, 65, 34, 21, 84, 24, 75, 37, 65, 84],
          borderColor: "white",
          borderWidth: 2,
        },
        {
          type: "bar",
          label: "Budgeting",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          data: [41, 52, 24, 74, 23, 21, 32, 41, 52, 24, 74, 23, 21, 32],
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData2(data);
    setChartOptions2(options);
  }, []);

  const GetDashboardCards = () => {
    ds.GetDashboardCards(GlobalVariable.userDet.tenantDetail.tenantID).then(
      (res) => {
        setCardDetails(res.data.data);
      }
    );
  };

  const GetBudgetBarcart = (item) => {
    ds.GetBudgetBarcart(
      GlobalVariable.userDet.tenantDetail.tenantID,
      item.year,
      item.month
    ).then((res) => {
      setAccountName(res.data.data);
    });
  };

  const GetPettyChashSplineChart = () => {
    ds.GetPettyChashSplineChart(
      GlobalVariable.userDet.tenantDetail.tenantID
    ).then((res) => {
      setChartData(res.data);
    });
  };

  const GetHomeBudgetBarChart = (item) => {
    ds.GetHomeBudgetBarChart(
      GlobalVariable.userDet.tenantDetail.tenantID,
      item.year
    ).then((res) => {
      setActualBudget(res.data);
    });
  };

  const handleSelectDate = (e) => {
    const month = (e.value.getMonth() + 1).toString().padStart(2, "0");
    const year = e.value.getFullYear();
    const formattedDate = { month, year };
    GetBudgetBarcart(formattedDate);
  };

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      // labels: ['Forecast', 'Spent', 'Balance'],
      datasets: [
        {
          data: [300, 50, 100],
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };
    const options = {
      cutout: "80%",
    };

    setChartData3(data);
    setChartOptions3(options);
  }, []);

  useEffect(() => {
    GetDashboardCards();
    const month = (new Date().getMonth() + 1).toString().padStart(2, "0");
    const year = new Date().getFullYear();
    const formattedDate = { month, year };
    GetBudgetBarcart(formattedDate);
    GetHomeBudgetBarChart(formattedDate);
    GetPettyChashSplineChart();
  }, []);

  return (
    <div className="row mx-auto">
      <div className="col-sm-12 ">
        <div className="row mx-auto d-flex gap-2">
          {cardDetails?.map((a, i) => {
            return (
              <div
                className="col-sm card gap-3 justify-content-between p-3 card-box "
                style={{ flexDirection: "row", border: "0" }}
              >
                <div
                  className="col-md-12 d-flex justify-content-between"
                  key={i}
                >
                  <div className="row">
                    <p>
                      <strong>{a.cardName}</strong>
                    </p>
                    <p>
                      <i className="pi pi-indian-rupee"></i>{" "}
                      <span>Total count : {a.totalCount}</span>
                    </p>
                    <p>
                      {a.cardName === "Invoice" || a.cardName === "Expenses" ? (
                        <span className="ps-1">
                          Amount :{" "}
                          {GlobalVariable.userDet.tenantConfig.currency +
                            " " +
                            a.amount}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  {a.cardName === "Customer" && (
                    <div className="d-flex align-items-center">
                      <i className="pi pi-users icon-design"></i>
                    </div>
                  )}
                  {a.cardName === "Invoice" && (
                    <div className="d-flex align-items-center">
                      <i className="pi pi-box icon-design-box"></i>
                    </div>
                  )}
                  {a.cardName === "Vendor" && (
                    <div className="d-flex align-items-center">
                      <i className="pi pi-users icon-design"></i>
                    </div>
                  )}
                  {a.cardName === "Expenses" && (
                    <div className="d-flex align-items-center">
                      <i className="pi pi-chart-line icon-design-box"></i>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="col-sm-12 my-2">
        <div className="row mx-auto d-flex gap-2">
          <div className="col-sm-8 p-0">
            <div className="card p-3 card-box ">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4>Trend</h4>
                
              </div>
              <div id="chart">
                <div className="toolbar"></div>
                <div id="chart-timeline" style={{ height: "13rem" }}>
                  <Chart
                    type="line"
                    data={chartData.data}
                    style={{ height: "100%" }}
                  />
                </div>
              </div>
              <div id="html-dist"></div>
            </div>
          </div>

          <div className="col-sm p-0">
            <div className=" p-3 card-box card" style={{ height: "100%" }}>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4>Notification </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-12">
        <div className="row mx-auto d-flex gap-2">
          <div className="col-sm p-0">
            <div className=" p-3 card-box card" style={{ height: "313px" }}>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4>Account Name</h4>
                <div>
                  <Calendar
                    value={accountNameDate}
                    onChange={(e) => handleSelectDate(e)}
                    view="month"
                    dateFormat="mm/yy"
                  />
                </div>
              </div>
              <div style={{ overflow: "auto" }}>
                {accountName.map((a, i) => {
                  return (
                    <div key={i} className="d-flex justify-content-between">
                      <p className="m-1">{a.budgetCategory}</p>
                      <p className="m-1">
                        {a.budgetType.isRaised === true ? (
                          <img
                            src={growup}
                            alt=""
                            style={{ width: "27px", marginRight: "5px" }}
                          />
                        ) : (
                          <img
                            src={growup}
                            alt=""
                            style={{
                              width: "27px",
                              marginRight: "5px",
                              transform: "scale(-1) rotate(252deg)",
                            }}
                          />
                        )}
                        <span
                          className="pe-1"
                          style={{
                            color: ` ${
                              a.budgetType.isRaised === true ? "#34C5AF" : "red"
                            }  `,
                          }}
                        >
                          {a.budgetType.percentageValue}%
                        </span>
                        {a.budgetType.analysis}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="col-sm-8 p-0" style={{ height: "100%" }}>
            <div className="card p-3 card-box">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4>Actual & Budgeting</h4>
                <div>
                  <Calendar
                    view="year"
                    placeholder="Select year"
                    dateFormat="yy"
                    style={{ width: "92px" }}
                  />
                </div>
              </div>
              <Chart
                type="bar"
                data={actualBudget.data}
                options={chartOptions2}
                style={{ height: "229Px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDashboard;
