// import React from 'react'

// export default function classNames(...args: any[]) {
//     return args.filter(Boolean).join(' ')
//   }
  

  import React from 'react'
  
  export default function classNames(...args) {
    return args.filter(Boolean).join(' ')
  }
  