import config from '../assets/config';
import { ax } from './base';

export default class ReportsService {

    GetProfitLoss(payload){
        return ax.post(`${config.apiUrl}DoubleEntrySystemReport/GetProfitLoss`,payload)

    }

}