import config from '../assets/config';
import { ax } from './base';

export default class PettycashServiceService {
    // Customer and Vendor
    
    GetPettyCashListSearch(payload){
        return ax.post(`${config.apiUrl}pettycash/GetPettyCashListSearch`,payload)
    }

    SavePettyCashTransaction(payload){
        return ax.post(`${config.apiUrl}pettycash/SavePettyCashTransaction`,payload)
    }

    SavePettyCashTopup(payload){
        return ax.post(`${config.apiUrl}pettycash/SavePettyCashTopup`,payload)
    }

   
    ClosePettyCash(payload){
        return ax.post(`${config.apiUrl}PettyCash/ClosePettyCash`,payload)
    }

    ArchiveTransction(payload){
        return ax.post(`${config.apiUrl}PettyCash/ArchiveTransction`,payload)
    }
    GetMonthTrancationView(tenantId,year,month){
        return ax.get(`${config.apiUrl}Pettycash/GetMonthTrancationView/${tenantId}/${year}/${month}`);
    }
    GetCustomTransactions(payload){
        return ax.post(`${config.apiUrl}PettyCash/GetCustomTransactions`,payload)
    }
    GetCustomDailyBalance(payload){
        return ax.post(`${config.apiUrl}PettyCash/GetCustomDailyBalance`,payload)
    }
}