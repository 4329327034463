import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  memo,
} from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Button as Primebutton } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import GV from "../../services/globalVar";
import PettycashServiceService from "../../services/pettycash-service";
import purchaseService from "../../services/purchase-sevice";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import "primeicons/primeicons.css";
import { Controller, useForm } from "react-hook-form";
import { Empty } from "antd";
import { Divider as Primediveder } from "primereact/divider";
import InvoiceService from "../../../../client/src/services/invoice-service";
import salesService from "../../services/sales-service";
import { Button, Divider, Input, Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { AutoComplete } from "primereact/autocomplete";
import { BlockUI } from "primereact/blockui";
import { date, object } from "yup";

const NewPurchaseOrderForm = (props) => {
  const rowDatas = props.data.rowData;
  console.log(rowDatas, "rowdatas");
  const is = new InvoiceService();
  const ss = new salesService();
  const toast = useRef(null);
  const inputRef = useRef(null);

  const [selectedDate, setSelectedDate] = useState(null);
  const [etRadio1, setEtRadio1] = useState("Goods");
  const ps = new purchaseService();
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [paidThroughDropDown, setPaidThroughDropDown] = useState();
  const [vendorNameList, setVendorNameList] = useState([]);
  const [selectVendorNameList, setSelectVendorNameList] = useState();
  const [selectedExpenseAccount, setSelectedExpenseAccount] = useState([]);
  const [selectedPaidThrough, setSelectedPaidThrough] = useState();
  const [clearUpload, setClearUpload] = useState(false);
  const [totalTax, setTotalTax] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [displayTotalExpenses, setDisplayTotalExpenses] = useState(0);
  const [displayTotalTax, setDisplayTotalTax] = useState(0);
  const [taxAmount, setTaxAmount] = useState([]);
  const [total, setTotal] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [tenantDetails, setTenantDetails] = useState({});
  const [isCustChoose, setIsCustChoose] = useState(true);
  const [isProcess, setIsProcess] = useState(false);
  const [countryTaxDetails, setCountryTaxDetails] = useState({});
  const [isType, setIsType] = useState(false);
  const [manualErr, setManualErr] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [sgstTax, setSgstTax] = useState(0);
  const [cgstTax, setCgstTax] = useState(0);
  const [sgstTaxAmount, setSgstTaxAmount] = useState(0);
  const [igstTax, setIgstTax] = useState(0);
  const [igstTaxAmount, setIgstTaxAmount] = useState(0);
  const [cgstTaxAmount, setCgstTaxAmount] = useState(0);
  const [netAmountValue, setNetAmountValue] = useState(0);
  const [productDrop, setProductDrop] = useState([]);
  const [productDrop1, setProductDrop1] = useState([]);
  const [name, setName] = useState("");
  const [filteredItems, setFilteredItems] = useState(null);
  const [poNumber, setPoNumber] = useState();
  const [dateSelect, setDateSelect] = useState(new Date());
  const [selectExpectedDate, setSelectExpectedDate] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState();
  const [selectedItemDropDown, setSelectedItemDropDown] = useState("");
  const [isAddItem, setIsAddItem] = useState(true);

  const [isTax, setIsTax] = useState(false);
  const [isGstin, setIsGstin] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [errorsTax, setErrorsTax] = useState(false);
  const [formDataa, setFormData] = useState([
    {
      id: 1,
      inputtextarea: "",
      hsnsac: "",
      tax: "",
      seperateamount: "",
      taxamount: "",
      total: "",
    },
  ]);
  const [errorsField, setErrorsField] = useState(false);

  const [termName, setTermName] = useState([
    { id: 1, count: 0, name: "Today", label: "today" },
    { id: 2, count: 15, name: "Net 15", label: "Net 15" },
    { id: 3, count: 30, name: "Net 30", label: "Net 30" },
    { id: 4, count: 45, name: "Net 45", label: "Net 45" },
    { id: 5, count: 60, name: "Net 60", label: "Net 60" },
    { id: 6, count: 75, name: "Net 75", label: "Net 75" },
    { id: 7, count: 90, name: "Net 90", label: "Net 90" },
  ]);

  const handleSelectTerm = (e) => {
    console.log(e, "li");
    setSelectedTerm(e);
  };

  const clearUploadedFile = () => {
    setUploadedFiles(null);
    setClearUpload(true);
  };

  const [invoiceLines, setInvoiceLines] = useState([
    {
      id: 1,
      productname: "",
      quantity: 0,
      rate: 0,
      discount: 0,
      statetax: 0,
      centraltax: 0,
      intertax: 0,
      netamount: "",
      // producttype: "",
      // shippngcharge: "",
    },
  ]);

  const addNewLine = () => {
    setIsType(true);
    // setIsDiscountDisabled(true)
    console.log(invoiceLines, "add");
    const invalidInvoiceLines = invoiceLines.filter(
      (line) => line.quantity === "" || line.discount === ""
    );

    if (invalidInvoiceLines.length > 0) {
      setManualErr("Unable to duplicate empty fields");
      return;
    } else {
      setManualErr("");
      const newId = invoiceLines[invoiceLines.length - 1].id + 1;
      const newLine = {
        id: newId,
        productname: "",
        quantity: "",
        rate: "",
        discount: "",
        statetax: "",
        centraltax: "",
        intertax: "",
        netamount: "",
        producttype: "",
      };

      setInvoiceLines((prevInvoiceLines) => [...prevInvoiceLines, newLine]);
    }
  };

  const clearFields = () => {
    setInvoiceLines([
      {
        id: 1,
        productname: "",
        quantity: "",
        rate: "",
        discount: "",
        statetax: "",
        centraltax: "",
        intertax: "",
        netamount: "",
        //   producttype: "",
        //   shippngcharge: "",
      },
    ]);
  };

  const vendorNameOnchange = (e) => {
    setIsCustChoose(false);
    setSelectVendorNameList(e);
  };

  const defaultValues = {
    billto: "",
    ponumber: "",
    referencenumber: "",
    date: "",
    deliverydate: "",
    paymentterms: "",
    deliveryterms: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues });

  const cancelForm = () => {
    reset();
    clearFields();
    setSelectedPaidThrough(null);
    setPaidThroughDropDown(null);
    setSelectedExpenseAccount(null);
    setSelectVendorNameList(null);
    setIsTax(false);
    setIsGstin(false);
    setIsInvoice(false);
    setSelectedDate("");
    setEtRadio1("");
    clearUploadedFile();
    setErrorsField(false);
    setErrorsTax(false);
  };

  const handleDateSelect = (e) => {
    console.log(e.target.value,'e.target.value');
    setDateSelect(e.target.value);
  };

  const handleSelectExpectedDelevryDate = (e) => {
    console.log(e.value, "ee");
    setSelectExpectedDate(e.value);
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const handleSaveBtn = (data, status) => {
    if (rowDatas) {
      setIsProcess(true);
      const datas = {
        PurchaseOrderID: rowDatas.purchaseOrderID || 0,
        PONumber: poNumber,
        PODate: formatDate(new Date(dateSelect)),
        ReferenceNo: data.referencenumber,
        DelivaryDate: formatDate(new Date(data.deliverydate)),
        TermName: data.paymentterms.name,
        NumberOfDays: selectedTerm.count,
        DueDate: "2024-08-22",
        DeliveryTerm: data.deliveryterms,
        VendorID: selectVendorNameList.contactID,
        CompanyName: selectVendorNameList.companyName,
        FirstName: selectVendorNameList.firstName,
        LastName: selectVendorNameList.lastName,
        Address1: selectVendorNameList.baAddress1,
        Address2: selectVendorNameList.baAddress2,
        City: selectVendorNameList.saCity,
        State: selectVendorNameList.saState,
        ZipCode: selectVendorNameList.saZipCode,
        Country: selectVendorNameList.saCountry,
        Phone: selectVendorNameList.phone,
        Fax: selectVendorNameList.saFax,
        Email: selectVendorNameList.email,
        SubTotal: subTotal,
        LocalTaxName: countryTaxDetails.localTaxName,
        LocalTaxRate: sgstTax,
        LocalTaxAmount: sgstTaxAmount,
        InterTaxName: countryTaxDetails?.interTaxName,
        InterTaxRate: igstTax,
        InterTaxAmount: igstTaxAmount,
        CentralTaxName: countryTaxDetails?.centralTaxName,
        CentralTaxRate: cgstTax,
        CentralTaxAmount: cgstTaxAmount,
        IsOtherTax: false,
        TotalAmount: netAmountValue,
        DocID: 0,
        PurchaseStatus: status,
        TenantID: GV.userDet?.tenantDetail?.tenantID,
        CreatedBy: GV.userDet?.appUser?.appuserID,
        IsItemChanged: true,
        PurchaseOrderItems: invoiceLines.map((a, i) => ({
          OrderItemsID:
            rowDatas.purchaseOrderItems && rowDatas.purchaseOrderItems[i]
              ? rowDatas.purchaseOrderItems[i].orderItemsID
              : 0,
          PurchaseOrderID: rowDatas.purchaseOrderID || 0,
          ItemID: a.productname
            ? a.productname.selectedDates?.itemID
            : 0 || a.productname.itemID,
          ItemName: a.productname ? a.productname.label : a.productname.label,
          ItemType: a.productname ? a.productname.selectedDates?.itemType : "",
          HsnCode: a.productname.selectedDates?.saCorHSNCode
            ? a.productname.selectedDates?.saCorHSNCode
            : a.productname.selectedDates?.hsnCode,
          HsnName: a.productname
            ? a.productname.selectedDates?.itemType === "goods"
              ? "HSN"
              : "SAC"
            : "",
          Unit: a.productname ? a.productname.selectedDates?.unit : "",
          Quantity: a.quantity,
          Rate: a.productname.selectedDates?.siSellingPrice
            ? a.productname.selectedDates?.siSellingPrice
            : a.productname.selectedDates?.rate,
          LocalTaxName: countryTaxDetails.localTaxName,
          LocalTaxRate: +a.statetax,
          LocalTaxAmount: sgstTaxAmount,
          InterTaxName: countryTaxDetails?.interTaxName,
          InterTaxRate: +a.intertax,
          InterTaxAmount: igstTaxAmount,
          CentralTaxName: countryTaxDetails?.centralTaxName,
          CentralTaxRate: +a.centraltax,
          CentralTaxAmount: cgstTaxAmount,
          IsOtherTax: false,
          TotalAmount: +a.netamount,
          Status: "ACTIVE",
          Description: a.productname.selectedDates?.siDescription
            ? a.productname.selectedDates?.siDescription
            : a.productname.selectedDates?.description,
          TenantID: GV.userDet.tenantDetail.tenantID,
          CreatedBy: GV.userDet.appUser.appuserID,
        })),
      };
      console.log(datas, "save");
      ps.SavePurchaseOrder(datas)
        .then((res) => {
          if (res.data.statusCode === 200) {
            setIsProcess(false);
            toast.current.show({
              severity: "success",
              summary: res.data.message,
            });
            backToGrid();
            setSelectedItemDropDown("");
          } else {
            setIsProcess(false);
          }
        })
        .catch((err) => {
          setIsProcess(false);
        });
    } else {
      setIsProcess(true);
      const datas = {
        PurchaseOrderID: rowDatas.purchaseOrderID || 0,
        PONumber: poNumber,
        PODate: formatDate(new Date(dateSelect)),
        ReferenceNo: data.referencenumber,
        DelivaryDate: formatDate(new Date(data.deliverydate)),
        TermName: data.paymentterms.name,
        NumberOfDays: selectedTerm.count,
        DueDate: "2024-08-22",
        DeliveryTerm: data.deliveryterms,
        VendorID: selectVendorNameList.contactID,
        CompanyName: selectVendorNameList.companyName,
        FirstName: selectVendorNameList.firstName,
        LastName: selectVendorNameList.lastName,
        Address1: selectVendorNameList.baAddress1,
        Address2: selectVendorNameList.baAddress2,
        City: selectVendorNameList.saCity,
        State: selectVendorNameList.saState,
        ZipCode: selectVendorNameList.saZipCode,
        Country: selectVendorNameList.saCountry,
        Phone: selectVendorNameList.phone,
        Fax: selectVendorNameList.saFax,
        Email: selectVendorNameList.email,
        SubTotal: subTotal,
        LocalTaxName: countryTaxDetails.localTaxName,
        LocalTaxRate: sgstTax,
        LocalTaxAmount: sgstTaxAmount,
        InterTaxName: countryTaxDetails?.interTaxName,
        InterTaxRate: igstTax,
        InterTaxAmount: igstTaxAmount,
        CentralTaxName: countryTaxDetails?.centralTaxName,
        CentralTaxRate: cgstTax,
        CentralTaxAmount: cgstTaxAmount,
        IsOtherTax: false,
        TotalAmount: netAmountValue,
        DocID: 0,
        PurchaseStatus: status,
        TenantID: GV.userDet?.tenantDetail?.tenantID,
        CreatedBy: GV.userDet?.appUser?.appuserID,
        IsItemChanged: true,
        PurchaseOrderItems: invoiceLines.map((a, i) => ({
          OrderItemsID: 0,
          PurchaseOrderID: 0,
          ItemID: a.productname ? a.productname?.itemID : 0,
          ItemName: a.productname ? a.productname.name : a.label,
          ItemType: a.productname ? a.productname?.itemType : "",
          HsnCode: a.productname ? a.productname.saCorHSNCode : "",
          HsnName: a.productname
            ? a.productname.selectedDates?.itemType === "goods"
              ? "HSN"
              : "SAC"
            : "",
          Unit: a.productname ? a.productname?.unit : "",
          Quantity: a.quantity,
          Rate: a.productname ? a.productname?.siSellingPrice : a.rate,
          LocalTaxName: countryTaxDetails.localTaxName,
          LocalTaxRate: +a.statetax,
          LocalTaxAmount: sgstTaxAmount,
          InterTaxName: countryTaxDetails?.interTaxName,
          InterTaxRate: +a.intertax,
          InterTaxAmount: igstTaxAmount,
          CentralTaxName: countryTaxDetails?.centralTaxName,
          CentralTaxRate: +a.centraltax,
          CentralTaxAmount: cgstTaxAmount,
          IsOtherTax: false,
          TotalAmount: +a.netamount,
          Status: "ACTIVE",
          Description: a.productname ? a.productname.siDescription : "",
          TenantID: GV.userDet.tenantDetail.tenantID,
          CreatedBy: GV.userDet.appUser.appuserID,
        })),
      };
      ps.SavePurchaseOrder(datas)
        .then((res) => {
          if (res.data.statusCode === 200) {
            setIsProcess(false);
            toast.current.show({
              severity: "success",
              summary: res.data.message,
            });
            backToGrid();
            setSelectedItemDropDown("");
          } else {
            setIsProcess(false);
          }
        })
        .catch((err) => {
          setIsProcess(false);
        });
    }
  };

  const onValid = (errors) => {
    console.log(errors);
  };

  const addProduct = () => {
    const newFormFields = [...formDataa];
    const newId = formDataa[formDataa.length - 1].id + 1;
    newFormFields.push({
      id: newId,
      inputtextarea: "",
      hsnsac: "",
      tax: "",
      seperateamount: "",
      taxamount: "",
      total: "",
    });
    setFormData(newFormFields);
    setTaxAmount([...taxAmount]);
    console.log(newFormFields, "addproduct");
  };

  const GetTenantDetails = () => {
    setIsProcess(true);
    is.GetTenantDetails(GV.userDet.tenantDetail.tenantID).then((res) => {
      setIsProcess(false);
      setTenantDetails(res.data.data);
      GetCountryTaxName(res.data.data.country);
    });
  };

  const GetPurchaseNumber = () => {
    const datas = {
      TenantID: GV.userDet.tenantDetail.tenantID,
      ServiceName: "PurchaseOrder",
    };
    ps.GeneratePurchaseOrderConfigNumber(datas).then((res) => {
      console.log(res.data, "po");
      setPoNumber(res.data.data);
    });
  };

  const GetCountryTaxName = (country) => {
    is.GetCountryTaxName(country).then((res) => {
      if (res.data.statusCode === 200) {
        setCountryTaxDetails(res.data.data);
      }
    });
  };

  const GetSalesItemList = () => {
    setIsProcess(true);

    return new Promise((resolve, reject) => {
      is.GetSalesItemList(GV.userDet.tenantDetail.tenantID)
        .then((res) => {
          setIsProcess(false);
          if (res.data.statusCode === 200) {
            let antDropdn = res.data.data.map((item) => ({
              label: item.name,
              value: item.itemID,
              selectedDates: item,
            }));
            setProductDrop1(antDropdn);
            setProductDrop(res.data.data);
            resolve(res.data.data);
            if (rowDatas) {
              let structObj = rowDatas?.purchaseOrderItems.filter(
                (a) => !a.itemID
              );
              if (structObj && structObj.length > 0) {
                let newItems = structObj.map((item) => ({
                  label: item.itemName,
                  value: item.itemID,
                  selectedDates: item,
                }));
                // console.log(newItems,'newItems')
                setProductDrop1((items) => [...items, ...newItems]);
                antDropdn = [...antDropdn, ...newItems];
              }
              setProductDrop1(antDropdn);
              console.log(structObj, "structObj");

              let getVals = rowDatas?.purchaseOrderItems.map((a) => ({
                label: a.itemName,
                value: a.itemID,
                selectedDates: a,
              }));

              const productDetails = getVals.map((a, i) => ({
                id: i + 1,
                productname: antDropdn.find((x) => x.value === a.value) || "",
                quantity: a.selectedDates.quantity || "",
                rate: a.selectedDates.rate || "",
                discount: a.selectedDates.discount || 0,
                statetax: a.selectedDates.localTaxRate || 0,
                centraltax: a.selectedDates.centralTaxRate || 0,
                intertax: a.selectedDates.interTaxRate || 0,
                netamount: a.selectedDates.totalAmount?.toFixed(2) || "",
                producttype: a.selectedDates.itemType || "",
                salesItemID: a.selectedDates.salesItemID || 0,
              }));
              console.log(productDetails, "productDetails");
              setInvoiceLines(productDetails);
            }
          } else {
            reject(new Error("Failed to fetch sales item list"));
          }
        })
        .catch((error) => {
          setIsProcess(false);
          reject(error);
        });
    });
  };

  const GetContactFilteredList = () => {
    if (rowDatas) {
      const data = {
        TenantID: GV.userDet.tenantDetail.tenantID,
        ContactType: "Vendor",
        SearchData: "",
      };
      ps.GetContactFilteredList(data).then((res) => {
        const contactName = res.data.data.map((a) => {
          return a;
        });
        setVendorNameList(contactName);
        setIsType(true);
        setIsCustChoose(false);
        let bindvendor = res.data.data.find(
          (a) => a.firstName === rowDatas.firstName
        );
        setSelectVendorNameList(bindvendor);
        setValue("billto", bindvendor);
        setValue("referencenumber", rowDatas.referenceNo);
        let poDate = new Date(rowDatas.poDate);
        setDateSelect(poDate);
        let deliveryDate = new Date(rowDatas.delivaryDate);
        console.log("Setting delivery date:", deliveryDate);
        setSelectExpectedDate(deliveryDate);
        setValue("deliverydate", deliveryDate);
        let termname = termName.find((a) => a.name === rowDatas.termName);
        setSelectedTerm(termname);
        setValue("paymentterms", termname);
        setValue("deliveryterms", rowDatas.deliveryTerm);
        setSubTotal(rowDatas.subTotal);
        setSgstTax(rowDatas.localTaxRate);
        setCgstTax(rowDatas.centralTaxRate);
        setIgstTax(rowDatas.interTaxRate);
        setNetAmountValue(rowDatas.totalAmount);
        setSgstTaxAmount(rowDatas.localTaxAmount);
        setCgstTaxAmount(rowDatas.centralTaxAmount);
        setIgstTaxAmount(rowDatas.interTaxAmount);
      });
    } else {
      const data = {
        TenantID: GV.userDet.tenantDetail.tenantID,
        ContactType: "Vendor",
        SearchData: "",
      };
      ps.GetContactFilteredList(data).then((res) => {
        const contactName = res.data.data.map((a) => {
          return a;
        });
        setVendorNameList(contactName);
      });
    }
  };

  const deleteLineItem = (e, index) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    if (newInvoiceLines.length <= 1) {
      toast.current.show({
        severity: "error",
        summary:
          "Item deletion is only possible after adding a second line item",
      });
      return;
    }
    toast.current.show({
      severity: "success",
      summary: "Deleted successfully",
    });
    setIsType(true);
    newInvoiceLines.splice(index, 1);
    setInvoiceLines(newInvoiceLines);
    handleTaxChange(newInvoiceLines);
  };

  const handleSelectProduct = (selectedProduct, index) => {
    setManualErr("");
    const updatedLines = invoiceLines.map((line, i) => {
      if (i === index) {
        return {
          id: line.id,
          productname: selectedProduct,
          quantity: 1,
          rate: selectedProduct.siSellingPrice?.toFixed(2),
          discount: 0,
          statetax: selectedProduct.localTaxRate,
          centraltax: selectedProduct.centralTaxRate,
          intertax: selectedProduct.interTaxRate,
          netamount: selectedProduct.siSellingPrice,
          //   producttype: selectedProduct.itemType,
        };
      }

      return line;
    });
    handleTaxChangeWithoutIndex(updatedLines, index);
    handleTaxChange(updatedLines);
    setInvoiceLines(updatedLines);
    setIsType(true);
  };

  const handleInputChangeQuantity = (e, i) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[i].quantity = e.target.value;
    setInvoiceLines(newInvoiceLines);
    let addSubTotal = newInvoiceLines
      .map((a) => a.rate * a.quantity)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSubTotal(addSubTotal);
    handleTaxChangeWithoutIndex(newInvoiceLines, i);
    handleTaxChange(newInvoiceLines);
  };

  const handleInputChangeRate = (e, i) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[i].rate = e.target.value;
    setInvoiceLines(newInvoiceLines);
    let addSubTotal = newInvoiceLines
      .map((a) => a.rate * a.quantity)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSubTotal(addSubTotal);
    handleTaxChangeWithoutIndex(newInvoiceLines, i);
    handleTaxChange(newInvoiceLines);
  };

  const handleTaxChangeWithoutIndex = (lines, i) => {
    const newInvoiceLines = [...lines];
    let total = 0;
    let finalAmount = +newInvoiceLines[i]?.quantity * +newInvoiceLines[i]?.rate;
    let collectTax =
      +(newInvoiceLines[i]?.statetax || 0) +
      (+newInvoiceLines[i]?.centraltax || 0) +
      (+newInvoiceLines[i]?.intertax || 0);
    let findTax = (finalAmount * collectTax) / 100;
    total = finalAmount + findTax;
    newInvoiceLines[i].netamount = total.toFixed(2);
  };

  const handleTaxChange = (newlines) => {
    setInvoiceLines(newlines);
    // setNetAmountValue(0);

    let addSubTotal = newlines
      .map((a) => a.rate * a.quantity)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSubTotal(addSubTotal);

    let calculateStateTax = newlines
      .map(
        (a, i) =>
          (Number(
            a.quantity * a.productname ? a.productname.siSellingPrice : a.rate
          ) /
            100) *
          +a.statetax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateStateTaxPercentage = newlines
      .map((a) => +a.statetax)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSgstTax(calculateStateTaxPercentage);

    let calculateCentralTax = newlines
      .map(
        (a, i) =>
          (Number(
            a.quantity * a.productname ? a.productname.siSellingPrice : a.rate
          ) /
            100) *
          +a.centraltax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateCentralTaxPercentage = newlines
      .map((a) => +a.centraltax)
      .reduce((acc, ttl) => acc + ttl, 0);
    setCgstTax(calculateCentralTaxPercentage);

    let calculateInterTax = newlines
      .map(
        (a, i) =>
          (Number(
            a.quantity * a.productname ? a.productname.siSellingPrice : a.rate
          ) /
            100) *
          +a.intertax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateInterTaxPercentage = newlines
      .map((a) => +a.intertax)
      .reduce((acc, ttl) => acc + ttl, 0);

    setIgstTax(calculateInterTaxPercentage);

    setSgstTaxAmount(
      isFinite(calculateStateTax) ? calculateStateTax.toFixed(2) : 0
    );
    setCgstTaxAmount(
      isFinite(calculateCentralTax) ? calculateCentralTax.toFixed(2) : 0
    );
    setIgstTaxAmount(
      isFinite(calculateInterTax) ? calculateInterTax.toFixed(2) : 0
    );

    let totalAm = newlines
      .map((b) => +b.netamount)
      .reduce((acc, total) => acc + total, 0);

    setNetAmountValue(isFinite(totalAm) ? totalAm : 0);
  };

  const handleInputChangeStateTax = (e, index) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[index].statetax = e.target.value;
    setInvoiceLines(newInvoiceLines);
    handleTaxChangeWithoutIndex(newInvoiceLines, index);
    handleTaxChange(newInvoiceLines);
  };

  const handleInputChangeCentralTax = (e, index) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[index].centraltax = e.target.value;
    setInvoiceLines(newInvoiceLines);
    handleTaxChangeWithoutIndex(newInvoiceLines, index);
    handleTaxChange(newInvoiceLines);
  };

  const handleInputChangeInterTax = (e, index) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[index].intertax = e.target.value;
    setInvoiceLines(newInvoiceLines);
    handleTaxChangeWithoutIndex(newInvoiceLines, index);
    handleTaxChange(newInvoiceLines);
  };

  const handleSelectDropdown = (value, index) => {
    setManualErr("");
    let findVal = productDrop1.find((a) => a.value === value);
    console.log(findVal, index, "findval");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[index].productname = findVal;
    setInvoiceLines(newInvoiceLines);

    // setSelectedItemDropDown(findVal);
    let check = Object.keys(findVal).includes("selectedDates");
    setIsEnable(check);
    if (check) {
      const updatedLines = invoiceLines.map((line, i) => {
        if (i === index) {
          return {
            id: line.id,
            productname: findVal.selectedDates,
            quantity: 1,
            rate: findVal.selectedDates.siSellingPrice?.toFixed(2) || 0,
            discount: 0,
            statetax: findVal.selectedDates.localTaxRate || 0,
            centraltax: findVal.selectedDates.centralTaxRate || 0,
            intertax: findVal.selectedDates.interTaxRate || 0,
            netamount: findVal.selectedDates.siSellingPrice || 0,
            isEnbl: true,
            //   producttype: selectedProduct.itemType,
          };
        }

        return line;
      });
      handleTaxChangeWithoutIndex(updatedLines, index);
      handleTaxChange(updatedLines);
      setInvoiceLines(updatedLines);
      setIsType(true);
    } else {
      const updatedLines = invoiceLines.map((line, i) => {
        if (i === index) {
          return {
            id: line.id,
            productname: check ? findVal.selectedDates : "",
            quantity: 1,
            rate: check
              ? findVal.selectedDates.siSellingPrice?.toFixed(2) || 0
              : 0,
            discount: 0,
            statetax: check ? findVal.selectedDates.localTaxRate || 0 : 0,
            centraltax: check ? findVal.selectedDates.centralTaxRate || 0 : 0,
            intertax: check ? findVal.selectedDates.interTaxRate || 0 : 0,
            netamount: check ? findVal.selectedDates.siSellingPrice || 0 : 0,
            label: findVal.label,
            isEnbl: false,
            //   producttype: selectedProduct.itemType,
          };
        }

        return line;
      });
      handleTaxChangeWithoutIndex(updatedLines, index);
      handleTaxChange(updatedLines);
      setInvoiceLines(updatedLines);
      setIsType(true);
    }

    // setName(event.target.value);
  };

  const onNameChange = (event) => {
    const value = event.target.value.trim();
    setIsAddItem(value === "");
    setName(event.target.value);
  };

  const generateUniqueId = () => {
    return Math.floor(1000 + Math.random() * 9000);
  };

  const addItem = (index) => {
    setProductDrop1([
      ...productDrop1,
      { label: name, value: generateUniqueId() },
    ]);
    setName("");
    setIsAddItem(true);
  };

  const orderStatus = (rowDatas, status) => {
    const datas = {
      TenantID: GV.userDet?.tenantDetail?.tenantID,
      PurchaseOrderID: rowDatas.purchaseOrderID,
      PurchaseStatus: status,
      CreatedBy: GV.userDet?.appUser?.appuserID,
      PONumber: "PO23453",
    };
    ps.UpdateStatusPurchaseOrder(datas)
      .then((res) => {
        console.log(res.data, "tr");
        if (res.data.statusCode === 200) {
          setIsProcess(false);
          toast.current.show({
            severity: "success",
            summary: res.data.message,
          });
          backToGrid();
          setSelectedItemDropDown("");
        } else {
          setIsProcess(false);
        }
      })
      .catch((err) => {
        setIsProcess(false);
      });
  };

  useEffect(() => {
    if (formDataa && formDataa.length > 0) {
      let tax = formDataa.map((c) => {
        const amount = parseFloat(c.seperateamount);
        const tax = parseFloat(c.tax);
        if (!isNaN(amount) && !isNaN(tax)) {
          const taxAmount1 = (amount * tax) / 100;
          return taxAmount1;
        }
        return 0;
      });

      setTaxAmount(tax);
      let totalTax = tax.reduce((acc, total) => acc + total, 0);
      setDisplayTotalTax(totalTax);

      let ttl = formDataa.map((c) => {
        const amount = parseFloat(c.seperateamount);
        const tax = parseFloat(c.tax);
        if (!isNaN(amount) && !isNaN(tax)) {
          const taxAmount1 = (amount * tax) / 100;
          const taxAmount2 = amount + taxAmount1;
          return taxAmount2;
        }
        return 0;
      });

      setTotal(ttl);
      let total = ttl.reduce((acc, total) => acc + total, 0);
      setDisplayTotalExpenses(total);
    } else {
      setTaxAmount([]);
      setDisplayTotalTax(0);
      setTotal([]);
      setDisplayTotalExpenses(0);
    }
  }, [formDataa]);

  const searchItems = (event) => {
    console.log(event.query, "event");
    let query = event.query;
    let _filteredItems = [];

    for (let i = 0; i < productDrop.length; i++) {
      let item = productDrop[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }

    setFilteredItems(_filteredItems);
  };

  const backToGrid = () => {
    props.data.backToPurchaseGrid(true);
    setIsType(false);
    // setIsUpdate(false);
    reset(defaultValues);
    clearFields();
    setIsCustChoose(true);
    setNetAmountValue("");
    setSubTotal(0);
    setSgstTax(0);
    setCgstTax(0);
    setIgstTax(0);
    setNetAmountValue(0);
    setCgstTaxAmount(0);
    setSgstTaxAmount(0);
    setIgstTaxAmount(0);
    // GenerateConfigNumber();
  };

  useEffect(() => {
    if (rowDatas) {
      setPoNumber(rowDatas.poNumber);
    } else {
      GetPurchaseNumber();
    }
    GetSalesItemList();
    GetTenantDetails();
    GetContactFilteredList();
  }, []);

  return (
    <div>
      <BlockUI blocked={isProcess}>
        <Toast ref={toast} />

        <form>
          <div>
            <div
              className="d-flex justify-content-between cc-greyCard-custm align-items-center mb-3"
              style={{ backgroundColor: "white" }}
            >
            <div className="ms-2 my-2 me-2">
              <div className="col-md mb-2">
                <h4 className="kk-bold m-0">New Purchase Order</h4>
              </div>

</div>

              <div>
                <Primebutton
                  type="button"
                  label="Back to Purchase Order list"
                  className="btn btn-danger py-1 me-2 mx-2 kk-btnThin my-auto"
                  onClick={backToGrid}
                />
              </div>
            </div>
          </div>
          <div className="bg-white shadow-sm chart-of-account h-100 p-3">
            <div className="row mx-auto mb-4">
              <div className="col-12 p-0">
                <div className="journal-of-account p-2 pt-0">
                  <div className="row mx-auto">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="d-flex justify-content-between pe-4">
                              <div className="col-md-2 me-3">
                                <label htmlFor="">
                                  <strong className="required">Bill To</strong>
                                </label>
                              </div>
                              <div className="col-md-9">
                                <Controller
                                  name="billto"
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field }) => (
                                    <Dropdown
                                      {...field}
                                      value={selectVendorNameList}
                                      disabled={
                                        rowDatas
                                          ? rowDatas.purchaseStatus ===
                                              "OPEN" ||
                                            rowDatas.purchaseStatus ===
                                              "BILLED" ||
                                            rowDatas.purchaseStatus ===
                                              "REJECTED"
                                          : false
                                      }
                                      onChange={(e) => {
                                        field.onChange(e.value);
                                        vendorNameOnchange(e.value);
                                      }}
                                      options={vendorNameList}
                                      optionLabel="fullName"
                                      placeholder="Select vendor"
                                      className="form-control"
                                    />
                                  )}
                                />
                                {errors.billto && (
                                  <p className="text-danger">
                                    This field is required
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="">
                              <strong>Shipping to</strong>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-12 d-flex mb-2 justify-content-between">
                        <div className="col-md">
                          <div className="row">
                            <div className="col-md p-3 pe-2">
                              {!isCustChoose && (
                                <div
                                  className=" p-2 h-100"
                                  style={{ backgroundColor: "#FDFDFD" }}
                                >
                                  <p className="m-0">
                                    <strong>
                                      {selectVendorNameList?.fullName},
                                    </strong>
                                  </p>
                                  <p className="m-0 ps-2">
                                    {selectVendorNameList?.baAddress1}
                                  </p>
                                  <p className="m-0 ps-2">
                                    {selectVendorNameList?.baState},{" "}
                                    {selectVendorNameList?.baZipCode}
                                  </p>
                                  <p className="m-0 ps-2">
                                    {" "}
                                    <strong className="me-2">
                                      <i className="pi pi-phone"></i>
                                    </strong>{" "}
                                    {selectVendorNameList?.phone},{" "}
                                    <strong className="m-2">
                                      <i className="pi pi-envelope"></i>
                                    </strong>{" "}
                                    {selectVendorNameList?.email}
                                    {}
                                  </p>
                                </div>
                              )}
                              {isCustChoose && (
                                <div
                                  className="text-center p-2 h-100"
                                  style={{ backgroundColor: "#FDFDFD" }}
                                >
                                  <Empty description="Select Customer to view details" />
                                </div>
                              )}
                            </div>
                            <div className="col-md p-3 pe-2">
                              <div
                                className=" p-2 h-100 "
                                style={{ backgroundColor: "#FDFDFD" }}
                              >
                                <p className="m-0">
                                  <strong>{tenantDetails.tenantName},</strong>
                                </p>
                                <p className="m-0 ps-2">
                                  {tenantDetails.billAddress1},{" "}
                                  {tenantDetails.billAddress2}
                                </p>
                                <p className="m-0 ps-2">
                                  <strong className="me-2">
                                    <i className="pi pi-home"></i>
                                  </strong>
                                  {tenantDetails.billCity} -{" "}
                                  {tenantDetails.billZipCode} -{" "}
                                  {tenantDetails.billCountry}
                                </p>
                                <p className="m-0 ps-2">
                                  <strong className="me-2">
                                    <i className="pi pi-phone"></i>
                                  </strong>{" "}
                                  {tenantDetails.primaryPhone},{" "}
                                  <strong className="m-2">
                                    <i className="pi pi-envelope"></i>
                                  </strong>{" "}
                                  {tenantDetails.email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Primediveder layout="vertical" />

                        <div className="col-md-6">
                          <div className="row mx-auto mb-3">
                            <div className="col-md">
                              <label htmlFor="" className="">
                                {" "}
                                PO Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={poNumber}
                                disabled
                                {...register("ponumber", { required: false })}
                              />
                              {errors.ponumber && (
                                <p className="text-danger">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className="col-md">
                              <label htmlFor="" className="required">
                                {" "}
                                Reference Number
                              </label>
                              <input
                                type="text"
                                disabled={
                                  rowDatas
                                    ? rowDatas.purchaseStatus === "OPEN" ||
                                      rowDatas.purchaseStatus === "BILLED" ||
                                      rowDatas.purchaseStatus === "REJECTED"
                                    : false
                                }
                                className="form-control"
                                {...register("referencenumber", {
                                  required: true,
                                })}
                              />
                              {errors.referencenumber && (
                                <p className="text-danger">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className="col-md">
                              <label htmlFor="" className="">
                                Date
                              </label>
                              <Calendar
                                name="date"
                                value={dateSelect}
                                disabled={
                                  rowDatas
                                    ? rowDatas.purchaseStatus === "OPEN" ||
                                      rowDatas.purchaseStatus === "BILLED" ||
                                      rowDatas.purchaseStatus === "REJECTED"
                                    : false
                                }
                                minDate={new Date()}
                                className="form-control p-0"
                                // {...register("date", { required: true })}
                                onChange={(e) => handleDateSelect(e)}
                                placeholder="Select date"
                              />

                              {errors.date && (
                                <p className="text-danger">
                                  This field is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="row mx-auto mb-3">
                            <div className="col-md">
                              <label htmlFor="" className="required">
                                {" "}
                                Expected Delivery Date
                              </label>
                              <Controller
                                name="deliverydate"
                                control={control}
                                rules={{ required: "This field is required" }}
                                render={({ field }) => (
                                  <Calendar
                                    {...field}
                                    value={selectExpectedDate || field.value}
                                    disabled={
                                      rowDatas
                                        ? rowDatas.purchaseStatus === "OPEN" ||
                                          rowDatas.purchaseStatus ===
                                            "BILLED" ||
                                          rowDatas.purchaseStatus === "REJECTED"
                                        : false
                                    }
                                    minDate={new Date()}
                                    className="form-control p-0"
                                    // {...register("deliverydate", { required: true })}
                                    onChange={(e) => {
                                      field.onChange(e.value);
                                      setSelectExpectedDate(e.value);
                                    }}
                                    placeholder="Select delivery date"
                                  />
                                )}
                              />

                              {errors.deliverydate && (
                                <p className="text-danger">
                                  This field is required
                                </p>
                              )}
                            </div>{" "}
                            <div className="col-md">
                              <label htmlFor="" className="required">
                                {" "}
                                Payment Terms
                              </label>
                              <Controller
                                name="paymentterms"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <Dropdown
                                    {...field}
                                    value={field.value || ""}
                                    disabled={
                                      rowDatas
                                        ? rowDatas.purchaseStatus === "OPEN" ||
                                          rowDatas.purchaseStatus ===
                                            "BILLED" ||
                                          rowDatas.purchaseStatus === "REJECTED"
                                        : false
                                    }
                                    onChange={(e) => {
                                      field.onChange(e.value);
                                      handleSelectTerm(e.value);
                                    }}
                                    options={termName}
                                    optionLabel="name"
                                    placeholder="Select Term"
                                    className="form-control "
                                    // disabled={editable}
                                  />
                                )}
                              />
                              {errors.paymentterms && (
                                <p className="text-danger">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className="col-md">
                              <label htmlFor="" className="required">
                                {" "}
                                Delivery Terms
                              </label>
                              <input
                                type="text"
                                disabled={
                                  rowDatas
                                    ? rowDatas.purchaseStatus === "OPEN" ||
                                      rowDatas.purchaseStatus === "BILLED" ||
                                      rowDatas.purchaseStatus === "REJECTED"
                                    : false
                                }
                                className="form-control"
                                {...register("deliveryterms", {
                                  required: true,
                                })}
                                // value={configNumber}
                              />
                              {errors.deliveryterms && (
                                <p className="text-danger">
                                  This field is required
                                </p>
                              )}
                            </div>
                          </div>

                          {/* <div className="row mx-auto">
                    <div className="col-md">
                        <label htmlFor=""> Delivery Terms</label>
                        <input
                          type="text"
                          className="form-control"
                          // value={configNumber}
                        />
                      </div>

                      <div className="col-md"></div>{" "}
                      <div className="col-md"></div>
                    </div> */}
                        </div>
                      </div>
                      <Primediveder
                        className="ms-5 mb-3"
                        style={{ width: "94%" }}
                      />
                    </div>

                    <div className="col-md-12 p-0">
                      <div className="row mx-auto">
                        <div>
                          <strong>Items</strong>
                        </div>
                        <div className="col-sm-12 p-0 m-2 mt-0 gap-5">
                          <div className="row">
                            <div className="col-md">
                              <div className="table-responsive">
                                <table className="table mt-3">
                                  <thead>
                                    <tr className="heading">
                                      <th
                                        style={{ width: "18%" }}
                                        className="borderIn"
                                      >
                                        PRODUCT NAME
                                      </th>
                                      <th
                                        className="borderIn"
                                        style={{ width: "5%" }}
                                      >
                                        QUANTITY
                                      </th>
                                      <th
                                        className="borderIn"
                                        style={{ width: "6%" }}
                                      >
                                        RATE ({GV.userDet.tenantConfig.currency}
                                        )
                                      </th>
                                      {/* <th
                                      className="borderIn"
                                      style={{ width: "6%" }}
                                    >
                                      DISCOUNT (%)
                                    </th> */}
                                      <th colSpan="3">TAX (%)</th>
                                      <th
                                        className="borderIn"
                                        style={{ width: "7%" }}
                                      >
                                        NET AMOUNT (
                                        {GV.userDet.tenantConfig.currency})
                                      </th>
                                      <th style={{ width: "3%" }}></th>
                                    </tr>
                                    <tr className="sub-heading">
                                      <th></th>
                                      <th></th>
                                      {/* <th></th> */}
                                      <th></th>
                                      <th className="borderIn">
                                        {countryTaxDetails?.localTaxName}
                                      </th>
                                      <th className="borderIn">
                                        {countryTaxDetails?.centralTaxName}
                                      </th>
                                      <th className="borderIn">
                                        {countryTaxDetails?.interTaxName}
                                      </th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {invoiceLines.map((line, index) => (
                                      <tr key={index}>
                                        <td>
                                          {/* <Dropdown
                                          name="productname"
                                          value={line.productname}
                                          onChange={(e) => {
                                            handleSelectProduct(e.value, index);
                                          }}
                                          options={productDrop}
                                          // disabled={fieldEnabled}
                                          optionLabel="name"
                                          placeholder="Choose to select"
                                          className="form-control-custom sm-2"
                                        /> */}

                                          <Select
                                            className="cust-form-control p-0"
                                            name="productname"
                                            placeholder="Choose to select item or add item"
                                            disabled={
                                              rowDatas
                                                ? rowDatas.purchaseStatus ===
                                                    "OPEN" ||
                                                  rowDatas.purchaseStatus ===
                                                    "BILLED" ||
                                                  rowDatas.purchaseStatus ===
                                                    "REJECTED"
                                                : false
                                            }
                                            value={
                                              line.productname
                                                ? line.productname.value
                                                : undefined
                                            }
                                            onChange={(value) =>
                                              handleSelectDropdown(value, index)
                                            }
                                            dropdownRender={(menu) => (
                                              <>
                                                {menu}
                                                <Divider
                                                  style={{ margin: "8px 0" }}
                                                />
                                                <Space className="ant-drop-search">
                                                  <Input
                                                    placeholder="Please enter item"
                                                    className="ant-drop-input"
                                                    ref={inputRef}
                                                    value={name}
                                                    onChange={(e) =>
                                                      onNameChange(e)
                                                    }
                                                  />
                                                  <Button
                                                    className="btn btn-primary"
                                                   disabled={isAddItem}
                                                    style={{
                                                      backgroundColor:
                                                        "#1E6444",
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                    type="text"
                                                    icon={<PlusOutlined />}
                                                    onClick={() =>
                                                      addItem(index)
                                                    }
                                                  >
                                                    Add item
                                                  </Button>
                                                </Space>
                                              </>
                                            )}
                                            options={productDrop1.map(
                                              (item) => ({
                                                label: item.label,
                                                value: item.value,
                                              })
                                            )}
                                          />
                                        </td>

                                        <td>
                                          <input
                                            type="text"
                                            name="quantity"
                                            className="form-control"
                                            disabled={
                                              rowDatas
                                                ? rowDatas.purchaseStatus ===
                                                    "OPEN" ||
                                                  rowDatas.purchaseStatus ===
                                                    "BILLED" ||
                                                  rowDatas.purchaseStatus ===
                                                    "REJECTED"
                                                : false
                                            }
                                            value={line.quantity}
                                            onInput={(e) => {
                                              handleInputChangeQuantity(
                                                e,
                                                index
                                              );
                                            }}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            name="rate"
                                            className="form-control text-end"
                                            disabled={
                                              rowDatas
                                                ? rowDatas.purchaseStatus ===
                                                    "OPEN" ||
                                                  rowDatas.purchaseStatus ===
                                                    "BILLED" ||
                                                  rowDatas.purchaseStatus ===
                                                    "REJECTED"
                                                : false
                                            }
                                            value={line.rate}
                                            onInput={(e) => {
                                              handleInputChangeRate(e, index);
                                            }}
                                          />
                                          {/* <AutoComplete
                                          value={selectedItem}
                                          suggestions={filteredItems}
                                          completeMethod={searchItems}
                                          virtualScrollerOptions={{
                                            itemSize: 38,
                                          }}
                                          field="name"
                                          dropdown
                                          onChange={(e) => setSelectedItem(e.value)}
                                        /> */}
                                        </td>
                                        {/* <td>
                                        <input
                                          type="number"
                                          name="discount"
                                          disabled
                                          className="form-control text-end"
                                          value={line.discount}
                                          onInput={(e) => {
                                            // handleInputChangeDiscount(e, index);
                                          }}
                                        />
                                      </td> */}
                                        <td>
                                          <input
                                            type="number"
                                            name="statetax"
                                            className="form-control text-end"
                                            disabled={
                                              line.isEnbl || rowDatas
                                                ? rowDatas.purchaseStatus ===
                                                    "OPEN" ||
                                                  rowDatas.purchaseStatus ===
                                                    "BILLED" ||
                                                  rowDatas.purchaseStatus ===
                                                    "REJECTED"
                                                : false
                                            }
                                            // disabled={!line.discount || !line.quantity}
                                            value={line.statetax}
                                            onInput={(e) => {
                                              handleInputChangeStateTax(
                                                e,
                                                index
                                              );
                                            }}
                                            title="To adjust the tax percentage, please access the Inventory section"
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            name="centraltax"
                                            className="form-control text-end"
                                            // disabled={!line.discount || !line.quantity}
                                            disabled={
                                              line.isEnbl || rowDatas
                                                ? rowDatas.purchaseStatus ===
                                                    "OPEN" ||
                                                  rowDatas.purchaseStatus ===
                                                    "BILLED" ||
                                                  rowDatas.purchaseStatus ===
                                                    "REJECTED"
                                                : false
                                            }
                                            value={line.centraltax}
                                            onInput={(e) => {
                                              handleInputChangeCentralTax(
                                                e,
                                                index
                                              );
                                            }}
                                            title="To adjust the tax percentage, please access the Inventory section"
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            name="intertax"
                                            className="form-control text-end"
                                            disabled={
                                              line.isEnbl || rowDatas
                                                ? rowDatas.purchaseStatus ===
                                                    "OPEN" ||
                                                  rowDatas.purchaseStatus ===
                                                    "BILLED" ||
                                                  rowDatas.purchaseStatus ===
                                                    "REJECTED"
                                                : false
                                            }
                                            // disabled={!line.discount || !line.quantity}
                                            value={line.intertax}
                                            onInput={(e) => {
                                              handleInputChangeInterTax(
                                                e,
                                                index
                                              );
                                            }}
                                            title="To adjust the tax percentage, please access the Inventory section"
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            name="netamount"
                                            className="form-control text-end"
                                            value={line.netamount}
                                            disabled
                                          />
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            padding: "1%",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            style={{
                                              background: "transparent",
                                            }}
                                            // disabled={fieldEnabled}
                                            disabled={
                                              rowDatas
                                                ? rowDatas.purchaseStatus ===
                                                    "OPEN" ||
                                                  rowDatas.purchaseStatus ===
                                                    "BILLED" ||
                                                  rowDatas.purchaseStatus ===
                                                    "REJECTED"
                                                : false
                                            }
                                            onClick={() =>
                                              deleteLineItem(line, index)
                                            }
                                          >
                                            <i
                                              className="pi pi-trash"
                                              aria-hidden="true"
                                              style={{ color: "red" }}
                                            ></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                {manualErr && (
                                  <p className="text-danger my-3">
                                    {manualErr}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          {isProcess && (
                            <ProgressSpinner
                              style={{
                                width: "50px",
                                height: "50px",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                              strokeWidth="2"
                              fill="var(--surface-ground)"
                              animationDuration=".5s"
                            />
                          )}
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row mx-auto mt-4">
                                <div className="col-md mb-2 ps-0">
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={addNewLine}
                                    // disabled={fieldEnabled}
                                    disabled={
                                      rowDatas
                                        ? rowDatas.purchaseStatus === "OPEN" ||
                                          rowDatas.purchaseStatus ===
                                            "BILLED" ||
                                          rowDatas.purchaseStatus === "REJECTED"
                                        : false
                                    }
                                  >
                                    + Add New Items
                                  </button>
                                </div>
                                <div className="col-md"></div>
                                <div className="col-md"></div>
                                <div
                                  className="col-md p-2"
                                  style={{
                                    background: "rgb(226 243 252)",
                                  }}
                                >
                                  <div className="d-flex justify-content-between">
                                    <p> Sub Total</p>
                                    <p>
                                      {" "}
                                      <i
                                        className="pi pi-indian-rupee"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {GV.userDet.tenantConfig.currency}
                                      {subTotal}
                                    </p>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <p> Tax(SGST)</p>
                                    <p>
                                      {" "}
                                      <i
                                        className="pi pi-indian-rupee"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {GV.userDet.tenantConfig.currency}
                                      {sgstTaxAmount}
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <p> Tax(CGST)</p>
                                    <p>
                                      {" "}
                                      <i
                                        className="pi pi-indian-rupee"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {GV.userDet.tenantConfig.currency}
                                      {cgstTaxAmount}
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <p> Tax(IGST)</p>
                                    <p>
                                      {" "}
                                      <i
                                        className="pi pi-indian-rupee"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {GV.userDet.tenantConfig.currency}
                                      {igstTaxAmount}
                                    </p>
                                  </div>

                                  <Primediveder className="m-0" />
                                  {/* <div className="d-flex justify-content-between">
                        <p> Discount </p>
                        {
                        discountedAmount !== "0.00" 
                        ? (
                          <p className="text-danger">
                            - {GV.userDet.tenantConfig.currency}{" "}
                            {discountedAmount}
                          </p>
                        ) : (
                          <p className="">
                            {" "}
                            {GV.userDet.tenantConfig.currency}{" "}
                            {discountedAmount}
                          </p>
                        )}
                      </div> */}
                                  <div className="d-flex justify-content-between">
                                    <p>
                                      <strong> Total</strong>
                                    </p>
                                    <p>
                                      {" "}
                                      <strong>
                                        <i
                                          className="pi pi-indian-rupee"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {GV.userDet.tenantConfig.currency}
                                        {netAmountValue?.toFixed(2)}
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {rowDatas.purchaseStatus !== "BILLED" &&
                    rowDatas.purchaseStatus !== "REJECTED" && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <Primebutton
                          type="button"
                          label="Cancel"
                          // autoFocus
                          className="btn btn-danger me-2"
                        />

                        {rowDatas.purchaseStatus === "OPEN" ? (
                          <>
                            <Primebutton
                              type="button"
                              className="btn btn-primary me-2"
                              label="Approve"
                              onClick={() => orderStatus(rowDatas, "BILLED")}
                            />
                            <Primebutton
                              type="button"
                              className="btn btn-primary my-3"
                              label="Reject"
                              style={{ backgroundColor: "#c1c140" }}
                              onClick={() => orderStatus(rowDatas, "REJECTED")}
                            />
                          </>
                        ) : (
                          <>
                            <Primebutton
                              type="button"
                              label="Save Draft"
                              onClick={handleSubmit((e) =>
                                handleSaveBtn(e, "DRAFT")
                              )}
                              className="btn btn-primary me-2"
                            />
                            {!rowDatas ? (
                              <Primebutton
                                type="Button"
                                label="Save & Submit"
                                onClick={handleSubmit((e) =>
                                  handleSaveBtn(e, "OPEN")
                                )}
                                className="btn btn-primary"
                              />
                            ) : (
                              <Primebutton
                                type="Button"
                                label="Update"
                                onClick={handleSubmit((e) =>
                                  handleSaveBtn(e, "OPEN")
                                )}
                                className="btn btn-primary"
                              />
                            )}
                          </>
                        )}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </BlockUI>
    </div>
  );
};

export default NewPurchaseOrderForm;
