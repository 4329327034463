import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown as Primedropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import InvoiceService from "../../../../client/src/services/invoice-service";
import GlobalVariable from "../../../../client/src/services/globalVar";
import { IcomoonReact, iconSet, Toast } from "../PrimeReact";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import salesService from "../../services/sales-service";
import { BlockUI } from "primereact/blockui";
import { Empty } from "antd";
import { ProgressSpinner } from "primereact/progressspinner";
import { DatePicker, Space, Dropdown, Menu, Select } from "antd";
import { DownOutlined } from "@ant-design/icons";
import purchaseService from "../../services/purchase-sevice";
import { object } from "yup";
import InvoicePayment from "./InvoicePayment";

export const CreditNoteForm = (props) => {
  const rowDatas = props.data.rowData;
  console.log(rowDatas,'rowDatas');
  const is = new InvoiceService();
  const ss = new salesService();
  const ps = new purchaseService();
  const paymodal = useRef(null);

  const [tenantDetails, setTenantDetails] = useState({});
  const [billToDropDown, setBillToDropDown] = useState([]);
  const [selectedBillToDropDown, setSelectedBillToDropDown] = useState([]);
  const [isCustChoose, setIsCustChoose] = useState(true);
  const [isShowEstimate, setIsShowEstimate] = useState(true);
  const [countryTaxDetails, setCountryTaxDetails] = useState({});
  const [rowData, setRowData] = useState([]);
  const [productDrop, setProductDrop] = useState([]);
  const [discountValue, setDiscountValue] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [sgstTax, setSgstTax] = useState(0);
  const [cgstTax, setCgstTax] = useState(0);
  const [sgstTaxAmount, setSgstTaxAmount] = useState(0);
  const [igstTax, setIgstTax] = useState(0);
  const [igstTaxAmount, setIgstTaxAmount] = useState(0);
  const [cgstTaxAmount, setCgstTaxAmount] = useState(0);
  const [shipCharge, setShipCharge] = useState(0);
  const [roundOff, setRoundOff] = useState(0);
  const [netAmountValue, setNetAmountValue] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState("0.00");
  const toast = useRef(null);
  const [isType, setIsType] = useState(false);
  const [estimateDateSelect, setEstimateDateSelect] = useState(new Date());
  const [expireDateSelect, setExpireDateSelect] = useState();
  const [isProcess, setIsProcess] = useState(false);
  const [manualErr, setManualErr] = useState(false);
  const [configNumber, setConfigNumber] = useState("");
  const [hideBtn, setHideBtn] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState();
  const [creditNoteNumber, setCreditNoteNumber] = useState();
  const [selectReturnDate, setSelectReturnDate] = useState();
  const [returnReson, setReturnReson] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [payment, setPayment] = useState("");
  const [maxQuantity, setMaxQuantity] = useState();
  const [getInputVal, setGetInputVal] = useState();

  const [invoiceLines, setInvoiceLines] = useState([
    {
      id: 1,
      productname: "",
      quantity: 0,
      prequantity: "",
      rate: "",
      discount: 0,
      statetax: 0,
      centraltax: 0,
      intertax: 0,
      returnreason: "",
      netamount: "",
      producttype: "",
      shippngcharge: "",
    },
  ]);

  const addNewLine = () => {
    setIsType(true);
    // setIsDiscountDisabled(true)
    const invalidInvoiceLines = invoiceLines.filter(
      (line) =>
        line.productname === "" ||
        line.quantity === "" ||
        line.discount === "" ||
        line.tax === ""
    );

    if (invalidInvoiceLines.length > 0) {
      setManualErr("Unable to duplicate empty fields");
      return;
    } else {
      setManualErr("");
      const newId = invoiceLines[invoiceLines.length - 1].id + 1;
      const newLine = {
        id: newId,
        productname: "",
        quantity: "",
        rate: "",
        discount: "",
        statetax: "",
        centraltax: "",
        intertax: "",
        netamount: "",
        returnreason: "",
        producttype: "",
      };

      setInvoiceLines((prevInvoiceLines) => [...prevInvoiceLines, newLine]);
    }
  };

  const clearFields = () => {
    setInvoiceLines([
      {
        id: 1,
        productname: "",
        quantity: "",
        prequantity: "",
        rate: "",
        discount: "",
        statetax: "",
        centraltax: "",
        intertax: "",
        netamount: "",
        returnreason: "",
        producttype: "",
        shippngcharge: "",
      },
    ]);
  };

  const defaultValues = {
    returndate: "",
  };

  const {
    register,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange", defaultValues });

  const handleSelectToBillDropdown = (value) => {
    const selected = billToDropDown.find((item) => item.contactID === value);
    setIsCustChoose(false);
    setSelectedBillToDropDown(selected);
  };
  const handleSelectToBillDropdown1 = (e) => {
    setIsCustChoose(false);
    setSelectedBillToDropDown(e);
  };

  const handleSelectEstimateDateSelect = (e) => {
    setEstimateDateSelect(e.target.value);
  };

  const handleSelectExpireDateSelect = (e) => {
    console.log(e);
    setExpireDateSelect(e);
  };

  const handleSelectProduct = (selectedProduct, index) => {
    setManualErr("");
    const updatedLines = invoiceLines.map((line, i) => {
      if (i === index) {
        return {
          id: line.id,
          productname: selectedProduct,
          quantity: 1,
          prequantity: 0,
          rate: selectedProduct.siSellingPrice?.toFixed(2),
          discount: 0,
          statetax: selectedProduct.localTaxRate,
          centraltax: selectedProduct.centralTaxRate,
          intertax: selectedProduct.interTaxRate,
          netamount: selectedProduct.siSellingPrice,
          returnreason: "",
          producttype: selectedProduct.itemType,
        };
      }

      return line;
    });
    handleTaxChangeWithoutIndex(updatedLines, index);
    handleTaxChange(updatedLines);
    setInvoiceLines(updatedLines);
    setIsType(true);
  };

  const handleTaxChangeWithoutIndex = (lines, i) => {
    const newInvoiceLines = [...lines];
    let total = 0;

    let totalDiscountAmount = newInvoiceLines
      .map((a) => {
        let discountPercentage = parseFloat(a.discount || 0) / 100;
        let quant = Number(a.quantity * a.productname.siSellingPrice);
        return quant * discountPercentage;
      })
      .reduce((acc, ttl) => acc + ttl, 0);
    let discount =
      totalDiscountAmount && isFinite(totalDiscountAmount)
        ? totalDiscountAmount.toFixed(2)
        : "0.00";
    setDiscountedAmount(discount);

    let separateLineItem =
      +newInvoiceLines[i]?.productname.siSellingPrice *
      (+newInvoiceLines[i]?.discount / 100);
    let discountPrice =
      +newInvoiceLines[i]?.productname.siSellingPrice - +separateLineItem;

    let finalAmount =
      +newInvoiceLines[i]?.quantity *
        +newInvoiceLines[i]?.productname.siSellingPrice -
      +separateLineItem;
    let collectTax =
      +(newInvoiceLines[i]?.statetax || 0) +
      (+newInvoiceLines[i]?.centraltax || 0) +
      (+newInvoiceLines[i]?.intertax || 0);
    let findTax = (finalAmount * collectTax) / 100;
    total = finalAmount + findTax;
    newInvoiceLines[i].netamount = total.toFixed(2);
  };

  const handleTaxChange = (newlines) => {
    setInvoiceLines(newlines);
    // setNetAmountValue(0);
    let disPer = newlines
      .map((a) => +a.discount)
      .reduce((acc, total) => acc + total, 0);
    let calculatePer = (+disPer * 100) / (newlines.length * 100);
    setDiscountValue(calculatePer);

    let addSubTotal = newlines
      .map((a) => a.rate * a.quantity)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSubTotal(addSubTotal);

    let calculateStateTax = newlines
      .map(
        (a, i) =>
          (Number(a.quantity * a.productname.siSellingPrice) / 100) *
          +a.statetax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateStateTaxPercentage = newlines
      .map((a) => +a.statetax)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSgstTax(calculateStateTaxPercentage);

    let calculateCentralTax = newlines
      .map(
        (a, i) =>
          (Number(a.quantity * a.productname.siSellingPrice) / 100) *
          +a.centraltax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateCentralTaxPercentage = newlines
      .map((a) => +a.centraltax)
      .reduce((acc, ttl) => acc + ttl, 0);
    setCgstTax(calculateCentralTaxPercentage);

    let calculateInterTax = newlines
      .map(
        (a, i) =>
          (Number(a.quantity * a.productname.siSellingPrice) / 100) *
          +a.intertax
      )
      .reduce((acc, ttl) => acc + ttl, 0);

    let calculateInterTaxPercentage = newlines
      .map((a) => +a.intertax)
      .reduce((acc, ttl) => acc + ttl, 0);

    setIgstTax(calculateInterTaxPercentage);

    setSgstTaxAmount(
      isFinite(calculateStateTax) ? calculateStateTax.toFixed(2) : 0
    );
    setCgstTaxAmount(
      isFinite(calculateCentralTax) ? calculateCentralTax.toFixed(2) : 0
    );
    setIgstTaxAmount(
      isFinite(calculateInterTax) ? calculateInterTax.toFixed(2) : 0
    );

    let roundVal = newlines
      .map((b) => +b.netamount - Math.trunc(+b.netamount))
      .reduce((acc, total) => acc + total, 0);
    setRoundOff(isFinite(roundVal) ? roundVal.toFixed(2) : 0);
    let totalAm = newlines
      .map((b) => +b.netamount)
      .reduce((acc, total) => acc + total, 0);

    setNetAmountValue(isFinite(totalAm) ? totalAm : 0);

    console.log(netAmountValue);
  };

  const handleInputChangeQuantity = (e, i) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[i].quantity = e.target.value;
    setGetInputVal(newInvoiceLines[i].quantity);
    setMaxQuantity(parseInt(rowDatas.salesItemsList[i].quantity));
    setInvoiceLines(newInvoiceLines);
    let addSubTotal = newInvoiceLines
      .map((a) => a.rate * a.quantity)
      .reduce((acc, ttl) => acc + ttl, 0);
    setSubTotal(addSubTotal);
    handleTaxChangeWithoutIndex(newInvoiceLines, i);
    handleTaxChange(newInvoiceLines);
  };



  const deleteLineItem = (e, index) => {
    const newInvoiceLines = [...invoiceLines];
    if (newInvoiceLines.length <= 1) {
      toast.current.show({
        severity: "error",
        summary:
          "You can't delete the last line item",
      });
      return;
    }
    toast.current.show({
      severity: "success",
      summary: "Deleted successfully",
    });
    setIsType(true);
    newInvoiceLines.splice(index, 1);
    setInvoiceLines(newInvoiceLines);
    handleTaxChange(newInvoiceLines);
  };

  const handleInputChangeDiscount = (e, i) => {
    setManualErr("");
    const inputValue = e.target.value;

    if (inputValue.includes("-")) {
      const sanitizedInput = inputValue.replace("-", "");
      e.target.value = sanitizedInput;
    }

    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[i].discount = e.target.value;
    setDiscountValue(e.target.value);
    setInvoiceLines(newInvoiceLines);

    let disPer = newInvoiceLines
      .map((a) => +a.discount)
      .reduce((acc, total) => acc + total, 0);
    let calculatePer = (+disPer * 100) / (newInvoiceLines.length * 100);
    let a = calculatePer;
    setDiscountValue(a);
    handleTaxChangeWithoutIndex(newInvoiceLines, i);
    handleTaxChange(newInvoiceLines);
  };

  const getShippingCharge = (e) => {
    const newInvoiceLines = [...invoiceLines];
    const charge = +e.target.value;
    setShipCharge(charge);
    let totalAm = newInvoiceLines
      .map((b) => +b.netamount)
      .reduce((acc, total) => acc + total, 0);
    let withShippin = totalAm + charge;
    setNetAmountValue(withShippin);
  };

  const onChangeInvoiceDate = (date, dateString) => {
    console.log(date, dateString, "invoice");
  };

  const onChangeReturnDate = (date, dateString) => {
    console.log(date, dateString, "return");
  };

  const GetTenantDetails = () => {
    setIsProcess(true);
    is.GetTenantDetails(GlobalVariable.userDet.tenantDetail.tenantID).then(
      (res) => {
        setIsProcess(false);
        setTenantDetails(res.data.data);
        GetCountryTaxName(res.data.data.country);
      }
    );
  };

  const GetCountryTaxName = (country) => {
    is.GetCountryTaxName(country).then((res) => {
      if (res.data.statusCode === 200) {
        setCountryTaxDetails(res.data.data);
      }
    });
  };

  const GetSalesItemList = () => {
    setIsProcess(true);

    return new Promise((resolve, reject) => {
      is.GetSalesItemList(GlobalVariable.userDet.tenantDetail.tenantID)
        .then((res) => {
          setIsProcess(false);
          if (res.data.statusCode === 200) {
            setProductDrop(res.data.data);
            resolve(res.data.data);
          } else {
            reject(new Error("Failed to fetch sales item list"));
          }
        })
        .catch((error) => {
          setIsProcess(false);
          reject(error);
        });
    });
  };

  const GetContact = () => {
    setIsProcess(true);
    const creditId = Object.keys(rowDatas)?.includes("creditNoteID");
    setIsDisable(creditId);
    if (!creditId) {
      const data = {
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        ContactType: "Customer",
        CustomerType: "all",
        Status: "Active",
      };
      is.GetContact(data).then((res) => {
        setIsProcess(false);
        setBillToDropDown(res.data.data);
        if (res.data.data) {
          if (rowDatas) {
            setIsType(true);
            setIsCustChoose(false);
            setHideBtn(rowDatas.status === "PENDING");
            let bindBilltoValue = res.data.data.find(
              (a) => a.firstName === rowDatas.firstName
            );
            setSelectedBillToDropDown(bindBilltoValue);
            setValue("billto", bindBilltoValue);

            const iDate = new Date(rowDatas.invoiceDate);
            setInvoiceDate(iDate);

            setConfigNumber(rowDatas.estimateNumber);
            setSubTotal(rowDatas.subTotal);
            setShipCharge(rowDatas.shippingCharge);
            setRoundOff(rowDatas.roundOff?.toFixed(2));
            setDiscountValue(rowDatas.discountRate);
            setSgstTax(rowDatas.localTaxRate);
            setCgstTax(rowDatas.centralTaxRate);
            setIgstTax(rowDatas.interTaxRate);
            setNetAmountValue(rowDatas.totalAmount);
            setDiscountedAmount(rowDatas.discountAmount?.toFixed(2));
            setSgstTaxAmount(rowDatas.localTaxAmount);
            setCgstTaxAmount(rowDatas.centralTaxAmount);
            setIgstTaxAmount(rowDatas.interTaxAmount);
            GetSalesItemList().then((res) => {
              const productDetails = rowDatas.salesItemsList.map((a, i) => ({
                id: i + 1,
                productname: res.find((x) => x.name === a.itemName) || "",
                quantity: a.quantity || "",
                prequantity: a.quantity,
                rate: a.rate || "",
                discount: a.discount || 0,
                statetax: a.localTaxRate || 0,
                centraltax: a.centralTaxRate || 0,
                intertax: a.interTaxRate || 0,
                netamount: a.totalAmount?.toFixed(2) || "",
                producttype: a.itemType || "",
                estimateID: a.estimateID || 0,
                returnreason: a.reason || "",
              }));
              setInvoiceLines(productDetails);
            });
          }
        }
      });
    } else {
      const data = {
        TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
        ContactType: "Customer",
        CustomerType: "all",
        Status: "Active",
      };
      is.GetContact(data).then((res) => {
        setIsProcess(false);
        setBillToDropDown(res.data.data);
        if (res.data.data) {
          if (rowDatas) {
            setIsType(true);
            setIsCustChoose(false);
            setHideBtn(rowDatas.status === "PENDING");
            let bindBilltoValue = res.data.data.find(
              (a) => a.firstName === rowDatas.firstName
            );

            setSelectedBillToDropDown(bindBilltoValue);
            setValue("billto", bindBilltoValue);
            // setValue("billto", {label:bindBilltoValue.companyName,value:bindBilltoValue.contactID});

            const iDate = new Date(rowDatas.invoiceDate);
            setInvoiceDate(iDate);

            const iDate1 = new Date(rowDatas.returnDate);
            setSelectReturnDate(iDate1);

            setConfigNumber(rowDatas.estimateNumber);
            setSubTotal(rowDatas.subTotal);
            setShipCharge(rowDatas.shippingCharge);
            setRoundOff(rowDatas.roundOff?.toFixed(2));
            setDiscountValue(rowDatas.discountRate);
            setSgstTax(rowDatas.localTaxRate);
            setCgstTax(rowDatas.centralTaxRate);
            setIgstTax(rowDatas.interTaxRate);
            setNetAmountValue(rowDatas.totalAmount);
            setDiscountedAmount(rowDatas.discountAmount?.toFixed(2));
            setSgstTaxAmount(rowDatas.localTaxAmount);
            setCgstTaxAmount(rowDatas.centralTaxAmount);
            setIgstTaxAmount(rowDatas.interTaxAmount);
            GetSalesItemList().then((res) => {
              const productDetails = rowDatas.salesReturnItems.map((a, i) => ({
                id: i + 1,
                productname: res.find((x) => x.name === a.itemName) || "",
                quantity: a.returnQuantity || 0,
                prequantity: a.returnQuantity || 0,
                rate: a.rate || "",
                discount: a.discount || 0,
                statetax: a.localTaxRate || 0,
                centraltax: a.centralTaxRate || 0,
                intertax: a.interTaxRate || 0,
                returnreason: a.reason,
                netamount: a.totalAmount?.toFixed(2) || "",
                producttype: a.itemType || "",
                estimateID: a.estimateID || 0,
              }));
              setInvoiceLines(productDetails);
            });
          }
        }
      });
    }
  };

  const handleReasonChange = (e, i) => {
    setManualErr("");
    const newInvoiceLines = [...invoiceLines];
    newInvoiceLines[i].returnreason = e.target.value;
    setReturnReson(e.target.value);
    setInvoiceLines(newInvoiceLines);
  };

  const onSubmit = (data) => {
    let isValid = true;

    for (let i = 0; i < invoiceLines.length; i++) {
      const getInputVal = invoiceLines[i].quantity;
      const maxQuantity = rowDatas.salesItemsList[i].quantity;

      if (getInputVal < 1 || getInputVal > maxQuantity) {
        isValid = false;
        break;
      }
    }

    if (!isValid) {
      setManualErr(`Entered quantity must be less than the sold quantity`);
      return;
    } else {
      setIsProcess(true);
      const iDate = new Date(selectReturnDate);
      const formattedInvoiceDate = iDate.toISOString().split("T")[0];

      const iDate1 = new Date(rowDatas.invoiceDate);
      const formattedInvoiceDate1 = iDate1.toISOString().split("T")[0];
      const datas = {
        CreditNoteID: rowData.salesInvoiceID || 0,
        CreditNoteNumber: creditNoteNumber,
        SalesInvoiceID: rowDatas.salesInvoiceID,
        InvoiceNumber: rowDatas.invoiceNumber,
        InvoiceDate: formattedInvoiceDate1,
        ReturnDate: formattedInvoiceDate,
        CustomerID: selectedBillToDropDown.contactID,
        CustomerGSTIN: selectedBillToDropDown.gstNumber,
        CompanyName: selectedBillToDropDown.companyName,
        FirstName: selectedBillToDropDown.firstName,
        LastName: selectedBillToDropDown.lastName,
        Address1: selectedBillToDropDown.baAddress1,
        Address2: selectedBillToDropDown.baAddress2,
        City: selectedBillToDropDown.saCity,
        State: selectedBillToDropDown.saState,
        ZipCode: selectedBillToDropDown.saZipCode,
        Country: selectedBillToDropDown.saCountry,
        Phone: selectedBillToDropDown.phone,
        Fax: selectedBillToDropDown.saFax,
        Email: selectedBillToDropDown.email,
        SubTotal: subTotal,
        DiscountType: "Percentage",
        DiscountRate: discountValue,
        DiscountAmount: discountedAmount,
        LocalTaxName: countryTaxDetails.localTaxName,
        LocalTaxRate: sgstTax,
        LocalTaxAmount: sgstTaxAmount,
        InterTaxName: countryTaxDetails?.interTaxName,
        InterTaxRate: igstTax,
        InterTaxAmount: igstTaxAmount,
        CentralTaxName: countryTaxDetails?.centralTaxName,
        CentralTaxRate: cgstTax,
        CentralTaxAmount: cgstTaxAmount,
        IsOtherTax: false,
        ShippingCharge: shipCharge,
        RoundOff: roundOff,
        TotalAmount: netAmountValue,
        DocID: 0,
        Status: "PENDING",
        TenantID: GlobalVariable.userDet?.tenantDetail?.tenantID,
        CreatedBy: GlobalVariable.userDet?.appUser?.appuserID,
        IsItemChanged: true,
        salesReturnItems: invoiceLines.map((a, i) => ({
          SalesReturnItemID: a.salesItemID || 0,
          CreditNoteID: rowData.salesInvoiceID || 0,
          ItemID: a.productname.itemID,
          ItemName: a.productname.name,
          ItemType: a.productname.itemType,
          HsnCode: a.productname.saCorHSNCode,
          HsnName: a.productname.itemType === "goods" ? "HSN" : "SAC",
          Unit: a.productname.unit,
          ReturnQuantity: a.quantity,
          Rate: a.productname.siSellingPrice,
          DiscountType: "Percentage",
          DiscountRate: discountValue,
          Discount: a.discount,
          LocalTaxName: countryTaxDetails.localTaxName,
          LocalTaxRate: +a.statetax,
          LocalTaxAmount: sgstTaxAmount,
          InterTaxName: countryTaxDetails?.interTaxName,
          InterTaxRate: +a.intertax,
          InterTaxAmount: igstTaxAmount,
          CentralTaxName: countryTaxDetails?.centralTaxName,
          CentralTaxRate: +a.centraltax,
          CentralTaxAmount: cgstTaxAmount,
          IsOtherTax: false,
          TotalAmount: +a.netamount,
          Reason: a.returnreason,
          Status: "PENDING",
          TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
          CreatedBy: GlobalVariable.userDet.appUser.appuserID,
        })),
      };
      ss.SaveSalesCreditNote(datas).then((res) => {
        if (res.data.statusCode === 200) {
          setIsProcess(false);
          toast.current.show({
            severity: "success",
            summary: res.data.message,
          });
          backToGrid();
        } else {
          setIsProcess(false);
        }
      });
    }
  };

  // const handleSelectToBillDropdown = (e) => {
  //   console.log(e,'e');
  //   setSelectedBillToDropDown(e.key);
  // };

  const menu = (
    <Menu
      style={{ maxHeight: "200px", overflowY: "auto" }}
      onClick={handleSelectToBillDropdown}
    >
      {billToDropDown.map((item) => (
        <Menu.Item key={item.label}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  const proceedPay = (data, page) => {
    data.page = page;
    console.log(data, "rowdata");
    setPayment(data);
    paymodal.current.alertToggle(data);
  };

  const backToGrid = () => {
    props.data.backToCreditNote(true);
    setDiscountValue(0);
    setIsType(false);
    // setIsUpdate(false);
    reset(defaultValues);
    setEstimateDateSelect(new Date());
    clearFields();
    setIsCustChoose(true);
    setNetAmountValue("");
    setSubTotal(0);
    setDiscountValue(0);
    setSgstTax(0);
    setCgstTax(0);
    setIgstTax(0);
    setRoundOff(0);
    setNetAmountValue(0);
    setShipCharge(0);
    setCgstTaxAmount(0);
    setSgstTaxAmount(0);
    setIgstTaxAmount(0);
    setDiscountedAmount(0);
    // GenerateConfigNumber();
  };

  const GenerateConfigNumber = () => {
    const data = {
      TenantID: GlobalVariable.userDet.tenantDetail.tenantID,
      ServiceName: "SalesReturn",
    };
    ps.GenerateConfigNumber(data).then((res) => {
      setCreditNoteNumber(res.data.data);
    });
  };

  useEffect(() => {
    GetTenantDetails();
    GetContact();
    GetSalesItemList();
    GenerateConfigNumber();
  }, []);

  return (
    <div>
      <BlockUI blocked={isProcess}>
        <Toast ref={toast} />
        <InvoicePayment
          ref={paymodal}
          data={{ payment }}
          parentFunction={backToGrid}
        />
        {/* <Dropdown menu={{items}}>
                            <Space>
                            Select customer
                              <DownOutlined />
                            </Space>
                          </Dropdown> */}
        <form>
          <div>
            <div className="row mx-auto">
              <div
                className="col-md d-flex justify-content-between cc-greyCard-custm align-items-center mb-3 p-2 mx-2"
                style={{ background: "white" }}
              >
            <div className="ms-2 my-2 me-2">
              
                <div>
                  <h4 className="m-0"> New Credit Note</h4>
                </div>
               </div>

                <div>
                  <button
                    type="button"
                    className="btn btn-danger py-1 me-2 "
                    onClick={backToGrid}
                  >
                    {" "}
                    Back
                  </button>
                </div>
              </div>
            </div>
            <div className="card p-3 ">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="">
                        <strong>Bill from</strong>
                      </label>
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex justify-content-between pe-4">
                        <div className="col-md-2 me-3">
                          <label htmlFor="">
                            <strong className="">Bill to</strong>
                          </label>
                        </div>
                        <div className="col-md-9">
                          {/* <Dropdown
                                className="form-control"
                                icon={<DownOutlined />}
                                // menu={{billToDropDown}}
                                placeholder="Select customer"
                                // onClick={() => enterLoading(1)}
                                trigger={["click"]}
                              />
                              </Dropdown> */}
                          {/* <Dropdown
                            overlay={menu}
                            className="mx-4"
                            placeholder="Select customer"
                            trigger={["click"]}
                          >
                            <span>
                              {selectedBillToDropDown
                                ? `${selectedBillToDropDown}`
                                : "Select customer"}{" "}
                              <DownOutlined />
                            </span>
                          </Dropdown> */}

                          {/* Other components here if needed */}

                          {/* <Dropdown menu={{billToDropDown}}>
                            <Space>
                            Select customer
                              <DownOutlined />
                            </Space>
                          </Dropdown> */}

                          <Controller
                            name="billto"
                            control={control}
                            rules={{ required: "This field is required" }}
                            render={({ field }) => (
                              <Primedropdown
                                {...field}
                                value={field.value || ""}
                                // disabled={fieldEnabled}
                                hidden={isDisable}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  handleSelectToBillDropdown1(e.value);
                                }}
                                options={billToDropDown}
                                optionLabel="companyName"
                                placeholder="Select customer"
                                className="form-control"
                              />
                            )}
                          />
                          {/* <Select
                            name="billto"
                            onChange={handleSelectToBillDropdown}
                            className="form-control p-0"
                            placeholder="Select"
                            // value={selectedBillToDropDown}
                            options={billToDropDown.map((option) => ({
                              label: option.companyName,
                              value: option.contactID,
                            }))}
                            {...register("billto", { required: true })}
                          /> */}
                          {errors.billto && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-md-12 d-flex mb-5 justify-content-between">
                  <div className="col-md">
                    <div className="row">
                      <div className="col-md p-3 pe-2">
                        <div
                          className=" p-2 h-100"
                          style={{
                            backgroundColor: "#FDFDFD",
                            border: "1px solid #D3D7DD",
                          }}
                        >
                          <p className="m-0">
                            <strong>{tenantDetails.tenantName},</strong>
                          </p>
                          <p className="m-0 ps-2">
                            {tenantDetails.billAddress1},{" "}
                            {tenantDetails.billAddress2}
                          </p>
                          <p className="m-0 ps-2">
                            <strong className="me-2">
                              <i className="pi pi-home"></i>
                            </strong>
                            {tenantDetails.billCity} -{" "}
                            {tenantDetails.billZipCode} -{" "}
                            {tenantDetails.billCountry}
                          </p>
                          <p className="m-0 ps-2">
                            <strong className="me-2">
                              <i className="pi pi-phone"></i>
                            </strong>{" "}
                            {tenantDetails.primaryPhone},{" "}
                            <strong className="m-2">
                              <i className="pi pi-envelope"></i>
                            </strong>{" "}
                            {tenantDetails.email}
                          </p>
                        </div>
                      </div>
                      <div className="col-md p-3 pe-2">
                        {!isCustChoose && (
                          <div
                            className=" p-2 h-100"
                            style={{
                              backgroundColor: "#FDFDFD",
                              border: "1px solid #D3D7DD",
                            }}
                          >
                            <p className="m-0">
                              <strong>
                                {selectedBillToDropDown?.fullName},
                              </strong>
                            </p>
                            <p className="m-0 ps-2">
                              {selectedBillToDropDown?.baAddress1}
                            </p>
                            <p className="m-0 ps-2">
                              {selectedBillToDropDown?.baState},{" "}
                              {selectedBillToDropDown?.baZipCode}
                            </p>
                            <p className="m-0 ps-2">
                              {" "}
                              <strong className="me-2">
                                <i className="pi pi-phone"></i>
                              </strong>{" "}
                              {selectedBillToDropDown?.phone},{" "}
                              <strong className="m-2">
                                <i className="pi pi-envelope"></i>
                              </strong>{" "}
                              {selectedBillToDropDown?.email}
                              {}
                            </p>
                          </div>
                        )}
                        {isCustChoose && (
                          <div
                            className="text-center p-2 h-100"
                            style={{
                              backgroundColor: "#FDFDFD",
                              border: "1px solid #D3D7DD",
                            }}
                          >
                            <Empty description="Select Customer to view details" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <Divider layout="vertical" />
                  <div className="col-md-6">
                    <div className="row mx-auto mb-3">
                      <div className="col-md">
                        <label htmlFor=""> Invoice Number</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={rowDatas?.invoiceNumber}
                        />
                      </div>
                      <div className="col-md">
                        <label htmlFor=""> Invoice Date</label>
                        {/* <Space
                          direction="vertical"
                          className="form-control p-0"
                        >
                          <DatePicker
                            onChange={onChangeInvoiceDate}
                            value={invoiceDate}
                            style={{ width: "100%" }}
                          />
                        </Space> */}
                        <Calendar
                          name="invoicedate"
                          value={invoiceDate}
                          disabled
                          className="form-control p-0"
                          onChange={onChangeInvoiceDate}
                        />
                      </div>
                    </div>

                    <div className="row mx-auto mb-3">
                      <div className="col-md">
                        <label htmlFor="" className="required">
                          {" "}
                          Return Date
                        </label>
                        {/* <Calendar
                          name="invoicedate"
                          value={estimateDateSelect}
                          disabled={fieldEnabled}
                          minDate={new Date()}
                          className="form-control p-0"
                          onChange={(e) => handleSelectEstimateDateSelect(e)}
                          placeholder="Select invoice date"
                        /> */}
                        {/* <Space
                          direction="vertical"
                          className="form-control p-0"
                        >
                          <DatePicker
                            onChange={onChangeReturnDate}
                            style={{ width: "100%" }}
                          />
                        </Space> */}
                        {/* <Calendar
                          name="returndate"
                          value={selectReturnDate}
                          // disabled={fieldEnabled}
                          placeholder="Select return date"
                          className="form-control p-0"
                          onChange={(e) => setSelectReturnDate(e.value)}
                          {...register("returndate")}
                        /> */}
                        <Controller
                          name="returndate"
                          control={control}
                          rules={{ required: "This field is required" }}
                          render={({ field }) => (
                            <Calendar
                              {...field}
                              value={selectReturnDate}
                              disabled={isDisable}
                              className="form-control p-0"
                              placeholder="Select return date"
                              onChange={(e) => {
                                field.onChange(e.value);
                                setSelectReturnDate(e.value);
                              }}
                            />
                          )}
                        />

                        {errors.returndate && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>{" "}
                      <div className="col-md">
                        <label htmlFor="">Credit Note Number</label>
                        <input
                          type="text"
                          className="form-control"
                          value={creditNoteNumber}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Divider className="ms-5" style={{ width: "94%" }} />
              </div>
              {isProcess && (
                <ProgressSpinner
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  strokeWidth="2"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              )}

              <div className="row">
                <div className="col-md">
                  <div className="table-responsive">
                    <table className="table mt-3">
                      <thead>
                        <tr className="heading">
                          <th className="borderIn" style={{ width: "5%" }}>
                            Product Name
                          </th>
                          
                          <th className="borderIn" style={{ width: "5%" }}>
                            Return Qty
                          </th>
                          <th className="borderIn" style={{ width: "5%" }}>
                            Sold Qty
                          </th>
                          <th className="borderIn" style={{ width: "5%" }}>
                            Rate ({GlobalVariable.userDet.tenantConfig.currency}
                            )
                          </th>
                          <th className="borderIn" style={{ width: "5%" }}>
                            Discount (%)
                          </th>
                          <th colSpan="3">Tax (%)</th>
                          <th className="borderIn">Reason Of Return (%)</th>
                          <th className="borderIn" style={{ width: "8%" }}>
                            New Amount (
                            {GlobalVariable.userDet.tenantConfig.currency})
                          </th>
                          <th style={{ width: "3%" }}></th>
                        </tr>
                        <tr className="sub-heading">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th className="borderIn">
                            {countryTaxDetails?.localTaxName}
                          </th>
                          <th className="borderIn">
                            {countryTaxDetails?.centralTaxName}
                          </th>
                          <th className="borderIn">
                            {countryTaxDetails?.interTaxName}
                          </th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      {isProcess && (
                        <ProgressSpinner
                          style={{
                            width: "50px",
                            height: "50px",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                          strokeWidth="2"
                          fill="var(--surface-ground)"
                          animationDuration=".5s"
                        />
                      )}
                      <tbody>
                        {invoiceLines.map((line, index) => (
                          <tr key={index}>
                            <td>
                              <Primedropdown
                                name="productname"
                                value={line.productname}
                                onChange={(e) => {
                                  handleSelectProduct(e.value, index);
                                }}
                                options={productDrop}
                                disabled
                                optionLabel="name"
                                placeholder="Choose to select"
                                className="form-control-custom sm-2"
                              />
                            </td>
                            
                            <td>
                              <input
                                type="text"
                                name="quantity"
                                className="form-control"
                                // disabled={!line.productname || fieldEnabled}
                                disabled={isDisable}
                                value={line.quantity}
                                onInput={(e) => {
                                  handleInputChangeQuantity(e, index);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="prequantity"
                                className="form-control"
                                disabled
                                value={line.prequantity}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="rate"
                                className="form-control"
                                disabled
                                value={line.rate}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="discount"
                                // disabled={!line.productname || !isDisable}
                                disabled
                                className="form-control"
                                value={line.discount}
                                onInput={(e) => {
                                  handleInputChangeDiscount(e, index);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="statetax"
                                className="form-control"
                                // disabled={!line.discount || !line.quantity}
                                disabled
                                value={line.statetax}
                                title="To adjust the tax percentage, please access the Inventory section"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="centraltax"
                                className="form-control"
                                // disabled={!line.discount || !line.quantity}
                                disabled
                                value={line.centraltax}
                                title="To adjust the tax percentage, please access the Inventory section"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="intertax"
                                className="form-control"
                                disabled
                                // disabled={!line.discount || !line.quantity}
                                value={line.intertax}
                                title="To adjust the tax percentage, please access the Inventory section"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="returnreason"
                                className="form-control"
                                disabled={isDisable}
                                // value={line.returnreason}
                                defaultValue={line.returnreason}
                                onInput={(e) => handleReasonChange(e, index)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="netamount"
                                className="form-control"
                                value={line.netamount}
                                disabled
                              />
                            </td>

                            <td style={{ textAlign: "center", padding: "1%" }}>
                              <button
                                type="button"
                                style={{ background: "transparent" }}
                                onClick={() => deleteLineItem(line, index)}
                                hidden={isDisable}
                              >
                                <i
                                  className="pi pi-trash"
                                  aria-hidden="true"
                                  style={{ color: "red" }}
                                ></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {manualErr && (
                      <p className="text-danger my-3">{manualErr}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row mx-auto my-4">
                    <div className="col-md p-0"></div>
                    <div className="col-md"></div>
                    <div className="col-md"></div>
                    <div
                      className="col-md  p-3"
                      style={{
                        background: "rgb(226 243 252)",
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <p> Sub Total</p>
                        <p>
                          {" "}
                          <i
                            className="pi pi-indian-rupee"
                            aria-hidden="true"
                          ></i>{" "}
                          {GlobalVariable.userDet.tenantConfig.currency}
                          {subTotal}
                        </p>
                      </div>

                      <div className="d-flex justify-content-between">
                        <p> Tax(SGST)</p>
                        <p>
                          {" "}
                          <i
                            className="pi pi-indian-rupee"
                            aria-hidden="true"
                          ></i>{" "}
                          {GlobalVariable.userDet.tenantConfig.currency}
                          {sgstTaxAmount}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p> Tax(CGST)</p>
                        <p>
                          {" "}
                          <i
                            className="pi pi-indian-rupee"
                            aria-hidden="true"
                          ></i>{" "}
                          {GlobalVariable.userDet.tenantConfig.currency}
                          {cgstTaxAmount}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p> Tax(IGST)</p>
                        <p>
                          {" "}
                          <i
                            className="pi pi-indian-rupee"
                            aria-hidden="true"
                          ></i>{" "}
                          {GlobalVariable.userDet.tenantConfig.currency}
                          {igstTaxAmount}
                        </p>
                      </div>

                      <div className="d-flex justify-content-between">
                        <p> Shipping Charges</p>
                        <p className="text-end">
                          {" "}
                          {GlobalVariable.userDet.tenantConfig.currency}
                          <input
                            value={shipCharge}
                            type="number"
                            disabled
                            name="shippngcharge"
                            className="text-end ms-1"
                            style={{ width: "31%" }}
                            onInput={(e) => {
                              getShippingCharge(e);
                            }}
                          />
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p> Round off</p>
                        <p>
                          {" "}
                          <i
                            className="pi pi-indian-rupee"
                            aria-hidden="true"
                          ></i>{" "}
                          {GlobalVariable.userDet.tenantConfig.currency}
                          {roundOff}
                        </p>
                      </div>
                      <Divider className="m-0" />
                      <div className="d-flex justify-content-between">
                        <p> Discount </p>
                        {discountedAmount !== "0.00" ? (
                          <p className="text-danger">
                            - {GlobalVariable.userDet.tenantConfig.currency}{" "}
                            {discountedAmount}
                          </p>
                        ) : (
                          <p className="">
                            {" "}
                            {GlobalVariable.userDet.tenantConfig.currency}{" "}
                            {discountedAmount}
                          </p>
                        )}
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>
                          <strong> Total</strong>
                        </p>
                        <p>
                          {" "}
                          <strong>
                            <i
                              className="pi pi-indian-rupee"
                              aria-hidden="true"
                            ></i>{" "}
                            {GlobalVariable.userDet.tenantConfig.currency}
                            {netAmountValue?.toFixed(2)}
                          </strong>
                        </p>
                      </div>
                      <Divider className="m-0" />
                    </div>
                    <div className="d-flex justify-content-end gap-3">
                      <Button
                        className="btn btn-danger my-3 "
                        type="button"
                        label="Cancel"
                        onClick={backToGrid}
                        // hidden={rowDatas.status === "PAID"}

                      />

                      {isDisable ? (
                        <Button
                          className="btn btn-primary my-3"
                          type="button"
                          onClick={() => proceedPay(rowDatas, "editpage")}
                          label="Record Payment "
                          hidden={rowDatas.status === "PAID"}
                        />
                      ) : (
                        <Button
                          className="btn btn-primary my-3"
                          type="button"
                          onClick={handleSubmit(onSubmit)}
                          label="Save & Submit"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmPopup />
        </form>
      </BlockUI>
    </div>
  );
};
